import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { uploadFile } from '../../utils/api';
import { flatButtonStyle, outlineButtonStyle, rem } from '../../styles/utils';
import Spinner from './spinner';

const Container = styled.div``;

const FileInputWrapper = styled.div`
  visibility: hidden;
  height: 0;
`;

const Button = styled.button`
  ${flatButtonStyle}
  margin-top: 10px;
`;

const ErrorLabel = styled.span`
  display: block;
  margin-top: 10px;
  color: red;
  font-size: 90%;
`;

const UploadedList = styled.ul`
  margin: 15px 0;
  padding: 0;
  list-style: none;
  width: 100%:
`;

const UploadedItem = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 8px;
`;

const Item = styled.span`
  color: var(--cornflower-blue);
`;

const RemoveButton = styled.button`
  ${outlineButtonStyle('dark')}
  margin-left: auto;
  padding: 8px;
  font-size: ${rem(8)};

  ${media.lg`
    font-size: ${rem(10)}; 
  `}
`;

const ItemImg = styled.img`
  height: 40px;
  margin-right: 10px;
`;

type Props = {
  folder: string;
  className?: string;
  onUploaded?: (url: string[]) => void;
  onRemoved?: (url: string[]) => void;
};

const FormUpload: React.FC<Props> = ({
  folder,
  className,
  onRemoved,
  onUploaded,
}) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileUrls, setUploadedFileUrls] = useState<
    {
      url: string;
      name: string;
    }[]
  >([]);
  const [error, setError] = useState('');

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event.target) return;
    if (!event.target.files) return;
    if (!event.target.files.length) return;
    const file = event.target.files[0];
    setIsUploading(true);
    setError('');
    const result = await uploadFile(file, folder);

    if (result.url && result.name) {
      const { url, name } = result;
      const newUrls = [...uploadedFileUrls, { url, name }];
      setUploadedFileUrls(newUrls);
      if (onUploaded) onUploaded(newUrls.map(u => u.url));
    } else {
      setError(
        result.error || 'Unable to upload, please contact us for assistance.'
      );
    }

    if (!fileUploadRef.current) {
      fileUploadRef.current.value = null;
    }
    setIsUploading(false);
  };

  const handleRemove = (url: string) => {
    const newUrls = [...uploadedFileUrls.filter(u => u.url !== url)];
    setUploadedFileUrls(newUrls);
    if (onRemoved) onRemoved(newUrls.map(u => u.url));
  };

  return (
    <Container className={className}>
      <FileInputWrapper>
        <input type="file" onChange={handleChange} ref={fileUploadRef} />
      </FileInputWrapper>
      <Button
        type="button"
        onClick={event => {
          event.preventDefault();
          fileUploadRef.current?.click();
        }}
      >
        Choose file
      </Button>
      {uploadedFileUrls.length > 0 && (
        <UploadedList>
          {uploadedFileUrls.map(u => (
            <UploadedItem key={u.url}>
              <ItemImg src={u.url} alt={u.name} />
              <Item>{u.name}</Item>
              <RemoveButton onClick={() => handleRemove(u.url)}>
                Remove
              </RemoveButton>
            </UploadedItem>
          ))}
        </UploadedList>
      )}
      {error && <ErrorLabel>{error}</ErrorLabel>}
      <Spinner isLoading={isUploading} size={30} />
    </Container>
  );
};

export default FormUpload;
