import { State } from './checkout-context-types';

export enum CheckoutEnum {
  ADDRESS = 'sg.woosa.address',
}

export const initialState: State = {
  sameAddress: true,
  deliveryOption: 'all',
};
