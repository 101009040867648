import React from 'react';

import MattressLayerV1, {
  commonLayerStyle,
  Props as DefaultPropsV1,
} from './mattress-layer';
import MattressLayerV2, { Props as DefaultPropsV2 } from './mattress-layer-v2';

export { commonLayerStyle };

type PropsV1 = {
  version: 'v1';
} & DefaultPropsV1;

type PropsV2 = {
  version: 'v2';
} & DefaultPropsV2;

type Props = PropsV1 | PropsV2;

const MattressLayer: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <MattressLayerV2 {...props} />;
    default:
      return <MattressLayerV1 {...props} />;
  }
};

export default MattressLayer;
