import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { ProductOrderItem } from '../../../types/order';
import { Product, Variant } from '../../../types/product';
import { rem } from '../../../styles/utils';
import SubVariantItem from './sub-variant-item';
import SubVariantList from './sub-variant-list';
import { Col, Row, media } from 'styled-bootstrap-grid';
import { Breakpoints } from '../../../styles/enums';
import { isSimpleSubVariant } from '../../../utils';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
  position: relative;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: 1.2;
  margin-bottom: 10px;
  display: block;
  text-transform: capitalize;

  ${media.lg`
  font-size: ${rem(14)};
`}

  ${media.xl`
  font-size: ${rem(16)};
  line-height: 1.3125;
`}
`;

const CustomizeSubVariantItem = styled(SubVariantItem)`
  div[class*='sub-variant-item__ImageContainer'] {
    width: 52px;
    height: 52px;

    &.selected,
    &:not(.unselectable, .disabled):hover {
      border: 1px solid var(--firefly);
      padding: 3px;
    }
  }

  span {
    display: block;
    width: 52px;
    color: var(--cornflower-blue);
  }
`;

const carouselResponsive = {
  xxl: {
    breakpoint: {
      max: 4000,
      min: Breakpoints.XXL,
    },
    items: 5,
    partialVisibilityGutter: 15,
  },
  xl: {
    breakpoint: {
      max: Breakpoints.XXL,
      min: Breakpoints.XL,
    },
    items: 3,
    partialVisibilityGutter: 25,
  },
  lg: {
    breakpoint: {
      max: Breakpoints.XL,
      min: Breakpoints.LG,
    },
    items: 4,
    partialVisibilityGutter: 15,
  },
  md: {
    breakpoint: {
      max: Breakpoints.LG,
      min: Breakpoints.MD,
    },
    items: 3,
    partialVisibilityGutter: 15,
  },
  sm: {
    breakpoint: {
      max: Breakpoints.MD,
      min: Breakpoints.SM,
    },
    items: 3,
    partialVisibilityGutter: 15,
  },
  xs: {
    breakpoint: { max: Breakpoints.SM, min: 0 },
    items: 3,
    partialVisibilityGutter: 15,
  },
};

type Props = {
  product: Product;
  onChange?: (productOrderItem: ProductOrderItem | null) => void;
};

const SimpleSubVariant: React.FC<Props> = ({ product, onChange }) => {
  const [selected, setSelected] = useState<Variant | null>(null);
  const standardVariants = product.variants;
  const subVariantKey = product.subVariantKeys && product.subVariantKeys[0];

  const getName = (variant: Variant) => {
    return variant.subVariants.map(v => v.value).join(' ');
  };

  const getImage = (variant: Variant, type: 'variant' | 'subVariant') => {
    const subVariant = variant.subVariants.find(s => s.key === subVariantKey);
    if (type === 'subVariant' && subVariant && subVariant.image)
      return subVariant.image;
    if (variant.thumbnail) return variant.thumbnail;
    if (!(product.media || []).length) return '';
    const defaultMedia = product.media.find(m => m.type === 'image');
    if (!defaultMedia) return '';
    return defaultMedia.path || '';
  };

  const handleClick = (variant: Variant) => {
    setSelected(variant);
    if (typeof onChange !== 'function') return;
    const { id, name, collection } = product;
    const {
      type,
      subVariants,
      status,
      statusDate,
      statusType,
      statusNote,
      sku,
      prices,
    } = variant;
    const { compareAmount, amount, currency } = prices[0];
    const selectedProduct: ProductOrderItem = {
      id,
      name,
      collection,
      image: getImage(variant, 'variant'),
      comparePrice: compareAmount || 0,
      currency,
      qty: 1,
      subtotal: amount,
      unitPrice: amount,
      variant: type,
      subVariants,
      isNew: true,
      status,
      statusDate: (statusDate
        ? moment.unix(statusDate._seconds)
        : moment()
      ).toDate(),
      statusType,
      statusNote,
      sku,
    };
    onChange(selectedProduct);
  };

  if (!isSimpleSubVariant(product.subVariantKeys)) return null;

  return (
    <Container>
      <Row>
        <Col xl={4}>
          <Label>{product.subVariantKeys && product.subVariantKeys[0]}</Label>
        </Col>
        <Col xl={8}>
          <SubVariantList
            responsive={carouselResponsive}
            items={standardVariants.map(v => (
              <CustomizeSubVariantItem
                key={v.id}
                onClick={() => handleClick(v)}
                image={getImage(v, 'subVariant')}
                name={getName(v)}
                selected={v.id === selected?.id}
              />
            ))}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SimpleSubVariant;
