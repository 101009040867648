import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { media } from 'styled-bootstrap-grid';
import classNames from 'classnames';

import { ProductOrderItem } from '../../../types/order';
import { Product, SubVariant, Variant } from '../../../types/product';
import SubVariantList from './sub-variant-list';
import SubVariantItem from './sub-variant-item';
import { isArrayObjectEqual } from '../../../utils';
import { CommonVariantTypes } from '../../../utils/constants';

const Container = styled.div``;

const Title = styled.h4``;

const List = styled.ul`
  list-style: none;
  margin: 0 -45px 0 0;
  padding: 0;

  ${media.xl`
    margin: 0;
  `}
`;

const Item = styled.li`
  &.hide {
    display: none;
  }
`;

const SubVariantItemStyled = styled(SubVariantItem)`
  &.hide {
    display: none;
  }
`;

type SubVariantOption = SubVariant & {
  hide?: boolean;
};

type Catalog = {
  [key: string]: SubVariantOption[];
};

type SelectedCatalog = {
  [key: string]: SubVariantOption;
};

type Props = {
  addOnProduct: Product;
  onChange?: (productOrderItem: ProductOrderItem | null) => void;
};

const CustomVariantTab: React.FC<Props> = ({ addOnProduct, onChange }) => {
  const [customVariants, setCustomVariants] = useState<Variant[]>([]);
  const [catalog, setCatalog] = useState<Catalog>({});
  const [selectedCatalog, setSelectedCatalog] = useState<SelectedCatalog>({});
  const baseCatalog = useRef<Catalog>({});

  const [diffPriceAddon, setDiffPriceAddon] = useState<number>();

  const mapVariantsToCatalog = (variants: Variant[]) => {
    const duplicateHash: {
      [key: string]: boolean;
    } = {};

    const newCatalog = variants.reduce<Catalog>((acc, v) => {
      v.subVariants.forEach(sv => {
        if (!(sv.key in acc)) {
          acc[sv.key] = [];
        }
        const customSv: SubVariantOption = { ...sv };
        if (sv.value) {
          const hashFormat = `${sv.key}-${sv.value}`;
          if (!(hashFormat in duplicateHash)) {
            acc[sv.key].push(customSv);
            duplicateHash[hashFormat] = true;
          }
        }
      });
      return acc;
    }, {});

    return newCatalog;
  };

  // Filter and get custom variants only
  useEffect(() => {
    const filterCustomVariants = addOnProduct.variants.filter(
      v => v.statusType === 'Custom'
    );
    setCustomVariants(filterCustomVariants);
  }, [addOnProduct]);

  // Updates catalog whenever there's changes to the custom variants
  useEffect(() => {
    const newCatalog = mapVariantsToCatalog(customVariants);
    setCatalog(newCatalog);
    baseCatalog.current = newCatalog;
    setSelectedCatalog({});
  }, [customVariants]);

  const handleClick = (subVariant: SubVariant, keyIndex: number) => {
    const { key, value } = subVariant;

    // Already exists, skip
    if (selectedCatalog[key] && selectedCatalog[key].value === value) return;

    // Remove other steps if the selected sub variant belongs to a earlier step
    const removeCatalogKeys = Object.keys(catalog).slice(keyIndex + 1);
    const newSelectedCatalog: SelectedCatalog = {
      ...selectedCatalog,
    };
    removeCatalogKeys.forEach(k => {
      delete newSelectedCatalog[k];
    });
    newSelectedCatalog[key] = subVariant;

    /**
     * 2. Hide unavailable combination in catalog logic
     */

    // 2.1 Check whether to reset to base catalog or we can continue with current catalog
    const referenceCatalog =
      Object.keys(newSelectedCatalog).length - 1 < keyIndex
        ? baseCatalog.current
        : catalog;

    // 2.2. Loop through catalog
    const newCatalog = Object.keys(referenceCatalog).reduce(
      (c, k, i) => {
        // 2.2.1. if it is already selected key we skip
        if (i <= keyIndex) return c;

        // 2.2.2. Find available options in custom variants
        const availableOptions = customVariants.reduce<SubVariantOption[]>(
          (options, cv) => {
            const isValidOption = Object.keys(newSelectedCatalog).every(ck =>
              cv.subVariants.some(
                sv => sv.key === ck && sv.value === newSelectedCatalog[ck].value
              )
            );
            if (isValidOption) {
              // 2.2.2.1 Only add in unique options
              const newOptions = cv.subVariants
                .filter(sv => sv.key === k)
                .reduce<SubVariantOption[]>((uniqueNewOptions, newOption) => {
                  if (
                    !options.find(
                      o =>
                        o.key === newOption.key && o.value === newOption.value
                    )
                  ) {
                    uniqueNewOptions.push(newOption);
                  }
                  return uniqueNewOptions;
                }, []);

              options.push(...newOptions);
            }
            return options;
          },
          []
        );

        // 2.2.3. Assign the found options
        c[k] = availableOptions;
        return c;
      },
      { ...referenceCatalog }
    );

    setSelectedCatalog(newSelectedCatalog);
    setCatalog(newCatalog);
    handleChange(newCatalog, newSelectedCatalog);
  };

  const getImage = (variant: Variant) => {
    if (variant.thumbnail) return variant.thumbnail;
    if (!(addOnProduct.media || []).length) return '';
    const defaultMedia = addOnProduct.media.find(m => m.type === 'image');
    if (!defaultMedia) return '';
    return defaultMedia.path || '';
  };

  const handleChange = (c: Catalog, s: SelectedCatalog) => {
    if (typeof onChange !== 'function') return;
    const selectedKeys = Object.keys(s);
    const catalogKeys = Object.keys(c);

    const diffPriceKey = CommonVariantTypes.DESIGN;
    const min = customVariants
      .filter(x =>
        x.subVariants.find(
          m => m.key === diffPriceKey && m.value === s[diffPriceKey].value
        )
      )
      .reduce((a, b) => (a.prices[0].amount > b.prices[0].amount ? b : a));

    if (selectedKeys.length !== catalogKeys.length) {
      setDiffPriceAddon(undefined);
      onChange(null);
      return;
    }

    const selectedArray = selectedKeys.map(key => s[key]);
    const variant = customVariants.find(cv =>
      isArrayObjectEqual(selectedArray, cv.subVariants, ['key', 'value'])
    );
    if (!variant) {
      setDiffPriceAddon(undefined);
      onChange(null);
      return;
    }

    // set diff amount
    const selectedVariantPrice = variant.prices[0]?.amount;
    const lowestVariantPrice = min.prices[0]?.amount;
    const diffVariantPrice = selectedVariantPrice - lowestVariantPrice;
    setDiffPriceAddon(diffVariantPrice);

    const { id, name, collection } = addOnProduct;
    const {
      type,
      subVariants,
      status,
      statusDate,
      statusType,
      statusNote,
      sku,
      prices,
    } = variant;
    const { compareAmount, amount, currency } = prices[0];
    const selectedProduct: ProductOrderItem = {
      id,
      name,
      collection,
      image: getImage(variant),
      comparePrice: compareAmount || 0,
      currency,
      qty: 1,
      subtotal: amount,
      unitPrice: amount,
      variant: type,
      subVariants,
      isNew: true,
      status,
      statusDate: (statusDate
        ? moment.unix(statusDate._seconds)
        : moment()
      ).toDate(),
      statusType,
      statusNote,
      sku,
    };

    onChange(selectedProduct);
  };

  return (
    <Container>
      <List>
        {Object.keys(catalog).map((key, i) => {
          return (
            <Item
              className={classNames({
                hide: i > Object.keys(selectedCatalog).length,
              })}
              key={key}
            >
              <Title>
                {i + 1}. Select {key}
              </Title>
              <SubVariantList
                items={catalog[key].map(sv => (
                  <SubVariantItemStyled
                    className={classNames({ hide: sv.hide })}
                    key={`${sv.key}-${sv.value}`}
                    onClick={() => handleClick(sv, i)}
                    image={sv.image}
                    name={sv.value}
                    selected={
                      selectedCatalog[sv.key] &&
                      selectedCatalog[key].value == sv.value
                    }
                    diffPrice={diffPriceAddon}
                    curVariantType={sv.key}
                    showDiffPriceTag={CommonVariantTypes.MATERIAL}
                  />
                ))}
              />
            </Item>
          );
        })}
      </List>
    </Container>
  );
};

export default CustomVariantTab;
