import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { USE_TEMP_ADDRESS } from 'gatsby-env-variables';

import AppointmentFormModal from './appointment-form-modal';
import { outlineButtonStyle, rem } from '../../styles/utils';
import { ContactLinks } from '../../utils/constants';
import { phoneContactLinksToFriendlyValue } from '../../utils';

const ButtonLink = styled.button`
  ${outlineButtonStyle('light')}
  margin-top: 10px;
  cursor: pointer;
  padding: 12px;
`;

const InfoContainer = styled.div`
  font-size: ${rem(16)};
`;

type Props = {
  className?: string;
  hideBookSlot?: boolean;
  hideContact?: boolean;
};

export const StoreAddress: React.FC<{ className?: string; lines?: number }> = ({
  className,
  lines,
}) => {
  const renderAddress = () => {
    return (
      <>
        1 Tampines North Dr 1, {numOfLines > 1 && <br />}
        #01-01 T-Space, {numOfLines > 2 && <br />}
        Singapore 528559
      </>
    );
  };

  const renderTempAddress = () => {
    return (
      <>
        1 Tampines North Drive 3, {numOfLines > 1 && <br />}
        #02-04, BHCC SPACE, {numOfLines > 2 && <br />}
        Singapore 528499
      </>
    );
  };

  const numOfLines = lines || 2;
  const locationLink = !USE_TEMP_ADDRESS
    ? ContactLinks.Location
    : ContactLinks.TempLocation;

  return (
    <a
      className={className}
      href={locationLink}
      target="_blank"
      rel="noreferrer"
    >
      {!USE_TEMP_ADDRESS ? renderAddress() : renderTempAddress()}
    </a>
  );
};

export const StoreContact: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <a
      className={className}
      href={ContactLinks.Phone}
      target="_blank"
      rel="noreferrer"
    >
      {phoneContactLinksToFriendlyValue()}
    </a>
  );
};

export const StoreEmail: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <a
      className={className}
      href={ContactLinks.Email}
      target="_blank"
      rel="noreferrer"
    >
      {ContactLinks.Email.replace('mailto:', '')}
    </a>
  );
};

export const StoreHours: React.FC<{ className?: string }> = ({ className }) => {
  if (USE_TEMP_ADDRESS) {
    return (
      <span className={className}>
        Monday to Sunday: 11am-8pm (by appointment only)
      </span>
    );
  }

  return (
    <span className={className}>
      Open daily (Monday to Sunday) from 11:00AM to 8:00PM
    </span>
  );
};

const StoreInfo: React.FC<Props> = ({
  className,
  hideBookSlot,
  hideContact,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const location = useLocation();
  const { hash } = location;

  useEffect(() => {
    if (hash !== '#appointment') return;
    if (displayModal) return;
    setDisplayModal(true);
  }, [hash]);

  return (
    <>
      <InfoContainer className={className}>
        <StoreAddress />
        <br />
        <br />
        <StoreHours />
        {!hideBookSlot && (
          <>
            <br />
            <ButtonLink onClick={() => setDisplayModal(true)}>
              Book a slot
            </ButtonLink>
          </>
        )}
        {!hideContact && (
          <>
            <br />
            <br />
            <StoreContact />
            <br />
            <StoreEmail />
          </>
        )}
      </InfoContainer>
      {!hideBookSlot && displayModal && (
        <AppointmentFormModal
          display
          onClickClose={() => setDisplayModal(false)}
        />
      )}
    </>
  );
};

export default StoreInfo;
