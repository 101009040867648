import React from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';

import { Accordion } from '../../types/component';
import { rem } from '../../styles/utils';

export const Headline = styled.h2`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-weight: normal;
  margin: 0;
  font-size: ${rem(12)};
`;

export const Subtitle = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: ${rem(12)};
  ${media.lg`
    font-size: ${rem(14)};
  `}
`;

const Section = styled.div`
  padding: 20px 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Img = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  ${media.lg`
    margin-right: 13px;
  `}
`;

type Props = {
  data: Accordion[];
};

const BasicContent: React.FC<Props> = ({ data }) => {
  return (
    <Section>
      <Row>
        {data.map(({ body, title, img }, index) => (
          <Col lg={6} key={index}>
            <DetailsContainer>
              <Img>{img}</Img>
              <div>
                <Headline>{title}</Headline>
                <Subtitle>{body}</Subtitle>
              </div>
            </DetailsContainer>
          </Col>
        ))}
      </Row>
    </Section>
  );
};

export default BasicContent;
