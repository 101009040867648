import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import {
  Eyebrow,
  ButtonLink,
  Error,
  Button,
  Title,
} from './auth-common-elements';
import { FormInput } from '../common';
import { signInWithEmailAndPassword } from '../../utils/auth';
import { useAuthDispatch } from '../../hooks/auth-context';

const Form = styled.form`
  width: 100%;
`;

type FormData = {
  email: string;
  password: string;
};

type Props = {
  className?: string;
  submitText?: string;
  onClickRegister?: () => void;
  onClickForgetPassword?: () => void;
  onBeforeRequest?: () => void;
  onAfterRequest?: (result: boolean) => void;
  registerMode?: 'guest' | 'signup';
};

const LoginForm: React.FC<Props> = ({
  className,
  submitText,
  onClickRegister,
  onClickForgetPassword,
  onBeforeRequest,
  onAfterRequest,
  registerMode,
}) => {
  const { register, handleSubmit, errors } = useForm<FormData>();
  const [error, setError] = useState('');
  const dispatch = useAuthDispatch();

  const hasErrors = () => Object.keys(errors).length > 0;

  const onSubmit = async (data: FormData) => {
    setError('');
    if (hasErrors()) return;

    const { email, password } = data;

    if (typeof onBeforeRequest === 'function') {
      onBeforeRequest();
    }

    const result = await signInWithEmailAndPassword(dispatch, email, password);

    if (!result) {
      setError('Invalid email/password. Please try again.');
    }

    if (typeof onAfterRequest === 'function') {
      onAfterRequest(result);
    }
  };

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Title>Sign in to existing Woosa account</Title>
      <FormInput
        error={errors.email}
        inputRef={register({
          required: 'Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Enter a valid email address',
          },
        })}
        inputProps={{
          placeholder: 'Email Address',
          name: 'email',
        }}
      />
      <FormInput
        error={errors.password}
        inputRef={register({ required: 'Password is required' })}
        inputProps={{
          placeholder: 'Password',
          type: 'password',
          name: 'password',
        }}
      />
      {error && <Error>{error}</Error>}
      {registerMode !== 'signup' && (
        <Eyebrow>
          Prefer to checkout as{' '}
          <ButtonLink type="button" onClick={onClickRegister}>
            Guest
          </ButtonLink>
          ?
        </Eyebrow>
      )}
      {registerMode === 'signup' && (
        <Eyebrow>
          New to Woosa?{' '}
          <ButtonLink type="button" onClick={onClickRegister}>
            Sign up now
          </ButtonLink>
        </Eyebrow>
      )}
      <Eyebrow>
        Forgot password?{' '}
        <ButtonLink type="button" onClick={onClickForgetPassword}>
          Reset it now
        </ButtonLink>
      </Eyebrow>
      <br />
      <Button type="submit" disabled={hasErrors()}>
        {submitText}
      </Button>
    </Form>
  );
};

export default LoginForm;
