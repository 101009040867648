import React from 'react';

import { PictureGridSection } from '../common';
import {
  ImgDetailBedsheetEgyptian1,
  ImgDetailBedsheetEgyptian2,
  ImgDetailBedsheetEgyptian3,
} from '../assets';

const EgyptianCottonBeddingSetPictureGridSection: React.FC = () => {
  return (
    <PictureGridSection
      headline="It's in the details"
      items={[
        {
          image: <ImgDetailBedsheetEgyptian1 />,
          content:
            'Elastic bands all throughout your fitted sheets help keep your sheets snugly in place.',
        },
        {
          image: <ImgDetailBedsheetEgyptian2 />,
          content:
            'Thoughtfully designed labels and ties helps you identify the right sides to your sheets, making dressing up the bed a breeze.',
        },
        {
          image: <ImgDetailBedsheetEgyptian3 />,
          content:
            'Zipper enclosures for our duvet covers ensure that your duvet insert stays securely in place throughout the night.',
        },
      ]}
    />
  );
};

export default EgyptianCottonBeddingSetPictureGridSection;
