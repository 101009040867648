import React from 'react';
import styled from 'styled-components';

import { ReviewGridSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const Section = styled(ReviewGridSection)``;

const PillowReviewGridSection: React.FC = () => {
  return (
    <Section
      filters={{ id: 'bLmYFUBvTpH8LcHAmyXP' }}
      allReviewsUrl={SiteUrls.OriginalPillowReview}
    />
  );
};

export default PillowReviewGridSection;
