import React from 'react';
import styled from 'styled-components';

import Video from './video';
import Container from './container';
import { lh, rem } from '../../styles/utils';
import { media } from 'styled-bootstrap-grid';

const Section = styled.section`
  background-color: var(--big-stone);
  padding: 64px 0;
`;

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  color: white;
  font-size: ${rem(24)};
  line-height: ${lh(32, 24)};
  text-align: center;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 40px;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};
  `}
`;

const Hero = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const Gallery = styled.div`
  ${media.md`
    display: flex;
    flex-wrap: wrap
  `}
`;

const GalleryItem = styled.div`
  margin-bottom: 32px;

  ${media.md`
    width: 50%;

    &:nth-child(even) {
      padding-left: 16px;
    }

    &:nth-child(odd) {
      padding-right: 16px;
    }
  `}
`;

const GalleryAsset = styled.div``;

const GalleryTitle = styled.h3`
  font-family: var(--font-header);
  color: white;
  font-size: ${rem(24)};
  line-height: ${lh(32, 24)};
  font-weight: 400;
  margin: 10px 0 6px 0;

  ${media.lg`
    margin: 30px 0 12px 0;
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};
  `}
`;

const GalleryDescription = styled.div`
  font-family: var(--font-body);
  color: white;
  font-size: ${rem(14)};
  line-height: ${lh(18, 14)};
  font-weight: 400;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const Eyebrow = styled.p`
  font-family: var(--font-body);
  color: var(--santa-fe);
  font-size: ${rem(14)};
  line-height: ${lh(18, 14)};
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

type BaseGalleryItem = {
  title?: React.ReactNode;
  description?: React.ReactNode;
};

type VideoGalleryItem = BaseGalleryItem & {
  type: 'video';
  src: unknown;
};

type ImageGalleryItem = BaseGalleryItem & {
  type: 'image';
  img: React.ReactNode;
};

type GalleryItem = VideoGalleryItem | ImageGalleryItem;

type Props = {
  className?: string;
  headline: React.ReactNode;
  gallery: GalleryItem[];
  eyebrow?: React.ReactNode;
};

const IntroGallerySection: React.FC<Props> = ({
  headline,
  gallery,
  eyebrow,
}) => {
  if (gallery.length === 0) return null;

  const heroGallery = gallery[0];
  const bodyGallery = gallery.slice(1);

  const renderAsset = (galleryItem: GalleryItem) => {
    switch (galleryItem.type) {
      case 'video':
        return <Video src={galleryItem.src} />;
      default:
        return galleryItem.img;
    }
  };

  return (
    <Section>
      <Container>
        <Wrapper>
          {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
          <Headline>{headline}</Headline>
          <Hero>{renderAsset(heroGallery)}</Hero>
          <Gallery>
            {bodyGallery.map((galleryItem, idx) => (
              <GalleryItem key={idx}>
                <GalleryAsset>{renderAsset(galleryItem)}</GalleryAsset>
                <GalleryTitle>{galleryItem.title}</GalleryTitle>
                <GalleryDescription>
                  {galleryItem.description}
                </GalleryDescription>
              </GalleryItem>
            ))}
          </Gallery>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default IntroGallerySection;
