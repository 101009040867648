import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Post } from '../../types/post';
import { rem } from '../../styles/utils';
import PostContainer from './post-container';

const Section = styled.section`
  font-family: var(--font-body);
  font-size: ${rem(18)};
  color: var(--cornflower-blue);
  line-height: 1.33;
  font-weight: normal;
  margin-top: 50px;
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote {
    font-family: var(--font-header);
    font-weight: 400;
    color: var(--font-primary-color);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  figure.image {
    display: inline-block;
    margin: 0;
    background: transparent;
  }

  figure.image img {
    margin: 0;
  }

  figure.image figcaption {
    margin: 6px 0;
  }

  blockquote {
    line-height: 1.1;
  }
  blockquote > * {
    margin: 0 !important;
  }

  .accordion {
    width: 100%;
    color: #65726b;
  }

  .accordion-item {
    width: 100%;
    border-top: 1px dashed #65726b;
  }

  .accordion-item:last-child {
    border-bottom: 1px dashed #65726b;
  }

  .accordion-header {
    width: 100%;
  }

  .accordion-header.active {
    border-bottom: 1px solid #f0f0f0;
  }

  .accordion-button {
    position: relative;
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 30px;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.33;
    color: #65726b;
  }

  .accordion-button::before {
    content: '+';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
    font-size: 1.5rem;
  }

  .accordion-collapse {
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    transition: max-height 0.3s ease-out, visibility 0.3s ease-out;
  }

  .accordion-collapse.active {
    visibility: visible;
    transition: max-height 0.3s ease-out, visibility 0.3s ease-out;
  }

  .accordion-body {
    padding: 15px 30px;
  }

  .accordion-body > p:first-child {
    margin-top: 0;
  }

  .accordion-body > p:last-child {
    margin-bottom: 0;
  }

  ${media.md`
    .row {
      display: flex;
      flex-wrap: wrap; 
    }
    .col {
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
      min-height: 1px;
    }
  `}

  ${media.lg`
    margin-top: 100px;
  `}
`;

const Container = styled(PostContainer)``;

type Props = {
  post: Post;
};

const PostBody: React.FC<Props> = ({ post }) => {
  const richContentRef = useRef<HTMLDivElement | null>(null);

  // accordion
  useEffect(() => {
    if (!richContentRef.current) return;
    const buttons = richContentRef.current.querySelectorAll(
      '.accordion-button'
    );
    const collapses = richContentRef.current.querySelectorAll(
      '.accordion-collapse'
    );
    const headers = richContentRef.current.querySelectorAll(
      '.accordion-header'
    );

    buttons.forEach((button, index) =>
      button.addEventListener('click', event => {
        event.preventDefault();
        collapses[index].classList.toggle('active');
        headers[index].classList.toggle('active');
        let maxHeight = 0;

        if (collapses[index].classList.contains('active')) {
          maxHeight = collapses[index].scrollHeight;
        }
        collapses[index].setAttribute('style', `max-height: ${maxHeight}px`);
      })
    );
  }, []);

  return (
    <Section>
      <Container
        ref={richContentRef}
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
    </Section>
  );
};

export default PostBody;
