import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { BgMattressPurchaseInfo } from '../assets';
import { Carousel, Accordion, Container as GridContainer } from '../common';
import { Config, Breakpoints } from '../../styles/enums';
import { rem, mediaQueryUpto, sectionMarginStyle } from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';

const Section = styled.section`
  width: 100%;
  ${sectionMarginStyle}
`;

const Container = styled(GridContainer)`
  padding-left: 0;
  padding-right: 0;

  ${mediaQueryUpto(Breakpoints.XL, 'max-width: inherit;')}

  ${media.xl`
    padding-left: ${Config.CONTAINER_PADDING}px;
    padding-right: ${Config.CONTAINER_PADDING}px;
  `}
`;

const BgImage = styled(BgMattressPurchaseInfo)`
  position: relative;
  height: 325px;

  ${media.md`
    height: 400px;
  `}

  ${media.lg`
    height: 460px;
  `}

  ${media.xl`
    height: 520px;
  `}
`;

const BgOverlapText = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 15%;
  left: 0;

  ${media.md`
    text-align: left;
    top: 10%;
    left: 0;
    width: 50%;
    padding-left: ${Config.CONTAINER_PADDING + 15}px;
  `}

  ${media.lg`
    top: 15%;
  `}
`;

const Headline = styled.h2`
  margin-top: 0;
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: white;

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const InfoCarousel = styled(Carousel)`
  counter-reset: number;

  ${media.md`
    display: none;
  `}
`;

const Slide = styled.div`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  text-align: left;
  padding-left: ${Config.CONTAINER_PADDING / 2}px;
  padding-right: ${Config.CONTAINER_PADDING / 2}px;
  padding-top: 45px;

  &:first-child {
    padding-left: ${Config.CONTAINER_PADDING}px;
  }
`;

const InfoWrapper = styled.div`
  display: none;

  ${media.md`
    display: block;
  `}
`;

const InfoContainer = styled(Accordion)`
  counter-reset: number;
  font-family: var(--font-body);
  color: white;
`;

const InfoHeadline = styled.strong`
  font-weight: bold;
  font-size: ${rem(14)};
  text-transform: uppercase;
  letter-spacing: 1px;
  counter-increment: number;
  display: flex;
  align-items: center;

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}

  &:before {
    content: counter(number);
    background-color: var(--mantle);
    font-size: 70%;
    font-weight: normal;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
`;

const InfoBody = styled.p`
  font-weight: normal;
  font-size: ${rem(12)};
  line-height: 1.3125;

  ${media.md`
    padding-left: 30px;
  `}

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
  `}

  a {
    color: white;
  }
`;

const FootnoteSmallDevice = styled.small`
  font-family: var(--font-body);
  text-align: center;
  width: 100%;
  display: block;
  margin: 20px auto 0;
  font-size: ${rem(10)};

  ${media.md`
    display: none;
  `}
`;

const FootnoteLargeDevice = styled.small`
  display: block;
  width: 100%;
  color: white;
  font-family: var(--font-body);
  padding-left: 30px;
`;

const info = [
  {
    headline: '100-night Free Trial',
    body:
      'Try the Woosa / MYSA Mattress risk free for 100 nights. Love it or receive a full refund, no questions asked.',
  },
  {
    headline: 'Free Delivery',
    body: 'Free delivery within 1-3 days to your doorstep*',
  },
  {
    headline: 'Quality Assured Warranty',
    body: `Each Woosa Mattress comes with a 10-year warranty against <a href="${SiteUrls.OriginalMattressWarranty}">manufacturing and workmanship defects in the mattress</a>.`,
  },
  {
    headline: 'Local SG Support',
    body:
      'A company born and raised in Singapore. 5-star internal support that is responsive whenever you need it.',
  },
];

const MattressPurchaseInfoSection: React.FC = () => {
  return (
    <Section>
      <Container>
        <BgImage>
          <BgOverlapText>
            <Headline>With every purchase:</Headline>
            <InfoWrapper>
              <InfoContainer
                data={info.map(({ headline, body }, index) => {
                  return {
                    title: <InfoHeadline>{headline}</InfoHeadline>,
                    body: (
                      <InfoBody dangerouslySetInnerHTML={{ __html: body }} />
                    ),
                    isActive: index === 0,
                  };
                })}
              />
              <FootnoteLargeDevice>
                *Subject to stock availability and location accessibility.
              </FootnoteLargeDevice>
            </InfoWrapper>
          </BgOverlapText>
        </BgImage>
        <InfoCarousel
          props={{
            showStatus: false,
            showThumbs: false,
            centerMode: true,
            centerSlidePercentage: 80,
          }}
        >
          {info.map(({ headline, body }, k) => (
            <Slide key={k}>
              <InfoHeadline>{headline}</InfoHeadline>
              <InfoBody dangerouslySetInnerHTML={{ __html: body }} />
            </Slide>
          ))}
        </InfoCarousel>
        <FootnoteSmallDevice>
          *Subject to stock availability and location accessibility.
        </FootnoteSmallDevice>
      </Container>
    </Section>
  );
};

export default MattressPurchaseInfoSection;
