import React from 'react';

import { WarrantyIntro } from '../common';

const PillowWarrantyIntroSection: React.FC = () => {
  return (
    <WarrantyIntro headline="3 Year Limited Warranty">
      The Woosa Cloud Foam in the Woosa Pillow is rigorously tested to withstand
      regular use beyond our given warranty. However, we recommend changing your
      pillow every 3-5 years to ensure you’re getting the best possible
      experience from the Woosa Pillow. In the unlikely case that your Woosa
      Pillow Is met with any defect, we will replace or repair it without any
      charge to you. Your new Woosa Pillow is covered by a 3-Year Limited
      Warranty, dating from the time you purchased your new pillow from Woosa
      Sleep Pte Ltd (“Woosa”) or it’s authorized distributors. The details of
      this warranty, and some limitations, are provided in the complete Warranty
      Description below.
    </WarrantyIntro>
  );
};

export default PillowWarrantyIntroSection;
