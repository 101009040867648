import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Breakpoints } from '../../styles/enums';

type Props = {
  className?: string;
};

const ImgCompleteNightsPillow: React.FC<Props> = ({ className }) => {
  const { desktop, tablet, mobile } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "complete-nights-pillow-d.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tablet: file(relativePath: { eq: "complete-nights-pillow-t.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "complete-nights-pillow-m.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    mobile.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: ${Breakpoints.MD}px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: ${Breakpoints.LG}px)`,
    },
  ];

  return <Img className={className} fluid={sources} />;
};

export default ImgCompleteNightsPillow;
