import React from 'react';
import { media, Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import {
  PRODUCT_ORIGINAL_MATTRESS_ID,
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_MYSA_MATTRESS_ID,
} from 'gatsby-env-variables';

import { ProductCard, PriceCartStatus } from '../common';

import {
  ImgTheOriginalMattress,
  ImgTheMysaMattress,
  ImgTheTerraMattress,
} from '../../components/assets';
import { Product } from '../../types/product';
import { SiteUrls } from '../../utils/constants';

const Section = styled.div`
  padding-top: 80px;
  width: 550px;
  ${media.sm`
    width: auto;
  `}
`;

type Props = {
  selectedVariantType: string;
  products: {
    [key: string]: Product;
  };
};

const CompareMattressCardSection: React.FC<Props> = ({
  selectedVariantType,
  products,
}) => {
  const cards = [
    {
      product: products[PRODUCT_ORIGINAL_MATTRESS_ID],
      selectedVariantType: selectedVariantType,
      asset: <ImgTheOriginalMattress />,
      url: SiteUrls.OriginalMattress,
      title: 'The Original Mattress',
      body:
        'For medium-firm comfort. Made with our signature 4-layer combination of premium Sonocore® Latex and European-made Memory Foam.',
    },
    {
      product: products[PRODUCT_TERRA_MATTRESS_ID],
      selectedVariantType: selectedVariantType,
      asset: <ImgTheTerraMattress />,
      url: SiteUrls.TerraMattress,
      title: 'The Terra Mattress',
      body:
        'For extra-firm comfort. Made with double layers of 100% Natural Latex for max support and posture alignment.',
    },
    {
      product: products[PRODUCT_MYSA_MATTRESS_ID],
      selectedVariantType: selectedVariantType,
      asset: <ImgTheMysaMattress />,
      url: SiteUrls.MysaMattress,
      title: 'The Mysa Mattress',
      body:
        'For a medium-plush hotel-style feel. A luxurious sleeping surface made possible with our very own Woosa Cloud Foam.',
    },
  ];

  return (
    <Section>
      <Container className="container">
        <Row>
          {cards.map((card, key) => {
            return (
              <Col xs={4} sm={4} key={key}>
                <ProductCard {...card} />
              </Col>
            );
          })}
        </Row>
        <Row>
          {cards.map((card, key) => {
            return (
              <Col xs={4} sm={4} key={key}>
                <PriceCartStatus
                  selectedVariantType={card.selectedVariantType}
                  product={card.product}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};

export default CompareMattressCardSection;
