import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Rating from 'react-rating';

import { rem, flatButtonStyle, outlineButtonStyle } from '../../styles/utils';
import SvgStar from '../../static/images/star.svg';
import SvgStarOutline from '../../static/images/star-outline.svg';
import { Product } from '../../types/product';
import { ReviewApi } from '../../types/review';
import { createReview } from '../../utils/api';
import Modal from './custom-modal';

const InfoHeadline = styled.strong`
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
  font-size: ${rem(22)};
  letter-spacing: 1px;
  counter-increment: number;
`;

const ReviewButton = styled.button`
  ${flatButtonStyle()}
`;

const CloseButton = styled.button`
  margin-right: 20px;
  ${outlineButtonStyle('dark')}
`;

const PopupContainer = styled.div`
  padding: 20px;
  text-align: left;
  label {
    color: var(--font-secondary-color);
  }
`;

const PopupHeader = styled.div`
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 20px;
`;

const PopupFooter = styled.div`
  text-align: right;
`;

const Input = styled.input`
  min-width: 100%;
  width: 100%;
  padding: 0.375rem 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
`;

const InputArea = styled.textarea`
  min-width: 100%;
  width: 100%;
  padding: 0.375rem 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
`;

const RatingCol = styled(Col)`
  text-align: center;
  margin-bottom: 25px;
  svg {
    width: 40px;
    height: 40px;
  }
`;

type Props = {
  product: Product;
};

const ReviewPopupSection: React.FC<Props> = ({ product }) => {
  const [review, setReview] = useState<ReviewApi>({
    productId: product.id,
    rating: 0,
    email: '',
    name: '',
    content: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const onRate = (value: number) => {
    setReview({
      ...review,
      rating: value,
    });
  };

  const onReviewChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    key: 'name' | 'email' | 'content'
  ) => {
    review[key] = event.target.value;
    setReview({ ...review });
  };

  const onSubmit = async () => {
    if (isLoading) return;

    if (!review.productId || !review.content || !review.email || !review.name) {
      alert('All the fields are required!');
      return;
    }

    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        review.email
      )
    ) {
      alert('Invalid email!');
      return;
    }

    setIsLoading(true);

    const result = await createReview(review);

    if (!result) alert('Error occurred. Unable to submit review.');
    else alert('Thank you for letting us know your experience');

    setReview({
      productId: product.id,
      rating: 0,
      email: '',
      name: '',
      content: '',
    });
    setOpen(false);

    setIsLoading(false);
  };

  return (
    <>
      <ReviewButton type="button" onClick={() => setOpen(true)}>
        Write a Review
      </ReviewButton>
      <Modal
        className="review-modal-form"
        isActive={open}
        onClickClose={() => setOpen(false)}
      >
        <PopupContainer>
          <PopupHeader>
            <InfoHeadline>{product.name}</InfoHeadline>
          </PopupHeader>
          <div>
            <Row>
              <RatingCol>
                <label>Rating</label>
                <br />
                <Rating
                  emptySymbol={<SvgStarOutline />}
                  fullSymbol={<SvgStar />}
                  fractions={2}
                  onChange={e => onRate(e)}
                  initialRating={review.rating}
                />
              </RatingCol>
              <Col lg={6}>
                <label>Name</label>
                <Input
                  type="text"
                  onChange={e => onReviewChange(e, 'name')}
                  value={review.name}
                />
              </Col>
              <Col lg={6}>
                <label>Email</label>
                <Input
                  type="email"
                  onChange={e => onReviewChange(e, 'email')}
                  value={review.email}
                />
              </Col>

              <Col>
                <label>Review</label>
                <InputArea
                  rows={4}
                  onChange={e => onReviewChange(e, 'content')}
                  value={review.content}
                />
              </Col>
            </Row>

            <PopupFooter>
              <CloseButton type="button" onClick={() => setOpen(false)}>
                Close
              </CloseButton>
              <ReviewButton
                type="button"
                onClick={() => onSubmit()}
                disabled={isLoading}
              >
                Submit
              </ReviewButton>
            </PopupFooter>
          </div>
        </PopupContainer>
      </Modal>
    </>
  );
};

export default ReviewPopupSection;
