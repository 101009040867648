import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { rem, lh } from '../../styles/utils';
import { currencyFormat } from '../../utils';
import { CommonVariantTypes } from '../../utils/constants';
import { Product } from '../../types/product';
import { Link } from '../common';

const ItemsContainer = styled.div`
  background-color: #d6d6d9;
  margin-bottom: 30px;
`;

const ProductImage = styled.img`
  width: 100%;
`;

const ProductInfo = styled.div`
  padding: 0 20px 20px 20px;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  color: var(--firefly);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(32, 24)};
  margin-bottom: 20px;
`;

const SubVariantInfo = styled.div`
  display: flex;
`;

const ColourSelection = styled.div`
  margin-right: auto;
`;

const PriceLabel = styled.div`
  font-family: var(--font-body);
  color: var(--firefly);
  font-size: ${rem(16)};
`;

const ColourImg = styled.img`
  cursor: pointer;
  width: 24px;
  margin-right: 10px;
  &:last-child {
    marigin-right: 0;
  }
  &.selected {
    border: 2px solid #000;
    border-radius: 3px;
  }
`;

const ProductLink = styled(Link)`
  text-decoration: none;
`;

type Props = {
  product: Product;
  siteUrl: string;
};

type SubVariant = {
  assetId: string;
  image: string;
  thumbnail: string;
  selected: boolean;
};

const BedsheetCategoryItem: React.FC<Props> = ({
  siteUrl,
  product: { media, name, variants },
}) => {
  const [selectedThumbnail, setSelectedThumbnail] = useState(
    variants[0].thumbnail
  );
  const [subVariantColours, setSubVariantColours] = useState<SubVariant[]>([]);

  useEffect(() => {
    const newSubVariantColours: SubVariant[] = [];
    variants.map(v =>
      v.subVariants.map(s => {
        if (
          s.key === CommonVariantTypes.COLOUR &&
          !newSubVariantColours.find(sc => sc.assetId === s.assetId)
        ) {
          const newSubVariant: SubVariant = {
            assetId: s.assetId,
            image: s.image,
            thumbnail: v.thumbnail,
            selected: !newSubVariantColours.length,
          };
          newSubVariantColours.push(newSubVariant);
        }
      })
    );
    setSubVariantColours(newSubVariantColours);
  }, []);

  const handleSelectSubVariant = (s: SubVariant, idx: number) => {
    setSelectedThumbnail(s.thumbnail || media[0].path);
    subVariantColours.forEach((s: SubVariant) => {
      s.selected = false;
    });
    subVariantColours[idx].selected = true;
    setSubVariantColours([...subVariantColours]);
  };

  const amounts = variants.map(v => v.prices[0].amount);
  const minPrice = Math.min(...amounts);
  const maxPrice = Math.max(...amounts);

  return (
    <ItemsContainer>
      <ProductLink to={siteUrl}>
        <ProductImage src={selectedThumbnail} alt="" />
      </ProductLink>
      <ProductInfo>
        <Headline>{name}</Headline>
        <SubVariantInfo>
          <ColourSelection>
            {subVariantColours.map((s, idx) => (
              <ColourImg
                onClick={() => handleSelectSubVariant(s, idx)}
                key={idx}
                src={s.image}
                alt=""
                className={s.selected ? 'selected' : ''}
              />
            ))}
          </ColourSelection>
          <PriceLabel>
            From {currencyFormat(minPrice)}
            {minPrice === maxPrice ? '' : ` - ${currencyFormat(maxPrice)}`}
          </PriceLabel>
        </SubVariantInfo>
      </ProductInfo>
    </ItemsContainer>
  );
};

export default BedsheetCategoryItem;
