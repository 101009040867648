import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';

import WizardHeadline from './wizard-headline';
import WizardButton from './wizard-button';
import WizardContainer from './wizard-container';
import WizardEyebrow from './wizard-eyebrow';
import { FormInput, FormCheckbox, Spinner } from '../common/';
import { Address } from '../../types/order';
import {
  useCheckoutState,
  useCheckoutDispatch,
} from '../../hooks/checkout-context';
import { useAuthState } from '../../hooks/auth-context';
import { getAddressInfo } from '../../utils/api';

const Form = styled.form`
  width: 100%;
  position: relative;
`;

const FormBody = styled.div`
  margin-top: 40px;
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  background-color: var(--border-color);
  margin-bottom: 30px;
`;

type Props = {
  className?: string;
  index?: number;
};

type FormData = {
  shipping: Address;
  billing: Address;
};

const AddressForm: React.FC<Props> = ({ className, index }) => {
  const { register, handleSubmit, errors, clearErrors, setValue } = useForm<
    FormData
  >();
  const {
    sameAddress,
    shipping,
    billing,
    populateAddressForm,
  } = useCheckoutState();
  const { email, accessToken, firstName, lastName } = useAuthState();
  const dispatch = useCheckoutDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const mapAddressToFields = (
    address: Address,
    type: 'shipping' | 'billing'
  ) => {
    Object.keys(address).forEach(key => {
      setValue(`${type}.${key}`, address[key]);
    });
  };

  useEffect(() => {
    if (!populateAddressForm || !shipping || !billing) return;
    mapAddressToFields(shipping, 'shipping');
    mapAddressToFields(billing, 'billing');
    dispatch({
      type: 'UPDATE_ADDRESS',
      shipping,
      billing,
      populateAddressForm: false,
    });
  }, [populateAddressForm]);

  useEffect(() => {
    if (!accessToken) return;

    // loads from auth state
    if (firstName && lastName) {
      const authAddress = {
        firstName,
        lastName,
        email,
        contactNo: '',
        country: '',
        company: '',
        address: '',
        postalCode: '',
        notes: '',
      };
      mapAddressToFields(authAddress, 'shipping');
    }

    // loads from localstorage if data can be found
    if (shipping && shipping.postalCode) {
      mapAddressToFields(shipping, 'shipping');

      if (!sameAddress && billing && billing.postalCode) {
        mapAddressToFields(billing, 'billing');
      }
      return;
    }

    // else loads from API to get shipping information
    (async function () {
      setIsLoading(true);

      const addressInfo = await getAddressInfo(accessToken);

      setIsLoading(false);
      if (!addressInfo) return;

      dispatch({
        type: 'TOGGLE_SAME_ADDRESS',
        sameAddress: addressInfo.sameAddress,
      });

      mapAddressToFields(addressInfo.shipping, 'shipping');

      if (!addressInfo.sameAddress) {
        mapAddressToFields(addressInfo.billing, 'billing');
      }
    })();
  }, [accessToken]);

  const handleChangeSameAddress = () => {
    clearErrors('billing');
    dispatch({ type: 'TOGGLE_SAME_ADDRESS', sameAddress: !sameAddress });
  };

  const onSubmit = (data: FormData) => {
    if (Object.keys(errors).length > 0) return;
    const { shipping } = data;

    if (!shipping.email) {
      shipping.email = email;
    }

    const billing = sameAddress ? { ...shipping } : data.billing;

    if (!billing.email) {
      billing.email = email;
    }

    dispatch({
      type: 'UPDATE_ADDRESS',
      shipping,
      billing,
    });
    dispatch({ type: 'SET_ACTIVE_INDEX', activeIndex: (index || 0) + 1 });
  };

  const renderAddressFields = (type: 'shipping' | 'billing') => {
    return (
      <>
        <Col col={6}>
          <FormInput
            error={errors[type]?.firstName}
            label="First Name"
            variant="BOTTOM_LINE"
            inputRef={register({
              required: 'Required field',
            })}
            inputProps={{
              name: `${type}.firstName`,
            }}
          />
        </Col>
        <Col col={6}>
          <FormInput
            error={errors[type]?.lastName}
            label="Last Name"
            variant="BOTTOM_LINE"
            inputRef={register({
              required: 'Required field',
            })}
            inputProps={{
              name: `${type}.lastName`,
            }}
          />
        </Col>
        <Col col={6}>
          <FormInput
            error={errors[type]?.country}
            label="Country"
            variant="BOTTOM_LINE"
            inputRef={register({
              required: 'Required field',
            })}
            inputProps={{
              name: `${type}.country`,
            }}
          />
        </Col>
        <Col col={6}>
          <FormInput
            error={errors[type]?.company}
            label="Company"
            variant="BOTTOM_LINE"
            inputRef={register()}
            inputProps={{
              name: `${type}.company`,
            }}
          />
        </Col>
        <Col col={12}>
          <FormInput
            error={errors[type]?.address}
            label="Street address"
            variant="BOTTOM_LINE"
            inputRef={register({
              required: 'Required field',
            })}
            inputProps={{
              name: `${type}.address`,
            }}
          />
        </Col>
        <Col col={6}>
          <FormInput
            error={errors[type]?.postalCode}
            label="Postal code"
            variant="BOTTOM_LINE"
            inputRef={register({
              required: 'Required field',
            })}
            inputProps={{
              name: `${type}.postalCode`,
            }}
          />
        </Col>
        <Col col={6}>
          <FormInput
            error={errors[type]?.contactNo}
            label="Contact no"
            variant="BOTTOM_LINE"
            inputRef={register({
              required: 'Required field',
            })}
            inputProps={{
              name: `${type}.contactNo`,
            }}
          />
        </Col>
      </>
    );
  };

  return (
    <WizardContainer className={className}>
      <WizardHeadline data-num={(index || 0) + 1}>
        Shipping Address
      </WizardHeadline>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormCheckbox
          inputProps={{
            name: 'same-address',
            id: 'same-address',
            checked: sameAddress,
            onChange: handleChangeSameAddress,
          }}
          label="Shipping address same as Billing address"
        />
        <FormBody>
          <Row>
            {renderAddressFields('shipping')}
            <Col col={12}>
              <FormInput
                error={errors.shipping?.notes}
                label="Order notes (optional)"
                tag="textarea"
                inputRef={register()}
                textAreaProps={{
                  name: 'shipping.notes',
                  placeholder: 'Special delivery instructions etc.',
                  rows: 5,
                }}
              />
            </Col>
          </Row>
        </FormBody>
        {!sameAddress && (
          <>
            <Divider />
            <WizardEyebrow>Enter your Billing address</WizardEyebrow>
            <Row>{renderAddressFields('billing')}</Row>
          </>
        )}
        <WizardButton>Continue to Delivery</WizardButton>
        <Spinner isLoading={isLoading} />
      </Form>
    </WizardContainer>
  );
};

export default AddressForm;
