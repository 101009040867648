import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { ProductAccordionSection, IconExpander } from '../common';
import { Paragraph } from '../common/product-accordion-section';
import { Accordion } from '../../types/component';
import {
  ImgAboutMaterial1,
  ImgAboutMaterial2,
  ImgAboutMaterial3,
  ImgAboutAccordion,
} from '../assets';

const FlexAlignCenterCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const data: Accordion[] = [
  {
    title: 'Understanding our materials',
    body: (
      <>
        <Row>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgAboutMaterial1 />}
              title="Sonocore Latex"
              content={
                <Paragraph>
                  Highest quality latex available in the market made using
                  Sonocore technology. Unlike the traditional
                  &ldquo;baking&rdquo; process used in regular latex, Sonocore
                  Latex is microwaved for a perfect open cell consistency.
                  Superior in durability, comfort and support than traditional
                  Dunlop latex.
                </Paragraph>
              }
            />
          </Col>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgAboutMaterial2 />}
              title="Woosa Cloud Foam"
              content={
                <Paragraph>
                  A modified combination of Memory Foam and High Resiliency Foam
                  with a unique cell structure for added breathability. Soft and
                  fluffy while maintaining high levels of resilient support.
                </Paragraph>
              }
            />
          </Col>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgAboutMaterial3 />}
              title="Polyester Blended Fabric"
              content={
                <Paragraph>
                  {' '}
                  Polyester fibres mixed with stretchy rayon for increased
                  moisture wicking properties.
                </Paragraph>
              }
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    title: 'No springs, no uneven support',
    body: (
      <Row>
        <FlexAlignCenterCol xs={12} md={6}>
          <Paragraph>
            Our foam components react to the body’s weight and heat in a way
            that springs can never achieve. Imagine having a million cells
            distributing the weight evenly across your body, offering support at
            the most critical points - it’s a no brainer. Through our research,
            we also found that good quality foam mattresses often last longer
            than spring mattresses, and best eliminates motion transfer.
          </Paragraph>
        </FlexAlignCenterCol>
        <Col xs={12} md={6}>
          <ImgAboutAccordion />
        </Col>
      </Row>
    ),
  },
];

const AboutAccordionSection: React.FC = () => {
  return <ProductAccordionSection data={data} />;
};

export default AboutAccordionSection;
