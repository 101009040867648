import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';
import moment from 'moment';

import { rem } from '../../../styles/utils';
import PriceLabel from '../price-label';
import SvgDropdownArrow from '../../../static/images/dropdown-arrow.svg';
import { Props } from './';
import { renderStatus } from '../../../utils';
import ProductStatusLabel from '../product-status-label';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
`;

const Label = styled.label`
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: 1.2;
  margin-bottom: 10px;
  display: block;

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
    line-height: 1.3125;
  `}
`;

const Bar = styled.div`
  position: relative;
  font-weight: normal;
  font-size: ${rem(14)};
  display: flex;
  align-items: center;

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

const TypeLabel = styled.span``;

const DropdownArrow = styled(SvgDropdownArrow)`
  width: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

const List = styled.ul<{ isActive: boolean }>`
  will-change: max-height;
  position: absolute;
  padding: 0;
  margin: 0;
  max-height: ${({ isActive }) => (isActive ? '1000px' : 0)};
  overflow-y: hidden;
  transition: max-height 0.3s ease-in-out;
  top: 100%;
  left: 0;
  width: 100%;
`;

const Item = styled.li`
  display: flex;
  width: 100%;
  background: white;
  padding: 15px;
  border: 0.25px solid var(--celeste);
  border-top-width: 0;
  cursor: pointer;
`;

const SelectedItem = styled(Item)`
  border-top-width: 0.25px;
  position: relative;
  padding-right: 40px;
`;

const QuantityLabel = styled.p<{ isOutOfStock: boolean }>`
  color: ${({ isOutOfStock }) => (isOutOfStock ? 'red' : 'var(--leather)')};
  font-size: ${rem(10)};
  margin-bottom: 0;

  ${media.lg`
    font-size: ${rem(12)};
  `}
`;

export const ProductVariantDropdown: React.FC<Props> = ({
  className,
  label,
  variants,
  hideStatus,
  hidePrice,
  onChange,
  plusCoverProduct,
  section,
}) => {
  const [selected, setSelected] = useState(variants[0]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setSelected(variants[0]);
  }, []);

  return (
    <Container className={className}>
      <Row alignItems="center">
        {label && (
          <Col xl={4}>
            <Label>{label}</Label>
          </Col>
        )}
        <Col xl={label ? 8 : 12}>
          <Bar>
            <SelectedItem onClick={() => setActive(!active)}>
              <TypeLabel>{selected.type}</TypeLabel>
              {!hidePrice && selected.prices.length > 0 && (
                <PriceLabel
                  price={selected.prices[0]}
                  plusCoverPrice={
                    plusCoverProduct?.variants.find(
                      x => x.type == selected.type
                    )?.prices[0]
                  }
                />
              )}
              <DropdownArrow />
            </SelectedItem>
            <List className="product-variant-dropdown-list" isActive={active}>
              {variants
                .filter(opt => opt.type !== selected.type)
                .map((opt, key) => {
                  return (
                    <Item
                      key={key}
                      onClick={() => {
                        setSelected(opt);
                        if (typeof onChange === 'function') onChange(opt);
                        setActive(false);
                      }}
                    >
                      <TypeLabel>{opt.type}</TypeLabel>
                      {opt.prices.length > 0 && (
                        // <PriceLabel price={opt.prices[0]} />
                        <PriceLabel
                          price={opt.prices[0]}
                          plusCoverPrice={
                            plusCoverProduct?.variants.find(
                              x => x.type == opt.type
                            )?.prices[0]
                          }
                        />
                      )}
                    </Item>
                  );
                })}
            </List>
          </Bar>
        </Col>
      </Row>
      {!hideStatus && (
        <Row>
          <Col className="status-label" xl={8} xlOffset={4}>
            <ProductStatusLabel selectedVariant={selected} />
          </Col>
        </Row>
      )}
    </Container>
  );
};
