import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { FieldError } from 'react-hook-form';

import { rem } from '../../styles/utils';
import FormLabel from './form-label';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  margin-bottom: 15px;
  font-size: ${rem(12)};

  ${media.lg`
    font-size: ${rem(16)};
    margin-bottom: 20px;
  `}
`;

const Input = styled.input<{
  error: boolean;
  variant?: 'DEFAULT' | 'BOTTOM_LINE';
}>`
  border: 1px solid ${({ error }) => (error ? 'red' : 'var(--quill-gray)')};
  width: 100%;
  padding: ${({ variant }) => (variant === 'BOTTOM_LINE' ? '5px' : '15px')};
  border-top: ${({ variant }) => (variant === 'BOTTOM_LINE' ? 0 : 1)};
  border-left: ${({ variant }) => (variant === 'BOTTOM_LINE' ? 0 : 1)};
  border-right: ${({ variant }) => (variant === 'BOTTOM_LINE' ? 0 : 1)};
`;

const Message = styled.span`
  display: block;
  margin-top: 5px;
  color: red;
  font-size: 80%;

  ${media.lg`
    margin-top: 10px;
  `}
`;

type Props = {
  className?: string;
  variant?: 'DEFAULT' | 'BOTTOM_LINE';
  label?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  textAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  inputRef?: (instance: HTMLInputElement) => void;
  error?: FieldError;
  tag?: 'textarea' | 'input';
};

const FormInput: React.FC<Props> = ({
  className,
  inputProps,
  textAreaProps,
  error,
  label,
  variant,
  tag,
  inputRef,
}) => {
  return (
    <Container className={className}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        as={tag || 'input'}
        ref={inputRef}
        variant={variant}
        error={error ? true : false}
        {...inputProps}
        {...textAreaProps}
      />
      {error && <Message>{error.message}</Message>}
    </Container>
  );
};

export default FormInput;
