import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import DefaultContainer from '../container';
import Video from '../video';
import { lh, rem } from '../../../styles/utils';

const Section = styled.section`
  color: var(--font-secondary-color);
  font-family: var(--font-body);
  width: 100%;
  height: auto;
`;

const Container = styled(DefaultContainer)`
  ${media.lg`
    max-width: calc(864px + 90px);
  `}
`;

const VideoBackground = styled(Video)`
  object-fit: cover;
  width: 100%;
`;

const Hero = styled.div`
  position: relative;
  height: 30vh;
  width: 100%;

  ${media.md`
    height: 50vh;
  `}

  ${media.xl`
    height: 100vh;
  `}

  .hero-intro-bg {
    height: 100%;
  }
`;

const Content = styled.div`
  padding: 60px 0;

  ${media.lg`
    padding: 100px 0;
  `}

  ${media.lg`
    padding: 180px 0;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 15px;

  ${media.md`
    font-size: ${rem(32)};
  `}

  ${media.lg`
    font-size: ${rem(36)};
  `}
`;

const Body = styled.div`
  font-size: ${rem(14)};
  line-height: 1.28;

  ${media.lg`
    font-size: ${rem(18)};
  `}
`;

const OverTextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-align: center;
  position: absolute;
  top: 0;
`;

const OverText = styled(Headline)`
  color: white;
  margin: 0;
`;

const Eyebrow = styled.h3`
  font-size: ${rem(14)};
  line-height: ${lh(21, 14)};
  color: var(--santa-fe);
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 700;
`;

export type Props = {
  className?: string;
  BackgroundImage?: React.FC<{ className?: string }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backgroundVideoSrc?: any;
  overText?: React.ReactNode;
  headline?: React.ReactNode;
  body: React.ReactNode;
  eyebrow?: React.ReactNode;
};

const HeroIntroV2: React.FC<Props> = ({
  className,
  BackgroundImage,
  backgroundVideoSrc,
  overText,
  headline,
  body,
  eyebrow,
}) => {
  return (
    <Section className={className}>
      <Hero className="hero-intro">
        {BackgroundImage && <BackgroundImage className="hero-intro-bg" />}
        {backgroundVideoSrc && (
          <VideoBackground className="hero-intro-bg" src={backgroundVideoSrc} />
        )}
        {overText && (
          <OverTextContainer>
            <OverText>{overText}</OverText>
          </OverTextContainer>
        )}
      </Hero>
      {(eyebrow || headline || body) && (
        <Container>
          <Content className="hero-intro-content">
            {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
            {headline && <Headline>{headline}</Headline>}
            <Body>{body}</Body>
          </Content>
        </Container>
      )}
    </Section>
  );
};

export default HeroIntroV2;
