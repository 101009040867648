import React from 'react';
import { ProductOrderItem } from '../../../types/order';
import { Product, ProductAddOnConfig, Variant } from '../../../types/product';
import ProductAddOnV1 from './product-add-on-v1';
import ProductAddOnV2 from './product-add-on-v2';

export type Props = {
  version?: 'v1' | 'v2';
  id: string;
  label: string;
  defaultText: string;
  selectedVariant?: Variant;
  products?: Product[];
  config?: ProductAddOnConfig;
  onChange?: (product: ProductOrderItem | null) => void;
};

const ProductAddOn: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <ProductAddOnV2 {...props} />;
    default:
      return <ProductAddOnV1 {...props} />;
  }
};

export default ProductAddOn;
