import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Container } from '../common';
import { rem } from '../../styles/utils';
import { BgSeresSilkDuvetIntro } from '../assets';

const Section = styled.section`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.md`
    flex-direction: column;
  `}
`;

const Info = styled.div`
  text-align: center;

  ${media.md`
    margin-bottom: 40px;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-size: ${rem(24)};
  font-weight: normal;

  ${media.md`
    margin-top: 0;
  `}

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const Body = styled.p`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-size: ${rem(12)};
  line-height: 1.3;
  font-weight: normal;

  ${media.md`
    max-width: 645px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 375px;

  ${media.md`
  height: 500px;
`}

  ${media.xl`
  height: 810px;
`}
`;

const Img = styled(BgSeresSilkDuvetIntro)`
  width: 100%;
  height: 100%;
`;

const SeresSilkDuvetIntroSection: React.FC = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Info>
            <Headline>Superior comfort all night</Headline>
            <Body>
              The Seres Silk Duvet is, itself, a beautifully crafted product. It
              has no clumping or stitching except at the ends to secure the
              outer shell. The seamless design, which drapes perfectly around
              the body, provides a comfortable, flowy feel. The robust, inner
              batting structure requires no adhesives to keep it in place, so
              our duvet remains 100% natural.
            </Body>
          </Info>
        </Wrapper>
      </Container>
      <ImgContainer>
        <Img />
      </ImgContainer>
    </Section>
  );
};

export default SeresSilkDuvetIntroSection;
