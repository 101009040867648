import React from 'react';
import { PRODUCT_MYSA_MATTRESS_ID } from 'gatsby-env-variables';

import { ProductReviewHeroSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const MysaMattressReviewHeroSection: React.FC = () => {
  return (
    <ProductReviewHeroSection
      productId={PRODUCT_MYSA_MATTRESS_ID}
      allReviewsUrl={SiteUrls.MysaMattressReview}
    />
  );
};

export default MysaMattressReviewHeroSection;
