import React from 'react';
import styled, { css } from 'styled-components';

type ScreenProps = {
  isActive: boolean;
};

const Screen = styled.div<ScreenProps>`
  will-change: opacity, visibility;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-in-out 0s, visibility 0.5s ease-in-out 0s;
  position: fixed;

  ${({ isActive }) =>
    isActive
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          z-index: -99999;
          opacity: 0;
          visibility: hidden;
        `}
`;

type Props = {
  isActive: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
};

const Overlay: React.FC<Props> = ({ isActive, className, children }) => {
  return (
    <Screen className={className} isActive={isActive}>
      {children}
    </Screen>
  );
};

export default Overlay;
