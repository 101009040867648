import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';

import { ImgFlatCardPlaceholder } from '../assets';
import {
  rem,
  sectionMarginStyle,
  outlineButtonStyle,
  lh,
} from '../../styles/utils';
import {
  Container,
  AppointmentFormModal,
  StoreAddress,
  StoreHours,
  StoreContact,
} from '../common';
import SvgAppointmentLocation from '../../static/images/appointment-location-icon.svg';
import SvgAppointmentPhone from '../../static/images/appointment-phone-icon.svg';
import SvgAppointmentStore from '../../static/images/appointment-store-icon.svg';

const Section = styled.section`
  width: 100%;
  ${sectionMarginStyle}
`;

const Img = styled(ImgFlatCardPlaceholder)`
  width: 100%;

  ${media.md`
    max-width: 300px;
  `}

  ${media.lg`
    max-width: 500px;
  `}

  ${media.xxl`
    max-width: 600px;
  `}
`;

const Card = styled.div`
  background-color: var(--pearl-bush);
  padding: 20px;

  ${media.md`
    display: flex;
    flex-direction: row-reverse;
    padding: 60px 40px;
  `}

  ${media.xl`
    padding-left: 80px;
    padding-right: 60px;
  `}
`;

const CopyContainer = styled.div`
  text-align: center;

  ${media.md`
    max-width: 400px;
    text-align: left;
    margin-right: auto;
    padding-right: 60px;
  `}

  ${media.lg`
    max-width: 655px;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(20)};
  color: var(--font-primary-color);
  margin-bottom: 5px;

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const Paragraph = styled.p`
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(14)};
  color: var(--font-primary-color);
  line-height: 1.2;
  margin-bottom: 30px;

  ${media.lg`
    font-size: ${rem(16)};
    font-size: ${lh(21, 16)};
  `}

  ${media.xl`
    margin-bottom: 40px;
  `}
`;

const StoreInfoContainer = styled.div`
  font-family: var(--font-body);
  margin-bottom: 10px;
  text-align: left;
  font-size: ${rem(14)};
  line-height: ${lh(18, 14)};

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const StoreInfoItem = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const StoreInfoIcon = styled.i`
  margin-right: 12px;
`;

const Address = styled(StoreAddress)`
  font-family: var(--font-body);
  text-decoration: none;
  display: block;
  font-weight: normal;
  line-height: 1.4;
  color: var(--xanadu);
`;

const Contact = styled(StoreContact)`
  color: var(--xanadu);
  text-decoration: none;
`;

const OpeningHours = styled(StoreHours)`
  color: var(--xanadu);
`;

const Button = styled.button`
  cursor: pointer;
  ${outlineButtonStyle('dark')}
  padding: 15px 40px;
`;

type Props = {
  description?: ReactElement;
};

const AppointmentSection: React.FC<Props> = ({ description }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const defaultDesc =
    'At Woosa, we make your mattress shopping experience as easy as crawling into bed. Buy your mattress online or head down to our mattress showroom in Singapore to view and test our products first-hand.';

  return (
    <>
      <Section>
        <Container>
          <Card>
            <Img />
            <CopyContainer>
              <Headline>Visit The Woosa Dreamery</Headline>
              <Paragraph>{description ?? defaultDesc}</Paragraph>
              <StoreInfoContainer>
                <Row>
                  <Col lg={6}>
                    <StoreInfoItem>
                      <StoreInfoIcon>
                        <SvgAppointmentLocation />
                      </StoreInfoIcon>
                      <Address lines={3} />
                    </StoreInfoItem>
                    <StoreInfoItem>
                      <StoreInfoIcon>
                        <SvgAppointmentPhone />
                      </StoreInfoIcon>
                      <Contact />
                    </StoreInfoItem>
                  </Col>
                  <Col lg={6}>
                    <StoreInfoItem>
                      <StoreInfoIcon>
                        <SvgAppointmentStore />
                      </StoreInfoIcon>
                      <OpeningHours />
                    </StoreInfoItem>
                  </Col>
                </Row>
              </StoreInfoContainer>
              {/* <Button onClick={() => setDisplayModal(true)}>
                Book a slot now
              </Button> */}
            </CopyContainer>
          </Card>
        </Container>
      </Section>
      {displayModal && (
        <AppointmentFormModal
          display
          onClickClose={() => setDisplayModal(false)}
        />
      )}
    </>
  );
};

export default AppointmentSection;
