import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import { Benefit } from '../../types/component';
import {
  ImgSeresSilkDuvetBenefit1,
  ImgSeresSilkDuvetBenefit2,
  ImgSeresSilkDuvetBenefit3,
  ImgSeresSilkDuvetBenefit4,
} from '../assets';

const Section = styled(ProductBenefitSection)`
  background-color: var(--pearl-bush);
`;

const benefits: Benefit[] = [
  {
    img: <ImgSeresSilkDuvetBenefit1 />,
    eyebrow: "FROM THE WORLD'S CITY OF SILK",
    headline: 'Fit for royalty',
    body: (
      <>
        Sensuous and mysterious, silk has a fabled origin going 5,000 years back
        in time to ancient China. Throughout the Tang, Ming and Qing dynasties,
        silk was the choice material of the nobles. One of the lightest, softest
        and most breathable fabrics to exist, silk is synonymous with both
        beauty and luxury.
        <br />
        <br />
        Our Seres Silk Duvet honours the ancient practice of sericulture, where
        silkworms are ethically-farmed to produce silk.
      </>
    ),
  },
  {
    img: <ImgSeresSilkDuvetBenefit2 />,
    eyebrow: 'TEMPERATURE REGULATING',
    headline: 'No more stuffy nights',
    body:
      "Our duvet's long silk strands intertwine to form millions of tiny open pockets for air flow. The molecular structure of silk keeps you temperature-neutral: cool on warm nights and snug on cold nights. Silk also wicks away moisture quickly. It can absorb at least 30% of its body weight in moisture without getting damp.",
  },
  {
    img: <ImgSeresSilkDuvetBenefit3 />,
    eyebrow: '100% NATURAL MATERIALS, HYPOALLERGENIC',
    headline: 'Say good bye to skin irritation',
    body:
      'Silk is a 100% natural fibre with a protein that makes it hypoallergenic and resistant to bacteria, microbes and mould build-up. This helps to reduce allergy reactions and is beneficial for those with sensitive or itchy skin. We also use 100% natural and hypoallergenic cotton for the shell.',
  },
  {
    img: <ImgSeresSilkDuvetBenefit4 />,
    eyebrow: 'FINELY CRAFTED',
    headline: 'Made to last',
    body:
      'The long threads in Mulberry silk are incredibly strong as they have a natural tendency to adhere and bond with itself. It is less prone to tearing, pulling, or bunching up over time. With proper care, The Seres Silk Duvet is able to last up to 15 years.',
  },
];

const SeresSilkDuvetBenefitSection: React.FC = () => (
  <Section
    benefits={benefits}
    blob={{ color1: '#dfcac3', color2: 'white' }}
    waveLineColor="#cac1b7"
  />
);

export default SeresSilkDuvetBenefitSection;
