import React from 'react';

import HeroIntroV1, { Props as DefaultPropsV1 } from './hero-intro';
import HeroIntroV2, { Props as DefaultPropsV2 } from './hero-intro-v2';

type PropsV1 = {
  version: 'v1';
} & DefaultPropsV1;

type PropsV2 = {
  version: 'v2';
} & DefaultPropsV2;

type Props = PropsV1 | PropsV2;

const HeroIntro: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <HeroIntroV2 {...props} />;
    default:
      return <HeroIntroV1 {...props} />;
  }
};

export default HeroIntro;
