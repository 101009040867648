import React from 'react';

import { ProductAccordionSection, ProductSizeSelectorSection } from '../common';
import { Paragraph, Subtitle } from '../common/product-accordion-section';
import { Accordion } from '../../types/component';
import { HardCodeValues, SiteUrls } from '../../utils/constants';
import {
  ImgStorageBaseDimensionSingle,
  ImgStorageBaseDimensionSuperSingle,
  ImgStorageBaseDimensionQueen,
  ImgStorageBaseDimensionKing,
} from '../assets';
import { Link } from 'gatsby';

const bufferContent = (
  <>
    <br />
    <br />
    <small>
      <sup>*</sup>Buffer additional 1-2cm for manufacturing tolerances
    </small>
  </>
);

const data: Accordion[] = [
  {
    title: 'Dimensions',
    body: (
      <>
        <ProductSizeSelectorSection
          sizes={[
            {
              size: 'Single',
              image: <ImgStorageBaseDimensionSingle />,
              content: (
                <>
                  <b>Headboard height:</b> <br />
                  <b>All Designs:</b> 120cm <br />
                  <br />
                  <b>Headboard Thickness:</b> 12cm <br />
                  <b>Sideframe Thickness:</b> 7cm <br />
                  <b>Total Width:</b> 109cm <br />
                  <b>Total Length:</b> 211cm <br />
                  <b>Sideframe Height:</b> 42cm
                  {bufferContent}
                </>
              ),
            },
            {
              size: 'Super Single',
              image: <ImgStorageBaseDimensionSuperSingle />,
              content: (
                <>
                  <b>Headboard height:</b>
                  <br />
                  <b>All Designs:</b> 120cm
                  <br />
                  <br />
                  <b>Headboard Thickness:</b> 12cm
                  <br />
                  <b>Sideframe Thickness:</b> 7cm
                  <br />
                  <b>Total Width:</b> 126cm
                  <br />
                  <b>Total Length:</b> 211cm
                  <br />
                  <b>Sideframe Height:</b> 42cm
                  <br />
                  <b>Internal Storage Depth Capacity:</b> 30cm
                  {bufferContent}
                </>
              ),
            },
            {
              size: 'Queen',
              image: <ImgStorageBaseDimensionQueen />,
              content: (
                <>
                  <b>Headboard height:</b>
                  <br />
                  <b>All Designs:</b> 120cm
                  <br />
                  <br />
                  <b>Headboard Thickness:</b> 12cm
                  <br />
                  <b>Sideframe Thickness:</b> 7cm
                  <br />
                  <b>Total Width:</b> 170cm
                  <br />
                  <b>Total Length:</b> 211cm
                  <br />
                  <b>Sideframe Height:</b> 42cm
                  <br />
                  <br />
                  <b>Internal Storage Depth Capacity:</b> 30cm
                  {bufferContent}
                </>
              ),
            },
            {
              size: 'King',
              image: <ImgStorageBaseDimensionKing />,
              content: (
                <>
                  <b>Headboard height:</b>
                  <br />
                  <b>All Designs:</b> 120cm
                  <br />
                  <br />
                  <b>Headboard Thickness:</b> 12cm
                  <br />
                  <b>Sideframe Thickness:</b> 7cm
                  <br />
                  <b>Total Width:</b> 201cm
                  <br />
                  <b>Total Length:</b> 211cm
                  <br />
                  <b>Sideframe Height:</b> 42cm
                  <br />
                  <b>Internal Storage Depth Capacity:</b> 30cm
                  {bufferContent}
                </>
              ),
            },
          ]}
        />
      </>
    ),
  },
  {
    title: 'Shipping, Return, Warranty & Cancellation',
    body: (
      <>
        <Subtitle>Shipping</Subtitle>
        <Paragraph>
          All products are shipped free to your doorstep and inclusive of
          installation. For delivery locations that are not lift accessible,
          there will be another $10 per level per item for up to a{' '}
          <b> maximum of 3 storeys / flight of stairs</b>. For more than 3
          storeys / flight of stairs – will be quoted separately.
        </Paragraph>
        <Paragraph>
          <b>Breakdown of items</b>
          <br />
          <br />
          Storage Bed – 4x
        </Paragraph>
        <Paragraph>
          Note: Carry-up items are required only for the above items,
          accessories do not need to be added in. Read more{' '}
          <Link to={SiteUrls.Shipping} target="_blank" rel="noreferrer">
            here
          </Link>
          .
        </Paragraph>
        <Subtitle>Return</Subtitle>
        <Paragraph>
          A restocking fee of $300 will be charged for return of one bed base
          per order, per address within the first 30 days of receiving your
          item. Read more{' '}
          <a href={SiteUrls.FreeTrial} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </Paragraph>
        <Subtitle>Warranty</Subtitle>
        <Paragraph>
          The Woosa Storage Bed Base comes with a 2-year full warranty on
          manufacturing defects, and moving parts, including the hydraulics, and
          the bed slats.
          {/* Read more{' '}
          <a href={SiteUrls.BedFrameWarranty} target="_blank" rel="noreferrer">
            here
          </a> */}
          .
        </Paragraph>
        <Subtitle>Cancellation</Subtitle>
        <Paragraph>
          Cancellations are free within 7 days from the order date, which is
          defined as the date where either partial or full payment has been made
          for the order. Read more{' '}
          <a href={SiteUrls.FreeTrial} target="_blank" rel="noreferrer">
            here
          </a>
          .
          <br />
          <br />
          Example: If you place your order on a Saturday this week, you will
          need to cancel your order before the end of Friday next week, assuming
          there is no Public Holiday in between. Read more here.
          <br />
          <br />A cancellation fee of $300 is chargeable for orders cancelled
          after 7 days from the order date.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Frequently Asked Questions',
    body: (
      <>
        <Subtitle>
          How does the Denmark-made SUSPA Hydraulic System in Woosa’s Storage
          Bed Base compare to other hydraulic lift systems?
        </Subtitle>
        <Paragraph>
          The Denmark-made SUSPA Hydraulic System in Woosa’s Storage Bed Base is
          renowned for its ease of use, automatically rising with a simple pull
          of a latch and closing effortlessly. With a bed frame constructed with
          the most solid of woods, its automatic lifting mechanism adds to its
          appeal of being the ultimate bedroom solution by delivering a
          convenient and hassle-free experience. You can visit{' '}
          <Link to={SiteUrls.Home}>Woosa’s mattress shop in Singapore</Link> for
          a hands-on experience and personalised assistance.
        </Paragraph>
        <Subtitle>
          How does the Woosa Storage Base's spacious 30cm depth of storage
          compare to other storage bases on the market?
        </Subtitle>
        <Paragraph>
          The Woosa Storage Bed Frame’s Base stands out with its spacious 30cm
          storage depth, providing ample space for large items such as travel
          luggage. This generous storage capacity surpasses many other platform
          bed storage bases on the market, offering a convenient solution for
          organising and storing belongings while promoting a clutter-free
          bedroom environment.
        </Paragraph>

        <Subtitle>
          What is the weight capacity of the Woosa Storage Bed Base?
        </Subtitle>
        <Paragraph>
          The Woosa Storage Base is designed to support an evenly applied load
          of up to 500 kg, including the weight of the mattress. This robust
          capacity makes it a durable and reliable choice for your bedding
          needs.
        </Paragraph>

        <Subtitle>
          How does the installation process of the Woosa Storage Bed Base work?
        </Subtitle>
        <Paragraph>
          The installation of the Woosa Storage Bed Base is a hassle-free
          process. When you buy from us, your Storage Bed Base is shipped
          directly to your doorstep, including installation. This ensures a
          smooth and easy setup process, saving you time and effort.
          <br />
          <br />
          Please note that additional fees may apply if your location doesn't
          have lift access.
        </Paragraph>

        <Subtitle>
          Are there any safety mechanisms in place to prevent accidental
          lowering of the hydraulic storage bed?
        </Subtitle>
        <Paragraph>
          The Woosa Storage Bed Base incorporates an efficient safety mechanism
          within its design, employing a Denmark-made SUSPA hydraulic system
          that provides a safe and secure lifting process. It rises
          automatically with a gentle pull on the latch, ensuring controlled
          movement. To lower the base, it requires deliberate action and
          smoothly closes without sudden drops, minimising the risk of
          accidental lowering and potential injuries.
        </Paragraph>
        <a href={SiteUrls.Faq} target="_blank" rel="noreferrer">
          Read All FAQs
        </a>
      </>
    ),
  },
];

const StorageBaseAccordionSection: React.FC = () => {
  return <ProductAccordionSection data={data} initCollapseAll />;
};

export default StorageBaseAccordionSection;
