import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { media } from 'styled-bootstrap-grid';

import { Config } from './enums';
import { zIndexes } from './utils';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  :root {
    --siam: #565855;
    --firefly: #0D1E2A;
    --celeste: #D6D8D2;
    --desert-storm: #F5F5F3;
    --desert-storm-darker: #E9E9E7;
    --desert-storm-light: #ECE7E0;
    --cape-cod: #2F3131;
    --nile-blue: #173649;
    --mantle: #8B9C8C;
    --pearl-bush: #EDE7DF;
    --nandor: #565856;
    --leather: #A26E55;
    --corduroy: #62736B;
    --panache: #F0F8F3;
    --catskill-white: #EDF5F6;
    --iron: #E4E5E6;
    --sirocco: #6A7A75;
    --bitter: #86877B;
    --harp: #F5FAF8;
    --athens-gray: #E6E7EA;
    --quill-gray: #DBDBDA;
    --hero-grey: #F5F6F6;
    --jumbo: #808184;
    --wild-sand: #F5F4F4;
    --tasman: #D5DBD5;
    --spring-wood: #F1ECE5;
    --tuscany: #B26729;
    --cararra: #E8E8E2;
    --black-haze: #EFF1F0;
    --gunsmoke: #838D87;
    --bali-hai: #7795AE;
    --harvest-gold: #DFBA72;
    --big-stone: #111E29;
    --mystic: #E6ECF1;
    --gull-gray: #9FADB9;
    --cornflower-blue: #65726B;
    --mongoose: #B69985;
    --mandy: #E76262;
    --pastel-green: #62E777;
    --santa-fe: #B77659;
    --xanadu: #717D77;
    --darkgrey: #F0ECE6;
    --lightgrey: #FAFAFA;
    --original: #4F7E75;
    --terra: #C5885D;
    --mysa: #354255;
    --blue-gray: #E6EEEF;
    --mercury: #E6E6E6;
    
    --font-base: ${Config.BASE_FONT_SIZE}px;
    --font-header: 'Orpheus', 'serif';
    --font-body: 'acumin-pro','Helvetica Neue','sans-serif';
    --font-primary-color: var(--firefly);
    --font-secondary-color: var(--corduroy);
    --font-nav-color: var(--cape-cod);
    --font-cart-color: var(--sirocco);
    --yellow-star: var(--harvest-gold);

    --border-color: var(--celeste);
  }

  html, body {
    overflow-x: hidden;
  }

  body {
    font-size: var(--font-base);
    font-family: var(---font-body);
    padding-top: ${Config.XS_HEADER_HEIGHT}px;

    ${media.lg`
      padding-top: 0;
    `}

    &.disabled {
      height: 100vh;
      overflow-y: hidden;
    }
  }

  .whatsapp-fixed {
    position: fixed;
    bottom: 60px;
    right: 10px;
  }

  ${zIndexes([
    '.product-variant-dropdown-list',
    '.whatsapp-fixed',
    '.product-fixed-bottom-bar',
    '.navbar-overlay',
    '.navbar',
    '.nav-brand',
    '.nav-cart',
    '.nav-auth',
    '.header',
    '.announcement-bar',
    '.lightbox-gallery',
    '.review-modal-form',
    '.appointment-form-modal',
    '.cart-drawer-overlay',
    '.cart-drawer',
    '.promo-bundle-modal',
    '.order-summary-register-modal',
    '.support-review-info-modal',
    '.support-redeem-form-modal',
    '.product-size-modal',
    '.temp-address-modal',
    '.dashboard-order-modal',
    '.custom-popup',
  ])}
`;

export default GlobalStyle;
