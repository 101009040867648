import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { FormRadio, FormLabel } from '../common';
import { useCartState } from '../../hooks/cart-context';
import {
  useCheckoutState,
  useCheckoutDispatch,
} from '../../hooks/checkout-context';
import { generateProductRefKey } from '../../utils';

const Container = styled.div`
  background-color: var(--black-haze);
  padding: 20px 15px;
  border-radius: 10px;
  margin-bottom: 20px;

  ${media.md`
		padding: 15px;
	`}
`;

const Label = styled(FormLabel)`
  color: var(--gunsmoke);
  margin-bottom: 15px;
`;

const InputGroup = styled.div``;

const Radio = styled(FormRadio)`
  margin-bottom: 12px;

  label {
    color: var(--gunsmoke);
  }

  input {
    background: white;
  }

  ${media.md`
		margin-bottom: 10px;
	`}

  &:last-child {
    margin-bottom: 0;
  }
`;

const defaultOptions = {
  all: {
    value: 'all',
    label: 'Ship all items',
  },
  mixedAll: {
    value: 'all',
    label: (
      <>
        Ship <b>all items together</b> at the earliest available date
      </>
    ),
  },
  ready: {
    value: 'ready',
    label: (
      <>
        Ship <b>available</b> items first
      </>
    ),
  },
  unsure: {
    value: 'unsure',
    label: "I am unsure of my delivery date, I'll update at a later time",
  },
  custom: {
    value: 'unsure',
    label:
      'Our team will contact you within 24 hours to verify your customised item delivery schedule',
  },
};

type OptionState =
  | 'ALL_READY'
  | 'MIXED'
  | 'MIXED_PREORDER'
  | 'MIXED_CUSTOM'
  | 'ONLY_CUSTOM'
  | 'ONLY_PREORDER';

const DeliveryOption: React.FC = () => {
  const [optionState, setOptionState] = useState<OptionState>('ALL_READY');
  const { deliveryOption } = useCheckoutState();
  const checkoutDispatch = useCheckoutDispatch();
  const { products } = useCartState();
  let title = '';
  const options = [];

  useEffect(() => {
    const newOptionState = checkOptionState();
    setOptionState(newOptionState);

    if (
      newOptionState === 'ONLY_CUSTOM' ||
      newOptionState === 'MIXED_CUSTOM' ||
      newOptionState === 'MIXED'
    ) {
      handleRadioInputChanged('unsure');
    }
  }, [products]);

  const checkOptionState = (): OptionState => {
    const allReady = products.every(
      p => p.status === 'Ready to Ship' && p.statusType !== 'Custom'
    );
    if (allReady) return 'ALL_READY';

    const onlyPreOrder = products.every(
      p => p.status === 'Pre-Order' && p.statusType !== 'Custom'
    );
    if (onlyPreOrder) return 'ONLY_PREORDER';

    const hasCustom = products.some(p => p.statusType === 'Custom');
    if (hasCustom) {
      const customRefKeys = products
        .filter(p => p.statusType === 'Custom')
        .map(p => generateProductRefKey(p));
      const refProductKeys = products
        .filter(p =>
          p.refKeys ? p.refKeys.some(k => customRefKeys.includes(k)) : false
        )
        .map(p => generateProductRefKey(p));
      const removedRefProducts = products.filter(
        p => !refProductKeys.includes(generateProductRefKey(p))
      );
      const onlyCustom = removedRefProducts.every(
        p => p.statusType === 'Custom'
      );
      if (onlyCustom) return 'ONLY_CUSTOM';
    }

    const hasPreOrder = products.some(
      p => p.status === 'Pre-Order' && p.statusType !== 'Custom'
    );

    if (!hasPreOrder && hasCustom) return 'MIXED_CUSTOM';
    if (hasPreOrder && !hasCustom) return 'MIXED_PREORDER';
    return 'MIXED';
  };

  const handleRadioInputChanged = (value: string) => {
    if (value === 'all' || value === 'ready' || value === 'unsure') {
      checkoutDispatch({
        type: 'UPDATE_DELIVERY_OPTION',
        deliveryOption: value,
      });
    }
  };

  switch (optionState) {
    case 'ALL_READY':
      options.push(...[defaultOptions.all, defaultOptions.unsure]);
      title = 'Choose delivery option';
      break;
    case 'ONLY_PREORDER':
      options.push(...[defaultOptions.all, defaultOptions.unsure]);
      title = 'You have a pre-order item in your cart!';
      break;
    case 'MIXED_PREORDER':
      options.push(
        ...[
          defaultOptions.mixedAll,
          defaultOptions.ready,
          defaultOptions.unsure,
        ]
      );
      title = 'You have a pre-order item in your cart!';
      break;
    case 'ONLY_CUSTOM':
    case 'MIXED_CUSTOM':
    case 'MIXED':
      options.push(defaultOptions.custom);
      title = 'You have a customised item in your cart!';
      break;
  }

  return (
    <Container>
      <Label>{title}</Label>
      <InputGroup>
        {options.map(({ value, label }) => (
          <Radio
            key={value}
            inputProps={{
              id: `delivery-slot-option-${value}`,
              name: 'delivery-slot-option',
              value,
              checked: deliveryOption === value,
              onChange: event => handleRadioInputChanged(event.target.value),
            }}
            label={label}
          />
        ))}
      </InputGroup>
    </Container>
  );
};

export default DeliveryOption;
