import React from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';

import { ProductIconInfo, Variant } from '../../types/product';
import { lh, rem } from '../../styles/utils';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
  position: relative;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: 1.2;
  margin-bottom: 10px;
  display: block;

  ${media.lg`
  font-size: ${rem(14)};
`}

  ${media.xl`
  font-size: ${rem(16)};
  line-height: 1.3125;
`}
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
`;

const Item = styled.li`
  margin-right: 10px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  background-color: transparent;
  width: 44px;
  height: 44px;
  border-radius: 2.5px;
  margin-bottom: 3.5px;
  position: relative;
  border: 1px solid var(--firefly);
  padding: 3px;
`;

const IconWrapper = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const IconQty = styled.small`
  position: absolute;
  bottom: 1px;
  font-size: ${rem(10)};
  line-height: 1.2;
  right: 1px;
`;

const IconLabel = styled.label`
  font-size: ${rem(12)};
  line-height: ${lh(12, 12)};
  width: 44px;
  display: block;
  color: var(--cornflower-blue);
`;

type Props = {
  className?: string;
  label: string;
  iconInfoList: ProductIconInfo[];
  selectedVariant?: Variant;
};

const ProductInfoIconList: React.FC<Props> = ({
  className,
  label,
  iconInfoList,
  selectedVariant,
}) => {
  return (
    <Container className={className}>
      <Row>
        <Col xl={4}>
          <Label>{label}</Label>
        </Col>
        <Col xl={8}>
          <List>
            {iconInfoList.map((iconInfo, index) => {
              let qty = iconInfo.qty;
              let name = iconInfo.name;

              if (
                selectedVariant &&
                iconInfo.variantInfo &&
                iconInfo.variantInfo[selectedVariant.type]
              ) {
                const variantInfo = iconInfo.variantInfo[selectedVariant.type];
                qty = variantInfo.qty || qty;
                name = variantInfo.name || name;
              }

              return (
                <Item key={index}>
                  <IconContainer>
                    <IconWrapper>
                      {iconInfo.icon}
                      <IconQty>x{qty}</IconQty>
                    </IconWrapper>
                  </IconContainer>
                  <IconLabel>{name}</IconLabel>
                </Item>
              );
            })}
          </List>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductInfoIconList;
