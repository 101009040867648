import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { useCartDispatch } from '../../hooks/cart-context';
import SvgCart from '../../static/images/cart.svg';

const Button = styled.button`
  background: transparent;
  border: 0;
  margin-left: auto;
  cursor: pointer;
`;

const Cart = styled(SvgCart)`
  width: 23px;

  ${media.lg`
    width: 38px;
  `}
`;

type Props = {
  className?: string;
};

const CartButton: React.FC<Props> = ({ className }) => {
  const dispatch = useCartDispatch();

  return (
    <Button
      className={className}
      onClick={() => dispatch({ type: 'TOGGLE_DRAWER' })}
    >
      <Cart />
    </Button>
  );
};

export default CartButton;
