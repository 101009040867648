import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Benefit } from '../../types/component';
import ProductBenefitRow from './product-benefit-row/product-benefit-row';
import WaveLineAnimation from './wave-line-animation';
import BlobAnimation from './blob-animation';
import { rem, sectionMarginStyle } from '../../styles/utils';
import { Config } from '../../styles/enums';
import Container from './container';

const Section = styled.section`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-size: ${rem(12)};
  position: relative;
  overflow: hidden;

  ${media.md`
    padding: 40px 0;
  `}

  ${media.xl`
    padding: 80px 0;
    font-size: ${rem(18)};
  `}

  ${sectionMarginStyle}
`;

const Wrapper = styled(Container)`
  padding: 0;

  ${media.md`
    padding-left: ${Config.CONTAINER_PADDING}px;
    padding-right: ${Config.CONTAINER_PADDING}px;
  `}
`;

const Wave = styled.div`
  position: absolute;
  display: none;

  ${media.md`
    display: block;
    right: -20%;
    bottom: -25%;
    max-width: 70%;
    transform: rotate(-30deg);
  `}

  ${media.xl`
    right: -20%;
    bottom: -15%;
  `}
`;

const Blob = styled.div`
  position: absolute;
  display: none;

  &:first-child {
    ${media.md`
      display: block;
      width: 45%;
      right: -15%;
      top: 0;
    `}

    ${media.lg`
      width: 40%;
    `}
  }

  &:nth-child(2) {
    ${media.md`
      display: block;
      width: 45%;
      left: -15%;
      top: 25%;
    `}

    ${media.lg`
      width: 40%;
    `}
  }
`;

type Props = {
  className?: string;
  blob?: {
    color1: string;
    color2: string;
  };
  waveLineColor?: string;
  children: React.ReactNode | React.ReactNodeArray;
};

const AnimatedBackgroundWrapper: React.FC<Props> = ({
  children,
  className,
  blob,
  waveLineColor,
}) => {
  return (
    <Section className={className}>
      <Blob>
        <BlobAnimation color={blob?.color1} />
      </Blob>
      <Blob>
        <BlobAnimation color={blob?.color2} />
      </Blob>
      <Wave>
        <WaveLineAnimation numOfWaves={8} color={waveLineColor} />
      </Wave>
      <Wrapper>{children}</Wrapper>
    </Section>
  );
};

export default AnimatedBackgroundWrapper;
