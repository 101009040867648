import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Price } from '../../types/product';
import { currencyFormat } from '../../utils';

const UnitPrice = styled.span<{ hasSale?: boolean; defaultColor?: string }>`
  font-family: var(--font-body);
  color: ${({ hasSale, defaultColor }) =>
    hasSale ? 'red' : defaultColor || 'var(--font-primary-color)'};
  margin-left: auto;
`;

const OriginalPrice = styled.del`
  color: var(--font-primary-color);
  margin-left: 5px;
  font-size: 80%;
`;

type Props = {
  price?: Price;
  defaultColor?: string;
  className?: string;
  children?: React.ReactNode;
  plusCoverPrice?: Price | null;
};

const PriceLabel: React.FC<Props> = ({
  price,
  defaultColor,
  className,
  children,
  plusCoverPrice,
}) => {
  const classes = [className];

  if (!price) {
    classes.push('unit-price--default');
    return (
      <UnitPrice className={classNames(...classes)} defaultColor={defaultColor}>
        {children || currencyFormat(0)}
      </UnitPrice>
    );
  }

  const amount =
    parseFloat(price.amount.toString()) +
    parseFloat(plusCoverPrice ? plusCoverPrice.amount.toString() : '0');
  const compareAmount = price.compareAmount
    ? parseFloat(price.compareAmount.toString()) +
      parseFloat(
        plusCoverPrice && plusCoverPrice.compareAmount
          ? plusCoverPrice.compareAmount.toString()
          : '0'
      )
    : 0;
  const hasSale = compareAmount > amount;

  return (
    <UnitPrice
      className={classNames(...classes, {
        'unit-price--has-sale': hasSale,
        'unit-price--default': !hasSale,
      })}
      hasSale={hasSale}
      defaultColor={defaultColor}
    >
      {currencyFormat(amount)}
      {hasSale && (
        <OriginalPrice>{currencyFormat(compareAmount)}</OriginalPrice>
      )}
    </UnitPrice>
  );
};

export default PriceLabel;
