import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { useInView } from 'react-intersection-observer';

import { rem, sectionMarginStyle } from '../../styles/utils';
import {
  ImgWoosaPillowCloudFoam,
  ImgWoosaPillowCover,
  ImgWoosaPillowCoverMaterial,
} from '../assets';
import { Carousel, Container as GridContainer, Badge } from '../common';

const Section = styled.section`
  width: 100%;
  font-family: var(--font-body);
  font-weight: normal;
  color: var(--font-secondary-color);
  ${sectionMarginStyle}
`;

const Container = styled(GridContainer)`
  position: relative;
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -0.4em;
`;

const UpgradeBadge = styled(Badge)`
  background-color: var(--mongoose);
  color: white;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  color: var(--font-primary-color);
  font-size: ${rem(24)};
  text-align: center;

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const Description = styled.p`
  font-size: ${rem(12)};
  line-height: 1.3;
  text-align: center;

  ${media.md`
    font-size: ${rem(14)};
  `}

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    max-width: 670px;
    margin: 0 auto;
    font-size: ${rem(18)};
  `}
`;

const Pillow = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const PillowCarousel = styled(Carousel)`
  counter-reset: number;

  .carousel {
    padding-bottom: 0;

    &-slider {
      overflow: visible;
    }

    .slider-wrapper {
      overflow: visible;
    }
  }

  ${media.md`
    display: none;
  `}
`;

const PillowInfoContainer = styled.div`
  counter-reset: number;
  display: none;

  ${media.md`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `}
`;

const PillowInfo = styled.div<{ inView: boolean }>`
  text-align: left;
  counter-increment: number;
  position: relative;
  padding-left: 35px;

  &:before {
    content: counter(number);
    background-color: var(--mantle);
    font-size: ${rem(16)};
    font-weight: normal;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
  }

  ${media.md`
    position: absolute;
    max-width: 150px;
    opacity: ${({ inView }) => (inView ? 1 : 0)};
    transform: translateY(${({ inView }) => (inView ? 0 : 20)}px);

    &:first-child {
      bottom: -10%;
      left: 0;
      transition: opacity 0.5s 1s ease-out, transform 0.5s 1s ease-out;
    }

    &:last-child {
      right: 0;
      top: 50%;
      transition: opacity 0.5s 1.2s ease-out, transform 0.5s 1.2s ease-out;
    }
  `}

  ${media.lg`
    max-width: 220px;

    &:last-child {
      top: 60%;
    }
  `}

  ${media.xl`
    max-width: 250px;

    &:first-child {
      bottom: initial;
      top: 40%;
      left: 8%;
    }

    &:last-child {
      top: initial;
      bottom: 5%;
      right: 12%;
    }
  `}

  @media (min-width: 1200px) and (max-width: 1440px) {
    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }
`;

const Title = styled.strong`
  font-weight: bold;
  font-size: ${rem(12)};
  text-transform: uppercase;
  line-height: 1.3;

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}
`;

const Body = styled.p`
  font-size: ${rem(12)};
  line-height: 1.3;

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}
`;

const Cover = styled.div<{ inView: boolean }>`
  margin-top: -80px;
  width: 100%;
  transform: translateY(${({ inView }) => (inView ? 60 : 100)}%);
  position: relative;
  z-index: 1;
  transition: transform 0.5s ease-out;

  ${media.md`
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -120px;
  `}

  ${media.lg`
    max-width: 500px;
    margin-top: -150px;
  `}

  ${media.xl`
    max-width: 600px;
    margin-top: -180px;
  `}
`;

const Foam = styled.div`
  width: 100%;

  ${media.md`
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.lg`
    max-width: 500px;
  `}

  ${media.xl`
    max-width: 600px;
  `}
`;

const ZoomLens = styled.div<{ inView: boolean }>`
  position: absolute;
  z-index: 2;
  width: 80px;
  border-radius: 50%;
  border: 2px solid white;
  bottom: 0;
  left: -20px;
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transition: opacity 0.5s 0.5s ease-out;

  ${media.md`
    width: 100px;
  `}

  ${media.lg`
    width: 140px;
  `}

  ${media.xl`
    width: 180px;
  `}
`;

const data = [
  {
    title: 'Italian-made Microtencel Fabric',
    body:
      'A luxuriously soft cover that has excellent heat dissipation and moisture-wicking properties.',
  },
  {
    title: 'Woosa cloud foam',
    body:
      'Revolutionary foam developed in-house that is plush, supportive and does not lose its shape over time.',
  },
];

const PillowLayerSection: React.FC = () => {
  const [ref, inView] = useInView({ threshold: 0.7, triggerOnce: true });

  const renderPillowInfo = () => {
    return data.map(({ title, body }, key) => (
      <PillowInfo key={key} inView={inView}>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </PillowInfo>
    ));
  };

  return (
    <Section>
      <Container>
        <BadgeWrapper>
          <UpgradeBadge>Now upgraded</UpgradeBadge>
        </BadgeWrapper>
        <Headline>The pillow of your dreams</Headline>
        <Description>
          Woosa Sleep's revolutionary cloud foam delivers all the qualities one
          could ever ask for in a pillow - the softness and plushness
          reminiscent of a down pillow, combined with memory foam's supportive
          and contouring nature and the bouncy and durable characteristic of
          latex pillows. The Woosa Pillow is the epitome of perfection when
          finding the ideal pillow for front, back, and side sleepers.
        </Description>
        <Pillow ref={ref}>
          <Cover inView={inView}>
            <ImgWoosaPillowCover />
            <ZoomLens inView={inView}>
              <ImgWoosaPillowCoverMaterial />
            </ZoomLens>
          </Cover>
          <Foam>
            <ImgWoosaPillowCloudFoam />
          </Foam>
        </Pillow>
        <PillowCarousel
          props={{
            showThumbs: false,
            showIndicators: false,
            showStatus: false,
            centerMode: true,
            centerSlidePercentage: 80,
          }}
        >
          {renderPillowInfo()}
        </PillowCarousel>
        <PillowInfoContainer>{renderPillowInfo()}</PillowInfoContainer>
      </Container>
    </Section>
  );
};

export default PillowLayerSection;
