import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Accordion } from '../../types/component';
import { rem } from '../../styles/utils';

export const Headline = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: normal;
  margin: 5px 0;
  font-size: ${rem(12)};
  ${media.lg`
    margin: 0;
  `}
`;

const Section = styled.div`
  padding: 20px 0;
`;

const Img = styled.div`
  min-width: 85px;
  height: auto;
  margin-right: 10px;
  display: none;
  ${media.lg`
    display: block;
  `}
`;

const ImgResponsive = styled.div`
  width: 100%;
  margin-bottom: 3px;
  display: block;
  ${media.lg`
    display: none;
  `}
`;

const Content = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 5px;
  border-bottom: 1px dashed var(--border-color);
`;

type Props = {
  data: Accordion[];
  resImg: JSX.Element;
};

const ConstructionContent: React.FC<Props> = ({ data, resImg }) => {
  return (
    <Section>
      <ImgResponsive>{resImg}</ImgResponsive>
      {data.map(({ title, img }, index) => (
        <Content key={index}>
          <Img>{img}</Img>
          <Headline>{title}</Headline>
        </Content>
      ))}
    </Section>
  );
};

export default ConstructionContent;
