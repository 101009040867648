import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import CustomModal from '../custom-modal';
import { ProductItem, ProductVariantItem } from './types';
import { rem } from '../../../styles/utils';

const Modal = styled(CustomModal)`
  .modal-container {
    ${media.lg`
    max-width: 800px;
  `}
  }
`;

const Image = styled.img`
  width: 100%;
  display: none;

  &.active {
    display: block;
  }
`;

const Item = styled.div`
  font-weight: 400;
  font-size: ${rem(18)};
  line-height; 1.16; 
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0 10px;

  &.active, 
  &:hover {
    color: var(--corduroy);
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 10px 0;
  padding: 0;
  display: flex;
  justify-content: center;

  ${Item} {
    margin-right: 10px;
  }
`;

type Props = {
  isActive: boolean;
  onClickClose?: () => void;
  item?: ProductItem;
};

const ProductSizeModal: React.FC<Props> = ({
  isActive,
  onClickClose,
  item,
}) => {
  const [activeItem, setActiveItem] = useState<
    ProductVariantItem | undefined
  >();

  useEffect(() => {
    setActiveItem(undefined);
    if (item && item.variants && item.variants.length > 0) {
      setActiveItem(item.variants[0]);
    }
  }, [item]);

  const itemActiveClass = (item: ProductVariantItem) => {
    return activeItem && activeItem.name === item.name ? 'active' : '';
  };

  return (
    <Modal
      className="product-size-modal"
      isActive={isActive}
      backdrop="allowClose"
      onClickClose={onClickClose}
    >
      {item && (
        <>
          {item.variants.map(item => (
            <Image
              key={item.name}
              className={itemActiveClass(item)}
              src={item.image}
            />
          ))}
          <List>
            {item.variants.map(item => (
              <Item
                className={itemActiveClass(item)}
                key={item.name}
                onClick={() => setActiveItem(item)}
              >
                {item.name}
              </Item>
            ))}
          </List>
        </>
      )}
    </Modal>
  );
};

export default ProductSizeModal;
