import React from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { lh, rem } from '../../../styles/utils';
import { Benefit } from '../../../types/component';
import ProductBenefitRow from '../product-benefit-row';
import GridContainer from '../container';
import HelpTooltip from '../help-tooltip';
import HelpSVG from '../../../static/images/help-v2.svg';

const Section = styled.section`
  background-color: var(--mystic);
  font-family: var(--font-body);
  padding: 48px 0;

  ${media.lg`
    padding: 64px 0;
  `}
`;

const Container = styled(GridContainer)`
  ${media.xs`
    width: 100%;
    padding: 0;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(28.8, 24)};
  text-align: center;
  margin-bottom: 10px;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};
  `}
`;

const HeaderMobileWrapper = styled.div`
  text-align: center;

  ${media.lg`
    display: none;
  `}
`;

const Subtitle = styled.h3`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height: ${lh(14.4, 12)};
  color: var(--xanadu);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  ${media.lg`
    font-size: ${rem(14)};
    line-height: ${lh(21, 14)};
  `}

  ${HeaderMobileWrapper} & {
    justify-content: center;
  }
`;

const Tooltip = styled(HelpTooltip)`
  margin-left: 6px;
`;

const HelpIcon = styled(HelpSVG)``;

export type Props = {
  className?: string;
  headline: React.ReactNode;
  subtitle?: React.ReactNode;
  subtitleTooltip?: {
    title: React.ReactNode;
    description: React.ReactNode;
  };
  benefits: Benefit[];
};

const ProductBenefitSection: React.FC<Props> = ({
  benefits,
  headline,
  subtitle,
  subtitleTooltip,
  className,
}) => {
  return (
    <Section className={className}>
      <Container>
        <Headline>{headline}</Headline>
        <Subtitle>
          {subtitle && <>{subtitle} </>}
          {subtitleTooltip && (
            <Tooltip
              id="subtitle-tooltip"
              headline={subtitleTooltip.title}
              description={subtitleTooltip.description}
              helpIcon={<HelpIcon />}
            />
          )}
        </Subtitle>
        {benefits.map((benefit, key) => (
          <ProductBenefitRow version="v2" benefit={benefit} key={key} />
        ))}
      </Container>
    </Section>
  );
};

export default ProductBenefitSection;
