import React from 'react';

import { IconGridSection } from '../common';
import { IconGrid } from '../../types/component';
import SvgFreeTrialIcon from '../../static/images/night-icon.svg';
import SvgFreeShippingIcon from '../../static/images/trunk-icon.svg';
import SvgQualityAssuredIcon from '../../static/images/medal-icon.svg';
import SvgLocalSgSupport from '../../static/images/chat-icon.svg';
import SvgFreeTrialIconV2 from '../../static/images/icon-free-trial.svg';
import SvgFreeShippingIconV2 from '../../static/images/icon-free-shipping.svg';
import SvgQualityAssuredIconV2 from '../../static/images/icon-warranty.svg';
import SvgLocalSgSupportV2 from '../../static/images/icon-support.svg';

const data: { [key: string]: IconGrid[] } = {
  v1: [
    {
      icon: <SvgFreeTrialIcon />,
      title: 'The Woosa Sleep trial',
      body:
        'Try our Mattresses risk-free for 30 nights. Love it or receive a full refund, no questions asked.',
    },
    {
      icon: <SvgFreeShippingIcon />,
      title: 'Free shipping ',
      body:
        'Leave the heavy lifting to us. Delivery and installations are free.',
    },
    {
      icon: <SvgQualityAssuredIcon />,
      title: 'Quality assured warranty',
      body:
        'All Woosa Adjustable Base come with a 5-year warranty so you can be assured and sleep peacefully every night.',
    },
    {
      icon: <SvgLocalSgSupport />,
      title: 'Local singapore support',
      body: 'Need to get in touch? Contact our local Singapore team.',
    },
  ],
  v2: [
    {
      icon: <SvgFreeTrialIconV2 />,
      title: 'The Woosa Sleep Trial',
      body:
        'Try our Mattresses risk-free for 30 nights. Love it or receive a full refund, no questions asked.',
    },
    {
      icon: <SvgFreeShippingIconV2 />,
      title: 'Free Shipping',
      body:
        'Get free delivery within 1-3 days to your doorstep when you buy your bed and mattress online, or at our showroom in Singapore.',
    },
    {
      icon: <SvgQualityAssuredIconV2 />,
      title: 'Quality Assured Warranty',
      body:
        'Each Woosa Mattress comes with a 10-year warranty against manufacturing and workmanship defects in the mattress.',
    },
    {
      icon: <SvgLocalSgSupportV2 />,
      title: 'Local Singapore Support',
      body:
        'A company born and raised in Singapore. 5-star internal support that is responsive whenever you need it.',
    },
  ],
};

type Props = {
  version?: 'v1' | 'v2';
};

const WoosaPromiseIconGridSection: React.FC<Props> = ({ version }) => (
  <IconGridSection
    version={version || 'v1'}
    grid={data[version || 'v1']}
    size={{ xs: 6, md: 3 }}
  />
);

export default WoosaPromiseIconGridSection;
