import React from 'react';

import { LateralSection } from '../common';
import { BgPillowLateral } from '../assets';

const PillowLateralSection: React.FC = () => {
  return (
    <LateralSection
      BgSection={BgPillowLateral}
      headline="It’s time to revolutionise your sleep."
      cta={{ text: 'Choose a size' }}
    />
  );
};

export default PillowLateralSection;
