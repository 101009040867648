import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { rem } from '../../../styles/utils';

const Container = styled.div`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height; 1.16; 
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  span {
    margin: 5px 0;
  }
`;

const ImageContainer = styled.div`
  border: 1px solid transparent;
  border-radius: 3px;
  width: 90px;
  height: 90px;

  &:not(.unselectable) {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }

  &.selected,
  &:not(.unselectable, .disabled):hover {
    border: 1px solid var(--border-color);
    padding: 3px;
  }

  &.xs {
    width: 18px;
    height: 18px;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }

    &.selected {
      padding: 1px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    img {
      filter: grayscale(100%);
    }
  }
`;

type Props = {
  selected?: boolean;
  onClick?: () => void;
  image: string;
  name: string;
  className?: string;
};

const ProductSizeTile: React.FC<Props> = ({
  onClick,
  selected,
  image,
  name,
  className,
}) => {
  return (
    <Container className={className} onClick={onClick}>
      <ImageContainer
        className={classnames({
          selected,
        })}
      >
        <img src={image} alt="" />
      </ImageContainer>
      <span>{name}</span>
    </Container>
  );
};

export default ProductSizeTile;
