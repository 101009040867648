import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { media, Container } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';

const plusIcon = css`
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: var(--cape-cod);
  }

  &:before {
    height: 2px;
    left: 0;
    top: 50%;
    width: 15px;
    transform: translateY(-50%);
  }

  &:after {
    height: 15px;
    width: 2px;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const bodyFont = css`
  font-size: ${rem(12)};
  line-height: 1.3;
  color: var(--font-secondary-color);

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}
`;

export const Headline = styled.h2`
  font-family: var(--font-header);
  color: var(--font-secondary-color);
  font-weight: normal;
  margin: 0;
  font-size: ${rem(12)};
`;

export const Subtitle = styled.h2`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  margin-top: 8px;
  margin-bottom: 0;
  font-size: ${rem(14)};
`;

export const ListItem = styled.li`
  font-weight: normal;
  position: relative;
  padding-left: 15px;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  ${bodyFont}
  ${plusIcon}
`;

const Section = styled.section`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: normal;
  margin-bottom: 20px;
`;

const AccordionList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Header = styled.div`
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
`;

const Title = styled.span`
  color: var(--cape-cod);
  font-weight: 700;
  font-size: ${rem(16)};
  padding-left: 25px;
  position: relative;

  ${media.lg`
    font-size: ${rem(21)};
  `}

  ${plusIcon}
`;

const Body = styled.div`
  will-change: max-height, visibility;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.3s ease-out, visibility 0.3s ease-out;

  &.active {
    visibility: visible;
    transition: max-height 0.3s ease-out, visibility 0.3s ease-out;
  }
`;

type Props = {
  title: string;
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  initCollapseAll?: boolean;
};

const AccordionSection: React.FC<Props> = ({
  title,
  children,
  className,
  initCollapseAll,
}) => {
  const accordionRef = useRef<HTMLUListElement>(null);

  const toggle = () => {
    if (accordionRef.current !== null) {
      const current = accordionRef.current.querySelector('.accordion-body');
      if (!current) return;

      const isActive = !current.classList.contains('active');
      if (isActive) {
        current.classList.add('active');
        current.setAttribute(`style`, `max-height: ${current?.scrollHeight}px`);
      } else {
        current.classList.remove('active');
        current.removeAttribute('style');
      }
    }
  };

  useEffect(() => {
    if (!initCollapseAll) {
      const initTimer = setTimeout(() => toggle(), 500);
      return () => clearTimeout(initTimer);
    }
  }, []);

  return (
    <Section className={className}>
      <AccordionList ref={accordionRef}>
        <li>
          <Header role="button" onClick={() => toggle()}>
            <Container>
              <Title>{title}</Title>
            </Container>
          </Header>
          <Body className="accordion-body">{children}</Body>
        </li>
      </AccordionList>
    </Section>
  );
};

export default AccordionSection;
