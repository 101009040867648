import React from 'react';
import styled from 'styled-components';

import { SimpleSplitSection } from '../common';
import {
  Wrapper,
  LeftTitle,
  fontStyle,
  PlusIconList,
  PlusIconListItem,
} from '../common/simple-split-section';

const Container = styled(Wrapper)``;

const Row = styled(SimpleSplitSection)`
  margin-top: 40px;
`;

const Paragraph = styled.p`
  ${fontStyle}
  margin-top: 0;
`;

const SeresSilkDuvetWarrantyContentSection: React.FC = () => {
  const data = [
    {
      title: 'Warranty Description',
      content: (
        <Paragraph>
          This warranty is valid only for the original purchaser from the
          original purchase date of the original Seres Silk Duvet purchased from
          Woosa and is non-transferable. If the original purchaser sells or
          otherwise relinquishes ownership of the pillow to another, the
          subsequent owner accepts the product “as is” and “with all faults.”
          Original proof of purchase (including date) will be required to make a
          warranty claim. Please retain a copy of your invoice as proof of
          purchase.
        </Paragraph>
      ),
    },
    {
      title: 'What does the Limited Warranty Cover?',
      content: (
        <Paragraph>
          Any physical flaws due to faulty worksmanship or materials, which
          causes the materials or stiches to split rip or otherwise fail despite
          proper handling as listed out in the care instructions provided
        </Paragraph>
      ),
    },
    {
      title: 'What does this Limited Warranty NOT Cover?',
      content: (
        <PlusIconList>
          <PlusIconListItem>
            Normal wear and tear (including piling) of the Seres Silk Duvet and
            it’s inner silk filling.
          </PlusIconListItem>
          <PlusIconListItem>Comfort preference.</PlusIconListItem>
          <PlusIconListItem>Comfort preference.</PlusIconListItem>
          <PlusIconListItem>
            Damage caused by physical abuse or aggressive handling including but
            not limited to, burns, cuts, tears, liquid damage, or stains on the
            Duvet or the inner silk material.
          </PlusIconListItem>
          <PlusIconListItem>
            Damage caused by improper washing of the Duvet.
          </PlusIconListItem>
          <PlusIconListItem>
            Damage associated with commercial usage or use beyond its intended
            household purpose.
          </PlusIconListItem>
          <PlusIconListItem>
            Product sold by resellers who are not authorized retailers. Product
            sold “as-is”, “preconditioned”, “reconditioned”, “used”, “comfort
            return”, “returned”, “previously owned”, or any other similar
            wording indicating that the product is not “new” or of “first
            quality”, or has previously been purchased or used by another
            consumer.
          </PlusIconListItem>
        </PlusIconList>
      ),
    },
  ];

  return (
    <Container>
      {data.map(({ title, content }, i) => (
        <Row key={i} left={<LeftTitle>{title}</LeftTitle>} right={content} />
      ))}
    </Container>
  );
};

export default SeresSilkDuvetWarrantyContentSection;
