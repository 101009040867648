import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Container } from '../common';
import { rem } from '../../styles/utils';

const Section = styled.section`
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${media.lg`
    min-height: 60vh;
  `}
`;

const StatusCode = styled.h1`
  font-family: var(--font-header);
  font-size: ${rem(32)};
  color: var(--font-primary-color);

  ${media.md`
    font-size: ${rem(36)};
  `}

  ${media.lg`
    font-size: ${rem(48)};
  `}
`;

const Message = styled.p`
  font-family: var(--font-body);
  font-size: ${rem(16)};
  color: var(--font-secondary-color);

  ${media.md`
    font-size: ${rem(18)};
  `}

  ${media.lg`
    font-size: ${rem(20)};
  `}
`;

type Props = {
  code?: string;
  message?: string;
  className?: string;
};

const Error: React.FC<Props> = ({ code, message, className }) => {
  return (
    <Section className={className}>
      <Container>
        {code && <StatusCode>{code}</StatusCode>}
        {message && <Message>{message}</Message>}
      </Container>
    </Section>
  );
};

export default Error;
