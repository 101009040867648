import React from 'react';
import styled from 'styled-components';

import { LateralSection } from '../../common';
import { BgMattressLateralV2 } from '../../assets';
import { lh, rem } from '../../../styles/utils';
import { media } from 'styled-bootstrap-grid';

const Section = styled(LateralSection)`
  height: 600px !important;

  h2 {
    font-size: ${rem(42)} !important;
    line-height: ${lh(50.4, 42)} !important;
  }

  .lateral-wrapper {
    ${media.xs`
      align-items: start;    
    `}
  }
`;

const MattressLateralSectionV2: React.FC = () => {
  return (
    <Section
      BgSection={BgMattressLateralV2}
      headline="It’s time to revolutionise your sleep."
      disableOverlay
    />
  );
};

export default MattressLateralSectionV2;
