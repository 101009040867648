import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Container, WaveBlobAnimationSection } from '../common';
import { rem } from '../../styles/utils';
import { ImgBedFrameSharingTheBed } from '../assets';

const Section = styled(WaveBlobAnimationSection)`
  min-height: 475px;
  color: white;
  text-align: center;
  padding-top: 40px;

  ${media.lg`
    min-height: 500px;
  `}

  ${media.xl`
    min-height: 680px;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const Body = styled.p`
  font-family: var(--font-body);
  font-weight: normal;
  font-size: ${rem(12)};
  line-height: 1.3;
  margin-bottom: 20px;

  ${media.md`
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  `}

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}
`;

const Img = styled(ImgBedFrameSharingTheBed)``;

const SharingTheBedSection: React.FC = () => {
  return (
    <Section>
      <Container>
        <Headline>Sharing the Bed?</Headline>
        <Body>
          The split king adjustable bed frame supports 2 single mattresses for a
          split sleep setup with 2 remotes. Simply select the ‘Split King’
          option when checking&nbsp;out!
        </Body>
        <Img />
      </Container>
    </Section>
  );
};

export default SharingTheBedSection;
