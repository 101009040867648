import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';
import moment from 'moment';

import ReviewPopupSection from './review-popup-section';
import { ReviewGrid } from '../../types/component';
import { Product } from '../../types/product';
import { rem } from '../../styles/utils';
import CTA from './cta';
import Container from './container';
import ReviewStars from './review-stars';
import DisplayPic from './display-pic';

const Section = styled.div``;

const Grid = styled.div`
  padding: 30px;
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-weight: normal;
  text-align: left;
  border-bottom: 1px solid var(--celeste);
  &.grid-0 {
    border-top: 1px solid var(--celeste);
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 20px;
  float: left;
`;

const Name = styled.div`
  color: var(--firefly);
  font-weight: bold;
  font-size: ${rem(16)};
  margin-bottom: 8px;

  ${media.xl`
    font-size: ${rem(18)};
  `}
`;

const Date = styled.div`
  color: var(--firefly);
  font-size: ${rem(16)};

  ${media.xl`
    font-size: ${rem(22)};
  `}
`;

const ActionRow = styled(Row)`
  font-family: var(--font-body);
  margin: 50px 0;
  text-align: center;
  ${media.lg`
    text-align: left;
  `}
`;

const WriteReview = styled.div`
  text-align: center;
  ${media.lg`
    text-align: right;
  `}
`;

const InfoHeadline = styled.strong`
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
  font-size: ${rem(20)};
  letter-spacing: 1px;
  counter-increment: number;
`;

const SubHeadline = styled.span`
  font-size: ${rem(18)};
  margin-bottom: 20px;
  display: block;
`;

const Content = styled.p`
  font-size: ${rem(14)};
  line-height: 23px;
  margin-top: 10px;

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}
`;

const CTAButton = styled.div`
  text-align: center;
  margin: 100px 0;
`;

type Props = {
  data: ReviewGrid[];
  title: string;
  product: Product;
  button: {
    text: string;
    url: string;
  };
};

const ReviewBodySection: React.FC<Props> = ({
  data,
  title,
  product,
  button,
}) => {
  const [reviews, setReviews] = useState<ReviewGrid[]>([]);

  useEffect(() => {
    const size = 10;
    let index = 1;
    let loading = false;
    let concatReviews = reviews;
    concatReviews = concatReviews.concat(paginate(index, size));
    setReviews(concatReviews);
    index++;

    const elementTarget = document.getElementById('shop_button');
    const offset = 80;

    const scrollLoad = () => {
      if (elementTarget) {
        if (
          window.innerHeight >
            elementTarget.getBoundingClientRect().top + offset &&
          reviews.length < data.length &&
          !loading
        ) {
          loading = true;
          setTimeout(() => {
            concatReviews = concatReviews.concat(paginate(index, size));
            setReviews(concatReviews);
            index++;
            loading = false;
          }, 500);
        }
      }
    };

    window.addEventListener('scroll', scrollLoad);

    return () => window.removeEventListener('scroll', scrollLoad);
  }, []);

  const paginate = (index: number, size: number) => {
    index = index > 0 ? index - 1 : index;
    size = size < 1 ? 1 : size;

    // filter
    return [
      ...data.filter((_value, n) => {
        return n >= index * size && n < (index + 1) * size;
      }),
    ];
  };

  const renderReview = () => {
    return reviews.map(
      ({ displayPic, name, rating, content, submittedOn }, key) => (
        <Grid key={key} className={`grid-${key}`}>
          <Container>
            <Row>
              <Col lg={3}>
                <Profile>
                  <DisplayPic src={displayPic} />
                  <div>
                    <Name>{name}</Name>
                    <Date>{moment(submittedOn).format('DD MMM YYYY')}</Date>
                  </div>
                </Profile>
              </Col>
              <Col lg={9}>
                <ReviewStars
                  hideText={true}
                  rating={rating}
                  width={'20px'}
                  isYellow={true}
                />
                <Content dangerouslySetInnerHTML={{ __html: content }} />
              </Col>
            </Row>
          </Container>
        </Grid>
      )
    );
  };

  return (
    <Section>
      <Container>
        <ActionRow>
          <Col lg={6}>
            <InfoHeadline>{title}</InfoHeadline>
            <SubHeadline>Shop</SubHeadline>
          </Col>
          <Col lg={6}>
            <WriteReview>
              <ReviewPopupSection product={product} />
            </WriteReview>
          </Col>
        </ActionRow>
      </Container>
      {renderReview()}
      <CTAButton id="shop_button">
        <CTA to={button.url} type="flat">
          {button.text}
        </CTA>
      </CTAButton>
    </Section>
  );
};

export default ReviewBodySection;
