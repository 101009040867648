import { State as CartState } from '../hooks/cart-context/cart-context-types';
import { TikTokTrackPayload } from '../types/analytics';
import { Order, ProductOrderItem } from '../types/order';
import { FacebookPixelEvent, TikTokEvent } from './constants';

export const fbq = (
  event: FacebookPixelEvent,
  payload?: { value: number; currency: string }
): void => {
  if (window.fbq) {
    window.fbq('track', event, payload);
  }
};

export const gtag = (type: string, event: string, payload?: unknown): void => {
  if (window.gtag) {
    window.gtag(type, event, payload);
  }
};

export const ttq = (event: TikTokEvent, payload: TikTokTrackPayload): void => {
  if (!window.ttq?.track) return;
  window.ttq.track(event, payload);
};

export const toGtagProduct = (
  product: ProductOrderItem
): Record<string, unknown> => {
  if (!product) return {};
  const {
    currency,
    subtotal,
    unitPrice,
    comparePrice,
    sku,
    name,
    collection,
    variant,
    subVariants,
    qty,
  } = product;
  const discount = (comparePrice - unitPrice) * qty;
  return {
    currency,
    value: subtotal,
    items: [
      {
        item_id: sku,
        item_name: name,
        discount: discount < 0 ? 0 : discount,
        item_category: collection,
        item_variant: [variant, ...(subVariants || [])].join(','),
        price: unitPrice,
        quantity: qty,
      },
    ],
  };
};

export const toGtagCart = (cartState: CartState): Record<string, unknown> => {
  if (!cartState) return {};

  const { products, promos } = cartState;

  if (!products || products.length == 0) return {};

  const gtagProducts = cartState.products.map(
    p => toGtagProduct(p) as { items: unknown[] }
  );

  const items = gtagProducts.reduce<unknown[]>((prevItems, p) => {
    return [...prevItems, ...p.items];
  }, []);

  return {
    currency: cartState.products[0].currency,
    value: cartState.amounts.totalAmount,
    coupon: (promos || []).map(p => p.code).join(','),
    items,
  };
};

export const toTtqProduct = (product: ProductOrderItem): TikTokTrackPayload => {
  const { id, unitPrice, currency, name } = product;
  return {
    value: unitPrice.toFixed(2),
    currency,
    contents: [{ content_id: id, content_type: 'product', content_name: name }],
  };
};

export const toTtqCart = (cartState: CartState): TikTokTrackPayload => {
  const { amounts, products } = cartState;
  return {
    value: amounts.totalAmount.toFixed(2) || '',
    currency: products.length && products[0] ? products[0].currency : 'SGD',
    contents: products.map(p => ({
      content_id: p.id,
      content_type: 'product',
      content_name: p.name,
    })),
  };
};
