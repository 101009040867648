import React from 'react';
import { PRODUCT_TERRA_MATTRESS_ID } from 'gatsby-env-variables';

import { ProductReviewHeroSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const TerraMattressReviewHeroSection: React.FC = () => {
  return (
    <ProductReviewHeroSection
      productId={PRODUCT_TERRA_MATTRESS_ID}
      allReviewsUrl={SiteUrls.TerraMattressReview}
    />
  );
};

export default TerraMattressReviewHeroSection;
