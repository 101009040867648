import { Dispatch } from './../hooks/auth-context';
import firebase from 'gatsby-plugin-firebase';
import { userSignUp, userWelcomeEmail } from './api';
import { FirebaseError } from 'firebase';

const authFlow = async (
  dispatch: Dispatch,
  userCred: firebase.auth.UserCredential,
  firstName?: string,
  lastName?: string
) => {
  const auth = firebase.auth();

  const { isNewUser } = userCred.additionalUserInfo
    ? userCred.additionalUserInfo
    : { isNewUser: false };

  const accessToken = await auth.currentUser?.getIdToken(true);

  if (!accessToken) return false;

  const names = userCred.user?.displayName?.split(' ') || ['', ''];
  const displayPic = userCred.user?.photoURL || '';
  const fName = firstName || names[0];
  const lName = lastName || names[1];

  if (isNewUser) {
    await userSignUp(accessToken, fName, lName, displayPic);
    await userWelcomeEmail(accessToken);
  }

  dispatch({
    type: 'SIGN_IN',
    accessToken,
    email: userCred.user?.email || '',
    uid: userCred.user?.uid,
    displayPic: userCred.user?.photoURL || '',
    firstName: fName,
    lastName: lName,
  });
};

export const signInWithEmailAndPassword = async (
  dispatch: Dispatch,
  email: string,
  password: string
): Promise<boolean> => {
  try {
    const result = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    if (!result.user) return false;
    authFlow(dispatch, result);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const signUpWithEmailAndPassword = async (
  dispatch: Dispatch,
  email: string,
  password: string,
  firstName: string,
  lastName: string
): Promise<boolean> => {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    if (!result.user) return false;
    authFlow(dispatch, result, firstName, lastName);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const signInWithSocial = async (
  type: 'Facebook' | 'Google'
): Promise<void> => {
  let provider:
    | firebase.auth.GoogleAuthProvider
    | firebase.auth.FacebookAuthProvider;
  switch (type) {
    case 'Facebook': {
      provider = new firebase.auth.FacebookAuthProvider();
      break;
    }
    case 'Google': {
      provider = new firebase.auth.GoogleAuthProvider();
      break;
    }
    default:
      throw 'Invalid type';
  }

  await firebase.auth().signInWithRedirect(provider);
};

export const handleSocialRedirectSignIn = async (
  dispatch: Dispatch
): Promise<{ result: boolean; message: string }> => {
  const returnData = {
    result: false,
    message: '',
  };

  try {
    const result = await firebase.auth().getRedirectResult();
    if (!result.user) return returnData;
    authFlow(dispatch, result);
    return { ...returnData, result: true };
  } catch (error) {
    if (
      (error as FirebaseError).code ===
      'auth/account-exists-with-different-credential'
    ) {
      return {
        ...returnData,
        result: false,
        message:
          'An account already exists with the same email address. Please try again with a different social login provider.',
      };
    } else {
      console.error(error);
      return returnData;
    }
  }
};

export const sendResetPassword = async (email: string): Promise<boolean> => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const updatePassword = async (
  dispatch: Dispatch,
  currentPassword: string,
  newPassword: string
): Promise<string> => {
  try {
    const auth = firebase.auth();
    const user = auth.currentUser;
    if (!user) return 'Unauthorized';
    if (!user.email) return 'Invalid user account';

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    const result = await user.reauthenticateWithCredential(credential);
    if (!result.user) return 'Invalid credentials provided';

    authFlow(dispatch, result);
    await result.user?.updatePassword(newPassword);
    return '';
  } catch (error) {
    console.error(error);
    return 'An error has occured. Please contact support via Whatsapp.';
  }
};

// const getProviderById = (
//   providerId: 'google.com' | 'facebook.com' | string
// ): firebase.auth.GoogleAuthProvider | firebase.auth.FacebookAuthProvider => {
//   switch (providerId) {
//     case 'facebook.com':
//       return new firebase.auth.FacebookAuthProvider();
//     case 'google.com':
//       return new firebase.auth.GoogleAuthProvider();
//     default:
//       throw `Invalid providerId - ${providerId}.`;
//   }
// };

// return await handleAccountExistsWithDifferentCredential(
//   dispatch,
//   error.credential,
//   error.email
// );

// export const handleAccountExistsWithDifferentCredential = async (
//   dispatch: Dispatch,
//   pendingCred: firebase.auth.AuthCredential,
//   email: string
// ): Promise<boolean> => {
//   try {
//     const methods = await firebase.auth().fetchSignInMethodsForEmail(email);

//     if (methods[0] === 'password') {
//       dispatch({ type: 'LINK_ACCOUNT_WITH_PASSWORD', pendingCred, email });
//     } else {
//       const provider = getProviderById(pendingCred.providerId);
//     }

//     return true;
//   } catch (error) {
//     console.error(error);
//     return false;
//   }
// };

// export const linkAccountWithEmailAndPassword = async (
//   email: string,
//   password: string,
//   pendingCred: firebase.auth.AuthCredential
// ): Promise<boolean> => {
//   try {
//     const userCred = await firebase
//       .auth()
//       .signInWithEmailAndPassword(email, password);
//     await userCred.user?.linkWithCredential(pendingCred);
//     return true;
//   } catch (error) {
//     console.log(error);
//     return false;
//   }
// };
