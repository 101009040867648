import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import Carousel from '../carousel';
import Video from '../video';
import ImageZoom from '../image-zoom';
import Picture from '../picture';
import { Product, ProductAddOnConfig, Variant } from '../../../types/product';
import Container from '../container';
import ProductSectionInfo from '../product-section-info';
import useAddToCart from '../../../hooks/use-add-to-cart';
import { Asset } from '../../../types/component';

const Section = styled.section`
  width: 100%;
  padding-bottom: 40px;

  ${media.xl`
    padding-bottom: 80px;
  `}
`;

const ProductCarousel = styled(Carousel)`
  margin-bottom: 40px;

  .carousel {
    padding-bottom: 0;

    .control-dots {
      .dot {
        background-color: transparent;
        border-color: white;

        &.selected {
          background-color: white;
        }
      }
    }
  }

  ${media.md`
    display: none;
  `}
`;

const ProductContainer = styled(Container)`
  ${media.md`
    display: flex;
    padding-top: 40px;
  `}

  ${media.lg`
    padding-top: 120px;
  `}

  ${media.xl`
    padding-top: 160px;
  `}
`;

const Slide = styled.div`
  width: 100%;
  height: 210px;
  position: relative;

  ${media.lg`
    height: 300px;
  `}

  ${media.xl`
    height: 425px;
  `}
`;

const ImgPicture = styled(Picture)`
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ImgZoom = styled(ImageZoom)`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Gallery = styled(Carousel)`
  display: none;

  ${media.md`
    display: block;
    width: 50%;
  `}

  ${media.lg`
    width: 60%;
  `}

  .carousel {
    padding-bottom: 0;
  }
`;

export type Props = {
  id: string;
  name: string;
  collection: string;
  children?: React.ReactNode | React.ReactNodeArray;
  media: {
    path: string;
    default: boolean;
    type: 'image' | 'video';
  }[];
  rating: {
    avg: number;
    count: number;
    total: number;
  };
  variants: Variant[];
  className?: string;
  defaultAddOnText?: string;
  addOns?: Product[];
  assets: Asset[];
  infoVersion?: 'v1' | 'v2';
  addOnLabel?: string;
  addOnConfig?: ProductAddOnConfig;
  customAddOn?: Product;
  badge?: JSX.Element;
};

const ProductSection: React.FC<Props> = ({
  id,
  name,
  collection,
  media,
  rating,
  className,
  children,
  defaultAddOnText,
  addOns,
  customAddOn,
  variants,
  assets: productAssets,
  infoVersion,
  addOnLabel,
  addOnConfig,
  badge,
}) => {
  const {
    product,
    isLoading,
    selectedVariant,
    selectedAddOn,
    selectedCustomAddOn,
    assets,
    handleAddToCart,
    handleProductAddOnChange,
    handleProductCustomAddOnChange,
    handleProductVariantDropdownChange,
  } = useAddToCart({
    id,
    collection,
    name,
    media,
    variants,
    rating,
    assets: productAssets,
  });

  const renderSlides = (allowZoom = false) => {
    return assets.map(({ type, src, webp }, key) => {
      switch (type) {
        case 'video':
          return (
            <Slide key={key}>
              <Video src={src} />
            </Slide>
          );
        default:
          return (
            <Slide key={key}>
              {allowZoom && <ImgZoom src={src} webp={webp} alt={name} />}
              {!allowZoom && (
                <ImgPicture src={{ png: src, webp }} imgProps={{ alt: '' }} />
              )}
            </Slide>
          );
      }
    });
  };

  const renderCustomThumbnail = () => {
    return assets.map(({ type, src, webp, thumbnail }, key) => {
      switch (type) {
        case 'video': {
          return <Picture key={key} src={{ png: thumbnail, webp }} />;
        }
        default:
          return (
            <Picture
              key={key}
              imgProps={{
                alt: '',
                role: 'presentation',
              }}
              src={{
                png: src,
                webp,
              }}
            />
          );
      }
    });
  };

  return (
    <Section className={className}>
      <ProductCarousel props={{ showStatus: false, showThumbs: false }}>
        {renderSlides()}
      </ProductCarousel>
      <ProductContainer>
        <Gallery
          props={{
            showStatus: false,
            showIndicators: false,
            renderThumbs: renderCustomThumbnail,
          }}
        >
          {renderSlides(true)}
        </Gallery>
        <ProductSectionInfo
          id={id}
          product={product}
          name={name}
          description={children}
          rating={rating}
          variants={variants}
          defaultAddOnText={defaultAddOnText}
          addOns={addOns}
          customAddOn={customAddOn}
          version={infoVersion}
          addOnLabel={addOnLabel}
          isLoading={isLoading}
          selectedVariant={selectedVariant}
          selectedAddOn={selectedAddOn}
          selectedCustomAddOn={selectedCustomAddOn}
          addOnConfig={addOnConfig}
          onChangeProductAddOn={handleProductAddOnChange}
          onChangeProductVariantDropdown={handleProductVariantDropdownChange}
          onChangeProductCustomAddOn={handleProductCustomAddOnChange}
          onClickAddToCart={handleAddToCart}
          badge={badge}
        />
      </ProductContainer>
    </Section>
  );
};

export default ProductSection;
