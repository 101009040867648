import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  ENABLE_MATTRESS_COMPARISON_PAGE,
  PRODUCT_MYSA_MATTRESS_ID,
  PRODUCT_PLUS_COVER_ID,
} from 'gatsby-env-variables';

import { ProductSection } from '../common';
import Mysa1 from '../../static/videos/mysa-mattress-1.mp4';
import Mysa2 from '../../assets/mysa-mattress-2.png';
import Mysa2Webp from '../../assets/mysa-mattress-2.webp';
import Mysa3 from '../../assets/mysa-mattress-3.png';
import Mysa3Webp from '../../assets/mysa-mattress-3.webp';
import Mysa4 from '../../assets/mysa-mattress-4.png';
import Mysa4Webp from '../../assets/mysa-mattress-4.webp';
import Mysa5 from '../../assets/mysa-mattress-5.png';
import Mysa5Webp from '../../assets/mysa-mattress-5.webp';
import Mysa6 from '../../static/videos/mysa-plus-cover.mp4';
import Mysa7 from '../../assets/plus-cover-asset.png';
import Mysa7Webp from '../../assets/plus-cover-asset.webp';

import useGraphQLGetProduct from '../../hooks/use-graphql-get-product';
import { BreadcrumbLink } from '../common/breadcrumb';
import { SiteUrls } from '../../utils/constants';

const Section = styled(ProductSection)`
  background-color: var(--spring-wood);

  &.plus-cover-background {
    background-color: var(--blue-gray);
  }
`;

type Props = {
  onChangeIsPlusCover?: (isChecked: boolean) => void;
  isPlusCover?: boolean;
};

const MysaMattressProductSection: React.FC<Props> = ({
  onChangeIsPlusCover,
  isPlusCover,
}) => {
  const product = useGraphQLGetProduct(PRODUCT_MYSA_MATTRESS_ID);
  const plusCover = useGraphQLGetProduct(PRODUCT_PLUS_COVER_ID);

  const breadcrumbs: BreadcrumbLink[] = [
    {
      urlPath: SiteUrls.ComparisonMattress,
      label: 'Mattresses',
    },
    { label: 'The Mysa Mattress' },
  ];

  if (!ENABLE_MATTRESS_COMPARISON_PAGE) {
    breadcrumbs[0].urlPath = undefined;
  }

  return (
    <Section
      className={isPlusCover ? 'plus-cover-background' : ''}
      version="v2"
      {...product}
      defaultAddOnText="Mysa mattress only"
      breadcrumbs={breadcrumbs}
      plusCover={plusCover}
      setIsPlusCover={onChangeIsPlusCover}
      isPlusCover={isPlusCover}
      assets={[
        {
          src: Mysa1,
          type: 'video',
        },
        {
          src: Mysa2,
          webp: Mysa2Webp,
          type: 'image',
        },
        {
          src: Mysa3,
          webp: Mysa3Webp,
          type: 'image',
        },
        {
          src: Mysa4,
          webp: Mysa4Webp,
          type: 'image',
        },
        {
          src: Mysa5,
          webp: Mysa5Webp,
          type: 'image',
        },
        {
          src: Mysa6,
          type: 'video',
          isPlusCover: true,
        },
        {
          src: Mysa7,
          webp: Mysa7Webp,
          type: 'image',
          isPlusCover: true,
        },
      ]}
    >
      <p>
        For a medium-plush hotel-style feel. A luxurious sleeping surface that
        provides a whole new level of comfort and is unlike anything in the
        market.
      </p>
      <ul>
        <li>
          Topped with Woosa Cloud Foam for soft velvety plushness that hugs you
          to sleep
        </li>
        <li>
          Premium Sonocore® Latex and the Memory Foam provides pressure relief &
          support
        </li>
        <li>
          Recommended for Side Sleepers, Stomach Sleepers or Light-back Sleepers
        </li>
        <li>Made in Belgium for superior comfort and durability</li>
      </ul>
    </Section>
  );
};

export default MysaMattressProductSection;
