import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

type Props = {
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
};

const BgSupportGift2: React.FC<Props> = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "support-gift-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const sources = [data.placeholderImage.childImageSharp.fluid];
  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={sources}
      backgroundColor="#fff"
    >
      {children}
    </BackgroundImage>
  );
};

export default BgSupportGift2;
