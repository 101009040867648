import React from 'react';
import styled from 'styled-components';
import { ENABLE_MATTRESS_COMPARISON_PAGE } from 'gatsby-env-variables';

import { Link, ProductAccordionSection } from '../common';
import { Paragraph, Subtitle } from '../common/product-accordion-section';
import { Accordion } from '../../types/component';
import { SiteUrls } from '../../utils/constants';

const Section = styled(ProductAccordionSection)`
  margin: 0 !important;
`;

const data: Accordion[] = [
  {
    title: 'Shipping, Return, Warranty & Cancellation',
    body: (
      <>
        <Subtitle>Shipping</Subtitle>
        <Paragraph>
          All products are shipped free to your doorstep and inclusive of
          installation. For delivery locations that are not lift accessible,
          there will be another $10 per level per item for up to a{' '}
          <b> maximum of 3 storeys / flight of stairs</b>. For more than 3
          storeys / flight of stairs – will be quoted separately.
        </Paragraph>
        <Paragraph>
          <b>Breakdown of items</b>
          <br />
          <br />
          Mattress – 1x
        </Paragraph>
        <Paragraph>
          Note: Carry-up items are required only for the above items,
          accessories do not need to be added in. Read more{' '}
          <Link to={SiteUrls.Shipping} target="_blank" rel="noreferrer">
            here
          </Link>
          .
        </Paragraph>
        <Subtitle>Return</Subtitle>
        <Paragraph>
          Return policy for Mattress Free returns are applicable for one
          mattress per order, per address, within the first 30 days of receiving
          your item, or prior to delivery. Read more{' '}
          <a href={SiteUrls.FreeTrial} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </Paragraph>
        <Subtitle>Warranty</Subtitle>
        <Paragraph>
          The Original Mattress comes with a 10-year warranty against any
          sagging, sinking, torn foams and poorly stitched covers. Read more{' '}
          <a
            href={SiteUrls.OriginalMattressWarranty}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Paragraph>
        <Subtitle>Cancellation</Subtitle>
        <Paragraph>
          Free cancellation prior to delivery. Read more{' '}
          <a href={SiteUrls.FreeTrial} target="_blank" rel="noreferrer">
            here
          </a>
          .
          <br />
          <br />
          For orders that have customization requirements (ie custom sizes or
          other customization requests), cancellation is not allowed.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'FAQ',
    body: (
      <>
        <Subtitle>
          What are the dimensions & specifications of The Original Mattress?
        </Subtitle>
        <Paragraph>
          Asian Standard Size:
          <br />
          Single - 91cm x 190cm x 30.5cm
          <br />
          Super Single - 107cm x 190cm x 30.5cm
          <br />
          Queen - 152cm x 190cm x 30.5cm
          <br />
          King - 183cm x 190cm x 30.5cm
          <br />
          <br />
          Weight: 27/32/45/53kg
        </Paragraph>
        <Subtitle>Are you able to customize sizes?</Subtitle>
        <Paragraph>
          We can customize your Original Woosa Mattress with tolerances of up to
          +/‐ 15cm on each end for an additional $300. Drop by our mattress shop
          in Singapore, the Woosa Sleep Lab, or contact us at{' '}
          <a href="mailto:support@woosa.sg" target="_blank" rel="noreferrer">
            support@woosa.sg
          </a>{' '}
          for your custom order. All customized orders have a waiting time of
          6‐8 weeks.
        </Paragraph>
        <Subtitle>Which mattress (Original/Terra/Mysa) should I get?</Subtitle>
        <Paragraph>
          <b>The Original</b>: Made for Back or Side Sleepers. We recommend the
          Original for users who are used to sleeping on medium-firm mattresses
          or are trying full foam mattress for the first time.
          <br />
          <br />
          <b>The Terra</b>: Made for Back Sleepers or Heavy Side-Sleepers. We
          recommend the Terra Mattress for users who prefer a firmer surface, or
          for users coming from firm spring mattresses.
          <br />
          <br />
          <b>The Mysa</b>: Made for Side Sleepers, Stomach Sleepers or
          Light-back Sleepers. We recommend the Mysa Mattress for users coming
          from memory foam mattresses, or those who are used to sleeping on
          plushy, spring mattresses.
          {ENABLE_MATTRESS_COMPARISON_PAGE && (
            <>
              <br />
              <br />
              For more information and comparison,{' '}
              <a
                href={SiteUrls.ComparisonMattress}
                target="_blank"
                rel="noreferrer"
              >
                read
              </a>{' '}
              here.
            </>
          )}
        </Paragraph>
        <a href={SiteUrls.Faq} target="_blank" rel="noreferrer">
          Read All FAQs
        </a>
      </>
    ),
  },
];

const OriginalMattressAccordionSection: React.FC = () => {
  return <Section data={data} initCollapseAll />;
};

export default OriginalMattressAccordionSection;
