import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';
import GridContainer from './container';
import { gridTheme } from '../../styles/grid';

export const Wrapper = styled(GridContainer)`
  padding-bottom: 40px;

  ${media.xl`
    max-width: ${gridTheme.container.maxWidth.lg}px;
  `}
`;

const Container = styled.div`
  width: 100%;

  ${media.md`
    display: flex;
  `}
`;

const Left = styled.div`
  width: 100%;

  ${media.md`
    width: 30%;
    padding-left: 40px;
  `}
`;

const Right = styled.div`
  width: 100%;

  ${media.md`
    width: 70%;
    padding-left: 20px;
  `}
`;

export const fontStyle = css`
  color: var(--font-secondary-color);
  font-family: var(--font-body);
  font-size: ${rem(14)};
  line-height: 1.5;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

export const LeftTitle = styled.h3`
  color: var(--font-primary-color);
  font-family: var(--font-body);
  font-size: ${rem(16)};
  margin-bottom: 20px;
  line-height: 1.5;

  ${media.md`
    display: inline-block;
    margin-top: 0;
    font-size: ${rem(14)};
  `}

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

export const PlusIconList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
`;

export const PlusIconListItem = styled.li`
  ${fontStyle}
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;

  &:before {
    content: '+';
    position: absolute;
    left: 0;
  }
`;

export const OrderedList = styled.ol`
  padding: 20px;
  margin: 0 0 20px;
`;

export const OrderedListItem = styled.li`
  ${fontStyle}
  position: relative;
  margin-bottom: 10px;
`;

type Props = {
  className?: string;
  left: React.ReactNode | React.ReactNodeArray;
  right: React.ReactNode | React.ReactNodeArray;
};

const SimpleSplitSection: React.FC<Props> = ({ className, left, right }) => {
  return (
    <Container className={className}>
      <Left>{left}</Left>
      <Right>{right}</Right>
    </Container>
  );
};

export default SimpleSplitSection;
