import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { media } from 'styled-bootstrap-grid';

import WizardHeadline from './wizard-headline';
import WizardButton from './wizard-button';
import WizardContainer from './wizard-container';
import WizardEyebrow from './wizard-eyebrow';
import { useAuthState, useAuthDispatch } from '../../hooks/auth-context';
import {
  useCheckoutState,
  useCheckoutDispatch,
} from '../../hooks/checkout-context';
import { useCartState } from '../../hooks/cart-context';
import { getDeliverySlots } from '../../utils/api';
import { DeliverySlot, Slot } from '../../types/deliverySlot';
import { Schedule } from '../../types/order';
import { CalendarPicker } from '../common';
import DeliveryOption from './delivery-option';
import { getDefaultMinDeliveryDate } from '../../utils';

const FormBody = styled.div``;

const Picker = styled(CalendarPicker)`
 ${media.md`
    display: flex;
    width: 100%;
  `}

  ${media.lg`
    display: block;
  `}

  ${media.xl`
    display: flex;
  `}

  .date-picker-container {
    ${media.md`
      width: 45%;
    `}

    ${media.lg`
      width: 70%;
    `}

    ${media.xl`
      width: 50%;
    `}
  }

  .slot-container {
    margin-top: 20px;

    ${media.md`
      margin-left: 40px;
    `}

    ${media.lg`
      margin-left: 0;
      width: 70%;
    `}

    ${media.xl`
      width: 40%;
      margin-left: 40px;
    `}
  }
`;

const ErrorMessage = styled.span`
  display: block;
  margin: 10px 0;
  color: red;
  font-size: 80%;
`;

type Props = {
  className?: string;
  index?: number;
};

const DeliveryForm: React.FC<Props> = ({ className, index }) => {
  const { accessToken } = useAuthState();
  const { activeIndex, schedule, deliveryOption } = useCheckoutState();
  const { products } = useCartState();
  const checkoutDispatch = useCheckoutDispatch();
  const authDispatch = useAuthDispatch();
  const deliverySlotsRef = useRef<DeliverySlot[] | null>(null);
  const [slots, setSlots] = useState<Slot[]>([]);
  const [error, setError] = useState('');

  const getMinDate = () => {
    const defaultMinDate = getDefaultMinDeliveryDate();

    if (deliveryOption === 'all') {
      return products.reduce((earliest, product) => {
        if (product.status !== 'Pre-Order' || !product.statusDate)
          return earliest;

        const productEarliest = moment(product.statusDate);

        return productEarliest.isAfter(moment(earliest), 'date')
          ? productEarliest.toDate()
          : earliest;
      }, defaultMinDate);
    }

    return defaultMinDate;
  };

  const [minDate, setMinDate] = useState<Date>(getMinDate());
  const [time, setTime] = useState<string>(schedule?.time || '');
  const [date, setDate] = useState<Date>(schedule?.date || getMinDate());

  useEffect(() => {
    if (activeIndex === index && !deliverySlotsRef.current) {
      const currentMinDate = getMinDate();
      setMinDate(currentMinDate);
      (async () => {
        await getDeliverySlotsForTheMonth(moment(currentMinDate));
      })();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (deliveryOption !== 'unsure') {
      const currentMinDate = getMinDate();
      setMinDate(currentMinDate);
      handleChangeDate(currentMinDate);
    }
  }, [deliveryOption]);

  const getDeliverySlotsForTheMonth = async (month: Moment) => {
    checkoutDispatch({ type: 'SET_IS_LOADING', isLoading: true });
    const startDate = month.startOf('month').toDate();
    const endDate = month.endOf('month').toDate();
    try {
      deliverySlotsRef.current = await getDeliverySlots(
        startDate,
        endDate,
        accessToken
      );
      handleChangeDate(date);
    } catch (error) {
      authDispatch({ type: 'SIGN_OUT' });
      checkoutDispatch({ type: 'SET_ACTIVE_INDEX', activeIndex: 0 });
    } finally {
      checkoutDispatch({ type: 'SET_IS_LOADING', isLoading: false });
    }
  };

  const setDefaultTime = (selectedDate: Date) => {
    if (deliverySlotsRef.current && deliverySlotsRef.current.length > 0) {
      const selectedDeliverySlot = deliverySlotsRef.current.find(
        slot => slot.date.getDate() === selectedDate.getDate()
      );

      if (
        selectedDeliverySlot &&
        selectedDeliverySlot.slots &&
        selectedDeliverySlot.slots.length > 0
      ) {
        setTime(selectedDeliverySlot.slots[0].time);
        return;
      }
    }

    setTime('');
  };

  const handleChangeDate = async (selectedDate: Date | null) => {
    setError('');
    if (!selectedDate) return;
    const availableSlots: Slot[] = [];
    if (!moment(selectedDate).isSame(moment(date), 'month')) {
      await getDeliverySlotsForTheMonth(moment(selectedDate));
    }
    if (deliverySlotsRef.current) {
      deliverySlotsRef.current
        .filter(d => moment(d.date).isSame(moment(selectedDate), 'date'))
        .forEach(d => d.slots.forEach(s => availableSlots.push(s)));
    }

    setSlots(availableSlots);
    setDate(selectedDate);
    setDefaultTime(selectedDate);
  };

  const handleContinue = () => {
    setError('');
    if (deliveryOption !== 'unsure') {
      if (!deliverySlotsRef.current) return;

      const selectedDateSlot = deliverySlotsRef.current.find(
        slot => slot.date.getDate() === date.getDate()
      );

      if (!selectedDateSlot) return;

      if (
        moment(selectedDateSlot.date).isBefore(moment(getMinDate()), 'date')
      ) {
        setError(
          'The delivery slot is not available any more. Please choose a different delivery slot.'
        );
        return;
      }

      const newSchedule: Schedule = {
        id: selectedDateSlot.id,
        date,
        time,
        createdUnix: moment().valueOf(),
      };

      checkoutDispatch({ type: 'UPDATE_DELIVERY', schedule: newSchedule });
    }

    checkoutDispatch({
      type: 'SET_ACTIVE_INDEX',
      activeIndex: (index || 0) + 1,
    });
  };

  return (
    <WizardContainer className={className}>
      <WizardHeadline data-num={(index || 0) + 1}>
        Delivery Slots
      </WizardHeadline>
      <FormBody>
        {activeIndex === index && (
          <>
            <DeliveryOption />
            {deliveryOption !== 'unsure' && (
              <>
                <WizardEyebrow>Pick delivery date and timing</WizardEyebrow>
                <Picker
                  datePickerContainerClassName="date-picker-container"
                  datePickerProps={{
                    minimumDate: minDate,
                    date,
                    onDateChange: handleChangeDate,
                  }}
                  slotContainerClassName="slot-container"
                  slotProps={{
                    placeholder: 'Timing',
                    value: time,
                    onChange: event => setTime(event.target.value),
                    options: slots.map(({ time }) => time),
                  }}
                />
              </>
            )}
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </FormBody>
      <WizardButton
        disabled={(!date || !time) && deliveryOption !== 'unsure'}
        onClick={handleContinue}
      >
        Continue to Payment
      </WizardButton>
    </WizardContainer>
  );
};

export default DeliveryForm;
