import React, { useState, useEffect } from 'react';
import { uniqBy } from 'lodash';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { ENABLE_ATOME } from 'gatsby-env-variables';

import { rem, flatButtonStyle } from '../../../styles/utils';
import ReviewStars from '../review-stars';
import ProductVariantDropdown from '../product-variant-dropdown';
import ProductAddOn from '../product-add-on';
import Spinner from '../spinner';
import ProductCustomAddOn from '../product-custom-add-on';
import ProductPlusCover from '../product-plus-cover';
import { currencyFormat, isSimpleSubVariant } from '../../../utils';
import AtomeLabel from '../atome-label';
import {
  Product,
  ProductAddOnConfig,
  ProductIconInfo,
  Variant,
} from '../../../types/product';
import { ProductOrderItem } from '../../../types/order';
import ProductInfoIconList from '../product-info-icon-list';

const InfoContainer = styled.div`
  width: 100%;

  ${media.md`
    width: 50%;
    padding-left: 40px;
  `}

  ${media.xl`
    width: 40%;
  `}
`;

const Info = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const AddToCartBtn = styled.button`
  ${flatButtonStyle()}
  width: 100%;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.xl`
    flex-direction: row;
  `}
`;

const Name = styled.h1`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 5px;

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const BadgeWrapper = styled.div`
  margin-bottom: 5px;

  ${media.xl`
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 0;
  `}
`;

const Description = styled.div`
  font-family: var(--font-body);
  font-size: ${rem(12)};
  font-weight: normal;
  color: var(--font-secondary-color);
  line-height: 1.3;
  margin: 0;

  ul {
    margin: 0;
    padding: 0 0 0 15px;
  }

  ${media.lg`
    font-size: ${rem(14)};
    line-height: 1.3125;
  `}

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const Atome = styled(AtomeLabel)`
  text-align: right;
  margin-top: -5px;
`;

export type Props = {
  id: string;
  product: Product;
  name: string;
  rating: {
    avg: number;
    count: number;
    total: number;
  };
  description: React.ReactNode | React.ReactNodeArray | string;
  variants: Variant[];
  className?: string;
  defaultAddOnText?: string;
  addOns?: Product[];
  customAddOn?: Product;
  version?: 'v2';
  addOnLabel?: string;
  onChangeProductVariantDropdown?: (selected: Variant) => void;
  onChangeProductOrderItem?: (p: ProductOrderItem | null) => void;
  isLoading?: boolean;
  selectedVariant?: Variant;
  selectedAddOn?: ProductOrderItem | null;
  selectedCustomAddOn?: ProductOrderItem | null;
  selectedProductOrderItem?: ProductOrderItem | null;
  addOnConfig?: ProductAddOnConfig;
  onChangeProductAddOn: (selected: ProductOrderItem | null) => void;
  onChangeProductCustomAddOn?: (selected: ProductOrderItem | null) => void;
  onClickAddToCart?: () => void;
  badge?: JSX.Element;
  plusCover?: Product;
  onChangePlusCover?: (
    selected: ProductOrderItem | null,
    isChecked: boolean
  ) => void;
  selectedProductPlusCover?: ProductOrderItem | null;
  isPlusCoverChecked?: boolean;
  includedInfoList?: ProductIconInfo[];
};

const ProductSectionInfo: React.FC<Props> = ({
  id,
  name,
  product,
  rating,
  className,
  description,
  defaultAddOnText,
  addOns,
  customAddOn,
  addOnLabel,
  onChangeProductVariantDropdown,
  onChangeProductOrderItem,
  isLoading,
  selectedVariant,
  selectedAddOn,
  selectedCustomAddOn,
  selectedProductOrderItem,
  addOnConfig,
  onChangeProductAddOn,
  onChangeProductCustomAddOn,
  onClickAddToCart,
  badge,
  plusCover,
  onChangePlusCover,
  selectedProductPlusCover,
  isPlusCoverChecked,
  includedInfoList,
}) => {
  const [amount, setAmount] = useState(0);

  const hasSubVariants = () =>
    product.variants.some(v => v.subVariants && v.subVariants.length > 0);

  const displayAmount = () =>
    !hasSubVariants() || (hasSubVariants() && selectedProductOrderItem);

  useEffect(() => {
    let amt = 0;

    if (selectedVariant && !selectedProductOrderItem) {
      amt += selectedVariant.prices[0].amount;
    }
    if (selectedAddOn) {
      amt += selectedAddOn.subtotal;
    }
    if (selectedProductPlusCover) {
      amt += selectedProductPlusCover.subtotal;
    }
    if (selectedCustomAddOn) {
      amt += selectedCustomAddOn.subtotal;
    }
    if (selectedProductOrderItem) {
      amt += selectedProductOrderItem.subtotal;
    }
    setAmount(amt);
  }, [
    selectedVariant,
    selectedAddOn,
    selectedCustomAddOn,
    selectedProductOrderItem,
    selectedProductPlusCover,
  ]);

  return (
    <InfoContainer className={className}>
      <Info>
        <NameWrapper>
          <Name>{name}</Name>
          {badge && <BadgeWrapper>{badge}</BadgeWrapper>}
        </NameWrapper>
        <ReviewStars
          isYellow
          hideIfNoRating
          rating={rating?.avg || 0}
          linkUrl="#reviews"
        />
        <Description>{description}</Description>
      </Info>
      {includedInfoList && (
        <Info>
          <ProductInfoIconList
            label="What’s included"
            selectedVariant={selectedVariant}
            iconInfoList={includedInfoList}
          />
        </Info>
      )}
      {hasSubVariants() && isSimpleSubVariant(product.subVariantKeys) && (
        <Info>
          <ProductCustomAddOn
            product={product}
            selectedVariant={selectedVariant}
            onChange={onChangeProductOrderItem}
            defaultEnabled
          />
        </Info>
      )}
      <Info></Info>
      <Info>
        {product && (
          <>
            <ProductVariantDropdown
              label="Size"
              hidePrice={hasSubVariants()}
              variants={
                !hasSubVariants()
                  ? product.variants
                  : uniqBy(product.variants, v => v.type)
              }
              onChange={onChangeProductVariantDropdown}
              version="v2"
            />
            {hasSubVariants() &&
              !isSimpleSubVariant(product.subVariantKeys) && (
                <ProductCustomAddOn
                  product={product}
                  selectedVariant={selectedVariant}
                  onChange={onChangeProductOrderItem}
                  defaultEnabled
                />
              )}
            <Spinner isLoading={isLoading} size={30} />
          </>
        )}
      </Info>
      {addOns && addOns.length > 0 && (
        <Info>
          <ProductAddOn
            id={id}
            label={addOnLabel || 'Accessories'}
            defaultText={defaultAddOnText || ''}
            products={addOns || []}
            selectedVariant={selectedVariant}
            onChange={onChangeProductAddOn}
            version="v2"
            config={addOnConfig}
          />
        </Info>
      )}
      {customAddOn && (
        <Info>
          <ProductCustomAddOn
            checkboxLabel="Complete the look with headboard/sideframes"
            product={customAddOn}
            selectedVariant={selectedVariant}
            onChange={onChangeProductCustomAddOn}
          />
        </Info>
      )}
      {plusCover && (
        <Info>
          <ProductPlusCover
            selectedVariant={selectedVariant}
            product={plusCover}
            onChange={onChangePlusCover}
            isPlusCoverChecked={isPlusCoverChecked}
          ></ProductPlusCover>
        </Info>
      )}
      <AddToCartBtn
        disabled={
          (selectedVariant && selectedVariant?.status === 'Out of Stock') ||
          isLoading ||
          (hasSubVariants() && !selectedProductOrderItem)
        }
        onClick={onClickAddToCart}
      >
        Add to Cart {displayAmount() && currencyFormat(amount)}
      </AddToCartBtn>
      {ENABLE_ATOME && <Atome amount={displayAmount() ? amount : undefined} />}
    </InfoContainer>
  );
};

export default ProductSectionInfo;
