import React from 'react';
import styled from 'styled-components';

import { SimpleSplitSection } from '../common';
import {
  Wrapper,
  LeftTitle,
  fontStyle,
  PlusIconList,
  PlusIconListItem,
} from '../common/simple-split-section';

const Container = styled(Wrapper)``;

const Row = styled(SimpleSplitSection)`
  margin-top: 40px;
`;

const Paragraph = styled.p`
  ${fontStyle}
  margin-top: 0;
`;

const MattressWarrantyContentSection: React.FC = () => {
  const data = [
    {
      title: 'Warranty Description',
      content: (
        <Paragraph>
          This warranty is valid only for the original purchaser from the
          original purchase date of the original mattress purchased from Woosa
          and is non-transferable. If the original purchaser sells or otherwise
          relinquishes ownership of the mattress to another, the subsequent
          owner accepts the product “as is” and “with all faults.” Original
          proof of purchase (including date) will be required to make a warranty
          claim. Please retain a copy of your receipt as proof of purchase.
          <br />
          <br />
          The Woosa mattress is intended to work on a firm, flat, solid-surface
          foundation structurally strong enough to support the weight of the
          mattress and its users. Other placement (whether long or short term)
          of the mattress, including but not limited to foundations of other
          description, improper storage orientation, or use of a non-sufficient
          frame beneath the foundation, voids this warranty.
          <br />
          <br />
          Woosa may require the purchaser to provide proof of the quality of the
          foundation, adjustable bed base, or bed frame used in conjunction with
          the mattress if purchaser makes a claim under this Limited Warranty.
          Woosa reserves the right to invalidate this Limited Warranty if the
          foundation is determined, in Woosa’s discretion, to be inadequate or
          if the mattress is found to be in an unsanitary condition.
        </Paragraph>
      ),
    },
    {
      title: 'What does the Limited Warranty Cover?',
      content: (
        <Paragraph>
          Deterioration causing the mattress to have a visible indentation
          greater than one (1) inch (2.54cm) that is not associated with an
          indentation or sag which results from use of an improper or
          unsupportive foundation or adjustable bed base. Normal wear requires
          that a purchaser’s mattress be continuously supported by a matching
          foundation or adjustable bed base with a proper bed frame sufficient
          to support the collective weight of the mattress and foundation. A
          proper bed frame must provide sturdy support for both the mattress and
          foundation. For slatted style bed frames, it is incumbent upon
          purchaser to ensure that the frame utilized offers ample structural
          support for the mattress and foundation in view of that particular
          slatted style frame. Failure to have a proper bed frame may invalidate
          the limited warranty. The use of a bed frame is important for the life
          and protection of your sleep system.
          <br />
          <br />
          Any physical flaw in the Mattress that causes the foam material to
          split or crack, despite normal usage and proper handling. Any
          manufacturing defect in the zipper assembly of the mattress cover.
        </Paragraph>
      ),
    },
    {
      title: 'What does this Limited Warranty NOT Cover?',
      content: (
        <PlusIconList>
          <PlusIconListItem>
            A normal increase in softness of the latex foam that does not affect
            the pressure-reliving qualities of the mattress.
          </PlusIconListItem>
          <PlusIconListItem>Comfort preference.</PlusIconListItem>
          <PlusIconListItem>
            Physical abuse or damage to the structure and/or cover material,
            including but not limited to, burns, cuts, tears, liquid damage, or
            stains resulting from use of the products.
          </PlusIconListItem>
          <PlusIconListItem>
            Damage associated with an improper bed frame, foundation, or
            adjustable base.
          </PlusIconListItem>
          <PlusIconListItem>
            Damage associated with improper storage or transportation after
            initial delivery provided by Woosa.
          </PlusIconListItem>
          <PlusIconListItem>
            Product sold by resellers who are not authorized retailers. Product
            sold “as-is”, “preconditioned”, “reconditioned”, “used”, “comfort
            return”, “returned”, “previously owned”, or any other similar
            wording indicating that the product is not “new” or of “first
            quality”, or has previously been purchased or used by another
            consumer.
          </PlusIconListItem>
          <PlusIconListItem>
            Product used by someone other than the initial, first purchaser of
            it.
          </PlusIconListItem>
        </PlusIconList>
      ),
    },
  ];

  return (
    <Container>
      {data.map(({ title, content }, i) => (
        <Row key={i} left={<LeftTitle>{title}</LeftTitle>} right={content} />
      ))}
    </Container>
  );
};

export default MattressWarrantyContentSection;
