import React from 'react';
import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';

import { rem } from '../../styles/utils';
import { Breakpoints } from '../../styles/enums';
import { Carousel, Container, MultiCarousel } from '.';

const Section = styled.section``;

const QuoteContainer = styled(Container)`
  display: none;

  ${media.md`
    display: block;
  `}
`;

const Quote = styled.blockquote`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(20)};
  color: var(--font-primary-color);
  line-height: 1.2;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;

  ${media.lg`
    font-size: ${rem(22)};
    padding: 0 20px;
  `}
`;

const Eyebrow = styled.strong`
  font-family: var(--font-body);
  font-size: ${rem(10)};
  font-weight: bold;
  letter-spacing: ${rem(0.5)};
  color: var(--font-primary-color);
  text-transform: uppercase;
  margin-top: 20px;
  display: block;

  ${media.lg`
    margin-top: 40px;
    font-size: ${rem(18)};
  `}
`;

const QuoteCarousel = styled(Carousel)`
  ${media.md`
    display: none;
  `}
`;

const commonQuoteStyle = css`
  padding: 0 10px;
  display: block;

  ${media.md`
    padding: 0;
  `}
`;

const QuoteLink = styled.a`
  ${commonQuoteStyle}
  text-decoration: none;
`;

const QuoteItem = styled.div`
  ${commonQuoteStyle}
`;

const QuoteLogo = styled.div`
  max-width: 150px;
  margin: 30px auto 0;
`;

type QuoteData = {
  content: string;
  logo: React.ReactNode;
  url?: string;
};

type Props = {
  className?: string;
  reviews: QuoteData[];
};

const PartnerReviewSection: React.FC<Props> = ({
  className,
  reviews: quotes,
}) => {
  const renderQuote = (quote: QuoteData, key?: number) => {
    const { content, logo, url } = quote;

    if (url) {
      return (
        <QuoteLink key={key} href={url} target="_blank" rel="noreferrer">
          <Quote>{content}</Quote>
          <QuoteLogo>{logo}</QuoteLogo>
        </QuoteLink>
      );
    } else {
      return (
        <QuoteItem key={key}>
          <Quote>{content}</Quote>
          <QuoteLogo>{logo}</QuoteLogo>
        </QuoteItem>
      );
    }
  };

  return (
    <Section className={className}>
      <QuoteContainer>
        <MultiCarousel
          infinite
          autoPlay
          arrows={false}
          responsive={{
            xl: {
              breakpoint: { max: 4000, min: Breakpoints.XL },
              items: 4,
            },
            md: {
              breakpoint: { max: Breakpoints.XL, min: Breakpoints.MD },
              items: 3,
            },
            sm: {
              breakpoint: { max: Breakpoints.MD, min: Breakpoints.SM },
              items: 3,
            },
            xs: {
              breakpoint: { max: Breakpoints.SM, min: 0 },
              items: 1,
            },
          }}
        >
          {quotes.map((quote, key) => renderQuote(quote, key))}
        </MultiCarousel>
      </QuoteContainer>
      <QuoteCarousel
        props={{
          showStatus: false,
          showThumbs: false,
          showIndicators: false,
          centerMode: true,
          selectedItem: 1,
          centerSlidePercentage: 60,
        }}
      >
        {quotes.map((quote, key) => renderQuote(quote, key))}
      </QuoteCarousel>
    </Section>
  );
};

export default PartnerReviewSection;
