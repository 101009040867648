import React from 'react';

import { InfoHero } from '../common';
import { SiteUrls } from '../../utils/constants';

const WaterproofMattressProtectorWarrantyHeroSection: React.FC = () => {
  return (
    <InfoHero
      prevLink={SiteUrls.SeresSilkDuvetWarranty}
      nextLink={SiteUrls.StorageBaseWarranty}
      dotLinks={[
        SiteUrls.OriginalMattressWarranty,
        SiteUrls.OriginalPillowWarranty,
        SiteUrls.BedFrameWarranty,
        SiteUrls.SeresSilkDuvetWarranty,
        SiteUrls.WaterproofMattressProtectorWarranty,
        SiteUrls.StorageBaseWarranty,
      ]}
      activeDotIndex={4}
    >
      Waterproof Mattress Protector
    </InfoHero>
  );
};

export default WaterproofMattressProtectorWarrantyHeroSection;
