import React from 'react';
import { useInView } from 'react-intersection-observer';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  min-height: 500px;

  ${media.md`
    min-height: 550px; 
  `}

  ${media.lg`
    min-height: 500px;
  `}
`;

const ClickableItem = styled.div<{
  zIndex: number;
  initial: {
    x: string;
    y: string;
    mdY: string;
  };
  active: {
    x: string;
    y: string;
    mdY: string;
  };
  neutral: {
    x: string;
    y: string;
    mdY: string;
  };
  size: {
    width: string;
    height: string;
  };
}>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  transition: transform 1s ease;
  top: ${({ initial: { y } }) => y};
  transform: skewY(16.6deg);

  width: ${({ size: { width } }) => width};
  height: ${({ size: { height } }) => height};

  &.active {
    transform: ${({ active: { x, y } }) =>
      `translate(${x}, ${y}) skewY(16.6deg);`};
  }

  &.neutral {
    transform: ${({ neutral: { x, y } }) =>
      `translate(${x}, ${y}) skewY(16.6deg);`};
  }

  ${media.md`
    top: ${({ initial: { mdY } }) => mdY};
    &.active {
    transform: ${({ active: { x, mdY } }) =>
      `translate(${x}, ${mdY}) skewY(16.6deg);`};
  }

  &.neutral {
    transform: ${({ neutral: { x, mdY } }) =>
      `translate(${x}, ${mdY}) skewY(16.6deg);`};
  }
  `}

  ${media.lg`
    top: ${({ initial: { y } }) => y};
    &.active {
    transform: ${({ active: { x, y } }) =>
      `translate(${x}, ${y}) skewY(16.6deg);`};
  }

  &.neutral {
    transform: ${({ neutral: { x, y } }) =>
      `translate(${x}, ${y}) skewY(16.6deg);`};
  }
  `}
`;

const Item = styled.div<{
  zIndex: number;
  initial: {
    x: string;
    y: string;
  };
  active: {
    x: string;
    y: string;
  };
  neutral: {
    x: string;
    y: string;
  };
}>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  transition: transform 1s ease;
  left: ${({ initial: { x } }) => x};
  bottom: ${({ initial: { y } }) => y};

  &.active {
    transform: ${({ active: { x, y } }) => `translate(${x}, ${y})`};
  }

  &.neutral {
    transform: ${({ neutral: { x, y } }) => `translate(${x}, ${y})`};
  }
`;

type Props = {
  className?: string;
  currentIndex: number;
  infos: {
    initial: {
      x: string;
      y: string;
    };
    active: {
      x: string;
      y: string;
    };
    neutral: {
      x: string;
      y: string;
    };
    asset: React.ReactNode;
    cover?: {
      initial: {
        x: string;
        y: string;
        mdY: string;
      };
      active: {
        x: string;
        y: string;
        mdY: string;
      };
      neutral: {
        x: string;
        y: string;
        mdY: string;
      };
      size: {
        width: string;
        height: string;
      };
    };
  }[];
  plusCoverAsset?: React.ReactNode;
  onNavigate: (index: number) => number;
  currentIndexRef: React.MutableRefObject<number>;
};

const MattressLayerAsset: React.FC<Props> = ({
  className,
  currentIndex,
  infos,
  plusCoverAsset,
  onNavigate,
  currentIndexRef,
}) => {
  const [ref, inView] = useInView({ threshold: 0.7, triggerOnce: true });
  // const currenIndexRef = useRef(currentIndex);

  const getItemState = (index: number) => {
    if (index === 0) {
      return inView ? (currentIndex === index ? 'active' : 'neutral') : '';
    }

    if (index === currentIndex) return 'active';

    return index <= currentIndex ? 'neutral' : '';
  };

  const handleNavigate = (index: number) => {
    const newIndex = onNavigate(index);
    currentIndexRef.current = newIndex;
  };

  return (
    <Container className={className} ref={ref}>
      {infos.map(({ asset, initial, active, neutral, cover }, idx) => (
        <React.Fragment key={`fragment-${idx}`}>
          {cover != undefined ? (
            <ClickableItem
              onClick={() => handleNavigate(idx)}
              className={getItemState(idx)}
              key={'ClickableItem' + idx}
              zIndex={infos.length - idx - 1 + 10}
              initial={cover.initial}
              active={cover.active}
              neutral={cover.neutral}
              size={cover.size}
            ></ClickableItem>
          ) : null}
          <Item
            className={getItemState(idx)}
            key={idx}
            zIndex={infos.length - idx - 1}
            initial={initial}
            active={active}
            neutral={neutral}
          >
            {plusCoverAsset != undefined && idx == 0 ? plusCoverAsset : asset}
          </Item>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default MattressLayerAsset;
