import React from 'react';
import styled from 'styled-components';
import { ResponsiveType } from 'react-multi-carousel';

import MultiCarousel from '../multi-carousel';
import { rem } from '../../../styles/utils';
import { Breakpoints } from '../../../styles/enums';

const Item = styled.div`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height; 1.16; 
  display: flex;
  flex-direction: column;

  span {
    margin: 5px 0;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  ${Item} {
    margin-right: 10px;
  }
`;

const carouselResponsive = {
  xxl: {
    breakpoint: {
      max: 4000,
      min: Breakpoints.XXL,
    },
    items: 4,
    partialVisibilityGutter: 15,
  },
  xl: {
    breakpoint: {
      max: Breakpoints.XXL,
      min: Breakpoints.XL,
    },
    items: 3,
    partialVisibilityGutter: 25,
  },
  lg: {
    breakpoint: {
      max: Breakpoints.XL,
      min: Breakpoints.LG,
    },
    items: 4,
    partialVisibilityGutter: 15,
  },
  md: {
    breakpoint: {
      max: Breakpoints.LG,
      min: Breakpoints.MD,
    },
    items: 3,
    partialVisibilityGutter: 15,
  },
  sm: {
    breakpoint: {
      max: Breakpoints.MD,
      min: Breakpoints.SM,
    },
    items: 3,
    partialVisibilityGutter: 15,
  },
  xs: {
    breakpoint: { max: Breakpoints.SM, min: 0 },
    items: 3,
    partialVisibilityGutter: 15,
  },
};

type Props = {
  className?: string;
  items: JSX.Element[];
  responsive?: ResponsiveType;
};

const SubVariantList: React.FC<Props> = ({ className, items, responsive }) => {
  if (items.length > 3) {
    return (
      <MultiCarousel
        className={className}
        arrows
        responsive={responsive || carouselResponsive}
        slidesToSlide={2}
      >
        {items}
      </MultiCarousel>
    );
  }
  return (
    <List className={className}>
      {items.map((item, i) => (
        <Item key={i}>{item}</Item>
      ))}
    </List>
  );
};

export default SubVariantList;
