import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import {
  ImgTerraBenefit1,
  ImgTerraBenefit2,
  ImgTerraBenefit3,
} from '../assets';

const Section = styled(ProductBenefitSection)``;

const TerraMattressBenefitSection: React.FC = () => (
  <Section
    version="v2"
    headline="Features"
    subtitle="Bedtimes without springs"
        subtitleTooltip={{
          title: 'Why use foams and not springs?',
          description: (
            <>
              Through our research and extensive testing, we find that most
              springs tend to give way much faster due to the constant
              gravitational pressure from your body, and offer sub-par weight
              distribution as compared to high quality foams.
              <br />
              <br />
              We wanted a core foundation that is supportive, resilient,
              durable, and eliminates motion transfer - which is best achieved
              through our premium quality, high density PU foam base.
              Furthermore, our foam components react to the body’s weight and
              heat in a way that springs can never achieve. Imagine having a
              million cells distributing the weight evenly across your body,
              offering support at the most critical points - it’s a no-brainer!
            </>
          ),
        }}
    benefits={[
      {
        img: <ImgTerraBenefit1 />,
        eyebrow: 'QUICK RECOVERY',
        headline: 'Responsive spinal alignment',
        body:
          '100% Natural Latex layers helps create a firm surface that is quick to recover, so you wouldn’t feel like you’ll sleeping on a bed of rocks. Bouncy latex helps ensure spinal alignment and maintains good sleeping posture throughout the night.',
      },
      {
        img: <ImgTerraBenefit2 />,
        eyebrow: 'INCREASED FIRMNESS',
        headline: 'Better manoeuvrability',
        body:
          'The extra firm surface of the Terra allows better movement on the mattress, perfect for sleepers who toss and turn throughout the night. It is also great for extended sitting and sleeping hours, and makes it easy to get in and out of bed.',
      },
      {
        img: <ImgTerraBenefit3 />,
        eyebrow: 'DUAL DENSITY',
        headline: 'Advanced latex technology',
        body:
          'Double thick layers of latex offer a premium surface that is extremely durable at great value. Dual densities help create ergonomic support for the entire body without sinking and sagging, specially targeted at areas around the lower back.',
      },
    ]}
  />
);

export default TerraMattressBenefitSection;
