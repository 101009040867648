import React from 'react';
import styled from 'styled-components';

import { ReviewGridSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const Section = styled(ReviewGridSection)``;

const MattressReviewGridSection: React.FC = () => {
  return (
    <Section
      filters={{ id: 'pbo1XADg2PacQiL6Kydx' }}
      allReviewsUrl={SiteUrls.OriginalMattressReview}
    />
  );
};

export default MattressReviewGridSection;
