import React from 'react';
import styled from 'styled-components';

import { enGB } from 'date-fns/locale';
import { DatePickerCalendar, DatePickerCalendarProps } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import SelectInput from './select-input';

const Container = styled.div`
  width: 100%;
`;

const DatePickerContainer = styled.div`
  .nice-dates-day_month {
    font-size: 60%;
  }
`;

const TimeSlotContainer = styled.div`
  margin: 20px 0;
`;

type Props = {
  className?: string;
  datePickerProps: Partial<DatePickerCalendarProps>;
  datePickerContainerClassName?: string;
  slotContainerClassName?: string;
  slotProps: {
    value: string;
    placeholder: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    options: string[];
  };
};

const CalendarPicker: React.FC<Props> = ({
  className,
  datePickerProps,
  datePickerContainerClassName,
  slotContainerClassName,
  slotProps,
}) => {
  return (
    <Container className={className}>
      <DatePickerContainer className={datePickerContainerClassName}>
        <DatePickerCalendar {...datePickerProps} locale={enGB} />
      </DatePickerContainer>
      <TimeSlotContainer className={slotContainerClassName}>
        {slotProps.options.length > 0 && (
          <SelectInput
            placeholder="Timing"
            value={slotProps.value}
            onChange={slotProps.onChange}
            options={slotProps.options.map(slot => slot)}
          />
        )}
        {slotProps.options.length === 0 &&
          'No slots available on selected date'}
      </TimeSlotContainer>
    </Container>
  );
};

export default CalendarPicker;
