import React from 'react';

import { WarrantyIntro } from '../common';

const MattressWarrantyIntroSection: React.FC = () => {
  return (
    <WarrantyIntro headline="10 Year Limited Warranty">
      The Woosa Mattress is engineered with quality materials to ensure superior
      durability night after night. In the unlikely case that your Woosa
      mattress Is met with any defect, we will replace or repair it without any
      charge to you. Your new Woosa mattress is covered by a 10 Year Limited
      Warranty, dating from the time you purchased your new mattress from Woosa
      Sleep Pte Ltd (“Woosa”). The details of this warranty, and some
      limitations, are provided in the complete Warranty Description below.
    </WarrantyIntro>
  );
};

export default MattressWarrantyIntroSection;
