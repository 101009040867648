import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import { useStaticQuery, graphql } from 'gatsby';
import {
  PRODUCT_EGYPTIAN_COTTON_BEDDING_SET,
  PRODUCT_EGYPTIAN_COTTON_FITTED_SHEET,
  PRODUCT_EGYPTIAN_COTTON_DUVET_COVER,
  PRODUCT_EGYPTIAN_COTTON_PILLOWCASE_SET,
} from 'gatsby-env-variables';

import { BedsheetCategoryInfo, BedsheetCategoryItem } from '../common';
import Container from '../common/container';
import { SiteUrls } from '../../utils/constants';

const ItemsContainer = styled(Container)`
  padding-top: 100px;
`;

const BedsheetCategoryItemsSection: React.FC = () => {
  const {
    allProducts: { edges },
  } = useStaticQuery(graphql`
    query {
      allProducts(filter: { displayInStore: { eq: true } }) {
        edges {
          node {
            id
            name
            collection
            media {
              path
              default
              type
            }
            variants {
              type
              thumbnail
              prices {
                amount
                currency
              }
              subVariants {
                assetId
                image
                key
                value
              }
            }
            displayInStore
          }
        }
      }
    }
  `);

  const products = {
    [PRODUCT_EGYPTIAN_COTTON_BEDDING_SET]: edges.find(
      ({ node }: { node: { id: string } }) =>
        node.id === PRODUCT_EGYPTIAN_COTTON_BEDDING_SET
    ).node,
    [PRODUCT_EGYPTIAN_COTTON_FITTED_SHEET]: edges.find(
      ({ node }: { node: { id: string } }) =>
        node.id === PRODUCT_EGYPTIAN_COTTON_FITTED_SHEET
    ).node,
    [PRODUCT_EGYPTIAN_COTTON_DUVET_COVER]: edges.find(
      ({ node }: { node: { id: string } }) =>
        node.id === PRODUCT_EGYPTIAN_COTTON_DUVET_COVER
    ).node,
    [PRODUCT_EGYPTIAN_COTTON_PILLOWCASE_SET]: edges.find(
      ({ node }: { node: { id: string } }) =>
        node.id === PRODUCT_EGYPTIAN_COTTON_PILLOWCASE_SET
    ).node,
  };

  const bedsheetItems = [
    {
      product: products[PRODUCT_EGYPTIAN_COTTON_BEDDING_SET],
      siteUrl: SiteUrls.EgyptianCottonBeddingSet,
    },
    {
      product: products[PRODUCT_EGYPTIAN_COTTON_DUVET_COVER],
      siteUrl: SiteUrls.EgyptianCottonDuvetCover,
    },
    {
      product: products[PRODUCT_EGYPTIAN_COTTON_PILLOWCASE_SET],
      siteUrl: SiteUrls.EgyptianCottonPillowcaseSet,
    },
    {
      product: products[PRODUCT_EGYPTIAN_COTTON_FITTED_SHEET],
      siteUrl: SiteUrls.EgyptianCottonFittedSheet,
    },
  ];

  return (
    <ItemsContainer>
      <Row>
        <Col lg={4}>
          <BedsheetCategoryInfo />
        </Col>
        <Col lg={8}>
          <Row>
            {bedsheetItems.map((item, key) => (
              <Col lg={6} key={key}>
                <BedsheetCategoryItem
                  product={item.product}
                  siteUrl={item.siteUrl}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </ItemsContainer>
  );
};

export default BedsheetCategoryItemsSection;
