import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import moment from 'moment';

import { Product } from '../../../types/product';
import { rem } from '../../../styles/utils';
import { getProductById } from '../../../utils/api';
import { currencyFormat } from '../../../utils';
import Spinner from '../spinner';
import { Props } from './';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
  position: relative;
`;

const Label = styled.label`
  font-weight: bold;
  font-size: ${rem(12)};
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
  display: block;

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  font-weight: normal;
  font-size: ${rem(12)};
  margin-bottom: 20px;

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

const RadioLabel = styled.label`
  position: relative;
  display: flex;
  padding-left: 15px;
  width: 100%;
  color: var(--font-secondary-color);

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: inline-block;
  }

  &:before {
    height: 16px;
    width: 16px;
    background-color: white;
    border-radius: 50%;
    top: -3px;
    left: -10px;
  }

  &:after {
    height: 8px;
    width: 8px;
    background-color: var(--leather);
    border-radius: 50%;
    left: -6px;
    top: 1px;
  }
`;

const RadioInput = styled.input`
  opacity: 0;

  & + ${RadioLabel}::after {
    content: none;
  }

  &:checked + ${RadioLabel}:after {
    content: '';
  }

  &:focus + ${RadioLabel}::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
`;

const Price = styled.span`
  margin-left: auto;
  font-size: ${rem(10)};
  font-weight: normal;
  color: var(--leather);

  ${media.lg`
    font-size: ${rem(12)};
  `}
`;

const RadioContainer = styled.div`
  display: flex;
`;

const ProductAddOn: React.FC<Props> = ({
  id,
  label,
  defaultText,
  products,
  selectedVariant,
  onChange,
}) => {
  const [addOns, setAddOns] = useState<Product[]>(products || []);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (!products) return;
    setIsLoading(true);
    const promises = products.map(({ id }) => getProductById(id));
    Promise.all(promises)
      .then(data => {
        for (const product of products) {
          const dataProduct = data.find(d => d.id === product.id);
          if (!dataProduct) continue;
          let variants = dataProduct.variants.filter(
            v => v.status !== 'Out of Stock'
          );
          if (selectedVariant !== null && variants.length > 1) {
            variants = variants.filter(
              v => v.type.toLowerCase() === selectedVariant?.type.toLowerCase()
            );
          }
          if (variants.length === 0) continue;
          product.variants = variants;
        }

        setAddOns([...products]);

        if (selectedIndex > -1) {
          handleChange(products[selectedIndex], selectedIndex);
        }
      })
      .finally(() => setIsLoading(false));
  }, [products, selectedVariant]);

  const handleChange = (product: Product | null, index: number) => {
    setSelectedIndex(index);
    if (typeof onChange !== 'function') return;
    if (product !== null && product.variants.length > 0) {
      const variant = product.variants[0];
      const price = variant.prices[0];

      onChange({
        id: product.id,
        name: product.name,
        collection: product.collection,
        image:
          product.media.find(
            m =>
              (m.default && m.type !== 'video') ||
              (!m.default && m.type === 'image')
          )?.path || '',
        comparePrice: price.compareAmount || 0,
        currency: price.currency,
        qty: 1,
        subtotal: price.amount,
        unitPrice: price.amount,
        variant: variant.type,
        isNew: true,
        status: variant.status,
        statusDate:
          variant.statusDate &&
          moment.unix(variant.statusDate._seconds).toDate(),
      });
    } else {
      onChange(null);
    }
  };

  return (
    <Container>
      <Label>{label}</Label>
      <List>
        <Item>
          <RadioContainer>
            <RadioInput
              id={`${id}-0`}
              type="radio"
              name="add-on"
              defaultChecked
              onClick={() => handleChange(null, -1)}
            />
            <RadioLabel htmlFor={`${id}-0`}>{defaultText}</RadioLabel>
          </RadioContainer>
        </Item>
        {addOns &&
          addOns.map((product, key) => (
            <Item key={key}>
              <RadioContainer>
                <RadioInput
                  id={`${id}-${key + 1}`}
                  type="radio"
                  name="add-on"
                  onClick={() => handleChange(product, key)}
                />
                <RadioLabel htmlFor={`${id}-${key + 1}`}>
                  Add {product.name}
                  <Price>
                    + {currencyFormat(product.variants[0].prices[0].amount)}
                  </Price>
                </RadioLabel>
              </RadioContainer>
            </Item>
          ))}
      </List>
      <Spinner isLoading={isLoading} size={30} />
    </Container>
  );
};

export default ProductAddOn;
