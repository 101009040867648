import React, { useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { lh, rem } from '../../styles/utils';
import CustomDropdown from './custom-dropdown';

const Section = styled.section``;

const DropdownWrapper = styled.div`
  max-width: 224px;
  margin-bottom: 24px;

  label {
    font-weight: bold;
  }
`;

const Content = styled.div`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-size: ${rem(16)};
  line-height: ${lh(21, 16)};
`;

const Image = styled.div``;

type SizeOption = {
  size: string;
  content: React.ReactNode;
  image: React.ReactNode;
};

type Props = {
  className?: string;
  sizes: SizeOption[];
};

const ProductSizeSelectorSection: React.FC<Props> = ({ className, sizes }) => {
  const sizeOptions = sizes.map(({ size }) => ({ label: size, value: size }));
  const [selectedSize, setSelectedSize] = useState<SizeOption>(sizes[0]);

  return (
    <Section className={className}>
      <Row>
        <Col xsOrder={2} xs={12} sm={6} lg={5}>
          <DropdownWrapper>
            <CustomDropdown
              options={sizeOptions}
              initialOption={sizeOptions[0]}
              onChange={selectedOption => {
                if (!selectedOption) return;
                const newSelectedSize = sizes.find(
                  ({ size }) => size === selectedOption.value
                );
                if (!newSelectedSize) return;
                setSelectedSize(newSelectedSize);
              }}
            />
          </DropdownWrapper>
          <Content>{selectedSize.content}</Content>
        </Col>
        <Col xsOrder={1} xs={12} sm={6} lg={7}>
          <Image>{selectedSize.image}</Image>
        </Col>
      </Row>
    </Section>
  );
};

export default ProductSizeSelectorSection;
