import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import WaveLineAnimation from './wave-line-animation';
import BlobAnimation from './blob-animation';
import { sectionMarginStyle } from '../../styles/utils';

const Section = styled.section`
  width: 100%;
  background-color: var(--mantle);
  position: relative;
  overflow: hidden;
  ${sectionMarginStyle}

  ${media.md`
    padding-top: 80px;
    padding-bottom: 80px;
  `}

  ${media.lg`
    margin-bottom: 80px;
  `}

  ${media.xl`
    padding-top: 120px;
    padding-bottom: 200px;
  `}

  ${media.xxl`
    padding-top: 120px;
    padding-bottom: 120px;
  `}
`;

const Blob = styled.div`
  position: absolute;
  width: 250px;
  z-index: 0;
  opacity: 0.3;

  ${media.md`
    width: 480px;
  `}

  ${media.xl`
    width: 600px;
  `}

  &:first-child {
    top: -15%;
    right: -30%;

    ${media.md`
      top: -30%;
    `}

    ${media.xl`
      top: -40%;
      right: -10%;
    `}
  }

  &:nth-child(2) {
    left: -15%;
    top: 20%;

    ${media.md`
      left: -30%;
      top: 60%;
    `}

    ${media.lg`
      left: -10%;
      top: 55%;
    `}
  }
`;

const Wave = styled.div`
  position: absolute;
  z-index: 0;
  bottom: -10%;
  max-width: 30%;
  right: -20%;
  opacity: 0.4;
  transform: rotate(-45deg);

  ${media.xl`
    right: -10%;
    transform: rotate(-30deg);
  `}
`;

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
};

const WaveBlobAnimationSection: React.FC<Props> = ({ children, className }) => {
  return (
    <Section className={className}>
      <Blob>
        <BlobAnimation color="#748175" />
      </Blob>
      <Blob>
        <BlobAnimation color="white" />
      </Blob>
      <Wave>
        <WaveLineAnimation color="#ebe5de" numOfWaves={8} />
      </Wave>
      {children}
    </Section>
  );
};

export default WaveBlobAnimationSection;
