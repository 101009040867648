import React from 'react';
import styled from 'styled-components';
import { PRODUCT_ADJUSTABLE_BASE_ID } from 'gatsby-env-variables';

import { ReviewGridSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const Section = styled(ReviewGridSection)``;

const BedFrameReviewGridSection: React.FC = () => {
  return (
    <Section
      filters={{ id: PRODUCT_ADJUSTABLE_BASE_ID }}
      allReviewsUrl={SiteUrls.BedFrameReview}
    />
  );
};

export default BedFrameReviewGridSection;
