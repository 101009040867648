import React from 'react';
import { TempAddressModal } from '../common';

const HomeControllerSection: React.FC = () => {
  return (
    <div>
      <TempAddressModal />
    </div>
  );
};

export default HomeControllerSection;
