import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { media } from 'styled-bootstrap-grid';

import { Cart, Modal, Spinner, Link as GatsbyLink } from '../common';
import { rem } from '../../styles/utils';
import { flatButtonStyle } from '../../styles/utils';
import { Order, Schedule } from '../../types/order';
import { getOrderByPaymentToken } from '../../utils/api';
import { stripTags, unslugify } from '../../utils';
import { gridTheme } from '../../styles/grid';
import {
  FacebookPixelEvent,
  GTagEvent,
  GTagType,
  SiteUrls,
  TikTokEvent,
} from '../../utils/constants';
import { useAuthState } from '../../hooks/auth-context';
import { RegisterForm } from '../auth';
import { fbq, gtag, toGtagCart, toTtqCart, ttq } from '../../utils/analytics';
import { CartEnum } from '../../hooks/cart-context/cart-context-config';
import { CheckoutEnum } from '../../hooks/checkout-context/checkout-context-config';
import { useCartDispatch, useCartState } from '../../hooks/cart-context';
import { USE_STRIPE_WEBHOOK } from 'gatsby-env-variables';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);

  ${media.lg`
    padding: 120px ${gridTheme.container.padding}px 0;
    max-width: ${gridTheme.container.maxWidth.lg}px;
    margin: 0 auto;
  `}

  ${media.xl`
    max-width: ${gridTheme.container.maxWidth.xl}px;
  `}
`;

const TextBlock = styled.div`
  width: 100%;
  padding: 0 30px;
`;

const Headline = styled.h2`
  color: var(--font-primary-color);
  font-family: var(--font-header);
  text-align: center;
  font-size: ${rem(20)};

  ${media.md`
    font-size: ${rem(22)};
  `}

  ${media.lg`
    font-size: ${rem(24)};
  `}
`;

const Paragraph = styled.p`
  color: var(--font-secondary-color);
  font-size: ${rem(10)};
  line-height: 1.5;
  text-align: center;

  ${media.md`
    font-size: ${rem(12)};
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.lg`
    font-size: ${rem(14)};
  `}
`;

const OrderDetails = styled.div`
  margin-top: 20px;
  border-top: 1px solid var(--border-color);
  padding-top: 10px;
  width: 100%;
`;

const OrderDetailsLayout = styled.div`
  ${media.lg`
    display: flex;
  `}
`;

const Label = styled.h3`
  font-size: ${rem(12)};
  font-weight: medium;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: var(--font-primary-color);

  ${media.md`
    font-size: ${rem(14)};
  `}

  ${media.lg`
    font-size: ${rem(16)};
    text-align: left;
  `}
`;

const Subheadline = styled(Label)`
  ${media.lg`
    padding-left: 20px;
  `}
`;

const CartContainer = styled.div`
  ${media.lg`
    flex-grow: 2;
    padding-right: 10%;

    .cart-items ul {
      max-height: none;
      overflow-y: visible;
    }
  `}
`;

const PaymentInfo = styled.div`
  padding: 0 15px;
  color: var(--font-secondary-color);
  font-size: ${rem(12)};

  ${media.md`
    font-size: ${rem(14)};
  `}

  ${media.lg`
    font-size: ${rem(16)};
    max-width: 30%;
  `}

  ${Subheadline} {
    text-align: left;
  }

  dl {
    margin-bottom: 40px;
  }

  dt {
    margin-bottom: 2px;
  }
`;

const Link = styled(GatsbyLink)`
  ${flatButtonStyle}
  width: 60%;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;

  ${media.md`
    max-width: 150px;
  `}

  ${media.lg`
    margin-top: 60px;
  `}
`;

const GuestConversionTopic = styled.div`
  background-color: var(--iron);
  border: 1px solid var(--jumbo);
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
`;

const InlineButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const RegisterModal: React.FC<{
  isActive: boolean;
  onClickClose: () => void;
  firstName?: string;
  lastName?: string;
  email?: string;
}> = ({ isActive, onClickClose, firstName, lastName, email }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hideForm, setHideForm] = useState(false);

  return (
    <Modal
      className="order-summary-register-modal"
      isActive={isActive}
      onClickClose={onClickClose}
    >
      {!hideForm && (
        <RegisterForm
          submitText="Create account"
          defaultValues={{
            firstName,
            lastName,
            email,
          }}
          onBeforeRequest={() => setIsLoading(true)}
          onAfterRequest={result => {
            setIsLoading(false);
            if (result) {
              setHideForm(true);
            }
          }}
        />
      )}
      {hideForm && <Headline>Thank you for signing up!</Headline>}
      <Spinner isLoading={isLoading} />
    </Modal>
  );
};

const OrderSummarySection: React.FC = () => {
  const location = useLocation();
  const { accessToken } = useAuthState();
  const cartDispatch = useCartDispatch();
  const cartState = useCartState();
  const { token } = location.search
    ? queryString.parse(location.search)
    : { token: '' };
  const [order, setOrder] = useState<Order | null>(null);
  const [displayRegister, setDisplayRegister] = useState(false);

  useEffect(() => {
    (async function () {
      if (!token) return;
      const paymentToken = unslugify(
        typeof token === 'string' ? token : token[0]
      );

      const result = await getOrderByPaymentToken(stripTags(paymentToken));
      setOrder(result);

      // TODO: Fix issue with not clearing localstorage
      cartDispatch({ type: 'CLEAR_CART' });
      [CartEnum.LOCAL_STORAGE_KEY, CheckoutEnum.ADDRESS].forEach(key => {
        const k = key as string;
        localStorage.removeItem(k);
      });

      fbq(FacebookPixelEvent.Purchase, {
        value: result?.totalPrice || 0,
        currency: result?.currency || 'SGD',
      });

      gtag('event', 'conversion', {
        send_to: 'AW-773591121/jwDFCPKsppMBENGg8PAC',
        transaction_id: result?.refNum,
      });

      gtag(GTagType.Event, GTagEvent.Purchase, {
        ...toGtagCart(cartState),
        transaction_id: result?.refNum,
        value: result?.totalPrice,
        tax: result?.tax?.amount,
      });

      ttq(TikTokEvent.CompletePayment, toTtqCart(cartState));
    })();
  }, [token]);

  const renderDeliveryDate = (schedule: Schedule) => {
    if (schedule.date instanceof Date || typeof schedule.date === 'string') {
      return moment(schedule.date).format('DD-MM-YYYY');
    } else {
      return moment.unix(schedule.date._seconds).format('DD-MM-YYYY');
    }
  };

  const renderPaymentDetails = () => {
    if (order) {
      const payments = (order.payments || []).filter(
        p => p.status === 'success'
      );
      if (payments.length) {
        return (
          <>
            {payments.map((p, i) => (
              <dl key={p.uuid || i}>
                <dt>Paid by: {(p.remarks || '').replace('Payment via', '')}</dt>
                <dt>
                  Payment Status: {_.capitalize(p.status) || 'Processing'}
                </dt>
                <dt>
                  Transaction Time:{' '}
                  {moment(p.paidDate).format('HH:mm:ss, DD-MM-YYYY')}
                </dt>
              </dl>
            ))}
          </>
        );
      }
    }

    return (
      <dl>
        <dt>
          Payment is currently being processed, you will receive the order
          invoice once payment has been successfully processed.
        </dt>
      </dl>
    );
  };

  if (!order) return null;

  return (
    <Container>
      <TextBlock>
        <Headline>Thank you for your order.</Headline>
        <Paragraph>
          {!USE_STRIPE_WEBHOOK && <>An invoice has been sent to your email.</>}
          {/* To check delivery updates, sign in to your woosa account and go to ‘My Order’. */}
        </Paragraph>
        {!accessToken && (
          <>
            <GuestConversionTopic>
              <Paragraph>
                Your nights just got better. Setting up your Woosa Shopping
                account automatically registers your product&apos;s warranty and
                allows you to track your orders. Sign up{' '}
                <InlineButton
                  onClick={() => setDisplayRegister(!displayRegister)}
                >
                  here
                </InlineButton>
                .
              </Paragraph>
            </GuestConversionTopic>
            <RegisterModal
              isActive={displayRegister}
              onClickClose={() => setDisplayRegister(false)}
              firstName={order.billing.firstName}
              lastName={order.billing.lastName}
              email={order.billing.email}
            />
          </>
        )}
        <Paragraph>
          <b>Reference no.:</b> &nbsp;
          {order.refNum}
        </Paragraph>
      </TextBlock>
      <OrderDetails>
        <Subheadline>Your order</Subheadline>
        <OrderDetailsLayout>
          <CartContainer>
            <Cart
              className="cart-items"
              disableQty={true}
              products={order.products}
              discount={order.discount}
              promos={order.promos}
              shippingFee={order.shippingFee}
              amounts={{
                totalAmount: order.finalPrice || 0,
                amount: order.totalPrice || 0,
                compareAmount: order.products.reduce(
                  (prev, p) => prev + (p.comparePrice || p.unitPrice) * p.qty,
                  0
                ),
              }}
              referralCode={order.referral?.code}
              tax={order.tax}
            />
          </CartContainer>
          <PaymentInfo>
            <Label>Payment Details</Label>
            {renderPaymentDetails()}
            {order.schedules.length > 0 && (
              <>
                <Label>Delivery Details</Label>
                <dl>
                  <dt>Date: {renderDeliveryDate(order.schedules[0])}</dt>
                  <dt>Timing: {order.schedules[0].time}</dt>
                </dl>
              </>
            )}
          </PaymentInfo>
        </OrderDetailsLayout>
      </OrderDetails>
      <Link to={SiteUrls.Home}>Back to Home</Link>
    </Container>
  );
};

export default OrderSummarySection;
