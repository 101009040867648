import React from 'react';
import styled from 'styled-components';
import { Row as GridRow, Col as GridCol, media } from 'styled-bootstrap-grid';
import { useInView } from 'react-intersection-observer';

import { rem } from '../../../styles/utils';
import { Benefit } from '../../../types/component';
import { Config } from '../../../styles/enums';

const Row = styled(GridRow)`
  ${media.md`
    padding: 20px 0;
  `}

  ${media.xl`
    padding: 40px 0;
  `}
`;

const Col = styled(GridCol)`
  ${media.lg`
    padding-left: 30px;
    padding-right: 30px;
  `}
`;

const Headline = styled.h3`
  font-family: var(--font-header);
  font-size: ${rem(20)};
  line-height: 1.2;
  font-weight: normal;
  color: var(--font-primary-color);

  ${media.xl`
    font-size: ${rem(30)};
  `}
`;

const Subheadline = styled.strong`
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  margin-top: -5px;
  margin-bottom: -8px;
  letter-spacing: 1px;
`;

const Eyebrow = styled.strong`
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border-bottom: 1px solid var(--font-secondary-color);
  display: inline-block;
  padding-bottom: 5px;
`;

const Body = styled.p`
  line-height: 1.2;
`;

const ImgContainer = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  ${Row}:nth-child(even) & {
    transform: translateX(-100%);
  }

  ${Row}:nth-child(odd) & {
    transform: translateX(100%);
  }

  &.active {
    opacity: 1;

    ${Row}:nth-child(even) &,
    ${Row}:nth-child(odd) & {
      transform: translateX(0);
    }
  }
`;

const Content = styled.div`
  padding: ${Config.CONTAINER_PADDING}px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  ${Row}:nth-child(odd) & {
    transform: translateX(-100%);
  }

  ${Row}:nth-child(even) & {
    transform: translateX(100%);
  }

  &.active {
    opacity: 1;

    ${Row}:nth-child(even) &,
    ${Row}:nth-child(odd) & {
      transform: translateX(0);
    }
  }

  ${media.md`
    padding: 0;
  `}
`;

export type Props = {
  benefit: Benefit;
  index: number;
};

const ProductBenefitRow: React.FC<Props> = ({ benefit, index }) => {
  const [ref, inView] = useInView({ threshold: 0.7, triggerOnce: true });
  const { headline, eyebrow, img, body, subheadline } = benefit;

  return (
    <Row ref={ref}>
      <Col md={6} mdOrder={index % 2 === 0 ? 'last' : 'first'}>
        <ImgContainer className={inView ? 'active' : ''}>{img}</ImgContainer>
      </Col>
      <Col md={6}>
        <Content className={inView ? 'active' : ''}>
          <Eyebrow>{eyebrow}</Eyebrow>
          <Headline>{headline}</Headline>
          {subheadline && <Subheadline>{subheadline}</Subheadline>}
          <Body>{body}</Body>
        </Content>
      </Col>
    </Row>
  );
};

export default ProductBenefitRow;
