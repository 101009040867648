import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import moment, { Moment } from 'moment';

import { TokenOrderDetail, CalendarPicker, Spinner } from '../common';
import { rem, flatButtonStyle } from '../../styles/utils';
import { ProductOrderItem, Order, Schedule } from '../../types/order';
import { DeliverySlot, Slot } from '../../types/deliverySlot';
import {
  getDeliverySlots,
  addDeliveryScheduleByPaymentToken,
} from '../../utils/api';
import { getDefaultMinDeliveryDate } from '../../utils';

const IntroContainer = styled.div`
  text-align: center;

  ${media.md`
    margin-bottom: 20px;
  `}
`;

const Headline = styled.h1`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-size: ${rem(20)};
`;

const Paragraph = styled.p``;

const ErrorMessage = styled.span`
  display: block;
  margin-bottom: 20px;
  color: red;
`;

const PickerContainer = styled.div`
  ${media.md`
    max-width: 60%;
    margin: 0 auto;
  `}
`;

const Button = styled.button`
  ${flatButtonStyle}
`;

const getProductEarliestDeliveryDate = (order: Order): Date => {
  const defaultMinDate = getDefaultMinDeliveryDate();
  const { products } = order;

  // TODO: Maybe need to get real-time product status and date instead
  return products.reduce((earliest, product) => {
    if (product.status !== 'Pre-Order' || !product.statusDate) return earliest;

    const productEarliest = moment(product.statusDate);

    return productEarliest.isAfter(moment(earliest))
      ? productEarliest.toDate()
      : earliest;
  }, defaultMinDate);
};

const SelectDeliverySection: React.FC = () => {
  const [paymentToken, setPaymentToken] = useState('');
  const [order, setOrder] = useState<Order | null>(null);
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [time, setTime] = useState<string>('');
  const [minDate, setMinDate] = useState<Date>(
    moment().add(1, 'days').toDate()
  );
  const [date, setDate] = useState<Date>(minDate);
  const [slots, setSlots] = useState<Slot[]>([]);
  const deliverySlotsRef = useRef<DeliverySlot[] | null>(null);

  const getDeliverySlotsForTheMonth = async (month: Moment) => {
    setIsProcessing(true);
    const startDate = month.startOf('month').toDate();
    const endDate = month.endOf('month').toDate();
    try {
      deliverySlotsRef.current = await getDeliverySlots(startDate, endDate, '');
      handleChangeDate(date);
    } catch (error) {
      setError(
        'Oops something went wrong. Please contact us for further assistance.'
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const validateActionable = () => {
    if (!order) return;
    const unscheduledProducts = handleFilterProduct(order);
    if (unscheduledProducts.length > 0) return;
    window.location.href = '/404';
  };

  useEffect(() => {
    if (!deliverySlotsRef.current && order) {
      (async () => {
        const orderMinDate = getProductEarliestDeliveryDate(order);
        setMinDate(orderMinDate);
        await getDeliverySlotsForTheMonth(moment(minDate));
      })();

      validateActionable();
    }
  }, [order]);

  const handleFilterProduct = (order: Order): ProductOrderItem[] => {
    const { products, schedules } = order;

    const updatedProducts = products.map(p => {
      // find product in schedules
      const productInSchedules = (schedules || []).filter(s =>
        s.products?.some(sp => sp.uuid === p.uuid)
      );

      // find the qty that has been added to schedule
      const productScheduleQty = productInSchedules.reduce((qty, pq) => {
        const subTotalQty = pq.products?.reduce((subQty, pp) => {
          return subQty + (pp.uuid === p.uuid ? pp.qty + subQty : 0);
        }, 0);
        return qty + (subTotalQty || 0);
      }, 0);

      // reduce qty of the product
      p.qty -= productScheduleQty;

      return p;
    });

    // filter out products that doesn't have any more qty
    return updatedProducts.filter(p => p.qty > 0);
  };

  const handleChangeDate = async (selectedDate: Date | null) => {
    if (!selectedDate) return;
    const availableSlots: Slot[] = [];
    if (!moment(selectedDate).isSame(moment(date), 'month')) {
      await getDeliverySlotsForTheMonth(moment(selectedDate));
    }
    if (deliverySlotsRef.current) {
      deliverySlotsRef.current
        .filter(d => moment(d.date).isSame(moment(selectedDate), 'date'))
        .forEach(d => d.slots.forEach(s => availableSlots.push(s)));
    }

    setSlots(availableSlots);
    setDate(selectedDate);
    setDefaultTime(selectedDate);
  };

  const setDefaultTime = (selectedDate: Date) => {
    if (deliverySlotsRef.current && deliverySlotsRef.current.length > 0) {
      const selectedDeliverySlot = deliverySlotsRef.current.find(
        slot => slot.date.getDate() === selectedDate.getDate()
      );

      if (
        selectedDeliverySlot &&
        selectedDeliverySlot.slots &&
        selectedDeliverySlot.slots.length > 0
      ) {
        setTime(selectedDeliverySlot.slots[0].time);
        return;
      }
    }

    setTime('');
  };

  const handleSubmit = async () => {
    if (!deliverySlotsRef.current) return;
    if (isProcessing) return;
    setIsProcessing(true);

    const selectedDateSlot = deliverySlotsRef.current.find(
      slot => slot.date.getDate() === date.getDate()
    );

    if (!selectedDateSlot) return;

    const newSchedule: Schedule = {
      id: selectedDateSlot.id,
      date,
      time,
      createdUnix: moment().valueOf(),
    };

    const result = await addDeliveryScheduleByPaymentToken(
      paymentToken,
      newSchedule
    );

    setIsProcessing(false);
    setIsDone(result);

    if (!result) {
      setError('Unexpected error. Please contact us for further assistance.');
    }
  };

  return (
    <TokenOrderDetail
      onLoad={(token, order) => {
        setPaymentToken(token);
        setOrder(order);
      }}
      onFilterProducts={handleFilterProduct}
      isProcessing={isProcessing}
      hidePaymentInfo
    >
      <IntroContainer>
        {!isDone && (
          <>
            <Headline>Thank you for your order.</Headline>
            <Paragraph>
              Please select delivery dates for your products
            </Paragraph>
            <PickerContainer>
              <CalendarPicker
                datePickerContainerClassName="date-picker-container"
                datePickerProps={{
                  minimumDate: minDate,
                  date,
                  onDateChange: handleChangeDate,
                }}
                slotContainerClassName="slot-container"
                slotProps={{
                  placeholder: 'Timing',
                  value: time,
                  onChange: event => setTime(event.target.value),
                  options: slots.map(({ time }) => time),
                }}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Button disabled={isProcessing} onClick={handleSubmit}>
                Submit
              </Button>
            </PickerContainer>
          </>
        )}
        {isDone && (
          <Headline>
            You have successfully scheduled your products for delivery!
          </Headline>
        )}
      </IntroContainer>
      <Spinner isLoading={isProcessing} />
    </TokenOrderDetail>
  );
};

export default SelectDeliverySection;
