import styled from 'styled-components';

import { FormLabel } from '../common';

const WizardEyebrow = styled(FormLabel)`
  color: var(--font-primary-color);
  font-weight: medium;
  margin-bottom: 10px;
`;

export default WizardEyebrow;
