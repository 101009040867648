import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { BundleProduct } from '../../types/promo';
import { rem } from '../../styles/utils';
import SelectInput from './select-input';
import { isArrayObjectEqual } from '../../utils';

const Container = styled.div`
  font-family: var(--font-body);
  margin-bottom: 20px;
`;

const Title = styled.strong`
  display: block;
  margin-bottom: 15px;
  font-size: ${rem(16)};
`;

const ProductList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
`;

const ProductItem = styled.li`
  width: 45%;
  margin-bottom: 15px;

  &:nth-child(even) {
    padding-left: 2.5%;
  }

  &:nth-child(odd) {
    padding-right: 2.5%;
  }
`;

const ProductImage = styled.img`
  height: 100%;
`;

const ProductImageContainer = styled.div`
  width: 100%;
  height: 90px;
  overflow: hidden;

  ${media.lg`
    height: 180px;
  `}
`;

const ProductName = styled.strong`
  font-size: ${rem(14)};
  margin: 5px 0;
  display: block;
  font-weight: 500;

  ${media.lg`
    margin: 10px 0;
    font-size: ${rem(16)};
  `}
`;

const Select = styled(SelectInput)`
  padding: 5px;
  font-size: ${rem(14)};

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

type Props = {
  className?: string;
  title: string;
  products: BundleProduct[];
  qty: number;
  isShowPromoBundleModal?: boolean;
  onChange?: (selectedProducts: BundleProduct[]) => void;
};

const PromoBundleItem: React.FC<Props> = ({
  className,
  title,
  products,
  qty,
  isShowPromoBundleModal,
  onChange,
}) => {
  const [selectedProducts, setSelectedProducts] = useState<BundleProduct[]>([]);
  const emptyOption = 'Select';
  const qtyOptions =
    products.length === 1
      ? [qty.toString()]
      : [emptyOption, '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].slice(
          0,
          qty + 1
        );

  useEffect(() => {
    if (isShowPromoBundleModal && products.length === 1) {
      const defaultSelectedProducts = [{ ...products[0], qty }];
      setSelectedProducts(defaultSelectedProducts);
      if (typeof onChange === 'function') {
        onChange(defaultSelectedProducts);
      }
    }
  }, [isShowPromoBundleModal]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(selectedProducts);
    }
  }, [selectedProducts]);

  const getTotalSelectedQty = (productsSeleted: BundleProduct[]) => {
    return productsSeleted.reduce((total, p) => (p.qty || 0) + total, 0);
  };

  const renderVariant = (p: BundleProduct) => {
    const builder: string[] = [p.variant];
    if (p.subVariants) {
      builder.push(...p.subVariants.filter(v => v.value).map(v => v.value));
    }
    return builder.join(', ');
  };

  return (
    <Container className={className}>
      <Title>
        {title} (Choose {qty})
      </Title>
      {products && (
        <ProductList>
          {products.map(bp => {
            const { variant, id, name, image, subVariants } = bp;

            return (
              <ProductItem key={`${id}-${variant}`}>
                <ProductImageContainer>
                  <ProductImage src={image} alt="" role="presentation" />
                </ProductImageContainer>
                <ProductName>
                  {name} - {renderVariant(bp)}
                </ProductName>
                <Select
                  placeholder="Select"
                  value={
                    selectedProducts
                      .find(
                        p =>
                          p.id === id &&
                          p.variant === variant &&
                          isArrayObjectEqual(p.subVariants, subVariants, [
                            'key',
                            'value',
                          ])
                      )
                      ?.qty?.toString() || emptyOption
                  }
                  options={qtyOptions}
                  onChange={event => {
                    let productsSelected = [...selectedProducts];
                    productsSelected = productsSelected.filter(
                      p =>
                        p.id !== id &&
                        p.variant !== variant &&
                        !isArrayObjectEqual(p.subVariants, subVariants, [
                          'key',
                          'value',
                        ])
                    );
                    if (event.target.value !== emptyOption) {
                      const selectedQty = parseInt(event.target.value);
                      let totalSelectedQty = getTotalSelectedQty(
                        productsSelected
                      );

                      while (totalSelectedQty + selectedQty > qty) {
                        productsSelected.splice(0, 1);
                        totalSelectedQty = getTotalSelectedQty(
                          productsSelected
                        );
                      }

                      productsSelected.push({
                        variant,
                        id,
                        name,
                        image,
                        qty: selectedQty,
                        subVariants,
                      });
                    }

                    setSelectedProducts(productsSelected);
                    if (typeof onChange === 'function') {
                      onChange(productsSelected);
                    }
                  }}
                />
              </ProductItem>
            );
          })}
        </ProductList>
      )}
    </Container>
  );
};

export default PromoBundleItem;
