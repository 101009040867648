import React from 'react';
import styled from 'styled-components';

import { SimpleSplitSection, Link } from '../common';
import {
  Wrapper,
  LeftTitle,
  fontStyle,
  PlusIconList,
  PlusIconListItem,
  OrderedList,
  OrderedListItem,
} from '../common/simple-split-section';
import { SiteUrls } from '../../utils/constants';

const Container = styled(Wrapper)``;

const Row = styled(SimpleSplitSection)`
  margin-top: 40px;
`;

const Paragraph = styled.p`
  ${fontStyle}
  margin-top: 0;
`;

const NestedPlusIconList = styled(PlusIconList)`
  margin-top: 15px;
`;

const FreeTrialContentSection: React.FC = () => {
  const data = [
    {
      title: 'Understanding the Woosa Sleep Trial',
      content: (
        <Paragraph>
          The Woosa Sleep Trial serves as our return policy.
          <br />
          <br />
          We recommend sleeping on your Woosa mattress for at least 30 days to
          allow your body to properly adjust to the pressure-reliving properties
          of the mattress. For our pillow, especially for users changing from a
          down pillow, we recommend giving yourself at least 7 days to adjust to
          the plush supportive properties of the Woosa Pillow.
          <br />
          <br />
          You must purchase the products to start the sleep trial. Your sleep
          trial starts from the day you receive your product, not your order
          date. You may initiate return all Woosa products within the first 30
          days from the date of delivery. Returns must be initiated via email at
          support@woosa.sg before the end of the 30th day (including weekends
          and public holidays) of receiving your product.
          <br />
          <br />
          Example: You purchased the order on the 1st of October, your return
          request must be filed to us the end of 30th October.
          <br />
          <br />
          All returned products are either donated to local social enterprises
          and charities or disposed properly at recycling centres. We never
          resell used Woosa products.
          <br />
          <br />
          Subject to terms and conditions:
        </Paragraph>
      ),
    },
    {
      title: 'Return policy for Mattress',
      content: (
        <>
          <Paragraph>
            <strong>
              <u>
                Free returns are applicable for one mattress per order, per
                address, within the first 30 days of receiving your item, or
                prior to delivery.{' '}
              </u>
            </strong>
          </Paragraph>
          <Paragraph>
            For x2 single mattresses purchased under split king bundle, a
            restocking fee of $300 is applicable for the return of&nbsp;
            <b>both</b>&nbsp;mattresses within the first 30 days of receiving
            your item.
          </Paragraph>
          <Paragraph>
            We will pick up the mattress for free from the original delivery
            address, subject to our earliest available pick up slot. Charges are
            applicable for locations with no lift access. For addresses with{' '}
            <b>no lift access </b>, a stairs fee of $10 is chargeable per level
            per item.
          </Paragraph>
          <Paragraph>
            <i>What is not returnable?</i>
          </Paragraph>
          <PlusIconList>
            <PlusIconListItem>
              Custom sized mattresses are not applicable for returns
            </PlusIconListItem>
            <PlusIconListItem>
              Products must be in donatable condition to be eligible for return
              (e.g., no stains, tears or other soiling including odors).
            </PlusIconListItem>
            <PlusIconListItem>
              Only the original purchaser is eligible for a return
            </PlusIconListItem>
            <PlusIconListItem>
              Only items at the original billing or shipping address will be
              eligible for the return
            </PlusIconListItem>
            <PlusIconListItem>
              Products purchased from 3rd party marketplaces (Shopee/Lazada)
              will have to follow their respective platform return terms and
              conditions.
            </PlusIconListItem>
          </PlusIconList>
          <Paragraph>
            Please ensure that your purchase fulfils the return policy before
            filing for a return. We have the right to exercise our own
            discretion to reject return requests that do not adhere to our
            policy.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Return policy for Bases',
      content: (
        <>
          <Paragraph>
            <strong>
              <u>
                A restocking fee of $300 will be charged for return of one bed
                base per order, per address within the first 30 days of
                receiving your item.
              </u>
            </strong>
          </Paragraph>
          <Paragraph>
            Our bed bases include The Storage Base, The Adjustable Base, The
            Adjustable Base with headboard and side frames, The Woosa Boxspring,
            The Woosa Boxspring with headboard and sideframes.
          </Paragraph>
          <Paragraph>
            We will pick it up the base from you from the original delivery
            address, subject to our earliest available pick up slot. Charges are
            applicable for locations with no lift access. For addresses with{' '}
            <b>no lift access</b>, a stairs fee of $10 is chargeable per level
            per bulky item. per item.
          </Paragraph>
          <Paragraph>
            For avoidance of doubt, please clarify with us on the stairs fee
            payable for the return of your order if you live in a location with{' '}
            <b>no lift access</b>.
          </Paragraph>
          <Paragraph>
            <i>What is not returnable?</i>
          </Paragraph>
          <PlusIconList>
            <PlusIconListItem>
              Custom sized bases are not applicable for returns- this includes
              custom sized bases, headboards, side frames, increased or reduced
              thickness and other customization request.
            </PlusIconListItem>
            <PlusIconListItem>
              Products must be in donatable condition to be eligible for return
              (e.g., no stains, tears or other soiling including odors).
            </PlusIconListItem>
            <PlusIconListItem>
              {' '}
              Products shipped outside of Singapore are not eligible for return
            </PlusIconListItem>
            <PlusIconListItem>
              Only the original purchaser is eligible for a return
            </PlusIconListItem>
            <PlusIconListItem>
              Only items at the original billing or shipping address will be
              eligible for the return
            </PlusIconListItem>
            <PlusIconListItem>
              The Woosa Adjustable Base will not be eligible for a return if the
              adjustable base has been mishandled whether in transit or by other
              means, subjected to physical or electrical abuse or misuse, or
              otherwise operated in any way inconsistent with the operation and
              maintenance procedures outlined in the Owner&apos;s Manual
            </PlusIconListItem>
            <PlusIconListItem>
              You may incur a replacement charge for returning products that do
              not meet our Return Conditions. For a full list of our replacement
              costs, please refer our our price list{' '}
              <Link to={`${SiteUrls.Faq}#cost-of-replacement-parts`}>here</Link>
              .
            </PlusIconListItem>
            <PlusIconListItem>
              Products purchased from 3rd party marketplaces (Shopee/Lazada)
              will have to follow their respective platform return terms and
              conditions.
            </PlusIconListItem>
          </PlusIconList>
          <Paragraph>
            Please ensure that your purchase fulfils the return policy before
            filing for a return. We have the right to exercise our own
            discretion to reject return requests that do not adhere to our
            policy.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Return policy for Accessories',
      content: (
        <>
          <Paragraph>
            <b>
              Free returns are applicable for one accessory per order, per
              address, within the first 30 days of receiving your item, or prior
              to delivery.
            </b>
          </Paragraph>
          <Paragraph>
            All our accessories are applicable for free returns within the first
            30 days of receiving your item. Our accessories include The Woosa
            Pillow, The Seres Silk Duvet, The Mattress Protector.
          </Paragraph>
          <Paragraph>
            Applicable to all accessories purchased after 1st November 2023.
          </Paragraph>
          <Paragraph>
            All returned accessories will have to be dropped off at our store
            and will have to be verified with a valid Woosa Order Number or
            invoice.
          </Paragraph>
          <Paragraph>Please return your accessories to:</Paragraph>
          <Paragraph>
            The Woosa Sleep Lab
            <br />
            1 Tampines North Dr 1,
            <br />
            #01-01, T-SPACE
            <br />
            Singapore 528499
          </Paragraph>
          <Paragraph>
            You do not need to retain the original packaging to be eligible for
            return. Please ensure that the products are in donatable condition
            to be eligible for return.
          </Paragraph>
          <Paragraph>
            <i>What is not returnable?</i>
          </Paragraph>
          <PlusIconList>
            <PlusIconListItem>
              Products must be in donatable condition to be eligible for return
              (e.g., no stains, tears or other soiling including odors).
            </PlusIconListItem>
            <PlusIconListItem>
              Products purchased from 3rd party marketplaces (Shopee/Lazada)
              will have to follow their respective platform return terms and
              conditions.
            </PlusIconListItem>
          </PlusIconList>
          <Paragraph>
            Please ensure that your purchase fulfils the return policy before
            filing for a return. We have the right to exercise our own
            discretion to reject return requests that do not adhere to our
            policy.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Return Processs',
      content: (
        <>
          <OrderedList>
            <OrderedListItem>
              Ensure you fulfil the return policy related to your product.
            </OrderedListItem>
            <OrderedListItem>
              Email us your return request to{' '}
              <a href="mailto:support@woosa.sg">support@woosa.sg</a> with your
              order number.
            </OrderedListItem>
            <OrderedListItem>
              We will reply within 3 working days to schedule a pick up (for
              items that are eligible for pick up)
            </OrderedListItem>
            <OrderedListItem>
              Upon pick up, our logistics will verify the condition of the
              product and will require a sign off by the customer. For products
              that do not fulfil the return policy upon product verification,
              our delivery team reserve the right to exercise their discretion
              and reject the return on the spot.
            </OrderedListItem>
            <OrderedListItem>
              Upon successful return of the product, we will process the refund
              within 3 working days, and it will take no more than 14 days for
              the refund to be credited back to the original mode of payment.
              However, Woosa Sleep reserves the right to change the mode of
              refund.
            </OrderedListItem>
          </OrderedList>
        </>
      ),
    },
    {
      title: 'Refund Mechanics',
      content: (
        <>
          <Paragraph>
            If you purchased a mattress using a discount code, the dollar value
            of any offer code will not be refunded or credited back if the
            mattress is returned.
          </Paragraph>
          <Paragraph>
            Gifts with Purchases (GWP) are non-refundable and chargeable at its
            original price should the original purchase be returned. (e.g If you
            purchased the Woosa Mattress with a free pillow, the original price
            of the pillow [$169] will be deducted from your total refunded
            amount).For returns of a single product only.
          </Paragraph>
          <Paragraph>
            100% refund minus any applicable fees, or minus the cost of any
            Gifts with Purchases (GWP)
          </Paragraph>
          <Paragraph>
            <b>For return of Single Product Purchased in a Bundle Discount:</b>
          </Paragraph>
          <Paragraph>
            Bundle discounts will not be extended should the customer choose to
            return only a portion of their order:
          </Paragraph>
          <Paragraph>
            <i>
              E.g. 1:
              <br />
              Queen size adjustable base bundle price: $3103.20
              <br />
              Queen Adjustable Base: $1,699
              <br />
              Queen Headboard and Side Frame: $250
              <br />
              Queen Original Mattress: $1,499
              <br />
              <br />
              Customer returns Queen Original Mattress
              <br />
              Rest of the items will be charged in full: $1,949
              <br />
              Refund Amount: $3103.20 - $1,949 = $1,154.20
            </i>
          </Paragraph>
          <Paragraph>
            <i>
              E.g 2:
              <br />
              Queen size adjustable base bundle price: $3103.20
              <br />
              Queen Adjustable Base: $1,699
              <br />
              Queen Headboard and Side Frame: $250
              <br />
              Queen Original Mattress: $1,499
              <br />
              Customer returns Queen Adjustable Base:Minus Restocking Fee: Rest
              of the items will be charged in full: $1,949Refund Amount:
              $3103.20 - $1,949 = $1,154.20
            </i>
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Cancellation Policy',
      content: (
        <>
          <Paragraph>
            You may cancel your order purchases prior to your delivery.
            <br />
            <br />
            In the event you wish to cancel your existing order and place a new
            order, kindly note that a new delivery lead time will be given at
            the time of new purchase.
            <br />
            <br />
            Subject to terms and conditions:
          </Paragraph>
          <Paragraph>
            <strong>
              <u>Cancellation policy for Mattress</u>
            </strong>
          </Paragraph>
          <Paragraph>Free cancellation prior to delivery.</Paragraph>
          <Paragraph>
            For orders that have customization requirements (ie custom sizes or
            other customization requests), cancellation is not allowed.
          </Paragraph>
          <Paragraph>
            <strong>
              <u>Cancellation policy for Bed Bases</u>
            </strong>
          </Paragraph>
          <Paragraph>
            Cancellations are free within 7 days from the order date, which is
            defined as the date where either partial or full payment has been
            made for the order.
          </Paragraph>
          <Paragraph>
            Example: If you place your order on a Saturday this week, you will
            need to cancel your order before the end of Friday next week,
            assuming there is no Public Holiday in between.
          </Paragraph>
          <Paragraph>
            A cancellation fee of $300 is chargeable for orders cancelled after
            7 days from the order date.
          </Paragraph>
          <Paragraph>
            <strong>
              <u>Cancellation policy for Accessories</u>
            </strong>
          </Paragraph>
          <Paragraph>Free cancellation prior to delivery.</Paragraph>
        </>
      ),
    },
  ];

  return (
    <Container>
      {data.map(({ title, content }, i) => (
        <Row key={i} left={<LeftTitle>{title}</LeftTitle>} right={content} />
      ))}
    </Container>
  );
};

export default FreeTrialContentSection;
