import React from 'react';
import styled from 'styled-components';

import Tag from './tag';
import { Post } from '../../types/post';
import { SiteUrls } from '../../utils/constants';
import { slugify } from '../../utils';

const Container = styled.div``;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.li`
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
`;

type Props = {
  className?: string;
  post: Post;
};

const PostTagList: React.FC<Props> = ({ post, className }) => {
  return (
    <Container className={className}>
      <List>
        {(post.categories || []).map(c => (
          <Item key={c.id}>
            <Tag
              url={`${SiteUrls.Blog}/categories/${slugify(c.name)}`}
              text={c.name}
            />
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default PostTagList;
