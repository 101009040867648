import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import {
  ENABLE_BLOG,
  ENABLE_PRODUCT_BEDSHEETS,
  PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID,
} from 'gatsby-env-variables';

import { Config } from '../../styles/enums';
import NavItem from './nav-item';
import {
  ImgEgyptianCottonBedsheetsDropdown,
  ImgPillowDropdown,
  ImgSeresSilkDuvetDropdown,
  ImgWaterproofMattressProtectorDropdown,
} from '../assets';
import { SiteUrls } from '../../utils/constants';
import MattressNavItem from './nav-mattress';
import { Dropdown } from '../../types/nav-item';

type Props = {
  isActive: boolean;
  className: string;
};

const NavStroke = styled.div`
  width: 0;
  position: absolute;
  height: 2px;
  bottom: -10px;
  background-color: var(--font-nav-color);
  opacity: 0;
  transition: left 0.3s ease, width 0.3s ease, opacity 0.3s ease;
`;

const Nav = styled.nav<Props>`
  will-change: max-height;
  position: absolute;
  top: ${Config.XS_HEADER_HEIGHT}px;
  background-color: white;
  width: 100%;
  left: 0;
  max-height: ${({ isActive }) => (isActive ? '1000px' : 0)};
  overflow-y: hidden;
  transition: max-height 0.5s ease-in-out;

  ${media.lg`
    top: inherit;
    max-height: inherit;
    background-color: transparent;
    display: flex;
    justify-content: center;
    overflow-y: visible;
  `}

  &:hover {
    ${NavStroke} {
      opacity: 1;
    }
  }
`;

const NavContainer = styled.div``;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${media.lg`
    display: flex;
  `}
`;

const Navbar: React.FC<Props> = ({ isActive, className }) => {
  const navRef = useRef<HTMLDivElement>(null);
  const accessories: { dropdowns: Dropdown[]; media: React.FC[] } = {
    dropdowns: [
      { text: 'The Woosa Pillow', to: SiteUrls.OriginalPillow },
      { text: 'The Seres Silk Duvet', to: SiteUrls.SeresSilkDuvet },
    ],
    media: [ImgPillowDropdown, ImgSeresSilkDuvetDropdown],
  };

  useEffect(() => {
    if (!navRef.current) return;
    const navList = navRef.current.querySelector('ul');
    if (!navList) return;

    const navItems = navList?.querySelectorAll('li');
    if (!navItems) return;

    const stroke = navRef.current.querySelector('.nav-stroke');
    if (!stroke) return;

    const setStroke = (item: HTMLLIElement) => {
      const { width, left } = item.getBoundingClientRect();
      stroke.setAttribute(
        'style',
        `width: ${width}px;
          left: ${left}px`
      );
    };

    navItems.forEach(item =>
      item.addEventListener('mouseover', () => setStroke(item))
    );

    setStroke(navItems[0]);
  }, []);

  const addAccessoryDropdown = (dropdown: Dropdown, media: React.FC): void => {
    accessories.dropdowns.push(dropdown);
    accessories.media.push(media);
  };

  if (PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID) {
    addAccessoryDropdown(
      {
        text: 'Waterproof Mattress Protector',
        to: SiteUrls.WaterproofMattressProtector,
      },
      ImgWaterproofMattressProtectorDropdown
    );
  }
  if (ENABLE_PRODUCT_BEDSHEETS) {
    addAccessoryDropdown(
      {
        text: 'Egyptian Cotton Bedsheets',
        to: '',
        dropdowns: [
          { text: 'All Bedsheets', to: SiteUrls.BedsheetsCategoryPage },
          { text: 'Bedding Set', to: SiteUrls.EgyptianCottonBeddingSet },
          { text: 'Fitted Sheet', to: SiteUrls.EgyptianCottonFittedSheet },
          { text: 'Duvet Cover', to: SiteUrls.EgyptianCottonDuvetCover },
          { text: 'Pillowcase Set', to: SiteUrls.EgyptianCottonPillowcaseSet },
        ],
      },
      ImgEgyptianCottonBedsheetsDropdown
    );
  }

  return (
    <Nav className={className} isActive={isActive} ref={navRef}>
      <NavContainer>
        <NavList>
          <MattressNavItem />
          <NavItem to={SiteUrls.AdjustableBase2} text="Adjustable" />
          <NavItem to={SiteUrls.StorageBase} text="Storage" />
          <NavItem
            text="Accessories"
            dropdowns={accessories.dropdowns}
            media={accessories.media}
          />
          <NavItem to={SiteUrls.About} text="About us" />
          {ENABLE_BLOG && <NavItem to={SiteUrls.Blog} text="Woosa Reads" />}
        </NavList>
        <NavStroke className="nav-stroke" />
      </NavContainer>
    </Nav>
  );
};

export default Navbar;
