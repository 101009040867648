import React, { useEffect } from 'react';
import { media } from 'styled-bootstrap-grid';
import { ENABLE_ATOME } from 'gatsby-env-variables';
import styled from 'styled-components';

import { PriceLabel, AtomeLabel } from '.';
import { renderStatus } from '../../utils/index';
import { rem, flatButtonStyle } from '../../styles/utils';
import { Product } from '../../types/product';
import useAddToCart from '../../hooks/use-add-to-cart';

const Price = styled(PriceLabel)`
  display: block;
  margin-top: 20px;
  font-size: ${rem(16)};
`;

const AddToCartBtn = styled.button`
  ${flatButtonStyle()}
  padding: 10px;
  margin: 20px 0;
  ${media.lg`
    width: 50%;
  `}
`;

const Atome = styled(AtomeLabel)`
  font-size: ${rem(11)};
  margin-top: -8px;
  .img-atome {
    width: 65px;
  }
`;

const Quantity = styled.p<{ isOutOfStock: boolean }>`
  color: ${({ isOutOfStock }) => (isOutOfStock ? 'red' : 'var(--leather)')};
  font-size: ${rem(11)};
  margin-top: 8px;
  font-family: var(--font-body);
`;

type Props = {
  selectedVariantType: string;
  product: Product;
};

const PriceCartStatus: React.FC<Props> = ({ selectedVariantType, product }) => {
  const variant = product.variants.find(v => v.type === selectedVariantType);

  const {
    isLoading,
    handleProductVariantDropdownChange,
    handleAddToCart,
  } = useAddToCart({
    id: product.id,
    collection: product.collection,
    name: product.name,
    media: product.media,
    variants: product.variants,
    rating: product.rating,
    assets: [],
  });

  useEffect(() => {
    handleProductVariantDropdownChange(variant);
  }, [variant]);

  if (!variant) return null;

  return (
    <div>
      <Price price={variant.prices[0]} />

      <AddToCartBtn
        disabled={variant.status === 'Out of Stock' || isLoading}
        onClick={handleAddToCart}
      >
        Add to Cart
      </AddToCartBtn>

      {ENABLE_ATOME && (
        <Atome imgClassName="img-atome" amount={variant.prices[0].amount} />
      )}

      <Quantity isOutOfStock={false}>
        {renderStatus(variant.status, variant.statusDate)}
      </Quantity>
    </div>
  );
};

export default PriceCartStatus;
