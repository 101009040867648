import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { HeroIntro } from '../common';
import Video from '../../static/videos/terra-mattress-intro.mp4';

const Intro = styled(HeroIntro)`
  .hero-intro {
    height: 200px;

    ${media.md`
      height: 300px;
    `}

    ${media.lg`
      height: 450px;
    `}

    ${media.xl`
      height: 700px;
    `}
  }

  .hero-intro-content {
    padding: 30px 0;

    ${media.md`
      padding: 50px 0;
    `}

    ${media.lg`
      padding: 100px 0;
    `}
  }
`;

const TerraMattressIntroSection: React.FC = () => {
  return (
    <Intro
      version="v2"
      backgroundVideoSrc={Video}
      eyebrow="Design Process"
      headline="Creating Ergonomic Support"
      body={
        <>
          <p>
            Developed in line with recommendations from professionals in the
            physiotherapy and chiropractic industry, The Terra Mattress is a
            firm sleeping surface that optimises proper spinal alignment and
            sleeping posture, while still delivering great comfort.
          </p>
          <p>
            We wanted to cater to users who are used to sleeping on ultra-firm
            spring mattresses, or are transiting to a full foam mattress for the
            first time. Leveraging on our high competencies in Latex production,
            we combined two uniquely formulated densities of 100% Natural Latex
            to combine perfectly with our Memory Foam. The result is an
            incredibly firm yet responsive sleeping surface that doesn&apos;t
            feel like you&apos;re sleeping on rocks.
          </p>
        </>
      }
    />
  );
};

export default TerraMattressIntroSection;
