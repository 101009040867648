import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const LogoList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

const LogoListItem = styled.li<{ borderRadius?: string }>`
  display: flex;
  flex: 0;
  align-items: center;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }

  ${media.lg`
    margin-bottom: 0;
  `}

  .gatsby-image-wrapper {
    width: 50px;
    border-radius: ${({ borderRadius }) => borderRadius || '10px'};
  }
`;

export type FooterLogoItem = {
  image: React.ReactNode;
  url?: string;
  borderRadius?: string;
};

type Props = {
  className?: string;
  logos: FooterLogoItem[];
};

const FooterLogoList: React.FC<Props> = ({ className, logos }) => {
  return (
    <LogoList className={className}>
      {logos.map(({ image, url, borderRadius }, i) => (
        <LogoListItem key={i} borderRadius={borderRadius}>
          {url && (
            <a href={url} target="_blank" rel="noreferrer">
              {image}
            </a>
          )}
          {!url && image}
        </LogoListItem>
      ))}
    </LogoList>
  );
};

export default FooterLogoList;
