import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { ProductAccordionSection, IconExpander, Video, Link } from '../common';
import {
  Paragraph,
  UnorderedList,
  ListItem,
  Subtitle,
} from '../common/product-accordion-section';
import {
  ImgSeresSilkDuvetMaterial1,
  ImgSeresSilkDuvetMaterial2,
} from '../assets';
import DesignProcessVideo from '../../static/videos/seres-silk-duvet-design-process.mp4';
import { Accordion } from '../../types/component';
import { SiteUrls } from '../../utils/constants';

const CareForWoosa = styled.div`
  margin-top: 40px;
`;

const data: Accordion[] = [
  {
    title: 'Design Process',
    body: (
      <Row>
        <Col xs={12} md={6} mdOrder="last">
          <Video src={DesignProcessVideo} />
        </Col>
        <Col xs={12} md={6}>
          <Paragraph>
            We wanted to create the perfect duvet that would be suitable for use
            in Singapore&apos;s hot and humid weather. Breathability, weight,
            comfort and durability were our top priorities. Our search led us to
            one of the oldest and most established silk makers in Suzhou, China,
            where the ancient art of silk production dates back to 5,000 years.
          </Paragraph>
          <Paragraph>
            The Seres Silk Duvet is filled with the highest grade of silk, 6A,
            which is tested for its quality and colour. Each of our ultra-long
            6A silk fibres can unravel to 1.5km in length without breaking.
            These fibres are batted together to form a seamless duvet filling
            that offers an extremely soft, flowy and cosy feel.
          </Paragraph>
          <Paragraph>
            We do not use a silk blend. Our 100% pure Mulberry silk is smoother,
            stronger and friction-free, which is great for sensitive skin. You
            can be assured that your duvet will not bunch up over time, and will
            last up to 15 years with proper care.
          </Paragraph>
        </Col>
      </Row>
    ),
  },
  {
    title: 'Material & Care',
    body: (
      <>
        <Row>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgSeresSilkDuvetMaterial1 />}
              title="600 TC Cotton"
              content={
                <Paragraph>
                  The shell of The Seres Silk Duvet is made with 100% cotton.
                  This luxury thread count paired with a sateen weave is super
                  soft and silky to the touch.
                </Paragraph>
              }
            />
          </Col>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgSeresSilkDuvetMaterial2 />}
              title="Mulberry Silk, 6A Grade"
              content={
                <Paragraph>
                  The Seres Silk Duvet is stuffed with the purest, whitest, most
                  refined silk, which comes from the cocoons of{' '}
                  <i>Bombyx mori</i>
                  silkworms that are fed only Mulberry leaves.
                </Paragraph>
              }
            />
          </Col>
        </Row>
        <CareForWoosa>
          <Paragraph fontWeight="bold">FILL WEIGHT:</Paragraph>
          <Paragraph>
            The Seres Silk Duvet has a fill weight that is carefully calibrated
            so that it is heavy enough for a snug feel, while still remaining
            airy enough for tropical weather.
          </Paragraph>
          <Paragraph>
            Single - 1000g
            <br />
            Super Single - 1143g
            <br />
            Queen - 1500g
            <br />
            King - 1714g
          </Paragraph>
          <Paragraph fontWeight="bold">HOW TO CARE FOR YOUR WOOSA:</Paragraph>
          <Paragraph>
            Clean The Seres Silk Duvet by sun curing it: place it under the
            direct rays of the sun.
          </Paragraph>
          <UnorderedList>
            <ListItem>Spot cleaning recommended</ListItem>
            <ListItem>Avoid washing</ListItem>
            <ListItem>Avoid dry cleaning</ListItem>
          </UnorderedList>
        </CareForWoosa>
      </>
    ),
  },
  {
    title: 'Shipping, Return, Warranty & Cancellation',
    body: (
      <>
        <Subtitle>Shipping</Subtitle>
        <Paragraph>
          All products are shipped free to your doorstep and inclusive of
          installation.
        </Paragraph>
        <Subtitle>Return</Subtitle>
        <Paragraph>
          All our accessories are applicable for free returns within the first
          30 days of receiving your item. Our accessories include The Woosa
          Pillow, The Seres Silk Duvet, The Mattress Protector. Read more{' '}
          <Link to={SiteUrls.FreeTrial}>here</Link>.
        </Paragraph>
        <Subtitle>Warranty</Subtitle>
        <Paragraph>
          The Seres Silk Duvet comes with a 3 year warranty. Read more{' '}
          <a
            href={SiteUrls.SeresSilkDuvetWarranty}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Paragraph>
        <Subtitle>Cancellation</Subtitle>
        <Paragraph>
          Free cancellation prior to delivery. Read more{' '}
          <Link to={SiteUrls.FreeTrial}>here</Link>{' '}
        </Paragraph>
      </>
    ),
  },
  {
    title: 'FAQ',
    body: (
      <>
        <Subtitle>What are the dimensions of The Seres Silk Duvet?</Subtitle>
        <Paragraph>
          Single: 140cm x 210cm
          <br />
          Super Single: 160cm x 210cm
          <br />
          Queen: 210cm x 210cm
          <br />
          King: 240cm x 210cm
        </Paragraph>
        <Subtitle>Do I have to buy a cover for The Seres Silk Duvet?</Subtitle>
        <Paragraph>
          It is recommended to use the duvet with a cover. The Woosa Seres Silk
          Duvet is designed to fit any standard Asian-sized duvet cover of the
          same dimensions listed above.
        </Paragraph>
        <Subtitle>Can I wash the duvet?</Subtitle>
        <Paragraph>
          It is not recommended to wash The Seres Silk Duvet as silk naturally
          repels dust, dirt, dust mites, moths, mildew and odours. Only sun cure
          it by placing it under the direct rays of the sun.
          <br />
          <br />
          If you have any stains, spot clean and place under the sun to dry.
          <br />
          <br />
          If you absolutely must wash the duvet, hand wash only with cool water
          and gentle soap, and hang flat to dry. Washing the duvet regularly
          causes the silk fibres to disintegrate quicker, affecting the intended
          properties of the filling and its durability.
        </Paragraph>
        <Subtitle>Do you use 100% silk?</Subtitle>
        <Paragraph>
          Our duvet is made from 100% pure long strand Mulberry silk - no
          synthetic silk is used in the filling.
        </Paragraph>
        <Subtitle>Is the duvet hot?</Subtitle>
        <Paragraph>
          The Seres Silk duvet is designed to be versatile and suitable for all
          seasons. Because of our natural silk filling, the duvet keeps you cool
          in the heat, and warm on chilly nights.
        </Paragraph>
        <Subtitle>Where is The Seres Silk Duvet made?</Subtitle>
        <Paragraph>
          Our duvet is sourced and made responsibly in Suzhou, China - the Silk
          capital of the world.
        </Paragraph>
        <a href={SiteUrls.Faq} target="_blank" rel="noreferrer">
          Read All FAQs
        </a>
      </>
    ),
  },
];

const SeresSilkDuvetAccordionSection: React.FC = () => {
  return <ProductAccordionSection data={data} />;
};

export default SeresSilkDuvetAccordionSection;
