import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { rem, flatButtonStyle } from '../../styles/utils';

export const Title = styled.strong`
  font-weight: bold;
  color: var(--font-primary-color);
  font-size: ${rem(12)};
  margin-bottom: 20px;
  display: block;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

export const ButtonLink = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  text-decoration: underline;
`;

export const Error = styled.span`
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: red;
  font-size: 80%;
`;

export const Message = styled.span`
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: green;
  font-size: 80%;
`;

export const Eyebrow = styled.span`
  color: var(--font-primary-color);
  font-size: ${rem(12)};
  display: block;
  margin-bottom: 5px;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

export const Button = styled.button`
  ${flatButtonStyle}
`;
