import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { ENABLE_ATOME } from 'gatsby-env-variables';

import { useCartState, useCartDispatch } from '../../hooks/cart-context';
import { rem } from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';
import {
  Overlay,
  Cart,
  CTA,
  PriceLabel,
  PromoInput,
  PromoBundleModal,
  CloseButton,
  Spinner,
  AtomeLabel,
} from '../common';

type DrawerProps = {
  isActive: boolean;
};

const Drawer = styled.section<DrawerProps>`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: var(--desert-storm);
  transition: transform 0.5s ease-out;
  transform: ${({ isActive }) =>
    isActive ? 'translateZ(0)' : 'translate3d(100%, 0, 0);'};

  ${media.md`
    max-width: 512px;
  `}
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid var(--border-color);
  padding: 10px 15px;
  height: 70px;
`;

const Title = styled.h3`
  font-family: var(--font-body);
  font-size: ${rem(16)};
  color: var(--font-nav-color);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: ${rem(1)};
`;

const Footer = styled.div<{ atomeEnabled: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 8%) 0px -0.125rem 0.25rem;
  height: ${({ atomeEnabled }) => (atomeEnabled ? 150 : 130)}px;
`;

const CheckoutButton = styled(CTA)`
  text-align: center;
  width: 100%;
`;

const GrandTotal = styled.strong`
  display: block;
  font-family: var(--font-body);
  font-weight: normal;
  color: #000;
  font-size: ${rem(16)};
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  ${media.xl`
    font-size: ${rem(22)};
  `}
`;

const Price = styled(PriceLabel)`
  color: #000;
`;

const CartContainer = styled.div<{ atomeEnabled: boolean }>`
  max-height: calc(
    100vh - ${({ atomeEnabled }) => (atomeEnabled ? 150 : 130)}px - 70px
  );
  overflow-y: scroll;
  padding-bottom: 30px;
`;

const Atome = styled(AtomeLabel)`
  margin-top: -25px;
  margin-bottom: 10px;
  text-align: right;
`;

type Props = {
  className?: string;
};

const CartDrawer: React.FC<Props> = ({ className }) => {
  const {
    products,
    show,
    amounts,
    discount,
    shippingFee,
    promos,
    referralCode,
    isLoading,
  } = useCartState();
  const dispatch = useCartDispatch();

  return (
    <>
      <Drawer className={className} isActive={show}>
        <Header>
          <Title>Your Cart</Title>
          <CloseButton onClick={() => dispatch({ type: 'TOGGLE_DRAWER' })} />
        </Header>
        {products.length > 0 && (
          <CartContainer atomeEnabled={ENABLE_ATOME}>
            <Cart
              amounts={amounts}
              products={products}
              discount={discount}
              shippingFee={shippingFee}
              promos={promos}
              referralCode={referralCode}
            />
            <PromoInput />
            <Footer atomeEnabled={ENABLE_ATOME}>
              <GrandTotal>
                <span>Your Total</span>
                <Price
                  price={{
                    amount: amounts.totalAmount,
                    currency: products[0].currency,
                  }}
                ></Price>
              </GrandTotal>
              {ENABLE_ATOME && <Atome amount={amounts.totalAmount} />}
              <CheckoutButton to={SiteUrls.Checkout} type="flat">
                Proceed to checkout
              </CheckoutButton>
            </Footer>
          </CartContainer>
        )}
        <Spinner isLoading={isLoading} />
      </Drawer>
      <Overlay className="cart-drawer-overlay" isActive={show} />
      <PromoBundleModal className="promo-bundle-modal" />
    </>
  );
};

export default CartDrawer;
