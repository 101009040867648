import React, { useState } from 'react';
import styled from 'styled-components';

import ProductSizeTile from './product-size-tile';
import ProductSizeModal from './product-size-modal';
import { rem } from '../../../styles/utils';
import { ProductItem } from './types';

const Item = styled.div`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height; 1.16; 
  display: flex;
  flex-direction: column;

  span {
    margin: 5px 0;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 20px 0;
  padding: 0;
  display: flex;

  ${Item} {
    margin-right: 10px;
  }
`;

type Props = {
  className?: string;
  items: ProductItem[];
};

const ProductSizeTileList: React.FC<Props> = ({ className, items }) => {
  const [openModal, setModalModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ProductItem | undefined>();

  const handleClickTile = (item: ProductItem) => {
    setModalModal(true);
    setSelectedItem(item);
  };

  return (
    <>
      <List className={className}>
        {items.map(item => (
          <ProductSizeTile
            key={item.name}
            {...item}
            onClick={() => handleClickTile(item)}
          />
        ))}
      </List>
      <ProductSizeModal
        isActive={openModal}
        onClickClose={() => setModalModal(false)}
        item={selectedItem}
      />
    </>
  );
};

export default ProductSizeTileList;
