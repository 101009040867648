export enum SiteUrls {
  ComparisonMattress = '/mattresses',
  OriginalMattress = '/mattresses/original',
  OriginalMattressWarranty = '/warranty/the-woosa-mattress',
  OriginalMattressReview = '/reviews/the-original-mattress',
  MysaMattress = '/mattresses/mysa',
  MysaMattressWarranty = '/warranty/the-woosa-mattress',
  MysaMattressReview = '/reviews/the-mysa-mattress',
  OriginalPillow = '/original-woosa-pillow',
  OriginalPillowWarranty = '/warranty/the-woosa-pillow',
  OriginalPillowReview = '/reviews/the-woosa-pillow',
  BedFrame = '/adjustable',
  BedFrameWarranty = '/warranty/adjustable',
  BedFrameReview = '/reviews/adjustable',
  Checkout = '/checkout',
  FreeTrial = '/sleep-trial',
  Faq = '/faq',
  About = '/about',
  Home = '/',
  Blog = '/blog',
  Post = '/post',
  SeresSilkDuvet = '/accessories/seres-silk-duvet',
  SeresSilkDuvetWarranty = '/warranty/seres-silk-duvet',
  SeresSilkDuvetReview = '/reviews/seres-silk-duvet',
  WaterproofMattressProtector = '/accessories/waterproof-mattress-protector',
  WaterproofMattressProtectorWarranty = '/warranty/waterproof-mattress-protector',
  OrderSummary = '/summary',
  AccountDashboard = '/account/dashboard',
  AccountSettings = '/account/profile/settings',
  AccountGeneral = '/account/profile/general',
  AccountLogout = '/account/logout',
  AccountLogin = '/account/login',
  AccountRegister = '/account/register',
  AccountForgetPassword = '/account/forget-password',
  TerraMattress = '/mattresses/terra',
  TerraMattressWarranty = '/warranty/the-woosa-mattress',
  TerraMattressReview = '/reviews/the-terra-mattress',
  Appointment = '/#appointment',
  AdjustableBase2 = '/adjustable',
  AdjustableBase2Review = '/reviews/adjustable',
  StorageBase = '/storage/the-woosa-storage-base',
  StorageBaseReview = '/reviews/the-woosa-storage-base',
  StorageBaseWarranty = '/warranty/storage-base',
  EgyptianCottonBeddingSet = '/accessories/bedsheets/egyptian-cotton-bedding-set',
  EgyptianCottonBeddingSetReview = '/reviews/egyptian-cotton-bedding-set',
  EgyptianCottonFittedSheet = '/accessories/bedsheets/egyptian-cotton-fitted-sheet',
  EgyptianCottonFittedSheetReview = '/reviews/egyptian-cotton-fitted-sheet',
  EgyptianCottonDuvetCover = '/accessories/bedsheets/egyptian-cotton-duvet-cover',
  EgyptianCottonDuvetCoverReview = '/reviews/egyptian-cotton-duvet-cover',
  EgyptianCottonPillowcaseSet = '/accessories/bedsheets/egyptian-cotton-pillowcase-set',
  EgyptianCottonPillowcaseSetReview = '/reviews/egyptian-cotton-pillowcase-set',
  BedsheetsCategoryPage = '/bedsheets',
  Shipping = '/shipping',
}

export enum FacebookPixelEvent {
  AddToCart = 'AddToCart',
  InitiateCheckout = 'InitiateCheckout',
  ViewContent = 'ViewContent',
  Lead = 'Lead',
  Purchase = 'Purchase',
}

export enum GTagType {
  Event = 'event',
}

export enum GTagEvent {
  AddToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  BeginCheckout = 'begin_checkout',
  Purchase = 'purchase',
}

export enum TikTokEvent {
  ViewContent = 'ViewContent',
  Search = 'Search',
  AddToCart = 'AddToCart',
  ClickButton = 'ClickButton',
  AddPaymentInfo = 'AddPaymentInfo',
  InitiateCheckout = 'InitiateCheckout',
  CompletePayment = 'CompletePayment',
}

export enum ContactLinks {
  Email = 'mailto:support@woosa.sg',
  Phone = 'tel:+6598485822',
  Location = 'https://g.page/Woosa-Sleep?share',
  TempLocation = 'https://goo.gl/maps/4RSDkPdViDUbiQgm9',
  Whatsapp = 'https://wa.me/6598485822',
  Facebook = 'http://fb.com/woosasg',
  Instagram = 'https://www.instagram.com/woosasg',
  Google = 'https://g.page/r/CXsLMur4Dfl6EAg/review',
}

export enum CountryCode {
  UNDEFINED = '',
  SG = 'sg',
}

export const GlobalSiteUrls = [
  // SiteUrls.AccountDashboard,
  // SiteUrls.AccountSettings,
  // SiteUrls.AccountGeneral,
  // SiteUrls.AccountLogout,
  // SiteUrls.AccountLogin,
  // SiteUrls.AccountRegister,
  // SiteUrls.AccountForgetPassword,
  // SiteUrls.OrderSummary,
  // SiteUrls.Checkout,
];

export enum CommonVariants {
  SINGLE = 'single',
  SUPER_SINGLE = 'super single',
  QUEEN = 'queen',
  KING = 'king',
  SPLIT_KING = 'split king',
}

export enum CommonVariantTypes {
  DESIGN = 'design',
  MATERIAL = 'material',
  COLOUR = 'colour',
}

export enum HardCodeValues {
  CHARGE_PER_STAIRS = '$40',
}
