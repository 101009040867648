import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';
import _ from 'lodash';

import { Post } from '../../types/post';
import { Container, PostSuggestCard, Link } from '../common';
import { rem } from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';
import { slugify } from '../../utils';

const Section = styled.section`
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: 1.33;
  background-color: var(--mystic);
  padding: 40px 0;

  ${media.lg`
    font-size: ${rem(18)};
    padding: 80px 0; 
  `}
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 100%;
`;

const Eyebrow = styled.strong`
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--cornflower-blue);
`;

const EyebrowLink = styled(Link)`
  margin-left: auto;
  text-decoration: none;
  color: var(--bali-hai);

  &:hover {
    text-decoration: underline;
  }
`;

const SuggestCard = styled(PostSuggestCard)`
  margin-bottom: 40px;

  ${media.lg`
    margin-bottom: 0;
  `}
`;

type Props = {
  post: Post;
};

const splitByCategories = (post: Post, categoryNames: string[]): boolean => {
  const postCategoryNames = (post.categories || []).map(c => c.name);
  return categoryNames.some(n => postCategoryNames.includes(n));
};

const BlogPostSuggestSection: React.FC<Props> = ({ post }) => {
  const {
    allPosts: { edges },
  } = useStaticQuery(graphql`
    query {
      allPosts(
        filter: { status: { eq: "published" } }
        sort: { fields: publishedOn, order: DESC }
      ) {
        edges {
          node {
            id
            author {
              name
            }
            content
            slug
            status
            thumbnail
            title
            categories {
              name
              id
            }
            isFeatured
            publishedOn
          }
        }
      }
    }
  `);

  const posts = edges.map(({ node }: { node: Post }) => node) as Post[];
  const categories = post.categories || [];
  const categoryNames = categories.map(c => c.name);
  const filteredPosts = posts.filter(p => p.id !== post.id);
  const [suggestedPosts, nonSuggestedPosts] = _.partition(filteredPosts, p =>
    splitByCategories(p, categoryNames)
  );

  while (suggestedPosts.length < 2 && nonSuggestedPosts.length > 0) {
    suggestedPosts.push(nonSuggestedPosts[0]);
    nonSuggestedPosts.shift();
  }

  if (suggestedPosts.length === 0) return null;

  return (
    <Section>
      <Container>
        <Header>
          <Eyebrow>More Reads</Eyebrow>
          <EyebrowLink
            to={`${SiteUrls.Blog}/categories/${categories
              .map(c => slugify(c.name))
              .join('|')}`}
          >
            Read more articles &gt;
          </EyebrowLink>
        </Header>
        <Row>
          {suggestedPosts.map(p => (
            <Col key={p.id} md={6}>
              <SuggestCard post={p} />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default BlogPostSuggestSection;
