import React, { createContext, useContext, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { useCustomReducer } from './use-custom-reducer';

type Action = {
  type:
    | 'INIT'
    | 'SIGN_IN'
    | 'SIGN_UP'
    | 'SIGN_OUT'
    | 'GUEST_SIGN_IN'
    | 'LINK_ACCOUNT_WITH_PASSWORD';
  accessToken?: string;
  email?: string;
  uid?: string;
  displayPic?: string;
  firstName?: string;
  lastName?: string;
  pendingCred?: firebase.auth.AuthCredential;
  populateGuestFormEmail?: boolean;
};
export type Dispatch = (action: Action) => void;
type State = {
  email: string;
  accessToken: string;
  uid: string;
  displayPic?: string;
  pendingCred?: firebase.auth.AuthCredential;
  firstName?: string;
  lastName?: string;
  populateGuestFormEmail?: boolean;
};
type AuthProviderProps = { children: React.ReactNode };

enum AuthEnum {
  LOCAL_STORAGE_KEY = 'sg.woosa.auth',
}
const AuthStateContext = createContext<State | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
  email: '',
  accessToken: '',
  uid: '',
  displayPic: '',
  firstName: '',
  lastName: '',
};

const saveToLocalStorage = (state: State): void => {
  localStorage.setItem(AuthEnum.LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const getFromLocalStorage = (): State | null => {
  const data = localStorage.getItem(AuthEnum.LOCAL_STORAGE_KEY);
  if (!data) return null;
  return JSON.parse(data);
};

const authReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'GUEST_SIGN_IN':
      return {
        ...state,
        email: action.email || '',
        populateGuestFormEmail: action.populateGuestFormEmail,
      };
    case 'SIGN_OUT': {
      firebase
        .auth()
        .signOut()
        .then()
        .catch(error => console.error(error));
      localStorage.removeItem(AuthEnum.LOCAL_STORAGE_KEY);
      return {
        ...state,
        accessToken: '',
        email: '',
        uid: '',
        displayPic: '',
        firstName: '',
        lastName: '',
      };
    }
    default: {
      let authState = state;

      const {
        accessToken,
        email,
        uid,
        displayPic,
        firstName,
        lastName,
      } = action;
      if (accessToken && email && uid) {
        authState = {
          ...state,
          accessToken,
          email,
          uid,
          displayPic,
          firstName,
          lastName,
        };
      }

      if (action.type !== 'INIT') {
        saveToLocalStorage(authState);
      }

      return authState;
    }
  }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useCustomReducer(
    'AuthContext',
    authReducer,
    initialState
  );

  useEffect(() => {
    const data = getFromLocalStorage();
    if (!data) return;
    dispatch({ ...data, type: 'INIT' });
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = (): State | never => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
};

export const useAuthDispatch = (): Dispatch | never => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};
