import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { BgBedsheetInfo, ImgCheckIcon } from '../assets';
import { rem, lh } from '../../styles/utils';

const InfoContainer = styled.div`
  position: relative;
  height: calc(100% - 30px);
  background-color: #d6d6d9;
  margin-bottom: 30px;
`;

const BgImg = styled(BgBedsheetInfo)`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  width: 100%;
  padding: 80px 0;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  color: white;
  font-weight: normal;
  font-size: ${rem(38)};
  line-height: ${lh(46, 38)};
  width: 100%;
  text-align: center;
  margin: 0;
`;

const Description = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 50px 35px;

  ${media.lg`
  position: absolute;
  `}
`;

const DescriptionListItem = styled.div`
  display: flex;
  font-family: var(--font-body);
  color: white;
  font-size: ${rem(16)};
  line-height: ${lh(20, 16)};
  font-weight: 400;
  margin-bottom: 15px;
`;

const DescriptionIcon = styled(ImgCheckIcon)`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`;

const DescriptionParagraph = styled.div`
  width: 90%;
`;

const data = [
  'Made from 100% Pure Egyptian Giza Cotton',
  '600 TC with sateen weave for a silky finish',
  'Gets softer with every wash',
  'Extra durable and lasts up to 10-15 years',
  'Designed in Singapore, Made in Portugal',
  'OEKO-TEX Certified & Accredited by the Cotton Egyptian Association',
];

const BedsheetCategoryInfo: React.FC = () => {
  return (
    <InfoContainer>
      <BgImg>
        <Title>
          <Headline>
            100% Egyptian
            <br />
            Cotton Bedding
          </Headline>
        </Title>
        <Description>
          {data.map((d, idx) => (
            <DescriptionListItem key={idx}>
              <DescriptionIcon />
              <DescriptionParagraph>{d}</DescriptionParagraph>
            </DescriptionListItem>
          ))}
        </Description>
      </BgImg>
    </InfoContainer>
  );
};

export default BedsheetCategoryInfo;
