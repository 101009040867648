import React from 'react';
import styled from 'styled-components';

import { SimpleSplitSection } from '../common';
import {
  Wrapper,
  LeftTitle,
  fontStyle,
  PlusIconList,
  PlusIconListItem,
} from '../common/simple-split-section';

const Container = styled(Wrapper)``;

const Row = styled(SimpleSplitSection)`
  margin-top: 40px;
`;

const Paragraph = styled.p`
  ${fontStyle}
  margin-top: 0;
`;

const PillowWarrantyContentSection: React.FC = () => {
  const data = [
    {
      title: 'Warranty Description',
      content: (
        <Paragraph>
          This warranty is valid only for the original purchaser from the
          original purchase date of the original pillow purchased from Woosa and
          is non-transferable. If the original purchaser sells or otherwise
          relinquishes ownership of the pillow to another, the subsequent owner
          accepts the product “as is” and “with all faults.” Original proof of
          purchase (including date) will be required to make a warranty claim.
          Please retain a copy of your invoice as proof of purchase.
        </Paragraph>
      ),
    },
    {
      title: 'What does the Limited Warranty Cover?',
      content: (
        <Paragraph>
          Any deformity or physical flaw of the inner foam material (cracks or
          splits).
          <br />
          Any manufacturing defect in the pillow cover (torn, unraveled stiches)
          and zipper assembly.
        </Paragraph>
      ),
    },
    {
      title: 'What does this Limited Warranty NOT Cover?',
      content: (
        <PlusIconList>
          <PlusIconListItem>
            Normal wear and tear and normal increase in softness of the Woosa
            Cloud Foam.
          </PlusIconListItem>
          <PlusIconListItem>Comfort preference.</PlusIconListItem>
          <PlusIconListItem>
            Damage caused by physical abuse or aggressive handling including but
            not limited to, burns, cuts, tears, liquid damage, or stains on the
            foam material or the inner cover.
          </PlusIconListItem>
          <PlusIconListItem>
            Damage caused by washing of the Pillow.
          </PlusIconListItem>
          <PlusIconListItem>
            Damage associated with commercial usage or use beyond its intended
            household purpose.
          </PlusIconListItem>
          <PlusIconListItem>
            Product sold by resellers who are not authorized retailers. Product
            sold “as-is”, “preconditioned”, “reconditioned”, “used”, “comfort
            return”, “returned”, “previously owned”, or any other similar
            wording indicating that the product is not “new” or of “first
            quality”, or has previously been purchased or used by another
            consumer.
          </PlusIconListItem>
        </PlusIconList>
      ),
    },
  ];

  return (
    <Container>
      {data.map(({ title, content }, i) => (
        <Row key={i} left={<LeftTitle>{title}</LeftTitle>} right={content} />
      ))}
    </Container>
  );
};

export default PillowWarrantyContentSection;
