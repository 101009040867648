import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const StyledCarousel = styled(ResponsiveCarousel)`
  .carousel {
    padding-bottom: 2.5rem;

    ${media.md`
      padding-bottom: 3rem;
    `}

    .control-arrow {
      display: none;
    }

    .control-dots {
      .dot {
        opacity: 1;
        box-shadow: none;
        border: 1px solid var(--siam);
        margin: 0 4px;

        &:hover {
          opacity: 0.3;
        }

        &:focus {
          opacity: 1;
        }

        &.selected {
          background: var(--siam);
        }

        ${media.md`
          margin: 0 8px;
        `}
      }
    }

    .slide {
      background: transparent;

      iframe {
        margin: 0;
        width: 100%;
      }
    }

    .thumbs {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;

      .thumb {
        border: 1px solid transparent;

        &:hover,
        &.selected {
          border-color: var(--siam);
        }
      }
    }
  }
`;

type Props = {
  children:
    | React.ReactChild[]
    | React.ReactChild
    | React.ReactNode
    | React.ReactNodeArray;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any;
  className?: string;
};

const Carousel: React.FC<Props> = ({ children, className, props }) => {
  return (
    <StyledCarousel className={className} {...props}>
      {children}
    </StyledCarousel>
  );
};

export default Carousel;
