import React, { useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import {
  Logo,
  CartButton,
  Container as RawContainer,
  Spinner,
  Cart,
} from '../common';
import {
  WizardMenu,
  WizardContent,
  AuthForm,
  AddressForm,
  DeliveryForm,
  PaymentForm,
} from '../checkout';
import { Config, Breakpoints } from '../../styles/enums';
import { rem, mediaQueryUpto } from '../../styles/utils';
import {
  useCheckoutDispatch,
  useCheckoutState,
} from '../../hooks/checkout-context';
import { useCartState } from '../../hooks/cart-context';
import { fbq, gtag, toGtagCart, toTtqCart, ttq } from '../../utils/analytics';
import {
  FacebookPixelEvent,
  GTagEvent,
  GTagType,
  TikTokEvent,
} from '../../utils/constants';

const Section = styled.section`
  width: 100%;
  position: relative;
  margin-top: -${Config.XS_HEADER_HEIGHT}px;

  ${media.lg`
    margin-top: 0;
  `}
`;

const Container = styled(RawContainer)`
  ${mediaQueryUpto(Breakpoints.MD, 'padding-left: 15px; padding-right: 15px;')}

  ${media.lg`
    display: flex;
    min-height: 80vh;
    align-items: flex-start;
  `}
`;

const WizardContainer = styled.div`
  ${media.lg`
    width: 60%;
    padding-right: 40px
  `}

  ${media.xl`
    width: 50%;
  `}
`;

const CartContainer = styled.div`
  display: none;

  ${media.lg`
    display: block;
    background-color: var(--wild-sand);
    min-height: 700px;
    padding: 60px 0;
    width: 40%;
    position: absolute;
    right: 0;
  `}
`;

const CartHeadline = styled.h2`
  font-family: var(--font-body);
  font-size: ${rem(16)};
  color: var(--font-nav-color);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: ${rem(1)};
  padding: 0 20px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;

  ${media.lg`
    display: flex;
    padding-top: 40px;
    align-items: center;
    margin-bottom: 60px;
  `}
`;

const Menu = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  height: ${Config.XS_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.lg`
    height: auto;
    min-height: ${Config.XS_HEADER_HEIGHT}px;
    width: auto;
    margin-right: 30px;
  `}
`;

const Body = styled.div``;

const CartBtn = styled(CartButton)`
  ${media.lg`
    display: none;
  `}
`;

const CheckoutSection: React.FC = () => {
  const dispatch = useCheckoutDispatch();
  const { isLoading } = useCheckoutState();
  const cartState = useCartState();
  const {
    products,
    amounts,
    shippingFee,
    discount,
    promos,
    referralCode,
    tax,
  } = cartState;

  useEffect(() => {
    fbq(FacebookPixelEvent.InitiateCheckout);
    gtag(GTagType.Event, GTagEvent.BeginCheckout, toGtagCart(cartState));
    ttq(TikTokEvent.InitiateCheckout, toTtqCart(cartState));
    dispatch({
      type: 'INIT',
      steps: [
        {
          label: 'Email',
          disabled: false,
          content: <AuthForm index={0} />,
        },
        {
          label: 'Shipping',
          disabled: true,
          content: <AddressForm index={1} />,
        },
        {
          label: 'Delivery',
          disabled: true,
          content: <DeliveryForm index={2} />,
        },
        {
          label: 'Billing',
          disabled: true,
          content: <PaymentForm index={3} />,
        },
      ],
    });
  }, []);

  return (
    <Section>
      <Container>
        <WizardContainer>
          <Header>
            <Menu>
              <Logo />
              <CartBtn />
            </Menu>
            <WizardMenu />
          </Header>
          <Body>
            <WizardContent />
          </Body>
        </WizardContainer>
        <CartContainer>
          <CartHeadline>Your Cart</CartHeadline>
          {products.length > 0 && (
            <Cart
              products={products}
              amounts={amounts}
              shippingFee={shippingFee}
              discount={discount}
              promos={promos}
              referralCode={referralCode}
              tax={tax}
            />
          )}
        </CartContainer>
      </Container>
      <Spinner isLoading={isLoading} />
    </Section>
  );
};

export default CheckoutSection;
