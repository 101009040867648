import React from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import {
  PRODUCT_STORAGE_BASE_ID,
  PRODUCT_ORIGINAL_MATTRESS_ID,
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_MYSA_MATTRESS_ID,
  PRODUCT_PILLOW_ID,
} from 'gatsby-env-variables';

import { ProductSection } from '../common';
import StorageBase1 from '../../static/videos/storage-base-2.mp4';
import StorageBase2 from '../../assets/storage-base-4.png';
import StorageBase2Webp from '../../assets/storage-base-4.webp';
import StorageBase3 from '../../assets/storage-base-5.png';
import StorageBase3Webp from '../../assets/storage-base-5.webp';
import StorageBase4 from '../../assets/storage-base-6.png';
import StorageBase4Webp from '../../assets/storage-base-6.webp';
import StorageBase5 from '../../assets/storage-base-7.png';
import StorageBase5Webp from '../../assets/storage-base-7.webp';

import SvgMattress from '../../static/images/add-on-mattress.svg';
import SvgMysa from '../../static/images/add-on-mysa.svg';
import SvgPillow from '../../static/images/add-on-pillow.svg';
import SvgTerra from '../../static/images/add-on-terra.svg';
import { useGraphQLGetAllProducts } from '../../hooks/use-graphql-get-product';
import { BreadcrumbLink } from '../common/breadcrumb';
import { ProductAddOnConfig } from '../../types/product';

const Section = styled(ProductSection)`
  background-color: var(--spring-wood);
`;

const StorageBaseProductSection: React.FC = () => {
  const addOnConfig: ProductAddOnConfig = {
    [PRODUCT_ORIGINAL_MATTRESS_ID]: {
      icon: <SvgMattress />,
      name: 'Original',
      sort: 2,
      qty: 1,
    },
    [PRODUCT_MYSA_MATTRESS_ID]: {
      icon: <SvgMysa />,
      name: 'Mysa',
      sort: 3,
      qty: 1,
    },
    [PRODUCT_PILLOW_ID]: {
      icon: <SvgPillow />,
      name: 'Pillow',
      sort: 1,
      qty: 2,
    },
    [PRODUCT_TERRA_MATTRESS_ID]: {
      icon: <SvgTerra />,
      name: 'Terra',
      sort: 4,
      qty: 1,
    },
  };

  const breadcrumbs: BreadcrumbLink[] = [
    {
      label: 'Storage',
    },
    { label: 'Storage Base' },
  ];
  const productId = PRODUCT_STORAGE_BASE_ID;
  const allProducts = useGraphQLGetAllProducts();

  const product = allProducts.find(p => p.id === productId);

  const addOns = sortBy(
    allProducts.filter(p => p.id in addOnConfig).map(p => p),
    [p => addOnConfig[p.id].sort]
  );

  return (
    <Section
      {...product}
      version="v2"
      addOns={addOns}
      addOnConfig={addOnConfig}
      addOnLabel={<>Accessories add&#8209;on</>}
      defaultAddOnText="Woosa Storage Base only"
      breadcrumbs={breadcrumbs}
      assets={[
        {
          src: StorageBase1,
          type: 'video',
        },
        {
          src: StorageBase2,
          webp: StorageBase2Webp,
          type: 'image',
        },
        {
          src: StorageBase3,
          webp: StorageBase3Webp,
          type: 'image',
        },
        {
          src: StorageBase4,
          webp: StorageBase4Webp,
          type: 'image',
        },
        {
          src: StorageBase5,
          webp: StorageBase5Webp,
          type: 'image',
        },
      ]}
    >
      <p>
        Where form meets function. Enjoy extra space in your bedroom with The
        Woosa Storage Bed Base. With the easiest hydraulic lift in the market,
        it rises automatically with a tug of the latch, and closes with ease.
      </p>
      <ul>
        <li>Denmark-made SUSPA Hydraulic System</li>
        <li>Unique sit-in design with a spacious 30cm depth of storage</li>
        <li>Over 100 different headboard and side frame options available</li>
      </ul>
    </Section>
  );
};

export default StorageBaseProductSection;
