import React from 'react';
import styled from 'styled-components';
import { PRODUCT_SERES_SILK_DUVET_ID } from 'gatsby-env-variables';

import { ReviewGridSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const Section = styled(ReviewGridSection)``;

const SeresSilkDuvetReviewGridSection: React.FC = () => {
  return (
    <Section
      filters={{ id: PRODUCT_SERES_SILK_DUVET_ID }}
      allReviewsUrl={SiteUrls.SeresSilkDuvetReview}
    />
  );
};

export default SeresSilkDuvetReviewGridSection;
