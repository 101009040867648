import React from 'react';
import styled from 'styled-components';
import { Col, Row, media } from 'styled-bootstrap-grid';

import { lh, rem } from '../../styles/utils';
import Container from './container';

const Section = styled.section`
  background-color: var(--mercury);
  font-family: var(--font-body);
  padding: 48px 0;

  ${media.lg`
    padding: 64px 0;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(28.8, 24)};
  color: var(--font-primary-color);
  text-align: center;
  margin-top: 0;
  margin-bottom: 24px;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};
    margin-bottom: 32px;
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;

  img {
    width: 100%;
  }
`;

const Card = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 40px;
  margin-bottom: 40px;

  ${media.lg`
    padding: 0;
  `}
`;

const Content = styled.p`
  font-family: var(--font-body);
  font-weight: normal;
  font-size: ${rem(14)};
  line-height: ${lh(14, 14)};
  color: var(--xanadu);
  margin-top: 0;
  margin-bottom: 0;
  margin-right: auto;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)}; 
  `}
`;

type PictureGridItem = {
  image: React.ReactNode;
  content: React.ReactNode;
};

type CardProps = {
  className?: string;
  item: PictureGridItem;
};

const PictureGridCard: React.FC<CardProps> = ({
  className,
  item: { image, content },
}) => {
  return (
    <Card className={className}>
      <ImageContainer>{image}</ImageContainer>
      <Wrapper>
        <Content>{content}</Content>
      </Wrapper>
    </Card>
  );
};

type Props = {
  className?: string;
  headline: React.ReactNode;
  items: PictureGridItem[];
};

const PictureGridSection: React.FC<Props> = ({
  className,
  headline,
  items,
}) => {
  return (
    <Section className={className}>
      <Container>
        <Wrapper>
          <Headline>{headline}</Headline>
        </Wrapper>
        <Row mdJustifyContent="center">
          {items.map((i, idx) => (
            <Col xs={12} md={6} lg={4} key={idx}>
              <PictureGridCard
                className={items.length > 1 ? 'grid' : ''}
                item={i}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default PictureGridSection;
