import React from 'react';
import styled from 'styled-components';

import ArrowRightSvg from '../../static/images/arrow-right.svg';
import { outlineButtonStyle, flatButtonStyle } from '../../styles/utils';
import GatsbyLink from './link';

export enum CTAIcons {
  ARROW_RIGHT = 'ARROW_RIGHT',
}

type IconsType = {
  [key: string]: JSX.Element;
};

const Icons: IconsType = {
  [CTAIcons.ARROW_RIGHT]: <ArrowRightSvg />,
};

type Props = {
  to: string;
  isExternal?: boolean;
  children?: React.ReactNode | React.ReactNodeArray;
  className?: string;
  icon?: string;
  type?: '' | 'flat' | 'outline';
  theme?: 'light' | 'dark';
};

const Link = styled(GatsbyLink)`
  display: inline-block;
  border: 0;
  background: transparent;
`;

const Flat = styled(GatsbyLink)`
  ${flatButtonStyle()}
`;

const Outline = styled(GatsbyLink)`
  ${({ theme }) => outlineButtonStyle(theme)};
`;

const CTA: React.FC<Props> = props => {
  const { icon, children, type } = props;
  let Component;

  switch (type) {
    case 'flat':
      Component = Flat;
      break;
    case 'outline':
      Component = Outline;
      break;
    default:
      Component = Link;
  }

  return (
    <Component {...props}>
      {icon && icon in Icons && Icons[icon]}
      {children}
    </Component>
  );
};

export default CTA;
