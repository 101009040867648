import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';

import { rem } from '../../styles/utils';
import { applyPromo, isReferralCode } from '../../utils/api';
import { useCartState, useCartDispatch } from '../../hooks/cart-context';

const Container = styled.div`
  width: 100%;
  padding: 0 15px;
  font-family: var(--font-body);
  font-weight: normal;
  color: var(--font-cart-color);
`;

const Label = styled.label`
  font-size: ${rem(14)};

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

const InputGroup = styled.div`
  position: relative;
  width: 60%;
  max-width: 230px;
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--border-color);
  background-color: transparent;
  padding: 10px;
`;

const Button = styled.button`
  font-size: ${rem(12)};
  color: var(--font-cart-color);
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  ${media.xl`
    font-size: ${rem(14)};
  `}
`;

const Error = styled.span`
  display: block;
  margin-top: 5px;
  color: red;
  font-size: 80%;
`;

type FormData = {
  code: string;
};

const PromoInput: React.FC = () => {
  const { register, handleSubmit, errors, reset } = useForm<FormData>();
  const [submitError, setSubmitError] = useState('');
  const { products, promos } = useCartState();
  const dispatch = useCartDispatch();

  const hasErrors = () => Object.keys(errors).length > 0;

  const setIsLoading = (isLoading: boolean) =>
    dispatch({ type: 'SET_IS_LOADING', isLoading });

  const onSubmit = async (data: FormData) => {
    setSubmitError('');
    if (hasErrors()) return;

    setIsLoading(true);
    const { code } = data;

    if (await isReferralCode(code)) {
      dispatch({ type: 'APPLY_REFERRAL', referralCode: code });
      reset();
      return;
    }

    const promoData = await applyPromo(
      code,
      products,
      promos.map(p => p.code)
    );

    setIsLoading(false);

    if (!promoData) {
      setSubmitError('Invalid promo code.');
    } else {
      reset();

      if (
        promoData.type === 'bundle' &&
        promoData.bundle &&
        promoData.bundle.gets &&
        promoData.bundle.gets.some(b => b.products && b.products.length > 0)
      ) {
        dispatch({
          type: 'SHOW_PROMO_BUNDLE_MODAL',
          bundle: promoData.bundle,
          promo: promoData,
        });
      } else {
        dispatch({
          type: 'APPLY_PROMO',
          promo: promoData,
        });
      }
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label>Add Promo Code:</Label>
        <InputGroup>
          <Input type="text" name="code" ref={register({ required: true })} />
          <Button disabled={hasErrors()}>Apply</Button>
        </InputGroup>
        {submitError && <Error>{submitError}</Error>}
      </form>
    </Container>
  );
};

export default PromoInput;
