/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Header from './header';
import Footer from './footer';
import CartDrawer from './cart-drawer';
import { BaseCSS } from 'styled-bootstrap-grid';
import { GlobalStyle, GridProvider } from '../../styles';
import { CartProvider } from '../../hooks/cart-context';
import { AuthProvider } from '../../hooks/auth-context';
import { CountryProvider } from '../../hooks/country-context';
import { UnderConstructionSection } from '../sections';
import { UNDER_CONSTRUCTION } from 'gatsby-env-variables';
import { Whatsapp } from '../common';
import { fbq } from '../../utils/analytics';
import { CountryCode, FacebookPixelEvent } from '../../utils/constants';

interface Props {
  countryCode?: CountryCode;
  children?: React.ReactNodeArray | React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  announcement?: React.ReactNodeArray | React.ReactNode;
  enablePage?: boolean;
}

const Layout: React.FC<Props> = ({
  countryCode,
  children,
  hideHeader,
  hideFooter,
  announcement,
  enablePage,
}) => {
  useEffect(() => {
    if (enablePage) return;
    window.location.href = 'https://woosasleep.co';
  }, [enablePage]);

  if (!enablePage) {
    return (
      <>
        You&apos;ve reached our old website. We&apos;ve moved to a new location!
        You will be automatically redirected to woosasleep.co in 5 seconds. If
        the redirect doesn&apos;t work, click{' '}
        <a href="https://woosasleep.co">here</a>.
      </>
    );
  }

  useEffect(() => {
    fbq(FacebookPixelEvent.ViewContent);
  }, []);

  return (
    <CountryProvider countryCode={countryCode}>
      <AuthProvider>
        <GridProvider>
          <>
            <BaseCSS />
            <GlobalStyle />
            <Helmet>
              <meta
                name="facebook-domain-verification"
                content="wxpvaqunsc448aiqj73uxom22kjrdd"
              />
              <meta
                name="google-site-verification"
                content="z5x0FTm9tLZD7RHciY8Pz8xmPFrHjWjI9nSMLyQ02sA"
              />
              <meta name="theme-color" content="#fff"></meta>
              <script>
                {`
                !function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                
                  ttq.load('CGL6V73C77UCQHK1FCMG');
                  ttq.page();
                }(window, document, 'ttq');
                `}
              </script>
            </Helmet>
            {UNDER_CONSTRUCTION ? (
              <UnderConstructionSection />
            ) : (
              <>
                <CartProvider>
                  {!hideHeader && <Header announcement={announcement} />}
                  <main>{children}</main>
                  <CartDrawer className="cart-drawer" />
                </CartProvider>
                {!hideFooter && <Footer />}
              </>
            )}
            <Whatsapp className="whatsapp-fixed" />
          </>
        </GridProvider>
      </AuthProvider>
    </CountryProvider>
  );
};

export default Layout;
