import React from 'react';

import { WarrantyIntro } from '../common';

const SeresSilkDuvetWarrantyIntroSection: React.FC = () => {
  return (
    <WarrantyIntro headline="3 Year Limited Warranty">
      The Seres Silk Duvet’s natural high grade silk construction is designed to
      keep you warm and sung for many years to come. In the unlikely case that
      your Seres Silk Duvet Is met with any defect, we will replace or repair it
      without any charge to you. Your new Seres Silk Duvet is covered by a
      3-Year Limited Warranty, dating from the time you purchased your new duvet
      from Woosa Sleep Pte Ltd (“Woosa”) or it’s authorized distributors. The
      details of this warranty, and some limitations, are provided in the
      complete Warranty Description below.
    </WarrantyIntro>
  );
};

export default SeresSilkDuvetWarrantyIntroSection;
