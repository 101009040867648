import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { Product } from '../../../types/product';
import { rem } from '../../../styles/utils';
import { getProductById } from '../../../utils/api';
import Spinner from '../spinner';
import { Props } from './';
import { currencyFormat } from '../../../utils';
import { CommonVariants } from '../../../utils/constants';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
  position: relative;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: 1.2;
  margin-bottom: 10px;
  display: block;

  ${media.lg`
  font-size: ${rem(14)};
`}

  ${media.xl`
  font-size: ${rem(16)};
  line-height: 1.3125;
`}
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
`;

const Item = styled.li`
  margin-right: 10px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  background-color: transparent;
  width: 44px;
  height: 44px;
  border-radius: 2.5px;
  margin-bottom: 3.5px;
  position: relative;
  border: 1px solid white;

  &.selected {
    border: 1px solid var(--border-color);
    padding: 3px;
  }
`;

const IconWrapper = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const IconQty = styled.small`
  position: absolute;
  bottom: 1px;
  font-size: ${rem(10)};
  line-height: 1.2;
  right: 1px;
`;

const IconLabel = styled.label`
  font-size: ${rem(12)};
  line-height: 1.16;
`;

const Tooltip = styled(ReactTooltip)`
  box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px !important;
  font-family: var(--font-body);
  padding: 20px 15px !important;

  &.show {
    opacity: 1 !important;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.strong`
  font-weight: 600px;
  font-size: ${rem(16)};
  line-height: 1.3125;
  color: var(--font-primary-color);
`;

const Thumbnail = styled.img`
  width: 100%;
  max-width: 160px;
  margin-bottom: 10px;
`;

const Price = styled.span`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height: 1.3333;
  color: var(--leather);
`;

const ProductAddOn: React.FC<Props> = ({
  label,
  products,
  selectedVariant,
  config,
  onChange,
}) => {
  const [addOns, setAddOns] = useState<Product[]>(products || []);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (!products) return;
    setIsLoading(true);
    const promises = products.map(({ id }) => getProductById(id));
    Promise.all(promises)
      .then(data => {
        for (const product of products) {
          const dataProduct = data.find(d => d.id === product.id);
          if (!dataProduct) continue;
          let variants = dataProduct.variants.filter(
            v => v.status !== 'Out of Stock'
          );
          if (selectedVariant !== null && variants.length > 1) {
            const lcSelectedVariant = selectedVariant?.type.toLowerCase();
            variants = variants.filter(
              v =>
                v.type.toLowerCase() ===
                (lcSelectedVariant === CommonVariants.SPLIT_KING
                  ? CommonVariants.SINGLE
                  : lcSelectedVariant)
            );
          }
          if (variants.length === 0) continue;
          product.variants = variants;
        }

        setAddOns([...products]);

        if (selectedIndex > -1) {
          handleChange(products[selectedIndex], selectedIndex);
        }
      })
      .finally(() => setIsLoading(false));
  }, [products, selectedVariant]);

  const handleChange = (product: Product | null, index: number, qty = 1) => {
    // handle selecting on the same add-on, remove it instead
    if (index === selectedIndex) {
      if (typeof onChange === 'function') {
        onChange(null);
      }
      setSelectedIndex(-1);
      return;
    }

    setSelectedIndex(index);
    if (typeof onChange !== 'function') return;
    if (product !== null && product.variants.length > 0) {
      const variant = product.variants[0];
      const price = variant.prices[0];

      onChange({
        id: product.id,
        name: product.name,
        collection: product.collection,
        image:
          product.media.find(
            m =>
              (m.default && m.type !== 'video') ||
              (!m.default && m.type === 'image')
          )?.path || '',
        comparePrice: price.compareAmount || 0,
        currency: price.currency,
        qty,
        subtotal: qty * price.amount,
        unitPrice: price.amount,
        variant: variant.type,
        isNew: true,
        status: variant.status,
        statusDate:
          variant.statusDate &&
          moment.unix(variant.statusDate._seconds).toDate(),
      });
    } else {
      onChange(null);
    }
  };

  return (
    <Container>
      <Row>
        <Col xl={4}>
          <Label>{label}</Label>
        </Col>
        <Col xl={8}>
          <List>
            {addOns &&
              addOns.map((product, index) => {
                const cProduct = config
                  ? config[product.id]
                  : { name: product.name, icon: '', qty: 1 };

                if (product.collection === 'Mattress')
                  cProduct.qty =
                    selectedVariant?.type.toLowerCase() ===
                    CommonVariants.SPLIT_KING
                      ? 2
                      : 1;

                const thumbnail =
                  product.media.find(
                    m =>
                      (m.default && m.type !== 'video') ||
                      (!m.default && m.type === 'image')
                  )?.path || '';

                return (
                  <Item
                    key={product.id}
                    onClick={() => handleChange(product, index, cProduct.qty)}
                  >
                    <a data-tip data-for={product.id}>
                      <IconContainer
                        className={index === selectedIndex ? 'selected' : ''}
                        data-tip
                        data-for={product.id}
                      >
                        <IconWrapper>
                          {cProduct.icon}
                          {cProduct.qty > 1 && (
                            <IconQty>x{cProduct.qty}</IconQty>
                          )}
                        </IconWrapper>
                      </IconContainer>
                      <IconLabel>{cProduct.name}</IconLabel>
                    </a>
                    {thumbnail && (
                      <Tooltip id={product.id} backgroundColor="white">
                        <TooltipWrapper>
                          <Thumbnail src={thumbnail} alt={product.name} />
                          <Name>{product.name}</Name>
                          <Price>
                            +{' '}
                            {currencyFormat(
                              product.variants[0].prices[0].amount
                            )}
                          </Price>
                        </TooltipWrapper>
                      </Tooltip>
                    )}
                  </Item>
                );
              })}
          </List>
        </Col>
      </Row>
      <Spinner isLoading={isLoading} size={30} />
    </Container>
  );
};

export default ProductAddOn;
