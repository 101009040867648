import React from 'react';

import ProductSectionInfoV1, {
  Props as PropsV1Default,
} from './product-section-info-v1';
import ProductSectionInfoV2, {
  Props as PropsV2Default,
} from './product-section-info-v2';

export type PropsV1 = {
  version: 'v1';
} & PropsV1Default;

export type PropsV2 = {
  version: 'v2';
} & PropsV2Default;

export type Props = PropsV1 | PropsV2;

const ProductSectionInfo: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <ProductSectionInfoV2 {...props} />;
    default:
      return <ProductSectionInfoV1 {...props} />;
  }
};

export default ProductSectionInfo;
