import React, { useEffect } from 'react';
import styled from 'styled-components';
import { maxBy } from 'lodash';
import { Row as GridRow, Col, media } from 'styled-bootstrap-grid';

import { SiteUrls } from '../../utils/constants';
import GridCarousel from './carousel';
import GridContainer from './container';
import UpsellGridCard from './upsell-grid-card';
import { Props as PictureProps } from './picture';
import { lh, mediaQueryUpto, rem } from '../../styles/utils';
import { Breakpoints } from '../../styles/enums';

const Section = styled.section`
  background: var(--mystic);
  padding: 64px 0;
`;

const Headline = styled.h2`
  text-align: center;
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(28.8, 24)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 32px;

  ${media.xs`
    padding: 0 40px;
  `}
`;

const Container = styled(GridContainer)`
  ${media.xs`
    width: 100%;
    padding: 0;
 `}
`;

const Row = styled(GridRow)`
  ${mediaQueryUpto(Breakpoints.MD, 'display: none')}
`;

const CarouselItem = styled.div`
  padding-left: 40px;
`;

const Carousel = styled(GridCarousel)`
  ${media.md`
    display: none;
  `}
`;

const UpsellCard = styled(UpsellGridCard)``;

type Props = {
  className?: string;
  headline: React.ReactNode;
  products: {
    id: string;
    picture: PictureProps;
    to: SiteUrls;
  }[];
};

const UpsellGridSection: React.FC<Props> = ({
  className,
  headline,
  products,
}) => {
  useEffect(() => {
    const scaleItemHeight = () => {
      const items = document.querySelectorAll('.upsell-item');

      items.forEach(item => item.removeAttribute('style'));

      const tallestItem = maxBy(items, item => {
        return item.getBoundingClientRect().height;
      });

      const tallestHeight = tallestItem?.getBoundingClientRect().height;

      items.forEach(item =>
        item.setAttribute('style', `height:${tallestHeight}px`)
      );
    };

    window.addEventListener('resize', scaleItemHeight);
    setTimeout(scaleItemHeight, 1000);

    return () => window.removeEventListener('resize', scaleItemHeight);
  }, []);

  return (
    <Section className={className}>
      <Container>
        <Headline>{headline}</Headline>
        <Row>
          {products.map(p => (
            <Col md={4} key={p.id}>
              <UpsellCard className="upsell-item" productId={p.id} {...p} />
            </Col>
          ))}
        </Row>
        <Carousel
          props={{
            showThumbs: false,
            showStatus: false,
            centerMode: true,
            showIndicators: false,
          }}
        >
          {products.map(p => (
            <CarouselItem key={p.id}>
              <UpsellCard className="upsell-item" productId={p.id} {...p} />
            </CarouselItem>
          ))}
        </Carousel>
      </Container>
    </Section>
  );
};

export default UpsellGridSection;
