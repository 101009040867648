import React from 'react';

import {
  Content,
  Headline,
  Subheadline,
  ArrowCTA,
  ButtonCTA,
  SlideProps,
  Overlay,
} from './hero-carousel-utils';
import { CTAIcons } from '../cta';

const HeroCarouselImageSlide: React.FC<SlideProps> = ({
  Background,
  headline,
  subheadline,
  cta,
  overlay,
}) => {
  const renderContent = () => {
    if (headline || subheadline || cta) {
      return (
        <Content>
          {headline && (
            <Headline dangerouslySetInnerHTML={{ __html: headline }}></Headline>
          )}
          {subheadline && (
            <Subheadline
              dangerouslySetInnerHTML={{ __html: subheadline }}
            ></Subheadline>
          )}
          {cta?.to && <ArrowCTA to={cta?.to} icon={CTAIcons.ARROW_RIGHT} />}
          {cta?.to && (
            <ButtonCTA to={cta?.to} type="flat">
              {cta?.text}
            </ButtonCTA>
          )}
        </Content>
      );
    }
  };

  return (
    <Background>
      {overlay && overlay.disabled ? (
        renderContent()
      ) : (
        <Overlay className={overlay?.className}>{renderContent()}</Overlay>
      )}
    </Background>
  );
};

export default HeroCarouselImageSlide;
