import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import { Benefit } from '../../types/component';
import {
  ImgPillowBenefit1,
  ImgPillowBenefit2,
  ImgPillowBenefit3,
} from '../assets';

const Section = styled(ProductBenefitSection)`
  background-color: var(--pearl-bush);
`;

const benefits: Benefit[] = [
  {
    img: <ImgPillowBenefit1 />,
    eyebrow: 'Pressure Relief',
    headline: 'Highly adaptive',
    body:
      'The Woosa Cloud Foam possesses a unique adaptive capability that gently contours to heads of different shapes and weight, and offers ample support regardless of sleeping positions.',
  },
  {
    img: <ImgPillowBenefit2 />,
    eyebrow: 'Support',
    headline: 'Stays in shape',
    body:
      'No shredded foams or down fillings that lose shape and strain your neck. The Woosa Pillow stays in shape and instils proper posture for all sleeping positions, including front, back and side sleepers through the night. No fluffing required.',
  },
  {
    img: <ImgPillowBenefit3 />,
    eyebrow: 'Pressure Relief',
    headline: 'Incredibly comfortable',
    body:
      "Its plush, velvety sensation and extreme breathability creates a feeling of weightlessness, making it feel like you're resting on a soft, fluffy cloud.",
  },
];

const PillowBenefitSection: React.FC = () => (
  <Section
    benefits={benefits}
    blob={{ color1: '#dfcac3', color2: 'white' }}
    waveLineColor="#cac1b7"
  />
);

export default PillowBenefitSection;
