import React from 'react';

import { MattressFirmnessSection } from '../common';
import Video from '../../static/videos/terra-mattress-firmness.mp4';

const TerraMattressFirmnessSection: React.FC = () => {
  return (
    <MattressFirmnessSection
      headline="How does it feel"
      firmness={9}
      description="A firm surface that is also responsive due to bouncy latex, making it great for back sleepers and heavier side sleepers."
      asset={{
        src: Video,
        type: 'video',
      }}
    />
  );
};

export default TerraMattressFirmnessSection;
