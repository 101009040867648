import React from 'react';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import {
  PRODUCT_PILLOW_ID,
  PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID,
} from 'gatsby-env-variables';
import { useStaticQuery, graphql } from 'gatsby';

import Hero1 from '../../static/videos/waterproof-mattress-protector-1.mp4';
import Hero1Thumbnail from '../../assets/waterproof-mattress-protector-1-thumbnail.png';
import Hero1Webp from '../../assets/waterproof-mattress-protector-1-thumbnail.webp';
import Hero2 from '../../assets/waterproof-mattress-protector-2.png';
import Hero2Webp from '../../assets/waterproof-mattress-protector-2.webp';
import Hero3 from '../../assets/waterproof-mattress-protector-3.png';
import Hero3Webp from '../../assets/waterproof-mattress-protector-3.webp';
import { ProductSection } from '../common';
import { Product, ProductAddOnConfig } from '../../types/product';
import SvgPillow from '../../static/images/add-on-pillow.svg';

const Section = styled(ProductSection)`
  background-color: var(--pearl-bush);
`;

const WaterproofMattressProtectorProductSection: React.FC = () => {
  const id = PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID;
  const addOnConfig: ProductAddOnConfig = {
    [PRODUCT_PILLOW_ID]: {
      icon: <SvgPillow />,
      name: 'Pillow',
      sort: 1,
      qty: 1,
    },
  };

  const {
    allProducts: { edges },
  } = useStaticQuery(graphql`
    query {
      allProducts(filter: { displayInStore: { eq: true } }) {
        edges {
          node {
            id
            name
            collection
            media {
              path
              default
              type
            }
            rating {
              avg
              count
              total
            }
            variants {
              type
              prices {
                amount
                currency
              }
              status
              statusDate {
                _seconds
              }
            }
            displayInStore
          }
        }
      }
    }
  `);

  const product = edges.find(
    ({ node }: { node: { id: string } }) => node.id === id
  );
  const addOns = sortBy(
    edges
      .filter(({ node }: { node: { id: string } }) => node.id in addOnConfig)
      .map(({ node }: { node: Product }) => node),
    [p => addOnConfig[p.id].sort]
  );

  return (
    <Section
      {...product.node}
      addOns={addOns}
      addOnConfig={addOnConfig}
      addOnLabel={<>Accessories add&#8209;on</>}
      defaultAddOnText="Woosa pillow only"
      assets={[
        {
          src: Hero1,
          thumbnail: Hero1Thumbnail,
          webp: Hero1Webp,
          type: 'video',
        },
        {
          src: Hero2,
          webp: Hero2Webp,
          type: 'image',
        },
        {
          src: Hero3,
          webp: Hero3Webp,
          type: 'image',
        },
      ]}
      infoVersion="v2"
    >
      <p>
        Get more sleeps out of your mattress. Chase your zzz’s without worrying
        about spills.
      </p>
      <ul>
        <li>100% waterproof for worry-free sleep</li>
        <li>Ultra breathable and cool to the touch</li>
        <li>Made with silent, noise-free fabric</li>
        <li>Snug, movement-free fit throughout the night </li>
        <li>Hypoallergenic fabric that&apos;s kind to your skin</li>
      </ul>
    </Section>
  );
};

export default WaterproofMattressProtectorProductSection;
