import React from 'react';

import MattressLateralSectionV1 from './mattress-lateral-section';
import MattressLateralSectionV2 from './mattress-lateral-section-v2';

type Props = {
  version?: 'v1' | 'v2';
};

const MattressLateralSection: React.FC<Props> = ({ version }) => {
  switch (version) {
    case 'v2':
      return <MattressLateralSectionV2 />;
    default:
      return <MattressLateralSectionV1 />;
  }
};

export default MattressLateralSection;
