import React from 'react';
import { Link as DefaultLink } from 'gatsby';

import { useCountryState } from '../../hooks/country-context';
import { navigateWithCountryCode } from '../../utils';

type Props = React.ComponentProps<typeof DefaultLink>;

const Link: React.FC<Props> = props => {
  const { countryCode } = useCountryState();
  const to = navigateWithCountryCode({ countryCode, to: props.to });

  return <DefaultLink {...props} to={to} />;
};

export default Link;
