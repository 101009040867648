import React from 'react';
import styled from 'styled-components';

import SvgClose from '../../static/images/close.svg';

const Button = styled.button`
  background: transparent;
  border: none;
  margin-left: auto;

  svg {
    display: block;
    width: 20px;
  }
`;

type Props = {
  className?: string;
  onClick?: () => void;
};

const CloseButton: React.FC<Props> = ({ className, onClick }) => {
  return (
    <Button className={className} onClick={onClick}>
      <SvgClose />
    </Button>
  );
};

export default CloseButton;
