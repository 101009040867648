import React from 'react';
import styled from 'styled-components';

import { IntroGallerySection } from '../common';
import ImgStorageBaseIntroGallery2 from '../../static/videos/storage-base-intro-gallery-2.mp4';
import {
  ImgStorageBaseIntroGallery3,
  ImgStorageBaseIntroGallery1,
} from '../assets';

const UnorderedList = styled.ul``;

const ListItem = styled.li``;

const StorageBaseIntroGallerySection: React.FC = () => {
  return (
    <IntroGallerySection
      eyebrow="Make it yours"
      headline="Over 100 different combination options"
      gallery={[
        {
          type: 'image',
          img: <ImgStorageBaseIntroGallery1 />,
        },
        {
          type: 'video',
          src: ImgStorageBaseIntroGallery2,
          title: 'Water-resistant Fabric',
          description: (
            <UnorderedList>
              <ListItem>100% Linen Upholstery Fabric</ListItem>
              <ListItem>Hydrophobic coating for high water repellence</ListItem>
              <ListItem>
                Stain and Oil resistant, Easy clean with just water
              </ListItem>
            </UnorderedList>
          ),
        },
        {
          type: 'image',
          img: <ImgStorageBaseIntroGallery3 />,
          title: 'Vegan Leather',
          description: (
            <UnorderedList>
              <ListItem>
                Sustainably made leather alternative made from plant-based
                materials
              </ListItem>
              <ListItem>
                Supple and smooth to the touch with high durability
              </ListItem>
              <ListItem>3-year peel guarantee warranty</ListItem>
            </UnorderedList>
          ),
        },
      ]}
    />
  );
};

export default StorageBaseIntroGallerySection;
