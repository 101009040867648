import React from 'react';

import Container from './container';
import HorizontalTile, { Props as HorizontalTileProp } from './horizontal-tile';

type Props = {
  className?: string;
  tiles: HorizontalTileProp[];
};

const HorizontalTileList: React.FC<Props> = ({ className, tiles }) => {
  return (
    <Container className={className}>
      {tiles.map((tile, idx) => {
        return <HorizontalTile key={idx} {...tile} />;
      })}
    </Container>
  );
};

export default HorizontalTileList;
