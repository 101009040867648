import React from 'react';

import { MattressLayer } from '../common';

import SvgCover from '../../static/images/original-mattress-layer-cover.svg';
import SvgPlusCover from '../../static/images/plus-cover-layer.svg';
import Svg1 from '../../static/images/original-mattress-layer-1.svg';
import Svg2 from '../../static/images/original-mattress-layer-2.svg';
import Svg3 from '../../static/images/original-mattress-layer-3.svg';
import Svg4 from '../../static/images/original-mattress-layer-4.svg';
type Props = {
  isPlusCover?: boolean;
};
const OriginalMattressLayerSection: React.FC<Props> = ({ isPlusCover }) => {
  return (
    <>
      <MattressLayer
        version="v2"
        headline="Let’s look under the covers."
        isPlusCover={isPlusCover}
        plusCoverInfo={{
          title: 'The PLUS+ Cover',
          subtitle: '',
          description:
            'Made in Italy from a blend of microtencel and cooling yarn. Quilted with a layer of organic cotton for plushier and firmer feel. Low-friction and allows for seamless movement without generating heat.',
          asset: <SvgPlusCover />,
        }}
        infos={[
          {
            title: 'THE COVER',
            subtitle: 'Italian-made Microtencel',
            description:
              'Incredibly soft and plush microtencel with embedded silver threading for added antimicrobial properties and odour reduction.',
            transition: {
              initial: { x: '-5px', y: '186px' },
              active: { x: '0', y: '-30%' },
              neutral: { x: '0', y: '-55%' },
            },
            asset: <SvgCover />,
            cover: {
              initial: { x: '0', y: '705px', mdY: '755px' },
              active: { x: '0', y: '-590%', mdY: '-590%' },
              neutral: { x: '0', y: '-655%', mdY: '-655%' },
              size: { width: '440px', height: '100px' },
            },
          },
          {
            title: 'THE COMFORT LAYER',
            subtitle: 'Ultra Premium Sonocore® Latex - 5.5cm',
            description:
              'Offers excellent resiliency and responsiveness to maintain optimal sleeping posture throughout the night.',
            transition: {
              initial: { x: '0', y: '87px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-33%' },
            },
            asset: <Svg1 />,
            cover: {
              initial: { x: '0', y: '285px', mdY: '335px' },
              active: { x: '0', y: '-202%', mdY: '-202%' },
              neutral: { x: '0', y: '-334%', mdY: '-334%' },
              size: { width: '380px', height: '28px' },
            },
          },
          {
            title: 'THE SUPPORT LAYER',
            subtitle: 'Ultra Premium Memory Foam - 5.5cm',
            description:
              'Highly adaptive for localised pressure relieving support with gentle contouring properties.',
            transition: {
              initial: { x: '0', y: '97px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-38%' },
            },
            asset: <Svg2 />,
            cover: {
              initial: { x: '0', y: '314px', mdY: '364px' },
              active: { x: '0', y: '-169%', mdY: '-169%' },
              neutral: { x: '0', y: '-324%', mdY: '-324%' },
              size: { width: '380px', height: '29px' },
            },
          },
          {
            title: 'THE TRANSITION LAYER',
            subtitle: 'High Resiliency Transition Foam - 5cm',
            description:
              'Provides even weight distribution and additional comfort and support at a deeper level.',
            transition: {
              initial: { x: '0', y: '68px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-38%' },
            },
            asset: <Svg3 />,
            cover: {
              initial: { x: '0', y: '343px', mdY: '393px' },
              active: { x: '0', y: '-174%', mdY: '-174%' },
              neutral: { x: '0', y: '-331%', mdY: '-331%' },
              size: { width: '380px', height: '28px' },
            },
          },
          {
            title: 'THE FOUNDATION',
            subtitle: 'Ultra High Density BaseCore Foam - 14cm',
            description:
              'Foundational base for added lift and zero motion transfer when you toss and turn.',
            transition: {
              initial: { x: '0', y: '0' },
              active: { x: '0', y: '0' },
              neutral: { x: '0', y: '0' },
            },
            asset: <Svg4 />,
            cover: {
              initial: { x: '0', y: '371px', mdY: '421px' },
              active: { x: '0', y: '0', mdY: '0' },
              neutral: { x: '0', y: '0', mdY: '0' },
              size: { width: '380px', height: '68px' },
            },
          },
        ]}
      />
    </>
  );
};

export default OriginalMattressLayerSection;
