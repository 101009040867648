import React, { useState, useEffect } from 'react';
import { USE_TEMP_ADDRESS } from 'gatsby-env-variables';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';
import { Modal, StoreAddress, StoreHours, Whatsapp } from '../common';

const Address = styled(StoreAddress)`
  color: var(--font-primary-color);
  text-decoration: none;
`;

const Container = styled.div`
  font-family: var(--font-body);
  font-size: ${rem(16)};
  color: var(--font-primary-color);
  line-height: 1.4;
  width: 100%;

  a:not(${Address}) {
    color: var(--font-primary-color);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin-bottom: 20px;

  ${media.lg`
    font-size: ${rem(38)};
    margin-bottom: 30px;
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const Body = styled.p``;

const TempAddressModal: React.FC<{
  display: boolean;
  onClickClose: () => void;
}> = ({ display, onClickClose }) => {
  return (
    <Modal
      className="temp-address-modal"
      isActive={display}
      backdrop="allowClose"
      onClickClose={onClickClose}
    >
      <Container>
        <Headline>We&apos;re upgrading your sleep experience! </Headline>
        <Body>
          Our Sleep Lab is closed for renovation until further notice. In the
          meantime, visit our temporary showroom:
        </Body>
        <Address />
        <br />
        <br />
        <StoreHours />
        <Body>
          Click{' '}
          <a href="#appointment" onClick={onClickClose}>
            here
          </a>{' '}
          to book a slot or WhatsApp <Whatsapp hideIcon />.
        </Body>
      </Container>
    </Modal>
  );
};

const TempAddressController: React.FC = () => {
  const [displayTempAddress, setDisplayTempAddress] = useState(false);

  useEffect(() => {
    if (!USE_TEMP_ADDRESS) return;
    if (displayTempAddress) return;
    setDisplayTempAddress(true);
  }, []);

  return (
    <div>
      <TempAddressModal
        display={displayTempAddress}
        onClickClose={() => setDisplayTempAddress(false)}
      />
    </div>
  );
};

export default TempAddressController;
