import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { HamburgerSqueeze } from 'react-animated-burgers';
import { ENABLE_ACCOUNT } from 'gatsby-env-variables';
import moment from 'moment';

import {
  Overlay,
  Container,
  CartButton,
  Logo,
  AuthButton,
  CustomPopup,
} from '../common';
import { Config } from '../../styles/enums';
import Navbar from './navbar';
import { SiteUrls } from '../../utils/constants';
import useScrollTracker from '../../hooks/use-scroll-tracker';
import { rem } from '../../styles/utils';
import useWindowResize from '../../hooks/use-window-resize';

const LogoLink = styled(Logo)`
  margin-left: 10px;

  ${media.lg`
  margin-left: 30px;
  `}
`;

const Hamburger = styled(HamburgerSqueeze)`
  display: block;

  ${media.lg`
    display: none;
  `}
`;

const HeaderWrapper = styled.header<{ top?: number }>`
  position: fixed;
  top: ${({ top }) => `${top}px` ?? 0};
  left: 0;
  background: white;
  border-bottom: none;
  width: 100%;
  height: ${Config.XS_HEADER_HEIGHT}px;
  transform: translateY(0);
  transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out,
    border-bottom 0.5s ease-in-out;

  &.hide {
    transform: translateY(-100%);
  }

  ${media.lg`
    background-color: transparent;
    height: auto;
    height: ${Config.LG_HEADER_HEIGHT}px;
    border-bottom: none;

    &.over,
    &.hide {
      background-color: var(--desert-storm);
      border-bottom: 1px solid var(--border-color);
    }

    &:hover {
      background-color: var(--desert-storm);
      border-bottom: 1px solid var(--border-color);

      & + .navbar-overlay {
          position: fixed;
          width: 100vw;
          height: 100vh;
          opacity: 0.5;
          visibility: visible;
      }
    }
  `}
`;

const HeaderContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`;

const AnnouncementBar = styled.div`
  background-color: var(--pearl-bush);
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(14)};
  color: var(--font-primary-color);
  line-height: 1.2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px 10px;
  text-align: center;
`;

type Props = {
  className?: string;
  announcement?: React.ReactNode | React.ReactNodeArray;
};

const Header: React.FC<Props> = ({ className, announcement }) => {
  const [scroll, setScroll] = useState<'show' | 'hide' | 'over'>('show');
  const [active, setActive] = useState(false);
  const [headerTopPos, setHeaderTopPos] = useState(0);
  const announcementRef = useRef<HTMLDivElement>(null);
  const scrollThreshold = 15;

  useWindowResize({
    handler: () => {
      if (!announcementRef.current) return;
      setHeaderTopPos(announcementRef.current.getBoundingClientRect().height);
    },
  });

  useScrollTracker({
    handler: (scrollTop, scrollPercent, lastScrollTop) => {
      if (scrollTop > lastScrollTop) {
        if (scrollPercent > scrollThreshold) {
          setScroll('hide');
        } else {
          setScroll('over');
        }
      } else {
        if (scrollPercent === 0) {
          setScroll('show');
        } else {
          setScroll('over');
        }
      }
    },
  });

  return (
    <>
      {announcement && (
        <AnnouncementBar className="announcement-bar" ref={announcementRef}>
          {announcement}
        </AnnouncementBar>
      )}
      <HeaderWrapper
        className={`header ${className} ${scroll}`}
        top={headerTopPos}
      >
        <HeaderContainer fluid>
          <Hamburger
            buttonWidth={20}
            isActive={active}
            toggleButton={() => setActive(!active)}
          />
          <LogoLink className="nav-brand" to={SiteUrls.Home} />
          <Navbar className="navbar" isActive={active} />
          <CartButton className="nav-cart" />
          {ENABLE_ACCOUNT && <AuthButton className="nav-auth" />}
        </HeaderContainer>
      </HeaderWrapper>
      <Overlay className="navbar-overlay" isActive={active} />
      <CustomPopup
        id="sleep-lab-move-notice-popup"
        maxRecurringViews={1}
        expireDate={moment('2024-03-01T00:00:00').toDate()}
      >
        <h3>Notice: We’ve moved!</h3>
        <p>
          Woosa has moved to a new showroom within the same building.
          <br />
          Find us at <b>#01-01 T-Space</b> to enjoy a brand new shopping
          experience at <b>The Woosa Dreamery</b>. Open daily 11:00AM - 8:00PM.
        </p>
      </CustomPopup>
    </>
  );
};

export default Header;
