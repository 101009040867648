import React from 'react';

type Props = {
  stroke?: string;
  className?: string;
};

const SvgWave: React.FC<Props> = ({ stroke, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="1600"
      height="400"
    >
      <path
        fill="transparent"
        stroke={stroke || 'white'}
        d="M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121"
        transform="matrix(-1 0 0 1 1600 0)"
      />
    </svg>
  );
};

export default SvgWave;
