import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import {
  ImgMattressBenefit1,
  ImgMattressBenefit2,
  ImgMattressBenefit3,
} from '../assets';

const Section = styled(ProductBenefitSection)``;

const OriginalMattressBenefitSection: React.FC = () => (
  <Section
    version="v2"
    headline="Features"
    subtitle="Bedtimes without springs"
    subtitleTooltip={{
      title: 'Why use foams and not springs?',
      description: (
        <>
          Through our research and extensive testing, we find that most springs
          tend to give way much faster due to the constant gravitational
          pressure from your body, and offer sub-par weight distribution as
          compared to high quality foams.
          <br />
          <br />
          We wanted a core foundation that is supportive, resilient, durable,
          and eliminates motion transfer - which is best achieved through our
          premium quality, high density PU foam base. Furthermore, our foam
          components react to the body’s weight and heat in a way that springs
          can never achieve. Imagine having a million cells distributing the
          weight evenly across your body, offering support at the most critical
          points - it’s a no-brainer!
        </>
      ),
    }}
    benefits={[
      {
        img: <ImgMattressBenefit1 />,
        eyebrow: 'SIGNATURE COMFORT',
        headline: 'Pressure Relieving',
        body:
          'The Original’s unique 4-layer construction work harmoniously together to create a contouring profile for all types of sleeping positions - soft at the areas of the body with high pressure, while firm at the lighter areas of the body for an overall medium-firm comfort.',
      },
      {
        img: <ImgMattressBenefit2 />,
        eyebrow: 'PERFECTLY BALANCED',
        headline: 'Premium Materials',
        body:
          'Thick premium Sonocore® Latex and European-made Memory Foam creates a surface that is both comfortable and supportive. Soft and bouncy natural latex provides resiliency and responsiveness, while the adaptive memory foam provides localised pressure relieving support. Cold cured HR foam helps with even weight distribution at a deeper level.',
      },
      {
        img: <ImgMattressBenefit3 />,
        eyebrow: 'NEVER SLEEP HOT',
        headline: 'Temperature Regulating',
        body:
          'Our Hybrid Sonocore® Latex used in the Original Mattress is one of the most breathable in the market thanks to its consistent, open-cell structure. Combine that with our Italian-sourced Microtencel for a cool, comfortable sleep throughout the night.',
      },
    ]}
  />
);

export default OriginalMattressBenefitSection;
