import React from 'react';
import { getProductPrice } from '../../hooks/cart-context/cart-context-utils';

import { ProductOrderItem } from '../../types/order';
import { PriceLabel } from '../common';

type Props = {
  className?: string;
  product: ProductOrderItem;
  defaultColor?: string;
  type?: 'subtotal' | 'unit';
};

const ProductOrderItemPrice: React.FC<Props> = ({
  className,
  product,
  defaultColor,
  type = 'subtotal',
}) => {
  return (
    <PriceLabel
      className={className}
      price={getProductPrice(product, type)}
      defaultColor={defaultColor || 'var(--sirocco)'}
    />
  );
};

export default ProductOrderItemPrice;
