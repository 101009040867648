import React from 'react';

import ProductSectionV1, { Props as PropsV1Default } from './product-section';
import ProductSectionV2, {
  Props as PropsV2Default,
} from './product-section-v2';

export type PropsV1 = {
  version: 'v1';
} & PropsV1Default;

export type PropsV2 = {
  version: 'v2';
} & PropsV2Default;

export type Props = PropsV1 | PropsV2;

const ProductSection: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <ProductSectionV2 {...props} />;
    default:
      return <ProductSectionV1 {...props} />;
  }
};

export default ProductSection;
