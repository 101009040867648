import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import { ImgMysaBenefit1, ImgMysaBenefit2, ImgMysaBenefit3 } from '../assets';

const Section = styled(ProductBenefitSection)``;

const MysaMattressBenefitSection: React.FC = () => (
  <Section
    version="v2"
    headline="Features"
    subtitle="Bedtimes without springs"
        subtitleTooltip={{
          title: 'Why use foams and not springs?',
          description: (
            <>
              Through our research and extensive testing, we find that most
              springs tend to give way much faster due to the constant
              gravitational pressure from your body, and offer sub-par weight
              distribution as compared to high quality foams.
              <br />
              <br />
              We wanted a core foundation that is supportive, resilient,
              durable, and eliminates motion transfer - which is best achieved
              through our premium quality, high density PU foam base.
              Furthermore, our foam components react to the body’s weight and
              heat in a way that springs can never achieve. Imagine having a
              million cells distributing the weight evenly across your body,
              offering support at the most critical points - it’s a no-brainer!
            </>
          ),
        }}
    benefits={[
      {
        img: <ImgMysaBenefit1 />,
        eyebrow: 'SLEEP INNOVATION',
        headline: 'Advanced Combination',
        body:
          'The addition of our Woosa Cloud Foam creates a whole new level of comfort. The combination of soft velvety plushness from the Cloud Foam, medium-firm bounce from the Sonocore® Latex, and gentle contouring properties from the Memory Foam offers an experience unlike anything in the market.',
      },
      {
        img: <ImgMysaBenefit2 />,
        eyebrow: 'PRESSURE RELIEF',
        headline: 'Pressure Relief',
        body:
          'The Woosa Cloud Foam cushions pressure spots like hips and shoulders, while thick layers of latex and memory foam offer deeper levels of progressive support and eliminates the sensation of over sinking. Tiny microcoils in the sublayer create better lift support in these areas for better spinal alignment.',
      },
      {
        img: <ImgMysaBenefit3 />,
        eyebrow: 'ENHANCED AIRFLOW',
        headline: 'Superior Cooling',
        body:
          'Open cell foams draw heat and channels it through the microcoils to prevent overheating. Extra-long and ultra-fine fibres used in our Microtencel fabric creates better airflow and dissipates moisture quickly - critical in humid Singapore nights.',
      },
    ]}
  />
);

export default MysaMattressBenefitSection;
