import { ProductOrderItem, Tax } from './../../types/order';
import { Promo, ValidatePromoApi } from '../../types/promo';
import { CartEnum } from './cart-context-config';
import { Dispatch } from './cart-context-types';
import { applyPromo, validatePromo } from '../../utils/api';
import { calculateTax, generateProductRefKey } from '../../utils';
import { gtag, toGtagProduct } from '../../utils/analytics';
import { GTagEvent, GTagType } from '../../utils/constants';

export const updateProductQty = (
  action: {
    index?: number;
    qty?: number;
  },
  data: {
    products: ProductOrderItem[];
    discount?: number;
    shippingFee?: number;
    promos: Promo[];
    referralCode?: string;
  },
  persist = false
): {
  products: ProductOrderItem[];
  amounts: {
    totalAmount: number;
    amount: number;
    compareAmount: number;
  };
  tax: Tax;
} | null => {
  if (action.index !== undefined && action.qty !== undefined) {
    data.products[action.index].qty = action.qty;
    const { refKeys, qty } = data.products[action.index];

    // remove product if no more qty
    if (qty < 1) {
      data.products.splice(action.index, 1);

      // capture instrumentation for remove product from cart
      gtag(
        GTagType.Event,
        GTagEvent.RemoveFromCart,
        toGtagProduct(data.products[action.index])
      );
    }

    // Check for refs and update qty accordingly, should be equal to main product
    if (refKeys && refKeys.length) {
      if (qty < 1) {
        data.products = data.products.filter(
          p => !refKeys.includes(generateProductRefKey(p))
        );
      } else {
        data.products.forEach((p, i) => {
          const key = generateProductRefKey(p);
          if (!refKeys.includes(key)) return;
          data.products[i].qty = qty;
        });
      }
    }

    const amounts = getAmounts(data.products, data.discount, data.shippingFee);
    const tax = calculateTax(amounts.totalAmount);

    // If there are no more products, empty out everything
    if (data.products.length === 0) {
      data.discount = 0;
      data.promos = [];
      data.referralCode = '';
      data.shippingFee = 0;
    }

    if (persist) {
      saveToLocalStorage(
        data.products,
        data.promos,
        data.shippingFee,
        data.discount,
        data.referralCode
      );
    }

    return { products: [...data.products], amounts, tax };
  }

  return null;
};

export const getAmounts = (
  products: ProductOrderItem[],
  discount = 0,
  shipping = 0
): {
  totalAmount: number;
  amount: number;
  compareAmount: number;
} => {
  const amount = products
    .map(({ unitPrice, qty }) => unitPrice * qty)
    .reduce((total, subtotal) => {
      return total + subtotal;
    }, 0);

  const compareAmount = products
    .map(
      ({ unitPrice, comparePrice, qty }) => (comparePrice || unitPrice) * qty
    )
    .reduce((total, subtotal) => {
      return total + subtotal;
    }, 0);

  const totalAmount = amount + shipping - discount;

  return { totalAmount, amount, compareAmount };
};

export const getProductPrice = (
  product: ProductOrderItem,
  type: 'subtotal' | 'unit' = 'subtotal'
): {
  amount: number;
  compareAmount: number;
  currency: string;
} => {
  const { unitPrice, comparePrice, currency, totalDiscount = 0, qty } = product;
  const quantity = type === 'subtotal' ? qty : 1;

  const amounts = {
    amount: unitPrice * quantity,
    compareAmount: comparePrice * quantity,
    currency,
  };

  const newAmount = amounts.amount - totalDiscount;

  if (newAmount < amounts.amount && !amounts.compareAmount) {
    amounts.compareAmount = amounts.amount;
  }

  amounts.amount = newAmount;

  return amounts;
};

export const saveToLocalStorage = (
  products: ProductOrderItem[],
  promos: Promo[],
  shippingFee?: number,
  discount?: number,
  referralCode?: string
): void => {
  localStorage.setItem(
    CartEnum.LOCAL_STORAGE_KEY,
    JSON.stringify({
      products,
      promos,
      shippingFee,
      discount,
      referralCode,
    })
  );
};

export const initFromLocalStorage = (dispatch: Dispatch): void => {
  const data = JSON.parse(
    localStorage.getItem(CartEnum.LOCAL_STORAGE_KEY) ||
      '{ "products": [], "promos": [], "shippingFee": 0, "discount": 0, "referralCode": "" }'
  );

  dispatch({
    type: 'INIT',
    products: data.products || [],
    promos: data.promos || [],
    shippingFee: data.shippingFee || 0,
    discount: data.discount || 0,
    referralCode: data.referralCode || '',
  });
};

export const checkForReferralCode = (
  codes: string | string[] | null,
  dispatch: Dispatch
): void => {
  if (!codes) return;

  let referralCode = '';
  if (Array.isArray(codes)) {
    referralCode = codes[0];
  } else {
    referralCode = codes;
  }

  dispatch({ type: 'APPLY_REFERRAL', referralCode });
};

export const hardcodePromoCode = async (
  products: ProductOrderItem[],
  promos: Promo[],
  promoCode: string
): Promise<Promo | null> => {
  const hasMattress = products.some(p => p.collection === 'Mattress');
  const hasBedFrame = products.some(p => p.collection === 'Bed Frame');

  if (!hasMattress || !hasBedFrame) return null;

  if (promos.some(p => p.code === promoCode)) return null;

  const promoData = await applyPromo(
    promoCode,
    products,
    promos.map(p => p.code)
  );

  return promoData;
};

export const managePromo = async (
  products: ProductOrderItem[],
  promos: Promo[],
  dispatch: Dispatch
): Promise<void> => {
  dispatch({ type: 'SET_IS_LOADING', isLoading: true });

  // inject hardcode COMBO promo here
  const comboPromoData = await hardcodePromoCode(products, promos, 'COMBO');
  if (comboPromoData) {
    promos.push(comboPromoData);
  }

  const promoData = await validatePromo(
    products,
    promos.map(p => p.code)
  );

  if (!promoData) {
    dispatch({ type: 'CLEAR_CART' });
  } else {
    const { validPromoCodes } = promoData;
    const { shippingFee, totalDiscount, promos, order, tax } = promoData.data;

    if (!needAdjustmentForPromoBundleFreeGifts(promoData, dispatch)) {
      dispatch({
        type: 'RECONCILE',
        products: order ? order.products : products,
        promos: promos.filter(p => validPromoCodes.includes(p.code)),
        shippingFee,
        discount: totalDiscount,
        tax,
      });
    }
  }

  dispatch({ type: 'SET_IS_LOADING', isLoading: false });
};

export const needAdjustmentForPromoBundleFreeGifts = (
  promoValidateResponse: ValidatePromoApi,
  dispatch: Dispatch
): boolean => {
  const { promos, order } = promoValidateResponse.data;

  const promoWithFreeGiftAdjustment = promos.find(promo => {
    if (promo.type !== 'bundle') return null;

    const { bundle } = promo;
    if (!bundle) return null;

    // Gets the TOTAL free product qty of the bundle
    const totalFreeProductQty = bundle.gets.reduce((totalQty, get) => {
      if (get.type !== 'products' || get.subType !== 'free' || !get.products)
        return totalQty;
      return totalQty + get.qty * get.multiplyQty;
    }, 0);

    // Gets the CLAIMED free product qty of the bundle
    const totalFreeProductQtyClaimed = bundle.gets.reduce((totalQty, get) => {
      if (
        get.type !== 'products' ||
        get.subType !== 'free' ||
        !get.products ||
        !order
      )
        return totalQty;

      const getProducts = get.products.map(p => p.id);
      const freeProductQtyInOrder = order?.products
        .filter(
          p =>
            getProducts.includes(p.id) &&
            p.promoCode?.toLowerCase() === promo.code.toLowerCase()
        )
        .reduce((qty, p) => {
          return qty + p.qty;
        }, 0);

      return totalQty + freeProductQtyInOrder;
    }, 0);

    // If TOTAL === CLAIMED, there's nothing we need to do
    if (totalFreeProductQty === totalFreeProductQtyClaimed) return null;

    // IF CLAIMED > TOTAL, something is wrong, let's remove the promo instead
    if (totalFreeProductQtyClaimed > totalFreeProductQty) return null;

    return promo;
  });

  if (!promoWithFreeGiftAdjustment) return false;

  dispatch({
    type: 'SHOW_PROMO_BUNDLE_MODAL',
    bundle: promoWithFreeGiftAdjustment.bundle,
    promo: promoWithFreeGiftAdjustment,
  });

  return true;
};
