import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import * as Sentry from '@sentry/gatsby';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import moment from 'moment';

import Logo from './logo';
import Cart from './cart';
import StoreInfo from './store-info';
import Spinner from './spinner';
import Error from './error';
import { getOrderByPaymentToken } from '../../utils/api';
import { unslugify } from '../../utils';
import { Order, Address, ProductOrderItem } from '../../types/order';
import { rem } from '../../styles/utils';

const OverrideGlobalStyles = createGlobalStyle`
  body {
    padding-top: 0 !important;
  }
`;

const Section = styled.section`
  width: 100vw;
  min-height: 100vh;
  background-color: var(--tasman);
  padding: 30px;
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-size: ${rem(12)};

  ${media.md`
    padding: 60px;
  `}
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 20px 15px;

  ${media.md`
    padding: 40px;
  `}
`;

const OrderInfoContainer = styled.div``;

const OrderInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
`;

const OrderInfoHeaderItem = styled.div``;

const OrderInfoBody = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const OrderInfoBodyItem = styled.div`
  &:first-child {
    margin-right: 10px;
    padding-right: 10px;
  }
`;

const OrderInfoLabel = styled.strong`
  display: block;
`;

const OrderInfo = styled.span``;

const LogoContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const CartContainer = styled.div`
  .cart-items ul {
    max-height: none;
    overflow-y: visible;

    li {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const StoreInfoContainer = styled.div`
  margin-top: 20px;
`;

const ErrorOrderSection = styled(Error)`
  min-height: 100vh;
`;

type Props = {
  onLoad?: (paymentToken: string, order: Order) => void;
  isProcessing?: boolean;
  children?: React.ReactNode | React.ReactNodeArray;
  onFilterProducts?: (order: Order) => ProductOrderItem[];
  hidePaymentInfo?: boolean;
};

const TokenOrderDetail: React.FC<Props> = ({
  onLoad,
  onFilterProducts,
  isProcessing,
  children,
  hidePaymentInfo,
}) => {
  const location = useLocation();
  const { token } = location.search
    ? queryString.parse(location.search)
    : { token: '' };
  const [order, setOrder] = useState<Order | null>(null);
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        setIsLoadingOrder(true);
        if (!token) throw 'No token found';
        const tokenValue = unslugify(
          typeof token === 'string' ? token : token[0]
        );

        const orderData = await getOrderByPaymentToken(tokenValue);
        if (!orderData) throw 'No order found';
        setOrder(orderData);

        if (onLoad) {
          onLoad(tokenValue, orderData);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoadingOrder(false);
      }
    })();
  }, [token]);

  const renderAddressLine = (value: string | null | undefined) => {
    if (!value) return null;
    return (
      <>
        {value} <br />
      </>
    );
  };

  const renderAddress = (address: Address) => {
    return (
      <>
        {address.firstName} {address.lastName} <br />
        {address.address} <br />
        {address.country} {address.postalCode}
        <br />
        {renderAddressLine(address.company)}
        <br />
        {renderAddressLine(address.email)}
        {renderAddressLine(address.contactNo)}
      </>
    );
  };

  if (!order && isLoadingOrder) return <Spinner isLoading={true} />;

  if (!order)
    return (
      <ErrorOrderSection
        code="400"
        message="There seems to be something wrong here. Please contact us for further assistance."
      />
    );

  return (
    <Section>
      <OverrideGlobalStyles />
      <Container>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {children}
        <OrderInfoContainer>
          <OrderInfoHeader>
            <OrderInfoHeaderItem>
              <OrderInfoLabel>Order Number:</OrderInfoLabel>
              <OrderInfoLabel>{order.refNum}</OrderInfoLabel>
            </OrderInfoHeaderItem>
            <OrderInfoHeaderItem>
              <OrderInfoLabel>Order Date:</OrderInfoLabel>
              <OrderInfoLabel>
                {order.orderedOn?._seconds &&
                  moment.unix(order.orderedOn?._seconds).format('DD-MM-YYYY')}
              </OrderInfoLabel>
            </OrderInfoHeaderItem>
          </OrderInfoHeader>
          <OrderInfoBody>
            <OrderInfoBodyItem>
              <OrderInfoLabel>Shipping Details</OrderInfoLabel>
              <OrderInfo>{renderAddress(order.shipping)}</OrderInfo>
            </OrderInfoBodyItem>
            <OrderInfoBodyItem>
              <OrderInfoLabel>Billing Details</OrderInfoLabel>
              <OrderInfo>{renderAddress(order.billing)}</OrderInfo>
            </OrderInfoBodyItem>
          </OrderInfoBody>
        </OrderInfoContainer>
        <CartContainer>
          <Cart
            className="cart-items"
            products={
              onFilterProducts ? onFilterProducts(order) : order.products
            }
            shippingFee={order.shippingFee}
            discount={order.discount}
            promos={order.promos}
            disableQty={true}
            disablePromo={true}
            disablePaymentInfo={hidePaymentInfo}
            amounts={{
              totalAmount: order.finalPrice || 0,
              amount: order.totalPrice || 0,
              compareAmount: order.products.reduce(
                (prev, p) => prev + (p.comparePrice || p.unitPrice) * p.qty,
                0
              ),
              paidAmount: order.payments
                ?.filter(p => !p.status || p.status === 'success')
                .reduce((paid, p) => paid + (p.amount || 0), 0),
            }}
            referralCode={order.referral?.code}
            tax={order.tax}
          />
        </CartContainer>
        <StoreInfoContainer>
          Woosa Sleep Pte Ltd
          <StoreInfo hideBookSlot />
        </StoreInfoContainer>
      </Container>
      <Spinner isLoading={isProcessing} />
    </Section>
  );
};

export default TokenOrderDetail;
