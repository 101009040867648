import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Wrapper, fontStyle } from './simple-split-section';

const Section = styled.section<{ borderAt: 'top' | 'bottom' }>`
  border-top: ${({ borderAt }) =>
    borderAt === 'top' ? '1px solid var(--border-color)' : 0};
  border-bottom: ${({ borderAt }) =>
    borderAt === 'bottom' ? '1px solid var(--border-color)' : 0};

  width: 100%;
  font-family: var(--font-body);
  text-align: center;
`;

const Container = styled(Wrapper)`
  padding-top: 0;
  padding-bottom: 0;
`;

const Content = styled.div<{ maxWidth?: string }>`
  width: 100%;
  padding: 40px 0;

  ${({ maxWidth }) => {
    return media.md`
        max-width: ${maxWidth || '60%'};
        margin: 0 auto;
      `;
  }};
`;

const Headline = styled.h2`
  color: var(--leather);
  font-weight: normal;
`;

const Body = styled.div`
  ${fontStyle}
  color: var(--font-secondary-color);
`;

type Props = {
  className?: string;
  headline: string;
  children: React.ReactNode | React.ReactNodeArray;
  borderAt?: 'top' | 'bottom';
  maxWidth?: string;
};

const WarrantyIntro: React.FC<Props> = ({
  className,
  headline,
  children,
  borderAt = 'bottom',
  maxWidth,
}) => {
  return (
    <Section className={className} borderAt={borderAt}>
      <Container>
        <Content maxWidth={maxWidth}>
          <Headline>{headline}</Headline>
          <Body>{children}</Body>
        </Content>
      </Container>
    </Section>
  );
};

export default WarrantyIntro;
