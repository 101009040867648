import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Container, Link, Unorphan, WaveBlobAnimationSection } from '../common';
import { ImgHowAreWeDifferent } from '../assets';
import { flatButtonStyle, lh, rem } from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';

const Section = styled(WaveBlobAnimationSection)`
  margin: 0 !important;
`;

const Wrapper = styled(Container)`
  position: relative;
  z-index: 0;

  ${media.md`
    position: relative;
  `}

  ${media.xxl`
    display: flex;
  `}
`;

const CopyContainer = styled.div`
  padding: 30px;
  text-align: center;

  ${media.md`
    text-align: left;
    width: 50%;
  `}

  ${media.xxl`
    text-align: left;
    width: 40%;
  `}
`;

const Headline = styled.h2`
  margin-top: 0;
  margin-bottom: 30px;
  font-family: var(--font-header);
  color: white;
  font-weight: normal;
  font-size: ${rem(24)};

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(50)};
  `}
`;

const Subheadline = styled.h3`
  margin-top: 40px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: white;
  font-family: var(--font-body);
  font-weight: bold;
  font-size: ${rem(12)};
  letter-spacing: 1px;

  &:first-child {
    margin-top: 0;
  }

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const Body = styled.p`
  margin: 0;
  color: white;
  font-family: var(--font-body);
  font-weight: normal;
  font-size: ${rem(14)};
  line-height: 1.4;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const ImgXs = styled(ImgHowAreWeDifferent)`
  width: 100%;
  box-shadow: -15px 15px 0px 0px rgba(116, 129, 117, 1);
  margin-bottom: 40px;

  ${media.md`
    display: none;
  `}
`;

const ImgMd = styled(ImgHowAreWeDifferent)`
  width: 100%;
  box-shadow: -30px 30px 0px 0px rgba(116, 129, 117, 1);
`;

const ImgContainer = styled.div`
  display: none;
  width: 100%;
  position: absolute;
  top: 0;
  right: -10%;

  ${media.md`
    display: block;
    max-width: 400px;
  `}

  ${media.lg`
    max-width: 500px;
  `}

  ${media.xl`
    max-width: 600px;
    right: -15%;
  `}

  ${media.xxl`
    position: initial;
    max-width: 600px;
    right: initial;
    top: initial;
    margin-left: auto;
    margin-top: -40px;
  `}
`;

const Button = styled(Link)`
  ${flatButtonStyle()}
  margin-top: 30px;
  padding: 14px 30px;
`;

const HowAreWeDifferentSection: React.FC = () => {
  return (
    <Section>
      <Wrapper>
        <CopyContainer>
          <Headline>The Woosa Difference</Headline>
          <ImgXs />
          <Subheadline>
            <Unorphan>DESIGNED IN SINGAPORE,</Unorphan>
            <br />
            <Unorphan>MADE ENTIRELY IN BELGIUM</Unorphan>
          </Subheadline>
          <Body>
            Woosa sets itself apart from other mattress stores in Singapore by
            carefully balancing local preferences in support, comfort, and
            quality. We source only the finest materials from Europe, and
            assemble our mattresses entirely in Belgium to ensure the creation
            of the best mattress possible for Singaporeans.
          </Body>
          <Subheadline>
            <Unorphan>WELCOME TO A SPRING-LESS FUTURE</Unorphan>
          </Subheadline>
          <Body>
            Say goodbye to outdated spring beds from the past. Woosa’s
            mattresses are made entirely with the highest grades of foams in the
            industry. From our signature Sonocore® Latex to The Woosa Cloud
            Foam, our beds provide superior pressure relief and incredible
            comfort that’s unlike anything you’ve experienced.
          </Body>
          <Button to={SiteUrls.About}>Read more</Button>
        </CopyContainer>
        <ImgContainer>
          <ImgMd />
        </ImgContainer>
      </Wrapper>
    </Section>
  );
};

export default HowAreWeDifferentSection;
