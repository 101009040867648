import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { HeroIntro } from '../common';
import Video from '../../static/videos/adjustable-base-2-intro.mp4';

const Intro = styled(HeroIntro)`
  .hero-intro {
    height: 200px;

    ${media.md`
      height: 300px;
    `}

    ${media.lg`
      height: 450px;
    `}

    ${media.xl`
      height: 700px;
    `}
  }

  .hero-intro-content {
    padding: 30px 0;

    ${media.md`
      padding: 50px 0;
    `}

    ${media.lg`
      padding: 100px 0;
    `}
  }
`;

const AdjustableBaseIntroSection: React.FC = () => {
  return (
    <Intro
      version="v2"
      backgroundVideoSrc={Video}
      eyebrow="Design Process"
      headline="Elevate your sleep game"
      body={
        <>
          <p>
            Introducing the pinnacle of bedtime comfort with the New Adjustable
            Base II.
          </p>
          <p>
            Designed to work seamlessly with all Woosa Mattresses, the
            Adjustable Base II offers nearly unlimited ergonomic head and foot
            adjustments for the ultimate sleep and lounge experience. You can
            also opt for our split mattress design which lets each sleeper
            individually control their side of the bed, optimising comfort for
            both users.
          </p>
          <p>
            The New Adjustable Base II has been re-engineered from the ground up
            to include a new host of features, including our revolutionary
            DUALPOINT Back-Tilt movement for improved back elevation, Zero
            Clearance design to allow for operation with or without legs, and
            state-of-the-art German Hydraulics for quieter performance.
          </p>
        </>
      }
    />
  );
};

export default AdjustableBaseIntroSection;
