import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/gatsby';
import {
  ENABLE_GRABPAY,
  ENABLE_ATOME,
  USE_STRIPE_WEBHOOK,
} from 'gatsby-env-variables';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import WizardContainer from './wizard-container';
import WizardHeadline from './wizard-headline';
import WizardButton from './wizard-button';
import { FormRadio, StripeForm, GrabPayForm, AtomeForm } from '../common';
import {
  createOrder,
  updateOrder,
  patchOrder,
  sendOrderReceipt,
  getOrderByPaymentToken,
} from '../../utils/api';
import { loadStripePromise, toSiteUrl, unslugify } from '../../utils';
import {
  useCheckoutState,
  useCheckoutDispatch,
} from '../../hooks/checkout-context';
import { useAuthState, useAuthDispatch } from '../../hooks/auth-context';
import { useCartState } from '../../hooks/cart-context';
import { useCartDispatch } from '../../hooks/cart-context';
import { OrderApi, Order, PaymentType, Payment } from '../../types/order';
import { useCountryState } from '../../hooks/country-context';
import { SiteUrls } from '../../utils/constants';

const stripePromise = loadStripePromise();

const PaymentMethodList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const PaymentMethodItem = styled.li`
  margin-bottom: 30px;
  padding-left: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const RadioBtnGroup = styled(FormRadio)`
  margin-bottom: 15px;
  margin-left: -20px;
`;

const SubmitBtn = styled(WizardButton)`
  margin-top: 40px;
`;

const ErrorMessage = styled.span`
  display: block;
  margin-top: 10px;
  color: red;
  font-size: 80%;
  padding-left: 20px;
`;

// const HoolahLabel = styled.label`
//   font-family: var(--font-body);
//   font-weight: normal;
//   font-size: ${rem(12)};
//   color: var(--font-secondary-color);
//   display: block;
//   margin-top: -8px;
// `;

const GrabPayMethodItem = styled(PaymentMethodItem)`
  margin-bottom: 0;
`;

const AtomeMethodItem = styled(PaymentMethodItem)`
  ${RadioBtnGroup} {
    label[for='atome'] {
      &:before {
        top: 15px;
      }

      &:after {
        top: 19px;
      }
    }
  }
`;

type Props = {
  className?: string;
  index?: number;
};

const PaymentForm: React.FC<Props> = ({ className, index }) => {
  const { countryCode } = useCountryState();
  const { accessToken } = useAuthState();
  const {
    isLoading,
    shipping,
    billing,
    schedule,
    deliveryOption,
    order,
  } = useCheckoutState();
  const location = useLocation();
  const authDispatch = useAuthDispatch();
  const checkoutDispatch = useCheckoutDispatch();
  const cartDispatch = useCartDispatch();
  const { products, promos, referralCode } = useCartState();
  const [orderId, setOrderId] = useState('');
  const [paymentToken, setPaymentToken] = useState('');
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null);
  const [paymentOption, setPaymentOption] = useState<PaymentType>('stripe');
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [paymentAttempts, setPaymentAttempts] = useState(0);
  const orderPaymentDataRef = useRef<Payment[]>([]);
  const { token } = location.search
    ? queryString.parse(location.search)
    : { token: '' };

  /**
   * if there's a token returned here,
   * let's load up the order so that we don't create too many same orders
   */
  useEffect(() => {
    (async function () {
      if (!token) return;
      const orderToken = unslugify(
        typeof token === 'string' ? token : token[0]
      );
      setIsLoading(true);
      const orderTokenData = await getOrderByPaymentToken(orderToken);
      if (orderTokenData) {
        // 1. If not signed in, user is guest so dispatch guest sign in
        if (!accessToken) {
          authDispatch({
            type: 'GUEST_SIGN_IN',
            email: orderTokenData.billing.email,
            populateGuestFormEmail: true,
          });
        }

        // 2. Dispatch cart context to update cart
        cartDispatch({
          type: 'INIT',
          products: orderTokenData.products,
          promos: orderTokenData.promos,
          shippingFee: orderTokenData.shippingFee,
          discount: orderTokenData.discount,
          referralCode: orderTokenData.referral?.code,
          reconcile: false,
        });

        // 3. Dispatch checkout context to update addresses
        checkoutDispatch({
          type: 'UPDATE_ADDRESS',
          shipping: orderTokenData.shipping,
          billing: orderTokenData.billing,
          populateAddressForm: true,
        });

        // 4. Dispatch checkout context to update delivery schedules if found
        if (orderTokenData.schedules && orderTokenData.schedules.length) {
          checkoutDispatch({
            type: 'UPDATE_DELIVERY',
            schedule: orderTokenData.schedules[0],
          });
        }

        // 5. Dispatch checkout context to update delivery option
        checkoutDispatch({
          type: 'UPDATE_DELIVERY_OPTION',
          deliveryOption: orderTokenData.deliveryOption,
        });

        // 6. Set orderId state to indicate for update during submitting
        if (orderTokenData.id) {
          setOrderId(orderTokenData.id);
        }

        // 7. Change to payment screen
        checkoutDispatch({ type: 'SET_ACTIVE_INDEX', activeIndex: 3 });

        // 8. Set payments ref to use for later during updating
        orderPaymentDataRef.current = orderTokenData.payments || [];
      }
      setIsLoading(false);
    })();
  }, [token]);

  const setIsLoading = (isLoading: boolean) =>
    checkoutDispatch({ type: 'SET_IS_LOADING', isLoading });

  const handleCreateOrder = async () => {
    if (!shipping || !billing || disabled) return;
    if (deliveryOption !== 'unsure' && !schedule) return;

    const orderData: OrderApi = {
      order: {
        ...order,
        shippingFee: 0,
        referral: {
          code: referralCode || '',
        },
        schedules: schedule ? [schedule] : [],
        deliveryOption,
        shipping,
        billing,
        status: 'pending payment',
        products,
        currency: 'SGD',
        payments: orderPaymentDataRef.current,
      },
      promoCodes: promos.map(p => p.code),
    };

    let orderResultData: Order | null = null;

    setIsLoading(true);
    if (!orderId) {
      orderResultData = await createOrder(accessToken, orderData);
    } else {
      orderResultData = await updateOrder(accessToken, orderId, orderData);
    }

    if (orderResultData && orderResultData.id && orderResultData.paymentToken) {
      checkoutDispatch({ type: 'SET_ORDER', order: orderResultData });
      setOrderId(orderResultData.id);
      setPaymentToken(orderResultData.paymentToken);
      setPaymentType(paymentOption);
      setPaymentAttempts(paymentAttempts + 1);
    } else {
      const errorMessage =
        'Unable to create order. Please contact us for further assistance.';
      Sentry.captureException(new Error(errorMessage), {
        extra: { order, orderId, orderResultData },
      });
      setPaymentType(null);
      setPaymentToken('');
      setIsLoading(false);
      setError(errorMessage);
    }
  };

  const handleAfterPayment = async (result: boolean, data: unknown | null) => {
    if (USE_STRIPE_WEBHOOK) {
      window.requestAnimationFrame(() => {
        window.location.href = `${toSiteUrl(
          SiteUrls.OrderSummary,
          countryCode
        )}?token=${paymentToken}`;
      });
      return;
    }

    setPaymentType(null);

    const patchOrderData = await patchOrder(
      accessToken,
      orderId,
      data,
      result ? 'processing' : 'payment error',
      {
        type: 'stripe',
        subtype: 'checkout',
        remarks: 'Payment via Card',
      }
    );

    if (patchOrderData) {
      checkoutDispatch({ type: 'SET_ORDER', order: patchOrderData });
    }

    if (!result) {
      const errorMessage =
        'Payment failed. Please try again or contact us for further assistance.';
      Sentry.captureException(new Error(errorMessage), {
        extra: { order, orderId, result, patchOrderData },
      });
      setError(errorMessage);
      setPaymentToken('');
      setIsLoading(false);
    } else {
      await sendOrderReceipt(accessToken, orderId);
      setIsLoading(false);
      cartDispatch({ type: 'CLEAR_CART' });
      checkoutDispatch({ type: 'CLEAR_ADDRESS' });
      window.requestAnimationFrame(
        () =>
          (window.location.href = `${toSiteUrl(
            SiteUrls.OrderSummary,
            countryCode
          )}?token=${paymentToken}`)
      );
    }
  };

  const handleError = (err: string) => {
    Sentry.captureException(err, {
      extra: {
        order,
      },
    });

    // if (USE_STRIPE_WEBHOOK && paymentToken) {
    //   window.requestAnimationFrame(
    //     () => (window.location.href = `/summary?token=${paymentToken}`)
    //   );
    //   return;
    // }

    if (paymentOption === 'stripe') {
      setDisabled(true);
    }
    setError(err);
    setIsLoading(false);
  };

  const handlePaymentOptionValueChange = (value: PaymentType) => {
    setPaymentOption(value);
  };

  return (
    <WizardContainer className={className}>
      <WizardHeadline data-num={(index || 0) + 1}>Payment</WizardHeadline>
      <PaymentMethodList>
        <PaymentMethodItem>
          <RadioBtnGroup
            label="Credit Card"
            inputProps={{
              id: 'credit-card',
              value: 'stripe',
              name: 'payment-method',
              checked: paymentOption === 'stripe',
              onChange: () => handlePaymentOptionValueChange('stripe'),
            }}
          />
          <Elements stripe={stripePromise}>
            <StripeForm
              paymentToken={paymentToken}
              disableButton={true}
              canPay={paymentType === 'stripe'}
              paymentAttempts={paymentAttempts}
              onInputChange={() => {
                setDisabled(false);
                setError('');
              }}
              onError={handleError}
              accessToken={accessToken}
              afterPayment={handleAfterPayment}
              orderId={orderId}
              paymentSubType="checkout"
            />
          </Elements>
        </PaymentMethodItem>
        {/* GrabPay is currently done via Stripe */}
        {ENABLE_GRABPAY && (
          <GrabPayMethodItem>
            <RadioBtnGroup
              label={
                <Elements stripe={stripePromise}>
                  <GrabPayForm
                    paymentToken={paymentToken}
                    onError={handleError}
                    accessToken={accessToken}
                    afterPayment={handleAfterPayment}
                    paymentAttempts={paymentAttempts}
                    orderId={orderId}
                    canPay={paymentType === 'grabpay'}
                    paymentSubType="checkout"
                  />
                </Elements>
              }
              inputProps={{
                id: 'grabpay',
                name: 'payment-method',
                value: 'grabpay',
                checked: paymentOption === 'grabpay',
                onChange: () => handlePaymentOptionValueChange('grabpay'),
              }}
            />
          </GrabPayMethodItem>
        )}
        {ENABLE_ATOME && (
          <AtomeMethodItem>
            <RadioBtnGroup
              label={
                <AtomeForm
                  accessToken={accessToken}
                  paymentToken={paymentToken}
                  canPay={paymentType === 'atome'}
                  paymentAttempts={paymentAttempts}
                  onError={handleError}
                />
              }
              inputProps={{
                id: 'atome',
                name: 'payment-method',
                value: 'atome',
                checked: paymentOption === 'atome',
                onChange: () => handlePaymentOptionValueChange('atome'),
              }}
            />
          </AtomeMethodItem>
        )}
        {/* <PaymentMethodItem>
          <RadioBtnGroup
            label="Hoolah Payment"
            inputProps={{ id: 'hoolah', name: 'payment-method' }}
          />
          <HoolahLabel>3 installments, 0% interest</HoolahLabel>
        </PaymentMethodItem> */}
      </PaymentMethodList>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <SubmitBtn disabled={isLoading || disabled} onClick={handleCreateOrder}>
        Place Order
      </SubmitBtn>
    </WizardContainer>
  );
};

export default PaymentForm;
