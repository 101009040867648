import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';

const FormLabel = styled.label`
  font-size: ${rem(12)};
  color: var(--font-secondary-color);
  margin-bottom: 5px;
  display: block;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

export default FormLabel;
