import React from 'react';
import styled from 'styled-components';

import DefaultOverlay from './overlay';
import CloseButton from './close-button';

const Overlay = styled(DefaultOverlay)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnderneathLayer = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const Container = styled.div`
  width: 80%;
  max-width: 500px;
  max-height: 90vh;
  background-color: white;
  padding: 15px 0;
  position: fixed;
`;

const Header = styled.div`
  padding: 0 15px;
  min-height: 10px;
  background-color: white;
`;

const Body = styled.div`
  max-height: 80vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 15px;
`;

const Button = styled(CloseButton)`
  position: absolute;
  top: 10px;
  right: 5px;

  svg {
    width: 10px;
  }
`;

type Props = {
  className?: string;
  isActive: boolean;
  children: React.ReactNode;
  onClickClose?: () => void;
  backdrop?: 'static' | 'allowClose';
  hideCloseBtn?: boolean;
};

const Modal: React.FC<Props> = ({
  className,
  isActive,
  children,
  onClickClose,
  backdrop = 'static',
  hideCloseBtn,
}) => {
  return (
    <Overlay className={className} isActive={isActive}>
      <UnderneathLayer
        onClick={() => {
          if (backdrop === 'static') return;
          if (!onClickClose) return;
          onClickClose();
        }}
      />
      <Container className="modal-container">
        {onClickClose && !hideCloseBtn && (
          <Header>
            <Button onClick={onClickClose} />
          </Header>
        )}
        <Body>{children}</Body>
      </Container>
    </Overlay>
  );
};

export default Modal;
