import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { media } from 'styled-bootstrap-grid';

import Container from './container';
import useAddToCart from '../../hooks/use-add-to-cart';
import useGraphQLGetProduct from '../../hooks/use-graphql-get-product';
import ProductVariantDropdown from './product-variant-dropdown';
import Spinner from './spinner';
import { flatButtonStyle, lh, rem } from '../../styles/utils';
import ProductStatusLabel from './product-status-label';
import useScrollTracker from '../../hooks/use-scroll-tracker';
import ProductPlusCoverCheckbox from './product-plus-cover/product-plus-cover-checkbox';
import { ProductOrderItem } from '../../types/order';

const Section = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  filter: drop-shadow(0px -10px 5px rgba(0, 0, 0, 0.1));
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out,
    border-bottom 0.5s ease-in-out;

  &.show {
    transform: translateY(0);
  }

  &.minimise {
    transform: translateY(65%);
  }
`;

const Wrapper = styled.div`
  padding: 16px 0;

  ${media.lg`
    display: flex;
    align-items: center;
  `}
`;

const ProductInfo = styled.div`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${lh(19.2, 16)};
  color: var(--font-primary-color);
  margin-bottom: 12px;
  display: flex;

  ${media.lg`
    font-size: ${rem(24)};
    line-height: ${lh(39, 24)};
    margin-bottom: 0;
    margin-left: 12px;
  `}
`;

const ProductPurchase = styled.div`
  margin-left: auto;
`;

const AddToCartWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin-right: 12px;

  ${media.lg`
      min-width: 300px;
  `}
`;

const ProductVariantSelect = styled(ProductVariantDropdown)`
  .product-variant-dropdown-list {
    top: auto;
    bottom: 100%;
  }
`;

const MinimiseButton = styled.button`
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  background: none;
  margin-left: auto;
  transition: transform 0.5s ease-in-out;

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid var(--font-primary-color);
    border-right: 2px solid var(--font-primary-color);
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
  }

  &.minimise {
    transform: rotate(-180deg);
  }

  ${media.lg`
    display: none;
  `};
`;

const Button = styled.button`
  ${flatButtonStyle()}
  min-width: 95px;

  ${media.lg`
    font-size: ${rem(14)};
    min-width: 110px;
  `}
`;

type Props = {
  productId: string;
  className?: string;
  scrollThreshold?: number;
  plusCoverProductId: string;
  setIsPlusCover?: (isChecked: boolean) => void;
  isPlusCover?: boolean;
};

type Position = 'hide' | 'show' | 'minimise';

const ProductFixedBottomBar: React.FC<Props> = ({
  className,
  productId,
  scrollThreshold = 750,
  plusCoverProductId,
  setIsPlusCover,
  isPlusCover,
}) => {
  const { collection, name, media, variants, rating } = useGraphQLGetProduct(
    productId
  );
  const plusCover = useGraphQLGetProduct(plusCoverProductId);

  const [position, setPosition] = useState<Position>('hide');
  const positionRef = useRef<Position>('hide');
  const {
    product,
    isLoading,
    selectedVariant,
    handleAddToCart,
    handleProductVariantDropdownChange,
    handleProductPlusCoverChange,
  } = useAddToCart({
    id: productId,
    collection,
    name,
    media,
    variants,
    rating,
  });

  const setPositionValue = (pos: Position) => {
    setPosition(pos);
    positionRef.current = pos;
  };

  useScrollTracker({
    handler: scrollTop => {
      if (scrollTop > scrollThreshold) {
        if (positionRef.current === 'minimise') {
          setPositionValue('minimise');
        } else {
          setPositionValue('show');
        }
      } else {
        setPositionValue('hide');
      }
    },
  });

  const handleProductPlusCover = (
    p: ProductOrderItem | null,
    isChecked: boolean
  ) => {
    if (typeof setIsPlusCover !== 'function') return;

    setIsPlusCover(isChecked);
    handleProductPlusCoverChange(p);
  };

  return (
    <Section
      className={classNames('product-fixed-bottom-bar', className, position)}
    >
      <Container>
        <Wrapper>
          <ProductInfo>
            {product.name}{' '}
            <MinimiseButton
              className={position}
              onClick={() => {
                if (positionRef.current === 'hide') return;
                if (positionRef.current === 'minimise') {
                  setPositionValue('show');
                } else {
                  setPositionValue('minimise');
                }
              }}
            />{' '}
          </ProductInfo>
          <ProductPurchase>
            <AddToCartWrapper>
              {product && (
                <SelectWrapper>
                  <ProductVariantSelect
                    hideStatus
                    variants={product.variants}
                    onChange={handleProductVariantDropdownChange}
                    version="v2"
                    plusCoverProduct={
                      isPlusCover && plusCover ? plusCover : undefined
                    }
                    section="footer"
                  />
                  <Spinner isLoading={isLoading} />
                </SelectWrapper>
              )}
              <Button onClick={handleAddToCart}>Add to Cart</Button>
            </AddToCartWrapper>
            {selectedVariant && (
              <ProductStatusLabel selectedVariant={selectedVariant} />
            )}

            {collection === 'Mattress' && plusCover && (
              <ProductPlusCoverCheckbox
                checkboxLabel="Upgrade to PLUS+ Cover"
                selectedVariant={selectedVariant}
                product={plusCover}
                onChange={handleProductPlusCover}
                isPlusCoverChecked={isPlusCover}
                section="footer"
              />
            )}
          </ProductPurchase>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default ProductFixedBottomBar;
