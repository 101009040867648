import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { rem } from '../../styles/utils';

const WizardHeadline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  color: var(--font-primary-color);
  font-size: ${rem(24)};
  display: flex;
  align-items: center;

  &:before {
    font-family: var(--font-body);
    color: white;
    opacity: 0.5;
    font-size: 50%;
    content: attr(data-num);
    width: 20px;
    height: 20px;
    display: flex;
    background-color: var(--siam);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    ${media.lg`
      width: 30px;
      height: 30px;
      font-size: 60%;
    `}
  }

  ${media.lg`
    font-size: ${rem(36)};
  `}
`;

export default WizardHeadline;
