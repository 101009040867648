import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import _ from 'lodash';

import { useCartDispatch } from '../../hooks/cart-context';
import { ProductOrderItem, Tax } from '../../types/order';
import QuantitySelector from './quantity-selector';
import { Promo } from '../../types/promo';
import {
  OrderCartSummary,
  ProductOrderItemInfo,
  ProductOrderItemPrice,
} from '../order';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
`;

const ProductList = styled(List)``;

const Item = styled.li<{ display?: string; hideBorder?: boolean }>`
  display: ${({ display }) => display || 'flex'};
  width: 100%;
  align-items: flex-start;
  padding: 20px 15px;
  border-bottom: ${({ hideBorder }) =>
    hideBorder ? '0' : '1px solid var(--border-color)'};

  ${media.md`
    padding: 20px;
  `}
`;

const Media = styled.img`
  width: 100%;
  max-width: 80px;

  ${media.md`
    max-width: 100px;
  `}
`;

const Info = styled(ProductOrderItemInfo)`
  width: 30%;
  margin-left: 15px;
  margin-right: auto;
`;

type Props = {
  products: ProductOrderItem[];
  amounts: {
    totalAmount: number;
    amount: number;
    compareAmount: number;
    paidAmount?: number;
  };
  shippingFee?: number;
  discount?: number;
  disableQty?: boolean;
  disablePromo?: boolean;
  disablePaymentInfo?: boolean;
  className?: string;
  promos?: Promo[];
  referralCode?: string;
  tax?: Tax;
};

const Cart: React.FC<Props> = ({
  products,
  amounts,
  discount,
  shippingFee,
  promos,
  disableQty,
  disablePromo,
  disablePaymentInfo,
  className,
  referralCode,
  tax,
}) => {
  const dispatch = useCartDispatch();

  return (
    <Container className={className}>
      <ProductList>
        {products.map((p, key) => (
          <Item key={`${p.name}-${p.variant}-${p.unitPrice}-${key}`}>
            <Media src={p.image} alt="" role="presentation" />
            <Info product={p} />
            <QuantitySelector
              disabled={disableQty || (p.promoCode ? true : false)}
              defaultValue={p.qty}
              onChange={value => {
                dispatch({ type: 'UPDATE_QTY', index: key, qty: value });
              }}
            />
            <ProductOrderItemPrice product={p} />
          </Item>
        ))}
      </ProductList>
      {!disablePaymentInfo && (
        <OrderCartSummary
          products={products}
          amounts={amounts}
          shippingFee={shippingFee}
          discount={discount}
          disablePromo={disablePromo}
          promos={promos}
          referralCode={referralCode}
          tax={tax}
          onClickRemovePromoCode={promo =>
            dispatch({
              type: 'REMOVE_PROMO',
              promo: promo,
            })
          }
          onClickRemoveReferralCode={() =>
            dispatch({ type: 'REMOVE_REFERRAL' })
          }
        />
      )}
    </Container>
  );
};

export default Cart;
