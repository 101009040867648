import React, { useState } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/gatsby';
import { media } from 'styled-bootstrap-grid';
import { Elements } from '@stripe/react-stripe-js';
import {
  ENABLE_GRABPAY,
  ENABLE_ATOME,
  USE_STRIPE_WEBHOOK,
} from 'gatsby-env-variables';

import {
  StripeForm,
  GrabPayForm,
  AtomeForm,
  TokenOrderDetail,
} from '../common';
import {
  patchOrderByPaymentToken,
  sendOrderReceiptByPaymentToken,
} from '../../utils/api';
import { loadStripePromise } from '../../utils';
import { rem } from '../../styles/utils';
import { ImgVisa, ImgMastercard, ImgAmex, ImgAtome } from '../assets';

const stripePromise = loadStripePromise();

const IntroContainer = styled.div`
  text-align: center;

  ${media.md`
    margin-bottom: 20px;
  `}
`;

const Headline = styled.h1`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-size: ${rem(20)};
`;

const Paragraph = styled.p``;

const PaymentContainer = styled.div`
  max-width: 100%;
  margin: 20px auto 20px;
  padding: 0 15px;
  text-align: center;

  ${media.md`
    max-width: 50%;
  `}

  .stripe-card-element {
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .stripe-card-element-btn {
    padding: 10px;
    width: 100%;
  }
`;

const ErrorMessage = styled.span`
  display: block;
  margin-top: 10px;
  color: red;
  font-size: 90%;
  padding-left: 20px;
`;

const ListTile = styled.div`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-size: ${rem(16)};
  font-weight: normal;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;

  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;

const LogoContainer = styled.div`
  display: flex;
  margin-top: 10px;

  ${media.lg`
    margin-top: 0;
  `}
`;

const VisaLogo = styled(ImgVisa)`
  width: 40px;
`;

const MastercardLogo = styled(ImgMastercard)`
  width: 40px;
  margin: 0 5px;
`;

const AmexLogo = styled(ImgAmex)`
  width: 40px;
`;

const ButtonItem = styled.div`
  width: 100%;
  margin: 0 5px;

  ${media.lg`
    width: 49%;
  `}
`;

const Separator = styled.div`
  margin: 20px 0;
  font-family: var(--font-header);
  font-size: ${rem(18)};
`;

const ButtonContainer = styled.div`
  ${media.lg`
    display: flex;
    justify-content: center;
  `}
`;

const PaymentSection: React.FC = () => {
  const [orderId, setOrderId] = useState('');
  const [paymentToken, setPaymentToken] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);

  const handleAfterPayment = async (result: boolean, data: unknown | null) => {
    setError('');
    setIsProcessing(true);

    if (!USE_STRIPE_WEBHOOK) {
      await patchOrderByPaymentToken(
        paymentToken,
        data,
        result ? 'processing' : 'payment error',
        'stripe',
        'payment link',
        'Payment via Card'
      );

      await sendOrderReceiptByPaymentToken(paymentToken);
    }

    setIsProcessing(false);
    setHasPaid(true);
  };

  const handleError = async (error: string) => {
    Sentry.captureException(error, {
      extra: {
        orderId,
      },
    });

    // if (USE_STRIPE_WEBHOOK) {
    //   setHasPaid(true);
    //   return;
    // }

    setError(error);
    setHasPaid(false);
  };

  return (
    <TokenOrderDetail
      onLoad={(token, order) => {
        setPaymentToken(token);
        setOrderId(order.id || '');

        if (USE_STRIPE_WEBHOOK) {
          setHasPaid(order.status === 'processing');
          return;
        }

        // Should have no issues, we expect order to be `pending payment`
        // status in order for payment link to work, and after payment is done
        // status will definitely be updated to `processing`, hence for payments
        // that redirects to payment gateway to complete, we can redirect them
        // back to this page and should then capture it as `successfully paid`
        setHasPaid(order.status !== 'pending payment');
      }}
      isProcessing={isProcessing}
    >
      <IntroContainer>
        {!hasPaid && (
          <>
            <Headline>Thank you for your order.</Headline>
            <Paragraph>
              We are pleased to have received your order. Please make your
              payment in order to proceed.
            </Paragraph>
          </>
        )}
        {hasPaid && !USE_STRIPE_WEBHOOK && (
          <>
            <Headline>Successfully paid!</Headline>
            <Paragraph>
              You should receive an email confirmation shortly.
            </Paragraph>
          </>
        )}
        {hasPaid && USE_STRIPE_WEBHOOK && (
          <>
            <Headline>We have received your payment.</Headline>
            <Paragraph>
              Payment is currently being processed, you will receive the order
              invoice once payment has been successfully processed.
            </Paragraph>
          </>
        )}
      </IntroContainer>
      {!hasPaid && (
        <PaymentContainer>
          <ListTile>
            <span>Visa / Mastercard / Amex</span>
            <LogoContainer>
              <VisaLogo />
              <MastercardLogo />
              <AmexLogo />
            </LogoContainer>
          </ListTile>
          <Elements stripe={stripePromise}>
            <StripeForm
              paymentToken={paymentToken}
              disableButton={false}
              canPay={false}
              onError={handleError}
              onInputChange={() => setError('')}
              afterPayment={handleAfterPayment}
              orderId={orderId}
              paymentSubType="payment link"
            />
          </Elements>

          {(ENABLE_GRABPAY || ENABLE_ATOME) && (
            <>
              <Separator>Or</Separator>
              <ButtonContainer>
                {ENABLE_GRABPAY && (
                  <ButtonItem>
                    <Elements stripe={stripePromise}>
                      <GrabPayForm
                        paymentToken={paymentToken}
                        onError={err => setError(err)}
                        onProcessingChanged={p => setIsProcessing(p)}
                        afterPayment={handleAfterPayment}
                        orderId={orderId}
                        canPay={false}
                        isButton
                        returnUrlParams={{
                          nextPath: 'payment',
                        }}
                        paymentSubType="payment link"
                      />
                    </Elements>
                  </ButtonItem>
                )}
                {ENABLE_ATOME && (
                  <ButtonItem>
                    <AtomeForm
                      paymentToken={paymentToken}
                      onError={handleError}
                      onProcessingChanged={p => setIsProcessing(p)}
                      canPay={false}
                      isButton
                      viaPaymentLink
                    />
                  </ButtonItem>
                )}
              </ButtonContainer>
            </>
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </PaymentContainer>
      )}
    </TokenOrderDetail>
  );
};

export default PaymentSection;
