import React from 'react';
import { PRODUCT_STORAGE_BASE_ID } from 'gatsby-env-variables';

import { ProductReviewHeroSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const StorageBaseReviewHeroSection: React.FC = () => {
  return (
    <ProductReviewHeroSection
      productId={PRODUCT_STORAGE_BASE_ID}
      allReviewsUrl={SiteUrls.StorageBaseReview}
    />
  );
};

export default StorageBaseReviewHeroSection;
