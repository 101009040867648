import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { Breakpoints } from '../../styles/enums';

type Props = {
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
};

const BgMattressPurchaseInfo: React.FC<Props> = ({ className, children }) => {
  const { desktop, tablet, mobile } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "mattress-purchase-info-d.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tablet: file(relativePath: { eq: "mattress-purchase-info-t.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "mattress-purchase-info-m.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    mobile.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: ${Breakpoints.MD}px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: ${Breakpoints.LG}px)`,
    },
  ];

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={sources}
      backgroundColor="#040e18"
    >
      {children}
    </BackgroundImage>
  );
};

export default BgMattressPurchaseInfo;
