import React from 'react';
import styled from 'styled-components';

import SvgCopy from '../../static/images/copy-icon.svg';

type Props = {
  className?: string;
  value: string;
};

const Container = styled.div`
  position: relative;
  padding-right: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
`;

const Input = styled.input`
  padding: 0;
  margin: 0;
  border: 0;
`;

const CopyIcon = styled(SvgCopy)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
`;

const CopyInput: React.FC<Props> = ({ className, value }) => {
  const handleClickCopy = () => {
    // TODO: Handle copy to clipboard
  };

  return (
    <Container className={className}>
      <Input value={value} readOnly />
      <CopyIcon onClick={handleClickCopy} />
    </Container>
  );
};

export default CopyInput;
