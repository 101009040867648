import React from 'react';
import { PRODUCT_ADJUSTABLE_BASE_2_ID } from 'gatsby-env-variables';

import { ProductReviewHeroSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const AdjustableBaseReviewHeroSection: React.FC = () => {
  return (
    <ProductReviewHeroSection
      productId={PRODUCT_ADJUSTABLE_BASE_2_ID}
      allReviewsUrl={SiteUrls.AdjustableBase2Review}
    />
  );
};

export default AdjustableBaseReviewHeroSection;
