import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import DefaultModal from './custom-modal';
import PromoBundleItem from './promo-bundle-item';
import { flatButtonStyle, rem } from '../../styles/utils';
import { useCartState, useCartDispatch } from '../../hooks/cart-context';
import { BundleProduct } from '../../types/promo';
import { ProductOrderItem } from '../../types/order';

const Container = styled.div`
  font-family: var(--font-body);
`;

const Header = styled.h2`
  font-family: var(--font-header);
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
  font-size: ${rem(22)};
  line-height: 1.5;

  ${media.lg`
    font-size: ${rem(24)};
  `}
`;

const Body = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  ${media.lg`
    max-height: 500px;
  `}
`;

const Button = styled.button`
  ${flatButtonStyle}
  display: block;
  width: 100%;
  margin-top: 20px;
`;

const Error = styled.span`
  display: block;
  margin-top: 10px;
  color: red;
  font-size: 80%;
`;

const Modal = styled(DefaultModal)`
  .modal-container {
    ${media.lg`
      max-width: 600px;
    `}
  }
`;

type Props = {
  className?: string;
};

const PromoBundleModal: React.FC<Props> = ({ className }) => {
  const { showPromoBundleModal, bundle, bundlePromoCode } = useCartState();
  const dispatch = useCartDispatch();
  const [selectedProducts, setSelectedProducts] = useState<BundleProduct[][]>(
    []
  );
  const [error, setError] = useState('');

  if (!bundle) return null;

  const { gets } = bundle;

  return (
    <Modal
      className={className}
      isActive={showPromoBundleModal}
      onClickClose={() => dispatch({ type: 'HIDE_PROMO_BUNDLE_MODAL' })}
    >
      <Container>
        <Header>
          Select your free gifts for promo code {bundlePromoCode?.code}
        </Header>
        <Body>
          {gets
            .filter(({ type }) => type === 'products' || type === 'collections')
            .map(({ products, qty }, idx) => (
              <PromoBundleItem
                key={idx}
                title={`Item ${idx + 1}`}
                products={products || []}
                qty={qty}
                isShowPromoBundleModal={showPromoBundleModal}
                onChange={prods => {
                  const mutatedSelectedProducts = [...selectedProducts];
                  mutatedSelectedProducts[idx] = [...prods];

                  mutatedSelectedProducts[idx].forEach(
                    p => (p.promoCode = bundlePromoCode?.code)
                  );

                  setSelectedProducts(mutatedSelectedProducts);
                }}
              />
            ))}
        </Body>
        <Button
          onClick={async () => {
            setError('');
            const bundledProducts: ProductOrderItem[] = selectedProducts.reduce(
              (acc: ProductOrderItem[], group) => {
                const flat = group.reduce((flatten: ProductOrderItem[], p) => {
                  flatten.push({
                    id: p.id,
                    name: p.name,
                    collection: '',
                    variant: p.variant,
                    currency: '',
                    comparePrice: 0,
                    unitPrice: 0,
                    image: p.image || '',
                    qty: p.qty || 0,
                    subtotal: 0,
                    isNew: true,
                    promoCode: p.promoCode,
                    subVariants: p.subVariants,
                  });
                  return flatten;
                }, []);

                acc.push(...flat);
                return acc;
              },
              []
            );

            dispatch({
              type: 'ADD_PROMO_BUNDLE_PRODUCTS',
              products: bundledProducts,
              promo: bundlePromoCode,
            });
          }}
        >
          Add to cart
        </Button>
        {error && <Error>{error}</Error>}
      </Container>
    </Modal>
  );
};

export default PromoBundleModal;
