import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { ProductAccordionSection, IconExpander, Video, Link } from '../common';
import {
  Paragraph,
  UnorderedList,
  ListItem,
  Subtitle,
} from '../common/product-accordion-section';
import {
  ImgSeresSilkDuvetMaterial1,
  ImgSeresSilkDuvetMaterial2,
} from '../assets';
import DesignProcessVideo from '../../static/videos/waterproof-mattress-protector-design-process.mp4';
import { Accordion } from '../../types/component';
import { SiteUrls } from '../../utils/constants';

const CareForWoosa = styled.div`
  margin-top: 40px;
`;

const data: Accordion[] = [
  {
    title: 'Design Process',
    body: (
      <Row>
        <Col xs={12} md={6} mdOrder="last">
          <Video src={DesignProcessVideo} />
        </Col>
        <Col xs={12} md={6}>
          <Paragraph>
            We wanted to create a mattress protector as comfortable as it is
            practical. This waterproof mattress protector hugs your bed with a
            double-layered material that sidesteps any accidental spills. Here’s
            how it works: the top layer absorbs any liquid, while the second
            waterproof layer gives you peace of mind that your mattress will be
            kept 100% dry – which means you can get more sleeps out of the
            mattress you love.
          </Paragraph>
          <Paragraph>
            That said, we firmly believe that the need to protect your mattress
            shouldn’t take away from the comfort factor. Crinkle-resistant and
            non-plasticky, our noise-free protector sets you up for a restful,
            disturbance-free slumber. Combined with its ultra-breathable
            material that keeps you cool through the night, you’ll be getting a
            restorative snooze tonight and always.
          </Paragraph>
        </Col>
      </Row>
    ),
  },
  {
    title: 'Material & Care',
    body: (
      <>
        <Row>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgSeresSilkDuvetMaterial1 />}
              title="100% Cooling Polyester"
              content={
                <Paragraph>
                  The top layer of the mattress protector is cool to the touch
                  and absorbs any liquid from spills.
                </Paragraph>
              }
            />
          </Col>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgSeresSilkDuvetMaterial2 />}
              title="100% Polyurethane"
              content={
                <Paragraph>
                  The bottom layer is waterproof and protects your mattress,
                  preventing any liquid from seeping through.
                </Paragraph>
              }
            />
          </Col>
        </Row>
        <CareForWoosa>
          <Paragraph fontWeight="bold">HOW TO CARE FOR YOUR WOOSA:</Paragraph>
          <UnorderedList>
            <ListItem>Wash at or below 60°</ListItem>
            <ListItem>Tumble dry at low temp</ListItem>
            <ListItem>Do not iron</ListItem>
            <ListItem>Do not bleach</ListItem>
            <ListItem>Dry clean, any solvent except trichloroethylene</ListItem>
          </UnorderedList>
        </CareForWoosa>
      </>
    ),
  },
  {
    title: 'Shipping, Return, Warranty & Cancellation',
    body: (
      <>
        <Subtitle>Shipping</Subtitle>
        <Paragraph>
          All products are shipped free to your doorstep and inclusive of
          installation.
        </Paragraph>
        <Subtitle>Return</Subtitle>
        <Paragraph>
          All our accessories are applicable for free returns within the first
          30 days of receiving your item. Our accessories include The Woosa
          Pillow, The Seres Silk Duvet, The Mattress Protector. Read more{' '}
          <Link to={SiteUrls.FreeTrial}>here</Link>.
        </Paragraph>
        <Subtitle>Warranty</Subtitle>
        <Paragraph>
          The Waterproof Mattress Protector comes with a 1 year warranty. Read
          more{' '}
          <a
            href={SiteUrls.WaterproofMattressProtectorWarranty}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Paragraph>
        <Subtitle>Cancellation</Subtitle>
        <Paragraph>
          Free cancellation prior to delivery. Read more{' '}
          <Link to={SiteUrls.FreeTrial}>here</Link>{' '}
        </Paragraph>
      </>
    ),
  },
  {
    title: 'FAQ',
    body: (
      <>
        <Subtitle>
          What are the dimensions of the Waterproof Mattress Protector?{' '}
          <i>(not duvet)</i>
        </Subtitle>
        <Paragraph>
          Single: 91cm x 191cm x3 5cm
          <br />
          Super Single: 107cm x 191cm x 35cm
          <br />
          Queen: 152cm x 191cm x 35cm
          <br />
          King: 183cm x 191cm x 35cm
        </Paragraph>
        <Subtitle>Is the mattress protector 100% waterproof?</Subtitle>
        <Paragraph>Yes, it keeps your mattress 100% dry.</Paragraph>
        <Subtitle>Can it be machine washed?</Subtitle>
        <Paragraph>
          Yes, the mattress protector can be machine washed.
        </Paragraph>
        <Subtitle>Is the Waterproof Mattress Protector hot or noisy?</Subtitle>
        <Paragraph>
          The mattress protector is neither hot nor noisy. The top layer of
          cooling polyester fibers is ultra breathable and helps ensure that
          your mattress stays cool. The bottom layer is made from a unique, soft
          Polyurethane backing, which does not produce crinkly and stiff plastic
          noises.
        </Paragraph>
        <Subtitle>Are all mattress protectors the same?</Subtitle>
        <Paragraph>
          A mattress protector acts as a second skin that wraps around your
          mattress, providing an extra layer of protection. A regular mattress
          protector protects against small spills and stains, but a moderate
          amount of liquid may still seep into the mattress. With a Polyurethane
          fabric backing, Woosa’s Waterproof Mattress Protector protects the
          mattress fully against any large spills or accidents, leaving your
          mattress 100% dry underneath.
        </Paragraph>
        <a href={SiteUrls.Faq} target="_blank" rel="noreferrer">
          Read All FAQs
        </a>
      </>
    ),
  },
];

const WaterproofMattressProtectorAccordionSection: React.FC = () => {
  return <ProductAccordionSection data={data} />;
};

export default WaterproofMattressProtectorAccordionSection;
