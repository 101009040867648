import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { ProductOrderItem } from '../../../types/order';
import { Product, Variant } from '../../../types/product';
import { rem } from '../../../styles/utils';
import SubVariantItem from './sub-variant-item';
import SubVariantList from './sub-variant-list';

const Container = styled.div``;

const Item = styled.li`
font-weight: 400;
font-size: ${rem(12)};
line-height; 1.16; 
display: flex;
flex-direction: column;

span {
  margin: 5px 0;
}
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  ${Item} {
    margin-right: 10px;
  }
`;

type Props = {
  addOnProduct: Product;
  onChange?: (productOrderItem: ProductOrderItem | null) => void;
};

const StandardVariantTab: React.FC<Props> = ({ addOnProduct, onChange }) => {
  const [selected, setSelected] = useState<Variant | null>(null);
  const standardVariants = addOnProduct.variants.filter(
    v => v.statusType === 'Standard'
  );

  const getName = (variant: Variant) => {
    return variant.subVariants.map(v => v.value).join(' ');
  };

  const getImage = (variant: Variant) => {
    if (variant.thumbnail) return variant.thumbnail;
    if (!(addOnProduct.media || []).length) return '';
    const defaultMedia = addOnProduct.media.find(m => m.type === 'image');
    if (!defaultMedia) return '';
    return defaultMedia.path || '';
  };

  const handleClick = (variant: Variant) => {
    setSelected(variant);
    if (typeof onChange !== 'function') return;
    const { id, name, collection } = addOnProduct;
    const {
      type,
      subVariants,
      status,
      statusDate,
      statusType,
      statusNote,
      sku,
      prices,
    } = variant;
    const { compareAmount, amount, currency } = prices[0];
    const selectedProduct: ProductOrderItem = {
      id,
      name,
      collection,
      image: getImage(variant),
      comparePrice: compareAmount || 0,
      currency,
      qty: 1,
      subtotal: amount,
      unitPrice: amount,
      variant: type,
      subVariants,
      isNew: true,
      status,
      statusDate: (statusDate
        ? moment.unix(statusDate._seconds)
        : moment()
      ).toDate(),
      statusType,
      statusNote,
      sku,
    };
    onChange(selectedProduct);
  };

  return (
    <Container>
      <SubVariantList
        items={standardVariants.map(v => (
          <SubVariantItem
            key={v.id}
            onClick={() => handleClick(v)}
            image={getImage(v)}
            name={getName(v)}
            selected={v.id === selected?.id}
          />
        ))}
      />
    </Container>
  );
};

export default StandardVariantTab;
