import React from 'react';
import styled from 'styled-components';

import { Expander, SimpleSplitSection, Link } from '../common';
import {
  Wrapper,
  LeftTitle,
  fontStyle,
  PlusIconList,
  PlusIconListItem,
} from '../common/simple-split-section';
import { HardCodeValues, SiteUrls } from '../../utils/constants';

const Container = styled(Wrapper)``;

const Row = styled(SimpleSplitSection)`
  margin-top: 40px;
`;

const Title = styled.strong`
  ${fontStyle}
`;

const Paragraph = styled.p`
  ${fontStyle}
`;

const Table = styled.table`
  ${fontStyle}
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th,
  td {
    padding: 5px;
  }

  th {
    border-bottom: 2px solid #ccc;
    border-top: 2px solid #ccc;
  }

  td {
    border-bottom: 1px solid #ccc;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  margin-bottom: 20px;
`;

const FaqContentSection: React.FC = () => {
  const data = [
    {
      headline: 'The Woosa Mattress',
      id: '',
      items: [
        {
          title:
            'I’m a side/back/front sleeper. Will the Woosa mattress fit me?',
          id: '',
          content: (
            <Paragraph>
              The Woosa mattress is designed to cater to most individuals, and
              formulated to respond to almost all sleeping positions.
            </Paragraph>
          ),
        },
        {
          title: 'Where is the Woosa mattress made?',
          id: '',
          content: (
            <Paragraph>
              The Woosa mattress is designed and tested in Singapore, and 100%
              made in Belgium
            </Paragraph>
          ),
        },
        {
          title: 'What is the Woosa mattress made of?',
          id: '',
          content: (
            <>
              <Paragraph>
                The Woosa mattress is a full foam mattress, engineered from:
              </Paragraph>
              <PlusIconList>
                <PlusIconListItem>
                  2 inches (4cm) of premium latex foam for that medium-firm
                  level of comfort
                </PlusIconListItem>
                <PlusIconListItem>
                  2 inches (4cm) of premium memory foam that gently conforms and
                  cradles you to sleep, providing greater comfort and pressure
                  relieving support
                </PlusIconListItem>
                <PlusIconListItem>
                  (5cm) inches of transitional high density PU (polyurethane)
                  foam for deeper support and even weight distribution
                </PlusIconListItem>
                <PlusIconListItem>
                  4 (11cm) inches of robust, high density PU foam for the
                  deepest level of support, offering greater resiliency,
                  durability and eliminates motion transfer.
                </PlusIconListItem>
                <PlusIconListItem>
                  The Woosa mattress is wrapped in a highly breathable,
                  polyester blended fabric that wicks away those sweaty nights.
                </PlusIconListItem>
              </PlusIconList>
            </>
          ),
        },
        {
          title: 'Why no springs?',
          id: '',
          content: (
            <Paragraph>
              Through our research and extensive testing, we find that most
              springs tend to give way much faster due to the constant
              gravitational pressure from your body, and offer sub-par weight
              distribution as compared to high quality foams.
              <br />
              <br />
              We wanted a core foundation that is supportive, resilient,
              durable, and eliminates motion transfer- which is best achieved
              through our high density PU foam base - just imagine millions of
              cells supporting and distributing your weight.
            </Paragraph>
          ),
        },
      ],
    },
    {
      headline: 'Caring for your Woosa Mattress',
      id: '',
      items: [
        {
          title:
            'How long must I wait for the mattress to fully regain its shape after unboxing?',
          id: '',
          content: (
            <Paragraph>
              The Woosa mattress is ready to use almost immediately after
              unpacking. However, it will take approximately an hour for the
              foams to be fully responsive. You will find that the mattress will
              get more comfortable the more you sleep on it, as it reacts to
              your body weight and heat.
            </Paragraph>
          ),
        },
        {
          title: 'Is there a smell?',
          id: '',
          content: (
            <Paragraph>
              The “new product smell” that some might encounter comes from fresh
              latex being packaged and shipped across the seas to your home. The
              smell, as with all our foams, are safe and certified free from any
              harmful chemicals. Placing the mattress in a well-ventilated room
              will help the smell dissipate within a day.
            </Paragraph>
          ),
        },
        {
          title: 'Where is the Woosa mattress made?',
          id: '',
          content: (
            <Paragraph>
              The Woosa mattress is designed and tested in Singapore, and 100%
              made in Belgium.
            </Paragraph>
          ),
        },
        {
          title: 'Can I machine wash the outer cover?',
          id: '',
          content: (
            <Paragraph>
              The outer cover is not meant to be machine washed. We recommend
              spot cleaning any stains with a wet cloth.
            </Paragraph>
          ),
        },
      ],
    },
    {
      headline: 'The Woosa Pillow',
      id: '',
      items: [
        {
          title: 'What are the pillow dimensions?',
          id: '',
          content: <Paragraph>65cm x 45cm x 13cm</Paragraph>,
        },
        {
          title: 'What are the pillow box dimensions?',
          id: '',
          content: <Paragraph>66cm x 41.5cm x 15cm</Paragraph>,
        },
        {
          title: 'Tell me more about the Woosa Cloud Foam.',
          id: '',
          content: (
            <Paragraph>
              The Woosa Cloud Foam is a modified combination of Memory Foam and
              High Resiliency Foam that possess all the qualities of the ideal
              pillow- soft and plush, supportive and breathable. The foam
              although dense, contains a unique cell structure that allows air
              to flow through freely, and the added elasticity to the foam also
              allows you to toss and turn with ease throughout the night.
              Throughout the process of development, the Woosa Cloud Foam has
              been rigorously tested and tweaked according to the sleeping
              preferences of Singaporeans.
            </Paragraph>
          ),
        },
        {
          title: 'Why not other materials?',
          id: '',
          content: (
            <Paragraph>
              Many pillows in the market all have their specific benefits, but
              each carry their own drawbacks. None came close to our idea of a
              perfect pillow. Memory foam pillows are supportive but sleeps hot,
              latex pillows are comfortable and resilient but lacks plush, down
              pillows are soft and plush but loses its shape and leaves a hole
              in the middle.
              <br />
              <br />
              We wanted a pillow that perfectly balances all these positives
              without any of the negatives, and used what we learnt to develop
              the Woosa Cloud Foam.
            </Paragraph>
          ),
        },
        {
          title: 'Is there a smell?',
          id: '',
          content: (
            <Paragraph>
              The Woosa Pillow may have a mild scent upon unboxing which will
              dissipate within a few hours in a well-ventilated room. The Woosa
              pillow is certified free from any harmful VOCs or chemicals.
              <br />
              <br />
              We wanted a core foundation that is supportive, resilient,
              durable, and eliminates motion transfer- which is best achieved
              through our high density PU foam base - just imagine millions of
              cells supporting and distributing your weight.
            </Paragraph>
          ),
        },
        {
          title: 'I’m a side/back/front sleeper. Will the Woosa Pillow fit me?',
          id: '',
          content: (
            <Paragraph>
              The unique adaptive properties of the Woosa Cloud Foam help make
              the Woosa Pillow suitable for all sleeping positions.
            </Paragraph>
          ),
        },
        {
          title: 'Where is the Woosa Pillow made from?',
          id: '',
          content: (
            <Paragraph>
              The fabric of the Woosa Pillow is sourced from Italy, while the
              rest of the pillow is made and assembled in Belgium. The Woosa
              Pillow is designed and tested in Singapore.
            </Paragraph>
          ),
        },
      ],
    },
    {
      headline: 'Caring for your Woosa Pillow',
      id: '',
      items: [
        {
          title: 'Can I wash the foam?',
          id: '',
          content: (
            <Paragraph>
              We do not recommend washing the inner foam material
            </Paragraph>
          ),
        },
        {
          title: 'Can I wash the outer cover?',
          id: '',
          content: (
            <Paragraph>
              We recommend washing the outer cover only when necessary. Hand
              wash with mild detergent and hang to dry. Avoid washing the cover
              too often to preserve the fabric’s quality.
            </Paragraph>
          ),
        },
      ],
    },
    {
      headline: 'The Seres Silk Duvet',
      id: '',
      items: [
        {
          title: 'What are the dimensions of The Seres Silk Duvet?',
          id: '',
          content: (
            <Paragraph>
              Single: 140cm x 210cm
              <br />
              Super Single: 160cm x 210cm
              <br />
              Queen: 210cm x 210cm
              <br />
              King: 240cm x 210cm
            </Paragraph>
          ),
        },
        {
          title: 'Do I have to buy a cover for The Seres Silk Duvet?',
          id: '',
          content: (
            <Paragraph>
              It is recommended to use the duvet with a cover. The Woosa Seres
              Silk Duvet is designed to fit any standard Asian-sized duvet cover
              of the same dimensions listed above.
            </Paragraph>
          ),
        },
        {
          title: 'Can I wash the duvet?',
          id: '',
          content: (
            <Paragraph>
              It is not recommended to wash The Seres Silk Duvet as silk
              naturally repels dust, dirt, dust mites, moths, mildew and odours.
              Only sun cure it by placing it under the direct rays of the sun.
              <br />
              <br />
              If you have any stains, spot clean and place under the sun to dry.
              <br />
              <br />
              If you absolutely must wash the duvet, hand wash only with cool
              water and gentle soap, and hang flat to dry. Washing the duvet
              regularly causes the silk fibres to disintegrate quicker,
              affecting the intended properties of the filling and its
              durability.
            </Paragraph>
          ),
        },
        {
          title: 'Do you use 100% silk?',
          id: '',
          content: (
            <Paragraph>
              Our duvet is made from 100% pure long strand Mulberry silk - no
              synthetic silk is used in the filling.
            </Paragraph>
          ),
        },
        {
          title: 'Is the duvet hot?',
          id: '',
          content: (
            <Paragraph>
              The Seres Silk duvet is designed to be versatile and suitable for
              all seasons. Because of our natural silk filling, the duvet keeps
              you cool in the heat, and warm on chilly nights.
            </Paragraph>
          ),
        },
        {
          title: 'Where is The Seres Silk Duvet made?',
          id: '',
          content: (
            <Paragraph>
              Our duvet is sourced and made responsibly in Suzhou, China - the
              Silk capital of the world.
            </Paragraph>
          ),
        },
      ],
    },
    {
      headline: 'The Adjustable',
      items: [
        {
          title: 'Can you adjust the height of the adjustable base?',
          id: '',
          content: (
            <Paragraph>
              Your provided legs for the Adjustable base 2 are height adjustable
              with the following configurations: 14.5cm, 19.5cm, 32.5cm from the
              ground.
            </Paragraph>
          ),
        },
        {
          title: 'Does the adjustable base come with warranty?',
          id: '',
          content: (
            <Paragraph>
              The Woosa Adjustable base comes with a 5 year limited, on-site
              warranty against any mechanical defects. Read more about our
              warranty <Link to={SiteUrls.BedFrameWarranty}>here</Link>.
            </Paragraph>
          ),
        },
        {
          title: 'What is the weight limit?',
          id: '',
          content: (
            <Paragraph>
              The Woosa Adjustable Base can function with an evenly applied load
              of 270kg, inclusive of the weight of the mattress.
            </Paragraph>
          ),
        },
        {
          title: "Can i keep the head/feet elevated when I'm not using it?",
          id: '',
          content: (
            <Paragraph>
              The beauty of an adjustable base is to elevate the mattress to
              your comfort level while you use it. For long-term durability
              reasons, we don’t recommend storing your mattress in the elevated
              position when it isn&apos;t actively in use. Instead, make sure it
              is entirely flat.
            </Paragraph>
          ),
        },
      ],
    },
    {
      headline: 'Waterproof Mattress Protector',
      id: '',
      items: [
        {
          title:
            'What are the dimensions of the Waterproof Mattress Protector?',
          id: '',
          content: (
            <Paragraph>
              Single: 91cm x 191cm x3 5cm
              <br />
              Super Single: 107cm x 191cm x 35cm
              <br />
              Queen: 152cm x 191cm x 35cm
              <br />
              King: 183cm x 191cm x 35cm
            </Paragraph>
          ),
        },
        {
          title: 'Is the mattress protector 100% waterproof?',
          id: '',
          content: <Paragraph>Yes, it keeps your mattress 100% dry.</Paragraph>,
        },
        {
          title: 'Can it be machine washed?',
          id: '',
          content: (
            <Paragraph>
              Yes, the mattress protector can be machine washed.
            </Paragraph>
          ),
        },
        {
          title: 'Is the Waterproof Mattress Protector hot or noisy?',
          id: '',
          content: (
            <Paragraph>
              The mattress protector is neither hot nor noisy. The top layer of
              cooling polyester fibers is ultra breathable and helps ensure that
              your mattress stays cool. The bottom layer is made from a unique,
              soft Polyurethane backing, which does not produce crinkly and
              stiff plastic noises.
            </Paragraph>
          ),
        },
        {
          title: 'Are all mattress protectors the same?',
          id: '',
          content: (
            <Paragraph>
              A mattress protector acts as a second skin that wraps around your
              mattress, providing an extra layer of protection. A regular
              mattress protector protects against small spills and stains, but a
              moderate amount of liquid may still seep into the mattress. With a
              Polyurethane fabric backing, Woosa’s Waterproof Mattress Protector
              protects the mattress fully against any large spills or accidents,
              leaving your mattress 100% dry underneath.
            </Paragraph>
          ),
        },
      ],
    },
    {
      headline: 'Return',
      id: '',
      items: [
        {
          title: 'What do you do with returned mattresses and pillows? ',
          id: '',
          content: (
            <Paragraph>
              All returned mattresses are either donated to local social
              enterprises and charities, or disposed properly at recycling
              centers. We never resell used Woosa products.
            </Paragraph>
          ),
        },
      ],
    },
    {
      headline: 'Warranty and Safety',
      id: 'warranty-and-safety',
      items: [
        {
          title: 'What certifications does Woosa products have?',
          id: '',
          content: (
            <Paragraph>
              All foam components used by Woosa, including the Woosa Cloud Foam,
              are Certi-purUS certified, which means all our foams are made
              without any harmful chemicals such as formaldehyde, ozone
              depleters and heavy metals.
              <br />
              <br />
              The Woosa Cloud Foam, latex, memory foam and fabric covers all
              have an added OEKO-TEX® Class 1 certification- adhering to
              Europe’s strictest standards safeguarding health, making all Woosa
              products safe for even babies and toddlers.
            </Paragraph>
          ),
        },
        {
          title: 'What does Woosa’s limited warranty cover?',
          id: '',
          content: (
            <Paragraph>
              The Woosa Mattress and Woosa Pillow comes with a 10-year and
              3-year limited warranty respectively. Please refer{' '}
              <Link to={SiteUrls.OriginalMattressWarranty}>here</Link> for the
              full terms of our Limited Warranty.
            </Paragraph>
          ),
        },
        {
          title: 'What is the cost of your replacement parts?',
          id: 'cost-of-replacement-parts',
          content: (
            <Table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={4}>
                    <b>Original/Terra/Mysa Mattress Cover</b>
                  </td>
                  <td>Single</td>
                  <td>$250.00</td>
                </tr>
                <tr>
                  <td>Super Single</td>
                  <td>$280.00</td>
                </tr>
                <tr>
                  <td>Queen</td>
                  <td>$350.00</td>
                </tr>
                <tr>
                  <td>King</td>
                  <td>$380.00</td>
                </tr>
                <tr>
                  <td>
                    <b>Pillow</b>
                  </td>
                  <td></td>
                  <td>$39.00</td>
                </tr>
                <tr>
                  <td rowSpan={5}>
                    <b>Adjustable</b>
                  </td>
                  <td>USB Port</td>
                  <td>$50.00</td>
                </tr>
                <tr>
                  <td>Actuator</td>
                  <td>$250.00</td>
                </tr>
                <tr>
                  <td>Wireless Remote</td>
                  <td>$100.00</td>
                </tr>
                <tr>
                  <td>Main Board</td>
                  <td>$300.00</td>
                </tr>
                <tr>
                  <td>Labour</td>
                  <td>$150.00</td>
                </tr>
              </tbody>
            </Table>
          ),
        },
      ],
    },
  ];

  return (
    <Container>
      {data.map(({ headline, id, items }, i) => (
        <div key={id || i} id={id}>
          <Row
            left={<LeftTitle>{headline}</LeftTitle>}
            right={
              <List>
                {items.map(({ title, content, id }, j) => (
                  <Item key={id || j}>
                    <Expander id={id} trigger={<Title>{title}</Title>}>
                      {content}
                    </Expander>
                  </Item>
                ))}
              </List>
            }
          />
        </div>
      ))}
    </Container>
  );
};

export default FaqContentSection;
