import React from 'react';

import { Benefit } from '../../../types/component';
import ProductBenefitRow from '../product-benefit-row';
import AnimatedBackgroundWrapper from '../animated-background-wrapper';

export type Props = {
  benefits: Benefit[];
  className?: string;
  blob?: {
    color1: string;
    color2: string;
  };
  waveLineColor?: string;
};

const ProductBenefitSection: React.FC<Props> = ({
  benefits,
  className,
  blob,
  waveLineColor,
}) => {
  return (
    <AnimatedBackgroundWrapper
      blob={blob}
      waveLineColor={waveLineColor}
      className={className}
    >
      {benefits.map((benefit, key) => (
        <ProductBenefitRow
          version="v1"
          benefit={benefit}
          key={key}
          index={key}
        />
      ))}
    </AnimatedBackgroundWrapper>
  );
};

export default ProductBenefitSection;
