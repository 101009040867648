import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import BlogCategorySection from './blog-category-section';
import BlogFeaturedPostSection from './blog-featured-post-section';
import { Post } from '../../types/post';
import { isSlug } from '../../utils';

type Props = {
  params?: Record<string, string>;
};

const BlogIndexSection: React.FC<Props> = ({ params }) => {
  const queryParam = (params && params['*']) || '';
  const queryCategories = queryParam.split('/');
  const categories: string[] = [];

  if (queryCategories.length > 1) {
    queryCategories[1]
      .split('|')
      .map(category => category.trim())
      .filter(category => (category || '').length > 0)
      .forEach(category => categories.push(category));
  }

  const {
    allPosts: { edges },
  } = useStaticQuery(graphql`
    query {
      allPosts(
        filter: { status: { eq: "published" } }
        sort: { fields: publishedOn, order: DESC }
      ) {
        edges {
          node {
            id
            author {
              name
            }
            content
            slug
            status
            thumbnail
            title
            categories {
              name
              id
            }
            isFeatured
            publishedOn
          }
        }
      }
    }
  `);

  // map posts into valid Post type
  const posts = edges.map(({ node }: { node: Post }) => node) as Post[];

  // populate filter categories if any
  const filterCategories: string[] = [];
  if (categories) {
    if (Array.isArray(categories)) {
      filterCategories.push(...categories);
    } else {
      filterCategories.push(categories);
    }
  }

  // populate posts into filtered conditions based on categories if any
  const filteredPosts = [];
  if (filterCategories.length) {
    filteredPosts.push(
      ...posts.filter(p =>
        (p.categories || []).some(c => isSlug(filterCategories, c.name))
      )
    );
  } else {
    filteredPosts.push(...posts);
  }

  return (
    <>
      <BlogFeaturedPostSection
        categories={filterCategories}
        posts={filteredPosts}
      />
      <BlogCategorySection
        categories={filterCategories}
        posts={filteredPosts}
      />
    </>
  );
};

export default BlogIndexSection;
