import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import SvgLogin from '../../static/images/login-icon.svg';
import { useAuthState } from '../../hooks/auth-context';
import { navigate } from 'gatsby';
import { SiteUrls } from '../../utils/constants';

const Button = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const Icon = styled(SvgLogin)`
  width: 23px;

  ${media.lg`
    width: 38px;
  `}
`;

type Props = {
  className?: string;
};

const AuthButton: React.FC<Props> = ({ className }) => {
  const { accessToken } = useAuthState();

  const handleClick = () => {
    if (!accessToken) {
      navigate(SiteUrls.AccountLogin);
    } else {
      navigate(SiteUrls.AccountDashboard);
    }
  };

  return (
    <Button className={className} onClick={handleClick}>
      <Icon />
    </Button>
  );
};

export default AuthButton;
