import React from 'react';
import styled from 'styled-components';

import FormLabel from './form-label';

const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

const Label = styled(FormLabel)`
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 22px;
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: medium;

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: inline-block;
  }

  &:before {
    height: 16px;
    width: 16px;
    border: 1px solid;
    border-radius: 50%;
    left: 0px;
    top: -3px;
    background-color: white;
  }

  &:after {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--jumbo);
    left: 4px;
    top: 1px;
  }
`;

const Container = styled.div`
  ${Input} + ${Label}::after {
    content: none;
  }

  ${Input}:checked + ${Label}::after {
    content: '';
  }

  ${Input}:focus + ${Label}::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
`;

type Props = {
  className?: string;
  label: string | React.ReactNode;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  inputRef?: (instance: HTMLInputElement) => void;
};

const FormRadio: React.FC<Props> = ({
  className,
  label,
  inputRef,
  inputProps,
}) => {
  return (
    <Container className={className}>
      <Input ref={inputRef} type="radio" {...inputProps} />
      <Label htmlFor={inputProps?.id}>{label}</Label>
    </Container>
  );
};

export default FormRadio;
