import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import {
  useCheckoutDispatch,
  useCheckoutState,
} from '../../hooks/checkout-context';
import { rem } from '../../styles/utils';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
`;

const Menu = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
`;

const Item = styled.li<{ size: number; disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  font-weight: bold;
  font-size: ${rem(10)};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: ${({ size }) => size}%;
  text-align: center;

  ${media.lg`
    font-size: ${rem(12)};
  `}
`;

const Bar = styled.div<{ activeIndex: number; size: number }>`
  width: 100%;
  height: 5px;
  background-color: var(--athens-gray);
  margin-top: 5px;
  position: relative;

  &:before {
    content: '';
    background-color: var(--firefly);
    width: ${({ size }) => size}%;
    height: 100%;
    display: block;
    left: ${({ activeIndex, size }) => activeIndex * size}%;
    right: 0;
    position: absolute;
    transition: left 0.3s ease-in-out;
  }
`;

type Props = {
  className?: string;
};

const WizardMenu: React.FC<Props> = ({ className }) => {
  const { activeIndex, steps } = useCheckoutState();
  const dispatch = useCheckoutDispatch();

  return (
    <Container className={className}>
      <Menu>
        {steps &&
          steps.map((item, key) => (
            <Item
              key={key}
              size={100 / steps.length}
              role="button"
              disabled={item.disabled}
              onClick={() => {
                if (item.disabled) return;
                dispatch({ type: 'SET_ACTIVE_INDEX', activeIndex: key });
              }}
            >
              {key + 1}. {item.label}
            </Item>
          ))}
      </Menu>
      <Bar activeIndex={activeIndex || 0} size={100 / (steps || []).length} />
    </Container>
  );
};

export default WizardMenu;
