import React, { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem, sectionMarginStyle } from '../../../styles/utils';
import { Container } from '..';

const Section = styled.section`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-weight: normal;
  ${sectionMarginStyle}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  color: var(--font-primary-color);
  font-size: ${rem(24)};
  text-align: center;

  ${media.md`
    margin-bottom: 40px;
  `}

  ${media.lg`
    font-size: ${rem(38)};
    margin-bottom: 60px;
  `}

  ${media.xl`
    font-size: ${rem(42)};
    margin-bottom: 100px;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.md`
    flex-direction: row;
  `}
`;

const Info = styled.div`
  ${media.md`
    max-width: 50%;
  `}
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  opacity: 0;
  transform: translateY(50px);
  transition-delay: 1.2s;
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
`;

const InfoItem = styled.li`
  --info-opacity: 0;
  position: absolute;
  opacity: var(--info-opacity);
  top: 0;
  transition: opacity 0.3s ease-in;
  margin-bottom: 20px;
  cursor: pointer;

  &:first-child {
    --info-opacity: 0;
  }

  ${media.md`
    --info-opacity: 0;
  `}

  &.show {
    &:first-child:not('.not-active') {
      --info-opacity: 1;
    }

    ${media.md`
      --info-opacity: 0.3;
    `}
  }

  &.not-active {
    --info-opacity: 0;

    ${media.md`
      --info-opacity: 0.3;
    `}
  }

  &:hover,
  &.active {
    --info-opacity: 1;
  }

  &:first-child {
    position: relative;
  }

  ${media.md`
    position: relative;
    margin-bottom: 30px;
  `}

  ${media.lg`
    margin-bottom: 50px;
  `}
`;

const numberedStyle = css`
  &:before {
    content: attr(data-num);
    background-color: var(--mantle);
    font-size: 80%;
    font-weight: bold;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    padding-top: 3px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    ${media.lg`
      width: 25px;
      height: 25px;
    `}

    .mysa & {
      background-color: var(--tuscany);
    }
  }
`;

const Title = styled.strong`
  font-size: ${rem(12)};
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  ${numberedStyle}

  ${media.lg`
    font-size: ${rem(18)};
  `}
`;

const Subtitle = styled.span`
  color: var(--leather);
  font-size: ${rem(10)};
  display: block;
  margin-bottom: 5px;

  ${media.lg`
    font-size: ${rem(13)};
  `}
`;

const Content = styled.p`
  font-size: ${rem(12)};
  line-height: 1.3;
  margin-top: 0;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const Mattress = styled.div`
  font-size: ${rem(12)};
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin: 40px 0;
  counter-reset: number 6;
  transform: translateX(20%);

  ${media.md`
    width: 100%;
    transform: translateX(30%);
  `}

  ${media.lg`
    font-size: ${rem(18)};
  `}
`;

const layerKeyframe = keyframes`
  0% {
    transform: translateY(var(--layer-y));
  }
  60%, 90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
`;

const layerAnimation = css`
  animation: ${layerKeyframe} 1.4s 0s forwards ease-in-out;
`;

export const commonLayerStyle = css`
  --layer-brightness: 1;
  width: 130%;
  transition: opacity 0.3s ease-in-out;
  counter-increment: number -1;
  opacity: var(--layer-brightness);
  position: relative;
  margin-top: -25%;
  transform: translateY(var(--layer-y));
  cursor: pointer;

  &.show {
    ${layerAnimation}
  }

  ${numberedStyle}

  &:before {
    position: absolute;
    top: 40px;
    left: -40px;
    transition: transform 0.3s 0.8s ease-in-out, opacity 0.3s 0.8s ease-in-out;
    opacity: 0;
    transform: translateX(10px);

    ${media.md`
      top: 60px;
    `}

    ${media.lg`
      top: 80px;
      left: -60px;
    `}

    ${media.xl`
      top: 125px;
    `}
  }

  &.show {
    &:before {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.not-active {
    --layer-brightness: 0.8;
  }

  &:hover,
  &.active {
    --layer-brightness: 1;
  }

  ${media.md`
    width: 150%;
  `}

  ${media.xl`
    width: 130%;
  `}
`;

export type Props = {
  className?: string;
  info: {
    title: string;
    subtitle: string;
    content: string;
    Layer: React.FC<{ className?: string; role?: string }>;
    Svg: React.FC;
  }[];
};

const MattressLayer: React.FC<Props> = ({ className, info }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current === null) return;

    const layers: Element[] = [];

    containerRef.current
      .querySelectorAll('.mattress-layer')
      .forEach(element => layers.push(element));

    // reverse cause arrangement of layer is in reversed-order
    layers.reverse();

    const infos: NodeListOf<Element> = containerRef.current.querySelectorAll(
      '.mattress-layer-info'
    );

    const onSelected = (event: Event, index: number) => {
      event.preventDefault();

      for (let i = 0; i < layers.length; ++i) {
        if (index === i) {
          layers[i].classList.remove('not-active');
          infos[i].classList.remove('not-active');

          layers[i].classList.add('active');
          infos[i].classList.add('active');
        } else {
          layers[i].classList.remove('active');
          infos[i].classList.remove('active');

          layers[i].classList.add('not-active');
          infos[i].classList.add('not-active');
        }
      }
    };

    for (let i = 0; i < layers.length; ++i) {
      const layer = layers[i];
      const info = infos[i];

      layer.addEventListener('animationend', () => {
        layer.classList.add(`${i === 0 ? '' : 'not-'}active`);
        info.classList.add(`${i === 0 ? '' : 'not-'}active`);
      });
      layer.addEventListener('click', event => onSelected(event, i));
      info.addEventListener('click', event => onSelected(event, i));
    }

    const checkScroll = () => {
      if (!containerRef.current) return;
      const element = containerRef.current.querySelector('.mattress-layer');
      if (!element) return;
      const position = element.getBoundingClientRect();

      // checking for partial visibility
      if (position.top < window.innerHeight && position.bottom >= 0) {
        const elements = containerRef.current.querySelectorAll('.animate');
        elements.forEach(el => {
          if (el.classList.contains('show')) return;
          el.classList.add('show');
        });
        window.removeEventListener('scroll', checkScroll);
      }
    };

    window.addEventListener('scroll', checkScroll);

    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  return (
    <Section className={className}>
      <Container ref={containerRef}>
        <Headline>Let’s look under the covers.</Headline>
        <Wrapper>
          <Info>
            <InfoList className="animate">
              {info.map(({ title, subtitle, content }, key) => (
                <InfoItem
                  className="mattress-layer-info animate"
                  key={key}
                  role="button"
                >
                  <Title data-num={key + 1}>{title}</Title>
                  {subtitle && <Subtitle>{subtitle}</Subtitle>}
                  <Content>{content}</Content>
                </InfoItem>
              ))}
            </InfoList>
          </Info>
          <Mattress>
            {[...info].reverse().map(({ Layer, Svg }, key) => (
              <Layer
                className="mattress-layer animate"
                key={key}
                role="button"
                data-num={info.length - key}
              >
                <Svg />
              </Layer>
            ))}
          </Mattress>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default MattressLayer;
