import React from 'react';

import { LateralSection } from '../common';
import { BgLateral } from '../assets';
import { SiteUrls } from '../../utils/constants';

const HomeLateralSection: React.FC = () => {
  return (
    <LateralSection
      BgSection={BgLateral}
      headline="Ready for bed?"
      cta={{
        text: 'Shop our mattresses online',
        to: SiteUrls.OriginalMattress,
      }}
    />
  );
};

export default HomeLateralSection;
