import React from 'react';

import { MattressFirmnessSection } from '../common';
import Video from '../../static/videos/mysa-mattress-firmness.mp4';

const MysaMattressFirmnessSection: React.FC = () => {
  return (
    <MattressFirmnessSection
      headline="How does it feel"
      firmness={6.5}
      duration={2.5}
      description="Bouncy latex helps provide a responsive surface that is cool to sleep, while memory foam helps provide a deeper level of support for pressure relief. Great for Side Sleepers, Stomach Sleepers or Light- back Sleepers!"
      asset={{
        src: Video,
        type: 'video',
      }}
    />
  );
};

export default MysaMattressFirmnessSection;
