import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';

const Container = styled.div`
  display: flex;
  font-family: var(--font-body);
  font-weight: normal;
`;

const ToggleBtn = styled.button`
  border: 0;
  background-color: white;
  font-size: ${rem(10)};
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--font-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.md`
    width: 25px;
    height: 25px;
  `}

  ${media.xl`
    width: 30px;
    height: 30px;
  `}
`;

const Input = styled.input`
  border: 0;
  background-color: var(--iron);
  color: var(--font-cart-color);
  font-size: ${rem(12)};
  width: 20px;
  height: 20px;
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${media.md`
    width: 25px;
    height: 25px;
    font-size: ${rem(14)};
  `}

  ${media.xl`
    width: 30px;
    height: 30px;
    font-size: ${rem(16)};
  `}
`;

type Props = {
  beforeChange?: (value: number) => Promise<boolean>;
  onChange?: (value: number, oldValue: number) => void;
  defaultValue?: number;
  disabled?: boolean;
};

const QuantitySelector: React.FC<Props> = ({
  beforeChange,
  onChange,
  defaultValue,
  disabled,
}) => {
  const [value, setValue] = useState(defaultValue || 0);

  useEffect(() => {
    setValue(defaultValue || 0);
  }, [defaultValue]);

  const canChange = async (afterValue: number) => {
    let changeable = true;

    if (typeof beforeChange === 'function') {
      changeable = await beforeChange(afterValue);
    }

    return changeable;
  };

  const handleChange = async (
    type: '+' | '-' | 'value',
    targetValue?: number
  ) => {
    let newValue = targetValue || 0;

    if (type !== 'value') {
      newValue = value + (type === '+' ? 1 : -1);
    }

    if (!(await canChange(newValue))) return;

    setValue(newValue < 0 ? 0 : newValue);

    if (typeof onChange === 'function') {
      onChange(newValue, value);
    }
  };

  return (
    <Container>
      {!disabled && (
        <ToggleBtn onClick={async () => await handleChange('-')}>-</ToggleBtn>
      )}
      <Input
        type="number"
        value={value}
        min={0}
        disabled={disabled}
        onChange={async event =>
          await handleChange('value', parseInt(event?.target?.value))
        }
      />
      {!disabled && (
        <ToggleBtn onClick={async () => await handleChange('+')}>+</ToggleBtn>
      )}
    </Container>
  );
};

export default QuantitySelector;
