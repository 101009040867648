import React from 'react';
import styled from 'styled-components';
import { ENABLE_MATTRESS_COMPARISON_PAGE } from 'gatsby-env-variables';

import { Link, ProductAccordionSection } from '../common';
import {
  Paragraph,
  Subtitle,
  Table,
} from '../common/product-accordion-section';
import { Accordion } from '../../types/component';
import { SiteUrls } from '../../utils/constants';
import { Col, Row } from 'styled-bootstrap-grid';

const Section = styled(ProductAccordionSection)`
  margin: 0 !important;
`;

const data: Accordion[] = [
  {
    title: 'Fitment Guide',
    body: (
      <>
        <Row>
          <Col lg={4}>
            <Subtitle>Fitted Sheet:</Subtitle>
            <Table>
              <tr>
                <td>Single</td>
                <td>90cm x 190cm x 45cm (35” x 79” x 15")</td>
              </tr>
              <tr>
                <td>Super Single</td>
                <td>107cm x 190cm x 45cm (42” x 79” x 15")</td>
              </tr>
              <tr>
                <td>Queen</td>
                <td>152cm x 190cm x 45cm (60” x 79” x 15")</td>
              </tr>
              <tr>
                <td>King</td>
                <td>183cm x 190cm x 45cm (72” x 79” x 15")</td>
              </tr>
            </Table>
          </Col>
          <Col lg={4}>
            <Subtitle>Duvet Cover:</Subtitle>
            <Table>
              <tr>
                <td>Single</td>
                <td>140cm x 210cm (55” x 83")</td>
              </tr>
              <tr>
                <td>Super Single</td>
                <td>160cm x 210cm (62" x 83")</td>
              </tr>
              <tr>
                <td>Queen</td>
                <td>210cm x 210cm (83” x 83")</td>
              </tr>
              <tr>
                <td>King</td>
                <td>245cm x 210cm (97” x 83")</td>
              </tr>
            </Table>
          </Col>
          <Col lg={4}>
            <Subtitle>Pillowcase:</Subtitle>
            <Table>
              <tr>
                <td>One size</td>
                <td>50cm x 76cm (20” x 30")</td>
              </tr>
            </Table>
          </Col>
        </Row>
        <Paragraph>
          <small>
            <sup>*</sup>All sizes fit up to 33cm (13”) thick mattresses
          </small>
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Shipping, Warranty, Return & Cancellation',
    body: (
      <>
        <Subtitle>Shipping</Subtitle>
        <Paragraph>
          All products are shipped free to your doorstep and inclusive of
          installation.
        </Paragraph>
        <Subtitle>Warranty</Subtitle>
        <Paragraph>
          Our bedsheets come with a 1-year warranty that safeguards against any
          manufacturing defects originating from our factory, including zipper
          assemblies and seam stitches. However, it does not extend to instances
          of piling.
        </Paragraph>
        <Subtitle>Return</Subtitle>
        <Paragraph>
          We accept returns within 30 days, but please note that we can only
          process returns for unused and unwashed sheets.
        </Paragraph>
        <Subtitle>Cancellation</Subtitle>
        <Paragraph>
          Free cancellation prior to delivery. Read more{' '}
          <Link to={SiteUrls.FreeTrial}>here</Link>.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'FAQ',
    body: (
      <>
        <Subtitle>Should I wash my newly purchased bedsheets?</Subtitle>
        <Paragraph>
          Yes, we suggest washing your brand new Woosa sheets before using them.
          The long-strand Egyptian cotton fibers in our sheets will break down
          and become softer with each wash.
        </Paragraph>
        <Subtitle>What is Egyptian cotton and why is it better?</Subtitle>
        <Paragraph>
          Egyptian cotton is a premium type of cotton renowned for its
          exceptional quality. It is grown in the Nile River Valley and is known
          for its long staple fibers, which are stronger, finer, and more
          lustrous than regular cotton. Egyptian cotton sheets offer superior
          softness, durability, and breathability, providing a luxurious and
          comfortable sleeping experience.
        </Paragraph>
        <Subtitle>Where are your sheets made?</Subtitle>
        <Paragraph>Woosa’s bedsheets are made in Portugal.</Paragraph>
        <Subtitle>What certifications does Woosa's bedsheets have?</Subtitle>
        <Paragraph>
          Our bedsheets hold various esteemed certifications, including
          accreditation from the Cotton Egyptian Association, adherence to the
          OEKO-TEX Standard 100, compliance with the Global Organic Textile
          Standard, and endorsement from the Better Cotton Initiative.
        </Paragraph>
      </>
    ),
  },
];

const EgyptianCottonBeddingSetAccordionSection: React.FC = () => {
  return <Section data={data} initCollapseAll />;
};

export default EgyptianCottonBeddingSetAccordionSection;
