import React, { createContext, useContext, useEffect } from 'react';
import { useCustomReducer } from './use-custom-reducer';
import { CountryCode } from '../utils/constants';

type Action = {
  type: 'SET_COUNTRY_CODE';
  countryCode?: CountryCode;
};

export type Dispatch = (action: Action) => void;

type State = {
  countryCode: CountryCode;
};

type CountryProviderProps = {
  children: React.ReactNode;
  countryCode?: CountryCode;
};

const CountryStateContext = createContext<State | undefined>(undefined);
const CountryDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
  countryCode: CountryCode.UNDEFINED,
};

const countryReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_COUNTRY_CODE':
      return {
        ...state,
        countryCode: action.countryCode || CountryCode.UNDEFINED,
      };
    default: {
      return state;
    }
  }
};

export const CountryProvider: React.FC<CountryProviderProps> = ({
  children,
  countryCode,
}) => {
  const [state, dispatch] = useCustomReducer(
    'CountryContext',
    countryReducer,
    initialState
  );

  const getCountryCode = () => {
    const url = window.location.href;
    const paths = url.split('/');
    if (paths.length >= 4) {
      const countryCodeStr = paths[3].toLowerCase();
      const countryCode = Object.values(CountryCode).find(
        code => code === countryCodeStr
      );
      if (countryCode) {
        return countryCode;
      }
    }

    return CountryCode.UNDEFINED;
  };

  useEffect(() => {
    dispatch({
      type: 'SET_COUNTRY_CODE',
      countryCode: countryCode || getCountryCode(),
    });
  }, [countryCode]);

  return (
    <CountryStateContext.Provider value={state}>
      <CountryDispatchContext.Provider value={dispatch}>
        {children}
      </CountryDispatchContext.Provider>
    </CountryStateContext.Provider>
  );
};

export const useCountryState = (): State | never => {
  const context = useContext(CountryStateContext);
  if (context === undefined) {
    throw new Error('useCountryState must be used within a CountryProvider');
  }
  return context;
};

export const useCountryDispatch = (): Dispatch | never => {
  const context = useContext(CountryDispatchContext);
  if (context === undefined) {
    throw new Error('useCountryDispatch must be used within a CountryProvider');
  }
  return context;
};
