import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Accordion } from '../../types/component';
import { rem, sectionMarginStyle } from '../../styles/utils';
import Container from './container';

const plusIcon = css`
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: var(--cape-cod);
  }

  &:before {
    height: 2px;
    left: 0;
    top: 50%;
    width: 10px;
    transform: translateY(-50%);
  }

  &:after {
    height: 10px;
    width: 2px;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const bodyFont = css`
  font-size: ${rem(12)};
  line-height: 1.3;
  color: var(--font-secondary-color);

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

export const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  line-height: 1;
  margin-top: 0;
  font-size: ${rem(24)};

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.lg`
    font-size: ${rem(42)};
  `}
`;

export const Subtitle = styled.h3`
  font-size: ${rem(12)};
    line-height: 1.4;

  ${media.md`
    font-size: ${rem(14)};
    line-height: 1.4;
  `}

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}
  margin: 0;
`;

export const Paragraph = styled.p<{ fontWeight?: 'normal' | 'bold' }>`
  font-weight: ${({ fontWeight }): string => fontWeight || 'normal'};
  ${bodyFont}
`;

export const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const ListItem = styled.li`
  font-weight: normal;
  position: relative;
  padding-left: 15px;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  ${bodyFont}
  ${plusIcon}
`;

export const Table = styled.table`
  ${bodyFont}
  width: 100%;
  padding: 0;
  border: 0;
  margin: 10px 0;

  td {
    padding: 8px 0;
    border: 0;
    border-bottom: 1px solid #d6d8d2;
  }
`;

const Section = styled.section`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: normal;

  ${sectionMarginStyle}
`;

const AccordionList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const AccordionItem = styled.li`
  border-bottom: 1px solid var(--border-color);

  &:first-child {
    border-top: 1px solid var(--border-color);
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
`;

const Title = styled.h2`
  color: var(--cape-cod);
  font-weight: 400;
  font-size: ${rem(16)};
  padding-left: 15px;
  position: relative;
  margin: 0;

  ${media.lg`
    font-size: ${rem(22)};
  `}

  ${plusIcon}
`;

const Body = styled.div`
  will-change: max-height, visibility;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.3s ease-out, visibility 0.3s ease-out;

  &.active {
    visibility: visible;
    transition: max-height 0.3s ease-out, visibility 0.3s ease-out;
  }
`;

const Content = styled(Container)`
  padding-bottom: 40px;

  ${media.md`
    padding-top: 40px;
  `}

  picture,
  img {
    width: 100%;
  }

  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }
`;

type Props = {
  className?: string;
  data: Accordion[];
  initCollapseAll?: boolean;
};

const ProductAccordionSection: React.FC<Props> = ({
  className,
  data,
  initCollapseAll,
}) => {
  const accordionRef = useRef<HTMLUListElement>(null);

  const toggle = (key: number) => {
    if (accordionRef.current !== null) {
      const listItemBodies = accordionRef.current.querySelectorAll(
        '.product-accordion-body'
      );

      const isActive = !listItemBodies[key].classList.contains('active');

      if (isActive) {
        listItemBodies[key].classList.add('active');
        listItemBodies[key].setAttribute(
          `style`,
          `max-height: ${listItemBodies[key].scrollHeight}px`
        );
      } else {
        listItemBodies[key].classList.remove('active');
        listItemBodies[key].removeAttribute('style');
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (accordionRef.current !== null) {
        const listItemBodies = accordionRef.current.querySelectorAll(
          '.product-accordion-body'
        );

        listItemBodies.forEach(body => {
          if (body.classList.contains('active')) {
            (body as HTMLElement).style.maxHeight = `${body.scrollHeight}px`;
          }
        });
      }
    };

    if (!initCollapseAll) {
      const initTimer = setTimeout(() => toggle(0), 500);
      window.addEventListener('resize', handleResize);

      return () => {
        clearTimeout(initTimer);
        window.removeEventListener('resize', handleResize);
      };
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [initCollapseAll]);

  return (
    <Section className={className}>
      <AccordionList className="product-accordion" ref={accordionRef}>
        {data.map(({ title, body }, key) => (
          <AccordionItem className="product-accordion-item" key={key}>
            <Header
              role="button"
              className="product-accordion-header"
              onClick={() => toggle(key)}
            >
              <Container>
                <Title>{title}</Title>
              </Container>
            </Header>
            <Body className="product-accordion-body">
              <Content>{body}</Content>
            </Body>
          </AccordionItem>
        ))}
      </AccordionList>
    </Section>
  );
};

export default ProductAccordionSection;
