import React from 'react';

import { PostHero, PostBody, PostFooter, PostSEO } from '../common';
import { Post } from '../../types/post';

type Props = {
  post: Post;
};

const BlogPostSection: React.FC<Props> = ({ post }) => {
  return (
    <article>
      <PostSEO post={post} />
      <PostHero post={post} />
      <PostBody post={post} />
      <PostFooter post={post} />
    </article>
  );
};

export default BlogPostSection;
