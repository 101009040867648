import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { rem } from '../../../styles/utils';
import { CommonVariantTypes } from '../../../utils/constants';

const Container = styled.div`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height; 1.16; 
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  span {
    margin: 5px 0;
  }
`;

const ImageContainer = styled.div`
  border: 1px solid transparent;
  border-radius: 3px;
  width: 90px;
  height: 90px;
  position: relative;

  &:not(.unselectable) {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }

  &.selected,
  &:not(.unselectable, .disabled):hover {
    border: 1px solid var(--border-color);
    padding: 3px;
  }

  &.xs {
    width: 18px;
    height: 18px;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }

    &.selected {
      padding: 1px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    img {
      filter: grayscale(100%);
    }
  }
`;

const PriceLabel = styled.div`
  font-family: var(--font-body);
  font-weight: 600;
  font-size: ${rem(10)};
  line-height: 1.2;
  position: absolute;
  color: red;
  right: 6px;
  padding: 2px 4px;
  background: white;
  bottom: 6px;
  border-radius: 5px;
`;

type Props = {
  selected?: boolean;
  onClick?: () => void;
  image: string;
  name: string;
  className?: string;
  diffPrice?: number;
  curVariantType?: string;
  showDiffPriceTag?: CommonVariantTypes;
};

const SubVariantItem: React.FC<Props> = ({
  onClick,
  selected,
  image,
  name,
  className,
  diffPrice,
  curVariantType,
  showDiffPriceTag,
}) => {
  return (
    <Container className={className} onClick={onClick}>
      <ImageContainer
        className={classnames({
          selected,
        })}
      >
        <img src={image} alt="" />
        {diffPrice != undefined &&
          diffPrice > 0 &&
          curVariantType == showDiffPriceTag &&
          selected && (
            <PriceLabel>
              <span>+${diffPrice}</span>
            </PriceLabel>
          )}
      </ImageContainer>
      <span>{name}</span>
    </Container>
  );
};

export default SubVariantItem;
