import React from 'react';
import styled from 'styled-components';

import { Container, Logo } from '../common';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  align-items: center;
`;

const Headline = styled.h1`
  font-family: var(--font-header);
  color: var(--font-primary-color);
`;

const Subheadline = styled.h2`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
`;

const UnderConstructionSection: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <Logo />
        <Headline>Sorry, we&apos;re doing some work on the site</Headline>
        <Subheadline>
          Thank you for being patient. We are doing some work on the site and
          will be back shortly.
        </Subheadline>
      </Wrapper>
    </Container>
  );
};

export default UnderConstructionSection;
