import { useStaticQuery, graphql } from 'gatsby';

import { Product } from '../types/product';

const getProducts = (): Product[] => {
  const {
    allProducts: { edges },
  } = useStaticQuery(graphql`
    query {
      allProducts {
        edges {
          node {
            id
            name
            collection
            subVariantKeys
            media {
              path
              default
              type
            }
            rating {
              avg
              count
              total
            }
            variants {
              type
              prices {
                amount
                currency
              }
              status
              statusDate {
                _seconds
              }
              subVariants {
                image
                key
                value
              }
            }
            displayInStore
          }
        }
      }
    }
  `);

  return edges.map(({ node }: { node: unknown }) => node);
};

export default function useGraphQLGetProduct(productId: string): Product {
  const products = getProducts();
  const product = products.find(p => p.id === productId);
  return (
    product || {
      collection: '',
      id: '',
      name: '',
      variants: [],
      media: [],
      rating: { avg: 0, count: 0, total: 0 },
      subVariantKeys: [],
    }
  );
}

export function useGraphQLGetAllProducts(): Product[] {
  return getProducts();
}
