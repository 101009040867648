import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';

const Container = styled.div`
  font-family: var(--font-body);
  font-size: ${rem(11)};
  border-radius: 20px;
  padding: 8px 15px;
  background-color: transparent;
  color: var(--font-primary-color);
  display: inline-block;

  ${media.lg`
    font-size: ${rem(14)}; 
  `}
`;

type Props = {
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
};

const Badge: React.FC<Props> = ({ className, children }) => {
  return <Container className={className}>{children}</Container>;
};

export default Badge;
