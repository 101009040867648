import React from 'react';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import {
  HEADBOARD_SIDEFRAME_PRODUCT_ID,
  PRODUCT_ADJUSTABLE_BASE_2_ID,
  PRODUCT_ORIGINAL_MATTRESS_ID,
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_MYSA_MATTRESS_ID,
  PRODUCT_PILLOW_ID,
} from 'gatsby-env-variables';

import { ProductSection } from '../common';
import { ProductAddOnConfig } from '../../types/product';
import AdjustableBase1 from '../../static/videos/adjustable-base-2-video.mp4';
import AdjustableBase2 from '../../assets/adjustable-base-2-massage.png';
import AdjustableBase2Webp from '../../assets/adjustable-base-2-massage.webp';
import AdjustableBase3 from '../../assets/adjustable-base-2-reading.png';
import AdjustableBase3Webp from '../../assets/adjustable-base-2-reading.webp';
import AdjustableBase4 from '../../assets/adjustable-base-2-feature.png';
import AdjustableBase4Webp from '../../assets/adjustable-base-2-feature.webp';
import AdjustableBase5 from '../../assets/adjustable-base-2-legs.png';
import AdjustableBase5Webp from '../../assets/adjustable-base-2-legs.webp';
import AdjustableBase6 from '../../assets/adjustable-base-2-no-legs.png';
import AdjustableBase6Webp from '../../assets/adjustable-base-2-no-legs.webp';
import SvgMattress from '../../static/images/add-on-mattress.svg';
import SvgMysa from '../../static/images/add-on-mysa.svg';
import SvgPillow from '../../static/images/add-on-pillow.svg';
import SvgTerra from '../../static/images/add-on-terra.svg';
import { useGraphQLGetAllProducts } from '../../hooks/use-graphql-get-product';
import { BreadcrumbLink } from '../common/breadcrumb';

const Section = styled(ProductSection)`
  background-color: var(--panache);
`;

const AdjustableBaseProductSection: React.FC = () => {
  const breadcrumbs: BreadcrumbLink[] = [
    {
      label: 'Adjustable',
    },
    { label: 'Woosa Adjustable Base II' },
  ];
  const productId = PRODUCT_ADJUSTABLE_BASE_2_ID;
  const customAddOnId = HEADBOARD_SIDEFRAME_PRODUCT_ID;
  const addOnConfig: ProductAddOnConfig = {
    [PRODUCT_ORIGINAL_MATTRESS_ID]: {
      icon: <SvgMattress />,
      name: 'Original',
      sort: 2,
      qty: 1,
    },
    [PRODUCT_MYSA_MATTRESS_ID]: {
      icon: <SvgMysa />,
      name: 'Mysa',
      sort: 3,
      qty: 1,
    },
    [PRODUCT_PILLOW_ID]: {
      icon: <SvgPillow />,
      name: 'Pillow',
      sort: 1,
      qty: 2,
    },
    [PRODUCT_TERRA_MATTRESS_ID]: {
      icon: <SvgTerra />,
      name: 'Terra',
      sort: 4,
      qty: 1,
    },
  };
  const allProducts = useGraphQLGetAllProducts();

  const product = allProducts.find(p => p.id === productId);
  const addOns = sortBy(
    allProducts.filter(p => p.id in addOnConfig),
    [p => addOnConfig[p.id].sort]
  );
  const customAddOn = allProducts.find(p => p.id === customAddOnId);

  return (
    <Section
      {...product}
      version="v2"
      addOns={addOns}
      addOnConfig={addOnConfig}
      addOnLabel={<>Accessories add&#8209;on</>}
      breadcrumbs={breadcrumbs}
      customAddOn={customAddOn}
      defaultAddOnText="Woosa Adjustable Base only"
      assets={[
        {
          src: AdjustableBase1,
          type: 'video',
        },
        {
          src: AdjustableBase2,
          webp: AdjustableBase2Webp,
          type: 'image',
        },
        {
          src: AdjustableBase3,
          webp: AdjustableBase3Webp,
          type: 'image',
        },
        {
          src: AdjustableBase4,
          webp: AdjustableBase4Webp,
          type: 'image',
          size: 'fit',
        },
        {
          src: AdjustableBase5,
          webp: AdjustableBase5Webp,
          type: 'image',
        },
        {
          src: AdjustableBase6,
          webp: AdjustableBase6Webp,
          type: 'image',
        },
      ]}
    >
      <p>
        Experience elevated comfort with the enhanced features of the new Woosa
        Adjustable Base II. Experience your favourite Zero Gravity, Anti-Snore,
        TV positions. Complete your Base with our customised bed frames
        (headboard and sideboards), or remove the provided legs to sit your
        Adjustable base II on the floor.
      </p>
    </Section>
  );
};

export default AdjustableBaseProductSection;
