import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import Carousel from '../carousel';
import Video from '../video';
import ImageZoom from '../image-zoom';
import Picture from '../picture';
import {
  Product,
  ProductAddOnConfig,
  ProductIconInfo,
  Variant,
} from '../../../types/product';
import Container from '../container';
import ProductSectionInfo from '../product-section-info';
import useAddToCart from '../../../hooks/use-add-to-cart';
import { Asset } from '../../../types/component';
import DefaultBreadcrumb, { BreadcrumbLink } from '../breadcrumb';
import LightboxGallery from '../light-box-gallery';
import { ProductOrderItem } from '../../../types/order';
import ProductSchemaMarkup from '../product-schema-markup';

const Section = styled.section`
  width: 100%;
  padding-bottom: 40px;

  ${media.xl`
    padding-bottom: 80px;
  `}
`;

const ProductCarousel = styled(Carousel)`
  margin-bottom: 40px;

  .carousel {
    padding-bottom: 0;

    .control-dots {
      .dot {
        background-color: transparent;
        border-color: white;

        &.selected {
          background-color: white;
        }
      }
    }
  }

  ${media.md`
    display: none;
  `}
`;

const Wrapper = styled.div`
  ${media.md`
    display: flex;
  `}
`;

const ProductContainer = styled(Container)`
  ${media.md`
    padding-top: 40px;
  `}

  ${media.lg`
    padding-top: 120px;
  `}

  ${media.xl`
    padding-top: 160px;
  `} 
  
`;

const Slide = styled.div`
  width: 100%;
  height: 210px;
  position: relative;
  overflow: hidden;

  ${media.lg`
    height: 300px;
  `}

  ${media.xl`
    height: 425px;
  `}
`;

const ImgPictureWrapper = styled.button`
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  &.fit {
    width: 100%;
  }
`;

const ImgPicture = styled(Picture)`
  img {
    width: 100%;
  }
`;

const ImgZoom = styled(ImageZoom)``;

const Gallery = styled.div`
  display: none;

  ${media.md`
    display: block;
    width: 50%;
  `}

  ${media.lg`
    width: 60%;
  `}
`;

const GalleryHero = styled.div`
  margin-bottom: 12px;

  ${Slide} {
    height: auto !important;
  }
`;

const GalleryItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const GalleryItem = styled.div`
  flex: 50%;
  margin-bottom: 12px;

  &:nth-child(even) {
    padding-left: 9px;
  }

  &:nth-child(odd) {
    padding-right: 9px;
  }

  &.fit {
    flex: 100%;
    padding: 0;
  }

  ${Slide} {
    height: auto !important;
  }
`;

const GalleryVideo = styled(Video)`
  width: 120%;
  &.fit {
    width: 100%;
  }
`;

const Breadcrumb = styled(DefaultBreadcrumb)`
  margin-bottom: 30px;
`;

type ProductSectionV2Asset = Asset & {
  size?: 'default' | 'fit';
};

export type Props = {
  id: string;
  name: string;
  collection: string;
  children?: React.ReactNode | React.ReactNodeArray;
  media: {
    path: string;
    default: boolean;
    type: 'image' | 'video';
  }[];
  rating: {
    avg: number;
    count: number;
    total: number;
  };
  variants: Variant[];
  className?: string;
  defaultAddOnText?: string;
  addOns?: Product[];
  assets: ProductSectionV2Asset[];
  addOnLabel?: string;
  addOnConfig?: ProductAddOnConfig;
  customAddOn?: Product;
  badge?: JSX.Element;
  breadcrumbs?: BreadcrumbLink[];
  plusCover?: Product;
  setIsPlusCover?: (isChecked: boolean) => void;
  isPlusCover?: boolean;
  includedInfoList?: ProductIconInfo[];
  subVariantAssets?: {
    [value: string]: ProductSectionV2Asset[];
  };
};

const ProductSection: React.FC<Props> = ({
  id,
  name,
  collection,
  media,
  rating,
  className,
  children,
  defaultAddOnText,
  addOns,
  customAddOn,
  variants,
  assets: productAssets,
  addOnLabel,
  addOnConfig,
  badge,
  breadcrumbs,
  plusCover,
  setIsPlusCover,
  isPlusCover,
  includedInfoList,
  subVariantAssets,
}) => {
  const {
    product,
    isLoading,
    selectedVariant,
    selectedAddOn,
    selectedCustomAddOn,
    selectedProductOrderItem,
    selectedProductPlusCover,
    assets,
    handleAddToCart,
    handleProductAddOnChange,
    handleProductCustomAddOnChange,
    handleProductVariantDropdownChange,
    handleProductOrderItemChange,
    handleProductPlusCoverChange,
  } = useAddToCart({
    id,
    collection,
    name,
    media,
    variants,
    rating,
    assets: productAssets,
    subVariantAssets,
    autoScroll: true,
  });

  const [openLightbox, setOpenLightbox] = useState(false);
  const [lightboxStartIndex, setLightboxStartIndex] = useState(0);

  const findAndSetLightboxStartIndex = (asset: Asset) => {
    const imageAssets = assets.filter(({ type }) => type === 'image');
    const assetIndex = imageAssets.findIndex(({ src }) => src === asset.src);
    if (!assetIndex && assetIndex !== 0) return;
    setLightboxStartIndex(assetIndex);
  };

  const renderSlide = (
    asset: Asset,
    key: string,
    allowZoom = false,
    size?: string
  ) => {
    const { type, src, webp } = asset;

    switch (type) {
      case 'video':
        return (
          <Slide key={src}>
            <GalleryVideo src={src} className={size} />
          </Slide>
        );
      default:
        return (
          <Slide key={key}>
            {allowZoom && (
              <ImgZoom src={src} webp={webp} alt={name} className={size} />
            )}
            {!allowZoom && (
              <ImgPictureWrapper
                className={size}
                onClick={() => {
                  findAndSetLightboxStartIndex(asset);
                  setOpenLightbox(true);
                }}
              >
                <ImgPicture src={{ png: src, webp }} imgProps={{ alt: '' }} />
              </ImgPictureWrapper>
            )}
          </Slide>
        );
    }
  };

  const handleProductPlusCover = (
    p: ProductOrderItem | null,
    isChecked: boolean
  ) => {
    if (typeof setIsPlusCover !== 'function') return;

    setIsPlusCover(isChecked);
    handleProductPlusCoverChange(p);
  };

  return (
    <Section className={className}>
      <ProductSchemaMarkup productId={id} description={children} />
      <ProductCarousel props={{ showStatus: false, showThumbs: false }}>
        {assets.map((asset, i) => renderSlide(asset, `xs-${i}`, false, 'fit'))}
      </ProductCarousel>
      <ProductContainer>
        {breadcrumbs && <Breadcrumb links={breadcrumbs} />}
        <Wrapper>
          <Gallery>
            <GalleryHero>
              {assets.length > 0 &&
                renderSlide(assets[0], `lg-0`, false, 'fit')}
            </GalleryHero>
            {assets.length > 1 && (
              <GalleryItemContainer>
                {assets.slice(1).map((asset, i) => {
                  const { size } = asset as ProductSectionV2Asset;
                  return (
                    <GalleryItem className={size} key={i}>
                      {renderSlide(asset, `lg-${i}`, false, size)}
                    </GalleryItem>
                  );
                })}
              </GalleryItemContainer>
            )}
          </Gallery>
          <ProductSectionInfo
            id={id}
            product={product}
            name={name}
            description={children}
            rating={rating}
            variants={variants}
            defaultAddOnText={defaultAddOnText}
            addOns={addOns}
            customAddOn={customAddOn}
            version="v2"
            addOnLabel={addOnLabel}
            isLoading={isLoading}
            selectedVariant={selectedVariant}
            selectedAddOn={selectedAddOn}
            selectedCustomAddOn={selectedCustomAddOn}
            selectedProductOrderItem={selectedProductOrderItem}
            selectedProductPlusCover={selectedProductPlusCover}
            addOnConfig={addOnConfig}
            onChangeProductAddOn={handleProductAddOnChange}
            onChangeProductVariantDropdown={handleProductVariantDropdownChange}
            onChangeProductCustomAddOn={handleProductCustomAddOnChange}
            onClickAddToCart={handleAddToCart}
            onChangeProductOrderItem={handleProductOrderItemChange}
            badge={badge}
            plusCover={plusCover}
            onChangePlusCover={handleProductPlusCover}
            isPlusCoverChecked={isPlusCover}
            includedInfoList={includedInfoList}
          />
        </Wrapper>
      </ProductContainer>
      <LightboxGallery
        isOpen={openLightbox}
        startIndex={lightboxStartIndex}
        assets={assets}
        onClose={() => setOpenLightbox(false)}
      />
    </Section>
  );
};

export default ProductSection;
