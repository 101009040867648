import React from 'react';
import styled from 'styled-components';
import { media, Container, Row, Col } from 'styled-bootstrap-grid';

import { Props } from '../../pages/sg/mattresses/index';
import { rem, flatButtonStyle } from '../../styles/utils';
import ProductVariantDropdown from '../common/product-variant-dropdown';
import { ImgDownArrow } from '../assets';
import Spinner from '../common/spinner';

const Section = styled(Container)`
  background-color: white;
  padding: 20px 30px;
`;

const HeadlineContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 100%;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin: 0;
`;

const DownArrow = styled(ImgDownArrow)`
  width: 20px;
  margin-left: 10px;
`;

const CompareContainer = styled.div`
  display: flex;
  align-items: center;
  float: inherit;
  margin-top: 10px;
  ${media.lg`
    float: right;
    margin-top: 0;
  `}
`;

const CompareBtn = styled.button`
  ${flatButtonStyle()}
  margin-left: 25px;
  width: 200px;
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 200px;
`;

const CompareMattressSelectionSection: React.FC<Props> = ({
  product,
  isLoading,
  onChangeProductVariantDropdown,
  onCompare,
}) => {
  return (
    <Section>
      <Row>
        <Col lg={6}>
          <HeadlineContainer>
            <Headline>Mattress: Comparison chart</Headline>
            <DownArrow />
          </HeadlineContainer>
        </Col>
        <Col lg={6}>
          <CompareContainer>
            <DropdownContainer>
              <ProductVariantDropdown
                label=""
                hideStatus={true}
                hidePrice={true}
                variants={product.variants}
                onChange={onChangeProductVariantDropdown}
                version="v2"
              />
              <Spinner isLoading={isLoading} size={30} />
            </DropdownContainer>
            <CompareBtn disabled={isLoading} onClick={onCompare}>
              Compare
            </CompareBtn>
          </CompareContainer>
        </Col>
      </Row>
    </Section>
  );
};

export default CompareMattressSelectionSection;
