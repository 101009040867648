import React from 'react';

import {
  ImgCompleteNightsPillow,
  ImgCompleteNightsBedFrame,
  ImgCompleteNightsMattress,
  ImgCompleteNightsSeresSilkDuvet,
} from '../assets';
import { ProductGrid } from '../../types/component';
import { ProductGridSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const grids: ProductGrid[] = [
  {
    headline: 'Mattress',
    type: 'Mattress',
    body:
      'Ultimate sleeping surface that is both supremely comfortable and incredibly supportive.',
    img: <ImgCompleteNightsMattress />,
    cta: {
      to: SiteUrls.OriginalMattress,
      text: 'Shop Mattress',
    },
  },
  {
    headline: 'Pillows',
    type: 'Pillow',
    body:
      "It's the pillow of your dreams. Made with the revolutionary Woosa Cloud Foam.",
    img: <ImgCompleteNightsPillow />,
    cta: {
      to: SiteUrls.OriginalPillow,
      text: 'Shop Pillows',
    },
  },
  {
    headline: 'Adjustable Base',
    type: 'BedFrame',
    body:
      'The perfect compliment to the Woosa Mattress for an incredible sleep experience.',
    img: <ImgCompleteNightsBedFrame />,
    cta: {
      to: SiteUrls.BedFrame,
      text: 'Shop Adjustable Bed Frames',
    },
  },
  // {
  //   headline: 'Accessories',
  //   body: 'More to make your sleep experience the best one',
  //   img: <ImgProductGridPlaceholder />,
  //   cta: {
  //     to: '/accessories',
  //     text: 'Shop Accessories',
  //   },
  // },
  {
    headline: 'Silk Duvet',
    body:
      'A luxuriously soft, flowy and cosy duvet. Made with grade 6A Mulberry silk.',
    type: 'SeresSilkDuvet',
    img: <ImgCompleteNightsSeresSilkDuvet />,
    cta: {
      to: SiteUrls.SeresSilkDuvet,
      text: 'Shop Silk Duvet',
    },
  },
];

type Props = {
  type:
    | 'Home'
    | 'Mattress'
    | 'Pillow'
    | 'BedFrame'
    | 'SeresSilkDuvet'
    | 'WaterproofMattressProtector';
};

const CompleteYourNightsSection: React.FC<Props> = ({ type }) => {
  const config = {
    Home: ['BedFrame', 'Pillow', 'SeresSilkDuvet'],
    Mattress: ['Pillow', 'BedFrame'],
    Pillow: ['Mattress', 'BedFrame'],
    BedFrame: ['Mattress', 'Pillow'],
    SeresSilkDuvet: ['Mattress', 'BedFrame'],
    WaterproofMattressProtector: ['Mattress', 'BedFrame'],
  };
  const data: ProductGrid[] = grids.filter(g => config[type].includes(g.type));
  return <ProductGridSection grids={data} />;
};

export default CompleteYourNightsSection;
