import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { ProductAccordionSection, IconExpander, Video, Link } from '../common';
import {
  Paragraph,
  UnorderedList,
  ListItem,
  Subtitle,
} from '../common/product-accordion-section';
import { ImgPillowMaterial1, ImgPillowMaterial2 } from '../assets';
import { Accordion } from '../../types/component';
import { SiteUrls } from '../../utils/constants';
import DesignProcessVideo from '../../static/videos/pillow-design-process.mp4';

const CareForWoosa = styled.div`
  margin-top: 40px;
`;

const data: Accordion[] = [
  {
    title: 'Design Process',
    body: (
      <Row>
        <Col xs={12} md={6} mdOrder="last">
          <Video src={DesignProcessVideo} />
        </Col>
        <Col xs={12} md={6}>
          <Paragraph>
            We wanted to create a pillow that is soft and plush, yet supportive
            and breathable. Different materials were explored, but each
            possesses its drawbacks. Memory foam pillows, known for their
            supportiveness, often tend to sleep hot, which may not be ideal for
            those who easily overheat during the night. Latex pillows, on the
            other hand, offer a bouncy feel but often lack the plushness that
            some sleepers prefer. And lastly, while down pillows are undeniably
            soft and plush, they tend to lose their shape over time.
          </Paragraph>
          <Paragraph>
            With the help of our foam specialists in Belgium, we developed the
            Woosa Cloud Foam, a revolutionary material that combines all of our
            favourite properties into a single material. In our newly upgraded
            version, we made it 30% bigger for a larger surface coverage and
            reshaped the Woosa Cloud Foam to suit different sleeping positions
            and cater to both back and side sleepers. The outer fabric has been
            upgraded to our unique Italian-made Microtencel fabric, providing a
            luxurious hand feel and better temperature-regulating properties for
            a cooler night&apos;s sleep.
          </Paragraph>
        </Col>
      </Row>
    ),
  },
  {
    title: 'Material & Care',
    body: (
      <>
        <Row>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgPillowMaterial1 />}
              title="Woosa Cloud Foam"
              content={
                <Paragraph>
                  A modified combination of Memory Foam and High Resiliency Foam
                  with a unique cell structure for added breathability. Soft and
                  fluffy while maintaining high levels of resilient support.
                </Paragraph>
              }
            />
          </Col>
          <Col xs={6} md={3} lg={3}>
            <IconExpander
              icon={<ImgPillowMaterial2 />}
              title="Microtencel Fabric"
              content={
                <Paragraph>
                  Italian-made microtencel that has a higher weave count, is
                  softer, and more breathable than normal tencel fabric.
                </Paragraph>
              }
            />
          </Col>
        </Row>
        <CareForWoosa>
          <Paragraph fontWeight="bold">HOW TO CARE FOR YOUR WOOSA:</Paragraph>
          <UnorderedList>
            <ListItem>Spot clean</ListItem>
            <ListItem>Gentle wash the removable cover separately</ListItem>
            <ListItem>Do not wash the foam inner core</ListItem>
          </UnorderedList>
        </CareForWoosa>
      </>
    ),
  },
  {
    title: 'Shipping, Return, Warranty & Cancellation',
    body: (
      <>
        <Subtitle>Shipping</Subtitle>
        <Paragraph>
          All products are shipped free to your doorstep and inclusive of
          installation.
        </Paragraph>
        <Subtitle>Return</Subtitle>
        <Paragraph>
          All our accessories are applicable for free returns within the first
          30 days of receiving your item. Our accessories include The Woosa
          Pillow, The Seres Silk Duvet, The Mattress Protector. Read more{' '}
          <Link to={SiteUrls.FreeTrial}>here</Link>.
        </Paragraph>
        <Subtitle>Warranty</Subtitle>
        <Paragraph>
          The{' '}
          <Link to={SiteUrls.OriginalPillowWarranty}>
            Woosa Pillow comes with a 3-year warranty
          </Link>{' '}
          against any sagging, sinking, torn foams and poorly stitched covers.
        </Paragraph>
        <Subtitle>Cancellation</Subtitle>
        <Paragraph>
          Free cancellation prior to delivery. Read more{' '}
          <Link to={SiteUrls.FreeTrial}>here</Link>{' '}
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Frequently Asked Questions',
    body: (
      <>
        <Subtitle>What are the Woosa Pillow Dimensions?</Subtitle>
        <Paragraph>
          The Woosa Pillow comes in 3 sizes:
          <br />
          <br />
          Standard (65cm x 45cm x 15cm) - The standard size of the Woosa Pillow
          fits well on most beds and offers ample support for different sleeping
          positions. Whether you&apos;re a back or side sleeper, it provides
          proper alignment and support for your head, neck, and shoulders. Best
          suited with the Terra and the Original Mattress.
          <br />
          <br />
          Long (134.5cm x 40cm x 15cm) - The long size of the Woosa Pillow is
          specifically designed for those who prefer a more extended surface
          area or require additional support for their body alignment.
          <br />
          <br />
          Low (65cm x 45cm x 12.5cm) The Low Woosa Pillow is best suited for
          back sleepers, or side sleepers of smaller built. The Low offers
          better neck alignment and less upward tilt, preventing shoulder
          strains. The low pillow pairs perfectly with The Mysa and Original
          Mattresses.
        </Paragraph>
        <Subtitle>Is the Woosa Pillow made of memory foam?</Subtitle>
        <Paragraph>
          No, the Woosa Pillow is not made of memory foam. Instead, it is
          crafted from our exclusive Woosa Cloud Foam, a proprietary material
          developed specially in Belgium. The Woosa Cloud Foam surpasses
          traditional memory foam in several aspects. It offers a plusher and
          more luxurious feel, providing more responsiveness and effortlessly
          adjusting to your movement throughout the night. This ensures
          consistent comfort and support for all sleeping positions, including
          back and side sleepers. The Woosa Cloud Foam is also highly
          breathable, allowing for optimal airflow and temperature regulation.
        </Paragraph>
        <Subtitle>
          I&apos;m a side/front/back sleeper. Will the Woosa Pillow fit me?
        </Subtitle>
        <Paragraph>
          Yes, the Woosa Pillow&apos;s versatility stems from the unique
          adaptive properties of the Woosa Cloud Foam. These properties ensure
          that the Woosa Pillow is suitable for individuals who prefer various
          sleeping positions, accommodating their specific needs with
          exceptional adaptability and comfort.
        </Paragraph>
        <Subtitle>What is the firmness level of the Woosa Pillow?</Subtitle>
        <Paragraph>
          The firmness level of the Woosa Pillow can be measured at 6.5/10, with
          10 being the firmest. However, the unique Woosa Cloud Foam exhibits
          adaptive properties that respond to pressure from both side and back
          sleeping positions. As more pressure is applied to the pillow, it
          offers a firmer resistance, providing the proper level of support and
          comfort tailored to your individual needs.
        </Paragraph>
        <Subtitle>How is the new version different?</Subtitle>
        <Paragraph>
          In this new version, we made the pillow 30% bigger and refined the
          shape of the pillow to better suit front, side, and back sleeping
          positions. The fabric used for the cover has been upgraded to
          Microtencel fabric for a higher thread count and softer touch.
        </Paragraph>
        <Subtitle>Do I need to use a pillowcase with Woosa Pillow?</Subtitle>
        <Paragraph>
          While the Woosa Pillow features a removable and washable Italian-made
          Microtencel cover, we still encourage using a pillowcase in addition
          to the provided cover. Using a pillowcase not only adds an extra layer
          of protection for your pillow but also allows you to personalise the
          look and style of your bedding. The Woosa Pillow is designed to fit
          all standard pillowcases, making it convenient and easy to find the
          perfect match for your preferred style.
        </Paragraph>

        <Subtitle>
          Are there features in the Woosa Pillow that support side sleepers?
        </Subtitle>
        <Paragraph>
          The Woosa Pillow is beneficial for side sleepers due to several
          features. It utilises the Woosa Cloud Foam, which enables gentle
          contouring to heads of different shapes and weights, offering ample
          support for side sleepers and reducing neck strain. Additionally, it
          is firm yet soft enough not to cause discomfort to the ears. These
          features combined provide exceptional comfort, support, and pressure
          point relief to ensure a good night&apos;s sleep.
        </Paragraph>

        <Subtitle>
          Are there features in the Woosa Pillow that support back sleepers?
        </Subtitle>
        <Paragraph>
          The Woosa Pillow is designed with back sleepers in mind. Because of
          its perfect balance of plush and firmness, it elevates the head and
          supports the neck&apos;s natural curve comfortably, avoiding strains
          or discomfort. Designed with our proprietary Cloud Foam, this pillow
          features an ergonomic and luxurious feel, and remarkable shape
          retention, providing optimal spinal alignment for back sleepers. Even
          when shifting to the side position, the Woosa Pillow offers full
          support.
        </Paragraph>

        <Subtitle>
          Can the Woosa Pillow help alleviate common issues faced by back and
          side sleepers?
        </Subtitle>
        <Paragraph>
          Yes! The Woosa Pillow, crafted with our special Woosa Cloud Foam, is
          specifically designed to address issues commonly encountered by back
          and side sleepers. Back sleepers will value its firm yet gentle
          support of the head, maintaining the spine&apos;s natural curve and
          effectively reducing strain. For side sleepers, the pillow&apos;s 30%
          larger surface area and refined shape fill the shoulder gap
          accurately, distributing weight evenly to prevent discomfort. Merging
          advanced ergonomic design with lush, cushioned fabric, the Woosa
          Pillow elevates the sleep experience and postural health of back and
          side sleepers.
        </Paragraph>

        <Subtitle>
          What are the advantages offered by the Woosa Cloud Foam?
        </Subtitle>
        <Paragraph>
          The Woosa Cloud Foam offers numerous advantages for back and side
          sleepers. It provides an immaculately customised cradle for every
          individual&apos;s head, accommodating variations in shape and weight
          with grace. The robust durability of this foam assures the persistence
          of its initial form, attesting to its premium quality and longevity.
          To top it off, the Woosa Cloud Foam is 100% hypoallergenic, making it
          a preferred choice for those susceptible to allergies. Buy the
          highest-quality ergonomic pillow in Singapore online from{' '}
          <Link to={SiteUrls.Home}>Woosa Sleep</Link> at an affordable price,
          and experience the ultimate union of comfort and healthy sleeping
          habits every night.
        </Paragraph>

        <Subtitle>
          What are the advantages offered by the Italian-made Microtencel
          fabric?
        </Subtitle>
        <Paragraph>
          The Italian-made Microtencel fabric offers manifold advantages. Beyond
          bestowing your pillow with a luxurious feel, akin to resting on a
          cloud, it excels in thermal regulation and moisture-wicking,
          guaranteeing a cool and refreshing slumber. Furthermore, this Italian
          Microtencel fabric is specially designed to enhance the tactile
          experience, lending a soothing touch to the skin. Owing to its
          remarkable attributes, it transforms every contact into a gentle
          embrace, perfectly designed for serene, restful nights.
        </Paragraph>
        <a href={SiteUrls.Faq} target="_blank" rel="noreferrer">
          Read All FAQs
        </a>
      </>
    ),
  },
];

const PillowAccordionSection: React.FC = () => {
  return <ProductAccordionSection data={data} />;
};

export default PillowAccordionSection;
