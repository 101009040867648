import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import SvgHelp from '../../static/images/help.svg';
import { rem, lh } from '../../styles/utils';

const Container = styled.div`
  font-family: var(--font-body);
  font-size: ${rem(12)};
  line-height: ${lh(13.2, 12)};
  font-weight: 400;
`;

const Headline = styled.h4`
  font-size: ${rem(16)};
  line-height: ${lh(17.6, 16)};
  font-weight: 400;
`;

const HelpIcon = styled(SvgHelp)`
  height: 22px;
  width: 22px;
`;

const Tooltip = styled(ReactTooltip)`
  max-width: 300px;
  background: white !important;
  color: var(--font-primary-color) !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  &.show {
    opacity: 1 !important;
  }

  &::before,
  &::after {
    border: 0 !important;
  }
`;

const TooltipLink = styled.a``;

const Paragraph = styled.p``;

type Props = {
  id: string;
  className?: string;
  headline?: React.ReactNode;
  description: React.ReactNode;
  helpIcon?: React.ReactNode;
};

const HelpTooltip: React.FC<Props> = ({
  id,
  className,
  headline,
  description,
  helpIcon,
}) => {
  return (
    <Container className={className}>
      <TooltipLink data-tip data-for={id}>
        {!helpIcon ? <HelpIcon /> : helpIcon}
      </TooltipLink>
      <Tooltip id={id}>
        {headline && <Headline>{headline}</Headline>}
        <Paragraph>{description}</Paragraph>
      </Tooltip>
    </Container>
  );
};

export default HelpTooltip;
