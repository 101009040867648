import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { lh, rem } from '../../../../styles/utils';
import Container from '../../container';
import MattressLayerInfo from './mattress-layer-info';
import MattressLayerAsset from './mattress-layer-asset';

const Section = styled.section`
  font-family: var(--font-body);
  padding: 48px 0;

  ${media.lg`
    padding: 137px 0;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.lg`
    flex-direction: row;
  `}
`;

const ContentWrapper = styled.div`
  ${media.lg`
    flex: 40%;
  `}
`;

const LayerWrapper = styled.div`
  ${media.lg`
    flex: 60%;
  `}
`;

const HeaderWrapper = styled.div`
  display: none;

  ${media.lg`
    display: block
  `}
`;

const HeaderMobileWrapper = styled.div`
  text-align: center;

  ${media.lg`
    display: none;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(28.8, 24)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 10px;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};
  `}
`;

export type Props = {
  className?: string;
  headline: React.ReactNode;
  plusCoverInfo: {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    description: React.ReactNode;
    asset: React.ReactNode;
  };
  infos: {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    description: React.ReactNode;
    transition: {
      initial: {
        x: string;
        y: string;
      };
      active: {
        x: string;
        y: string;
      };
      neutral: {
        x: string;
        y: string;
      };
    };
    asset: React.ReactNode;
    cover?: {
      initial: {
        x: string;
        y: string;
        mdY: string;
      };
      active: {
        x: string;
        y: string;
        mdY: string;
      };
      neutral: {
        x: string;
        y: string;
        mdY: string;
      };
      size: {
        width: string;
        height: string;
      };
    };
  }[];
  isPlusCover?: boolean;
};

const MattressLayer: React.FC<Props> = ({
  className,
  headline,
  infos,
  isPlusCover,
  plusCoverInfo,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currenIndexRef = useRef(currentIndex);

  const renderHeader = () => {
    return (
      <>
        <Headline>{headline}</Headline>
      </>
    );
  };

  const handleNavigateToIndex = (index: number): number => {
    let newIndex = index;

    if (newIndex > infos.length - 1) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = infos.length - 1;
    }

    setCurrentIndex(newIndex);
    return newIndex;
  };

  return (
    <Section className={className}>
      <Container>
        <HeaderMobileWrapper>{renderHeader()}</HeaderMobileWrapper>
        <Wrapper>
          <ContentWrapper>
            <HeaderWrapper>{renderHeader()}</HeaderWrapper>
            <MattressLayerInfo
              currentIndexRef={currenIndexRef}
              currentIndex={currentIndex}
              onNavigate={handleNavigateToIndex}
              infos={infos}
              plusCoverInfo={isPlusCover ? plusCoverInfo : undefined}
            />
          </ContentWrapper>
          <LayerWrapper>
            <MattressLayerAsset
              currentIndexRef={currenIndexRef}
              currentIndex={currentIndex}
              onNavigate={handleNavigateToIndex}
              infos={infos.map(({ transition, asset, cover }) => ({
                ...transition,
                asset,
                cover,
              }))}
              plusCoverAsset={isPlusCover ? plusCoverInfo.asset : undefined}
            />
          </LayerWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default MattressLayer;
