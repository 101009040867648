import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Row as GridRow, Col, media } from 'styled-bootstrap-grid';

import GridContainer from './container';
import DisplayPic from './display-pic';
import Carousel from './carousel';
import ReviewStars from './review-stars';
import CTA from './cta';
import { ReviewGrid } from '../../types/component';
import { Breakpoints } from '../../styles/enums';
import { rem, mediaQueryUpto } from '../../styles/utils';

const Section = styled.section`
  width: 100%;
  background-color: var(--desert-storm);
  padding: 40px 0;

  ${media.md`
    background-color: transparent;
    margin-bottom: 20px;
  `}

  ${media.lg`
    padding: 0;
    margin-bottom: 100px;
  `}
`;

const Container = styled(GridContainer)`
  text-align: center;
`;

const Row = styled(GridRow)`
  ${mediaQueryUpto(Breakpoints.XL, 'display: none')}
  margin-bottom: 60px;
`;

const ReviewCarousel = styled(Carousel)`
  .carousel {
    &-slider {
      overflow: visible;
    }

    .slider-wrapper {
      overflow: visible;
    }
  }

  ${media.xl`
    display: none;
  `}
`;

const Headline = styled.h2`
  margin-top: 0;
  margin-bottom: 40px;
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-size: ${rem(24)};
  font-weight: normal;
  text-align: center;

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const Grid = styled.div`
  background-color: var(--desert-storm-darker);
  padding: 20px;
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-weight: normal;
  text-align: left;
  min-height: 276px;
  margin-right: 30px;

  ${media.xl`
    margin: 0;
    min-height: 300px;
  `}
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Name = styled.span`
  font-size: ${rem(18)};
`;

const Content = styled.p`
  font-size: ${rem(14)};
  line-height: 1.3;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

type Props = {
  id?: string;
  className?: string;
  allReviewsUrl?: string;
  filters?: {
    limit?: number;
    id?: string;
  };
};

type ReviewNode = {
  node: {
    id: string;
    user: {
      displayPic?: string;
      firstName: string;
      lastName: string;
    };
    rating: number;
    content: string;
    product: {
      id: string;
    };
  };
};

enum CenterSlidePercentage {
  XS = 95,
  MD = 40,
  LG = 30,
}

const DEFAULT_LIMIT = 4;

const ReviewGridSection: React.FC<Props> = ({
  id,
  className,
  allReviewsUrl,
  filters,
}) => {
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(
    CenterSlidePercentage.XS
  );
  const [reviews, setReviews] = useState<ReviewGrid[]>([]);

  const {
    allReviews: { edges },
  } = useStaticQuery(graphql`
    query {
      allReviews(
        filter: { display: { eq: true } }
        sort: { fields: submittedOn, order: DESC }
      ) {
        edges {
          node {
            content
            display
            id
            media
            product {
              id
              name
            }
            rating
            submittedOn
            user {
              displayPic
              firstName
              lastName
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const newReviewData: ReviewGrid[] = edges
      .filter(({ node }: ReviewNode) => {
        if (!filters) return true;
        if (filters && !filters.id) return true;
        if (!node.product) return false;
        return node.product.id === filters.id;
      })
      .slice(0, filters && filters.limit ? filters.limit : DEFAULT_LIMIT)
      .map(({ node }: ReviewNode) => {
        return {
          id: node.id,
          displayPic: node.user.displayPic,
          name: `${node.user.firstName} ${node.user.lastName}`,
          rating: node.rating,
          content: node.content,
        };
      });
    setReviews(newReviewData);
  }, [edges]);

  useEffect(() => {
    const updateCenterSlidePercentage = () => {
      if (document.documentElement.clientWidth > Breakpoints.XL) return;
      if (document.documentElement.clientWidth < Breakpoints.MD) {
        setCenterSlidePercentage(CenterSlidePercentage.XS);
      } else if (document.documentElement.clientWidth < Breakpoints.LG) {
        setCenterSlidePercentage(CenterSlidePercentage.MD);
      } else {
        setCenterSlidePercentage(CenterSlidePercentage.LG);
      }
    };

    window.addEventListener('resize', updateCenterSlidePercentage);
    updateCenterSlidePercentage();

    return () =>
      window.removeEventListener('resize', updateCenterSlidePercentage);
  }, []);

  const renderReview = () => {
    return reviews.map(({ displayPic, name, rating, content }, key) => (
      <Grid key={key}>
        <Profile>
          <DisplayPic src={displayPic} />
          <Name>{name}</Name>
        </Profile>
        <ReviewStars isYellow hideText={true} rating={rating} />
        <Content dangerouslySetInnerHTML={{ __html: content }} />
      </Grid>
    ));
  };

  if (reviews.length === 0) return null;

  return (
    <Section id={id || 'reviews'} className={className}>
      <Container>
        <Headline>Reviews</Headline>
        <Row>
          {renderReview().map((review, key) => (
            <Col lg={3} key={key}>
              {review}
            </Col>
          ))}
        </Row>
        <ReviewCarousel
          props={{
            showThumbs: false,
            showStatus: false,
            centerMode: true,
            showIndicators: false,
            centerSlidePercentage,
          }}
        >
          {renderReview()}
        </ReviewCarousel>
        {allReviewsUrl && (
          <CTA to={allReviewsUrl} type="outline">
            Read all reviews
          </CTA>
        )}
      </Container>
    </Section>
  );
};

export default ReviewGridSection;
