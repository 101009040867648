import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { flatButtonStyle } from '../../styles/utils';

export const wizardButtonStyle = css`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: block;

  ${media.md`
    width: auto;
    margin-left: 0;
    margin-right: 0;
  `}
`;

const WizardButton = styled.button`
  ${flatButtonStyle}
  ${wizardButtonStyle}
`;

export default WizardButton;
