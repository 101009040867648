import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Overlay from './overlay';

const Container = styled(Overlay)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  className?: string;
  isLoading?: boolean;
  size?: number;
};

const Spinner: React.FC<Props> = ({ className, isLoading, size }) => {
  if (!isLoading) return null;

  if (!size) {
    size = 50;
  }

  return (
    <Container className={className} isActive={true}>
      <Loader type="Oval" color="#000000" height={size} width={size} />
    </Container>
  );
};

export default Spinner;
