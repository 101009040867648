import React from 'react';

import { LateralSection } from '../../common';
import { BgMattressLateral } from '../../assets';

const MattressLateralSection: React.FC = () => {
  return (
    <LateralSection
      BgSection={BgMattressLateral}
      headline="It’s time to revolutionise your sleep."
      cta={{ text: 'Choose a size' }}
    />
  );
};

export default MattressLateralSection;
