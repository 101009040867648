import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Post } from '../../types/post';
import PostTagList from './post-tag-list';
import Unorphan from './unorphan';
import { toMoment } from '../../utils';
import { rem, lh } from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';
import Link from './link';

const Container = styled.div`
  width: 100%;
`;

const Anchor = styled(Link)`
  text-decoration: none;
  display: block;
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 10px;

  ${media.lg`
    margin-bottom: 20px;
  `}
`;

const Title = styled.h3`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(46.2, 42)};
  color: var(--font-primary-color);
  margin: 0 0 10px;

  ${media.lg`
    font-size: ${rem(42)};
  `}
`;

const Author = styled.span`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${lh(26.4, 24)};
  color: var(--font-primary-color);
  font-style: italic;
  display: block;
  margin-bottom: 15px;

  ${media.lg`
    margin-bottom: 30px;
    font-size: ${rem(24)};
  `}
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TagList = styled(PostTagList)``;

const Date = styled.span`
  margin-left: 20px;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${lh(16, 16)};
  color: var(--cornflower-blue);
`;

type Props = {
  className?: string;
  post: Post;
};

const PostSuggestCard: React.FC<Props> = ({ className, post }) => {
  return (
    <Container className={className}>
      <Anchor to={`${SiteUrls.Post}/${post.slug}`}>
        <Image src={post.thumbnail} alt={post.title} />
        <Title>
          <Unorphan>{post.title}</Unorphan>
        </Title>
        <Author>by {post.author?.name}</Author>
      </Anchor>
      <Footer>
        <TagList post={post} />
        <Date>{toMoment(post.publishedOn).format('D MMMM YYYY')}</Date>
      </Footer>
    </Container>
  );
};

export default PostSuggestCard;
