import React from 'react';
import styled from 'styled-components';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled(ToastContainer)``;

type Props = {
  className?: string;
  toastProps?: ToastContainerProps;
};

const Toastr: React.FC<Props> = ({ className, toastProps }) => (
  <StyledToastContainer {...toastProps} className={className} />
);

export default Toastr;
