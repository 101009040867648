import React from 'react';
import styled, { css } from 'styled-components';

import {
  ImgReviewsProfile1,
  ImgReviewsProfile2,
  ImgReviewsProfile3,
  ImgReviewsProfile4,
} from '../assets';

const commonStyle = css`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 65px;
  height: 65px;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
`;

const Default = styled.img`
  ${commonStyle}
`;

const Random1 = styled(ImgReviewsProfile1)`
  ${commonStyle}
`;
const Random2 = styled(ImgReviewsProfile2)`
  ${commonStyle}
`;
const Random3 = styled(ImgReviewsProfile3)`
  ${commonStyle}
`;
const Random4 = styled(ImgReviewsProfile4)`
  ${commonStyle}
`;

const random = [Random1, Random2, Random3, Random4];

type Props = {
  src?: string;
  className?: string;
};

const DisplayPic: React.FC<Props> = ({ src, className }) => {
  if (src) {
    return (
      <Wrapper>
        <Default className={className} src={src} alt="" role="presentation" />
      </Wrapper>
    );
  }

  const randomNum = Math.floor(Math.random() * Math.floor(4));
  const Pic = random[randomNum];

  return (
    <Wrapper>
      <Pic className={className} />
    </Wrapper>
  );
};

export default DisplayPic;
