import React, { useState } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { rem } from '../../styles/utils';
import SvgDropdownArrow from '../../static/images/dropdown-arrow.svg';

const DropdownArrow = styled(SvgDropdownArrow)`
  width: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

const Label = styled.h3`
  font-size: ${rem(14)};
  line-height: 1.2;
  display: block;
  margin: 0;
  font-weight: bold;

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
    line-height: 1.3125;
  `}
`;

const Bar = styled.div`
  font-family: var(--font-body);
  position: relative;
  font-weight: normal;
  font-size: ${rem(14)};
  display: flex;
  align-items: center;

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

const List = styled.ul<{ isActive: boolean }>`
  will-change: max-height;
  position: absolute;
  padding: 0;
  margin: 0;
  max-height: ${({ isActive }) => (isActive ? '1000px' : 0)};
  overflow-y: hidden;
  transition: max-height 0.3s ease-in-out;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const Item = styled.li`
  display: flex;
  width: 100%;
  background: white;
  padding: 15px;
  border: 0.25px solid var(--celeste);
  border-top-width: 0;
  cursor: pointer;
`;

const SelectedItem = styled(Item)`
  border-top-width: 0.25px;
  position: relative;
  padding-right: 40px;
`;

type Option = {
  label: string;
  value: unknown;
};

type Props = {
  className?: string;
  initialOption?: Option;
  options: Option[];
  onChange?: (selectedOption?: Option) => void;
};

const CustomDropdown: React.FC<Props> = ({
  className,
  initialOption,
  options,
  onChange,
}) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(initialOption);

  return (
    <Bar className={className}>
      <SelectedItem onClick={() => setActive(!active)}>
        <Label>{selected?.label || ''}</Label>
        <DropdownArrow />
      </SelectedItem>
      <List className="custom-dropdown-list" isActive={active}>
        {options
          .filter(opt => opt.label !== selected?.label)
          .map((opt, key) => {
            return (
              <Item
                key={key}
                onClick={() => {
                  setSelected(opt);
                  if (typeof onChange === 'function') onChange(opt);
                  setActive(false);
                }}
              >
                <Label>{opt.label}</Label>
              </Item>
            );
          })}
      </List>
    </Bar>
  );
};

export default CustomDropdown;
