import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/gatsby';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import styled from 'styled-components';

import {
  createPaymentIntent,
  createPaymentIntentByPaymentToken,
  logEvent,
} from '../../utils/api';
import { flatButtonStyle } from '../../styles/utils';
import { PaymentSubType } from '../../types/order';

const cardStyle = {
  style: {
    base: {
      color: '#0d1e2a',
      fontFamily: 'Acumin, sans-serif',
      fontWeight: 'normal',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {},
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const Button = styled.button`
  ${flatButtonStyle}
`;

const StyledCardElement = styled.div`
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
`;

type Props = {
  onInputChange?: () => void;
  onError?: (error: string) => void;
  afterPayment?: (result: boolean, data: unknown | null) => void;
  paymentToken: string | null;
  canPay: boolean;
  accessToken?: string;
  disableButton?: boolean;
  orderId?: string;
  paymentAttempts?: number;
  paymentSubType: PaymentSubType;
};

const StripeForm: React.FC<Props> = ({
  onInputChange,
  afterPayment,
  onError,
  paymentToken,
  canPay,
  accessToken,
  disableButton,
  orderId,
  paymentAttempts,
  paymentSubType,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = async (event: StripeCardElementChangeEvent) => {
    const isValid = !event.empty && !event.error;

    if (typeof onError === 'function' && !isValid) {
      onError(event.error?.message || '');
    }

    if (typeof onInputChange === 'function' && isValid) {
      onInputChange();
    }
  };

  const makeCardPayment = async () => {
    if (!stripe || !elements || !paymentToken || isProcessing) return;

    try {
      setIsProcessing(true);

      const clientSecret = await (accessToken
        ? createPaymentIntent(accessToken, paymentToken, paymentSubType)
        : createPaymentIntentByPaymentToken(paymentToken, paymentSubType));
      if (!clientSecret) throw 'Invalid clientSecret';

      const card = elements.getElement(CardElement);
      if (!card) throw 'Card element is empty';

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card },
      });

      if (payload.paymentIntent) {
        if (typeof afterPayment === 'function') {
          afterPayment(true, payload.paymentIntent);
        }
      } else {
        const defaultErrorMessage =
          'Payment unsuccessful, unexpected error occurred, please try again.';
        const error = payload.error
          ? payload.error.message || defaultErrorMessage
          : defaultErrorMessage;

        Sentry.captureException(payload.error, {
          extra: {
            stripePaymentIntent: payload.paymentIntent,
          },
        });
        logEvent('error', error, { orderId, stripe: payload });

        throw error;
      }
    } catch (error) {
      Sentry.captureException(error);
      if (typeof onError === 'function') {
        onError(error as string);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!canPay) return;
      await makeCardPayment();
    })();
  }, [paymentToken, canPay, paymentAttempts]);

  return (
    <form>
      <StyledCardElement>
        <CardElement
          className="stripe-card-element"
          options={cardStyle}
          onChange={handleChange}
        />
      </StyledCardElement>
      {!disableButton && (
        <Button
          className="stripe-card-element-btn"
          type="button"
          onClick={async event => {
            event.preventDefault();
            await makeCardPayment();
          }}
        >
          {isProcessing ? 'Processing' : 'Pay Now'}
        </Button>
      )}
    </form>
  );
};

export default StripeForm;
