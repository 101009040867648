import React from 'react';
import { PRODUCT_EGYPTIAN_COTTON_PILLOWCASE_SET } from 'gatsby-env-variables';

import { ProductReviewHeroSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const EgyptianCottonPillowcaseSetReviewHeroSection: React.FC = () => {
  return (
    <ProductReviewHeroSection
      productId={PRODUCT_EGYPTIAN_COTTON_PILLOWCASE_SET}
      allReviewsUrl={SiteUrls.EgyptianCottonPillowcaseSetReview}
    />
  );
};

export default EgyptianCottonPillowcaseSetReviewHeroSection;
