import React from 'react';
import styled from 'styled-components';

const Select = styled.select`
  font-family: var(--font-body);
  width: 100%;
  border: 0;
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  padding: 15px;
`;

type Props = {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
};

const SelectInput: React.FC<Props> = ({
  className,
  placeholder,
  value,
  onChange,
  options,
}) => {
  return (
    <Select
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    >
      {options.map((value, key) => (
        <option key={key} value={value}>
          {value}
        </option>
      ))}
    </Select>
  );
};

export default SelectInput;
