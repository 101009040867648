import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { HeroIntro } from '../common';
import AboutVideo from '../../static/videos/about-video.mp4';

const Intro = styled(HeroIntro)`
  .hero-intro {
    height: 200px;

    ${media.md`
      height: 300px;
    `}

    ${media.lg`
      height: 450px;
    `}

    ${media.xl`
      height: 700px;
    `}
  }

  .hero-intro-content {
    padding: 30px 0;

    ${media.md`
      padding: 50px 0;
    `}

    ${media.lg`
      padding: 100px 0;
    `}
  }
`;

const AboutIntroSection: React.FC = () => {
  return (
    <Intro
      backgroundVideoSrc={AboutVideo}
      overText={<>Obsessed with comfort and&nbsp;quality</>}
      body={
        <>
          Everyone should be entitled to great quality comfort. So we work with
          the best manufacturers in the world, to sell the best possible product
          at the price it should cost. No cutting corners, no excessive add-ons
          you don&apos;t need, just honest materials and good craftsmanship.
        </>
      }
    />
  );
};

export default AboutIntroSection;
