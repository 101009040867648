import React from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import useGraphQLGetProduct from '../../hooks/use-graphql-get-product';
import useProductFromPrice from '../../hooks/use-product-from-price';
import { lh, rem } from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';
import Picture, { Props as PictureProps } from './picture';
import Link from './link';

const Container = styled.div`
  background-color: white;
`;

const ImgContainer = styled.div`
  img,
  picture {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  padding: 16px 21px;
  display: flex;
  align-items: center;

  ${media.lg`
    padding: 24px 31px; 
  `}
`;

const ProductName = styled.span`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${lh(22, 16)};
  color: var(--font-primary-color);
  margin-right: auto;
  text-align: left;

  ${media.xl`
    font-size: ${rem(24)};
    line-height: ${lh(32, 24)};
  `}
`;

const ProductPrice = styled.span`
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(11)};
  line-height: ${lh(14, 11)};
  color: var(--font-primary-color);

  ${media.md`
    min-width: auto;
  `}

  ${media.xl`
    font-size: ${rem(16)};
    line-height: ${lh(16, 21)};
    min-width: 100px;  
  `}
`;

const Navigate = styled(Link)`
  text-decoration: none;
`;

type Props = {
  className?: string;
  productId: string;
  picture: PictureProps;
  to: SiteUrls;
};

const UpsellCard: React.FC<Props> = ({ className, productId, picture, to }) => {
  const product = useGraphQLGetProduct(productId);
  const price = useProductFromPrice(productId);

  return (
    <Navigate to={to}>
      <Container className={className}>
        <ImgContainer>
          <Picture {...picture} />
        </ImgContainer>
        <InfoContainer>
          <ProductName>{product.name}</ProductName>
          <ProductPrice>{price}</ProductPrice>
        </InfoContainer>
      </Container>
    </Navigate>
  );
};

export default UpsellCard;
