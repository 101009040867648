import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { ENABLE_ATOME } from 'gatsby-env-variables';
import moment from 'moment';

import { rem } from '../../../styles/utils';
import PriceLabel from '../price-label';
import SvgDropdownArrow from '../../../static/images/dropdown-arrow.svg';
import { Props } from './';
import AtomeLabel from '../atome-label';

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
`;

const Label = styled.label`
  font-weight: bold;
  font-size: ${rem(12)};
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: block;

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

const Bar = styled.div`
  position: relative;
  font-weight: normal;
  font-size: ${rem(14)};
  display: flex;
  align-items: center;

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

const TypeLabel = styled.span``;

const DropdownArrow = styled(SvgDropdownArrow)`
  width: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

const List = styled.ul<{ isActive: boolean }>`
  will-change: max-height;
  position: absolute;
  padding: 0;
  margin: 0;
  max-height: ${({ isActive }) => (isActive ? '1000px' : 0)};
  overflow-y: hidden;
  transition: max-height 0.3s ease-in-out;
  top: 100%;
  left: 0;
  width: 100%;
`;

const Item = styled.li`
  display: flex;
  width: 100%;
  background: white;
  padding: 15px;
  border: 0.25px solid var(--celeste);
  border-top-width: 0;
  cursor: pointer;
`;

const SelectedItem = styled(Item)`
  border-top-width: 0.25px;
  position: relative;
  padding-right: 40px;
`;

const QuantityLabel = styled.p<{ isOutOfStock: boolean }>`
  color: ${({ isOutOfStock }) => (isOutOfStock ? 'red' : 'var(--leather)')};
  font-size: ${rem(10)};

  ${media.lg`
    font-size: ${rem(12)};
  `}
`;

const Atome = styled(AtomeLabel)`
  margin-top: -8px;
`;

export const ProductVariantDropdown: React.FC<Props> = ({
  label,
  variants,
  onChange,
}) => {
  const [selected, setSelected] = useState(variants[0]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setSelected(variants[0]);
  }, [variants]);

  const renderStatus = () => {
    if (selected.status === 'Pre-Order') {
      if (selected.statusDate && selected.statusDate._seconds) {
        return `PRE-ORDER: Earliest shipment ${moment
          .unix(selected.statusDate._seconds)
          .format('Do MMM YYYY')}`;
      } else {
        return 'Pre-order now!';
      }
    } else {
      return selected.status;
    }
  };

  return (
    <Container>
      <Label>{label}</Label>
      <Bar>
        <SelectedItem onClick={() => setActive(!active)}>
          <TypeLabel>{selected.type}</TypeLabel>
          <PriceLabel price={selected.prices[0]} />
          <DropdownArrow />
        </SelectedItem>
        <List className="product-variant-dropdown-list" isActive={active}>
          {variants
            .filter(opt => opt.type !== selected.type)
            .map((opt, key) => {
              return (
                <Item
                  key={key}
                  onClick={() => {
                    setSelected(opt);
                    if (typeof onChange === 'function') onChange(opt);
                    setActive(false);
                  }}
                >
                  <TypeLabel>{opt.type}</TypeLabel>
                  <PriceLabel price={opt.prices[0]} />
                </Item>
              );
            })}
        </List>
      </Bar>
      {ENABLE_ATOME && <Atome amount={selected.prices[0].amount} />}
      <QuantityLabel isOutOfStock={selected.status === 'Out of Stock'}>
        {renderStatus()}
      </QuantityLabel>
    </Container>
  );
};
