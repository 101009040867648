import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { rem } from '../../styles/utils';

const Container = styled.div`
  background-color: var(--pearl-bush);
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(14)};
  color: var(--font-primary-color);
  line-height: 1.2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  text-align: center;
`;

type Props = {
  className?: string;
  children: React.ReactNode | React.ReactNodeArray;
};

const AnnouncementBar: React.FC<Props> = ({ className, children }) => {
  return (
    <Container className={classNames('announcement-bar', className)}>
      {children}
    </Container>
  );
};

export default AnnouncementBar;
