import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import classNames from 'classnames';

import { Post } from '../../types/post';
import PostTagList from './post-tag-list';
import Unorphan from './unorphan';
import { toMoment } from '../../utils';
import { SiteUrls } from '../../utils/constants';
import { rem, lh } from '../../styles/utils';
import Link from './link';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  ${media.md`
    height: 130px;
  `}

  ${media.lg`
    width: 80%;
    height: 200px;
    margin: 0 auto 80px;
  `}
`;

const Eyebrow = styled.strong`
  font-weight: 600px;
  text-transform: uppercase;
  font-size: ${rem(16)};
  line-height: ${lh(24, 18)};
  letter-spacing: 1px;
  color: var(--cornflower-blue);
  margin-bottom: 12px;
  display: block;

  ${media.lg`
    font-size: ${rem(18)};  
  `}
`;

const titleCss = css`
  font-family: var(--font-header);
  font-weight: 400;
  color: var(--font-primary-color);
`;

const FeaturedTitle = styled.h2`
  ${titleCss}
  font-size: ${rem(32)};
  line-height: ${lh(66, 60)};
  margin: 0 0 16px;

  ${media.lg`
    font-size: ${rem(60)};
  `}
`;

const Title = styled.h2`
  ${titleCss}
  font-size: ${rem(24)};
  line-height: ${lh(46.2, 42)};
  margin: 0 0 16px;

  ${media.lg`
    font-size: ${rem(42)};
  `}
`;

const authorCss = css`
  font-family: var(--font-header);
  font-weight: 400;
  font-style: italic;
  display: block;
  color: var(--font-primary-color);
`;

const FeaturedAuthor = styled.span`
  ${authorCss}
  font-size: ${rem(18)};
  line-height: ${lh(33, 30)};
  margin: 0 0 30px;

  ${media.lg`
    font-size: ${rem(30)};
  `}
`;

const Author = styled.span`
  ${authorCss}
  margin-bottom: 30px;
  font-size: ${rem(16)};
  line-height: ${lh(26.4, 24)};
  margin: 0 0 16px;

  ${media.lg`
    font-size: ${rem(24)};
  `}
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Date = styled.span`
  margin-left: 20px;
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${lh(16, 16)};
  color: var(--cornflower-blue);
`;

const Anchor = styled(Link)`
  text-decoration: none;
  display: block;
`;

const TagList = styled(PostTagList)``;

const Image = styled.img`
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  font-family: var(--font-body);
  text-align: left;

  &.featured {
    text-align: center;

    ${Footer} {
      justify-content: center;
    }

    ${Wrapper} {
      margin-bottom: 30px;
    }
  }

  ${media.lg`
    &.featured {
      ${Wrapper} {
        width: 100%;
      }
    }
  `}
`;

type Props = {
  className?: string;
  post: Post;
  isFeatured?: boolean;
};

const PostCard: React.FC<Props> = ({ post, className, isFeatured }) => {
  const TitleComponent = isFeatured ? FeaturedTitle : Title;
  const AuthorComponent = isFeatured ? FeaturedAuthor : Author;

  return (
    <Container className={classNames(className, { featured: isFeatured })}>
      <Wrapper>
        <Anchor to={`${SiteUrls.Post}/${post.slug}`}>
          {isFeatured && <Eyebrow>Featured</Eyebrow>}
          <TitleComponent>
            <Unorphan>{post.title}</Unorphan>
          </TitleComponent>
          <AuthorComponent>by {post.author?.name}</AuthorComponent>
        </Anchor>
        <Footer>
          <TagList post={post} />
          <Date>{toMoment(post.publishedOn).format('D MMMM YYYY')}</Date>
        </Footer>
      </Wrapper>
      <Anchor to={`${SiteUrls.Post}/${post.slug}`}>
        <Image src={post.thumbnail} alt={post.title} />
      </Anchor>
    </Container>
  );
};

export default PostCard;
