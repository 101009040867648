import { useEffect, useState } from 'react';
import { minBy } from 'lodash';

import { Price, Variant } from '../types/product';
import { currencyFormat } from '../utils';
import { getProductById } from '../utils/api';
import useGraphQLGetProduct from './use-graphql-get-product';

const computeMinPrice = (variants: Variant[], defaultPrice: string): string => {
  if (!Array.isArray(variants)) return defaultPrice;

  const price = variants.reduce<Price | undefined>((minPrice, variant) => {
    const newMinPrice = minBy(variant.prices, price => price.amount);
    if (newMinPrice && !minPrice) {
      return newMinPrice;
    }
    if (newMinPrice && minPrice) {
      return minPrice.amount < newMinPrice.amount ? minPrice : newMinPrice;
    }
    return minPrice;
  }, undefined);

  if (!price) return defaultPrice;

  return currencyFormat(price.amount);
};

export default function useProductFromPrice(productId: string): string {
  const defaultPrice = computeMinPrice(
    useGraphQLGetProduct(productId).variants || [],
    ''
  );
  const [price, setPrice] = useState<string>(defaultPrice);

  useEffect(() => {
    (async function () {
      const result = await getProductById(productId);
      setPrice(computeMinPrice(result.variants, defaultPrice));
    })();
  }, [productId]);

  return `From ${price}`;
}
