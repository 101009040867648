import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { media } from 'styled-bootstrap-grid';

import { Post } from '../../types/post';
import { PostContainer, PostCard } from '../common';
import { rem, lh } from '../../styles/utils';
import { isSlug } from '../../utils';

const Section = styled.section`
  background-color: var(--mystic);
  padding: 40px 0;

  ${media.lg`
    padding: 160px 0 70px;  
  `}
`;

const Container = styled(PostContainer)``;

const Headline = styled.h1`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(28)};
  line-height: ${lh(45.2, 42)};
  color: var(--font-primary-color);
  margin: 0 0 40px;
  text-align: center;

  ${media.lg`
    margin: 0 0 80px;
    font-size: ${rem(42)};
  `}
`;

type Props = {
  categories?: string[];
  posts: Post[];
};

const BlogFeaturedPostSection: React.FC<Props> = ({ categories, posts }) => {
  // find the first featured post
  const featuredPost = posts.find(p => p.isFeatured);

  return (
    <Section>
      <Container>
        <Headline>Woosa Reads</Headline>
        {featuredPost && (categories || []).length === 0 && (
          <PostCard post={featuredPost} isFeatured />
        )}
      </Container>
    </Section>
  );
};

export default BlogFeaturedPostSection;
