import React from 'react';
import {
  PRODUCT_PILLOW_ID,
  PRODUCT_SERES_SILK_DUVET_ID,
  PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID,
} from 'gatsby-env-variables';
import styled from 'styled-components';

import { UpsellGridSection } from '../common';
import { SiteUrls } from '../../utils/constants';
import PicturePng1 from '../../assets/pillow-1-ow.png';
import PictureWebp1 from '../../assets/pillow-1-ow.webp';
import PicturePng2 from '../../assets/seres-silk-duvet-1-ow.png';
import PictureWebp2 from '../../assets/seres-silk-duvet-1-ow.webp';
import PicturePng3 from '../../assets/waterproof-mattress-protector-1-ow.png';
import PictureWebp3 from '../../assets/waterproof-mattress-protector-1-ow.webp';
import { media } from 'styled-bootstrap-grid';

const Section = styled(UpsellGridSection)`
  margin-bottom: 40px;

  ${media.lg`
    margin-bottom: 90px;
  `}
`;

const HomeUpsellGridSection: React.FC = () => {
  return (
    <Section
      headline="Complete your nights with Woosa"
      products={[
        {
          id: PRODUCT_PILLOW_ID,
          picture: {
            src: { png: PicturePng1, webp: PictureWebp1 },
          },
          to: SiteUrls.OriginalPillow,
        },
        {
          id: PRODUCT_SERES_SILK_DUVET_ID,
          picture: {
            src: { png: PicturePng2, webp: PictureWebp2 },
          },
          to: SiteUrls.SeresSilkDuvet,
        },
        {
          id: PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID,
          picture: {
            src: { png: PicturePng3, webp: PictureWebp3 },
          },
          to: SiteUrls.WaterproofMattressProtector,
        },
      ]}
    />
  );
};

export default HomeUpsellGridSection;
