import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { SiteUrls } from '../../utils/constants';
import DefaultLink from './link';
import SVGBreadcrumbHomeIcon from '../../static/images/breadcrumb-home-icon.svg';
import { lh, rem } from '../../styles/utils';

const Container = styled.div`
  display: none;
  font-family: var(--font-body);
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${lh(20, 14)};

  ${media.lg`
    display: block;
  `}
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;

const HomeIcon = styled(SVGBreadcrumbHomeIcon)``;

const Chevron = styled.i`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 1px solid transparent;
  border-radius: 100px;
  margin-left: 22px;

  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid var(--cornflower-blue);
    border-right: 1px solid var(--cornflower-blue);
    transform: rotate(-45deg);
    right: 6px;
    top: 4px;
  }
`;

const Item = styled.li`
  display: flex;
  margin-right: 22px;
  color: var(--cornflower-blue);

  &:last-child {
    margin-right: 0;
    color: var(--font-primary-color);
  }
`;

const Link = styled(DefaultLink)`
  color: var(--cornflower-blue);
  text-decoration: none;

  &:hover {
    color: var(--font-primary-color);
  }
`;

export type BreadcrumbLink = {
  urlPath?: SiteUrls;
  label: React.ReactNode;
};

type Props = {
  className?: string;
  links: BreadcrumbLink[];
};

const Breadcrumb: React.FC<Props> = ({ className, links }) => {
  const renderItem = (
    link: BreadcrumbLink,
    shouldRenderChevron: boolean,
    index: number
  ) => {
    const { urlPath, label } = link;
    const classNames: string[] = [];

    if (shouldRenderChevron) {
      classNames.push('has-chevron');
    }

    return (
      <Item key={index} className={classNames.join(' ')}>
        {urlPath && <Link to={urlPath}>{label}</Link>}
        {!urlPath && label}
        {shouldRenderChevron && <Chevron />}
      </Item>
    );
  };

  const mainLinks = [
    {
      link: {
        urlPath: SiteUrls.Home,
        label: <HomeIcon />,
      },
      shouldRenderChevron: links.length > 0,
    },
    ...links.map((link, idx) => ({
      link,
      shouldRenderChevron: idx !== links.length - 1,
    })),
  ];

  return (
    <Container className={className}>
      <List>
        {mainLinks.map(({ link, shouldRenderChevron }, index) =>
          renderItem(link, shouldRenderChevron, index)
        )}
      </List>
    </Container>
  );
};

export default Breadcrumb;
