import React from 'react';
import styled from 'styled-components';
import { Row as GridRow, Col as GridCol, media } from 'styled-bootstrap-grid';
import { useInView } from 'react-intersection-observer';

import { lh, rem } from '../../../styles/utils';
import { Benefit } from '../../../types/component';
import { Config } from '../../../styles/enums';
import Unorphan from '../unorphan';

const Row = styled(GridRow)`
  font-family: var(--font-body);

  ${media.md`
    padding: 20px 0;
  `}

  ${media.xl`
    padding: 40px 0;
  `}
`;

const Col = styled(GridCol)`
  ${media.lg`
    padding-left: 30px;
    padding-right: 30px;
  `}
`;

const Headline = styled.h3`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  line-height: ${lh(28.8, 24)};
  font-weight: 400;
  color: var(--font-primary-color);
  margin: 0 0 15px 0;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};

  `}
`;

const Eyebrow = styled.h4`
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  color: var(--santa-fe);
  font-size: ${rem(12)};
  line-height: ${lh(14.4, 12)};
  margin-bottom: 12px;
  margin-top: 0;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
    margin-bottom: 5px;
  `}
`;

const Body = styled.p`
  color: var(--xanadu);
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${lh(16.8, 14)};

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)}; 
  `}
`;

const ImgContainer = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  ${Row} & {
    transform: translateX(-100%);
  }

  &.active {
    opacity: 1;

    ${Row} & {
      transform: translateX(0);
    }
  }
`;

const Content = styled.div`
  padding: ${Config.CONTAINER_PADDING}px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  ${Row} & {
    transform: translateX(100%);
  }

  &.active {
    opacity: 1;

    ${Row} & {
      transform: translateX(0);
    }
  }

  ${media.md`
    padding: 0;
  `}
`;

export type Props = {
  benefit: Benefit;
};

const ProductBenefitRow: React.FC<Props> = ({ benefit }) => {
  const [ref, inView] = useInView({ threshold: 0.7, triggerOnce: true });
  const { headline, eyebrow, img, body } = benefit;

  return (
    <Row ref={ref}>
      <Col md={6}>
        <ImgContainer className={inView ? 'active' : ''}>{img}</ImgContainer>
      </Col>
      <Col md={6}>
        <Content className={inView ? 'active' : ''}>
          <Eyebrow>
            <Unorphan>{eyebrow}</Unorphan>
          </Eyebrow>
          <Headline>
            <Unorphan>{headline}</Unorphan>
          </Headline>
          <Body>{body}</Body>
        </Content>
      </Col>
    </Row>
  );
};

export default ProductBenefitRow;
