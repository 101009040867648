import {
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_PLUS_COVER_ID,
} from 'gatsby-env-variables';
import React from 'react';
import { ProductFixedBottomBar } from '../common';

type Props = {
  onChangeIsPlusCover?: (isChecked: boolean) => void;
  isPlusCover?: boolean;
};
const TerraMattressProductFixedBottomBar: React.FC<Props> = ({
  onChangeIsPlusCover,
  isPlusCover,
}) => {
  return (
    <ProductFixedBottomBar
      productId={PRODUCT_TERRA_MATTRESS_ID}
      plusCoverProductId={PRODUCT_PLUS_COVER_ID}
      setIsPlusCover={onChangeIsPlusCover}
      isPlusCover={isPlusCover}
    />
  );
};

export default TerraMattressProductFixedBottomBar;
