import _ from 'lodash';
import { State, Action } from './cart-context-types';
import {
  ProductOrderItem,
  ProductOrderItemSubVariant,
} from '../../types/order';
import { initialState, CartEnum } from './cart-context-config';
import {
  getAmounts,
  saveToLocalStorage,
  updateProductQty,
} from './cart-context-utils';
import { calculateTax, isNullOrUndefined } from '../../utils';

const init = (state: State, action: Action): State => {
  if (action.products) {
    const shippingFee = action.shippingFee || state.shippingFee;
    const discount = action.discount || state.discount;
    const referralCode = action.referralCode || state.referralCode;

    const amounts = getAmounts(action.products, discount, shippingFee);
    const tax = calculateTax(amounts.totalAmount);

    return {
      ...state,
      promos: action.promos ? [...action.promos] : [],
      products: [...action.products],
      shippingFee,
      discount,
      amounts,
      referralCode,
      reconcile: action.reconcile,
      tax,
    };
  }

  return state;
};

const isProductSubVariantEqual = (
  a: ProductOrderItemSubVariant[] | undefined,
  b: ProductOrderItemSubVariant[] | undefined
): boolean => {
  if (a && b) {
    return _.isEmpty(_.differenceWith(a, b, _.isEqual));
  }
  return !a && !b;
};

const setAddProduct = (
  products: ProductOrderItem[],
  product: ProductOrderItem
): void => {
  const existInCartIndex = products.findIndex(
    (p: ProductOrderItem) =>
      p.id === product.id &&
      p.variant === product.variant &&
      isProductSubVariantEqual(p.subVariants, product.subVariants)
  );
  if (existInCartIndex > -1) {
    products[existInCartIndex].qty += product.qty;
    products[existInCartIndex].subtotal =
      products[existInCartIndex].unitPrice * products[existInCartIndex].qty;
  } else {
    products.push(product);
  }
};

const addProduct = (state: State, action: Action): State => {
  if (action.product) {
    const { product } = action;
    const { products, shippingFee, discount, promos, referralCode } = state;
    setAddProduct(products, product);

    const amounts = getAmounts(products, discount, shippingFee);
    const tax = calculateTax(amounts.totalAmount);

    saveToLocalStorage(products, promos, shippingFee, discount, referralCode);

    return {
      ...state,
      products: [...products],
      amounts,
      reconcile: true,
      tax,
    };
  }

  return state;
};

const addPromoBundleProduct = (state: State, action: Action): State => {
  if (action.products && action.promo) {
    const products = [...state.products].filter(
      (p: ProductOrderItem) => p.promoCode !== action.promo?.code
    );

    products.push(...action.products);

    let { discount, shippingFee, amounts, promos } = state;

    if (action.promo) {
      promos.push(action.promo);
      promos = _.uniqBy(promos, p => p.code);
    }

    if (action.discount) {
      discount = action.discount;
    }

    if (action.shippingFee) {
      shippingFee = action.shippingFee;
    }

    amounts = getAmounts(products, discount, shippingFee);
    const tax = calculateTax(amounts.totalAmount);

    saveToLocalStorage(
      products,
      promos,
      shippingFee,
      discount,
      state.referralCode
    );

    return {
      ...state,
      products: [...products],
      promos: [...promos],
      amounts: { ...amounts },
      shippingFee,
      discount,
      showPromoBundleModal: false,
      reconcile: true,
      tax,
    };
  }

  return state;
};

const removeProduct = (state: State, action: Action): State => {
  if (action.index !== undefined) {
    state.products.splice(action.index, 1);

    const amounts = getAmounts(
      state.products,
      state.discount,
      state.shippingFee
    );
    const tax = calculateTax(amounts.totalAmount);

    saveToLocalStorage(
      state.products,
      state.promos,
      state.shippingFee,
      state.discount,
      state.referralCode
    );

    return {
      ...state,
      products: [...state.products],
      amounts,
      reconcile: true,
      tax,
    };
  }

  return state;
};

const updateQty = (state: State, action: Action): State => {
  const result = updateProductQty(action, state, true);

  if (result) {
    const { products, amounts, tax } = result;
    return { ...state, products, amounts, reconcile: true, tax };
  }

  return state;
};

const applyPromo = (state: State, action: Action): State => {
  if (!action.promo) return state;

  const promos = _.uniqBy([...state.promos, action.promo], p => p.code);

  saveToLocalStorage(
    state.products,
    promos,
    state.shippingFee,
    state.discount,
    state.referralCode
  );

  return { ...state, promos, reconcile: true };
};

const removePromo = (state: State, action: Action): State => {
  const { promo, promos } = action;

  let removedPromos: string[] = [];

  if (promo) {
    removedPromos = [promo.code];
  } else if (promos) {
    removedPromos = promos.map(p => p.code);
  } else return state;

  const currentPromos = state.promos.filter(
    p => !removedPromos.includes(p.code)
  );

  // Remove free bundle items
  const products = state.products.filter(
    p =>
      !p.promoCode || (!removedPromos.includes(p.promoCode) && p.unitPrice > 0)
  );

  // Free up any products that has the promo code
  products.forEach(p => {
    if (!p.promoCode) return;
    if (!removedPromos.includes(p.promoCode)) return;
    p.promoCode = '';
  });

  saveToLocalStorage(
    products,
    currentPromos,
    state.shippingFee,
    state.discount,
    state.referralCode
  );

  return {
    ...state,
    promos: [...currentPromos],
    products,
    reconcile: true,
  };
};

const applyReferral = (state: State, action: Action): State => {
  if (action.referralCode) {
    saveToLocalStorage(
      state.products,
      state.promos,
      state.shippingFee,
      state.discount,
      action.referralCode
    );
    return { ...state, referralCode: action.referralCode, show: true };
  }

  return state;
};

const reconcile = (state: State, action: Action): State => {
  const { shippingFee, discount, promos, tax } = action;
  let products = action.products || state.products;

  if (isNullOrUndefined(shippingFee) || isNullOrUndefined(discount) || !promos)
    return state;

  const amounts = getAmounts(products, discount, shippingFee);
  const taxData = _.isEmpty(tax) ? calculateTax(amounts.totalAmount) : tax;

  // make sure order doesn't contain any invalid free products
  products = products.filter(
    p => !p.promoCode || promos.some(pr => pr.code === p.promoCode)
  );

  saveToLocalStorage(
    products,
    promos,
    shippingFee,
    discount,
    state.referralCode
  );

  return {
    ...state,
    products,
    promos,
    amounts,
    shippingFee,
    discount,
    reconcile: false,
    tax: taxData,
  };
};

const showPromoBundleModal = (state: State, action: Action) => {
  const bundle = action.bundle || state.bundle;

  bundle?.gets.forEach(get => {
    get.qty = get.qty * get.multiplyQty;
  });

  return {
    ...state,
    bundle,
    showPromoBundleModal: true,
    bundlePromoCode: action.promo,
    reconcile: false,
  };
};

export const cartReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INIT':
      return init(state, action);
    case 'ADD':
      return addProduct(state, action);
    case 'ADD_PROMO_BUNDLE_PRODUCTS':
      return addPromoBundleProduct(state, action);
    case 'REMOVE':
      return removeProduct(state, action);
    case 'UPDATE_QTY':
      return updateQty(state, action);
    case 'OPEN_DRAWER':
      return { ...state, show: true };
    case 'TOGGLE_DRAWER':
      return { ...state, show: !state.show };
    case 'SHOW_PROMO_BUNDLE_MODAL':
      return showPromoBundleModal(state, action);
    case 'HIDE_PROMO_BUNDLE_MODAL':
      return { ...state, showPromoBundleModal: false };
    case 'CLEAR_CART':
      localStorage.removeItem(CartEnum.LOCAL_STORAGE_KEY);
      return { ...initialState };
    case 'APPLY_PROMO':
      return applyPromo(state, action);
    case 'REMOVE_PROMO':
      return removePromo(state, action);
    case 'APPLY_REFERRAL':
      return applyReferral(state, action);
    case 'REMOVE_REFERRAL': {
      saveToLocalStorage(
        state.products,
        state.promos,
        state.shippingFee,
        state.discount,
        ''
      );
      return { ...state, referralCode: '' };
    }
    case 'SET_IS_LOADING': {
      return { ...state, isLoading: action.isLoading || false };
    }
    case 'RECONCILE':
      return reconcile(state, action);
    default: {
      return state;
    }
  }
};
