import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Product, Variant } from '../../../types/product';
import { ProductOrderItem } from '../../../types/order';
import FormCheckbox from '../form-checkbox';
import { getProductById } from '../../../utils/api';
import { rem } from '../../../styles/utils';

const CustomCheckBox = styled(FormCheckbox)`
  &.product-section-checkbox {
    position: absolute;
    margin: 24px;

    label {
      padding-left: 39px;
      color: #0d1e2a;

      &:before {
        height: 24px;
        width: 24px;
        background: white;
        border: 1px solid var(--border-color);
        border-color: #0d1e2a;
        border-radius: 2.5px;
      }

      &:after {
        height: 7.5px;
        width: 13.5px;
        left: 5px;
        top: 3px;
        border-color: var(--leather);
      }
    }
  }

  &.footer-section-checkbox {
    margin-top: 12px;
    label {
      font-size: ${rem(12)};
    }

    label {
      &:before {
        height: 18px;
        width: 18px;
        background: white;
        border: 1px solid var(--border-color);
        border-color: #0d1e2a;
        border-radius: 2.5px;
      }

      &:after {
        height: 6px;
        width: 10px;
        left: 3px;
        top: 2px;
        border-color: var(--leather);
      }
    }
  }
`;

type Props = {
  product: Product;
  checkboxLabel?: string;
  selectedVariant?: Variant;
  onChange?: (
    productOrderItem: ProductOrderItem | null,
    isChecked: boolean
  ) => void;
  isPlusCoverChecked?: boolean;
  section?: string;
};

const ProductPlusCoverCheckbox: React.FC<Props> = ({
  product,
  checkboxLabel,
  selectedVariant,
  onChange,
  isPlusCoverChecked,
  section = 'product',
}) => {
  useEffect(() => {
    if (!isPlusCoverChecked) {
      handleChange(null, 0);
      return;
    }

    getProductById(product.id).then(dataProduct => {
      let variants = dataProduct.variants.filter(
        v => v.status !== 'Out of Stock'
      );
      if (selectedVariant !== null && variants.length > 1) {
        variants = variants.filter(
          v => v.type.toLowerCase() === selectedVariant?.type.toLowerCase()
        );
      }
      const selectedPlusCoverProduct = { ...product };
      if (variants.length > 0) {
        selectedPlusCoverProduct.variants = variants;
      } else {
        selectedPlusCoverProduct.variants = [];
      }

      if (isPlusCoverChecked) handleChange(selectedPlusCoverProduct);
    });
  }, [selectedVariant, isPlusCoverChecked]);

  const handleChange = (product: Product | null, qty = 1) => {
    if (typeof onChange != 'function') return;

    if (product != undefined && product.variants.length > 0) {
      const variant = product.variants[0];
      const price = variant.prices[0];

      onChange(
        {
          id: product.id,
          name: product.name,
          collection: product.collection,
          image:
            product.media.find(
              m =>
                (m.default && m.type !== 'video') ||
                (!m.default && m.type === 'image')
            )?.path || '',
          comparePrice: price.compareAmount || 0,
          currency: price.currency,
          qty,
          subtotal: qty * price.amount,
          unitPrice: price.amount,
          variant: variant.type,
          isNew: true,
          status: variant.status,
          statusDate:
            variant.statusDate &&
            moment.unix(variant.statusDate._seconds).toDate(),
        },
        true
      );
    } else {
      onChange(null, false);
    }
  };

  return (
    <>
      {checkboxLabel && (
        <CustomCheckBox
          className={`${section}-section-checkbox`}
          label={checkboxLabel}
          inputProps={{
            id: `product-plus-cover-checkbox-${product.id}-${section}`,
            checked: isPlusCoverChecked,
            onChange: () => {
              const isEnabled = !isPlusCoverChecked;

              if (isEnabled) handleChange(product);
              else {
                handleChange(null);
              }
            },
          }}
        />
      )}
    </>
  );
};

export default ProductPlusCoverCheckbox;
