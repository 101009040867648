import React from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { lh, rem } from '../../styles/utils';
import Container from './container';
import FirmnessChart from './firmness-chart';
import Picture from './picture';
import Video from './video';

const Section = styled.section`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  padding: 48px 0;

  ${media.lg`
    padding: 64px 0;
    border-top: 1px solid var(--celeste);
    border-bottom: 1px solid var(--celeste);
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Headline = styled.h2`
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(28.8, 24)};
  margin-top: 0;
  margin-bottom: 12px;
  text-align: center;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)}; 
    margin-bottom: 32px;
  `}
`;

const Firmness = styled.p`
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${lh(19.2, 16)};
  color: var(--font-primary-color);
  margin-bottom: 8px;
  margin-top: 0;
  text-align: center;

  ${media.lg`
    font-size: ${rem(24)};
    line-height: ${lh(28.8, 24)};
  `}
`;

const AssetWrapperMobile = styled.div`
  margin-bottom: 28px;

  ${media.lg`
    display: none;
  `}
`;

const AssetWrapper = styled.div`
  display: none;
  max-width: 528px;
  margin-right: 28px;

  ${media.lg`
    display: block;
  `}
`;

const ImgPicture = styled(Picture)``;

const ChartWrapper = styled.div`
  width: 100%;
  max-width: 530px;
`;

const Chart = styled(FirmnessChart)`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
`;

const Description = styled.p`
  margin: 0 auto;
  font-family: var(--font-body);
  width: 100%;
  max-width: 530px;
  text-align: center;
  font-size: ${rem(14)};
  line-height: ${lh(16.8, 14)};
  color: var(--xanadu);

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)}; 
  `}
`;

type Props = {
  className?: string;
  headline: React.ReactNode;
  firmness: number;
  duration?: number;
  description: React.ReactNode;
  asset?: {
    src: any;
    webp?: any;
    type: 'image' | 'video';
  };
};

const MattressFirmnessSection: React.FC<Props> = ({
  className,
  headline,
  firmness,
  duration,
  description,
  asset,
}) => {
  const renderAsset = () => {
    if (!asset) return;

    const { type, src, webp } = asset;
    switch (type) {
      case 'video':
        return <Video src={src} />;
      default:
        return <ImgPicture src={{ png: src, webp }} imgProps={{ alt: '' }} />;
    }
  };

  return (
    <Section className={className}>
      {asset && <AssetWrapperMobile>{renderAsset()}</AssetWrapperMobile>}
      <Container>
        <Headline>{headline}</Headline>
        <Wrapper>
          {asset && <AssetWrapper>{renderAsset()}</AssetWrapper>}
          <ChartWrapper>
            <Firmness>Firmness: {firmness}/10</Firmness>
            <Chart duration={duration} firmness={firmness} />
            <Description>{description}</Description>
          </ChartWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default MattressFirmnessSection;
