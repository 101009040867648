import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { HeroIntro } from '../common';
import Video from '../../static/videos/mysa-mattress-intro.mp4';

const Intro = styled(HeroIntro)`
  .hero-intro {
    height: 200px;

    ${media.md`
      height: 300px;
    `}

    ${media.lg`
      height: 450px;
    `}

    ${media.xl`
      height: 700px;
    `}
  }

  .hero-intro-content {
    padding: 30px 0;

    ${media.md`
      padding: 50px 0;
    `}

    ${media.lg`
      padding: 100px 0;
    `}
  }
`;

const MysaMattressIntroSection: React.FC = () => {
  return (
    <Intro
      version="v2"
      backgroundVideoSrc={Video}
      eyebrow="Design Process"
      headline="Crafting our Ultimate Flagship Comfort"
      body={
        <>
          <p>
            The Mysa Mattress was created from listening to existing Woosa
            customers who were looking for something more in their mattress - a
            plushier, soft yet firm “hotel-style” feel. With the development of
            our proprietary Woosa Cloud Foam, we were able to combine our core
            foam components with the unique plushness of the Cloud Foam to
            create an indulgent yet incredibly supportive mattress.
          </p>
          <p>
            We went through more than 30 different iterations before we settled
            on the final combination. The 6 layers in the Mysa Mattress work
            harmoniously together to create an indulgent sleeping surface that
            offers you a sensation of being cradled to bed, and has since become
            our signature mattress that is unlike anything in the market.{' '}
          </p>
        </>
      }
    />
  );
};

export default MysaMattressIntroSection;
