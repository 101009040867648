import React from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';

const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;

  ${media.lg`
    margin-bottom: 60px;
  `}
`;

const Asset = styled.div``;

const Content = styled.div`
  ${media.md`
    padding-left: 40px;
  `}

  ${media.lg`
    padding-right: 40px;
  `}

  ${media.xl`
    padding-left: 60px;
    padding-right: 60px;
  `}
`;

const Title = styled.h3`
  font-family: var(--font-header);
  font-size: ${rem(20)};
  line-height: 1.2;
  font-weight: normal;
  color: var(--font-primary-color);

  ${media.md`
    margin-top: 0;
  `}

  ${media.xl`
    font-size: ${rem(30)};
  `}
`;

const Body = styled.p`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-size: ${rem(14)};
  line-height: 1.2;

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}
`;

export type Props = {
  className?: string;
  asset: React.ReactNode;
  title: React.ReactNode;
  body: React.ReactNode;
};

const HorizontalTile: React.FC<Props> = ({ className, asset, title, body }) => {
  return (
    <Container className={className}>
      <Row mdAlignItems="center">
        <Col md={6}>
          <Asset>{asset}</Asset>
        </Col>
        <Col md={6}>
          <Content>
            <Title>{title}</Title>
            <Body>{body}</Body>
          </Content>
        </Col>
      </Row>
    </Container>
  );
};

export default HorizontalTile;
