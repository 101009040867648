import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Post } from '../../types/post';
import { Container, PostCard, Link } from '../common';
import { rem, lh } from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';

const Section = styled.section`
  width: 100%;
  background-color: var(--mystic);
  padding-bottom: 25px;

  &:last-child {
    padding-bottom: 40px;
  }

  ${media.lg`
    padding-bottom: 50px;

    &:last-child {
      padding-bottom: 80px;
    }
  `}
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${lh(24, 18)};
  border-bottom: 1px solid var(--border-color);
  padding: 0 0 10px;

  ${media.lg`
    font-size: ${rem(18)};
    padding: 0 0 20px;
    margin-bottom: 50px;
  `}
`;

const Eyebrow = styled.strong`
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--cornflower-blue);
`;

const EyebrowLink = styled(Link)`
  margin-left: auto;
  text-decoration: none;
  color: var(--bali-hai);

  &:hover {
    text-decoration: underline;
  }
`;

const PostCardList = styled.div`
  width: 100%;

  ${media.md`
    display: flex;
  `}
`;

const PostCardItem = styled.div`
  width: 100%;
  margin-bottom: 30px;

  ${media.md`
    width: 50%;
    margin-bottom: 0;
  `}
`;

type Props = {
  categories?: string[];
  posts: Post[];
};

const BlogCategorySection: React.FC<Props> = ({ categories, posts }) => {
  // check whether there are any featured posts, used to filter out duplicates
  const featuredPost = posts.find(p => p.isFeatured);

  // group posts by their categories, even if they exist in multiple we shall list them all
  const groupedPosts = posts.reduce<{ [key: string]: Post[] }>((acc, p) => {
    (p.categories || []).forEach(c => {
      // check for featured posts duplicates, but for filterCategories.length > 0 we won't have a featured post, hence can ignore
      if (
        featuredPost &&
        featuredPost.id === p.id &&
        (categories || []).length === 0
      )
        return acc;
      if (!(c.name in acc)) {
        acc[c.name] = [p];
      } else if (acc[c.name].length < 2) {
        acc[c.name].push(p);
      }
    });
    return acc;
  }, {});

  return (
    <>
      {Object.keys(groupedPosts).map(key => (
        <Section key={key}>
          <Container>
            <Header>
              <Eyebrow>{key}</Eyebrow>
              <EyebrowLink
                to={`${SiteUrls.Blog}${
                  (categories || []).length == 0
                    ? `?categories=${encodeURIComponent(key)}`
                    : ''
                }`}
              >
                Read more articles
              </EyebrowLink>
            </Header>
            <PostCardList>
              {groupedPosts[key].map(p => (
                <PostCardItem key={`${key}-${p.id}`}>
                  <PostCard post={p} />
                </PostCardItem>
              ))}
            </PostCardList>
          </Container>
        </Section>
      ))}
    </>
  );
};

export default BlogCategorySection;
