import React from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import {
  ImgLogoHomeDecor,
  ImgLogoHoneyCombers,
  ImgLogoQanvast,
  ImgLogoVulcan,
  ImgLogoNoc,
  ImgLogoSilverKris,
  ImgLogoCoconuts,
  ImgLogoMothership,
} from '../assets';
import { PartnerReviewSection } from '../common';

const Section = styled(PartnerReviewSection)`
  margin-top: 90px;
  margin-bottom: 45px;

  ${media.lg`
    margin-bottom: 90px; 
  `}
`;

const HomePartnerReviewSection: React.FC = () => {
  return (
    <Section
      reviews={[
        {
          content:
            '"Woosa’s surprisingly accessible price tag means that such a bed is a possible acquisition even for the average Singaporean home."',
          logo: <ImgLogoMothership />,
          url: 'https://mothership.sg/2023/06/office-bed-sleep-deprived-naps/',
        },
        {
          content:
            '"the ultimate mattress made for homeowners – without the hefty price tag."',
          logo: <ImgLogoQanvast />,
          url:
            'https://qanvast.com/sg/articles/where-to-find-a-quality-and-affordable-mattress-in-singapore-1347',
        },
        {
          content: '".Woosa offers users the ultimate level of comfort"',
          logo: <ImgLogoHomeDecor />,
          url:
            'https://www.homeanddecor.com.sg/shopping/get-better-sleep-with-this-bed-and-mattress/',
        },
        {
          content: '"suitable for all sleepers and all different positions"',
          logo: <ImgLogoSilverKris />,
          url: 'https://www.silverkris.com/woosa-choosing-right-mattress/',
        },
        {
          content: '"a solid mattress that’ll last you a long time”',
          logo: <ImgLogoCoconuts />,
        },
        {
          content:
            '"..it has the perfect amount of firmness but it’s also soft enough to mould into."',
          logo: <ImgLogoHoneyCombers />,
          url:
            'https://thehoneycombers.com/singapore/woosa-mattress-quality-sleep-rest/',
        },
        {
          content:
            '"...they’re ditching everything you know about the traditional mattress industry."',
          logo: <ImgLogoVulcan />,
          url: 'https://vulcanpost.com/659376/woosa-sleep-mattress-singapore/',
        },
        {
          content:
            '"..have to spend an extra 30 min getting out of bed because it\'s that comfortable"',
          logo: <ImgLogoNoc />,
          url: 'https://youtu.be/kjvbHOFmCzQ?t=1027',
        },
      ]}
    />
  );
};

export default HomePartnerReviewSection;
