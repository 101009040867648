import React from 'react';
import styled from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';

import { rem, lh } from '../../styles/utils';
import { Container } from '../common';
import {
  ImgSeresSilkDuvetSubIntro1,
  ImgSeresSilkDuvetSubIntro2,
} from '../assets';

const Section = styled.section`
  background-color: var(--firefly);
  font-family: var(--font-body);
  color: white;
  padding: 45px 0;

  ${media.lg`
    padding: 70px 0 100px;  
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  line-height: ${lh(51.87, 42)};
  font-weight: 400;
  text-align: center;
  margin-top: 0;
  margin-bottom: 60px;

  ${media.lg`
    font-size: ${rem(42)};
  `}
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  ${media.xl`
    margin-bottom: 0;
  `}
`;

const Img = styled.div`
  width: 130px;
  flex-shrink: 0;
  margin-right: 25px;

  img {
    width: 100%;
  }

  ${media.xl`
    width: 215px;
    margin-right: 45px;
  `}
`;

const Content = styled.div`
  ${media.xl`
    padding-right: 30px;
  `}
`;

const Title = styled.strong`
  font-size: ${rem(14)};
  line-height: ${lh(24, 18)};
  text-transform: uppercase;
  font-weight: 600;

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.xl`
    font-size: ${rem(18)}; 
  `}
`;

const Paragraph = styled.p`
  font-size: ${rem(12)};
  line-height: ${lh(24, 18)};
  font-weight: 400;

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(18)}; 
  `}
`;

const SeresSilkDuvetSubIntroSection: React.FC = () => {
  return (
    <Section>
      <Container>
        <Headline>Meticulously designed</Headline>
        <Row>
          <Col md={6}>
            <Item>
              <Img>
                <ImgSeresSilkDuvetSubIntro1 />
              </Img>
              <Content>
                <Title>Direction tags</Title>
                <Paragraph>
                  When inserting The Seres Silk Duvet into a cover, Woosa tags
                  are attached at top and bottom to indicate where your head and
                  legs should go.
                </Paragraph>
              </Content>
            </Item>
          </Col>
          <Col md={6}>
            <Item>
              <Img>
                <ImgSeresSilkDuvetSubIntro2 />
              </Img>
              <Content>
                <Title>Duvet clips</Title>
                <Paragraph>
                  8-sided clips help secure the duvet properly in its cover so
                  that it stays in place.
                </Paragraph>
              </Content>
            </Item>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default SeresSilkDuvetSubIntroSection;
