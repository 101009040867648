import React from 'react';
import styled from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';

import Container from './container';
import PostShare from './post-share';
import Unorphan from './unorphan';
import { Post } from '../../types/post';
import { rem } from '../../styles/utils';

const Section = styled.section`
  font-family: var(--font-body);
  padding-top: 20px;

  ${media.lg`
    padding-top: 100px;
  `}

  ${media.xl`
    padding-top: 150px; 
  `}
`;

const CategoryList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
`;

const CategoryItem = styled.li`
  color: var(--cornflower-blue);
  font-weight: 600;
  font-size: ${rem(18)};
  line-height: 1.33;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-left: 25px;

  &:first-child {
    list-style: none;
    margin-left: 0;
  }
`;

const Headline = styled.h1`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-weight: 400;
  font-size: ${rem(40)};
  line-height: 1.1;
  margin-top: 15px;
  margin-bottom: 10px;

  ${media.lg`
    margin-top: 30px;
    font-size: ${rem(60)}; 
  `}
`;

const Subtitle = styled.div`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-weight: 400;
  line-height: 1.1;
  font-size: ${rem(20)};
  font-style: italic;
  margin-bottom: 40px;

  ${media.lg`
    margin-bottom: 80px;
    font-size: ${rem(30)};
  `}
`;

const Image = styled.img`
  width: 100%;
  margin-top: 40px;

  ${media.lg`
    width: auto;
    margin-top: 0;
  `}
`;

type Props = {
  post: Post;
};

const PostHero: React.FC<Props> = ({ post }) => {
  return (
    <Section>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <CategoryList>
              {post.isFeatured && <CategoryItem>Featured</CategoryItem>}
              {!post.isFeatured && post.categories &&
                post.categories.map(c => (
                  <CategoryItem key={c.id}>{c.name}</CategoryItem>
                ))}
            </CategoryList>
            <Headline>
              <Unorphan>{post.title}</Unorphan>
            </Headline>
            {post.author && <Subtitle>by {post.author.name}.</Subtitle>}
            <PostShare post={post} />
          </Col>
          <Col xs={12} lg={6}>
            <Image src={post.thumbnail} alt={post.title} />
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default PostHero;
