import { graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { media } from 'styled-bootstrap-grid';

import { ReviewGraphQL } from '../../types/review';
import ReviewStars from './review-stars';
import GridContainer from './container';
import CTA from './cta';
import { SiteUrls } from '../../utils/constants';
import { lh, rem } from '../../styles/utils';

const Section = styled.section`
  text-align: center;
  width: 100%;
  padding: 64px 0;
`;

const Container = styled(GridContainer)`
  ${media.md`
      max-width: 600px;
  `}
`;

const Stars = styled(ReviewStars)`
  width: auto;
`;

const StarsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
`;

const Content = styled.div`
  font-family: var(--font-header);
  font-weight: 400;
  color: var(--font-primary-color);
  font-size: ${rem(32)};
  line-height: ${lh(38.4, 32)};
  margin-bottom: 12px;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};
  `}
`;

const Eyebrow = styled.p`
  font-family: var(--font-body);
  color: var(--santa-fe);
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: ${lh(21, 14)};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  span:first-child {
    margin-right: 10px;
  }
`;

const Button = styled(CTA)``;

type Props = {
  className?: string;
  productId: string;
  allReviewsUrl?: SiteUrls;
};

type GetReviewGrahpQL = {
  allReviews: {
    edges: {
      node: ReviewGraphQL;
    }[];
  };
};

const getReviews = (productId: string) => {
  const {
    allReviews: { edges },
  } = useStaticQuery<GetReviewGrahpQL>(graphql`
    query {
      allReviews(
        filter: { display: { eq: true } }
        sort: { fields: [rating, submittedOn], order: [DESC, DESC] }
      ) {
        edges {
          node {
            content
            display
            id
            media
            product {
              id
              name
            }
            rating
            submittedOn
            user {
              displayPic
              firstName
              lastName
            }
          }
        }
      }
    }
  `);

  const reviews = edges
    .filter(
      ({ node }) =>
        node.product && node.product.id === productId && !isEmpty(node.content)
    )
    .map(({ node }) => node);

  return reviews;
};

const getLatestHighestRatedReview = (
  reviews: ReviewGraphQL[]
): ReviewGraphQL | undefined => {
  if (!reviews) return;
  if (reviews.length === 0) return;

  // the grahql query should have done the sorting properly and the filtering done on the code level will preserve the same order
  return reviews[0];
};

const MAX_CONTENT_CHAR = 100;

const ProductReviewHeroSection: React.FC<Props> = ({
  className,
  productId,
  allReviewsUrl,
}) => {
  const reviews = getReviews(productId);
  const latestReview = getLatestHighestRatedReview(reviews);

  if (!latestReview) return null;

  const { rating, content, user, submittedOn } = latestReview;
  const { firstName } = user;

  const renderContent = () => {
    if (content.length <= MAX_CONTENT_CHAR) return content;
    return content.substring(0, MAX_CONTENT_CHAR + 1) + '...';
  };

  return (
    <Section className={className}>
      <Container>
        <StarsContainer>
          <Stars fillColor="var(--santa-fe)" rating={rating} hideText />
        </StarsContainer>
        <Content>{renderContent()}</Content>
        <Eyebrow>
          <span>{firstName}</span>
          <span>{moment(submittedOn).format('MMM DD, YYYY')}</span>
        </Eyebrow>
        {allReviewsUrl && (
          <Button to={allReviewsUrl} type="outline">
            Read all reviews
          </Button>
        )}
      </Container>
    </Section>
  );
};

export default ProductReviewHeroSection;
