import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import styled from 'styled-components';

import { Spinner, PostHero, PostBody, PostFooter, PostSEO } from '../common';
import { getPreviewBlogPost } from '../../utils/api';
import { stripTags, unslugify } from '../../utils';
import { Post } from '../../types/post';

const InfoBlock = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-body);
  font-size: 24px;
`;

const BlogPostPreviewSection: React.FC = () => {
  const location = useLocation();
  const { token } = location.search
    ? queryString.parse(location.search)
    : { token: '' };
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState<Post | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!token) return;
    const previewToken = unslugify(Array.isArray(token) ? token[0] : token);
    (async function () {
      setIsLoading(true);
      const postData = await getPreviewBlogPost(stripTags(previewToken));
      setPost(postData);
      setIsLoading(false);
      if (!postData) {
        setError('Unable to load preview.');
      } else {
        setError('');
      }
    })();
  }, [token]);

  if (!post && !error && !isLoading) {
    return (
      <InfoBlock>Your preview will show here once it&apos;s loaded</InfoBlock>
    );
  }

  if (isLoading) {
    return (
      <InfoBlock>
        <Spinner isLoading size={30} />
      </InfoBlock>
    );
  }

  if (error || !post) {
    return <InfoBlock>{error}</InfoBlock>;
  }

  return (
    <article>
      <PostSEO post={post} />
      <PostHero post={post} />
      <PostBody post={post} />
      <PostFooter post={post} />
    </article>
  );
};

export default BlogPostPreviewSection;
