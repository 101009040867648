import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import CTA from '../cta';
import { rem } from '../../../styles/utils';
import Video from '../video';

export type SlideProps = {
  Background: React.FC | typeof Video;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backgroundVideoSrc?: any;
  headline?: string;
  subheadline?: string;
  cta?: {
    to?: string;
    text?: string;
  };
  overlay?: {
    disabled?: boolean;
    className?: string;
  };
  className?: string;
};

export const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 15px;
  // background: rgba(0, 0, 0, 0.2);

  ${media.md`
    // background: transparent;
    display: block;
    height: auto;
    position: absolute;
    left: 5%;
    bottom: 5%;
    padding: 40px 15px;
  `}
`;

export const Headline = styled.h1`
  font-family: var(--font-header);
  font-size: ${rem(38)};
  font-weight: normal;
  color: white;
  max-width: 420px;
  text-align: center;
  margin: 0 0 ${rem(20)} 0;
  line-height: 1;

  ${media.md`
    font-size: ${rem(52)};
    text-align: left;
  `}

  ${media.lg`
    font-size: ${rem(60)};
  `}
`;

export const Subheadline = styled.h3`
  font-family: var(--font-body);
  font-size: ${rem(14)};
  font-weight: normal;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: ${rem(5)};
  line-height: 1.3;
  margin: 0 0 ${rem(20)};
  display: none;

  ${media.md`
    text-align: left;
    font-size: ${rem(16)};
    display: block;
  `}
`;

export const ArrowCTA = styled(CTA)`
  max-width: 50px;
  display: none;
  margin-top: ${rem(24)};

  ${media.md`
    display: block;
  `}
`;

export const ButtonCTA = styled(CTA)`
  display: block;
  font-size: ${rem(16)};

  ${media.md`
    display: none;
  `}
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
`;
