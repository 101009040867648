import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { Dispatch, State, CartProviderProps } from './cart-context-types';
import { initialState } from './cart-context-config';
import { cartReducer } from './cart-context-reducer';
import {
  initFromLocalStorage,
  checkForReferralCode,
  managePromo,
} from './cart-context-utils';
import { useCustomReducer } from '../use-custom-reducer';

const CartStateContext = createContext<State | undefined>(undefined);
const CartDispatchContext = createContext<Dispatch | undefined>(undefined);

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [state, dispatch] = useCustomReducer(
    'CartContext',
    cartReducer,
    initialState
  );
  const location = useLocation();
  const { friend } = location.search
    ? queryString.parse(location.search)
    : { friend: '' };

  // Reads localStorage data and populate into context
  useEffect(() => {
    initFromLocalStorage(dispatch);
  }, []);

  // Check for referral codes
  useEffect(() => {
    checkForReferralCode(friend, dispatch);
  }, [friend]);

  // Listen for changes on products to apply promotion logic from API
  useEffect(() => {
    if (!state.reconcile) return;

    (async function () {
      // Check on promo validity and perform reconciliation to make sure
      // amounts are correct and the promo codes are valid
      await managePromo(state.products, state.promos, dispatch);
    })();
  }, [state.reconcile]);

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};

export const useCartState = (): State | never => {
  const context = useContext(CartStateContext);
  if (context === undefined) {
    throw new Error('useCartState must be used within a CartProvider');
  }
  return context;
};

export const useCartDispatch = (): Dispatch | never => {
  const context = useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error('useCartDispatch must be used within a CartProvider');
  }
  return context;
};
