import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { HIDE_REVIEW_TAB_LIST } from 'gatsby-env-variables';

import GridContainer from './container';
import ReviewStars from './review-stars';
import { rem } from '../../styles/utils';
import { Product } from '../../types/product';
import { SiteUrls } from '../../utils/constants';
import {
  ImgMattressReview,
  ImgMysaReview,
  ImgPillowReview,
  ImgBedFrameReview,
  ImgSeresSilkDuvetReview,
  ImgTerraReview,
  ImgAdjustableBaseReview,
  ImgStorageBaseReview,
  ImgEgyptianCottonBeddingSetReview,
  ImgEgyptianCottonFittedSheetReview,
  ImgEgyptianCottonDuvetCoverReview,
  ImgEgyptianCottonPillowcaseSetReview,
} from '../assets';
import Link from './link';
import Unorphan from './unorphan';
import CustomDropdown from './custom-dropdown';
import { useCountryState } from '../../hooks/country-context';
import { navigateWithCountryCode } from '../../utils';

const Section = styled.section`
  width: 100%;
  /* background-color: var(--hero-grey); */
  padding: 40px 0;

  ${media.md`
    padding-bottom: 20px;
  `}

  ${media.lg`
    padding-bottom: 100px;
  `}
`;

const Container = styled(GridContainer)`
  ${media.md`
    padding-top: 40px;
  `}

  ${media.lg`
    padding-top: 120px;
  `}

  ${media.xl`
    padding-top: 160px;
  `}
`;

const DropdownContainer = styled.div`
  font-family: var(--font-body);

  ${media.md`
    display: flex;
    justify-content: flex-end;
  `}
`;

const DropdownWrapper = styled.div`
  ${media.md`
    display: flex;
    align-items: center;
  `}
`;

const DropdownSelector = styled(CustomDropdown)`
  ${media.md`
    min-width: 300px;
  `}
`;

const DropdownTitle = styled.label`
  display: block;
  font-size: ${rem(14)};
  margin-bottom: 10px;

  ${media.md`
    margin-bottom: 0;
    margin-right: 10px;
  `}
`;

const ReviewStarsCenter = styled.div`
  position: relative;
  height: 30px;
  margin-top: 20px;
`;

const ReviewStarsContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 230px;
`;

const HeroContainer = styled.div`
  padding: 0;
  margin: 50px 0;
  ${media.lg`
    padding: 0 100px;
  `}
`;

const Headline = styled.h2`
  margin-top: 0;
  margin-bottom: 30px;
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-size: ${rem(24)};
  font-weight: normal;
  text-align: center;
  margin: auto;
  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
    width: 400px;
  `}
`;

const TabList = styled.ul`
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;

  ${media.xs`
    overflow-x: scroll;
  `}

  ${media.md`
    margin: 0 0 40px;
  `}

  ${media.lg`
    margin: 0 0 60px;
  `}

  ${media.xl`
    margin: 0 0 100px;
  `}
`;

const TabItem = styled.li<{ isActive: boolean }>`
  margin: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;

  ${media.lg`
    margin: 0 15px;
  `}

  ${media.xl`
    margin: 0 30px;
  `}

  a {
    text-decoration: none;
    font-family: var(--font-body);
    font-size: ${rem(10)};
    color: var(--font-nav-color);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: ${rem(1)};
    line-height: 1.3;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.7)};

    ${media.md`
      font-size: ${rem(14)};
    `}

    ${media.lg`
      font-size: ${rem(16)};
    `}
  }
`;

const data = [
  {
    type: 'Mattress',
    text: 'The Original Mattress',
    url: SiteUrls.OriginalMattressReview,
    image: <ImgMattressReview />,
  },
  {
    type: 'Mysa',
    text: 'The Mysa Mattress',
    url: SiteUrls.MysaMattressReview,
    image: <ImgMysaReview />,
  },
  {
    type: 'Terra',
    text: 'The Terra Mattress',
    url: SiteUrls.TerraMattressReview,
    image: <ImgTerraReview />,
  },
  // {
  //   type: 'BedFrame',
  //   text: 'Adjustable Base',
  //   url: SiteUrls.BedFrameReview,
  //   image: <ImgBedFrameReview />,
  // },
  {
    type: 'AdjustableBase2',
    text: 'Adjustable Base II',
    url: SiteUrls.AdjustableBase2Review,
    image: <ImgAdjustableBaseReview />,
  },
  {
    type: 'StorageBase',
    text: 'Storage Base',
    url: SiteUrls.StorageBaseReview,
    image: <ImgStorageBaseReview />,
  },
  {
    type: 'Pillow',
    text: 'The Woosa Pillow',
    url: SiteUrls.OriginalPillowReview,
    image: <ImgPillowReview />,
  },
  {
    type: 'SeresSilkDuvet',
    text: 'The Seres Silk Duvet',
    url: SiteUrls.SeresSilkDuvetReview,
    image: <ImgSeresSilkDuvetReview />,
  },
  {
    type: 'EgyptianCottonBeddingSet',
    text: 'Egyptian Cotton Bedding Set',
    url: SiteUrls.EgyptianCottonBeddingSetReview,
    image: <ImgEgyptianCottonBeddingSetReview />,
  },
  {
    type: 'EgyptianCottonFittedSheet',
    text: 'Egyptian Cotton Fitted Sheet',
    url: SiteUrls.EgyptianCottonFittedSheetReview,
    image: <ImgEgyptianCottonFittedSheetReview />,
  },
  {
    type: 'EgyptianCottonDuvetCover',
    text: 'Egyptian Cotton Duvet Cover',
    url: SiteUrls.EgyptianCottonDuvetCoverReview,
    image: <ImgEgyptianCottonDuvetCoverReview />,
  },
  {
    type: 'EgyptianCottonPillowcaseSet',
    text: 'Egyptian Cotton Pillowcase Set',
    url: SiteUrls.EgyptianCottonPillowcaseSetReview,
    image: <ImgEgyptianCottonPillowcaseSetReview />,
  },
];

type ProductReviewType =
  | 'Mattress'
  | 'Mysa'
  | 'Pillow'
  | 'BedFrame'
  | 'SeresSilkDuvet'
  | 'Terra'
  | 'AdjustableBase2'
  | 'StorageBase'
  | 'EgyptianCottonBeddingSet'
  | 'EgyptianCottonFittedSheet'
  | 'EgyptianCottonDuvetCover'
  | 'EgyptianCottonPillowcaseSet';

type Props = {
  product: Product;
  title: string;
  type: ProductReviewType;
};

const ReviewHeroSection: React.FC<Props> = ({ product, title, type }) => {
  const { countryCode } = useCountryState();

  const getOption = (reviewType: ProductReviewType) => {
    const p = data.find(d => d.type === reviewType);
    if (!p) return;

    return {
      label: p.text,
      value: p.type,
      to: navigateWithCountryCode({ to: p.url, countryCode }),
    };
  };

  return (
    <Section>
      <Container>
        <DropdownContainer>
          <DropdownWrapper>
            <DropdownTitle>Select Product</DropdownTitle>
            <DropdownSelector
              options={data
                .filter(d => !HIDE_REVIEW_TAB_LIST.includes(d.type))
                .map(d => ({
                  label: d.text,
                  value: d.type,
                }))}
              initialOption={getOption(type)}
              onChange={selected => {
                if (!selected) return;
                const opt = getOption(selected.value as ProductReviewType);
                if (!opt) return;
                navigate(opt.to);
              }}
            />
          </DropdownWrapper>
        </DropdownContainer>

        <HeroContainer>{data.find(d => d.type === type)?.image}</HeroContainer>

        <Headline>
          <Unorphan>{title}</Unorphan>
        </Headline>

        <ReviewStarsCenter>
          <ReviewStarsContainer>
            <ReviewStars isYellow rating={product.rating?.avg || 0} />
          </ReviewStarsContainer>
        </ReviewStarsCenter>
      </Container>
    </Section>
  );
};

export default ReviewHeroSection;
