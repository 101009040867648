import React from 'react';

import { Post } from '../../types/post';
import SEO from './seo';

type Props = {
  post: Post;
};

const PostSEO: React.FC<Props> = ({ post }) => {
  const description = post.meta?.description || post.title;

  return (
    <SEO
      title={post.title}
      description={description}
      meta={[
        { property: 'og:type', content: 'article' },
        { property: 'og:image', content: post.thumbnail },
        { name: 'twitter:creator', content: post.author?.name },
      ]}
    />
  );
};

export default PostSEO;
