import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { HorizontalTileList } from '../common';
import { ImgAboutTile1, ImgAboutTile2 } from '../assets';

const Section = styled.section`
  margin-top: 60px;
  margin-bottom: 60px;

  ${media.lg`
    margin-bottom: 80px;
  `}

  ${media.xl`
    margin-top: 80px;
    margin-bottom: 120px;
  `}
`;

const AboutTileList: React.FC = () => {
  return (
    <Section>
      <HorizontalTileList
        tiles={[
          {
            title: <>Disrupting a tired industry</>,
            body: (
              <>
                We&apos;re sick of commission driven store experiences, gimmicky
                sales tactics and unreliable prices. Buying a mattress should be
                simple and stress-free. From pioneering the 100-night free trial
                in Singapore and introducing the Sleep Lab, we&apos;re making
                your shopping experience as simple as crawling into bed.
              </>
            ),
            asset: <ImgAboutTile1 />,
          },
          {
            title: <>Crafting new bedtime experiences</>,
            body: (
              <>
                We started by delivering an incredibly comfortable mattress in a
                box, to creating affordable adjustable bases that transform into
                your favorite sleeping position. We&apos;re always set on
                innovating and creating new experiences that enhance your nights
                for the better.
              </>
            ),
            asset: <ImgAboutTile2 />,
          },
        ]}
      />
    </Section>
  );
};

export default AboutTileList;
