import React from 'react';
import styled from 'styled-components';
import { Row, Col as ColGrid, media } from 'styled-bootstrap-grid';

import { rem, sectionMarginStyle } from '../../../styles/utils';
import { IconGrid } from '../../../types/component';
import Container from '../container';

const Section = styled.section`
  width: 100%;
  font-family: var(--font-body);
  color: var(--font-primary-color);
  ${sectionMarginStyle}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin: 0;
  margin-bottom: 60px;
  width: 100%;
  text-align: center;

  ${media.lg`
    font-size: ${rem(38)};
    margin-bottom: 80px;
  `}

  ${media.xl`
    font-size: ${rem(42)};
    margin-bottom: 100px;
  `}
`;

const Icon = styled.div`
  height: 55px;
  width: auto;
  margin: 0 auto;
  margin-bottom: 20px;

  svg {
    height: 100%;
  }

  ${media.lg`
    height: 70px;
  `}

  ${media.xl`
    height: 90px;
  `}
`;

const Title = styled.h3`
  display: block;
  font-weight: bold;
  font-size: ${rem(12)};
  line-height: 1.2;
  text-transform: capitalize;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: 1.5;
  `}

  ${media.xl`
    font-size: ${rem(18)};
  `}
`;

const Col = styled(ColGrid)`
  text-align: center;
  margin-bottom: 30px;

  ${media.md`
    margin-bottom: 0;
  `}
`;

const Body = styled.p`
  font-size: ${rem(10)};
  line-height: 1.3;

  ${media.lg`
    font-size: ${rem(12)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

export type Props = {
  className?: string;
  headline?: string;
  grid: IconGrid[];
  size?: {
    col?: number;
    xs?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
};

const IconGridSection: React.FC<Props> = ({
  className,
  headline,
  grid,
  size,
}) => {
  return (
    <Section className={className}>
      <Container>
        {headline && <Headline>{headline}</Headline>}
        <Row justifyContent="center">
          {grid.map(({ icon, title, body }, k) => (
            <Col key={k} {...size}>
              <Icon>{icon}</Icon>
              <Title>{title}</Title>
              <Body>{body}</Body>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default IconGridSection;
