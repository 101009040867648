import React, { useLayoutEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { gsap } from 'gsap';

const userPathFirmnessMap: Record<number, string> = {
  9: 'M2 35C145.977 35 122.023 68 266 68 C409.977 68 386.023 35 530 35',
  7.5: 'M2 35C145.977 35 122.023 116.5 266 116.5C409.977 116.5 386.023 35 530 35',
  6.5: 'M2 35C145.977 35 122.023 143 266 143C409.977 143 386.023 35 530 3',
};

const mattressPathFirmnessMap: Record<number, string> = {
  9: 'M2 66 C145.977 66 122.023 88 266 88 C409.977 88 386.023 66 530 66',
  7.5: 'M2 82C145.977 82 122.023 131 266 131C409.977 131 386.023 82 530 82',
  6.5: 'M2 82C145.977 82 122.023 154 266 154C409.977 154 386.023 82 530 82',
};

const arrowFirmnessMap: Record<number, string> = {
  9: '0',
  7.5: '40px',
  6.5: '70px',
};

const UserPath = styled.path``;

const MattressPath = styled.path``;

const ArrowPath = styled.path`
  opacity: 0;
  transform: translateY(-30%);
`;

const Svg = styled.svg``;

type Props = {
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
  firmness: number;
  duration?: number;
};

const FirmnessChart: React.FC<Props> = ({
  className,
  firmness,
  duration = 2,
}) => {
  const [ref, inView] = useInView({ threshold: 0.7, triggerOnce: true });

  const animateFirmPath = (id: string, firmnessMap: Record<number, string>) => {
    const $path = document.querySelector(`#${id}`);
    if (!$path) return;
    gsap.to($path, {
      duration,
      attr: { d: firmnessMap[firmness] },
    });
  };

  const animateArrowPath = () => {
    const $arrows = document.querySelectorAll('.arrow-path');
    if (!$arrows) return;
    gsap.to($arrows, {
      duration,
      y: arrowFirmnessMap[firmness],
      opacity: 1,
    });
  };

  useLayoutEffect(() => {
    if (!inView) return;
    animateFirmPath('user-path', userPathFirmnessMap);
    animateFirmPath('mattress-path', mattressPathFirmnessMap);
    animateArrowPath();
  }, [inView]);

  return (
    <Svg
      ref={ref}
      className={className}
      width="530"
      height="169"
      viewBox="0 0 530 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_281_926"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="530"
        height="169"
      >
        <rect width="530" height="169" fill="url(#paint0_linear_281_926)" />
      </mask>
      <g mask="url(#mask0_281_926)">
        <line x1="20" y1="67.5" x2="510" y2="67.5" stroke="#D6D8D2" />
        <line x1="20" y1="34.5" x2="510" y2="34.5" stroke="#D6D8D2" />
        <line x1="20" y1="102.5" x2="510" y2="102.5" stroke="#D6D8D2" />
        <line x1="20" y1="131.5" x2="510" y2="131.5" stroke="#D6D8D2" />
        <line x1="20" y1="154.5" x2="510" y2="154.5" stroke="#D6D8D2" />
      </g>
      <mask
        id="mask1_281_926"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="20"
        y="0"
        width="490"
        height="169"
      >
        <rect
          x="20"
          width="490"
          height="169"
          fill="url(#paint1_linear_281_926)"
        />
      </mask>
      <g mask="url(#mask1_281_926)">
        <UserPath
          d="M2 35 C145.977 35 122.023 35 266 35 C409.977 35 386.023 35 530 35"
          stroke="#B77659"
          strokeWidth="2"
          strokeMiterlimit="10"
          id="user-path"
        />
        <MattressPath
          d="M2 66 C145.977 66 122.023 66 266 66 C409.977 66 386.023 66 530 66"
          stroke="#ABC5C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          id="mattress-path"
        />
        <ArrowPath
          d="M267 7C267 6.44772 266.552 6 266 6C265.448 6 265 6.44772 265 7H267ZM265.293 47.7071C265.683 48.0976 266.317 48.0976 266.707 47.7071L273.071 41.3431C273.462 40.9526 273.462 40.3195 273.071 39.9289C272.681 39.5384 272.047 39.5384 271.657 39.9289L266 45.5858L260.343 39.9289C259.953 39.5384 259.319 39.5384 258.929 39.9289C258.538 40.3195 258.538 40.9526 258.929 41.3431L265.293 47.7071ZM265 7V47H267V7H265Z"
          fill="#B77659"
          className="arrow-path"
        />
        <ArrowPath
          d="M240 7C240 6.44772 239.552 6 239 6C238.448 6 238 6.44772 238 7H240ZM238.293 47.7071C238.683 48.0976 239.317 48.0976 239.707 47.7071L246.071 41.3431C246.462 40.9526 246.462 40.3195 246.071 39.9289C245.681 39.5384 245.047 39.5384 244.657 39.9289L239 45.5858L233.343 39.9289C232.953 39.5384 232.319 39.5384 231.929 39.9289C231.538 40.3195 231.538 40.9526 231.929 41.3431L238.293 47.7071ZM238 7V47H240V7H238Z"
          fill="#B77659"
          className="arrow-path"
        />
        <ArrowPath
          d="M294 7C294 6.44772 293.552 6 293 6C292.448 6 292 6.44772 292 7H294ZM292.293 47.7071C292.683 48.0976 293.317 48.0976 293.707 47.7071L300.071 41.3431C300.462 40.9526 300.462 40.3195 300.071 39.9289C299.681 39.5384 299.047 39.5384 298.657 39.9289L293 45.5858L287.343 39.9289C286.953 39.5384 286.319 39.5384 285.929 39.9289C285.538 40.3195 285.538 40.9526 285.929 41.3431L292.293 47.7071ZM292 7V47H294V7H292Z"
          fill="#B77659"
          className="arrow-path"
        />
        <line
          x1="2"
          y1="155"
          x2="530"
          y2="155"
          stroke="#0D1E2A"
          strokeWidth="2"
        />
      </g>
      <path
        d="M6.51988 69.1755C6.77588 70.4075 7.67188 71.6555 9.78387 71.6555C12.9199 71.6555 13.5119 68.3755 13.5119 65.6075C13.5119 62.8235 12.5199 60.7595 9.91188 60.7595C7.65588 60.7595 6.35988 62.3595 6.35988 64.2955C6.35988 66.2315 7.60788 67.6395 9.59188 67.6395C10.6959 67.6395 11.6079 67.2715 12.1039 66.3915C12.0559 67.7195 11.8799 70.4875 9.83188 70.4875C8.80788 70.4875 8.26388 70.0235 7.95988 69.1755H6.51988ZM9.87988 61.9435C11.7039 61.9435 11.9439 63.6555 11.9439 64.2955C11.9439 65.7035 11.2879 66.4715 9.83188 66.4715C8.58388 66.4715 7.81588 65.5915 7.81588 64.2315C7.81588 62.9515 8.43988 61.9435 9.87988 61.9435Z"
        fill="#717D77"
      />
      <path
        d="M516.52 69.1755C516.776 70.4075 517.672 71.6555 519.784 71.6555C522.92 71.6555 523.512 68.3755 523.512 65.6075C523.512 62.8235 522.52 60.7595 519.912 60.7595C517.656 60.7595 516.36 62.3595 516.36 64.2955C516.36 66.2315 517.608 67.6395 519.592 67.6395C520.696 67.6395 521.608 67.2715 522.104 66.3915C522.056 67.7195 521.88 70.4875 519.832 70.4875C518.808 70.4875 518.264 70.0235 517.96 69.1755H516.52ZM519.88 61.9435C521.704 61.9435 521.944 63.6555 521.944 64.2955C521.944 65.7035 521.288 66.4715 519.832 66.4715C518.584 66.4715 517.816 65.5915 517.816 64.2315C517.816 62.9515 518.44 61.9435 519.88 61.9435Z"
        fill="#717D77"
      />
      <path
        d="M6.84975 38.4795V27.9035H5.79375C5.07375 28.5915 4.04975 29.1835 3.23375 29.4075V30.5435C3.82575 30.4315 4.89775 29.9995 5.40975 29.6315V38.4795H6.84975ZM12.9808 27.7595C10.1328 27.7595 9.25275 30.5115 9.25275 33.2155C9.25275 35.9355 10.0528 38.6715 12.9328 38.6715C15.8768 38.6715 16.6128 35.7435 16.6128 33.1355C16.6128 30.4475 15.8128 27.7595 12.9808 27.7595ZM12.9968 28.9595C14.7888 28.9595 15.1408 31.1195 15.1408 33.1515C15.1408 35.3275 14.8048 37.4715 12.9488 37.4715C11.0928 37.4715 10.7408 35.2155 10.7408 33.2155C10.7408 30.8955 11.1568 28.9595 12.9968 28.9595Z"
        fill="#717D77"
      />
      <path
        d="M516.85 38.4795V27.9035H515.794C515.074 28.5915 514.05 29.1835 513.234 29.4075V30.5435C513.826 30.4315 514.898 29.9995 515.41 29.6315V38.4795H516.85ZM522.981 27.7595C520.133 27.7595 519.253 30.5115 519.253 33.2155C519.253 35.9355 520.053 38.6715 522.933 38.6715C525.877 38.6715 526.613 35.7435 526.613 33.1355C526.613 30.4475 525.813 27.7595 522.981 27.7595ZM522.997 28.9595C524.789 28.9595 525.141 31.1195 525.141 33.1515C525.141 35.3275 524.805 37.4715 522.949 37.4715C521.093 37.4715 520.741 35.2155 520.741 33.2155C520.741 30.8955 521.157 28.9595 522.997 28.9595Z"
        fill="#717D77"
      />
      <path
        d="M9.94575 106.671C12.1218 106.671 13.6898 105.471 13.6898 103.455C13.6898 102.047 12.8098 101.055 11.8178 100.735C12.6658 100.415 13.2738 99.6795 13.2738 98.5435C13.2738 96.9275 12.0578 95.7595 10.0898 95.7595C7.91375 95.7595 6.76175 96.9435 6.76175 98.5755C6.76175 99.6155 7.30575 100.351 8.23375 100.799C7.32175 101.151 6.31375 102.015 6.31375 103.503C6.31375 105.455 7.89775 106.671 9.94575 106.671ZM10.0098 105.503C8.58575 105.503 7.76975 104.607 7.76975 103.439C7.76975 102.287 8.47375 101.407 10.0098 101.407C11.4018 101.407 12.2498 102.111 12.2498 103.439C12.2498 104.799 11.3378 105.503 10.0098 105.503ZM10.0418 96.9275C11.3058 96.9275 11.8818 97.6955 11.8818 98.6235C11.8818 99.5995 11.3218 100.207 10.0578 100.207C8.72975 100.207 8.15375 99.4555 8.15375 98.5755C8.15375 97.5995 8.69775 96.9275 10.0418 96.9275Z"
        fill="#717D77"
      />
      <path
        d="M519.946 106.671C522.122 106.671 523.69 105.471 523.69 103.455C523.69 102.047 522.81 101.055 521.818 100.735C522.666 100.415 523.274 99.6795 523.274 98.5435C523.274 96.9275 522.058 95.7595 520.09 95.7595C517.914 95.7595 516.762 96.9435 516.762 98.5755C516.762 99.6155 517.306 100.351 518.234 100.799C517.322 101.151 516.314 102.015 516.314 103.503C516.314 105.455 517.898 106.671 519.946 106.671ZM520.01 105.503C518.586 105.503 517.77 104.607 517.77 103.439C517.77 102.287 518.474 101.407 520.01 101.407C521.402 101.407 522.25 102.111 522.25 103.439C522.25 104.799 521.338 105.503 520.01 105.503ZM520.042 96.9275C521.306 96.9275 521.882 97.6955 521.882 98.6235C521.882 99.5995 521.322 100.207 520.058 100.207C518.73 100.207 518.154 99.4555 518.154 98.5755C518.154 97.5995 518.698 96.9275 520.042 96.9275Z"
        fill="#717D77"
      />
      <path
        d="M6.53131 124.951V126.183H11.9553C10.2593 128.423 8.38731 132.487 7.90731 135.479H9.36331C9.89131 132.375 11.6033 128.567 13.4433 126.103V124.951H6.53131Z"
        fill="#717D77"
      />
      <path
        d="M516.531 124.951V126.183H521.955C520.259 128.423 518.387 132.487 517.907 135.479H519.363C519.891 132.375 521.603 128.567 523.443 126.103V124.951H516.531Z"
        fill="#717D77"
      />
      <path
        d="M13.3694 150.239C13.2414 149.151 12.4094 147.759 10.2654 147.759C7.33738 147.759 6.39338 150.447 6.39338 153.359C6.39338 156.319 7.24138 158.671 10.1534 158.671C12.4734 158.671 13.7054 156.911 13.7054 154.831C13.7054 152.815 12.5054 151.327 10.4734 151.327C9.01738 151.327 8.23338 152.047 7.80138 152.799C7.84938 150.575 8.42538 148.911 10.2014 148.911C11.2734 148.911 11.7694 149.487 11.9774 150.239H13.3694ZM10.1374 157.487C8.39338 157.487 7.96137 155.791 7.96137 154.927C7.96137 153.343 8.87338 152.511 10.1854 152.511C11.8014 152.511 12.2494 153.759 12.2494 154.943C12.2494 156.255 11.6574 157.487 10.1374 157.487Z"
        fill="#717D77"
      />
      <path
        d="M523.369 150.239C523.241 149.151 522.409 147.759 520.265 147.759C517.337 147.759 516.393 150.447 516.393 153.359C516.393 156.319 517.241 158.671 520.153 158.671C522.473 158.671 523.705 156.911 523.705 154.831C523.705 152.815 522.505 151.327 520.473 151.327C519.017 151.327 518.233 152.047 517.801 152.799C517.849 150.575 518.425 148.911 520.201 148.911C521.273 148.911 521.769 149.487 521.977 150.239H523.369ZM520.137 157.487C518.393 157.487 517.961 155.791 517.961 154.927C517.961 153.343 518.873 152.511 520.185 152.511C521.801 152.511 522.249 153.759 522.249 154.943C522.249 156.255 521.657 157.487 520.137 157.487Z"
        fill="#717D77"
      />
      <defs>
        <linearGradient
          id="paint0_linear_281_926"
          x1="4.29941"
          y1="71.5"
          x2="530"
          y2="71.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="0.410722" stopColor="#D9D9D9" stopOpacity="0" />
          <stop offset="0.58398" stopColor="#D9D9D9" stopOpacity="0" />
          <stop offset="1" stopColor="#D9D9D9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_281_926"
          x1="23.9749"
          y1="71.5"
          x2="510"
          y2="71.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" stopOpacity="0" />
          <stop offset="0.182698" stopColor="#D9D9D9" />
          <stop offset="0.778059" stopColor="#D9D9D9" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default FirmnessChart;
