import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { BgHeroCompareMattress } from '../assets';
import { rem } from '../../styles/utils';
import { ImgPoint } from '../assets';

const Section = styled.section`
  color: var(--font-secondary-color);
  font-family: var(--font-body);
  width: 100%;
  height: auto;
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 430px;
`;

const Img = styled(BgHeroCompareMattress)`
  width: 100%;
  height: 100%;
`;

const HeadlineContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
  text-align: center;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 20px;

  ${media.lg`
    font-size: ${rem(42)};
    margin-bottom: 30px;
  `}
`;

const PointContainer = styled.div`
  display: block;
  justify-content: center;
  .points:first-child {
    margin-right: 0;
  }

  ${media.lg`
    display: flex;
    .points:first-child {
      margin-right: 30px;
    }
  `}
`;

const Points = styled.div`
  margin-top: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const PointText = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(14)};
  color: var(--font-primary-color);
  margin: 0;
  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const PointIcon = styled(ImgPoint)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const CompareMattressHeroSection: React.FC = () => {
  return (
    <Section>
      <ImgContainer>
        <Img>
          <HeadlineContainer>
            <Headline>Find the mattress for you.</Headline>
            <PointContainer>
              <Points className="points">
                <PointIcon />
                <PointText>Designed and tested for Singaporeans</PointText>
              </Points>
              <Points className="points">
                <PointIcon />
                <PointText>Made in Belgium</PointText>
              </Points>
            </PointContainer>
          </HeadlineContainer>
        </Img>
      </ImgContainer>
    </Section>
  );
};

export default CompareMattressHeroSection;
