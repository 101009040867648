import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import { ImgStorageBaseBenefit1, ImgStorageBaseBenefit2 } from '../assets';

const Section = styled(ProductBenefitSection)`
  background-color: white;
`;

const StorageBaseBenefitSection: React.FC = () => (
  <Section
    version="v2"
    headline="Features"
    benefits={[
      {
        img: <ImgStorageBaseBenefit1 />,
        eyebrow: 'Floats like a Butterfly',
        headline: 'One Pull Magic',
        body:
          'The Woosa Storage Bed Base features the easiest hydraulic lift in the market. The bed frame is made with solid wood construction, and our exclusive Denmark-made SUSPA hydraulic system ensures that the storage bed rises automatically with a tug of the latch and closes with ease.',
      },
      {
        img: <ImgStorageBaseBenefit2 />,
        eyebrow: 'Cavernous Storage',
        headline: 'Stylish sit-in design',
        body:
          'Featuring a unique sit-in design, The Woosa Storage Bed Base boasts a generous depth of 30cm, perfect for accommodating even the largest check-in luggage. With its top-notch engineering and efficient use of space, it sets an impressive standard for hydraulic storage beds available in mattress and bed stores across Singapore.',
      },
    ]}
  />
);

export default StorageBaseBenefitSection;
