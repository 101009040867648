import React, { useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import SvgInfo from '../../static/images/info-24px.svg';

import Expander from './expander';
import { Subtitle } from './product-accordion-section';

const Container = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`;

const Icon = styled.div`
  margin-bottom: 10px;

  ${media.lg`
    width: 80%;
    margin: 0 auto;
  `}

  ${media.xl`
    width: 70%;
  `}
`;

const Trigger = styled.div`
  cursor: pointer;

  .icon-info {
    width: 15px;
    height: 15px;
  }
`;

type Props = {
  className?: string;
  icon: React.ReactNode;
  title: React.ReactNode | string;
  content: React.ReactNode;
};

const IconExpander: React.FC<Props> = ({ className, icon, title, content }) => {
  const ref = useRef<HTMLDivElement>(null);

  const toggleParentAccordion = () => {
    const parentAccordion =
      ref.current?.parentElement?.parentElement?.parentElement?.parentElement;
    if (parentAccordion) {
      if (!parentAccordion.classList.contains('product-accordion-body')) return;
      parentAccordion.setAttribute(
        `style`,
        `max-height: ${parentAccordion.scrollHeight}px`
      );
    }
  };

  return (
    <Container className={className} ref={ref}>
      <Icon>{icon}</Icon>
      <Expander
        trigger={
          <Trigger>
            <Subtitle>
              {title}&nbsp;
              <SvgInfo className="icon-info" />
            </Subtitle>
          </Trigger>
        }
        onChange={toggleParentAccordion}
      >
        {content}
      </Expander>
    </Container>
  );
};

export default IconExpander;
