import React from 'react';
import { media, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import {
  ImgFullOri,
  ImgFullMysa,
  ImgFullTerra,
  ImgCloseupOri,
  ImgCloseupMysa,
  ImgCloseupTerra,
} from '../assets';
import { Link } from '../common';
import {
  rem,
  sectionMarginStyle,
  lh,
  flatButtonStyle,
} from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';

const Section = styled.section`
  padding-top: 40px;

  ${media.lg`
    padding-top: 80px;
  `}

  ${sectionMarginStyle}
`;

const Body = styled(Link)`
  text-decoration: none;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  line-height: ${lh(36, 24)};
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  padding: 0 30px;

  & br {
    display: block;
  }

  ${media.lg`
    font-size: ${rem(42)};
    padding: 0;
  `}

  ${media.md`
    & br {
      display: none;
    }
  `}
`;

const Title = styled.h2`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin: 15px 0;
  font-weight: 400;
  text-align: center;

  ${media.lg`
    font-size: ${rem(32)};
  `}
`;

const Subtitle = styled.h2`
  font-family: var(--font-body);
  font-size: ${rem(12)};
  color: var(--font-primary-color);
  font-weight: 700;
  text-align: center;
  color: var(--santa-fe);

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const Description = styled.h2`
  font-family: var(--font-header);
  font-size: ${rem(14)};
  color: var(--font-primary-color);
  line-height: ${lh(17, 13)};
  margin: 15px 0;
  font-weight: 400;
  text-align: center;
  padding: 0 80px;

  ${media.lg`
    font-size: ${rem(16)};
  `}

  ${media.sm`
    padding: 0 30px;
  `}
`;

const Img = styled.div`
  display: none;
  position: relative;
  padding-bottom: 50px;
  ${media.sm`
    display: block;
  `}
`;

const ResImg = styled.div`
  display: block;
  position: relative;
  padding-bottom: 50px;
  width: 80%;
  margin: auto;
  ${media.sm`
    display: none;
  `}
`;

const ImgCloseup = styled.div`
  position: absolute;
  top: 30%;
  left: 7%;
  width: 25%;
`;

const BoxShadow = styled.div`
  position: absolute;
  bottom: 53px;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
`;

const BoxShadowInner = styled.div`
  width: 72%;
  box-shadow: -2px 51px 30px -9px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: -2px 51px 30px -9px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: -2px 51px 30px -9px rgba(0, 0, 0, 0.07);
`;

const ButtonDiv = styled.div`
  text-align: center;
  margin: 15px 0;
`;

const Button = styled(Link)`
  ${flatButtonStyle()}
  padding: 12px 32px;

  ${media.lg`
    padding: 15px 45px;
  `}
`;

const CompareMattressesSection: React.FC = () => {
  const mattresses = [
    {
      url: SiteUrls.OriginalMattress,
      asset: <ImgFullOri />,
      closeup: <ImgCloseupOri />,
      title: 'The Original Mattress',
      subtitle: 'For Back or Side Sleepers',
      description:
        'For medium-firm comfort. Made with our signature 4-layer combination of premium Sonocore® Latex and European-made.',
    },
    {
      url: SiteUrls.TerraMattress,
      asset: <ImgFullTerra />,
      closeup: <ImgCloseupTerra />,
      title: 'The Terra Mattress',
      subtitle: 'For Back or Heavy Side-Sleepers',
      description:
        'For extra-firm comfort. Made with double layers of 100% Natural Latex for max support and posture alignment.',
    },
    {
      url: SiteUrls.MysaMattress,
      asset: <ImgFullMysa />,
      closeup: <ImgCloseupMysa />,
      title: 'The Mysa Mattress',
      subtitle: 'For Side, Stomach or Light-back Sleepers',
      description:
        'For a medium-plush hotel-style feel. A luxurious sleeping surface made possible with our very own Woosa Cloud Foam.',
    },
  ];

  const renderImg = (asset: JSX.Element, closeup: JSX.Element) => {
    return (
      <>
        {asset}
        <BoxShadow>
          <BoxShadowInner />
        </BoxShadow>
        <ImgCloseup>{closeup}</ImgCloseup>
      </>
    );
  };

  return (
    <Section>
      <Headline>
        Sleep Better With <br /> Woosa’s Mattresses
      </Headline>
      <Row justifyContent="center">
        {mattresses.map((m, key) => {
          return (
            <Col sm={3} noGutter key={key}>
              <Body to={m.url}>
                <Img>{renderImg(m.asset, m.closeup)}</Img>
              </Body>
            </Col>
          );
        })}
      </Row>
      <Row justifyContent="center">
        {mattresses.map((m, key) => {
          return (
            <Col sm={3} noGutter key={key}>
              <Body to={m.url}>
                <ResImg>{renderImg(m.asset, m.closeup)}</ResImg>
                <Subtitle>{m.subtitle}</Subtitle>
                <Title>{m.title}</Title>
                <Description>{m.description}</Description>
              </Body>
            </Col>
          );
        })}
      </Row>

      <ButtonDiv>
        <Button to={SiteUrls.ComparisonMattress} type="flat">
          Compare mattresses
        </Button>
      </ButtonDiv>
    </Section>
  );
};

export default CompareMattressesSection;
