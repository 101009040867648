import moment from 'moment';
import { useEffect, useState } from 'react';

import { Asset } from '../types/component';
import { ProductOrderItem } from '../types/order';
import { Product, Variant } from '../types/product';
import { generateProductRefKey } from '../utils';
import {
  fbq,
  gtag,
  toGtagProduct,
  toTtqProduct,
  ttq,
} from '../utils/analytics';
import { getProductById } from '../utils/api';
import {
  FacebookPixelEvent,
  GTagEvent,
  GTagType,
  TikTokEvent,
} from '../utils/constants';
import { useCartDispatch } from './cart-context';
import { animateScroll as scroll } from 'react-scroll';

type Props = {
  id: string;
  name: string;
  collection: string;
  media: {
    path: string;
    default: boolean;
    type: 'image' | 'video';
  }[];
  variants: Variant[];
  rating: {
    avg: number;
    count: number;
    total: number;
  };
  assets?: Asset[];
  subVariantAssets?: {
    [value: string]: Asset[];
  };
  autoScroll?: boolean;
};

type ReturnValue = {
  product: Product;
  selectedVariant: Variant | undefined;
  isLoading: boolean;
  selectedAddOn: ProductOrderItem | null;
  selectedCustomAddOn: ProductOrderItem | null;
  selectedProductOrderItem: ProductOrderItem | null;
  selectedProductPlusCover: ProductOrderItem | null;
  assets: Asset[];
  handleProductCustomAddOnChange: (p: ProductOrderItem | null) => void;
  handleAddToCart: () => void;
  handleProductAddOnChange: (p: ProductOrderItem | null) => void;
  handleProductVariantDropdownChange: (v: Variant | undefined) => void;
  handleProductOrderItemChange: (p: ProductOrderItem | null) => void;
  handleProductPlusCoverChange: (p: ProductOrderItem | null) => void;
};

export default function useAddToCart({
  id,
  collection,
  name,
  media,
  variants,
  rating,
  assets: productAssets,
  subVariantAssets,
  autoScroll = false,
}: Props): ReturnValue {
  const [product, setProduct] = useState<Product>({
    id,
    collection,
    name,
    variants,
    media: [],
    rating,
  });
  const [selectedVariant, setSelectedVariant] = useState<Variant | undefined>();
  const [
    selectedProductOrderItem,
    setSelectedProductOrderItem,
  ] = useState<ProductOrderItem | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAddOn, setSelectedAddOn] = useState<ProductOrderItem | null>(
    null
  );
  const [
    selectedProductPlusCover,
    setselectedProductPlusCover,
  ] = useState<ProductOrderItem | null>(null);
  const [isPlusCoverEnabled, setIsPlusCoverEnabled] = useState(false);
  const [
    selectedCustomAddOn,
    setSelectedCustomAddOn,
  ] = useState<ProductOrderItem | null>(null);
  const [assets, setAssets] = useState(productAssets);
  const dispatch = useCartDispatch();

  useEffect(() => {
    setIsLoading(true);
    setIsPlusCoverEnabled(false);
    getProductById(id)
      .then(data => {
        setProduct({
          ...product,
          variants: [...data.variants],
          media: [...data.media],
          subVariantKeys: data.subVariantKeys && [...data.subVariantKeys],
        });
        if (data.variants.length > 0) {
          setSelectedVariant({ ...data.variants[0] });
        }
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  const handleProductCustomAddOnChange = (p: ProductOrderItem | null) => {
    setSelectedCustomAddOn(p);
    if (!assets) return;
    const newAssets = assets.filter(a => !a.isCustom);

    if (p) {
      newAssets.unshift({ type: 'image', src: p.image, isCustom: true });
      if (p.statusType === 'Custom' && autoScroll) {
        scrollToTop();
      }
    }

    setAssets(newAssets);
  };

  const handleSelectedVariantAddToCart = () => {
    if (!selectedVariant || !product || selectedProductOrderItem) return;
    const { compareAmount, amount, currency } = selectedVariant.prices[0];
    const addProduct: ProductOrderItem = {
      id,
      name,
      collection,
      image:
        (product.media || media).find(
          m =>
            (m.default && m.type !== 'video') ||
            (!m.default && m.type === 'image')
        )?.path || '',
      comparePrice: compareAmount || 0,
      currency,
      qty: 1,
      subtotal: amount,
      unitPrice: amount,
      variant: selectedVariant.type,
      isNew: true,
      status: selectedVariant.status,
      statusDate:
        selectedVariant.statusDate &&
        moment.unix(selectedVariant.statusDate._seconds).toDate(),
      sku: selectedVariant.sku,
      refKeys: selectedCustomAddOn
        ? [generateProductRefKey(selectedCustomAddOn)]
        : [],
    };

    dispatch({ type: 'OPEN_DRAWER' });
    dispatch({ type: 'ADD', product: addProduct });
    sendAnalytics(addProduct);
  };

  const handleSelectedProductOrderItemAddToCart = () => {
    if (!selectedProductOrderItem) return;
    dispatch({ type: 'OPEN_DRAWER' });
    dispatch({ type: 'ADD', product: selectedProductOrderItem });
    sendAnalytics(selectedProductOrderItem);
  };

  const sendAnalytics = (product: ProductOrderItem) => {
    fbq(FacebookPixelEvent.AddToCart);
    gtag(GTagType.Event, GTagEvent.AddToCart, toGtagProduct(product));
    ttq(TikTokEvent.AddToCart, toTtqProduct(product));
  };

  const handleAddToCart = () => {
    handleSelectedVariantAddToCart();
    handleSelectedProductOrderItemAddToCart();

    if (selectedAddOn) {
      dispatch({ type: 'ADD', product: { ...selectedAddOn } });
    }

    if (selectedCustomAddOn) {
      dispatch({ type: 'ADD', product: { ...selectedCustomAddOn } });
    }

    if (selectedProductPlusCover) {
      dispatch({ type: 'ADD', product: { ...selectedProductPlusCover } });
    }
  };

  const handleProductAddOnChange = (p: ProductOrderItem | null) => {
    setSelectedAddOn(p);
  };

  const handleProductVariantDropdownChange = (v: Variant | undefined) => {
    setSelectedVariant(v);
  };

  const handleProductPlusCoverChange = (p: ProductOrderItem | null) => {
    setselectedProductPlusCover(p);
    if (!assets) return;
    let newAssets = assets;
    const assetLength = newAssets.filter(x => x.isPlusCover).length;

    if (p) {
      if (!newAssets[newAssets.length - 1].isPlusCover) return;
      const lastTwo = newAssets.splice(
        newAssets.length - assetLength,
        assetLength
      );
      newAssets = lastTwo.concat(newAssets);
      setAssets(newAssets);

      if (autoScroll) {
        scrollToTop();
      }
    } else {
      if (!newAssets[0].isPlusCover) return;

      for (let i = 0; i < assetLength; i++) {
        if (newAssets[0].isPlusCover) {
          const shiftedNewAssets = newAssets.shift();
          if (shiftedNewAssets) {
            newAssets.push(shiftedNewAssets);
          }
        }
      }
    }
    //
  };

  const handleProductOrderItemChange = (p: ProductOrderItem | null) => {
    setSelectedProductOrderItem(p);

    // decide on whether to display subvariant assets over the default assets
    // this only works for 1 sub variant product
    if (p && p.subVariants && p.subVariants.length > 0) {
      // find the first non empty sub variant value, there should only be 1
      // if BE returns multiple though sub variant keys === 1, we can still manage to get the right one with this logic
      // and we can't take the first sub variant as the array might not be sorted properly.
      const nonEmptySubVariantValue = p.subVariants.find(s => s.value);
      if (
        nonEmptySubVariantValue &&
        subVariantAssets &&
        subVariantAssets[nonEmptySubVariantValue.value] &&
        subVariantAssets[nonEmptySubVariantValue.value].length > 0
      ) {
        const newAssets = subVariantAssets[nonEmptySubVariantValue.value];
        setAssets(newAssets);
        return;
      }
    }

    if (!assets) return;
    const newAssets = assets.filter(a => !a.isCustom);

    if (p) {
      newAssets.unshift({ type: 'image', src: p.image, isCustom: true });
      if (p.statusType === 'Custom' && autoScroll) {
        scrollToTop();
      }
    }

    setAssets(newAssets);
  };

  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 500 });
  };

  return {
    product,
    isLoading,
    selectedVariant,
    selectedAddOn,
    selectedCustomAddOn,
    selectedProductOrderItem,
    selectedProductPlusCover,
    assets: assets || [],
    handleProductCustomAddOnChange,
    handleAddToCart,
    handleProductAddOnChange,
    handleProductVariantDropdownChange,
    handleProductOrderItemChange,
    handleProductPlusCoverChange,
  };
}
