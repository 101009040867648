import React from 'react';
import { PRODUCT_ORIGINAL_MATTRESS_ID } from 'gatsby-env-variables';

import { ProductReviewHeroSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const OriginalMattressReviewHeroSection: React.FC = () => {
  return (
    <ProductReviewHeroSection
      productId={PRODUCT_ORIGINAL_MATTRESS_ID}
      allReviewsUrl={SiteUrls.OriginalMattressReview}
    />
  );
};

export default OriginalMattressReviewHeroSection;
