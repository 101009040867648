import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { ProductAccordionSection, Video, ProductSizeTileList } from '../common';
import {
  Paragraph,
  UnorderedList,
  ListItem,
  Subtitle,
} from '../common/product-accordion-section';
import { Accordion } from '../../types/component';
import { ImgBedFrameMaterial3 } from '../assets';
import DesignProcessVideo from '../../static/videos/adjustable-bed-frame-design-process.mp4';
import FabricMaterialVideo from '../../static/videos/adjustable-bed-frame-fabric.mp4';
import { SiteUrls } from '../../utils/constants';

import AdjustableBaseTufted from '../../assets/adjustable-base-tufted.jpeg';
import AdjustableBaseTuftedSuperSingle from '../../assets/adjustable-base-dimensions/tufted/1_SUPERSINGLE.png';
import AdjustableBaseTuftedSingle from '../../assets/adjustable-base-dimensions/tufted/1_SINGLE.png';
import AdjustableBaseTuftedQueen from '../../assets/adjustable-base-dimensions/tufted/1_QUEEN.png';
import AdjustableBaseTuftedKing from '../../assets/adjustable-base-dimensions/tufted/1_KING.png';

import AdjustableBaseFluted from '../../assets/adjustable-base-fluted.jpeg';
import AdjustableBaseFlutedSuperSingle from '../../assets/adjustable-base-dimensions/fluted/2_SUPERSINGLE.png';
import AdjustableBaseFlutedSingle from '../../assets/adjustable-base-dimensions/fluted/2_SINGLE.png';
import AdjustableBaseFlutedQueen from '../../assets/adjustable-base-dimensions/fluted/2_QUEEN.png';
import AdjustableBaseFlutedKing from '../../assets/adjustable-base-dimensions/fluted/2_KING.png';

import AdjustableBaseCurved from '../../assets/adjustable-base-curved.jpeg';
import AdjustableBaseCurvedSuperSingle from '../../assets/adjustable-base-dimensions/curved/3_SUPERSINGLE.png';
import AdjustableBaseCurvedSingle from '../../assets/adjustable-base-dimensions/curved/3_SINGLE.png';
import AdjustableBaseCurvedQueen from '../../assets/adjustable-base-dimensions/curved/3_QUEEN.png';
import AdjustableBaseCurvedKing from '../../assets/adjustable-base-dimensions/curved/3_King.png';

import AdjustableBaseClassic from '../../assets/adjustable-base-classic.jpeg';
import AdjustableBaseClassicSuperSingle from '../../assets/adjustable-base-dimensions/classic/4_SUPERSINGLE.png';
import AdjustableBaseClassicSingle from '../../assets/adjustable-base-dimensions/classic/4_SINGLE.png';
import AdjustableBaseClassicQueen from '../../assets/adjustable-base-dimensions/classic/4_QUEEN.png';
import AdjustableBaseClassicKing from '../../assets/adjustable-base-dimensions/classic/4_KING.png';

const FlexAlignCenterCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const ProductSizeList = styled(ProductSizeTileList)`
  margin: 20px 0;
`;

const data: Accordion[] = [
  {
    title: 'Design Process',
    body: (
      <Row>
        <Col xs={12} md={6} mdOrder="last">
          <Video src={DesignProcessVideo} />
        </Col>
        <FlexAlignCenterCol xs={12} md={6}>
          <Paragraph>
            Traditional lines true to our modern style. The slim profile of the
            adjustable base fits sungly into our custom headboard and sideframe,
            stylish for any bedroom. The Woosa Adjustable bed is powered by
            high-end German motors, and supported by an industrial grade welded
            steel base that can support up to 270kg.
          </Paragraph>
        </FlexAlignCenterCol>
      </Row>
    ),
  },
  {
    title: 'Material & Care',
    body: (
      <>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <Video src={FabricMaterialVideo} />
            <Paragraph fontWeight="bold">WATER RESISTANT FABRIC</Paragraph>
            <UnorderedList>
              <ListItem>High quality linen upholstery fabric</ListItem>
              <ListItem>
                Hydrophobic treatment makes clean up and accidental spills
                simple
              </ListItem>
            </UnorderedList>
            <br />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <ImgBedFrameMaterial3 />
            <Paragraph fontWeight="bold">FULL GRAIN LEATHER</Paragraph>
            <UnorderedList>
              <ListItem>
                Genuine Full Grain Leather headboard for a luxurious touch
              </ListItem>
              <ListItem>
                High quality engineered PU leather sideboards for added
                durability
              </ListItem>
              <ListItem>1 year peel-free guarantee</ListItem>
            </UnorderedList>
          </Col>
        </Row>
      </>
    ),
  },
  {
    title: 'Shipping, Warranty & Returns',
    body: (
      <>
        <Subtitle>Shipping</Subtitle>
        <Paragraph>
          All products are shipped free to your doorstep and inclusive of
          installation. For areas without lift access, an additional $25/per
          level is payable to the delivery team per adjustable base.
        </Paragraph>
        <Subtitle>Warranty</Subtitle>
        <Paragraph>
          The Woosa Adjustable base comes with a 5-year limited on-site
          warranty. Read more{' '}
          <a href={SiteUrls.BedFrameWarranty} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </Paragraph>
        <Subtitle>Returns</Subtitle>
        <Paragraph>
          The Woosa Adjustable Base is eligible for a 30-night Woosa Trial, with
          a restocking fee of $250. Read more{' '}
          <a href={SiteUrls.FreeTrial} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </Paragraph>
      </>
    ),
  },
  {
    title: 'FAQ',
    body: (
      <>
        <Subtitle>
          What are the dimensions of the Adjustable Base? (excluding mattress)
        </Subtitle>
        <Paragraph>
          Height of base: 25cm (standard), 27.5cm, 30cm
          <br />
          Single: 91cm x 191cm
          <br />
          Super Single: 107 x 191cm
          <br />
          Queen: 152cm x 191cm
          <br />
          King/Split-King: 183cm x 191cm
        </Paragraph>
        <Subtitle>
          What are the dimensions of the Adjustable Base with headboard and side
          frame?
        </Subtitle>
        <ProductSizeList
          items={[
            {
              name: 'Tufted',
              image: AdjustableBaseTufted,
              variants: [
                { name: 'Single', image: AdjustableBaseTuftedSingle },
                {
                  name: 'Super Single',
                  image: AdjustableBaseTuftedSuperSingle,
                },
                { name: 'Queen', image: AdjustableBaseTuftedQueen },
                { name: 'King', image: AdjustableBaseTuftedKing },
              ],
            },
            {
              name: 'Fluted',
              image: AdjustableBaseFluted,
              variants: [
                { name: 'Single', image: AdjustableBaseFlutedSingle },
                {
                  name: 'Super Single',
                  image: AdjustableBaseFlutedSuperSingle,
                },
                { name: 'Queen', image: AdjustableBaseFlutedQueen },
                { name: 'King', image: AdjustableBaseFlutedKing },
              ],
            },
            {
              name: 'Curved',
              image: AdjustableBaseCurved,
              variants: [
                { name: 'Single', image: AdjustableBaseCurvedSingle },
                {
                  name: 'Super Single',
                  image: AdjustableBaseCurvedSuperSingle,
                },
                { name: 'Queen', image: AdjustableBaseCurvedQueen },
                { name: 'King', image: AdjustableBaseCurvedKing },
              ],
            },
            {
              name: 'Classic',
              image: AdjustableBaseClassic,
              variants: [
                { name: 'Single', image: AdjustableBaseClassicSingle },
                {
                  name: 'Super Single',
                  image: AdjustableBaseClassicSuperSingle,
                },
                { name: 'Queen', image: AdjustableBaseClassicQueen },
                { name: 'King', image: AdjustableBaseClassicKing },
              ],
            },
          ]}
        />
        <Paragraph>
          Select headboard design and size for full drawn dimensions
          <br />
          <small>
            <sup>*</sup>Buffer additional 1-2cm for manufacturing tolerances
          </small>
        </Paragraph>
        <Subtitle>
          Can I use another mattress on the Woosa Adjustable Base?
        </Subtitle>
        <Paragraph>
          The Woosa Adjustable Base is designed for intended use with Woosa
          Mattress and is also compatible with other high-quality full foam
          mattresses. We do not recommend using spring mattresses with the
          adjustable base, as it may shorten the longevity of both the mattress
          and the adjustable base and may potentially void the base&apos;s
          warranty.
        </Paragraph>
        <a href={SiteUrls.Faq} target="_blank" rel="noreferrer">
          Read All FAQs
        </a>
      </>
    ),
  },
];

const BedFrameAccordionSection: React.FC = () => {
  return <ProductAccordionSection data={data} />;
};

export default BedFrameAccordionSection;
