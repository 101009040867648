import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import Picture from './picture';

const rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const Figure = styled.figure`
  position: relative;
  display: inline-block;
  width: auto;
  min-height: 25vh;
  background-position: 50% 50%;
  background-color: #eee;
  margin: 0;
  overflow: hidden;
  cursor: zoom-in;
  &:before {
    content: '';
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #333;
    border-right-color: #333;
    border-bottom-color: #333;
    animation: ${rotate} 2s linear infinite;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
  }
  &.loaded {
    min-height: auto;
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 0;
    }
  }

  &.fit {
    width: 100%;
  }
`;

const ImgPicture = styled(Picture)`
  img {
    transition: opacity 0.8s;
    display: block;
  }
`;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  src: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webp?: any;
  id?: string;
  className?: string;
  zoom?: string;
  alt?: string;
  width?: string;
  height?: string;
};

const ImageZoom: React.FC<Props> = ({
  src,
  webp,
  id,
  className,
  zoom = '200',
  alt = 'This is an imageZoom image',
  width = '100%',
  height = 'auto',
}) => {
  // define and set default values to the states of the component
  const [zoomed, setZoomed] = useState('1');
  const [position, setPosition] = useState('50% 50%');
  const [imgData, setImgData] = useState<string | undefined>();
  // convert state data into strings to be used as helper classes
  const figureClass = imgData ? 'loaded' : 'loading';
  const figureZoomed = zoomed === '0' ? 'zoomed' : 'fullView';

  const zoomInPosition = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // this will handle the calculations of the area where the image needs to zoom in depending on the user interaction
    const zoomer = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - zoomer.x;
    const offsetY = e.clientY - zoomer.y;
    const x = (offsetX / zoomer.width) * 100;
    const y = (offsetY / zoomer.height) * 100;
    setPosition(`${x}% ${y}% `);
    // OLD JQUERY CODE FOR REF ONLY
    // e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX;
    // e.offsetY ? offsetY = e.offsetY : offsetY = e.touches[0].pageX;
    // console.log({offsetX, offsetY});
    // x = offsetX/zoomer.offsetWidth*100;
    // y = offsetY/zoomer.offsetHeight*100;
  };

  const toggleZoomImage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (zoomed === '0') {
      // zoom out
      setZoomed('1');
    } else {
      //zoom in and set the background position correctly
      setZoomed('0');
      zoomInPosition(e);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // Handle the click events
    toggleZoomImage(e);
  };

  const handleMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // Handle the mouse move events
    if (zoomed === '0') {
      zoomInPosition(e);
    }
  };

  const handleLeave = () => {
    // Resets the state of the component on mouse leave
    setZoomed('1');
    setPosition('50% 50%');
  };

  useEffect(() => {
    // This checks if the prop src was passed when the component was called and throw an error if this is null or set to empty
    if (src === '' || src == null) {
      throw new Error(
        'Prop src must be defined when using ImageZoom component!'
      );
    }

    // Set initial state on component mount
    setZoomed('1');
    const img = new Image();
    img.src = src;
    const load = () => setImgData(img.src);

    img.addEventListener('load', load);
    return () => img.removeEventListener('load', load);
  }, [src]);

  return (
    <Figure
      id={id}
      className={[figureClass, figureZoomed, className].join(' ')}
      style={{
        backgroundImage: 'url(' + imgData + ')',
        backgroundSize: zoom + '%',
        backgroundPosition: position,
      }}
      onClick={handleClick}
      onMouseMove={handleMove}
      onMouseLeave={handleLeave}
    >
      <ImgPicture
        imgProps={{
          alt,
          style: { opacity: zoomed },
          width,
          height,
        }}
        src={{
          png: imgData,
          webp,
        }}
      />
    </Figure>
  );
};

export default ImageZoom;
