import React from 'react';
import styled from 'styled-components';

import Modal from './custom-modal';
import { flatButtonStyle, outlineButtonStyle } from '../../styles/utils';
import { rem } from '../../styles/utils';

const Headline = styled.strong`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin-bottom: 16px;
  display: block;
`;

const Paragraph = styled.div`
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(16)};
  color: var(--font-primary-color);
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div``;

const ProceedButton = styled.button`
  ${flatButtonStyle()}
`;

const CancelButton = styled.button`
  ${outlineButtonStyle('dark')}
  margin-left: 10px;
`;

type Props = {
  className?: string;
  isActive: boolean;
  onClickCancel?: () => void;
  onClickProceed?: () => void;
  title?: string | React.ReactNode;
  body?: string | React.ReactNode;
  proceedButtonText?: string | React.ReactNode;
  cancelButtonText?: string | React.ReactNode;
};

const ConfirmModal: React.FC<Props> = ({
  className,
  isActive,
  onClickCancel,
  onClickProceed,
  title,
  body,
  proceedButtonText = 'Yes',
  cancelButtonText = 'No',
}) => {
  return (
    <Modal className={className} isActive={isActive}>
      <Headline>{title}</Headline>
      <Paragraph>{body}</Paragraph>
      <ButtonContainer>
        <ProceedButton onClick={onClickProceed}>
          {proceedButtonText}
        </ProceedButton>
        <CancelButton onClick={onClickCancel}>{cancelButtonText}</CancelButton>
      </ButtonContainer>
    </Modal>
  );
};

export default ConfirmModal;
