/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

export type Props = {
  className?: string;
  pictureProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  >;
  imgProps?: Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    'src'
  >;
  src: {
    png: any;
    webp?: any;
  };
};

const Picture: React.FC<Props> = ({
  className,
  pictureProps,
  imgProps,
  src,
}) => {
  const { png, webp } = src;

  return (
    <picture
      {...pictureProps}
      className={[pictureProps?.className, className].join(' ').trim()}
    >
      {webp && <source srcSet={webp} type="image/webp" />}
      <img {...imgProps} src={png} />
    </picture>
  );
};

export default Picture;
