import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';
import { Link } from '../common';
import { SiteUrls } from '../../utils/constants';

const Section = styled.div`
  padding: 80px 0 40px 0;
`;

const FaqTitle = styled.h2`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-weight: 400;
  margin: 0 0 40px 0;
  line-height: ${rem(46)};
  font-size: ${rem(42)};
`;

const FaqContent = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: 700;
  margin: 0 0 10px 0;
  font-size: ${rem(20)};
`;

const Description = styled.h2`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-weight: 400;
  margin: 0;
  font-size: ${rem(16)};
  line-height: 21px;
`;

const DescriptionLink = styled(Link)`
  color: var(--font-secondary-color);
`;

const data = [
  {
    title: <Title>Why “Made in Belgium”?</Title>,
    description: (
      <Description>
        We want only the very best components in our mattresses. After extensive
        research and study of materials utilised in existing luxury mattresses
        in the market, Belgium was chosen for its high standards in quality and
        safety, with verifiable certifications and innovative technologies to
        produce the most premium mattresses in the world.
        <br />
        <br />
        At Woosa, we have eliminated the over-excessive components to design an
        indulgent sleeping surface for Singaporeans. Each layer is calibrated to
        just the right thickness, just enough for the body to reap the full
        benefits of these premium materials while maintaining a reasonable
        price.
      </Description>
    ),
  },
  {
    title: <Title>Why use foams and not springs?</Title>,
    description: (
      <Description>
        Through our research and extensive testing, we find that most springs
        tend to give way much faster due to the constant gravitational pressure
        from your body, and offer sub-par weight distribution as compared to
        high quality foams.
        <br />
        <br />
        We wanted a core foundation that is supportive, resilient, durable, and
        eliminates motion transfer - which is best achieved through our premium
        quality, high density PU foam base. Furthermore, our foam components
        react to the body’s weight and heat in a way that springs can never
        achieve. Imagine having a million cells distributing the weight evenly
        across your body, offering support at the most critical points - it’s a
        no brainer.
      </Description>
    ),
  },
  {
    title: <Title>Where can I try out Woosa’s mattresses in Singapore?</Title>,
    description: (
      <Description>
        Visit our{' '}
        <DescriptionLink to={SiteUrls.Appointment}>Sleep Lab</DescriptionLink>{' '}
        to test our mattresses in-person.{' '}
        <DescriptionLink to={SiteUrls.BedFrame}>
          Adjustable bed frames
        </DescriptionLink>{' '}
        and customization options are also available, along with a range of
        bedroom accessories such as{' '}
        <DescriptionLink to={SiteUrls.OriginalPillow}>pillows</DescriptionLink>,{' '}
        <DescriptionLink to={SiteUrls.SeresSilkDuvet}>duvets</DescriptionLink>,{' '}
        <DescriptionLink to={SiteUrls.WaterproofMattressProtector}>
          waterproof mattress protectors
        </DescriptionLink>{' '}
        and more.
      </Description>
    ),
  },
];

const MattressFaqSection: React.FC = () => {
  return (
    <Section>
      <Row>
        <Col lg={5}>
          <FaqTitle>Frequently asked questions</FaqTitle>
        </Col>
        <Col lg={7}>
          {data.map((d, key) => (
            <FaqContent key={key}>
              {d.title}
              {d.description}
            </FaqContent>
          ))}
        </Col>
      </Row>
    </Section>
  );
};

export default MattressFaqSection;
