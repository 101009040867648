import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const ImgContainer = styled.div`
  width: 100%;

  img {
    border-radius: 10px;
  }
`;

const ProfileContainer = styled.div`
  width: 96%;
  position: absolute;
  font-family: var(--font-body);
  font-size: ${rem(10)};
  background-color: white;
  color: var(--nandor);
  border-radius: 10px;
  left: 2%;
  bottom: 0;
  display: flex;
  margin-bottom: 2%;
  padding: 15px;
  text-align: left;
  align-items: flex-start;

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
  `}

  .gatsby-image-wrapper,
  img {
    width: 30%;
    margin-right: 15px;

    ${media.md`
      width: 17%;
    `}

    ${media.lg`
      width: 20%;
    `}

    ${media.xl`
      width: 14%;
      margin-right: 20px;
    `}
  }

  a {
    text-decoration: none;
    color: var(--font-secondary-color);
  }
`;

const Text = styled.p`
  width: calc(70% - 15px);

  ${media.md`
    width: calc(83% - 15px);
  `}

  ${media.lg`
    width: calc(80% - 15px);
  `}

  ${media.xl`
    width: calc(86% - 20px);
  `}
`;

const Content = styled.p`
  margin-bottom: 0;

  &.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Handle = styled.a`
  margin-top: 10px;
  display: block;
`;

const ExpandBtn = styled.button`
  display: block;
  border: 0;
  background-color: transparent;
  color: #0089c7;
  padding: 0;
  margin-top: 5px;
  cursor: pointer;
`;

type Props = {
  img: JSX.Element;
  avatar?: string;
  text: string;
  handle: string;
};

const InstagramCard: React.FC<Props> = ({ img, avatar, text, handle }) => {
  const content = useRef<HTMLParagraphElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const [showExpand, setShowExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (content.current) {
      setShowExpand(
        content.current.scrollHeight > content.current.clientHeight
      );
    }
  }, []);

  const textBlocks = text.split(' ');
  for (let i = 0; i < textBlocks.length; ++i) {
    if (textBlocks[i][0] !== '@') continue;
    textBlocks[i] = `<a href="https://www.instagram.com/${textBlocks[
      i
    ].substring(1)}/" target="_blank" rel="noreferrer">${textBlocks[i]}</a>`;
  }
  text = textBlocks.join(' ');

  return (
    <Container>
      <ImgContainer>{img}</ImgContainer>
      <ProfileContainer ref={container}>
        {avatar ? (
          <>
            <img src={avatar} alt="" role="presentation" />
            <Text dangerouslySetInnerHTML={{ __html: text }} />
          </>
        ) : (
          <div>
            <Content
              ref={content}
              className={isExpanded ? '' : 'truncate'}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {showExpand && (
              <ExpandBtn onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? 'Show less' : 'Read more'}
              </ExpandBtn>
            )}
            <Handle
              href={`https://www.instagram.com/${handle}/`}
              target="_blank"
              rel="noreferrer"
            >
              @{handle}
            </Handle>
          </div>
        )}
      </ProfileContainer>
    </Container>
  );
};

export default InstagramCard;
