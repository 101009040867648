import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';
import ChevronRight from '../../static/images/chevron-right.svg';
import ChevronLeft from '../../static/images/chevron-left.svg';
import GridContainer from './container';
import Link from './link';

const Section = styled.section`
  background-color: var(--cararra);
  width: 100%;
  height: 30vh;

  ${media.xl`
    height: 45vh;
  `}
`;

const Container = styled(GridContainer)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Arrow = styled(Link)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  svg {
    width: 60px;
  }

  ${media.md`
    position: relative;
    left: auto;
    right: auto;
    transform: none;
    top: auto;
  `}
`;

const Headline = styled.h1`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  font-weight: normal;
  color: var(--font-primary-color);
  text-align: center;
  margin: 0 ${rem(20)};
  line-height: 1.2;

  ${media.md`
    margin: 0 ${rem(40)};
    font-size: ${rem(32)};
  `}

  ${media.xl`
    font-size: ${rem(40)};
  `}
`;

const DotList = styled.ul`
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
`;

const DotItem = styled.li`
  display: inline-block;
  margin: 0 8px;
`;

const Dot = styled(Link)`
  display: block;
  width: 8px;
  height: 8px;
  border: 1px solid var(--siam);
  border-radius: 50%;

  &.active {
    background-color: var(--siam);
  }
`;

type Props = {
  children: React.ReactNode;
  nextLink?: string;
  prevLink?: string;
  dotLinks?: string[];
  activeDotIndex?: number;
};

const InfoHero: React.FC<Props> = ({
  children,
  nextLink,
  prevLink,
  dotLinks,
  activeDotIndex,
}) => {
  return (
    <Section>
      <Container>
        {prevLink && (
          <Arrow className="left" to={prevLink}>
            <ChevronLeft />
          </Arrow>
        )}
        <Headline>{children}</Headline>
        {nextLink && (
          <Arrow className="right" to={nextLink}>
            <ChevronRight />
          </Arrow>
        )}
        {dotLinks && (
          <DotList>
            {dotLinks.map((dot, idx) => (
              <DotItem key={idx}>
                <Dot
                  to={dot}
                  className={idx === activeDotIndex ? 'active' : ''}
                />
              </DotItem>
            ))}
          </DotList>
        )}
      </Container>
    </Section>
  );
};

export default InfoHero;
