import React from 'react';
import { PRODUCT_EGYPTIAN_COTTON_FITTED_SHEET } from 'gatsby-env-variables';

import { ProductReviewHeroSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const EgyptianCottonFittedSheetReviewHeroSection: React.FC = () => {
  return (
    <ProductReviewHeroSection
      productId={PRODUCT_EGYPTIAN_COTTON_FITTED_SHEET}
      allReviewsUrl={SiteUrls.EgyptianCottonFittedSheetReview}
    />
  );
};

export default EgyptianCottonFittedSheetReviewHeroSection;
