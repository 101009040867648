import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Post } from '../../types/post';
import Container from './container';
import PostTagList from './post-tag-list';
import { rem } from '../../styles/utils';
import { toMoment } from '../../utils';

const Section = styled.section`
  width: 100%;
  font-family: var(--font-body);
  font-weight: 400;
  margin-top: 40px;

  ${media.lg`  
    margin-top: 80px;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  border-top: 1px solid var(--border-color);
  padding: 13px 0 27px;

  ${media.lg`  
    display: flex;
  `}
`;

const TagList = styled(PostTagList)`
  margin-left: auto;
`;

const FooterText = styled.span`
  font-size: ${rem(16)};
  line-height: 1.3125;
  margin-bottom: 10px;
  display: block;

  ${media.lg`
    margin-bottom: 0;
  `}
`;

const Author = styled.span`
  color: var(--bali-hai);
  border-right: 1px solid #ccc;
  padding-right: 10px;
  margin-right: 10px;
`;

const Timestamp = styled.span`
  color: var(--cornflower-blue);
`;

type Props = {
  post: Post;
};

const PostFooter: React.FC<Props> = ({ post }) => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <FooterText>
            <Author>by {post.author.name}</Author>
            <Timestamp>
              {toMoment(post.publishedOn).format('D MMMM YYYY')}
            </Timestamp>
          </FooterText>
          <TagList post={post} />
        </Wrapper>
      </Container>
    </Section>
  );
};

export default PostFooter;
