import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import DefaultModal from './custom-modal';
import FormInput from './form-input';
import CalendarPicker from './calendar-picker';
import { Error, Button } from '../auth/auth-common-elements';
import { rem, outlineButtonStyle } from '../../styles/utils';
import { sendBookSlotEmail, findAppointmentSlots } from '../../utils/api';
import { fbq } from '../../utils/analytics';
import { FacebookPixelEvent } from '../../utils/constants';
import { Appointment } from '../../types/appointment';

const Form = styled.form`
  font-family: var(--font-body);
  width: 100%;
`;

const Success = styled(Error)`
  color: var(--sirocco);
  font-size: 110%;
  margin-bottom: 20px;
`;

const Headline = styled.h3`
  font-family: var(--font-header);
  margin-bottom: 30px;
  display: block;
  font-weight: bold;
  font-size: ${rem(22)};
  color: black;
`;

const Modal = styled(DefaultModal)`
  .modal-container {
    ${media.lg`
      max-width: 800px;
    `}
  }
`;

const ButtonContainer = styled.div`
  display: none;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;

  ${media.md`
    display: flex;
  `}
`;

const MobileButtonContainer = styled(ButtonContainer)`
  display: flex;

  ${media.md`
    display: none;
  `}
`;

const CancelButton = styled.button`
  ${outlineButtonStyle('dark')}
  margin-right: 10px;
`;

type Props = {
  className?: string;
  display: boolean;
  onClickClose: () => void;
};

type FormData = {
  name: string;
  email: string;
  contact: string;
};

const AppointmentFormModal: React.FC<Props> = ({
  className,
  display,
  onClickClose,
}) => {
  const { register, handleSubmit, errors, reset } = useForm<FormData>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [minDate, setMinDate] = useState<Date>(moment().add(1, 'd').toDate());
  const [time, setTime] = useState<string>('');
  const [date, setDate] = useState<Date>(moment().add(1, 'd').toDate());
  const [month, setMonth] = useState<Date>(moment().toDate());
  const [appointmentSlots, setAppointmentSlots] = useState<Appointment[]>([]);
  const [slots, setSlots] = useState<string[]>([]);

  useEffect(() => {
    setMinDate(moment().add(1, 'd').toDate());
    setDate(moment().add(1, 'd').toDate());
    setMonth(moment().toDate());
  }, []);

  useEffect(() => {
    (async () => {
      const data = await findAppointmentSlots(month);
      setAppointmentSlots(data);
      generateAvailableSlots(date, data);
    })();
  }, [month]);

  const generateAvailableSlots = (
    selectedDate: Date,
    appointmentSlotData: Appointment[]
  ): void => {
    const selectedDateUnix = moment(selectedDate).startOf('day').unix();
    const dateSlot = appointmentSlotData.find(a => a.date === selectedDateUnix);

    if (!dateSlot) {
      setSlots([]);
      return;
    }
    const availableSlots = dateSlot.slots
      .filter(s => s.emptySlot > 0)
      .map(s => s.time);

    setSlots(availableSlots);
    setTime(availableSlots[0]);
  };

  const hasErrors = () => Object.keys(errors).length > 0;

  const handleCloseModal = () => {
    if (success) {
      setSuccess('');
    }
    onClickClose();
  };

  const renderButtons = () => (
    <>
      {' '}
      <CancelButton disabled={loading} type="button" onClick={handleCloseModal}>
        Cancel
      </CancelButton>
      <Button disabled={loading}>Submit</Button>
    </>
  );

  const onSubmit = async (data: FormData) => {
    setError('');
    setSuccess('');

    if (hasErrors() || loading) return;

    setLoading(true);

    const { name, email, contact } = data;
    const result = await sendBookSlotEmail(name, email, contact, date, time);
    fbq(FacebookPixelEvent.Lead);

    if (result) {
      setSuccess(
        'Thank you! Your appointment is confirmed. You should receive an email confirmation shortly.'
      );
      reset();
    } else {
      setError('An error has occurred. Please email us for assistance.');
    }
    setLoading(false);
  };

  return (
    <Modal
      className={`appointment-form-modal ${className}`}
      isActive={display}
      onClickClose={handleCloseModal}
      backdrop={!loading ? 'allowClose' : 'static'}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6} xl={6}>
            <Headline>Book a slot</Headline>
            {success && <Success>{success}</Success>}
            {error && <Error>{error}</Error>}
            <FormInput
              error={errors.name}
              inputRef={register({ required: 'Name is required' })}
              inputProps={{
                placeholder: 'Name',
                name: 'name',
              }}
            />
            <FormInput
              error={errors.email}
              inputRef={register({
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Enter a valid email address',
                },
              })}
              inputProps={{
                placeholder: 'Email Address',
                name: 'email',
              }}
            />
            <FormInput
              error={errors.contact}
              inputRef={register({
                required: 'Contact No. is required',
                pattern: {
                  value: /[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65\s[6|8|9]\d{7}/g,
                  message: 'Enter a valid phone number',
                },
              })}
              inputProps={{
                placeholder: 'Contact No.',
                name: 'contact',
              }}
            />
            <ButtonContainer>{renderButtons()}</ButtonContainer>
          </Col>
          <Col lg={6} xl={6}>
            <CalendarPicker
              datePickerProps={{
                minimumDate: minDate,
                date,
                onDateChange: selectedDate => {
                  if (!selectedDate) return;
                  setDate(selectedDate);

                  // change month only when selected date is different
                  if (selectedDate.getMonth() !== month.getMonth()) {
                    setMonth(selectedDate);
                  } else {
                    generateAvailableSlots(selectedDate, appointmentSlots);
                  }
                },
              }}
              slotProps={{
                placeholder: 'Timing',
                value: time,
                onChange: event => setTime(event.target.value),
                options: slots,
              }}
            />
          </Col>
        </Row>
        <MobileButtonContainer>{renderButtons()}</MobileButtonContainer>
      </Form>
    </Modal>
  );
};

export default AppointmentFormModal;
