import React from 'react';

type Props = {
  children: string;
};

const Unorphan: React.FC<Props> = ({ children }) => {
  const builder = children.split(' ');
  if (builder.length < 2) {
    return <>{builder.join(' ')}</>;
  }
  const lastTwoWords = builder.slice(builder.length - 2);
  const words = builder.splice(0, builder.length - 2);

  return (
    <>
      {[...words, lastTwoWords[0]].join(' ')}&nbsp;{lastTwoWords[1]}
    </>
  );
};

export default Unorphan;
