import React from 'react';

import { MattressLayer } from '../common';

import SvgCover from '../../static/images/mysa-layer-cover.svg';
import SvgPlusCover from '../../static/images/plus-cover-layer.svg';
import Svg1 from '../../static/images/mysa-layer-1.svg';
import Svg2 from '../../static/images/mysa-layer-2.svg';
import Svg3 from '../../static/images/mysa-layer-3.svg';
import Svg4 from '../../static/images/mysa-layer-4.svg';
import Svg5 from '../../static/images/mysa-layer-5.svg';
import Svg6 from '../../static/images/mysa-layer-6.svg';

type Props = {
  isPlusCover?: boolean;
};

const MysaMattressLayerSection: React.FC<Props> = ({ isPlusCover }) => {
  return (
    <>
      <MattressLayer
        version="v2"
        headline="Let’s look under the covers."
        isPlusCover={isPlusCover}
        plusCoverInfo={{
          title: 'The PLUS+ Cover',
          subtitle: '',
          description:
            'Made in Italy from a blend of microtencel and cooling yarn. Quilted with a layer of organic cotton for plushier and firmer feel. Low-friction and allows for seamless movement without generating heat.',
          asset: <SvgPlusCover />,
        }}
        infos={[
          {
            title: 'THE COVER',
            subtitle: 'Italian-made Microtencel',
            description:
              'Incredibly soft and plush microtencel with embedded silver threading for added antimicrobial properties and odour reduction.',
            transition: {
              initial: { x: '-2px', y: '222px' },
              active: { x: '0', y: '-30%' },
              neutral: { x: '0', y: '-55%' },
            },
            asset: <SvgCover />,
            cover: {
              initial: { x: '0', y: '705px', mdY: '705px' },
              active: { x: '0', y: '-635%', mdY: '-580%' },
              neutral: { x: '0', y: '-700%', mdY: '-645%' },
              size: { width: '440px', height: '100px' },
            },
          },
          {
            title: 'THE PLUSH LAYER',
            subtitle: 'Woosa Cloud Foam - 3.5cm',
            description:
              'Creates a plush, weightless sensation with superior airflow.',
            transition: {
              initial: { x: '-20px', y: '121px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-40%' },
            },
            asset: <Svg1 />,
            cover: {
              initial: { x: '0', y: '251px', mdY: '301px' },
              active: { x: '0', y: '-300%', mdY: '-298%' },
              neutral: { x: '0', y: '-600%', mdY: '-596%' },
              size: { width: '380px', height: '19px' },
            },
          },
          {
            title: 'THE COMFORT LAYER',
            subtitle: 'Ultra Premium Sonocore® Latex - 5cm',
            description:
              'For resilient comfort and a gentle bounce to ensure optimal sleeping posture throughout the night.',
            transition: {
              initial: { x: '0', y: '141px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-47%' },
            },
            asset: <Svg2 />,
            cover: {
              initial: { x: '0', y: '269px', mdY: '320px' },
              active: { x: '0', y: '-172%', mdY: '-172%' },
              neutral: { x: '0', y: '-402%', mdY: '-406%' },
              size: { width: '380px', height: '28px' },
            },
          },
          {
            title: 'THE SUPPORT LAYER',
            subtitle: 'Ultra Premium Memory Foam - 5cm',
            description:
              'Offers pressure relieving support with gentle contouring properties.',
            transition: {
              initial: { x: '0', y: '112px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-47%' },
            },
            asset: <Svg3 />,
            cover: {
              initial: { x: '0', y: '298px', mdY: '347px' },
              active: { x: '0', y: '-169%', mdY: '-164%' },
              neutral: { x: '0', y: '-394%', mdY: '-390%' },
              size: { width: '380px', height: '29px' },
            },
          },
          {
            title: 'THE TRANSITION LAYER',
            subtitle: 'High Resiliency Transition Foam - 2.5cm',
            description:
              'Provides additional, deeper levels of comfort and support before easing into the base foam.',
            transition: {
              initial: { x: '0', y: '90px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-49%' },
            },
            asset: <Svg4 />,
            cover: {
              initial: { x: '0', y: '327px', mdY: '377px' },
              active: { x: '0', y: '-202%', mdY: '-202%' },
              neutral: { x: '0', y: '-502%', mdY: '-502%' },
              size: { width: '380px', height: '23px' },
            },
          },
          {
            title: 'THE ALIGNMENT LAYER',
            subtitle: 'Microcoils - 2cm',
            description:
              'Tiny, individual pocketed microcoils for better spinal alignment and airflow.',
            transition: {
              initial: { x: '0', y: '69px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-49%' },
            },
            asset: <Svg5 />,
            cover: {
              initial: { x: '0', y: '349px', mdY: '399px' },
              active: { x: '0', y: '-213%', mdY: '-213%' },
              neutral: { x: '0', y: '-525%', mdY: '-525%' },
              size: { width: '380px', height: '22px' },
            },
          },
          {
            title: 'THE FOUNDATION',
            subtitle: 'Ultra High Density BaseCore Foam - 13cm',
            description:
              'Foundational base for added lift and zero motion transfer when you toss and turn.',
            transition: {
              initial: { x: '0', y: '0' },
              active: { x: '0', y: '0' },
              neutral: { x: '0', y: '0' },
            },
            asset: <Svg6 />,
            cover: {
              initial: { x: '0', y: '371px', mdY: '421px' },
              active: { x: '0', y: '0', mdY: '0' },
              neutral: { x: '0', y: '0', mdY: '0' },
              size: { width: '380px', height: '68px' },
            },
          },
        ]}
      />
    </>
  );
};

export default MysaMattressLayerSection;
