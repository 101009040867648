import { ENABLE_MATTRESS_COMPARISON_PAGE } from 'gatsby-env-variables';
import React from 'react';

import { SiteUrls } from '../../utils/constants';
import {
  ImgCompareMattressDropdown,
  ImgMattressDropdown,
  ImgMysaDropdown,
  ImgTerraDropdown,
} from '../assets';
import { ResponsiveNavItem } from './nav-item';

const MattressNavItem: React.FC = () => {
  const text = 'Mattress';
  const dropdowns = [
    { text: 'The Original Mattress', to: SiteUrls.OriginalMattress },
    { text: 'The Mysa Mattress', to: SiteUrls.MysaMattress },
    { text: 'The Terra Mattress', to: SiteUrls.TerraMattress },
  ];
  const media = [ImgMattressDropdown, ImgMysaDropdown, ImgTerraDropdown];

  if (ENABLE_MATTRESS_COMPARISON_PAGE) {
    dropdowns.push({
      text: 'Compare all mattresses',
      to: SiteUrls.ComparisonMattress,
    });
    media.push(ImgCompareMattressDropdown);
  }

  return (
    <>
      <ResponsiveNavItem text={text} dropdowns={dropdowns} media={media} />
      <ResponsiveNavItem
        className="desktop"
        text={text}
        dropdowns={dropdowns}
        media={media}
      />
    </>
  );
};

export default MattressNavItem;
