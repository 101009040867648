import { State } from './cart-context-types';

export enum CartEnum {
  LOCAL_STORAGE_KEY = 'sg.woosa.cart',
}

export const initialState: State = {
  products: [],
  amounts: {
    totalAmount: 0,
    amount: 0,
    compareAmount: 0,
  },
  tax: {
    amount: 0,
    rate: 0,
  },
  discount: 0,
  shippingFee: 0,
  show: false,
  showPromoBundleModal: false,
  promos: [],
  referralCode: '',
  isLoading: false,
  reconcile: false,
};
