import React from 'react';

import Carousel from '../carousel';
import { SlideProps } from './hero-carousel-utils';
import HeroCarouselImageSlide from './hero-carousel-image-slide';
import HeroCarouselVideoSlide from './hero-carousel-video-slide';

type Props = {
  className?: string;
  slides: SlideProps[];
};

const HeroCarousel: React.FC<Props> = ({ slides, className }) => {
  return (
    <section className={className}>
      <Carousel props={{ showStatus: false, showThumbs: false }}>
        {slides.map((slide, key) => {
          const SlideComponent = !slide.backgroundVideoSrc
            ? HeroCarouselImageSlide
            : HeroCarouselVideoSlide;
          return <SlideComponent {...slide} key={key} />;
        })}
      </Carousel>
    </section>
  );
};

export default HeroCarousel;
