import React from 'react';

import { InfoHero } from '../common';
import { SiteUrls } from '../../utils/constants';

const StorageBaseWarrantyHeroSection: React.FC = () => {
  return (
    <InfoHero
      prevLink={SiteUrls.WaterproofMattressProtectorWarranty}
      nextLink={SiteUrls.OriginalMattressWarranty}
      dotLinks={[
        SiteUrls.OriginalMattressWarranty,
        SiteUrls.OriginalPillowWarranty,
        SiteUrls.BedFrameWarranty,
        SiteUrls.SeresSilkDuvetWarranty,
        SiteUrls.WaterproofMattressProtectorWarranty,
        SiteUrls.StorageBaseWarranty,
      ]}
      activeDotIndex={5}
    >
      The Storage Bed
    </InfoHero>
  );
};

export default StorageBaseWarrantyHeroSection;
