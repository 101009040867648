import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { BgHeroHome2, BgHeroHome3 } from '../assets';
import { HeroCarousel, Video } from '../common';
import { SiteUrls } from '../../utils/constants';
import HomeHeroVideo from '../../static/videos/home-hero.mp4';

const heroStyle = css`
  position: relative;
  width: 100vw;
  height: 330px;

  ${media.md`
    height: 730px;
  `}
`;

const Hero2 = styled(BgHeroHome2)`
  ${heroStyle}
`;

const Hero3 = styled(BgHeroHome3)`
  ${heroStyle}
`;

const VideoBackground = styled(Video)`
  object-fit: cover;
  ${heroStyle}
`;

const Carousel = styled(HeroCarousel)`
  .hero-home-video-overlay {
    height: calc(100% - 3px);
  }
`;

const HeroCarouselSection: React.FC = () => (
  <Carousel
    slides={[
      {
        Background: VideoBackground,
        backgroundVideoSrc: HomeHeroVideo,
        headline: 'Sweet Dreams Start&nbsp;Here',
        subheadline: 'Made for incredible&nbsp;comfort',
        cta: {
          to: SiteUrls.OriginalMattress,
          text: 'Shop mattress',
        },
        overlay: {
          className: 'hero-home-video-overlay',
        },
      },
      {
        Background: Hero2,
        headline: "Singapore's Favorite&nbsp;Pillow",
        subheadline: 'Made with Woosa&nbsp;cloud&nbsp;foam',
        cta: {
          to: SiteUrls.OriginalPillow,
          text: 'Shop pillow',
        },
      },
      {
        Background: Hero3,
        headline: 'Pay with&nbsp;Atome',
        subheadline: 'Pay in 3 interest-free&nbsp;installments',
        cta: {
          to: SiteUrls.OriginalMattress,
          text: 'Shop now',
        },
      },
    ]}
  />
);

export default HeroCarouselSection;
