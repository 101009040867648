import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import { FormInput, FormCheckbox } from '../common';
import { Eyebrow, ButtonLink, Button, Error } from './auth-common-elements';
import SvgHelp from '../../static/images/help.svg';
import { rem } from '../../styles/utils';
import { signUpWithEmailAndPassword } from '../../utils/auth';
import { isUserGuest } from '../../utils/api';
import { useAuthDispatch, useAuthState } from '../../hooks/auth-context';

const Form = styled.form`
  width: 100%;
`;

const CreateAccountForm = styled.div`
  margin-top: 20px;
`;

const Label = styled(Eyebrow)`
  margin-top: 10px;
`;

const HelpIcon = styled(SvgHelp)`
  height: 22px;
  width: 22px;
`;

const TooltipLink = styled.a`
  display: inline-block;
  margin-left: 2px;
  margin-top: -15px;
`;

const Tooltip = styled(ReactTooltip)`
  max-width: 300px;
  font-size: ${rem(12)};

  ${media.lg`
    font-size: ${rem(14)};
  `}
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
`;

const Checkbox = styled(FormCheckbox)`
  label {
    color: var(--font-primary-color);
  }
`;

const HorizontalFormGroup = styled.div`
  ${media.lg`
    display: flex;

    div[class^="form-input"] {
      width: 47.5%;

      &:first-child {
        margin-right: 5%;
      }
    }
  `}
`;

const ErrorMessage = styled(Error)`
  margin: 20px 0 0;
`;

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
};

type Props = {
  className?: string;
  submitText?: string;
  onClickLogin?: () => void;
  onBeforeRequest?: () => void;
  onAfterRequest?: (result: boolean) => void;
};

const GuestForm: React.FC<Props> = ({
  className,
  onClickLogin,
  submitText,
  onBeforeRequest,
  onAfterRequest,
}) => {
  const { register, handleSubmit, errors, watch, setValue } = useForm<
    FormData
  >();
  const [createAccount, setCreateAccount] = useState(false);
  const [error, setError] = useState('');
  const password = useRef({});
  password.current = watch('password', '');
  const { email: emailAddress, populateGuestFormEmail } = useAuthState();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    if (!populateGuestFormEmail || !emailAddress) return;
    setValue('email', emailAddress);
    dispatch({
      type: 'GUEST_SIGN_IN',
      email: emailAddress,
      populateGuestFormEmail: false,
    });
  }, [populateGuestFormEmail]);

  const hasErrors = () => Object.keys(errors).length > 0;

  const isGuestUser = async (email: string) => {
    const isGuest = await isUserGuest(email);

    if (!isGuest) {
      setError('Account exists! Please click on Sign in above.');
    }

    return isGuest;
  };

  const onSubmit = async (data: FormData) => {
    setError('');
    if (hasErrors()) return;

    const { email, firstName, lastName, password } = data;

    if (typeof onBeforeRequest === 'function') {
      onBeforeRequest();
    }

    let result = false;

    if (createAccount) {
      // Create account checkout flow
      result = await signUpWithEmailAndPassword(
        dispatch,
        email,
        password,
        firstName,
        lastName
      );

      if (!result) {
        setError('Invalid email/password. Please try again.');
      }
    } else if (await isGuestUser(email)) {
      // Guest checkout flow
      dispatch({ type: 'GUEST_SIGN_IN', email });
      result = true;
    }

    if (typeof onAfterRequest === 'function') {
      onAfterRequest(result);
    }
  };

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        error={errors.email}
        inputRef={register({
          required: 'Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Enter a valid email address',
          },
        })}
        inputProps={{
          placeholder: 'Email Address',
          name: 'email',
        }}
      />
      <Checkbox
        inputProps={{
          name: 'create-account',
          id: 'create-account',
          checked: createAccount,
          onChange: () => setCreateAccount(!createAccount),
        }}
        label={
          <>
            Create a Woosa shopping account.{' '}
            <TooltipLink data-tip data-for="create-account-tooltip">
              <HelpIcon />
            </TooltipLink>
          </>
        }
      />
      <Tooltip id="create-account-tooltip">
        Creating a Woosa Account automatically registers you for your Woosa
        Warranty. You can also check on your order, initial returns, and even
        receive referral cashbacks with your Woosa account!
      </Tooltip>
      <Label>
        Already registered?{' '}
        <ButtonLink type="button" onClick={onClickLogin}>
          Sign in
        </ButtonLink>
      </Label>
      {createAccount && (
        <CreateAccountForm>
          <HorizontalFormGroup>
            <FormInput
              error={errors.firstName}
              inputRef={register({ required: 'Required field' })}
              inputProps={{
                placeholder: 'First Name',
                name: 'firstName',
              }}
            />
            <FormInput
              error={errors.lastName}
              inputRef={register({ required: 'Required field' })}
              inputProps={{
                placeholder: 'Last Name',
                name: 'lastName',
              }}
            />
          </HorizontalFormGroup>
          <FormInput
            error={errors.password}
            inputRef={register({ required: 'Password is required' })}
            inputProps={{
              placeholder: 'Password',
              type: 'password',
              name: 'password',
            }}
          />
          <FormInput
            error={errors.confirmPassword}
            inputRef={register({
              validate: value =>
                value === password.current || 'The passwords do not match',
            })}
            inputProps={{
              placeholder: 'Confirm Password',
              type: 'password',
              name: 'confirmPassword',
            }}
          />
        </CreateAccountForm>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <SubmitButton type="submit" disabled={hasErrors()}>
        {submitText}
      </SubmitButton>
    </Form>
  );
};

export default GuestForm;
