import { useEffect, useRef } from 'react';

type Props = {
  handler: (
    scrollTop: number,
    scrollPercent: number,
    lastScrollTop: number
  ) => void;
};

export default function useScrollTracker(props: Props): void {
  const { handler } = props;
  const lastScrollTop = useRef(0);

  useEffect(() => {
    const getScrollPos = () => {
      const h = document.documentElement;
      const b = document.body;
      const st = 'scrollTop';
      const sh = 'scrollHeight';
      const percent =
        ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      return percent;
    };

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollPos = getScrollPos();

      handler(scrollTop, scrollPos, lastScrollTop.current);
      lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
}
