import React from 'react';
import { renderToString } from 'react-dom/server';
import { Helmet } from 'react-helmet';

import { useGraphQLGetAllProducts } from '../../hooks/use-graphql-get-product';

const reactNodeArrayToString = (
  node: React.ReactNode | React.ReactNodeArray
) => {
  const nodeArray: React.ReactNodeArray = [];
  if (!Array.isArray(node)) {
    nodeArray.push(node);
  } else {
    nodeArray.push(...node);
  }

  return nodeArray
    .map(element =>
      renderToString(element as React.ReactElement).replace(/<[^>]+>/g, '')
    )
    .join('');
};

type Props = {
  productId: string;
  description?: string | React.ReactNode | React.ReactNodeArray;
};

const ProductSchemaMarkup: React.FC<Props> = ({ productId, description }) => {
  const allProducts = useGraphQLGetAllProducts();
  const product = allProducts.find(p => p.id === productId);

  if (!product) return null;

  const { name, media, variants } = product;

  // select one image
  const image = (media || []).find(m => m.type === 'image');

  // get highest and lowest price of product
  const [highPrice, lowPrice] = variants.reduce(
    (acc, v) => {
      const prices = v.prices.map(p => p.amount);
      const highest = Math.max(...prices);
      const lowest = Math.min(...prices);
      return [Math.max(highest, acc[0]), Math.min(lowest, acc[1])];
    },
    [-Infinity, Infinity]
  );

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name,
          image: image?.path || '',
          description: reactNodeArrayToString(description),
          brand: {
            '@type': 'Brand',
            name: 'Woosa Sleep',
          },
          offers: {
            '@type': 'AggregateOffer',
            url: '',
            priceCurrency: 'SGD',
            lowPrice,
            highPrice,
            // offerCount: '4',
          },
          // aggregateRating: {
          //   '@type': 'AggregateRating',
          //   ratingValue: '5',
          //   bestRating: '5',
          //   worstRating: '5',
          //   ratingCount: '1',
          //   reviewCount: '1',
          // },
          // review: {
          //   '@type': 'Review',
          //   reviewBody:
          //     'Just bought duvet and it’s so soft and comfy ☺️ Awesome customer service and products!.',
          //   reviewRating: {
          //     '@type': 'Rating',
          //     ratingValue: '5',
          //     bestRating: '5',
          //     worstRating: '5',
          //   },
          //   datePublished: '2021-05-31',
          //   author: { '@type': 'Person', name: 'Tan Alicia' },
          //   publisher: { '@type': 'Organization', name: 'Google' },
          // },
        })}
      </script>
    </Helmet>
  );
};

export default ProductSchemaMarkup;
