import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import {
  Eyebrow,
  ButtonLink,
  Button,
  Message,
  Title,
} from './auth-common-elements';
import { FormInput } from '../common';
import { sendResetPassword } from '../../utils/auth';

const Form = styled.form`
  width: 100%;
`;

type FormData = {
  email: string;
};

type Props = {
  className?: string;
  onClickLogin?: () => void;
  onClickRegister?: () => void;
  onBeforeRequest?: () => void;
  onAfterRequest?: () => void;
};

const ForgetPasswordForm: React.FC<Props> = ({
  className,
  onClickLogin,
  onClickRegister,
  onBeforeRequest,
  onAfterRequest,
}) => {
  const { register, handleSubmit, errors, reset } = useForm<FormData>();
  const [message, setMessage] = useState('');

  const hasErrors = () => Object.keys(errors).length > 0;

  const onSubmit = async (data: FormData) => {
    setMessage('');
    if (hasErrors()) return;

    const { email } = data;

    if (typeof onBeforeRequest === 'function') {
      onBeforeRequest();
    }

    await sendResetPassword(email);
    setMessage('Reset password email has been succesfully sent.');
    reset();

    if (typeof onAfterRequest === 'function') {
      onAfterRequest();
    }
  };

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Title>Reset your password</Title>
      <FormInput
        error={errors.email}
        inputRef={register({
          required: 'Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Enter a valid email address',
          },
        })}
        inputProps={{
          placeholder: 'Email Address',
          name: 'email',
        }}
      />
      {message && <Message>{message}</Message>}
      <Eyebrow>
        New to Woosa?{' '}
        <ButtonLink type="button" onClick={onClickRegister}>
          Create new account
        </ButtonLink>
      </Eyebrow>
      <Eyebrow>
        Already Registered?{' '}
        <ButtonLink type="button" onClick={onClickLogin}>
          Sign in
        </ButtonLink>
      </Eyebrow>
      <br />
      <Button type="submit" disabled={hasErrors()}>
        Submit
      </Button>
    </Form>
  );
};

export default ForgetPasswordForm;
