import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { rem, outlineButtonStyle } from '../../styles/utils';

const Section = styled.div`
  padding: 80px 0;
  border-top: 1px dashed var(--font-secondary-color);
`;

const ButtonSection = styled.div`
  text-align: center;
`;

const ReadButton = styled.button`
  ${outlineButtonStyle('dark')}
  width: 200px;
`;

const Content = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: 400;
  margin: 0 0 10px 0;
  font-size: ${rem(14)};
  line-height: 20px;
`;

const Description = styled.h2`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-weight: normal;
  margin: 0;
  font-size: ${rem(14)};
  line-height: 20px;
`;

const Body = styled.div`
  will-change: max-height, visibility;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.3s ease-out, visibility 0.3s ease-out;

  &.active {
    visibility: visible;
    transition: max-height 0.3s ease-out, visibility 0.3s ease-out;
  }
`;

const LatexMattressInfoSection: React.FC = () => {
  const accordionRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);

  const toggle = () => {
    if (accordionRef.current !== null) {
      const current = accordionRef.current.querySelector('.accordion-body');
      if (!current) return;

      const isActive = !current.classList.contains('active');
      setIsActive(isActive);
      if (isActive) {
        current.classList.add('active');
        current.setAttribute(`style`, `max-height: ${current?.scrollHeight}px`);
      } else {
        current.classList.remove('active');
        current.removeAttribute('style');
      }
    }
  };

  return (
    <Section ref={accordionRef}>
      <Content>
        <Title>Transform Your Sleep with Natural Latex in Mattresses</Title>
        <Description>
          The latex and memory foam used in the Woosa’s mattresses are simply
          the best in the business. We use the highest quality latex available
          in the market, which made using Sonocore technology. Unlike the
          traditional “baking” process used in regular latex, Sonocore Latex is
          microwaved for a perfect open cell consistency. It is superior in
          durability, comfort and support than traditional Dunlop latex. Enjoy
          the benefits of natural latex foam mattresses with Woosa.
        </Description>
      </Content>

      <Body className="accordion-body">
        <Content>
          <Title>Benefits of Latex Memory Foam Mattresses</Title>
          <Description>
            Woosa uses latex that is bouncy, extra thick and firm, which helps
            provide the proper support for the spine. We calibrate each layer to
            the right thickness, to prevent a “sagging” feel after long-term
            use. Natural latex is ultra durable and all of Woosa’s mattresses
            come with a 10-year warranty of quality assurance.
            <br />
            <br />
            Memory foam in our mattresses helps cushion the extra firm latex to
            provide a comfortable hugging feel, without being overwhelming. In
            Woosa’s mattresses, memory foam helps provide a deeper level of
            conformity to the body’s natural curvature.
          </Description>
        </Content>
        <Content>
          <Title>Buy Natural Latex Foam Mattress in Singapore</Title>
          <Description>
            The Original Woosa Mattress was one of the early pioneers that
            popularized the combination of high quality latex and memory foam
            mattresses in Singapore. This simple yet brilliant combination
            helped users experience the best of two of the most popular
            materials used in mattresses today, and together with our selected
            thickness, density and quality of the foam, our mattresses feel like
            no other.
          </Description>
        </Content>
      </Body>
      <ButtonSection>
        <ReadButton onClick={() => toggle()}>
          {isActive ? 'Read less' : 'Read more'}
        </ReadButton>
      </ButtonSection>
    </Section>
  );
};

export default LatexMattressInfoSection;
