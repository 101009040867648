import React from 'react';
import styled from 'styled-components';
import Icon from '../../static/images/whatsapp.svg';
import { ContactLinks } from '../../utils/constants';

const Link = styled.a`
  svg {
    width: 60px;
  }
`;

type Props = {
  className?: string;
  hideIcon?: boolean;
};

const Whatsapp: React.FC<Props> = ({ hideIcon, className }) => {
  return (
    <Link className={className} href={ContactLinks.Whatsapp} target="_blank">
      {!hideIcon && <Icon />}
      {hideIcon && ContactLinks.Whatsapp.replace('https://wa.me/65', '')}
    </Link>
  );
};

export default Whatsapp;
