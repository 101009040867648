import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import {
  Eyebrow,
  ButtonLink,
  Error,
  Button,
  Title,
} from './auth-common-elements';
import { FormInput } from '../common';
import { signUpWithEmailAndPassword } from '../../utils/auth';
import { useAuthDispatch } from '../../hooks/auth-context';

const Form = styled.form`
  width: 100%;
`;

type FormData = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
};

type Props = {
  className?: string;
  submitText?: string;
  defaultValues?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  onClickLogin?: () => void;
  onClickForgetPassword?: () => void;
  onBeforeRequest?: () => void;
  onAfterRequest?: (result: boolean) => void;
};

const RegisterForm: React.FC<Props> = ({
  className,
  submitText,
  onClickLogin,
  onClickForgetPassword,
  onBeforeRequest,
  onAfterRequest,
  defaultValues,
}) => {
  const { register, handleSubmit, errors, watch, reset } = useForm<FormData>({
    defaultValues,
  });
  const [error, setError] = useState('');
  const dispatch = useAuthDispatch();
  const password = useRef({});
  password.current = watch('password', '');

  const hasErrors = () => Object.keys(errors).length > 0;

  const onSubmit = async (data: FormData) => {
    setError('');
    if (hasErrors()) return;

    const { email, password, firstName, lastName } = data;

    if (typeof onBeforeRequest === 'function') {
      onBeforeRequest();
    }

    const result = await signUpWithEmailAndPassword(
      dispatch,
      email,
      password,
      firstName,
      lastName
    );

    if (!result) {
      setError(
        'Error occurred while signing up. Please contact us for further assistance.'
      );
    } else {
      reset();
    }

    if (typeof onAfterRequest === 'function') {
      onAfterRequest(result);
    }
  };

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Title>Sign up for a new Woosa account</Title>
      <FormInput
        error={errors.email}
        inputRef={register({
          required: 'Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Enter a valid email address',
          },
        })}
        inputProps={{
          placeholder: 'Email Address',
          name: 'email',
        }}
      />
      <FormInput
        error={errors.firstName}
        inputRef={register({ required: 'Required field' })}
        inputProps={{
          placeholder: 'First Name',
          name: 'firstName',
        }}
      />
      <FormInput
        error={errors.lastName}
        inputRef={register({ required: 'Required field' })}
        inputProps={{
          placeholder: 'Last Name',
          name: 'lastName',
        }}
      />
      <FormInput
        error={errors.password}
        inputRef={register({ required: 'Password is required' })}
        inputProps={{
          placeholder: 'Password',
          type: 'password',
          name: 'password',
        }}
      />
      <FormInput
        error={errors.confirmPassword}
        inputRef={register({
          validate: value =>
            value === password.current || 'The passwords do not match',
        })}
        inputProps={{
          placeholder: 'Confirm Password',
          type: 'password',
          name: 'confirmPassword',
        }}
      />
      {error && <Error>{error}</Error>}
      {typeof onClickLogin === 'function' && (
        <Eyebrow>
          Already registered?{' '}
          <ButtonLink type="button" onClick={onClickLogin}>
            Sign in
          </ButtonLink>
        </Eyebrow>
      )}
      {typeof onClickForgetPassword === 'function' && (
        <Eyebrow>
          Forgot password?{' '}
          <ButtonLink type="button" onClick={onClickForgetPassword}>
            Reset it now
          </ButtonLink>
        </Eyebrow>
      )}
      <br />
      <Button type="submit" disabled={hasErrors()}>
        {submitText}
      </Button>
    </Form>
  );
};

export default RegisterForm;
