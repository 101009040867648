/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import { ENV } from 'gatsby-env-variables';
import _ from 'lodash';

export function useCustomReducer<S>(
  name: string,
  reducerFn: (state: S, action: any) => S,
  initialState: S
): [S, React.Dispatch<any>] {
  const [state, dispatch] = useReducer(reducerFn, initialState);
  const enableLogger = ENV !== 'prod';

  const preState = useRef<{ state?: S; action?: any }>({});

  const dispatchWithLogging = useCallback((action: any) => {
    preState.current = _.cloneDeep({ ...preState.current, action });
    dispatch(action);
  }, []);

  const customDispatch = enableLogger ? dispatchWithLogging : dispatch;

  useMemo(
    function logStateAfterChange() {
      if (!enableLogger || !preState.current) return;

      const { state: previousState, action } = preState.current;

      if (!action) return;

      console.groupCollapsed('%c action', 'color: #9E9E9E;', name, action.type);
      console.log(
        '%c prev state',
        'color: #F20404; font-weight: bold;',
        previousState || {}
      );
      console.log(
        '%c action',
        'color: #03A9F4; font-weight: bold;',
        action || {}
      );
      console.log('%c next state', 'color: #4CAF50; font-weight: bold;', state);
      console.groupEnd();
    },
    [state, enableLogger]
  );

  preState.current = _.cloneDeepWith({ ...preState.current, state });

  return [state, customDispatch];
}
