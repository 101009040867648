import React from 'react';
import styled from 'styled-components';
import {
  ENABLE_MATTRESS_COMPARISON_PAGE,
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_PLUS_COVER_ID,
} from 'gatsby-env-variables';

import { ProductSection } from '../common';
import useGraphQLGetProduct from '../../hooks/use-graphql-get-product';
import MattressSlower from '../../static/videos/terra-mattress-slower.mp4';
import Terra2 from '../../assets/terra-mattress-2.png';
import Terra2Webp from '../../assets/terra-mattress-2.webp';
import Terra3 from '../../assets/terra-mattress-3.png';
import Terra3Webp from '../../assets/terra-mattress-3.webp';
import Terra4 from '../../assets/terra-mattress-4.png';
import Terra4Webp from '../../assets/terra-mattress-4.webp';
import Terra5 from '../../assets/terra-mattress-5.png';
import Terra5Webp from '../../assets/terra-mattress-5.webp';
import Terra6 from '../../static/videos/terra-plus-cover.mp4';
import Terra7 from '../../assets/plus-cover-asset.png';
import Terra7Webp from '../../assets/plus-cover-asset.webp';

import { SiteUrls } from '../../utils/constants';
import { BreadcrumbLink } from '../common/breadcrumb';

const Section = styled(ProductSection)`
  background-color: var(--spring-wood);

  &.plus-cover-background {
    background-color: var(--blue-gray);
  }
`;
type Props = {
  onChangeIsPlusCover?: (isChecked: boolean) => void;
  isPlusCover?: boolean;
};

const TerraMattressProductSection: React.FC<Props> = ({
  onChangeIsPlusCover,
  isPlusCover,
}) => {
  const product = useGraphQLGetProduct(PRODUCT_TERRA_MATTRESS_ID);
  const plusCover = useGraphQLGetProduct(PRODUCT_PLUS_COVER_ID);
  const breadcrumbs: BreadcrumbLink[] = [
    {
      urlPath: SiteUrls.ComparisonMattress,
      label: 'Mattresses',
    },
    { label: 'The Terra Mattress' },
  ];

  if (!ENABLE_MATTRESS_COMPARISON_PAGE) {
    breadcrumbs[0].urlPath = undefined;
  }

  return (
    <Section
      {...product}
      className={isPlusCover ? 'plus-cover-background' : ''}
      breadcrumbs={breadcrumbs}
      plusCover={plusCover}
      setIsPlusCover={onChangeIsPlusCover}
      isPlusCover={isPlusCover}
      assets={[
        {
          src: MattressSlower,
          type: 'video',
        },
        {
          src: Terra2,
          webp: Terra2Webp,
          type: 'image',
        },
        {
          src: Terra3,
          webp: Terra3Webp,
          type: 'image',
        },
        {
          src: Terra4,
          webp: Terra4Webp,
          type: 'image',
        },
        {
          src: Terra5,
          webp: Terra5Webp,
          type: 'image',
        },
        {
          src: Terra6,
          type: 'video',
          isPlusCover: true,
        },
        {
          src: Terra7,
          webp: Terra7Webp,
          type: 'image',
          isPlusCover: true,
        },
      ]}
      version="v2"
    >
      <p>
        For extra-firm comfort. Made with double layers of 100% Natural Latex
        for max support and posture alignment.
      </p>
      <ul>
        <li>
          Ergonomic design for optimal spinal alignment and sleeping posture
        </li>
        <li>
          Dual density latex foams prevent sinking and supports your lower back
        </li>
        <li>Recommended for Back Sleepers or Heavy Side-Sleepers</li>
        <li>Made in Belgium for superior comfort and durability</li>
      </ul>
    </Section>
  );
};

export default TerraMattressProductSection;
