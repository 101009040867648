import React from 'react';
import styled from 'styled-components';

import { ReviewGridSection } from '../common';

const Section = styled(ReviewGridSection)``;

const WaterproofMattressProtectorReviewGridSection: React.FC = () => {
  return <Section />;
};

export default WaterproofMattressProtectorReviewGridSection;
