import React from 'react';

import { InstagramGridSection } from '../common';
import {
  ImgInstagram1,
  ImgInstagram2,
  ImgInstagram3,
  ImgInstagram5,
  ImgInstagram6,
  ImgInstagram7,
  ImgInstagram8,
  ImgInstagram9,
  ImgInstagramCalistaEvangelista,
  ImgInstagramCharlieGoh13,
  ImgInstagramCherzinga,
  ImgInstagramEmmtann,
  ImgInstagramJeffreyxu,
  ImgInstagramJulietanCxq,
  ImgInstagramRoxannegan,
  ImgInstagramTugmyheartstring,
  ImgInstagramWafflenessa,
  ImgInstagramXoxoapo,
  ImgInstagramJiaqiwoo,
  ImgInstagramHayleywoojiayi,
  ImgInstagramZolalfredo,
} from '../assets';
import { InstagramCardGrid } from '../../types/component';
import styled from 'styled-components';

const GridSection = styled(InstagramGridSection)``;

const cards: InstagramCardGrid[] = [
  {
    img: <ImgInstagramJiaqiwoo />,
    handle: 'jiaqiwoo',
    avatar: '',
    text: `I am now always looking forward to getting my beauty sleep with my brand new adjustable bed from @woosasg 💙🥰`,
  },
  {
    img: <ImgInstagramHayleywoojiayi />,
    handle: 'hayleywoojiayi',
    avatar: '',
    text: `Night is the wonderful opportunity to take rest, to forgive, to smile, to get ready for all the battles that you have to fight tomorrow. Thank you @woosasg for the amazing quality mattress and pillows.`,
  },
  {
    img: <ImgInstagramZolalfredo />,
    handle: 'zolalfredo',
    avatar: '',
    text: `No pillow talk here, only fighting for our favourite pillow from @woosasg cuz even Momo loves it! We used to all squeeze on 1 king-sized bed, but with our newborn Qiuqiu and confinement nanny, we tried out a new sleeping arrangement - it’s all boys! Luckily the Woosa Original Mattress is so comfortable that the boys don’t go running back to mama anymore! They love it so much, and despite sleeping with 2 kids I still manage to get really good quality sleep on it!`,
  },
  {
    img: <ImgInstagramRoxannegan />,
    handle: 'roxannegan_',
    avatar: '',
    text: `The @woosasg Adjustable Base is able to customize our positions for an incredible night’s sleep, or when I’m nursing while my husband replies to his emails in bed using our wireless remotes! Oh, it also comes with a massage function, the perfect way to wind down for the night.`,
  },
  {
    img: <ImgInstagramJeffreyxu />,
    handle: 'jeffreyxu',
    avatar: '',
    text: `Love reading on this bed 🛏️📖 Thank you Woosa for allowing me to have quality, peaceful and comfortable sleep 💤❤️`,
  },
  {
    img: <ImgInstagramXoxoapo />,
    handle: 'xoxoapo',
    avatar: '',
    text: `My woosa pillow is so soft yet enough support for my neck. It makes me feel like sleeping on clouds every night :) This pillow is so comfortable, I feel like falling asleep again and again 😂️`,
  },
  {
    img: <ImgInstagramTugmyheartstring />,
    handle: 'tugmyheartstring',
    avatar: '',
    text: `The #woosasg Adjustable Bed frame in grey is gorgeous! We got it for watching TV, but didn't expect that it would help us so much when we were both recovering from surgery. After a year, the mattress has remained in shape and didnt sag, and the high-end German motors are so silent and works so well that we didn't have any issues at all since! It's probably the best $3k+ investment we have made for the entire frame and mattress bundle.`,
  },
  {
    img: <ImgInstagramCalistaEvangelista />,
    handle: 'calista_evangelista',
    avatar: '',
    text: `Where do you spend most of your time ? 😜
      I’m most particular when it comes to choosing my bed 🤭
      Because I wanna wake up feeling happy 💕
      
      And my pick is @woosasg’s adjustable bed + their Mysa Mattress 😍
      It has 6 calibrated layers, including their signature Sonocore Latex and a Woosa Cloud Foam Top. Wrapped in Sliver Microtencel fabric from Italy 💯
      Love how my body sink into this cloud foam mattress 🥰
      
      And I love that I’m able to adjust the degree of the bed with just a controller 😜
      Not to mention, it also has a massaging mode 😱
      What more can I ask for 😋
      
      📸: my love @zolynnn 😘`,
  },
  {
    img: <ImgInstagramCharlieGoh13 />,
    handle: 'charliegoh13',
    avatar: '',
    text: `Smile when you sleep.😴

      Its been almost 2 years since I moved into my home which is the same amount of time I have spent sleeping on the @woosasg mattress. And i must say it has given me really good sleep all these while.
      
      If you are looking for a mattress that gives maximum comfort and yet affordable, now you know where to go.`,
  },
  {
    img: <ImgInstagramCherzinga />,
    handle: 'cherzinga',
    avatar: '',
    text: `Just woke up but I can't wait to go back to sleep because my Woosa pillow is wayyy too comfy 😫 My bf and I fight for the pillow every night I think I'll just get him another one 😒 Its flush and velvety sensation really makes me feel like I'm sleeping on a soft, fluffy cloud ☺️ I haven't woken up with a stiff neck ever since!!
      .
      They're a local brand but all their pillows are made in Belgium! And you don't like the pillow (are you cray) you can return it in 100 days no questions asked 😉 #woosasg`,
  },
  {
    img: <ImgInstagramEmmtann />,
    handle: 'emmtann',
    avatar: '',
    text:
      'Finally revamped my room & although it’s far from perfect, I’m so glad I finally got to try @woosasg mattress. It’s so comfortable it feels like I’m sleeping in a hotel bed 😍 #woosasg',
  },
  {
    img: <ImgInstagramJulietanCxq />,
    handle: 'julietan_cxq',
    avatar: '',
    text:
      "Finally back in Singapore and so glad to be coming home to my @woosasg mattress and pillow - always giving me sufficient support for my back and neck for a good night's sleep! #woosasg",
  },
  {
    img: <ImgInstagramWafflenessa />,
    handle: 'wafflenessa',
    avatar: '',
    text: `My bedroom is finally coming together!! I was so excited for it as I upgraded to a queen sized bed, and no regrets at all as my new mattress from @woosasg is sooo comfortable 😌
          <br /><br />
          The mattress is really breathable as it regulates temperature and it’s just so cosy, it’s so hard to leave my bed every morning now 😂 Try it for yourself, they provide a free trial for 100 nights, no questions asked.`,
  },
  {
    img: <ImgInstagram1 />,
    handle: 'heyhihazelle',
    avatar: '',
    text:
      'I gave the Woosa pillow a shot and... it really is like a cloud ☁️ My head sinks in to a perfect degree, and the material is so breathable it doesn’t keep the heat in. If you’ve worked long and hard over the day, the least you deserve is a quality pillow and a good night’s sleep. ♥️',
  },
  {
    img: <ImgInstagram2 />,
    handle: 'mongabong',
    avatar: '',
    text:
      'The perfect support for your body, I no longer wake up with body aches anymore',
  },
  {
    img: <ImgInstagram3 />,
    handle: 'jamiepang',
    avatar: '',
    text: 'It’s seriously getting harder to get out of bed these days! 🙊',
  },
  {
    img: <ImgInstagram5 />,
    handle: 'elaineruimin',
    avatar: '',
    text:
      "So excited about finally moving on to the next phase of life, getting the keys to my new place. And this came just in time! @woosasg pillows are absolutely comfy. Can't wait to try out their mattresses after renovation. 👍👍👍",
  },
  {
    img: <ImgInstagram6 />,
    handle: 'novitalam',
    avatar: '',
    text:
      'Good sleep is good self love 🥰 turning in for the night with this amazingly plush and supportive pillow from @woosasg',
  },
  {
    img: <ImgInstagram7 />,
    handle: 'zoeraymondtan',
    avatar: '',
    text:
      'Just the kind of things I like to receive these days. When you are about to turn 30 and a mom, practicality tops all! - Squeezing my new cloud pillow from @woosasg! You can really feel the airflow by pressing down on it! Made in Belgium, this pillow is balanced soft & plush comfort. Get this cloud pillow at an introductory price of $149!',
  },
  {
    img: <ImgInstagram8 />,
    handle: 'maybelinesim',
    avatar: '',
    text:
      'We are almost done with our new nest and we are ready to move in really soon! No more hopping over only during the weekends :P',
  },
  {
    img: <ImgInstagram9 />,
    handle: 'jamietyj',
    avatar: '',
    text:
      'Sleeping so well at night on my new @woosasg pillow. It’s sooo comfy it’s like I’m sleeping on a marshmallow! I’m a side sleeper so it’s always difficult to find pillows that are a good height, and firm but still soft enough (so it doesn’t hurt my ears). Woosa’s pillow checks all the right boxes for me! If you know someone who has a hard time finding a good pillow, try getting them this for Christmas (at an introductory price of $149). Sleep is the best gift hehe 😴✨',
  },
];

const MeetOurHappySleepersSection: React.FC = () => (
  <GridSection cards={cards} />
);

export default MeetOurHappySleepersSection;
