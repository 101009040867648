import React from 'react';

import { WarrantyIntro } from '../common';

const MattressWarrantyFooterSection: React.FC = () => {
  return (
    <WarrantyIntro headline="Last Words" borderAt="top" maxWidth="80%">
      Except as otherwise provided herein, Woosa will not charge the purchaser
      to repair or replace the mattress if it is deemed defective during the
      length of this 10 year Limited Warranty, but any transportation costs
      associated with repairs or replacements are the purchaser’s
      responsibility. Woosa will, at its sole discretion, determine whether a
      repair or replacement of a defective mattress is appropriate. In the event
      a warranty claim is filed and a warranty replacement is deemed necessary,
      the purchaser will be required to surrender the original product to Woosa
      at the time of replacement.
      <br />
      <br />
      Repaired or replaced Mattresses are subject to the same limited Mattress
      Warranty as the original Mattress. The warranty for any repaired or
      replaced mattress will run from the date of original purchase and not from
      the date of the replacement.
      <br />
      <br />
      Replacement mattresses may not come in its original vacuum packaging.
      Woosa reserves its right to offer refurbished mattresses as warranty
      replacement mattress.
      <br />
      <br />
      WOOSA SHALL NOT BE LIABLE FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES
      RESULTING FROM THE USE OF THIS PRODUCT OR ARISING OUT OF ANY BREACH OF
      THIS WARRANTY; THE EXCLUSIVE REMEDY FOR BREACH OF THIS WARRANTY SHALL BE
      REPLACEMENT OR CREDIT TOWARDS REPLACEMENT AS SET FORTH HEREIN. THERE ARE
      NO EXPRESS OR IMPLIED WARRANTIES, INCLUDING IMPLIED WARRANTIES OF
      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THE
      WARRANTY DESCRIBED ON THE FACE OF THIS LIMITED WARRANTY.
      <br />
      <br />
      For all claims and defects covered under this Limited Warranty, please
      reach us at{' '}
      <a href="mailto:support@woosa.sg" target="_blank" rel="noreferrer">
        support@woosa.sg
      </a>
    </WarrantyIntro>
  );
};

export default MattressWarrantyFooterSection;
