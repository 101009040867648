import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { HeroIntro } from '../common';
import Video from '../../static/videos/original-mattress-intro.mp4';

const Intro = styled(HeroIntro)`
  .hero-intro {
    height: 200px;

    ${media.md`
      height: 300px;
    `}

    ${media.lg`
      height: 450px;
    `}

    ${media.xl`
      height: 700px;
    `}
  }

  .hero-intro-content {
    padding: 30px 0;

    ${media.md`
      padding: 50px 0;
    `}

    ${media.lg`
      padding: 100px 0;
    `}
  }
`;

const OriginalMattressIntroSection: React.FC = () => {
  return (
    <Intro
      version="v2"
      backgroundVideoSrc={Video}
      eyebrow="Design Process"
      headline="Crafting A Perfectly Balanced Mattress"
      body={
        <>
          <p>
            Woosa has upheld the same philosophy throughout the years - honest,
            high-quality materials for a high-quality mattress. Our pioneering
            combination of premium Latex and Memory Foam helps users experience
            the best of two of the most popular materials used in mattresses
            today. Together with our carefully calibrated thickness, density and
            quality of the foams used, The Original Woosa Mattress is an
            award-winning mattress that is loved by thousands of happy users.
          </p>
          <p>
            In its latest iteration, The Original uses even thicker layers of
            Sonocore® Latex and European-made Memory Foam, and is wrapped in our
            upgraded, Italian-sourced Microtencel fabric.
          </p>
        </>
      }
    />
  );
};

export default OriginalMattressIntroSection;
