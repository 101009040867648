import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem, flatButtonStyle } from '../../../styles/utils';
import ReviewStars from '../review-stars';
import ProductVariantDropdown from '../product-variant-dropdown';
import ProductAddOn from '../product-add-on';
import Spinner from '../spinner';
import { Product, ProductAddOnConfig, Variant } from '../../../types/product';
import { ProductOrderItem } from '../../../types/order';

const InfoContainer = styled.div`
  width: 100%;

  ${media.md`
    width: 50%;
    padding-left: 40px;
  `}

  ${media.xl`
    width: 40%;
  `}
`;

const Info = styled.div`
  position: relative;
  margin-bottom: 30px;

  ${media.xl`
    margin-bottom: 50px;
  `}
`;

const AddToCartBtn = styled.button`
  ${flatButtonStyle()}
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.xl`
    flex-direction: row;
  `}
`;

const Name = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 5px;

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const BadgeWrapper = styled.div`
  margin-bottom: 5px;

  ${media.xl`
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 0;
  `}
`;

const Description = styled.div`
  font-family: var(--font-body);
  font-size: ${rem(12)};
  font-weight: normal;
  color: var(--font-secondary-color);
  line-height: 1.3;
  margin: 0;

  ul {
    margin: 0;
    padding: 0 0 0 15px;
  }

  ${media.lg`
    font-size: ${rem(14)};
    line-height: 1.3125;
  `}

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

export type Props = {
  id: string;
  product: Product;
  name: string;
  rating: {
    avg: number;
    count: number;
    total: number;
  };
  description: React.ReactNode | React.ReactNodeArray | string;
  variants: Variant[];
  className?: string;
  defaultAddOnText?: string;
  addOns?: Product[];
  customAddOn?: Product;
  addOnLabel?: string;
  onChangeProductVariantDropdown?: (selected: Variant) => void;
  isLoading?: boolean;
  selectedVariant?: Variant;
  selectedAddOn?: ProductOrderItem | null;
  selectedCustomAddOn?: ProductOrderItem | null;
  addOnConfig?: ProductAddOnConfig;
  onChangeProductAddOn: (selected: ProductOrderItem | null) => void;
  onChangeProductCustomAddOn?: (selected: ProductOrderItem | null) => void;
  onClickAddToCart?: () => void;
  badge?: JSX.Element;
};

const ProductSectionInfo: React.FC<Props> = ({
  id,
  name,
  product,
  rating,
  className,
  description,
  defaultAddOnText,
  addOns,
  addOnLabel,
  onChangeProductVariantDropdown,
  isLoading,
  selectedVariant,
  onChangeProductAddOn,
  onClickAddToCart,
  badge,
}) => {
  return (
    <InfoContainer className={className}>
      <Info>
        <NameWrapper>
          <Name>{name}</Name>
          {badge && <BadgeWrapper>{badge}</BadgeWrapper>}
        </NameWrapper>
        <ReviewStars
          isYellow
          hideIfNoRating
          rating={rating?.avg || 0}
          linkUrl="#reviews"
        />
        <Description>{description}</Description>
      </Info>
      <Info>
        {product && (
          <>
            <ProductVariantDropdown
              label="Size"
              variants={product.variants}
              onChange={onChangeProductVariantDropdown}
            />
            <Spinner isLoading={isLoading} size={30} />
          </>
        )}
      </Info>
      <Info>
        <ProductAddOn
          id={id}
          label={addOnLabel || 'Accessories'}
          defaultText={defaultAddOnText || ''}
          products={addOns || []}
          selectedVariant={selectedVariant}
          onChange={onChangeProductAddOn}
        />
      </Info>
      <AddToCartBtn
        disabled={
          (selectedVariant && selectedVariant?.status === 'Out of Stock') ||
          isLoading
        }
        onClick={onClickAddToCart}
      >
        Add to Cart
      </AddToCartBtn>
    </InfoContainer>
  );
};

export default ProductSectionInfo;
