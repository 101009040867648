import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { useCheckoutState } from '../../hooks/checkout-context';

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  --translate: 0%;
  display: flex;
  margin: 0;
  padding: 0;
  transition: transform 0.5s ease-in-out;
  transform: translateX(var(--translate));
  width: 100%;
`;

const Content = styled.div`
  flex-shrink: 0;
  width: 100%;
`;

type Props = {
  className?: string;
};

const WizardContent: React.FC<Props> = ({ className }) => {
  const { activeIndex, steps } = useCheckoutState();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.setProperty(
      '--translate',
      `-${(activeIndex || 0) * 100}%`
    );
  }, [activeIndex]);

  return (
    <Container className={className}>
      <Wrapper ref={wrapperRef}>
        {steps &&
          steps.map(({ content }, key) => (
            <Content key={key}>{content}</Content>
          ))}
      </Wrapper>
    </Container>
  );
};

export default WizardContent;
