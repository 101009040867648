import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { ImgAtome } from '../assets';
import { rem } from '../../styles/utils';
import { currencyFormat } from '../../utils';

const Img = styled(ImgAtome)`
  width: 80px;
  display: inline-block;
  top: 8px;
`;

const Container = styled.div`
  color: var(--font-secondary-color);
  width: 100%;
  font-size: ${rem(12)};
  font-family: var(--font-body);

  ${media.lg`
    font-size: ${rem(14)};
  `}
`;

type Props = {
  className?: string;
  imgClassName?: string;
  amount?: number;
};

const AtomeLabel: React.FC<Props> = ({ className, imgClassName, amount }) => {
  const renderInstallmentAmount = () => {
    if (!amount) return ' ';
    return ` of ${currencyFormat(amount / 3)} `;
  };
  return (
    <Container className={className}>
      Or 3 payments{renderInstallmentAmount()}with
      <Img className={imgClassName} /> 0% interest.
    </Container>
  );
};

export default AtomeLabel;
