import React from 'react';

import { IntroGallerySection } from '../common';
import HeroVideoSrc from '../../static/videos/adjustable-base-2-intro-gallery.mp4';
import FabricVideoSrc from '../../static/videos/adjustable-base-2-intro-gallery-fabric.mp4';
import { ImgAdjustableBase2Comfort } from '../assets';

const AdjustableBaseIntroGallerySection: React.FC = () => {
  return (
    <IntroGallerySection
      headline="A fully customisable bed that fits like a glove"
      gallery={[
        {
          type: 'video',
          src: HeroVideoSrc,
        },
        {
          type: 'image',
          img: <ImgAdjustableBase2Comfort />,
          title: 'Perfect in any bedroom',
          description:
            'Experience personalised comfort in any bedroom with our innovative Zero Clearance design- The Adjustable Base II works with or without legs, whether flush on the floor, platform, on your existing bed frame*, or with our customisable headboard and sideboard designs.',
        },
        {
          type: 'video',
          src: FabricVideoSrc,
          title: 'Customizable head and sideboard designs',
          description:
            'Choose from our water and stain resistant fabric options, or high-grade vegan leather options with over 100 different design and colour combinations. Constructed with durable furniture grade solid wood core.',
        },
      ]}
    />
  );
};

export default AdjustableBaseIntroGallerySection;
