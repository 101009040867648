import React from 'react';

import { Error } from '../common';

type Props = {
  code?: string;
  message?: string;
  className?: string;
};

const ErrorSection: React.FC<Props> = props => <Error {...props} />;

export default ErrorSection;
