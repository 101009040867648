import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';
import { ImgFirmBar } from '../../components/assets';

const Headline = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: normal;
  margin: 0;
  font-size: ${rem(16)};
  display: none;
  ${media.lg`
    display: block;
  `}
`;

const HeadlineRes = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: normal;
  margin: 0;
  font-size: ${rem(16)};
`;

const HeadlineResSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  ${media.lg`
    display: none;
  `}
`;

const Section = styled.div`
  padding: 20px 0 40px 0;
  display: block;
  align-items: center;
  ${media.lg`
    display: flex;
  `}
`;

const Img = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  ${media.lg`
    margin: 0 20px;
  `}

  .left-65 {
    left: 65%;
  }
  .left-75 {
    left: 75%;
  }
  .left-90 {
    left: 90%;
  }
`;

type Props = {
  img: JSX.Element;
  imgInner: JSX.Element;
  value: number;
};

const Point = styled.div`
  position: absolute;
  top: 20px;
  transform: translateX(-20px);
  width: 20px;
  text-align: center;
  font-size: ${rem(16)};
  font-weight: 400;
`;

const Ellipse = styled.div`
  position: absolute;
  top: -5px;
  transform: translateX(-20px);
  width: 20px;
  height: 20px;
`;

const EllipseInner = styled.div`
  position: absolute;
  top: -5px;
  transform: translate(-15px, 5px);
  width: 10px;
  height: 10px;
  z-index: 2;
`;

const FirmnessContent: React.FC<Props> = ({ img, imgInner, value }) => {
  const position = value * 10;

  return (
    <Section>
      <HeadlineResSection>
        <HeadlineRes>Soft</HeadlineRes>
        <HeadlineRes>Firm</HeadlineRes>
      </HeadlineResSection>

      <Headline>Soft</Headline>
      <Img>
        <ImgFirmBar />
        <Ellipse className={`left-${position}`}>{img}</Ellipse>
        <EllipseInner className={`left-${position}`}>{imgInner}</EllipseInner>
        <Point className={`left-${position}`}>{value}</Point>
      </Img>
      <Headline>Firm</Headline>
    </Section>
  );
};

export default FirmnessContent;
