import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import {
  ImgAdjustableBaseBenefit1,
  ImgAdjustableBaseBenefit2,
  ImgAdjustableBaseBenefit3,
} from '../assets';

const Section = styled(ProductBenefitSection)``;

const AdjustableBaseBenefitSection: React.FC = () => (
  <Section
    version="v2"
    headline="Features of Our Adjustable Base"
    benefits={[
      {
        img: <ImgAdjustableBaseBenefit1 />,
        eyebrow: 'DUALPOINT Back-Tilt',
        headline: 'Extra Lumbar Support',
        body:
          'Our improved hydraulic design and innovative construction allows for a first of its kind, DUALPOINT curvature on the back-tilt, providing a natural hugging curve and extra lumbar support without having the need for additional moving parts or components.',
      },
      {
        img: <ImgAdjustableBaseBenefit2 />,
        eyebrow: 'Hypnotic Snooze',
        headline: 'Quad Rhythmic Massage',
        body:
          'Unwind and fall asleep quicker with the 4-zone vibrational massage. Designed to operate silently and in a rhythmic fashion, the enhanced pulsing sensation follows the principle of counting sheep, forcing your mind to focus on a monotonous pattern, lulling you into a deeper sleep.',
      },
      {
        img: <ImgAdjustableBaseBenefit3 />,
        eyebrow: 'Stay Recharged',
        headline: 'USB-C Charging & Enhanced Mood Lighting',
        body:
          'Fast charge your devices with both USB-A and USB-C charging ports located conveniently on both sides of the bed. Extra warm under-bed lighting sets the mood and helps light the way for midnight bathroom trips.',
      },
    ]}
  />
);

export default AdjustableBaseBenefitSection;
