import React from 'react';
import styled from 'styled-components';

import { SimpleSplitSection } from '../common';
import { Wrapper, LeftTitle, fontStyle } from '../common/simple-split-section';

const Container = styled(Wrapper)``;

const Row = styled(SimpleSplitSection)`
  margin-top: 40px;
`;

const Paragraph = styled.p`
  ${fontStyle}
  margin-top: 0;
`;

const BedFrameWarrantyContentSection: React.FC = () => {
  const data = [
    {
      title: 'Warranty Description',
      content: (
        <Paragraph>
          All Woosa warranties, including any implied warranties, are valid only
          for the period of time the adjustable is owned by the original
          purchaser of the Woosa Adjustable Bed. The “original purchaser,” for
          the purposes of this Adjustable Bed Warranty, is the first purchaser
          of the adjustable base from Woosa Sleep or a Woosa authorized
          retailer. Please retain a copy of your receipt as proof of purchase.
          All Woosa limited warranties are not transferable.
        </Paragraph>
      ),
    },
    {
      title: 'What does the Limited Warranty Cover?',
      content: (
        <Paragraph>
          Year 1: Full coverage parts and labor warranty. This adjustable bed is
          warranted against defects in the workmanship or materials for a period
          of up to one (1) year from the date of purchase for adjustable bases
          bought new. Woosa Sleep will send replacement parts (at no cost to the
          purchaser) for any defective adjustable base part to the purchaser,
          and Woosa Sleep will pay all pre-authorized labor and transportation
          costs associated with the repair or replacement of any parts which
          Woosa Sleep determines to be defective.
          <br />
          <br />
          Years 2-5: Limited Warranty. If the defect occurs in the second
          through fifth year following purchase for adjustable bases bought new,
          the purchaser shall pay 20% in the 2nd year, 40% in the 3rd year, 60%
          in the 4th year and 80% in the 5th year of the then current
          replacement cost of the defective part, inclusive of labour and
          transportation charges. This extends to all mechanical, electrical
          components, drive motors and massage motors.
        </Paragraph>
      ),
    },
    {
      title: 'What does this Limited Warranty NOT Cover?',
      content: (
        <Paragraph>
          This limited warranty does not cover or apply:
          <br />
          <br />
          To the wireless remote.
          <br />
          <br />
          To any damage caused to the adjustable base by the purchaser. If there
          has been any unauthorized repair or replacement of the adjustable bed
          or parts. If the adjustable base has been mishandled whether in
          transit or by other means, subjected to physical or electrical abuse
          or misuse, or otherwise operated in any way inconsistent with the
          operation and maintenance procedures outlined in the Owner’s Manual,
          this limited warranty, and any other applicable document published or
          approved by Woosa Sleep. If the recommended weight restrictions
          (250kg) and guidelines for proper distribution of weight as stated in
          the Owner’s Manual are not followed. For adjustable beds used in
          commercial type settings. for adjustable beds that have been resold on
          the secondary market The decision to repair or to replace defective
          parts under this warranty shall be made, or cause to be made, by Woosa
          Sleep at its option and in its sole discretion.
        </Paragraph>
      ),
    },
  ];

  return (
    <Container>
      {data.map(({ title, content }, i) => (
        <Row key={i} left={<LeftTitle>{title}</LeftTitle>} right={content} />
      ))}
    </Container>
  );
};

export default BedFrameWarrantyContentSection;
