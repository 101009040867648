export enum Breakpoints {
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1440,
}

export enum Config {
  BASE_FONT_SIZE = 16,
  XS_HEADER_HEIGHT = 56,
  LG_HEADER_HEIGHT = 80,
  CONTAINER_PADDING = 45,
}
