import React from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { rem } from '../../styles/utils';
import { Variant } from '../../types/product';
import { renderStatus } from '../../utils';

type Props = {
  className?: string;
  selectedVariant?: Variant;
};

const Container = styled.div``;

const QuantityLabel = styled.p<{ isOutOfStock: boolean }>`
  font-family: var(--font-body);
  color: ${({ isOutOfStock }) => (isOutOfStock ? 'red' : 'var(--leather)')};
  font-size: ${rem(10)};
  margin-bottom: 20px;

  ${media.lg`
    font-size: ${rem(12)};
    margin-bottom: 0px;
  `}
`;

const ProductStatusLabel: React.FC<Props> = ({
  className,
  selectedVariant,
}) => {
  if (!selectedVariant) return null;

  return (
    <Container className={className}>
      <QuantityLabel isOutOfStock={selectedVariant.status === 'Out of Stock'}>
        {renderStatus(selectedVariant?.status, selectedVariant?.statusDate)}
      </QuantityLabel>
    </Container>
  );
};

export default ProductStatusLabel;
