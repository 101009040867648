import React from 'react';

import { MattressFirmnessSection } from '../common';
import Video from '../../static/videos/original-mattress-firmness.mp4';

const OriginalMattressFirmnessSection: React.FC = () => {
  return (
    <MattressFirmnessSection
      headline="How does it feel"
      firmness={7.5}
      description="The Original Mattress is soft at the areas of the body with high pressure, while firm at the lighter areas of the body for an overall medium-firm comfort. Great for for Back or Side Sleepers!"
      asset={{
        src: Video,
        type: 'video',
      }}
    />
  );
};

export default OriginalMattressFirmnessSection;
