import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Accordion as AccordionData } from '../../types/component';

const Container = styled.div``;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Item = styled.li`
  margin-bottom: 20px;
`;

const Header = styled.div`
  cursor: pointer;
`;

const Body = styled.div`
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.3s ease-out, visibility 0.3s ease-out;

  &.active {
    visibility: visible;
    transition: max-height 0.3s ease-out, visibility 0.3s ease-out;
  }
`;

type Props = {
  className?: string;
  data: AccordionData[];
  onChange?: (activeIndex: number | number[]) => void;
  // Allow multiple accordions to be open
  allowMultiple?: boolean;
};

const Accordion: React.FC<Props> = ({
  className,
  data,
  onChange,
  allowMultiple,
}) => {
  const accordionRef = useRef<HTMLDivElement>(null);
  const activeIndexTrackerRef = useRef<Set<number>>(new Set());

  useEffect(() => {
    if (accordionRef.current !== null) {
      const listItemBodies = accordionRef.current.querySelectorAll(
        '.accordion-body'
      );

      listItemBodies.forEach(item => {
        if (item.classList.contains('active')) {
          item.setAttribute('style', `max-height: ${item.scrollHeight}px`);
        }
      });
    }
  }, []);

  const handleOnChange = (key: number, action: 'add' | 'delete' = 'add') => {
    if (typeof onChange !== 'function') return;
    if (!allowMultiple) {
      onChange(key);
    } else {
      activeIndexTrackerRef.current[action](key);
      onChange([...activeIndexTrackerRef.current]);
    }
  };

  const toggleForSingle = (
    listItemHeaders: NodeListOf<Element>,
    listItemBodies: NodeListOf<Element>,
    key: number
  ) => {
    listItemBodies.forEach(item => {
      item.removeAttribute('style');
      item.classList.remove('active');
    });

    listItemHeaders.forEach(item => {
      item.classList.remove('active');
    });

    listItemHeaders[key].classList.add('active');
    listItemBodies[key].classList.add('active');
    listItemBodies[key].setAttribute(
      'style',
      `max-height: ${listItemBodies[key].scrollHeight}px`
    );
    handleOnChange(key);
  };

  const toggleForMultiple = (
    listItemHeaders: NodeListOf<Element>,
    listItemBodies: NodeListOf<Element>,
    key: number
  ) => {
    if (listItemBodies[key].classList.contains('active')) {
      listItemHeaders[key].classList.remove('active');
      listItemBodies[key].removeAttribute('style');
      listItemBodies[key].classList.remove('active');
      handleOnChange(key, 'delete');
    } else {
      listItemHeaders[key].classList.add('active');
      listItemBodies[key].classList.add('active');
      listItemBodies[key].setAttribute(
        'style',
        `max-height: ${listItemBodies[key].scrollHeight}px`
      );
      handleOnChange(key, 'add');
    }
  };

  const toggle = (key: number) => {
    if (accordionRef.current !== null) {
      const listItemHeaders = accordionRef.current.querySelectorAll(
        '.accordion-header'
      );
      const listItemBodies = accordionRef.current.querySelectorAll(
        '.accordion-body'
      );

      if (!allowMultiple) {
        toggleForSingle(listItemHeaders, listItemBodies, key);
        return;
      }

      toggleForMultiple(listItemHeaders, listItemBodies, key);
    }
  };

  return (
    <Container className={`accordion ${className}`} ref={accordionRef}>
      <List className="accordion-list">
        {data.map(({ title, body, isActive }, key) => (
          <Item className="accordion-item" key={key}>
            <Header
              className={`accordion-header ${isActive ? 'active' : ''}`}
              role="button"
              onClick={() => toggle(key)}
            >
              {title}
            </Header>
            <Body className={`accordion-body ${isActive ? 'active' : ''}`}>
              {body}
            </Body>
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default Accordion;
