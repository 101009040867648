import React from 'react';

import { WarrantyIntro } from '../common';

const WaterproofMattressProtectorWarrantyIntroSection: React.FC = () => {
  return (
    <WarrantyIntro headline="1 Year Limited Warranty">
      The Waterproof Mattress Protector unique double layered design keeps your
      mattress 100% dry in the event of accidental spills. In the unlikely case
      that your Waterproof Mattress Protector Is met with any defect, we will
      replace it without any charge to you. Your new Waterproof Mattress
      Protector is covered by a 1-Year Limited Warranty, dating from the time
      you purchased it from Woosa Sleep Pte Ltd (“Woosa”) or its authorized
      distributors. The details of this warranty, and some limitations, are
      provided in the complete Warranty Description below.
    </WarrantyIntro>
  );
};

export default WaterproofMattressProtectorWarrantyIntroSection;
