import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { CTA, Unorphan } from '../common';
import { rem, outlineButtonStyle } from '../../styles/utils';

const Section = styled.section`
  width: 100%;
  height: 320px;

  ${media.md`
    height: 500px;
  `}

  ${media.xl`
    height: 600px;
  `}

  .lateral-bg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div`
  text-align: center;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: white;

  ${media.md`
    font-size: ${rem(32)};
  `}

  ${media.lg`
    font-size: ${rem(42)};
  `}
`;

const Button = styled.button`
  ${outlineButtonStyle('light')}
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export type Props = {
  className?: string;
  BgSection: React.FC<{ className?: string }>;
  headline: string;
  cta?: {
    text: string;
    to?: string;
  };
  disableOverlay?: boolean;
};

const LateralSection: React.FC<Props> = ({
  className,
  BgSection,
  headline,
  cta,
  disableOverlay,
}) => {
  return (
    <Section className={className}>
      <BgSection className="lateral-bg">
        <Wrapper className="lateral-wrapper">
          {!disableOverlay && <Overlay />}
          <Container>
            <Headline>
              <Unorphan>{headline}</Unorphan>{' '}
            </Headline>
            {cta && cta.to && (
              <CTA to={cta.to} type="outline" theme="light">
                {cta.text}
              </CTA>
            )}
            {cta && !cta.to && (
              <Button
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                {cta.text}
              </Button>
            )}
          </Container>
        </Wrapper>
      </BgSection>
    </Section>
  );
};

export default LateralSection;
