import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Pillow1 from '../../assets/pillow-1.png';
import Pillow1Webp from '../../assets/pillow-1.webp';
import Pillow2 from '../../assets/pillow-2.png';
import Pillow2Webp from '../../assets/pillow-2.webp';
import Pillow3 from '../../assets/pillow-3.png';
import Pillow3Webp from '../../assets/pillow-3.webp';
import Pillow4 from '../../assets/pillow-4.png';
import Pillow4Webp from '../../assets/pillow-4.webp';

import { ProductSection, Badge } from '../common';

const Section = styled(ProductSection)`
  background-color: var(--pearl-bush);
`;

const UpgradeBadge = styled(Badge)`
  background-color: var(--mongoose);
  color: white;
`;

const PillowProductSection: React.FC = () => {
  const id = 'bLmYFUBvTpH8LcHAmyXP';

  const {
    allProducts: { edges },
  } = useStaticQuery(graphql`
    query {
      allProducts {
        edges {
          node {
            id
            name
            collection
            media {
              path
              default
              type
            }
            rating {
              avg
              count
              total
            }
            variants {
              type
              prices {
                amount
                currency
              }
              status
              statusDate {
                _seconds
              }
            }
          }
        }
      }
    }
  `);

  const product = edges.find(
    ({ node }: { node: { id: string } }) => node.id === id
  );

  return (
    <Section
      {...product.node}
      addOns={[]}
      badge={<UpgradeBadge>Now upgraded</UpgradeBadge>}
      defaultAddOnText="Woosa pillow only"
      assets={[
        {
          src: Pillow1,
          webp: Pillow1Webp,
          type: 'image',
        },
        {
          src: Pillow2,
          webp: Pillow2Webp,
          type: 'image',
        },
        {
          src: Pillow3,
          webp: Pillow3Webp,
          type: 'image',
        },
        {
          src: Pillow4,
          webp: Pillow4Webp,
          type: 'image',
        },
      ]}
    >
      <p>
        Introducing our amazingly plush and supportive Woosa Pillow. This
        premium ergonomic pillow is made using our revolutionary Woosa Cloud
        Foam, developed in Belgium. Wrapped in Italian-made Microtencel fabric
        for extra cool comfort and a restful night&apos;s sleep.
      </p>
      <ul>
        <li>Made in Belgium</li>
        <li>Offers plus medium-firm support</li>
        <li>Now upgraded to be 30% bigger</li>
        <li>Suitable for all sleeping positions</li>
        <li>100% Hypoallergenic</li>
        <li>Great for back and side sleepers</li>
      </ul>
    </Section>
  );
};

export default PillowProductSection;
