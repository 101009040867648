import React from 'react';

import { WarrantyIntro } from '../common';

const PillowWarrantyFooterSection: React.FC = () => {
  return (
    <WarrantyIntro headline="Last Words" borderAt="top" maxWidth="80%">
      Except as otherwise provided herein, Woosa will not charge the purchaser
      to repair or replace the pillow if it is deemed defective during the
      length of this 3 year Limited Warranty, but any transportation costs
      associated with repairs or replacements are the purchaser’s
      responsibility. Woosa will, at its sole discretion, determine whether a
      repair or replacement of a defective pillow is appropriate. In the event a
      warranty claim is filed and a warranty replacement is deemed necessary,
      the purchaser will be required to surrender the original product to Woosa
      at the time of replacement.
      <br />
      <br />
      Repaired or replaced Pillows are subject to the same limited Pillow
      Warranty as the original Pillow. The warranty for any repaired or replaced
      pillow will run from the date of original purchase and not from the date
      of the replacement.
      <br />
      <br />
      For all claims and defects covered under this Limited Warranty, please
      reach us at{' '}
      <a href="mailto:support@woosa.sg" target="_blank" rel="noreferrer">
        support@woosa.sg
      </a>
    </WarrantyIntro>
  );
};

export default PillowWarrantyFooterSection;
