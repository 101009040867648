import { PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID } from 'gatsby-env-variables';
import React from 'react';
import { ProductFixedBottomBar } from '../common';

const WaterproofMattressProtectorProductFixedBottomBar: React.FC = () => {
  return (
    <ProductFixedBottomBar
      productId={PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID}
    />
  );
};

export default WaterproofMattressProtectorProductFixedBottomBar;
