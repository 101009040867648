import React from 'react';
import { Product, Variant } from '../../../types/product';
import { ProductVariantDropdown as ProductVariantDropdownV1 } from './product-variant-dropdown-v1';
import { ProductVariantDropdown as ProductVariantDropdownV2 } from './product-variant-dropdown-v2';

export type Props = {
  className?: string;
  version?: 'v1' | 'v2';
  onChange?: (selected: Variant) => void;
  variants: Variant[];
  label?: string;
  shippingLabel?: string;
  hideStatus?: boolean;
  hidePrice?: boolean;
  plusCoverProduct?: Product | null;
  isPlusCoverChecked?: boolean;
  section?: string;
};

const ProductVariantDropdown: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <ProductVariantDropdownV2 {...props} />;
    default:
      return <ProductVariantDropdownV1 {...props} />;
  }
};

export default ProductVariantDropdown;
