import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import ReviewStars from './review-stars';
import { PriceCartStatus } from '.';
import { Product } from '../../types/product';
import { rem } from '../../styles/utils';
import { CTA } from '../common/index';

export const Title = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: bold;
  margin-bottom: 5px;
  font-size: ${rem(16)};
`;

export const Headline = styled.h2`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-weight: normal;
  margin: 0;
  font-size: ${rem(16)};
`;

const Section = styled.div`
  padding: 20px 0;
`;

const ReviewSection = styled(CTA)`
  margin-bottom: 40px;
  display: block;
  align-items: center;
  text-decoration: none;
  ${media.lg`
    display: flex;
  `}
`;

const ReviewStarsRestyle = styled(ReviewStars)`
  width: auto;
`;

type Props = {
  product: Product;
  selectedVariantType: string;
  fillColor: string;
  reviewLink: string;
};

const ReviewContent: React.FC<Props> = ({
  product,
  selectedVariantType,
  fillColor,
  reviewLink,
}) => {
  const ReviewText = styled.div`
    font-family: var(--font-body);
    color: ${fillColor};
    font-weight: normal;
    margin: 0;
    font-size: ${rem(15)};
    margin-left: 0;
    ${media.lg`
      margin-left: 10px;
    `}
  `;
  const { rating } = product;
  const avg = rating ? rating.avg : 0;
  const size = 5;

  return (
    <Section>
      <ReviewSection to={reviewLink}>
        <ReviewStarsRestyle
          className="w-auto"
          hideText
          fillColor={fillColor}
          rating={avg}
        />
        <ReviewText>
          {avg > size ? size : avg < 0 ? 0 : avg} star
          {avg > 1 ? 's' : ''} {`(${rating?.total || 0})`}
        </ReviewText>
      </ReviewSection>
      <PriceCartStatus
        product={product}
        selectedVariantType={selectedVariantType}
      />
    </Section>
  );
};

export default ReviewContent;
