import React from 'react';
import styled from 'styled-components';

import SvgEgyptian from '../../static/images/cotton-egyptian-association.svg';
import { rem } from '../../styles/utils';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Logo = styled(SvgEgyptian)`
  width: 35px;
  margin-right: 5px;
`;

const Content = styled.p`
  font-family: var(--font-body);
  color: black;
  font-size: ${rem(12)};
  line-height: 1;
`;

const CottonEgyptianAssociation: React.FC = () => {
  return (
    <Container>
      <Logo />
      <Content>
        OEKO-TEX Certified & Accredited by the Cotton Egyptian Association
      </Content>
    </Container>
  );
};

export default CottonEgyptianAssociation;
