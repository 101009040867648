import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import {
  ImgBedFrameBenefit1,
  ImgBedFrameBenefit2,
  ImgBedFrameBenefit3,
  ImgBedFrameBenefit4,
} from '../assets';

const Section = styled(ProductBenefitSection)`
  background-color: var(--harp);
`;

const BedFrameBenefitSection: React.FC = () => (
  <Section
    benefits={[
      {
        img: <ImgBedFrameBenefit1 />,
        eyebrow: 'Pressure Relief',
        headline: 'Silent German Motors',
        body:
          'Upgraded top of the line hydraulic motors from Germany lifts in silence, with infinite angle adjustability. Self-lubricating moving parts mean zero maintenance required.',
      },
      {
        img: <ImgBedFrameBenefit2 />,
        eyebrow: 'Bonus features',
        headline: 'Light and USB',
        body:
          'The Woosa Adjustable Bed comes with a warm underbed night light and dual USB charging ports to power your devices.',
      },
      {
        img: <ImgBedFrameBenefit3 />,
        eyebrow: 'Bonus features',
        headline: 'Massage',
        body:
          'Calming vibration massage modes with preset timer functions help the body relax and improves blood circulation for a more restful sleep.',
      },
      {
        img: <ImgBedFrameBenefit4 />,
        eyebrow: 'Bonus features',
        headline: 'One touch memory function',
        body:
          'Hit the sweet spot with just a tap of a button. Whether legs raised for lower back pain relief, or experience zero gravity, our wireless remote stores up to 4 of your favourite positions.',
      },
    ]}
    blob={{ color1: '#c0d1c5', color2: 'white' }}
    waveLineColor="#cac1b7"
  />
);

export default BedFrameBenefitSection;
