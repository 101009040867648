import React from 'react';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import {
  PRODUCT_PILLOW_ID,
  PRODUCT_SERES_SILK_DUVET_ID,
} from 'gatsby-env-variables';
import { useStaticQuery, graphql } from 'gatsby';

import Duvet1 from '../../static/videos/seres-silk-duvet-1.mp4';
import Duvet1Thumbnail from '../../assets/seres-silk-duvet-1-thumbnail.png';
import Duvet1Webp from '../../assets/seres-silk-duvet-1-thumbnail.webp';
import Duvet2 from '../../assets/seres-silk-duvet-2.png';
import Duvet2Webp from '../../assets/seres-silk-duvet-2.webp';
import Duvet3 from '../../assets/seres-silk-duvet-3.png';
import Duvet3Webp from '../../assets/seres-silk-duvet-3.webp';
import Duvet4 from '../../assets/seres-silk-duvet-4.png';
import Duvet4Webp from '../../assets/seres-silk-duvet-4.webp';
import { ProductSection } from '../common';
import { Product, ProductAddOnConfig } from '../../types/product';
import SvgPillow from '../../static/images/add-on-pillow.svg';

const Section = styled(ProductSection)`
  background-color: var(--pearl-bush);
`;

const SeresSilkDuvetProductSection: React.FC = () => {
  const id = PRODUCT_SERES_SILK_DUVET_ID;
  const addOnConfig: ProductAddOnConfig = {
    [PRODUCT_PILLOW_ID]: {
      icon: <SvgPillow />,
      name: 'Pillow',
      sort: 1,
      qty: 1,
    },
  };

  const {
    allProducts: { edges },
  } = useStaticQuery(graphql`
    query {
      allProducts(filter: { displayInStore: { eq: true } }) {
        edges {
          node {
            id
            name
            collection
            media {
              path
              default
              type
            }
            rating {
              avg
              count
              total
            }
            variants {
              type
              prices {
                amount
                currency
              }
              status
              statusDate {
                _seconds
              }
            }
            displayInStore
          }
        }
      }
    }
  `);

  const product = edges.find(
    ({ node }: { node: { id: string } }) => node.id === id
  );
  const addOns = sortBy(
    edges
      .filter(({ node }: { node: { id: string } }) => node.id in addOnConfig)
      .map(({ node }: { node: Product }) => node),
    [p => addOnConfig[p.id].sort]
  );

  return (
    <Section
      {...product.node}
      addOns={addOns}
      addOnConfig={addOnConfig}
      addOnLabel={<>Accessories add&#8209;on</>}
      defaultAddOnText="Woosa pillow only"
      assets={[
        {
          src: Duvet1,
          thumbnail: Duvet1Thumbnail,
          webp: Duvet1Webp,
          type: 'video',
        },
        {
          src: Duvet2,
          webp: Duvet2Webp,
          type: 'image',
        },
        {
          src: Duvet3,
          webp: Duvet3Webp,
          type: 'image',
        },
        {
          src: Duvet4,
          webp: Duvet4Webp,
          type: 'image',
        },
      ]}
      infoVersion="v2"
    >
      <p>
        A luxuriously soft, silk-filled duvet insert. Only the highest quality
        silk, graded 6A, is used. This duvet hugs your body naturally and keeps
        you cool in the heat and warm on chilly nights.
      </p>
      <ul>
        <li>
          Filled with 100% natural, sustainable and ultra-long Mulberry silk
        </li>
        <li>Extremely breathable with moisture wicking properties</li>
        <li>Hypoallergenic and suitable for sensitive skin</li>
        <li>Long-lasting, for up to 15 years</li>
      </ul>
    </Section>
  );
};

export default SeresSilkDuvetProductSection;
