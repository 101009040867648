import React from 'react';
import styled from 'styled-components';

const Container = styled.video`
  width: 100%;
`;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  src?: any;
  className?: string;
};

const Video: React.FC<Props> = ({ src, className }) => {
  if (!src) return null;

  return (
    <Container
      className={className}
      muted
      loop
      playsInline
      preload="none"
      x-webkit-airplay="true"
      autoPlay
    >
      <source src={src} type="video/mp4" />
    </Container>
  );
};

export default Video;
