import React from 'react';
import styled from 'styled-components';
import { WEB_HOST } from 'gatsby-env-variables';

import { Post } from '../../types/post';
import ShareFacebook from '../../static/images/facebook-blue.svg';
import { rem } from '../../styles/utils';

const Container = styled.div`
  font-family: var(--font-body);
  color: var(--cornflower-blue);
  font-size: ${rem(14)};
  line-height: 1;
  display: flex;
  align-items: center;
`;

const ShareIconLink = styled.a`
  display: block;

  svg {
    width: 32px;
  }
`;

const Text = styled.span`
  margin-right: 16px;
`;

const ShareIconList = styled.ul`
  margin: 0;
  padding: 0;
  display: inline-flex;
  list-style: none;
`;

const ShareIconItem = styled.li`
  margin-right: 8px;
`;

const shareIcons = [
  {
    shareUrl: (url: string) => {
      return `http://www.facebook.com/sharer.php?u=${url}`;
    },
    Icon: ShareFacebook,
  },
];

type Props = {
  post: Post;
};

const PostShare: React.FC<Props> = ({ post }) => {
  return (
    <Container>
      <Text>Share this article</Text>
      <ShareIconList>
        {shareIcons.map(({ Icon, shareUrl }, idx) => (
          <ShareIconItem key={idx}>
            <ShareIconLink
              href={shareUrl(`${WEB_HOST}/post/${post.slug}`)}
              target="_blank"
            >
              <Icon />
            </ShareIconLink>
          </ShareIconItem>
        ))}
      </ShareIconList>
    </Container>
  );
};

export default PostShare;
