import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import SvgStar from '../../static/images/star.svg';
import SvgStarOutline from '../../static/images/star-outline.svg';
import SvgStarHalf from '../../static/images/star-half.svg';
import { rem } from '../../styles/utils';
import Link from './link';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Rating = styled.strong`
  font-family: var(--font-body);
  font-size: ${rem(10)};
  color: var(--leather);
  font-weight: normal;
  margin-left: 5px;

  ${media.lg`
    font-size: ${rem(13)};
  `}
`;

const Stars = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`;

const Star = styled.li<{
  width?: string;
  fillColor?: string;
}>`
  width: ${({ width }) => (width ? width : '15px')};

  svg {
    width: 100%;
    fill: ${({ fillColor }) => fillColor || 'var(--font-secondary-color)'};
  }
`;

const Anchor = styled(Link)`
  text-decoration: none;
`;

type Props = {
  rating: number;
  hideText?: boolean;
  width?: string;
  isYellow?: boolean;
  className?: string;
  hideIfNoRating?: boolean;
  linkUrl?: string;
  fillColor?: string;
};

const ReviewStars: React.FC<Props> = ({
  rating,
  hideText,
  width,
  isYellow,
  fillColor,
  className,
  hideIfNoRating,
  linkUrl,
}) => {
  const stars = [];
  const size = 5;

  let rate = rating;

  for (let i = 0; i < size; ++i) {
    if (rate >= 1) {
      stars.push(<SvgStar />);
    } else if (rate > 0 && rate < 1) {
      stars.push(<SvgStarHalf />);
    } else {
      stars.push(<SvgStarOutline />);
    }
    --rate;
  }

  if (hideIfNoRating && rating === 0) {
    return null;
  }

  const component = (
    <Container className={className}>
      <Stars>
        {stars.map((star, key) => (
          <Star
            width={width}
            key={key}
            fillColor={isYellow ? 'var(--yellow-star)' : fillColor}
          >
            {star}
          </Star>
        ))}
      </Stars>
      {!hideText && (
        <Rating>
          {rating > size ? size : rating < 0 ? 0 : rating} star
          {rating > 1 ? 's' : ''} based on Google &amp; Facebook verified
          reviews
        </Rating>
      )}
    </Container>
  );

  if (linkUrl) {
    return <Anchor to={linkUrl}>{component}</Anchor>;
  }

  return component;
};

export default ReviewStars;
