import React from 'react';
import styled from 'styled-components';
import { media, Row, Col, Container } from 'styled-bootstrap-grid';
import {
  PRODUCT_ORIGINAL_MATTRESS_ID,
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_MYSA_MATTRESS_ID,
} from 'gatsby-env-variables';

import { SiteUrls } from '../../utils/constants';
import { Product } from '../../types/product';
import {
  AccordionSection,
  BasicContent,
  ConstructionContent,
  FirmnessContent,
  FeatureContent,
  MadeForContent,
  ReviewContent,
} from '../common';
import { Accordion } from '../../types/component';
import {
  ImgOriThickness,
  ImgOriWeight,
  ImgOriLayers,
  ImgTerraThickness,
  ImgTerraWeight,
  ImgTerraLayers,
  ImgMysaThickness,
  ImgMysaWeight,
  ImgMysaLayers,
  ImgOriConstruction,
  ImgOriConstruction1,
  ImgOriConstruction2,
  ImgOriConstruction3,
  ImgOriConstruction4,
  ImgTerraConstruction,
  ImgTerraConstruction1,
  ImgTerraConstruction2,
  ImgTerraConstruction3,
  ImgTerraConstruction4,
  ImgTerraConstruction5,
  ImgMysaConstruction,
  ImgMysaConstruction1,
  ImgMysaConstruction2,
  ImgMysaConstruction3,
  ImgMysaConstruction4,
  ImgMysaConstruction5,
  ImgMysaConstruction6,
  ImgOriEllipse,
  ImgTerraEllipse,
  ImgMysaEllipse,
  ImgOriConstructionRes,
  ImgTerraConstructionRes,
  ImgMysaConstructionRes,
  ImgOriEllipseInner,
  ImgTerraEllipseInner,
  ImgMysaEllipseInner,
} from '../assets';

const Section = styled.div`
  padding-top: 80px;
  width: 550px;
  ${media.sm`
    width: auto;
  `}
`;

const ConstructionCol = styled(Col)`
  display: flex;
  align-items: start;
  ${media.lg`
    align-items: end;
  `}
`;

type Props = {
  selectedVariantType: string;
  products: {
    [key: string]: Product;
  };
};

const CompareMattressAccordionSection: React.FC<Props> = ({
  selectedVariantType,
  products,
}) => {
  const oriBasicsData: Accordion[] = [
    {
      title: 'Thickness',
      body: '30.5cm/12”',
      img: <ImgOriThickness />,
    },
    {
      title: 'Weight',
      body: '27/32/45/53kg',
      img: <ImgOriWeight />,
    },
    {
      title: 'Layers',
      body: '4 layers',
      img: <ImgOriLayers />,
    },
  ];
  const terraBasicsData: Accordion[] = [
    {
      title: 'Thickness',
      body: '30.5cm/12”',
      img: <ImgTerraThickness />,
    },
    {
      title: 'Weight',
      body: '27/34/51/56kg',
      img: <ImgTerraWeight />,
    },
    {
      title: 'Layers',
      body: '5 layers',
      img: <ImgTerraLayers />,
    },
  ];
  const mysaBasicsData: Accordion[] = [
    {
      title: 'Thickness',
      body: '30.5cm/12”',
      img: <ImgMysaThickness />,
    },
    {
      title: 'Weight',
      body: '32/37/51/63kg',
      img: <ImgMysaWeight />,
    },
    {
      title: 'Layers',
      body: '6 layers',
      img: <ImgMysaLayers />,
    },
  ];
  const basicsData = [oriBasicsData, terraBasicsData, mysaBasicsData];

  const oriConstructionData: Accordion[] = [
    {
      title: 'Italian-made Microtencel Cover',
      img: <ImgOriConstruction />,
    },
    {
      title: '1: Ultra Premium Sonocore® Latex - 5.5cm',
      img: <ImgOriConstruction1 />,
    },
    {
      title: '2: Ultra Premium Memory Foam - 5.5cm',
      img: <ImgOriConstruction2 />,
    },
    {
      title: '3: High Resiliency Transition Foam - 5cm',
      img: <ImgOriConstruction3 />,
    },
    {
      title: '4: Ultra High Density BaseCore Foam - 14cm',
      img: <ImgOriConstruction4 />,
    },
  ];
  const terraConstructionData: Accordion[] = [
    {
      title: 'Italian-made Microtencel Cover',
      img: <ImgTerraConstruction />,
    },
    {
      title: '1: 100% Natural Latex - 3cm',
      img: <ImgTerraConstruction1 />,
    },
    {
      title: '2: 100% Natural Latex - 5cm',
      img: <ImgTerraConstruction2 />,
    },
    {
      title: '3: Ultra Premium Memory Foam - 5cm',
      img: <ImgTerraConstruction3 />,
    },
    {
      title: '4: High Resiliency Transition Foam - 7cm',
      img: <ImgTerraConstruction4 />,
    },
    {
      title: '5: Ultra High Density BaseCore Foam - 10cm',
      img: <ImgTerraConstruction5 />,
    },
  ];
  const mysaConstructionData: Accordion[] = [
    {
      title: 'Italian-made Microtencel Cover',
      img: <ImgMysaConstruction />,
    },
    {
      title: '1: Woosa Cloud Foam - 3.5cm',
      img: <ImgMysaConstruction1 />,
    },
    {
      title: '2: Ultra Premium Sonocore® Latex - 5cm',
      img: <ImgMysaConstruction2 />,
    },
    {
      title: '3: Ultra Premium Memory Foam - 5cm',
      img: <ImgMysaConstruction3 />,
    },
    {
      title: '4: High Resiliency Transition Foam - 2.5cm',
      img: <ImgMysaConstruction4 />,
    },
    {
      title: '5: Microcoils - 2cm',
      img: <ImgMysaConstruction5 />,
    },
    {
      title: '6: Ultra High Density BaseCore Foam - 13cm',
      img: <ImgMysaConstruction6 />,
    },
  ];
  const constructionsData = [
    { resImg: <ImgOriConstructionRes />, data: oriConstructionData },
    { resImg: <ImgTerraConstructionRes />, data: terraConstructionData },
    { resImg: <ImgMysaConstructionRes />, data: mysaConstructionData },
  ];

  const firmnessData = [
    {
      imgInner: <ImgOriEllipseInner />,
      img: <ImgOriEllipse />,
      value: 7.5,
    },
    {
      imgInner: <ImgTerraEllipseInner />,
      img: <ImgTerraEllipse />,
      value: 9,
    },
    {
      imgInner: <ImgMysaEllipseInner />,
      img: <ImgMysaEllipse />,
      value: 6.5,
    },
  ];

  const oriFeatureData: Accordion[] = [
    {
      title: 'Pressure relieving',
      body:
        'The Original’s unique 4-layer construction work harmoniously together to create a contouring profile for all types of sleeping positions - soft at the areas of the body with high pressure, while firm at the lighter areas of the body for an overall medium-firm comfort.',
    },
    {
      title: 'Perfectly balanced',
      body:
        'Thick premium Sonocore® Latex and European-made Memory Foam creates a surface that is both comfortable and supportive. Soft and bouncy natural latex provides resiliency and responsiveness, while the adaptive memory foam provides localised pressure relieving support. Cold cured HR foam helps with even weight distribution at a deeper level.',
    },
    {
      title: 'Temperature regulating',
      body:
        'Our Hybrid Sonocore® Latex used in the Original Mattress is one of the most breathable in the market thanks to its consistent, open-cell structure. Combine that with our Belgian-made moisture wicking fabric for a cool, comfortable sleep throughout the night.',
    },
  ];
  const terraFeatureData: Accordion[] = [
    {
      title: 'Ergonomic design',
      body:
        'Developed in line with recommendations from professionals in the physiotherapy and chiropractic industry, the Terra is a firm sleeping surface that optimises proper spinal alignment and sleeping posture, while still delivering great comfort',
    },
    {
      title: 'Responsive spinal alignment',
      body:
        '100% Natural Latex layers helps create a firm surface that is quick to recover, so you wouldn’t feel like you’re sleeping on a bed of rocks. Bouncy latex helps ensure spinal alignment and maintains good sleeping posture throughout the night.',
    },
    {
      title: 'Advanced dual latex technology',
      body:
        'Double thick layers of latex offer a premium surface that is extremely durable at great value. Dual densities help create ergonomic support for the entire body without sinking and sagging, specially targeted at areas around the lower back.',
    },
  ];
  const mysaFeatureData: Accordion[] = [
    {
      title: 'Hotel-style comfort',
      body:
        'The addition of our Woosa Cloud Foam creates a whole new level of comfort. The combination of soft velvety plushness from the Cloud Foam, medium-firm bounce from the Sonocore® Latex, and gentle contouring properties from the Memory Foam offers an experience unlike anything in the market.',
    },
    {
      title: 'Responsive spinal alignment',
      body:
        'The Woosa Cloud Foam cushions pressure spots like hips and shoulders, while thicker layers of latex and memory foam offer deeper levels of progressive support. Tiny microcoils in the sublayer creates better lift support in these areas for better spinal alignment.',
    },
    {
      title: 'Advanced dual latex technology',
      body:
        'Open cell foams draw heat and channels it through the microcoils to prevent overheating. Ultra fine fibres used in our Microtencel fabric creates better airflow and dissipates moisture quickly- critical in humid Singapore nights.',
    },
  ];
  const featuresData = [oriFeatureData, terraFeatureData, mysaFeatureData];

  const madeForData: Accordion[] = [
    {
      title: 'Back or Side Sleepers',
      body:
        'We recommend the Original for users who are used to sleeping on medium-firm mattresses or are trying full foam mattress for the first time.',
    },
    {
      title: 'Back Sleepers or Heavy Side-Sleepers',
      body:
        'We recommend the Terra Mattress for users who prefer a firmer surface, or for users coming from firm spring mattresses.',
    },
    {
      title: 'Side Sleepers, Stomach Sleepers or Light- back Sleepers',
      body:
        'We recommend the Mysa Mattress for users coming from memory foam mattresses, or those who are used to sleeping on plushy, spring mattresses.',
    },
  ];

  const reviewData = [
    {
      product: products[PRODUCT_ORIGINAL_MATTRESS_ID],
      selectedVariantType: selectedVariantType,
      fillColor: 'var(--original)',
      reviewLink: SiteUrls.OriginalMattressReview,
    },
    {
      product: products[PRODUCT_TERRA_MATTRESS_ID],
      selectedVariantType: selectedVariantType,
      fillColor: 'var(--terra)',
      reviewLink: SiteUrls.TerraMattressReview,
    },
    {
      product: products[PRODUCT_MYSA_MATTRESS_ID],
      selectedVariantType: selectedVariantType,
      fillColor: 'var(--mysa)',
      reviewLink: SiteUrls.MysaMattressReview,
    },
  ];

  return (
    <Section>
      <AccordionSection title={'Basics'}>
        <Container>
          <Row>
            {basicsData.map((data, key) => (
              <Col xs={4} sm={4} key={key}>
                <BasicContent data={data} />
              </Col>
            ))}
          </Row>
        </Container>
      </AccordionSection>

      <AccordionSection title={'Constructions'}>
        <Container>
          <Row>
            {constructionsData.map((data, key) => (
              <ConstructionCol xs={4} sm={4} key={key}>
                <ConstructionContent {...data} />
              </ConstructionCol>
            ))}
          </Row>
        </Container>
      </AccordionSection>

      <AccordionSection title={'Firmness'}>
        <Container>
          <Row>
            {firmnessData.map((data, key) => (
              <Col xs={4} sm={4} key={key}>
                <FirmnessContent {...data} />
              </Col>
            ))}
          </Row>
        </Container>
      </AccordionSection>

      <AccordionSection title={'Features'}>
        <Container>
          <Row>
            {featuresData.map((data, key) => (
              <Col xs={4} sm={4} key={key}>
                <FeatureContent data={data} />
              </Col>
            ))}
          </Row>
        </Container>
      </AccordionSection>

      <AccordionSection title={'Made for'}>
        <Container>
          <Row>
            {madeForData.map((data, key) => (
              <Col xs={4} sm={4} key={key}>
                <MadeForContent data={data} />
              </Col>
            ))}
          </Row>
        </Container>
      </AccordionSection>

      <AccordionSection title={'Reviews'}>
        <Container>
          <Row>
            {reviewData.map(data => (
              <Col xs={4} sm={4}>
                <ReviewContent {...data} />
              </Col>
            ))}
          </Row>
        </Container>
      </AccordionSection>
    </Section>
  );
};

export default CompareMattressAccordionSection;
