import React from 'react';
import styled from 'styled-components';

import { ReviewGridSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const Section = styled(ReviewGridSection)``;

const PremiumMattressReviewGridSection: React.FC = () => {
  return (
    <Section
      filters={{ id: 'GMrBc8wOJAaOisAn9jNk' }}
      allReviewsUrl={SiteUrls.MysaMattressReview}
    />
  );
};

export default PremiumMattressReviewGridSection;
