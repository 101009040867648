import React from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import {
  ImgIntroWoosaMattress,
  ImgIntroWoosaPremium,
  ImgLogoHomeDecor,
  ImgLogoHoneyCombers,
  ImgLogoQanvast,
  ImgLogoVulcan,
  ImgLogoNoc,
  ImgLogoSilverKris,
} from '../assets';
import { rem, sectionMarginStyle } from '../../styles/utils';
import { CTA, PartnerReviewSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const Section = styled.section`
  padding-top: 100px;

  ${media.lg`
    padding-top: 80px;
  `}

  ${media.xl`
    padding-top: 200px;
  `}

  ${sectionMarginStyle}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${rem(40)};

  ${media.lg`
    margin-bottom: ${rem(80)};
  `}

  ${media.xl`
    margin-bottom: ${rem(100)};
  `}
`;

type ImageContainerProps = {
  align?: 'left' | 'right';
  justify?: 'flex-start' | 'flex-end';
};

const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  max-width: 150px;
  text-align: ${({ align }) => align || 'left'};
  margin-left: ${({ justify }) => (justify === 'flex-end' ? 'auto' : '0')};

  ${media.md`
    max-width: 350px;
  `}

  ${media.xl`
    max-width: 500px;
  `}
`;

const ContentContainer = styled.div`
  position: absolute;
  top: -100px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  width: 250px;

  ${media.lg`
    width: auto;
    top: -80px;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  line-height: 1;
  margin-bottom: 20px;
  font-weight: normal;

  ${media.lg`
    font-size: ${rem(42)};
    margin-bottom: 40px;
  `}
`;

const Eyebrow = styled.strong`
  font-family: var(--font-body);
  font-size: ${rem(10)};
  font-weight: bold;
  letter-spacing: ${rem(0.5)};
  color: var(--font-primary-color);
  text-transform: uppercase;
  margin-top: 20px;
  display: block;

  ${media.lg`
    margin-top: 40px;
    font-size: ${rem(18)};
  `}
`;

const EyebrowLeft = styled(Eyebrow)`
  padding-right: 5px;

  ${media.md`
    padding-right: 15px;
  `}

  ${media.lg`
    padding-right: 20px;
  `}
`;

const EyebrowRight = styled(Eyebrow)`
  padding-left: 5px;

  ${media.lg`
    padding-left: 15px;
  `}

  ${media.lg`
    padding-left: 20px;
  `}
`;

const MattressQuoteSection: React.FC = () => {
  return (
    <Section>
      <Wrapper>
        <ContentContainer>
          <Headline>
            The mattress
            <br />
            everyone&lsquo;s talking about.
          </Headline>
          <CTA to={SiteUrls.OriginalMattress} type="flat">
            Shop now
          </CTA>
        </ContentContainer>
        <ImageContainer align="right">
          <ImgIntroWoosaMattress />
          <EyebrowLeft>The Woosa Mattress</EyebrowLeft>
        </ImageContainer>
        <ImageContainer justify="flex-end">
          <ImgIntroWoosaPremium />
          <EyebrowRight>The Mysa Mattress</EyebrowRight>
        </ImageContainer>
      </Wrapper>
      <PartnerReviewSection
        reviews={[
          {
            content:
              '"..it has the perfect amount of firmness but it’s also soft enough to mould into."',
            logo: <ImgLogoHoneyCombers />,
            url:
              'https://thehoneycombers.com/singapore/woosa-mattress-quality-sleep-rest/',
          },
          {
            content:
              '"...they’re ditching everything you know about the traditional mattress industry."',
            logo: <ImgLogoVulcan />,
            url:
              'https://vulcanpost.com/659376/woosa-sleep-mattress-singapore/',
          },
          {
            content:
              '"the ultimate mattress made for homeowners – without the hefty price tag."',
            logo: <ImgLogoQanvast />,
            url:
              'https://qanvast.com/sg/articles/where-to-find-a-quality-and-affordable-mattress-in-singapore-1347',
          },
          {
            content: '".Woosa offers users the ultimate level of comfort"',
            logo: <ImgLogoHomeDecor />,
            url:
              'https://www.homeanddecor.com.sg/shopping/get-better-sleep-with-this-bed-and-mattress/',
          },
          {
            content: '"suitable for all sleepers and all different positions"',
            logo: <ImgLogoSilverKris />,
            url: 'https://www.silverkris.com/woosa-choosing-right-mattress/',
          },
          {
            content:
              '"..have to spend an extra 30 min getting out of bed because it\'s that comfortable"',
            logo: <ImgLogoNoc />,
            url: 'https://youtu.be/kjvbHOFmCzQ?t=1027',
          },
        ]}
      />
    </Section>
  );
};

export default MattressQuoteSection;
