import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import {
  Content as RawContent,
  Headline,
  Subheadline,
  ArrowCTA,
  ButtonCTA,
  SlideProps,
  Overlay,
} from './hero-carousel-utils';
import { CTAIcons } from '../cta';

const Wrapper = styled.div`
  position: relative;
`;

const Content = styled(RawContent)`
  height: 330px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${media.md`
    height: auto;
    top: auto;
    left: 5%;
    bottom: 5%;
  `}
`;

const HeroCarouselVideoSlide: React.FC<SlideProps> = ({
  Background,
  backgroundVideoSrc,
  headline,
  subheadline,
  cta,
  overlay,
  className,
}) => {
  if (!backgroundVideoSrc) return null;

  const renderContent = () => {
    if (headline || subheadline || cta) {
      return (
        <Content>
          {headline && (
            <Headline dangerouslySetInnerHTML={{ __html: headline }}></Headline>
          )}
          {subheadline && (
            <Subheadline
              dangerouslySetInnerHTML={{ __html: subheadline }}
            ></Subheadline>
          )}
          {cta?.to && <ArrowCTA to={cta?.to} icon={CTAIcons.ARROW_RIGHT} />}
          {cta?.to && (
            <ButtonCTA to={cta?.to} type="flat">
              {cta?.text}
            </ButtonCTA>
          )}
        </Content>
      );
    }
  };

  return (
    <Wrapper className={className}>
      <Background src={backgroundVideoSrc} />
      {overlay && overlay.disabled ? (
        renderContent()
      ) : (
        <Overlay className={overlay?.className}>{renderContent()}</Overlay>
      )}
    </Wrapper>
  );
};

export default HeroCarouselVideoSlide;
