import React from 'react';
import styled from 'styled-components';
import { PRODUCT_EGYPTIAN_COTTON_BEDDING_SET } from 'gatsby-env-variables';

import { SiteUrls } from '../../utils/constants';
import { CottonEgyptianAssociation, ProductSection } from '../common';
import WhiteImage1 from '../../assets/egyptian-bedding-set-white-1.png';
import WhiteImage1WebP from '../../assets/egyptian-bedding-set-white-1.webp';
import WhiteImage2 from '../../assets/egyptian-bedding-set-white-2.png';
import WhiteImage2WebP from '../../assets/egyptian-bedding-set-white-2.webp';
import WhiteImage3 from '../../assets/egyptian-bedding-set-white-3.png';
import WhiteImage3WebP from '../../assets/egyptian-bedding-set-white-3.webp';
import WhiteImage4 from '../../assets/egyptian-bedding-set-white-4.png';
import WhiteImage4WebP from '../../assets/egyptian-bedding-set-white-4.webp';
import WhiteImage5 from '../../assets/egyptian-bedding-set-white-5.png';
import WhiteImage5WebP from '../../assets/egyptian-bedding-set-white-5.webp';
import DustyRoseImage1 from '../../assets/egyptian-bedding-set-dusty-rose-1.png';
import DustyRoseImage1WebP from '../../assets/egyptian-bedding-set-dusty-rose-1.webp';
import DustyRoseImage2 from '../../assets/egyptian-bedding-set-dusty-rose-2.png';
import DustyRoseImage2WebP from '../../assets/egyptian-bedding-set-dusty-rose-2.webp';
import DustyRoseImage3 from '../../assets/egyptian-bedding-set-dusty-rose-3.png';
import DustyRoseImage3WebP from '../../assets/egyptian-bedding-set-dusty-rose-3.webp';
import DustyRoseImage4 from '../../assets/egyptian-bedding-set-dusty-rose-4.png';
import DustyRoseImage4WebP from '../../assets/egyptian-bedding-set-dusty-rose-4.webp';
import DustyRoseImage5 from '../../assets/egyptian-bedding-set-dusty-rose-5.png';
import DustyRoseImage5WebP from '../../assets/egyptian-bedding-set-dusty-rose-5.webp';
import ChalkImage1 from '../../assets/egyptian-bedding-set-chalk-1.png';
import ChalkImage1WebP from '../../assets/egyptian-bedding-set-chalk-1.webp';
import ChalkImage2 from '../../assets/egyptian-bedding-set-chalk-2.png';
import ChalkImage2WebP from '../../assets/egyptian-bedding-set-chalk-2.webp';
import ChalkImage3 from '../../assets/egyptian-bedding-set-chalk-3.png';
import ChalkImage3WebP from '../../assets/egyptian-bedding-set-chalk-3.webp';
import ChalkImage4 from '../../assets/egyptian-bedding-set-chalk-4.png';
import ChalkImage4WebP from '../../assets/egyptian-bedding-set-chalk-4.webp';
import ChalkImage5 from '../../assets/egyptian-bedding-set-chalk-5.png';
import ChalkImage5WebP from '../../assets/egyptian-bedding-set-chalk-5.webp';
import GreyImage1 from '../../assets/egyptian-bedding-set-grey-1.png';
import GreyImage1WebP from '../../assets/egyptian-bedding-set-grey-1.webp';
import GreyImage2 from '../../assets/egyptian-bedding-set-grey-2.png';
import GreyImage2WebP from '../../assets/egyptian-bedding-set-grey-2.webp';
import GreyImage3 from '../../assets/egyptian-bedding-set-grey-3.png';
import GreyImage3WebP from '../../assets/egyptian-bedding-set-grey-3.webp';
import GreyImage4 from '../../assets/egyptian-bedding-set-grey-4.png';
import GreyImage4WebP from '../../assets/egyptian-bedding-set-grey-4.webp';
import GreyImage5 from '../../assets/egyptian-bedding-set-grey-5.png';
import GreyImage5WebP from '../../assets/egyptian-bedding-set-grey-5.webp';
import NavyImage1 from '../../assets/egyptian-bedding-set-navy-1.png';
import NavyImage1WebP from '../../assets/egyptian-bedding-set-navy-1.webp';
import NavyImage2 from '../../assets/egyptian-bedding-set-navy-2.png';
import NavyImage2WebP from '../../assets/egyptian-bedding-set-navy-2.webp';
import NavyImage3 from '../../assets/egyptian-bedding-set-navy-3.png';
import NavyImage3WebP from '../../assets/egyptian-bedding-set-navy-3.webp';
import NavyImage4 from '../../assets/egyptian-bedding-set-navy-4.png';
import NavyImage4WebP from '../../assets/egyptian-bedding-set-navy-4.webp';
import NavyImage5 from '../../assets/egyptian-bedding-set-navy-5.png';
import NavyImage5WebP from '../../assets/egyptian-bedding-set-navy-5.webp';
import { BreadcrumbLink } from '../common/breadcrumb';
import SvgFittedSheet from '../../static/images/fitted-sheet.svg';
import SvgDuvetCover from '../../static/images/duvet-cover.svg';
import SvgPillow from '../../static/images/add-on-pillow.svg';
import useGraphQLGetProduct from '../../hooks/use-graphql-get-product';

const Section = styled(ProductSection)`
  background-color: var(--mercury);
`;

const EgyptianCottonBeddingSetProductSection: React.FC = () => {
  const product = useGraphQLGetProduct(PRODUCT_EGYPTIAN_COTTON_BEDDING_SET);
  const breadcrumbs: BreadcrumbLink[] = [
    { label: 'Accessories' },
    {
      label: 'Egyptian Cotton Bedsheets',
      urlPath: SiteUrls.BedsheetsCategoryPage,
    },
    { label: 'Bedding Set' },
  ];

  return (
    <Section
      version="v2"
      {...product}
      breadcrumbs={breadcrumbs}
      includedInfoList={[
        {
          icon: <SvgFittedSheet />,
          name: 'Fitted Sheet',
          qty: 1,
          variantInfo: {
            'Split King': {
              name: 'Single Fitted Sheets',
              qty: 2,
            },
          },
        },
        {
          icon: <SvgDuvetCover />,
          name: 'Duvet Cover',
          qty: 1,
          variantInfo: {
            'Split King': {
              name: 'King Duvet Cover',
            },
          },
        },
        {
          icon: <SvgPillow />,
          name: 'Pillowcase',
          qty: 1,
          variantInfo: {
            Queen: {
              qty: 2,
            },
            King: {
              qty: 2,
            },
            'Split King': {
              qty: 2,
            },
          },
        },
      ]}
      assets={[
        {
          src: WhiteImage1,
          webp: WhiteImage1WebP,
          type: 'image',
        },
        {
          src: WhiteImage2,
          webp: WhiteImage2WebP,
          type: 'image',
        },
        {
          src: WhiteImage3,
          webp: WhiteImage3WebP,
          type: 'image',
        },
        {
          src: WhiteImage4,
          webp: WhiteImage4WebP,
          type: 'image',
        },
        {
          src: WhiteImage5,
          webp: WhiteImage5WebP,
          type: 'image',
        },
      ]}
      subVariantAssets={{
        White: [],
        'Dusty Rose': [
          {
            src: DustyRoseImage1,
            webp: DustyRoseImage1WebP,
            type: 'image',
          },
          {
            src: DustyRoseImage2,
            webp: DustyRoseImage2WebP,
            type: 'image',
          },
          {
            src: DustyRoseImage3,
            webp: DustyRoseImage3WebP,
            type: 'image',
          },
          {
            src: DustyRoseImage4,
            webp: DustyRoseImage4WebP,
            type: 'image',
          },
          {
            src: DustyRoseImage5,
            webp: DustyRoseImage5WebP,
            type: 'image',
          },
        ],
        Chalk: [
          {
            src: ChalkImage1,
            webp: ChalkImage1WebP,
            type: 'image',
          },
          {
            src: ChalkImage2,
            webp: ChalkImage2WebP,
            type: 'image',
          },
          {
            src: ChalkImage3,
            webp: ChalkImage3WebP,
            type: 'image',
          },
          {
            src: ChalkImage4,
            webp: ChalkImage4WebP,
            type: 'image',
          },
          {
            src: ChalkImage5,
            webp: ChalkImage5WebP,
            type: 'image',
          },
        ],
        Grey: [
          {
            src: GreyImage1,
            webp: GreyImage1WebP,
            type: 'image',
          },
          {
            src: GreyImage2,
            webp: GreyImage2WebP,
            type: 'image',
          },
          {
            src: GreyImage3,
            webp: GreyImage3WebP,
            type: 'image',
          },
          {
            src: GreyImage4,
            webp: GreyImage4WebP,
            type: 'image',
          },
          {
            src: GreyImage5,
            webp: GreyImage5WebP,
            type: 'image',
          },
        ],
        Navy: [
          {
            src: NavyImage1,
            webp: NavyImage1WebP,
            type: 'image',
          },
          {
            src: NavyImage2,
            webp: NavyImage2WebP,
            type: 'image',
          },
          {
            src: NavyImage3,
            webp: NavyImage3WebP,
            type: 'image',
          },
          {
            src: NavyImage4,
            webp: NavyImage4WebP,
            type: 'image',
          },
          {
            src: NavyImage5,
            webp: NavyImage5WebP,
            type: 'image',
          },
        ],
      }}
    >
      <p>
        Crafted from the finest cotton in the world, these bedsheets are
        designed to provide you with the ultimate sleeping experience, night
        after night.
      </p>
      <ul>
        <li>Made from 100% Pure Egyptian Giza Cotton</li>
        <li>600 TC with sateen weave for a silky finish</li>
        <li>Gets softer with every wash</li>
        <li>Extra durable and lasts up to 10-15 years</li>
        <li>Designed in Singapore, Made in Portugal</li>
      </ul>
      <CottonEgyptianAssociation />
    </Section>
  );
};

export default EgyptianCottonBeddingSetProductSection;
