import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { HeroIntro } from '../common';
import { BGIntroBedsheetEgyptian } from '../assets';

const Intro = styled(HeroIntro)`
  .hero-intro {
    height: 200px;

    ${media.md`
      height: 300px;
    `}

    ${media.lg`
      height: 450px;
    `}

    ${media.xl`
      height: 700px;
    `}
  }

  .hero-intro-content {
    padding: 30px 0;

    ${media.md`
      padding: 50px 0;
    `}

    ${media.lg`
      padding: 100px 0;
    `}
  }
`;

const EgyptianCottonBeddingSetIntroSection: React.FC = () => {
  return (
    <Intro
      version="v2"
      BackgroundImage={BGIntroBedsheetEgyptian}
      eyebrow="Design Process"
      headline="Unmatched Quality and Durability"
      body={
        <>
          <p>
            When it comes to selecting the perfect bedsheets, the choice of
            fabric plays a crucial role in determining their quality and
            longevity. This is where the significance of long-staple cotton,
            such as our 100% Pure Egyptian Giza Cotton, shines through. Longer
            fibers mean fewer breakages, resulting in bedsheets that can
            withstand the test of time, maintaining their integrity and
            luxurious feel even after countless uses and washes. 
          </p>
          <p>
            Additionally, the extended fibers create a smoother surface,
            reducing the likelihood of pilling or roughness. The natural
            breathability of long-strand cotton allows air to circulate freely,
            ensuring a cool and comfortable sleep throughout the night. The
            result is a sumptuous, buttery-soft texture that gently embraces
            your body, allowing you to experience a level of comfort and
            indulgence that is simply unmatched. 
          </p>
        </>
      }
    />
  );
};

export default EgyptianCottonBeddingSetIntroSection;
