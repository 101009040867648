import React from 'react';
import { PRODUCT_ADJUSTABLE_BASE_ID } from 'gatsby-env-variables';

import { UpsellHeroSection } from '../common';
import { ImgTerraUpsellHero } from '../assets';
import { SiteUrls } from '../../utils/constants';

const MysaMattressUpsellHeroSection: React.FC = () => {
  return (
    <UpsellHeroSection
      eyebrow="Upgrade to the full experience"
      headline="Get additional 10% off when you bundle with our adjustable base"
      products={[
        {
          image: <ImgTerraUpsellHero />,
          name: 'Adjustable bases',
          description:
            'Upgrade your nights by customizing your positions in bed.',
          productId: PRODUCT_ADJUSTABLE_BASE_ID,
          to: SiteUrls.BedFrame,
        },
      ]}
    />
  );
};

export default MysaMattressUpsellHeroSection;
