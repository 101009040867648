import React from 'react';
import { PRODUCT_EGYPTIAN_COTTON_BEDDING_SET } from 'gatsby-env-variables';

import { ProductReviewHeroSection } from '../common';
import { SiteUrls } from '../../utils/constants';

const EgyptianCottonBeddingSetReviewHeroSection: React.FC = () => {
  return (
    <ProductReviewHeroSection
      productId={PRODUCT_EGYPTIAN_COTTON_BEDDING_SET}
      allReviewsUrl={SiteUrls.EgyptianCottonBeddingSetReview}
    />
  );
};

export default EgyptianCottonBeddingSetReviewHeroSection;
