import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, media } from 'styled-bootstrap-grid';
import moment from 'moment';

import { Tab, Tabs, TabList, TabPanel } from '../tabs';
import { rem } from '../../../styles/utils';
import { Product, Variant } from '../../../types/product';
import { ProductOrderItem } from '../../../types/order';
import FormCheckbox from '../form-checkbox';
import Spinner from '../spinner';
import { getProductById } from '../../../utils/api';
import StandardVariantTab from './standard-variant-tab';
import CustomVariantTab from './custom-variant-tab';
import SimpleSubVariant from './simple-sub-variant';
import { isSimpleSubVariant } from '../../../utils';

const Container = styled.div`
  font-family: var(--font-body);
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: 1.2;

  ${media.lg`
    font-size: ${rem(14)};
  `}

  ${media.xl`
    font-size: ${rem(16)};
    line-height: 1.3125;
  `}
`;

const CustomCheckBox = styled(FormCheckbox)`
  margin-bottom: 20px;

  label {
    padding-left: 39px;

    &:before {
      height: 24px;
      width: 24px;
      background: white;
      border: 1px solid var(--border-color);
      border-radius: 2.5px;
    }

    &:after {
      height: 7.5px;
      width: 13.5px;
      left: 5px;
      top: 3px;
      border-color: var(--leather);
    }
  }
`;

const TabNavbar = styled(TabList)`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
`;

const TabNavItem = styled(Tab)`
  border-bottom: 1px solid black;
  width: 100%;
  opacity: 0.2;
  padding-bottom: 8px;
  cursor: pointer;
  background-color: transparent;

  &.react-tabs__tab--selected {
    opacity: 1;
  }

  small {
    display: block;
    font-weight: 400;
    font-size: ${rem(12)};
    line-height; 1.16;
    color: var(--font-secondary-color);
  }
`;

type Props = {
  product: Product;
  checkboxLabel?: string;
  selectedVariant?: Variant;
  onChange?: (productOrderItem: ProductOrderItem | null) => void;
  defaultEnabled?: boolean;
};

type TabData = {
  type: 'Standard' | 'Custom';
  title: string;
  subTitle: string;
};

const ProductCustomAddOn: React.FC<Props> = ({
  product,
  checkboxLabel,
  selectedVariant,
  onChange,
  defaultEnabled,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [addOn, setAddOn] = useState<Product | null>(null);
  const [tabData, setTabData] = useState<TabData[]>([]);
  const [enabled, setEnabled] = useState(defaultEnabled || false);
  const [isLoading, setIsLoading] = useState(false);

  const mapStatusToNote = (
    statusType: 'Standard' | 'Custom',
    status: 'Ready to Ship' | 'Pre-Order' | 'Out of Stock',
    statusDate: Date,
    statusNote: string
  ): string => {
    if (statusType === 'Custom') {
      return statusNote;
    }

    switch (status) {
      case 'Ready to Ship':
        return 'Instock';
      case 'Pre-Order':
        return `PRE-ORDER: Earliest shipment ${moment(statusDate).format(
          'Do MMM YYYY'
        )}`;
      default:
        return 'Out of Stock';
    }
  };

  const mapProductVariantToTabData = (p: Product | null) => {
    if (!p) {
      setTabData([]);
      return;
    }

    const variants: Variant[] = [];

    const firstStandardVariant = p.variants.find(
      v => v.statusType === 'Standard'
    );
    const firstCustomVariant = p.variants.find(v => v.statusType === 'Custom');

    if (firstStandardVariant) {
      variants.push(firstStandardVariant);
    }

    if (firstCustomVariant) {
      variants.push(firstCustomVariant);
    }

    setTabData(
      variants.map(v => ({
        type: v.statusType,
        title: v.statusType,
        subTitle: mapStatusToNote(
          v.statusType,
          v.status,
          v.statusDate
            ? moment.unix(v.statusDate._seconds).toDate()
            : moment().toDate(),
          v.statusNote
        ),
      }))
    );
  };

  // Effect to load product data from remote server
  useEffect(() => {
    setTabIndex(0);
    setTabData([]);
    setAddOn(null);
    handleChange(null, 0);
    setEnabled(defaultEnabled || false);
    setIsLoading(true);
    getProductById(product.id).then(dataProduct => {
      let variants = dataProduct.variants.filter(
        v => v.status !== 'Out of Stock'
      );
      if (selectedVariant !== null && variants.length > 1) {
        variants = variants.filter(
          v => v.type.toLowerCase() === selectedVariant?.type.toLowerCase()
        );
      }
      if (variants.length > 0) {
        const addOnProduct = { ...product, variants };
        setAddOn(addOnProduct);
        mapProductVariantToTabData(addOnProduct);
      }
      setIsLoading(false);
    });
  }, [product, selectedVariant]);

  const handleChange = (
    productOrderItem: ProductOrderItem | null,
    tabIndex: number
  ) => {
    if (productOrderItem) {
      const newTabData = [...tabData];
      const { statusType, status, statusDate, statusNote } = productOrderItem;
      if (statusType && status && statusDate && statusNote) {
        newTabData[tabIndex].subTitle = mapStatusToNote(
          statusType,
          status,
          statusDate,
          statusNote
        );
        setTabData(newTabData);
      }
    } else {
      mapProductVariantToTabData(addOn);
    }

    if (typeof onChange !== 'function') return;
    onChange(productOrderItem);
  };

  if (isSimpleSubVariant(product.subVariantKeys) && addOn) {
    return (
      <SimpleSubVariant product={addOn} onChange={p => handleChange(p, 0)} />
    );
  }

  return (
    <Container>
      {checkboxLabel && (
        <CustomCheckBox
          label={checkboxLabel}
          inputProps={{
            id: `product-custom-add-on-checkbox-${product.id}`,
            checked: enabled,
            onChange: () => {
              const isEnabled = !enabled;
              setEnabled(isEnabled);
              if (isEnabled) return;
              handleChange(null, 0);
            },
          }}
        />
      )}
      {enabled && (
        <Tabs
          selectedIndex={tabIndex}
          onSelect={index => {
            setTabIndex(index);
            handleChange(null, index);
          }}
        >
          <TabNavbar>
            {tabData.map(t => (
              <TabNavItem key={t.type}>
                {t.title} <small>{t.subTitle}</small>
              </TabNavItem>
            ))}
          </TabNavbar>
          {tabData.map((t, i) => (
            <TabPanel key={t.type}>
              {t.type === 'Standard' && addOn && (
                <StandardVariantTab
                  addOnProduct={addOn}
                  onChange={p => handleChange(p, i)}
                />
              )}
              {t.type === 'Custom' && addOn && (
                <CustomVariantTab
                  addOnProduct={addOn}
                  onChange={p => handleChange(p, i)}
                />
              )}
            </TabPanel>
          ))}
        </Tabs>
      )}
      <Spinner isLoading={isLoading} size={30} />
    </Container>
  );
};

export default ProductCustomAddOn;
