import React from 'react';

import IconGridSectionV1, {
  Props as DefaultPropsV1,
} from './icon-grid-section';
import IconGridSectionV2, {
  Props as DefaultPropsV2,
} from './icon-grid-section-v2';

type PropsV1 = {
  version: 'v1';
} & DefaultPropsV1;

type PropsV2 = {
  version: 'v2';
} & DefaultPropsV2;

type Props = PropsV1 | PropsV2;

const IconGridSection: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <IconGridSectionV2 {...props} />;
    default:
      return <IconGridSectionV1 {...props} />;
  }
};

export default IconGridSection;
