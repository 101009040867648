import React from 'react';
import styled from 'styled-components';
import YouTube, { Options } from 'react-youtube';

import { getYoutubeId } from '../../utils';

const Container = styled.div`
  width: 100%;
  height: 100%;

  div:first-of-type {
    width: 100%;
    height: 100%;
  }
`;

type Props = {
  url: string;
  className?: string;
};

const YoutubeEmbedded: React.FC<Props> = ({ url, className }) => {
  const videoId = getYoutubeId(url);

  const opts: Options = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 0,
      rel: 0,
    },
  };

  return (
    <Container className={className}>
      <YouTube videoId={videoId} opts={opts} />
    </Container>
  );
};

export default YoutubeEmbedded;
