import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { HeroIntro } from '../common';
import Video from '../../static/videos/storage-base-intro-1.mp4';

const Intro = styled(HeroIntro)`
  .hero-intro {
    height: 200px;

    ${media.md`
      height: 300px;
    `}

    ${media.lg`
      height: 450px;
    `}

    ${media.xl`
      height: 700px;
    `}
  }

  .hero-intro-content {
    padding: 30px 0;

    ${media.md`
      padding: 50px 0;
    `}

    ${media.lg`
      padding: 100px 0;
    `}
  }
`;

const StorageBaseIntroSection: React.FC = () => {
  return <Intro version="v2" backgroundVideoSrc={Video} />;
};

export default StorageBaseIntroSection;
