import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';
import { Link } from '../common';
import { Dropdown } from '../../types/nav-item';

type StyleProps = {
  isActive: boolean;
};

const NavItemStyle = styled.li<StyleProps>`
  font-family: var(--font-body);
  font-size: ${rem(14)};
  color: var(--font-nav-color);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: ${rem(1)};
  padding: 20px 30px;
  border-bottom: 1px solid var(--border-color);
  background-color: ${({ isActive }) =>
    isActive ? 'var(--border-color)' : 'transparent'};

  ${media.lg`
    font-size: ${rem(16)};
    background-color: transparent;
    border-bottom: none;

    &:hover {
      div[class^='nav-item__DropdownMenu'] {
        max-height: 1000px;
      }

      /* span[class^="nav-item__Text"] {
        border-bottom: solid 2px var(--font-nav-color);
      } */
    }
  `}

  &:first-child {
    border-top: 1px solid var(--border-color);

    ${media.lg`
      border-top: none;
    `}
  }

  a {
    color: var(--font-primary-color);
    text-decoration: none;
  }
`;

const DropdownMenu = styled.div<StyleProps>`
  display: none;

  ${({ isActive }) =>
    isActive &&
    css`
      display: block;
    `}

  ${media.lg`
    display: block;
    will-change: max-height;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background-color: var(--desert-storm);
    max-height: 0;
    transition: max-height 0.2s ease-in-out;
    overflow-x: hidden;
  `}
`;

const DropdownContainer = styled.div`
  ${media.lg`
    width: 780px;
    margin: 0 auto;
    padding: 50px 0 80px 30px;
    display: flex;
  `}
`;

const DropdownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const DropdownItem = styled.li`
  font-size: ${rem(16)};
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  margin: 0;
  padding-top: 15px;
`;

const Text = styled.span`
  cursor: pointer;
  display: inline;
  padding-bottom: 3px;
`;

const DropdownTitle = styled.span`
  display: none;

  ${media.lg`
    display: block;
    margin-bottom: 25px;
  `}
`;

const DropdownLink = styled(Link)`
  display: block;
  transform: translateX(0);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateX(5px);
  }
`;

const MediaContainer = styled.div`
  position: relative;
  margin-left: auto;
`;

const DropdownMedia = styled.div`
  display: none;
  border-radius: 20px;
  overflow: hidden;

  ${media.lg`
    width: 490px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;

    &:first-child {
      position: relative;
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }
  `}
`;

const NestedDropdownContainer = styled.div`
  position: relative;
`;

const NestedDropdownMenu = styled.div<StyleProps>`
  will-change: max-height;
  max-height: ${({ isActive }) => (isActive ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

const NestedDropdownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 0 15px;
`;

const NestedDropdownText = styled.span<StyleProps>`
  cursor: pointer;
  display: inline;
  padding-bottom: 3px;
  padding-left: 15px;
  display: relative;

  &:before {
    content: '${({ isActive }) => (isActive ? '-' : '+')}';
    position: absolute;
    left: 0;
  }
`;

type Props = {
  className?: string;
  text: string;
  to?: string;
  dropdowns?: Dropdown[];
  media?: React.FC[];
};

const NavItem: React.FC<Props> = ({
  className,
  text,
  to,
  dropdowns,
  media,
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const links = ref.current.querySelectorAll('[data-hover-index]');
    const media = ref.current.querySelectorAll('[data-media-index]');

    const hoverEffect = (type: 'over' | 'out', index: number) => {
      media.forEach(m => m.classList.remove('active'));
      if (type === 'over' && media[index]) {
        media[index].classList.add('active');
      }
    };

    links.forEach((link, index) => {
      link.addEventListener('mouseover', () => hoverEffect('over', index));
      link.addEventListener('mouseout', () => hoverEffect('out', index));
    });

    return () => {
      links.forEach((link, index) => {
        link.removeEventListener('mouseover', () => hoverEffect('over', index));
        link.removeEventListener('mouseout', () => hoverEffect('out', index));
      });
    };
  }, []);

  return (
    <NavItemStyle className={className} isActive={active} ref={ref}>
      {to ? (
        <Link to={to}>
          <Text>{text}</Text>
        </Link>
      ) : (
        <Text onClick={() => setActive(!active)}>{text}</Text>
      )}
      {dropdowns && (
        <DropdownMenu isActive={active}>
          <DropdownContainer>
            <div>
              <DropdownTitle>{text}</DropdownTitle>
              <DropdownList>
                {dropdowns.map(
                  ({ text, to, dropdowns: nestedDropdowns }, key) => (
                    <DropdownItem key={key} data-hover-index={key}>
                      {nestedDropdowns && nestedDropdowns.length > 0 ? (
                        <NestedDropdowns
                          text={text}
                          dropdowns={nestedDropdowns}
                        />
                      ) : (
                        <DropdownLink to={to}>{text}</DropdownLink>
                      )}
                    </DropdownItem>
                  )
                )}
              </DropdownList>
            </div>
            <MediaContainer>
              {media &&
                media.map((Media, key) => (
                  <DropdownMedia key={key} data-media-index={key}>
                    <Media />
                  </DropdownMedia>
                ))}
            </MediaContainer>
          </DropdownContainer>
        </DropdownMenu>
      )}
    </NavItemStyle>
  );
};

const ResponsiveNavItem = styled(NavItem)`
  ${media.lg`
    display: none;
  `}

  &.desktop {
    display: none;

    ${media.lg`
      display: block;
    `}
  }
`;

const NestedDropdowns: React.FC<{ text: string; dropdowns: Dropdown[] }> = ({
  text,
  dropdowns,
}) => {
  const [active, setActive] = useState(false);

  return (
    <NestedDropdownContainer>
      <NestedDropdownText isActive={active} onClick={() => setActive(!active)}>
        {text}
      </NestedDropdownText>
      <NestedDropdownMenu isActive={active}>
        <NestedDropdownList>
          {dropdowns.map((d, i) => (
            <DropdownItem key={i}>
              <DropdownLink to={d.to}>{d.text}</DropdownLink>
            </DropdownItem>
          ))}
        </NestedDropdownList>
      </NestedDropdownMenu>
    </NestedDropdownContainer>
  );
};

export { ResponsiveNavItem };

export default NavItem;
