import React from 'react';
import styled from 'styled-components';

import { Container } from '../common';
import { ImgHomeComboAdsBanner, ImgHomeComboAdsBannerMobile } from '../assets';
import { lh, rem } from '../../styles/utils';
import { media } from 'styled-bootstrap-grid';

const Section = styled.section`
  margin: 45px 0;

  ${media.lg`
    margin: 90px 0; 
  `}
`;

const Wrapper = styled.div`
  background-color: var(--mystic);
  padding: 32px 16px;
  text-align: center;

  ${media.md`
    padding: 40px; 
  `}

  ${media.xl`
    padding: 80px;
    display: flex; 
    text-align: left;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(32, 24)};
  color: var(--font-primary-color);

  ${media.xl`
    font-size: ${rem(36)};
    line-height: ${lh(39.6, 36)}; 
  `}
`;

const Footnote = styled.p`
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(12)};
  line-height: ${lh(14, 12)};
  color: var(--font-primary-color);

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(14, 16)};
  `}
`;

const Content = styled.div`
  ${media.xl`
    flex: calc(100% - 650px);
  `}
`;

const Asset = styled.div`
  display: none;

  ${media.xl`
    display: block;
    flex: 650px;
  `}
`;

const MobileAsset = styled.div`
  ${media.xl`
    display: none;
  `}
`;

const HomeAdsBannerSection: React.FC = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <MobileAsset>
            <ImgHomeComboAdsBannerMobile />
          </MobileAsset>
          <Content>
            <Headline>
              10% off your entire bill when you purchase any Mattress with The
              Woosa Adjustable Base
            </Headline>
            <Footnote>
              Promo code “COMBO” automatically applied at checkout!
            </Footnote>
          </Content>
          <Asset>
            <ImgHomeComboAdsBanner />
          </Asset>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default HomeAdsBannerSection;
