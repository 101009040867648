import React, { createContext, useContext, useEffect } from 'react';

import {
  State,
  Dispatch,
  CheckoutProviderProps,
} from './checkout-context-types';
import { initialState } from './checkout-context-config';
import { getAddressFromLocalStorage } from './checkout-context-utils';
import { checkoutReducer } from './checkout-context-reducer';
import { useCustomReducer } from '../use-custom-reducer';

const CheckoutStateContext = createContext<State | undefined>(undefined);
const CheckoutDispatchContext = createContext<Dispatch | undefined>(undefined);

export const CheckoutProvider: React.FC<CheckoutProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useCustomReducer(
    'CheckoutContext',
    checkoutReducer,
    initialState
  );

  useEffect(() => {
    const data = getAddressFromLocalStorage();
    if (!data) return;
    dispatch({
      type: 'UPDATE_ADDRESS',
      sameAddress: data.sameAddress,
      shipping: data.shipping,
      billing: data.billing,
    });
  }, []);

  return (
    <CheckoutStateContext.Provider value={state}>
      <CheckoutDispatchContext.Provider value={dispatch}>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
};

export const useCheckoutState = (): State | never => {
  const context = useContext(CheckoutStateContext);
  if (context === undefined) {
    throw new Error('useCheckoutState must be used within a CheckoutProvider');
  }
  return context;
};

export const useCheckoutDispatch = (): Dispatch | never => {
  const context = useContext(CheckoutDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useCheckoutDispatch must be used within a CheckoutProvider'
    );
  }
  return context;
};
