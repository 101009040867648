import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Breakpoints } from '../../styles/enums';

type Props = {
  className?: string;
};

const ImgAdjustableBaseSmartApp: React.FC<Props> = ({ className }) => {
  const { desktop, tablet, mobile } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "adjustable-base-2-smart-app.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tablet: file(relativePath: { eq: "adjustable-base-2-smart-app-t.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "adjustable-base-2-smart-app-m.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  console.log(mobile, tablet, desktop);
  const sources = [
    mobile.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: ${Breakpoints.MD}px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: ${Breakpoints.LG}px)`,
    },
  ];

  return <Img className={className} fluid={sources} />;
};

export default ImgAdjustableBaseSmartApp;
