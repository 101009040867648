import React, { useEffect, useState } from 'react';
import { useStripe, Elements } from '@stripe/react-stripe-js';
import { useLocation } from '@reach/router';
import * as Sentry from '@sentry/gatsby';
import queryString from 'query-string';
import Loader from 'react-loader-spinner';
import { loadStripePromise, unslugify } from '../../utils';
import {
  logEvent,
  patchOrderByPaymentToken,
  sendOrderReceiptByPaymentToken,
} from '../../utils/api';
import { ImgLogo } from '../assets/index';
import styled, { createGlobalStyle } from 'styled-components';
import { rem } from '../../styles/utils';
import { useCartDispatch } from '../../hooks/cart-context';
import {
  CheckoutProvider,
  useCheckoutDispatch,
} from '../../hooks/checkout-context';

const OverrideGlobalStyles = createGlobalStyle`
  body {
    padding-top: 0 !important;
  }
`;

const LogoIcon = styled(ImgLogo)`
  width: 80px;
`;

const Section = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  padding: 0 15px;
`;

const LoadingContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  margin-top: 40px;
`;

const Title = styled.p`
  font-size: ${rem(20)};
  text-align: center;
`;

const stripePromise = loadStripePromise();

const GrabPayReturnForm: React.FC = () => {
  const stripe = useStripe();
  const location = useLocation();
  const cartDispatch = useCartDispatch();
  const checkoutDispatch = useCheckoutDispatch();
  const query = location.search
    ? queryString.parse(location.search)
    : { payment_intent_client_secret: '', paymentToken: '', nextPath: '' };
  const clientSecret = query.payment_intent_client_secret;
  const { paymentToken, nextPath } = query;
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    (async () => {
      let hasSucceeded = false;
      try {
        if (!paymentToken)
          throw 'No payment token found on return from GrabPay';
        if (!clientSecret)
          throw 'No client secret found on return from GrabPay';

        const secret = unslugify(
          typeof clientSecret === 'string' ? clientSecret : clientSecret[0]
        );
        const token = unslugify(
          typeof paymentToken === 'string' ? paymentToken : paymentToken[0]
        );

        const payload = await stripe.retrievePaymentIntent(secret);
        hasSucceeded =
          (payload.paymentIntent &&
            payload.paymentIntent.status === 'succeeded') ||
          false;

        await patchOrderByPaymentToken(
          token,
          payload.paymentIntent,
          hasSucceeded ? 'processing' : 'payment error',
          'stripe',
          'payment link',
          'Payment via GrabPay'
        );

        // Clear all localstorage data no matter whether successful or not
        // We handle error manually from here by asking customers to contact Woosa for technical support
        cartDispatch({ type: 'CLEAR_CART' });
        checkoutDispatch({ type: 'CLEAR_ADDRESS' });

        if (hasSucceeded) {
          await sendOrderReceiptByPaymentToken(token);
          window.requestAnimationFrame(
            () =>
              (window.location.href = `/${
                nextPath || 'summary'
              }?token=${paymentToken}`)
          );
        } else {
          Sentry.captureException(payload.error, {
            extra: {
              stripePaymentIntent: payload.paymentIntent,
            },
          });
          logEvent('error', 'GrabPay (Stripe) payment error', {
            paymentToken: token,
            stripe: payload,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        if (!hasSucceeded) {
          setErrorMessage(
            'Payment unsuccessful, unexpected error occurred, please contact us for further assistance.'
          );
        }
      }
    })();
  }, [clientSecret, stripe, paymentToken]);

  return (
    <Section>
      <OverrideGlobalStyles />
      <LogoIcon />
      {!errorMessage && (
        <LoadingContainer>
          <Loader type="Oval" color="#000000" height={60} width={60} />
          <Title>
            Payment processing, please keep this window open until
            payment&nbsp;completes.
          </Title>
        </LoadingContainer>
      )}
      {errorMessage && (
        <ErrorContainer>
          <Title>{errorMessage}</Title>
        </ErrorContainer>
      )}
    </Section>
  );
};

const GrabPayReturnSection: React.FC = () => {
  return (
    <CheckoutProvider>
      <Elements stripe={stripePromise}>
        <GrabPayReturnForm />
      </Elements>
    </CheckoutProvider>
  );
};

export default GrabPayReturnSection;
