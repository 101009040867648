import React from 'react';
import styled from 'styled-components';
import { lh, rem } from '../../../styles/utils';
import { Product, Variant } from '../../../types/product';
import { ProductOrderItem } from '../../../types/order';

import {
  BgPlusCover,
  ImgExtraCool,
  ImgSilkySmooth,
  ImgEnhancedComfort,
} from '../../assets';

import { media, Container as GridContainer } from 'styled-bootstrap-grid';
import ProductPlusCoverCheckbox from './product-plus-cover-checkbox';

const Section = styled.section`
  font-family: var(--font-body);
  font-weight: 600;
  font-size: ${rem(16)};
  
  border: 1px solid #D6D8D2;
  border-radius: 3px;

  .plus-cover-bg{
    position: relative;
    height: 209px;

    ${media.md`
      height: 150px;
    `}

    ${media.lg`
      height: 180px;
    `}

    ${media.xl`
      height: 209px;
    `}

    ${media.xs`
      max-height: 150px;
    `}    
  }
`;

const Img = styled.div`
  min-width: 65px;
  height: auto;
  margin-right: 10px;

  ${media.lg`
    display: block;
  `}
`;

const Container = styled(GridContainer)`
  padding: 24px;
`;

const Wrapper = styled.div`
  &.enabled-description {
    opacity: 1;
    background-color: #ffffff;
  }

  opacity: 0.4;
  ${media.lg`
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center; 
    padding: 0;
  `}
`;

const Content = styled.div`
  ${media.lg`
    display: flex;
    flex-wrap: wrap;
  `}
`;

const SubContent = styled.div`
  display: flex;

  ${media.lg`
    width: 50%;
    margin-bottom: 24px;
  `}

  &:nth-child(3) {
    margin-bottom: 0;
  }
`;

const Title = styled.h3`
  font-size: ${rem(12)};
  line-height: ${lh(15, 12)};
  font-weight: 600;
  display: flex;
  flex-direction: column;

  ${media.lg`
    align-items: start;
    font-size: ${rem(14)};
    line-height: ${lh(17, 14)};
    margin: 0;
  `}
`;

const Paragraph = styled.p`
  font-weight: 400;
  margin: 0;
  color: var(--font-secondary-color);

  ${media.lg`
  `}
`;

type Props = {
  product: Product;
  checkboxLabel?: string;
  selectedVariant?: Variant;
  onChange?: (
    productOrderItem: ProductOrderItem | null,
    isChecked: boolean
  ) => void;
  isPlusCoverChecked?: boolean;
};

const ProductPlusCover: React.FC<Props> = ({
  product,
  selectedVariant,
  onChange,
  isPlusCoverChecked,
}) => {
  return (
    <Section>
      <BgPlusCover className="plus-cover-bg">
        <ProductPlusCoverCheckbox
          checkboxLabel="Upgrade to PLUS+ Cover"
          selectedVariant={selectedVariant}
          product={product}
          onChange={onChange}
          isPlusCoverChecked={isPlusCoverChecked}
        />
      </BgPlusCover>
      <Wrapper className={isPlusCoverChecked ? 'enabled-description' : ''}>
        <Container>
          <Content>
            <SubContent>
              <Img>
                <ImgExtraCool />
              </Img>
              <Title>
                Extra cool to the touch
                <Paragraph>
                  Made in Italy from a blend of microtencel and cooling yarn.
                </Paragraph>
              </Title>
            </SubContent>
            <SubContent>
              <Img>
                <ImgSilkySmooth />
              </Img>
              <Title>
                Silky smooth
                <Paragraph>
                  Low-friction design allows for seamless movement without
                  generating heat.
                </Paragraph>
              </Title>
            </SubContent>
            <SubContent>
              <Img>
                <ImgEnhancedComfort />
              </Img>
              <Title>
                Enhanced comfort
                <Paragraph>
                  Quilted with a layer of organic cotton for plushier feel.
                </Paragraph>
              </Title>
            </SubContent>
          </Content>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default ProductPlusCover;
