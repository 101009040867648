import styled from 'styled-components';
import { Container as GridContainer, media } from 'styled-bootstrap-grid';
import { defaultContainerMaxWidth } from 'styled-bootstrap-grid/dist/components/ThemeProvider/ThemeProvider';

const Container = styled(GridContainer)`
  ${p =>
    !p.fluid &&
    media.xxl`
    max-width: ${
      ((!p.theme ||
        !p.theme.styledBootstrapGrid ||
        !p.theme.styledBootstrapGrid.getContainerMaxWidth) &&
        defaultContainerMaxWidth.xxl) ||
      p.theme.styledBootstrapGrid.getContainerMaxWidth('xxl')
    }px;
  `}
`;

export default Container;
