import React from 'react';

import ProductBenefitRowV1, {
  Props as DefaultPropsV1,
} from './product-benefit-row';
import ProductBenefitRowV2, {
  Props as DefaultPropsV2,
} from './product-benefit-row-v2';

type PropsV1 = {
  version: 'v1';
} & DefaultPropsV1;

type PropsV2 = {
  version: 'v2';
} & DefaultPropsV2;

type Props = PropsV1 | PropsV2;

const ProductBenefitRow: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <ProductBenefitRowV2 {...props} />;
    default:
      return <ProductBenefitRowV1 {...props} />;
  }
};

export default ProductBenefitRow;
