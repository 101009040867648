import { State, Action } from './checkout-context-types';
import { CheckoutEnum } from './checkout-context-config';
import { saveAddressToLocalStorage } from './checkout-context-utils';

export const checkoutReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        activeIndex: 0,
        steps: [...(action.steps || [])],
      };
    case 'SET_ACTIVE_INDEX': {
      const { steps } = state;
      const { activeIndex } = action;

      if (activeIndex !== undefined && steps !== undefined) {
        for (let i = activeIndex; i >= 0; i--) {
          steps[i].disabled = false;
        }
        return { ...state, activeIndex, steps: [...steps] };
      } else {
        return state;
      }
    }
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.isLoading };
    case 'RESET': {
      const { steps } = state;
      localStorage.removeItem(CheckoutEnum.ADDRESS);

      if (steps) {
        steps?.forEach(step => (step.disabled = true));
        return { ...state, steps: [...steps], activeIndex: 0 };
      } else {
        return state;
      }
    }
    case 'UPDATE_DELIVERY': {
      const { schedule } = action;
      if (!schedule) return state;
      return { ...state, schedule };
    }
    case 'UPDATE_DELIVERY_OPTION': {
      const { deliveryOption } = action;
      if (!deliveryOption) return state;
      return { ...state, deliveryOption };
    }
    case 'TOGGLE_SAME_ADDRESS': {
      saveAddressToLocalStorage(
        action.sameAddress,
        state.shipping,
        state.billing
      );
      return { ...state, sameAddress: action.sameAddress };
    }
    case 'UPDATE_ADDRESS': {
      saveAddressToLocalStorage(
        state.sameAddress,
        action.shipping,
        action.billing
      );
      return {
        ...state,
        shipping: action.shipping,
        billing: action.billing,
        populateAddressForm: action.populateAddressForm,
      };
    }
    case 'UPDATE_SHIPPING_ADDRESS': {
      saveAddressToLocalStorage(
        state.sameAddress,
        action.shipping,
        state.billing
      );
      return { ...state, shipping: action.shipping };
    }
    case 'UPDATE_BILLING_ADDRESS': {
      saveAddressToLocalStorage(
        state.sameAddress,
        state.shipping,
        action.billing
      );
      return { ...state, billing: action.billing };
    }
    case 'SET_ORDER':
      return { ...state, order: action.order };
    case 'CLEAR_ADDRESS':
      localStorage.removeItem(CheckoutEnum.ADDRESS);
      return { ...state, shipping: undefined, billing: undefined };
    default:
      return state;
  }
};
