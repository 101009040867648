import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';

import SvgEmail from '../../static/images/support-email-icon.svg';
import SvgPhone from '../../static/images/support-phone-icon.svg';
import SvgLocation from '../../static/images/support-location-icon.svg';
import SvgChevronRight from '../../static/images/chevron-right.svg';
import SvgExternalLink from '../../static/images/external-link-icon.svg';
import { rem, flatButtonStyle } from '../../styles/utils';
import { Config } from '../../styles/enums';
import {
  Container,
  ReviewStars,
  BlobAnimation,
  CloseButton,
  FormInput,
  Logo,
  FormUpload,
} from '../common';
import { ContactLinks, SiteUrls } from '../../utils/constants';
import {
  BgSupportReview,
  BgSupportRedemption,
  BgSupportGift1,
  BgSupportGift2,
  BgSupportGift3,
  ImgSupportGift,
} from '../assets';
import { sendRedeemFreeGiftEmail } from '../../utils/api';

const Wrapper = styled.div`
  position: relative;

  ${media.lg`
    min-height: 1032px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const Header = styled.header`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const LogoLink = styled(Logo)``;

const Section = styled.section`
  color: black;
  font-family: var(--font-body);
  font-size: ${rem(14)};
  font-weight: normal;
  height: auto;
  letter-spacing: -0.01em;
  line-height: ${rem(18)};
  width: 100%;
  position: relative;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const Headline1 = styled.h1`
  color: black;
  font-family: var(--font-header);
  font-size: ${rem(38)};
  font-weight: normal;
  letter-spacing: -0.01em;
  line-height: 1;

  ${media.lg`
    font-size: ${rem(60)};
  `}
`;

const Headline2 = styled.h2`
  color: black;
  font-family: var(--font-header);
  font-size: ${rem(32)};
  font-weight: normal;
  letter-spacing: -0.01em;
  line-height: 1.14;
  margin-bottom: 20px;

  ${media.lg`
    font-size: ${rem(42)};
  `}
`;

const Headline3 = styled.h3`
  color: black;
  font-family: var(--font-header);
  font-size: ${rem(24)};
  font-weight: normal;
  letter-spacing: -0.01em;
  line-height: 1.16;
  margin-bottom: 12px;

  ${media.lg`
    font-size: ${rem(30)};
  `}
`;

const contactIconStyle = css`
  margin-right: 8px;
  height: 18px;
  width: 18px;
  flex-shrink: 0;
`;

const ContactRow = styled.div`
  display: flex;
`;

const ContactCol = styled.div`
  width: 50%;

  &:first-of-type {
    margin-right: 10px;
  }
`;

const ContactIconTile = styled.div`
  display: flex;
  margin-bottom: 14px;
`;

const AnchorLink = styled.a`
  text-decoration: none;
  color: black;
  line-height: 1.375;
`;

const Button = styled.button`
  ${flatButtonStyle}
  font-size: ${rem(14)};
  line-height: 1.14;
  box-shadow: 2px 2px 4px rgba(119, 149, 174, 0.3);

  ${media.md`
    font-size: ${rem(16)};
  `}
`;

const SvgEmailIcon = styled(SvgEmail)`
  ${contactIconStyle}
`;
const SvgPhoneIcon = styled(SvgPhone)`
  ${contactIconStyle}
`;
const SvgLocationIcon = styled(SvgLocation)`
  ${contactIconStyle}
`;

const Blob1 = styled.div`
  position: absolute;
  width: 300px;
  height: 350px;
  right: -50%;
  top: 20%;
  z-index: 0;

  ${media.md`
    right: -20%;
    top: -10%;
    width: 490px;
    height: 523px;
  `}

  ${media.lg`
    top: 0;
  `}

  ${media.xl`
    width: 40%;
    height: auto;
    right: -20%;
    top: -5%;
  `}
`;

const Blob2 = styled.div`
  position: absolute;
  width: 500px;
  height: 550px;
  left: -70%;
  top: 30%;
  z-index: 0;

  ${media.md`
    left: -60%;
    top: 40%;
    width: 815px;
    height: 510px;
  `}

  ${media.lg`
    left: -40%;
  `}

  ${media.xl`
    left: -15%;
    top: 40%;
    width: 50%;
    height: 80%;
    overflow: hidden;
  `}
`;

const ContactSection: React.FC = () => {
  return (
    <Section>
      <Headline1>Thank you for your support!</Headline1>
      <ContactRow>
        <ContactCol>
          <ContactIconTile>
            <SvgEmailIcon />
            <AnchorLink
              href={ContactLinks.Email}
              target="_blank"
              rel="noreferrer"
            >
              support@woosa.sg
            </AnchorLink>
          </ContactIconTile>
          <ContactIconTile>
            <SvgPhoneIcon />
            <AnchorLink href={ContactLinks.Phone}>9848 5822</AnchorLink>
          </ContactIconTile>
        </ContactCol>
        <ContactCol>
          <ContactIconTile>
            <SvgLocationIcon />
            <AnchorLink
              href={ContactLinks.Location}
              target="_blank"
              rel="noreferrer"
            >
              1 Tampines North Drive 1, #01-01
              <br />
              T-SPACE
              <br />
              Singapore 528559
            </AnchorLink>
          </ContactIconTile>
        </ContactCol>
      </ContactRow>
    </Section>
  );
};

const SocialList = styled.ul`
  padding: 48px 0;
  margin: 0 0 20px;
  list-style: none;
`;

const SocialListTile = styled.li`
  border-top: 1px solid var(--celeste);

  &:last-child {
    border-bottom: 1px solid var(--celeste);
  }
`;

const SocialListTileLink = styled(AnchorLink)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;
`;

const SocialListTileIcon = styled(SvgChevronRight)``;

const SocialHandlerText = styled.strong`
  color: var(--bali-hai);
  font-weight: normal;
  margin-left: 12px;
`;

const SocialSection: React.FC = () => {
  return (
    <Section>
      <SocialList>
        <SocialListTile>
          <SocialListTileLink href={ContactLinks.Whatsapp} target="_blank">
            Whatsapp Support
            <SocialListTileIcon />
          </SocialListTileLink>
        </SocialListTile>
        <SocialListTile>
          <SocialListTileLink href={ContactLinks.Facebook} target="_blank">
            <span>
              Like our Facebook
              <SocialHandlerText>fb.com/woosasg</SocialHandlerText>
            </span>
            <SocialListTileIcon />
          </SocialListTileLink>
        </SocialListTile>
        <SocialListTile>
          <SocialListTileLink href={ContactLinks.Instagram} target="_blank">
            <span>
              Like our Instagram
              <SocialHandlerText>@woosasg</SocialHandlerText>
            </span>
            <SocialListTileIcon />
          </SocialListTileLink>
        </SocialListTile>
        <SocialListTile>
          <SocialListTileLink
            href="https://www.youtube.com/watch?v=TppT2ydqAew"
            target="_blank"
          >
            How To Assemble The Woosa Adjustable Base II
            <SocialListTileIcon />
          </SocialListTileLink>
        </SocialListTile>
      </SocialList>
    </Section>
  );
};

const RedemptionReviewEyebrow = styled.strong`
  position: absolute;
  top: 40px;
  font-size: ${rem(12)};
  line-height: 1.6;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--big-stone);
  font-weight: 700;

  &:after {
    content: ' ';
    background-color: var(--big-stone);
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -6px;
    left: 0;
  }
`;

const RedemptionReviewBody = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 50%;

  ${media.md`
    width: 100%;
  `}
`;

const RedemptionReviewHeadline = styled.h2`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  line-height: 1.16;
  letter-spacing: -0.01em;
  margin: 0 0 16px;
  font-weight: normal;

  ${media.md`
    font-size: ${rem(30)};
    padding: 0 24px;
  `}
`;

const RedemptionReviewBg = styled(BgSupportReview)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;

  ${media.md`
    box-shadow: 15px 15px 0px -1px rgba(230,236,241,1);
    -webkit-box-shadow: 15px 15px 0px -1px rgba(230,236,241,1);
    -moz-box-shadow: 15px 15px 0px -1px rgba(230,236,241,1);
    margin-bottom: 40px;
  `}

  ${media.xl`
    min-height: 650px;
  `}
`;

const RedemptionCtaBg = styled(BgSupportRedemption)`
  min-height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
  margin-bottom: 10px;

  ${media.md`
    box-shadow: 15px 15px 0px -1px rgba(219, 210, 201, 1);
    -webkit-box-shadow: 15px 15px 0px -1px rgba(219, 210, 201, 1);
    -moz-box-shadow: 15px 15px 0px -1px rgba(219, 210, 201, 1);
    margin-bottom: 40px;
  `}

  ${media.lg`
    min-height: 120px;
  `}
`;

const RedemptionCtaBgWrapper = styled.button`
  width: 100%;
  background: none;
  border: 0;
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  letter-spacing: -0.01em;
  line-height: 1.16;
  color: black;

  ${media.lg`
    font-size: ${rem(30)};
    line-height: 1;
  `}
`;

const RedemptionReviewStars = styled(ReviewStars)`
  width: auto;
  margin: 0 auto;
`;

const RedemptionReviewButton = styled(Button)`
  width: 80%;
  margin: 0 auto;

  ${media.md`
    width: 50%;
  `}
`;

const RedemptionSection: React.FC<{
  onClickInfoButton?: () => void;
  onClickRedeemButton?: () => void;
}> = ({ onClickInfoButton, onClickRedeemButton }) => {
  return (
    <Section>
      <RedemptionReviewBg>
        <RedemptionReviewEyebrow>Loving our products?</RedemptionReviewEyebrow>
        <RedemptionReviewBody>
          <RedemptionReviewStars rating={5} isYellow hideText width="20px" />
          <RedemptionReviewHeadline>
            Leave a review and&nbsp;receive&nbsp;a&nbsp;free&nbsp;gift!
          </RedemptionReviewHeadline>
          <RedemptionReviewButton onClick={onClickInfoButton}>
            See more details
          </RedemptionReviewButton>
        </RedemptionReviewBody>
      </RedemptionReviewBg>
      {/* <RedemptionCtaBgWrapper onClick={onClickRedeemButton}>
        <RedemptionCtaBg>Redeem gift</RedemptionCtaBg>
      </RedemptionCtaBgWrapper> */}
    </Section>
  );
};

const ModalSection = styled(Section)<{ isActive?: boolean }>`
  background-color: var(--mystic);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  transition: transform 0.5s ease-out;
  transform: ${({ isActive }) =>
    isActive ? 'translateZ(0)' : 'translate3d(0, -100%, 0);'};

  ${media.md`
    width: 50vw;
    left: auto;
    right: 0;
    transform: ${({ isActive }) =>
      isActive ? 'translateZ(0)' : 'translate3d(100%, 0, 0);'};

  `}

  ${media.xl`
    width: 44vw
  `}

  ${media.xxl`
    width: 45vw
  `}
`;

const ModalScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const ModalContainer = styled(Container)`
  ${media.lg`
    max-width: 416px;
    padding: 0;
    margin: 0 auto;
  `}
`;

const ModalCloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 30px 0 0;
  margin-bottom: 26px;
`;

const ModalCloseButton = styled(CloseButton)``;

const modalSubtitleStyle = css`
  color: var(--bali-hai);
  font-size: ${rem(14)};
  line-height: 1.14;
  letter-spacing: -0.01em;
  display: inline-block;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const ModalSubtitle = styled.i`
  ${modalSubtitleStyle}
  margin-bottom: 12px;
`;

const ModalStepList = styled.ul`
  margin: 24px 0 20px;
  padding: 0;
  list-style: none;
  counter-reset: step;
`;

const ModalStepListItem = styled.li`
  font-size: ${rem(14)};
  line-height: 1.14;
  letter-spacing: -0.01em;
  color: var(--big-stone);
  margin-bottom: 10px;
  display: flex;

  &:last {
    margin-bottom: 0;
  }

  &:before {
    counter-increment: step;
    content: 'Step ' counter(step) ':';
    margin-right: 22px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 600;
    width: 45px;
  }

  ${media.lg`
    font-size: ${rem(16)};

    &:before {
      width: 55px;
    }
  `}
`;

const ModalTermsCondition = styled.div`
  font-size: ${rem(12)};
  line-height: 1.33;
  letter-spacing: -0.01em;
  color: var(--gull-gray);
  margin-top: 50px;
  border-top: 1px solid var(--celeste);
  padding-top: 12px;
  margin-bottom: 100px;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const ModalTermsList = styled.ul`
  margin: 5px 0 0;
  padding: 0;
  list-style: none;
`;

const ModalTermsListItem = styled.li`
  display: flex;
  margin-bottom: 5px;

  &:last {
    margin-bottom: 0;
  }

  &:before {
    content: '+';
    margin-right: 16px;
  }
`;

const ModalFreeGiftContainer = styled.div`
  display: flex;
  margin-bottom: 9px;
`;

const modalFreeGiftBgStyle = css`
  width: 50%;
  min-height: 205px;
  postiion: relative;

  span {
    padding: 15px 5px;
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
  }

  ${media.md`
    min-height: 278px;
  `}
`;

const ModalFreeGiftEyebrow = styled.strong`
  color: #97856d;
  font-weight: normal;
  font-size: ${rem(10)};
  line-height: 1.14;
  letter-spacing: -0.01em;
  margin: 0;

  ${media.lg`
    font-size: ${rem(12)};
  `}
`;

const ModalFreeGiftCaption = styled.h5`
  font-weight: normal;
  color: #4f423c;
  font-size: ${rem(14)};
  line-height: 1.14;
  margin: 2px 0 0;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const ModalFreeGift1 = styled(BgSupportGift1)`
  ${modalFreeGiftBgStyle}
`;

const ModalFreeGift2 = styled(BgSupportGift2)`
  ${modalFreeGiftBgStyle}
  justify-content: flex-end;
`;

const ModelFreeGift3 = styled(BgSupportGift3)`
  ${modalFreeGiftBgStyle}
  width: 100%;
`;

const ImgModalFreeGift = styled(ImgSupportGift)`
  width: 100%;
  max-width: 225px;
`;

const ModalLink = styled.a`
  color: var(--bali-hai);
  text-decoration: none;
  position: relative;

  svg {
    position: absolute;
    top: -3px;
    right: -18px;
  }
`;

const InfoModal: React.FC<{
  onClickClose?: () => void;
  onClickSubmitDetails?: () => void;
  isActive: boolean;
  giftVersion?: 'v1' | 'v2';
}> = ({ onClickClose, onClickSubmitDetails, isActive, giftVersion = 'v1' }) => {
  return (
    <ModalSection isActive={isActive} className="support-review-info-modal">
      <ModalScrollContainer>
        <ModalCloseButtonContainer>
          <ModalCloseButton onClick={onClickClose} />
        </ModalCloseButtonContainer>
        <ModalContainer>
          <Headline3>
            Review your favourite Woosa product and receive a complimentary gift
            from us!
          </Headline3>
          <ModalSubtitle>
            Every review will be tagged to your order number.
          </ModalSubtitle>
          <ModalSubtitle>Gift: $10 Grab Voucher</ModalSubtitle>
          <ModalFreeGiftContainer>
            {giftVersion === 'v1' && (
              <>
                <ModalFreeGift1>
                  <span>
                    <ModalFreeGiftEyebrow>
                      For orders above $1000
                    </ModalFreeGiftEyebrow>
                    <ModalFreeGiftCaption>
                      Receive a Woosa Signature&nbsp;Candle
                    </ModalFreeGiftCaption>
                    <ModalFreeGiftEyebrow>(Worth $26.90)</ModalFreeGiftEyebrow>
                  </span>
                </ModalFreeGift1>
                <ModalFreeGift2>
                  <span>
                    <ModalFreeGiftEyebrow>
                      For orders less than $1000
                    </ModalFreeGiftEyebrow>
                    <ModalFreeGiftCaption>
                      Receive a<br />
                      NTUC&nbsp;voucher
                    </ModalFreeGiftCaption>
                    <ModalFreeGiftEyebrow>(Worth $10)</ModalFreeGiftEyebrow>
                  </span>
                </ModalFreeGift2>
              </>
            )}
            {giftVersion === 'v2' && <ImgModalFreeGift />}
          </ModalFreeGiftContainer>
          <ModalStepList>
            <ModalStepListItem>
              <span>
                Leave a review on{' '}
                <ModalLink
                  href={ContactLinks.Google}
                  target="_blank"
                  rel="noreferrer"
                >
                  Google <SvgExternalLink />
                </ModalLink>
              </span>
            </ModalStepListItem>
            {/* <ModalStepListItem>
              <span>
                Leave a review on{' '}
                <ModalLink
                  href={ContactLinks.Facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook <SvgExternalLink />
                </ModalLink>
              </span>
            </ModalStepListItem> */}
            <ModalStepListItem>
              Leave us a 5-star review with at least 1 photo and take a
              screenshot.
            </ModalStepListItem>
            <ModalStepListItem>
              Submit your details with the screenshot, and you&apos;ll receive
              your digital Grab Voucher in 14 working days.
            </ModalStepListItem>
          </ModalStepList>
          <Button onClick={onClickSubmitDetails}>Submit my details</Button>
          <ModalTermsCondition>
            <b>Terms and Conditions </b>
            <ModalTermsList>
              <ModalTermsListItem>
                Reviews will be matched with your order.
              </ModalTermsListItem>
              <ModalTermsListItem>
                Only 1 free gift entitled per order.
              </ModalTermsListItem>
              <ModalTermsListItem>
                Free gifts will be delivered within 14 days from review
                submission.
              </ModalTermsListItem>
              <ModalTermsListItem>
                Gifts with Purchases are chargeable in the event of a return
                under the Woosa Sleep Trial policy.
              </ModalTermsListItem>
              <ModalTermsListItem>
                Woosa Sleep Pte Ltd reserves the right to refuse/reject your
                free gift claim based on our sole discretion.
              </ModalTermsListItem>
              <ModalTermsListItem>
                Free gifts subject to availability and may change according to
                season.
              </ModalTermsListItem>
            </ModalTermsList>
          </ModalTermsCondition>
        </ModalContainer>
      </ModalScrollContainer>
    </ModalSection>
  );
};

const Form = styled.form``;

const FormLabelContainer = styled.div`
  ${media.lg`
    display: flex;
    align-items: center;
  `}
`;

const FormLabel = styled.label`
  font-size: ${rem(16)};
  line-height: 1.375;
  font-weight: normal;
  letter-spacing: -0.01em;

  ${media.lg`
    margin-right: 9px;
  `}
`;

const Input = styled(FormInput)`
  margin-bottom: 24px;

  &.no-mb {
    margin-bottom: 4px;

    ${media.lg`
      margin-bottom: 8px;
    `}
  }
`;

const FormSubtitle = styled.i`
  ${modalSubtitleStyle}
  margin-bottom: 8px;

  ${media.lg`
    font-size: ${rem(14)};
    line-height: 1;
    margin-bottom: 0;
  `}
`;

const formMessage = css`
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
  font-size: 110%;
  margin-bottom: 20px;
`;

const FormSuccessMessage = styled.span`
  ${formMessage}
  color: var(--sirocco);
`;

const FormErrorMessage = styled.span`
  ${formMessage}
  color: red;
`;

const FormUploadContainer = styled.div`
  margin-bottom: 30px;
`;

type FormData = {
  orderNum: string;
  email: string;
  googleLink: string;
  facebookLink: string;
};

const RedeemModal: React.FC<{
  onClickClose?: () => void;
  isActive: boolean;
  enableReviewUpload?: boolean;
}> = ({ onClickClose, isActive, enableReviewUpload }) => {
  const { register, handleSubmit, errors, reset } = useForm<FormData>();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const hasErrors = () => Object.keys(errors).length > 0;

  const hasUploaded = () => {
    if (uploadedUrls.length < 2) {
      setErrorMessage(
        'Please upload screenshots of your Google & Facebook reviews.'
      );
      return false;
    }
    return true;
  };

  const onSubmit = async (data: FormData) => {
    setErrorMessage('');
    if (hasErrors() || loading || !hasUploaded()) return;

    setLoading(true);

    const { email, orderNum, googleLink, facebookLink } = data;

    await sendRedeemFreeGiftEmail(
      email,
      orderNum,
      googleLink,
      facebookLink,
      uploadedUrls
    );

    setMessage(
      'Your redemption has been submitted, if your email and order number matches, you should receive an email confirmation shortly.'
    );
    setLoading(false);
    reset();
  };

  return (
    <ModalSection isActive={isActive} className="support-redeem-form-modal">
      <ModalCloseButtonContainer>
        <ModalCloseButton onClick={onClickClose} />
      </ModalCloseButtonContainer>
      <ModalContainer>
        <Headline2>Redeem my gift</Headline2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {message && <FormSuccessMessage>{message}</FormSuccessMessage>}
          {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
          <FormLabelContainer>
            <FormLabel>Order number</FormLabel>
            <FormSubtitle>(This can be found on your invoice.)</FormSubtitle>
          </FormLabelContainer>
          <Input
            error={errors.orderNum}
            inputRef={register({
              required: 'Order number is required',
            })}
            inputProps={{
              placeholder: 'e.g. WS200418-0001',
              name: 'orderNum',
            }}
          />
          <FormLabelContainer>
            <FormLabel>Email address</FormLabel>
            <FormSubtitle>
              (Use the same email address as per your order)
            </FormSubtitle>
          </FormLabelContainer>
          <Input
            error={errors.email}
            inputRef={register({
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid email address',
              },
            })}
            inputProps={{
              placeholder: 'e.g. happycustomer@gmail.com',
              name: 'email',
            }}
          />
          {!enableReviewUpload && (
            <>
              <FormLabelContainer>
                <FormLabel>Links to reviews</FormLabel>
              </FormLabelContainer>
              <Input
                className="no-mb"
                error={errors.googleLink}
                inputRef={register({
                  required: 'Please enter the link to your Google review',
                })}
                inputProps={{
                  placeholder: 'Google Link',
                  name: 'googleLink',
                }}
              />
              <Input
                error={errors.facebookLink}
                inputRef={register({
                  required: 'Please enter the link to your Facebook review',
                })}
                inputProps={{
                  placeholder: 'Facebook link',
                  name: 'facebookLink',
                }}
              />
            </>
          )}
          {enableReviewUpload && (
            <>
              <FormLabelContainer>
                <FormLabel>
                  Please upload screenshots of your Google &amp; Facebook
                  reviews:
                </FormLabel>
              </FormLabelContainer>
              <FormUploadContainer>
                <FormUpload
                  folder="redeem"
                  onUploaded={urls => setUploadedUrls([...urls])}
                  onRemoved={urls => setUploadedUrls([...urls])}
                />
              </FormUploadContainer>
            </>
          )}
          <Button type="submit" disabled={loading || hasErrors()}>
            Submit
          </Button>
        </Form>
      </ModalContainer>
    </ModalSection>
  );
};

const MainContainer = styled.div`
  ${media.md`
    padding: 0 ${Config.CONTAINER_PADDING}px;
  `}

  ${media.lg`
    max-width: 844px;
    margin: 0 auto;
    padding: 0;
  `}

  ${media.xl`
    max-width: 1400px;
  `}
`;

const ContactSocialContainer = styled.div`
  padding: 0 ${Config.CONTAINER_PADDING}px;

  ${media.md`
    padding: 0;
  `}
`;

const SupportSection: React.FC = () => {
  const [showInfo, setShowInfo] = useState(false);
  const [showRedeem, setShowRedeem] = useState(false);

  return (
    <>
      <Header>
        <LogoLink to={SiteUrls.Home} />
      </Header>
      <Wrapper>
        <Blob1>
          <BlobAnimation color="#E6ECF1" />
        </Blob1>
        <Blob2>
          <BlobAnimation color="#F1F8F3" />
        </Blob2>
        <MainContainer>
          <Row>
            <Col md={6} xl={7}>
              <ContactSocialContainer>
                <ContactSection />
                <SocialSection />
              </ContactSocialContainer>
            </Col>
            <Col md={6} xlOffset={1} xl={4}>
              <RedemptionSection
                onClickInfoButton={() => setShowInfo(true)}
                onClickRedeemButton={() => setShowRedeem(true)}
              />
            </Col>
          </Row>
        </MainContainer>
      </Wrapper>
      <InfoModal
        isActive={showInfo}
        onClickClose={() => setShowInfo(false)}
        onClickSubmitDetails={() => {
          setShowRedeem(true);
          setShowInfo(false);
        }}
        giftVersion="v2"
      />
      <RedeemModal
        isActive={showRedeem}
        onClickClose={() => setShowRedeem(false)}
        enableReviewUpload
      />
    </>
  );
};

export default SupportSection;
