import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/gatsby';

import { ImgAtome } from '../assets';
import { createAtomePaymentIntent } from '../../utils/api';
import { rem } from '../../styles/utils';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AtomeLogo = styled(ImgAtome)`
  width: 120px;
  margin-right: 20px;
`;

const AtomeButtonLogo = styled(ImgAtome)`
  width: 80px;
  top: -3px;
`;

const AtomeButton = styled.button`
  border: 1px solid #f1ff60;
  background-color: #f1ff60;
  border-radius: 10px;
  padding: 5px 5px 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto 10px;
  font-size: ${rem(14)};
  justify-content: center;
  font-weight: 700;
  letter-spacing: 1.5px;
`;

type Props = {
  accessToken?: string;
  paymentToken: string | null;
  canPay: boolean;
  paymentAttempts?: number;
  onError?: (error: string) => void;
  isButton?: boolean;
  onProcessingChanged?: (processing: boolean) => void;
  viaPaymentLink?: boolean;
  className?: string;
};

const AtomeForm: React.FC<Props> = ({
  paymentToken,
  accessToken,
  canPay,
  paymentAttempts,
  onError,
  isButton,
  onProcessingChanged,
  viaPaymentLink,
  className,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const makePayment = async () => {
    if (isProcessing || !paymentToken) return;
    try {
      setIsProcessing(true);
      const redirectUrl = await createAtomePaymentIntent({
        accessToken,
        paymentToken,
        viaPaymentLink,
      });
      if (!redirectUrl) throw 'Unable to redirect to Atome for payment';
      window.requestAnimationFrame(() => (window.location.href = redirectUrl));
    } catch (error) {
      Sentry.captureException(error);
      if (typeof onError === 'function') {
        onError(error as string);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!canPay) return;
      await makePayment();
    })();
  }, [paymentToken, canPay, paymentAttempts]);

  useEffect(() => {
    if (onProcessingChanged) {
      onProcessingChanged(isProcessing);
    }
  }, [isProcessing]);

  if (isButton) {
    return (
      <AtomeButton
        className={className}
        onClick={async event => {
          event.preventDefault();
          await makePayment();
        }}
      >
        Pay with
        <AtomeButtonLogo />
      </AtomeButton>
    );
  }

  return (
    <Container className={className}>
      <AtomeLogo />
      <span>
        Atome - 3 monthly payments, 0% interest. Enjoy S$10 off your first
        order.
      </span>
    </Container>
  );
};

export default AtomeForm;
