import React from 'react';
import styled from 'styled-components';

import { ImgAdjustableBaseSmartApp } from '../assets';
import { Container as GridContainer } from '../common';
import SvgAutoFlat from '../../static/images/auto-flat.svg';
import SvgTimeToRise from '../../static/images/time-to-rise.svg';
import SvgFavouritePresets from '../../static/images/favourite-presets.svg';
import { lh, rem } from '../../styles/utils';
import { media } from 'styled-bootstrap-grid';

const Section = styled.section`
  position: relative;
  color: var(--font-primary-color);
  font-family: var(--font-body);
`;

const BackgroundImage = styled(ImgAdjustableBaseSmartApp)`
  width: 100%;
`;

const Container = styled(GridContainer)`
  max-width: 1450px;
`;

const Wrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 12px;

  ${media.lg`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center; 
    padding: 0;
  `}
`;

const Content = styled.div`
  ${media.md`
    display: flex;
    flex-wrap: wrap;
  `}

  ${media.lg`
    max-width: 500px;
    margin-left: auto;
    background-color: var(--desert-storm-light);
    padding: 25px 30px 0 30px;
  `}
  
  ${media.xl`
    padding: 35px 35px 0 35px;
  `}
`;

const SubContent = styled.div`
  margin-bottom: 20px;

  ${media.md`
    width: 50%;

    &:nth-child(even) {
      padding-left: 16px;
    }

    &:nth-child(odd) {
      padding-right: 16px;
    }

    &:first-child,
    &:nth-child(2) {
      width: 100%;
      padding: 0;
    }
  `}

  ${media.lg`
    margin-bottom: 10px;
  `}

  ${media.xl`
    margin-bottom: 20px;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  line-height: ${lh(32, 24)};
  font-weight: 400;
  margin: 0;

  ${media.lg`
    font-size: ${rem(30)};
    line-height: ${lh(38, 30)};
  `}

  ${media.xl`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};
  `}
`;

const Paragraph = styled.p`
  font-size: ${rem(14)};
  line-height: ${lh(18, 14)};
  font-weight: 400;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const Title = styled.h3`
  font-size: ${rem(14)};
  line-height: ${lh(18, 14)};
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const TitleIcon = styled.i`
  margin-right: 10px;
`;

type Props = {
  className?: string;
};

const AdjustableBaseSmartAppSection: React.FC<Props> = ({ className }) => {
  return (
    <Section className={className}>
      <BackgroundImage />
      <Wrapper>
        <Container>
          <Content>
            <SubContent>
              <Headline>A smarter way to sleep</Headline>
              <Paragraph>
                Increase the functionalities of your Adjustable Base with the
                new Woosa Sleep App.
              </Paragraph>
            </SubContent>
            <SubContent>
              <Title>
                <TitleIcon>
                  <SvgTimeToRise />
                </TitleIcon>
                Time-To-Rise
              </Title>
              <Paragraph>
                Experience a better way to wake up in the mornings. The
                adjustable bed base automatically vibrates gently and raises to
                your desired position at your specified time.
              </Paragraph>
            </SubContent>
            <SubContent>
              <Title>
                <TitleIcon>
                  <SvgFavouritePresets />
                </TitleIcon>
                Favourite Presets
              </Title>
              <Paragraph>
                Customise and save your favourite positions in bed, or set your
                under-bed lighting to switch off after a specified time.
              </Paragraph>
            </SubContent>
            <SubContent>
              <Title>
                <TitleIcon>
                  <SvgAutoFlat />
                </TitleIcon>
                Auto Flat
              </Title>
              <Paragraph>
                Want to fall asleep at the same time everyday? Set your
                adjustable bed to lower to flat automatically at your desired
                time.
              </Paragraph>
            </SubContent>
          </Content>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default AdjustableBaseSmartAppSection;
