import { Config, Breakpoints } from './enums';
import { css, FlattenInterpolation, ThemeProps } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const zIndexes = (selectors: string[]): string => {
  const builder = [];
  for (let i = 1; i <= selectors.length; ++i) {
    builder.push(`${selectors[i - 1]} { z-index: ${i + 1000}; }`);
  }
  return builder.join('');
};

export const rem = (pixel: number): string => {
  return `${pixel / Config.BASE_FONT_SIZE}rem`;
};

export const lh = (lineHeight: number, fontSize?: number): string => {
  return `${lineHeight / (fontSize || Config.BASE_FONT_SIZE)}`;
};

export const mediaQueryUpto = (to: Breakpoints, exp: string): string => {
  return `@media screen and (max-width: ${to}px) {
    ${exp}
  }`;
};

export const outlineButtonStyle = (
  theme: 'light' | 'dark'
): FlattenInterpolation<ThemeProps<unknown>> => {
  return css`
    display: inline-block;
    font-family: var(--font-body);
    font-size: ${rem(12)};
    font-weight: normal;
    text-decoration: none;
    background-color: transparent;
    color: ${theme === 'light' ? 'white' : 'black'};
    border: 1px solid ${theme === 'light' ? 'white' : 'black'};
    padding: 15px;
    border-radius: 5px;

    ${media.lg`
      font-size: ${rem(18)};
    `}
  `;
};

export const flatButtonStyle = (): FlattenInterpolation<
  ThemeProps<unknown>
> => {
  return css`
    display: inline-block;
    border: 0;
    background: var(--firefly);
    border-radius: 5px;
    border: 1px solid transparent;
    font-family: var(--font-body);
    font-size: ${rem(12)};
    color: white;
    padding: 15px;
    font-weight: normal;
    text-decoration: none;
    transition: background 0.3s linear, color 0.3s linear,
      border-color 0.3s linear;

    ${media.lg`
    font-size: ${rem(18)};
  `}

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      background: white;
      color: var(--firefly);
      border-color: var(--firefly);
    }
  `;
};

export const sectionMarginStyle = (): FlattenInterpolation<
  ThemeProps<unknown>
> => {
  return css`
    margin-bottom: 40px;

    ${media.md`
      margin-bottom: 60px;
    `}

    ${media.lg`
      margin-bottom: 80px;
    `}

    ${media.xl`
      margin-bottom: 100px;
    `}
  `;
};
