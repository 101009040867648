import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { gridTheme } from '../../styles/grid';

const PostContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${gridTheme.container.maxWidth.xs}px;
  padding: 0 ${gridTheme.container.padding}px;

  ${media.md`
    max-width: ${gridTheme.container.maxWidth.md}px;
  `}

  ${media.lg`  
    max-width: 864px;
    padding: 0;
  `}
`;

export default PostContainer;
