import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { get } from 'lodash';

import { ProductOrderItem, Tax } from '../../types/order';
import { Promo } from '../../types/promo';
import PriceLabel from '../common/price-label';
import { rem } from '../../styles/utils';

const Container = styled.div``;

const List = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
`;

const Item = styled.li<{ display?: string; hideBorder?: boolean }>`
  display: ${({ display }) => display || 'flex'};
  width: 100%;
  align-items: flex-start;
  padding: 20px 15px;
  border-bottom: ${({ hideBorder }) =>
    hideBorder ? '0' : '1px solid var(--border-color)'};

  ${media.md`
    padding: 20px;
  `}
`;

const InfoItem = styled(Item)`
  padding: 25px 15px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  font-size: ${rem(14)};
  font-family: var(--font-body);
  font-weight: normal;
  color: var(--font-cart-color);

  ${media.xl`
    font-size: ${rem(16)};
  `}
`;

const Price = styled(PriceLabel)`
  font-size: ${rem(14)};
  text-align: right;

  del {
    display: block;
  }

  ${media.xl`
    font-size: ${rem(16)};
  `}

  &.small {
    font-size: 80%;
  }
`;

const PromoList = styled.ul`
  margin: 0;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  display: flex;
`;

const PromoListItem = styled.li`
  margin-left: 5px;
  margin-bottom: 5px;

  &:first-child {
    margin-left: 0;
  }
`;

const promoBadgeAfter = css`
  &:after {
    content: 'x';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 80%;
    font-weight: normal;
    border-radius: 100%;
    background-color: white;
    color: var(--firefly);
    width: 10px;
    height: 10px;
  }
`;

const PromoBadge = styled.span<{ disable?: boolean }>`
  font-family: var(--font-body);
  background-color: var(--firefly);
  color: white;
  border: 0;
  border-radius: 5px;
  font-size: 80%;
  padding: 5px;
  font-weight: bold;
  position: relative;
  display: block;
  cursor: pointer;

  ${({ disable }) => !disable && 'padding-right: 20px;'}
  ${({ disable }) => !disable && promoBadgeAfter}
`;

const ReferralBadge = styled(PromoBadge)`
  margin-left: auto;
`;

type Props = {
  products: ProductOrderItem[];
  amounts: {
    totalAmount: number;
    amount: number;
    compareAmount: number;
    paidAmount?: number;
  };
  shippingFee?: number;
  discount?: number;
  disablePromo?: boolean;
  className?: string;
  promos?: Promo[];
  referralCode?: string;
  tax?: Tax;
  onClickRemoveReferralCode?: () => void;
  onClickRemovePromoCode?: (promo: Promo) => void;
};

const OrderCartSummary: React.FC<Props> = ({
  className,
  products,
  amounts: { totalAmount, amount, compareAmount, paidAmount },
  shippingFee,
  discount,
  disablePromo,
  promos,
  referralCode,
  tax,
  onClickRemoveReferralCode,
  onClickRemovePromoCode,
}) => {
  const currency = get(products[0], 'currency', 'SGD');

  const getProductDiscounts = (): number => {
    return products.reduce(
      (productDiscount, p) => (p.totalDiscount || 0) + productDiscount,
      0
    );
  };

  const getTotalDiscount = (): number => {
    if (!discount) return 0;
    if (discount === 0) return 0;

    const totalDiscount = discount - getProductDiscounts();
    return totalDiscount < 0 ? 0 : totalDiscount;
  };

  const getSubtotal = () => {
    const subtotal = {
      amount,
      compareAmount,
      currency,
    };

    subtotal.amount -= getProductDiscounts();
    return subtotal;
  };

  return (
    <Container className={className}>
      <List>
        <InfoItem display="block">
          <Wrapper>
            <Label>Subtotal:</Label>
            <Price defaultColor="var(--sirocco)" price={getSubtotal()} />
          </Wrapper>
          <Wrapper>
            <Label>Shipping:</Label>
            {shippingFee && shippingFee > 0 ? (
              <Price
                defaultColor="var(--sirocco)"
                price={{
                  amount: shippingFee,
                  compareAmount: 0,
                  currency,
                }}
              />
            ) : (
              <Price defaultColor="var(--sirocco)">Free</Price>
            )}
          </Wrapper>
          {(discount || (promos && promos.length > 0)) && (
            <Wrapper>
              <Label>
                Promo:
                {promos && (
                  <PromoList>
                    {promos.map(promo => (
                      <PromoListItem key={promo.code}>
                        <PromoBadge
                          disable={disablePromo}
                          onClick={() => {
                            if (disablePromo) return;
                            if (typeof onClickRemovePromoCode !== 'function')
                              return;
                            onClickRemovePromoCode(promo);
                          }}
                        >
                          {promo.code}
                        </PromoBadge>
                      </PromoListItem>
                    ))}
                  </PromoList>
                )}
              </Label>
              {getTotalDiscount() > 0 && (
                <Price
                  defaultColor="var(--sirocco)"
                  price={{
                    amount: getTotalDiscount(),
                    compareAmount: 0,
                    currency,
                  }}
                />
              )}
            </Wrapper>
          )}
          {referralCode && (
            <Wrapper>
              <Label>Referral:</Label>
              <ReferralBadge onClick={onClickRemoveReferralCode}>
                {referralCode}
              </ReferralBadge>
            </Wrapper>
          )}
        </InfoItem>
        <InfoItem hideBorder={!paidAmount} display="block">
          <Wrapper>
            <Label className="total-amount-label">Total</Label>
            <Price
              className="total-amount"
              defaultColor="var(--sirocco)"
              price={{
                amount: totalAmount,
                currency,
              }}
            />
          </Wrapper>
          {tax && (
            <Wrapper>
              <Label>
                <small>GST ({(tax.rate * 100).toFixed(0)}%) Inclusive</small>
              </Label>
              <Price
                className="small"
                defaultColor="var(--sirocco)"
                price={{
                  amount: tax.amount,
                  currency: currency,
                }}
              />
            </Wrapper>
          )}
        </InfoItem>
        {paidAmount !== null && paidAmount !== undefined && paidAmount > 0 && (
          <InfoItem display="block" hideBorder={true}>
            <Wrapper>
              <Label>Amount Paid</Label>
              <Price
                defaultColor="var(--sirocco)"
                price={{
                  amount: paidAmount,
                  currency,
                }}
              />
            </Wrapper>
            <Wrapper>
              <Label>Balance remaining</Label>
              <Price
                defaultColor="var(--sirocco)"
                price={{
                  amount: totalAmount - paidAmount,
                  currency,
                }}
              />
            </Wrapper>
          </InfoItem>
        )}
      </List>
    </Container>
  );
};

export default OrderCartSummary;
