import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

type Props = {
  color?: string;
};

const BlobAnimation: React.FC<Props> = ({ color }) => {
  color = color || 'white';

  return (
    <Container>
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={color}
          d="M35.1,-62.8C42.8,-56.4,44.5,-41.5,49,-29.7C53.4,-17.8,60.5,-8.9,66.5,3.5C72.6,15.9,77.6,31.8,73.5,44.3C69.5,56.9,56.6,66.2,42.8,68C29.1,69.8,14.5,64.2,1.9,61C-10.8,57.7,-21.6,56.9,-33.6,54.1C-45.7,51.3,-59,46.5,-67.2,37.2C-75.3,27.8,-78.3,13.9,-79.8,-0.9C-81.2,-15.6,-81.2,-31.2,-72.1,-38.9C-62.9,-46.5,-44.6,-46.1,-31.1,-49.2C-17.6,-52.2,-8.8,-58.8,2.5,-63.1C13.7,-67.3,27.4,-69.2,35.1,-62.8Z"
          transform="translate(100 100)"
        >
          <animate
            repeatCount="indefinite"
            fill={color}
            attributeName="d"
            dur="10s"
            values="
              M35.1,-62.8C42.8,-56.4,44.5,-41.5,49,-29.7C53.4,-17.8,60.5,-8.9,66.5,3.5C72.6,15.9,77.6,31.8,73.5,44.3C69.5,56.9,56.6,66.2,42.8,68C29.1,69.8,14.5,64.2,1.9,61C-10.8,57.7,-21.6,56.9,-33.6,54.1C-45.7,51.3,-59,46.5,-67.2,37.2C-75.3,27.8,-78.3,13.9,-79.8,-0.9C-81.2,-15.6,-81.2,-31.2,-72.1,-38.9C-62.9,-46.5,-44.6,-46.1,-31.1,-49.2C-17.6,-52.2,-8.8,-58.8,2.5,-63.1C13.7,-67.3,27.4,-69.2,35.1,-62.8Z;
              M23.7,-45.3C31.2,-36.7,38.1,-31.3,47.1,-24.3C56.2,-17.3,67.3,-8.7,66.3,-0.6C65.3,7.5,52.2,15,43.1,22C34.1,29,29.1,35.4,22.6,43.4C16.1,51.3,8.1,60.8,-1.3,63.1C-10.7,65.4,-21.4,60.5,-32.7,55.3C-43.9,50.1,-55.8,44.6,-65.4,35.3C-75,26,-82.3,13,-83.8,-0.8C-85.2,-14.7,-80.8,-29.4,-69.2,-35.2C-57.7,-41.1,-39,-38.2,-26.3,-43.8C-13.5,-49.4,-6.8,-63.6,0.7,-64.7C8.1,-65.9,16.2,-53.9,23.7,-45.3Z;
              M36,-61.1C44.5,-57.4,47.8,-43.4,57.1,-31.6C66.5,-19.7,81.8,-9.8,81.8,0C81.8,9.8,66.3,19.6,57.2,31.8C48,44,45.2,58.7,36.7,61.4C28.2,64.1,14.1,54.7,2.2,50.9C-9.6,47,-19.3,48.5,-32,48.3C-44.8,48.1,-60.7,46.2,-65,37.8C-69.4,29.5,-62.2,14.7,-59.9,1.3C-57.6,-12.1,-60.3,-24.3,-55,-30.9C-49.7,-37.6,-36.4,-38.7,-25.9,-41.3C-15.3,-43.9,-7.7,-47.8,3,-53.1C13.7,-58.3,27.5,-64.8,36,-61.1Z;
              M35.1,-62.8C42.8,-56.4,44.5,-41.5,49,-29.7C53.4,-17.8,60.5,-8.9,66.5,3.5C72.6,15.9,77.6,31.8,73.5,44.3C69.5,56.9,56.6,66.2,42.8,68C29.1,69.8,14.5,64.2,1.9,61C-10.8,57.7,-21.6,56.9,-33.6,54.1C-45.7,51.3,-59,46.5,-67.2,37.2C-75.3,27.8,-78.3,13.9,-79.8,-0.9C-81.2,-15.6,-81.2,-31.2,-72.1,-38.9C-62.9,-46.5,-44.6,-46.1,-31.1,-49.2C-17.6,-52.2,-8.8,-58.8,2.5,-63.1C13.7,-67.3,27.4,-69.2,35.1,-62.8Z;
            "
          ></animate>
        </path>
      </svg>
    </Container>
  );
};

export default BlobAnimation;
