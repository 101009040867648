import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { ImgLogo } from '../assets/index';
import Link from './link';

const LogoIcon = styled(ImgLogo)`
  width: 80px;

  ${media.lg`
    width: 110px;
  `}
`;

type Props = {
  className?: string;
  to?: string;
};

const Logo: React.FC<Props> = ({ to, className }) => {
  return (
    <Link className={className} to={to || '/'}>
      <LogoIcon />
    </Link>
  );
};

export default Logo;
