import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Modal from './custom-modal';
import { rem, lh } from '../../styles/utils';

const Container = styled.div`
  h3 {
    font-family: var(--font-header);
    color: var(--font-primary-color);
    font-weight: 400;
    font-size: ${rem(24)};
    line-height: ${lh(32, 24)};
    margin-bottom: 12px;
    text-align: center;
  }

  p {
    color: var(--font-secondary-color);
    font-family: var(--font-body);
    font-size: ${rem(14)};
    line-height: ${lh(24, 14)};
  }
`;

type Params = {
  className?: string;
  id: string;
  children: React.ReactNode | React.ReactNodeArray;
  maxRecurringViews?: number;
  expireDate?: Date;
};

const CustomPopup: React.FC<Params> = ({
  id,
  className,
  children,
  maxRecurringViews,
  expireDate,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const newMaxRecurringViews = maxRecurringViews || Number.MAX_VALUE;
    const newexpireDate = moment(expireDate) || moment().add(2, 'y');

    const currentRecurringViews = parseInt(localStorage.getItem(id) || '0');
    const newIsActive =
      currentRecurringViews < newMaxRecurringViews &&
      moment().isBefore(newexpireDate);

    setIsActive(newIsActive);
    if (!newIsActive) return;
    localStorage.setItem(id, (currentRecurringViews + 1).toString());
  }, []);

  return (
    <Modal
      className={`custom-popup ${className}`}
      isActive={isActive}
      onClickClose={() => setIsActive(false)}
      backdrop="allowClose"
    >
      <Container>{children}</Container>
    </Modal>
  );
};

export default CustomPopup;
