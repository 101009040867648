// * https://github.com/stripe-samples/accept-a-payment/blob/main/custom-payment-flow/client/react-cra/src/GrabPay.js

import React, { useState, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import * as Sentry from '@sentry/gatsby';
import { WEB_HOST } from 'gatsby-env-variables';
import {
  createPaymentIntent,
  createPaymentIntentByPaymentToken,
  logEvent,
} from '../../utils/api';
import SvgGrabPayMark from '../../static/images/grabpay-acceptance-mark.svg';
import SvgGrabPayButton from '../../static/images/grabpay-button.svg';
import { PaymentSubType } from '../../types/order';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  justify-content: center;
  width: 100%;
`;

const GrabPayButton = styled(SvgGrabPayButton)`
  width: 100%;
`;

const GrabPayMark = styled(SvgGrabPayMark)`
  height: 30px;
  margin-right: 10px;
  margin-left: 10px;
`;

type Props = {
  onError?: (error: string) => void;
  onProcessingChanged?: (isProcessing: boolean) => void;
  afterPayment?: (result: boolean, data: unknown | null) => void;
  paymentToken: string | null;
  accessToken?: string;
  orderId?: string;
  canPay: boolean;
  paymentAttempts?: number;
  isButton?: boolean;
  className?: string;
  returnUrlParams?: {
    nextPath?: string; // the next internal path to redirect to after return url
  };
  paymentSubType: PaymentSubType;
};

const GrabPayForm: React.FC<Props> = ({
  afterPayment,
  onProcessingChanged,
  onError,
  paymentToken,
  accessToken,
  orderId,
  canPay,
  paymentAttempts,
  isButton,
  className,
  returnUrlParams,
  paymentSubType,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const generateReturnUrl = () => {
    const builder = [`${WEB_HOST}/grabpay?paymentToken=${paymentToken}`];

    if (returnUrlParams) {
      Object.entries(returnUrlParams).forEach(([key, value]) => {
        if (!key || !value) return;
        builder.push(`${key}=${value}`);
      });
    }

    return builder.join('&');
  };

  const makePayment = async () => {
    if (isProcessing || !stripe || !elements || !paymentToken) return;
    try {
      setIsProcessing(true);
      const clientSecret = await (accessToken
        ? createPaymentIntent(accessToken, paymentToken, paymentSubType)
        : createPaymentIntentByPaymentToken(paymentToken, paymentSubType));
      if (!clientSecret) throw 'Invalid clientSecret';
      const payload = await stripe.confirmGrabPayPayment(clientSecret, {
        return_url: generateReturnUrl(),
      });
      if (payload.paymentIntent) {
        if (typeof afterPayment === 'function') {
          afterPayment(true, payload.paymentIntent);
        }
      } else {
        const defaultErrorMessage =
          'Payment unsuccessful, unexpected error occurred, please try again.';
        const error = payload.error
          ? payload.error.message || defaultErrorMessage
          : defaultErrorMessage;

        logEvent('error', error, { orderId, stripe: payload });
        throw error;
      }
    } catch (error) {
      Sentry.captureException(error);
      if (typeof onError === 'function') {
        onError(error as string);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!canPay) return;
      await makePayment();
    })();
  }, [paymentToken, canPay, paymentAttempts]);

  useEffect(() => {
    if (onProcessingChanged) {
      onProcessingChanged(isProcessing);
    }
  }, [isProcessing]);

  if (isButton) {
    return (
      <ButtonContainer
        className={className}
        onClick={async event => {
          event.preventDefault();
          await makePayment();
        }}
      >
        <GrabPayButton />
      </ButtonContainer>
    );
  }

  return (
    <Container className={className}>
      <GrabPayMark />
      <span>GrabPay</span>
    </Container>
  );
};

export default GrabPayForm;
