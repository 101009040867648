import React from 'react';

import { HeroIntro } from '../common';
import { BgAboutHero } from '../assets';

const AboutHeroSection: React.FC = () => {
  return (
    <HeroIntro
      BackgroundImage={BgAboutHero}
      headline={<>Good sleep is a wonderful&nbsp;thing</>}
      body={
        <>
          We just wanted a really comfortable mattress that wouldn&apos;t burn a
          hole in our wallets, and ended making it ourselves. The more we learnt
          about the industry, the more we wanted to change it, and share the
          incredible experience we got from sleeping on a phenomenal mattress.
          We&apos;re now dreaming bigger, to shape the way you think about
          mattress, sleep, and your shopping experience.
        </>
      }
    />
  );
};

export default AboutHeroSection;
