import React from 'react';
import styled from 'styled-components';

import { rem } from '../../styles/utils';
import Link from './link';

const Container = styled.div`
  border-radius: 24px;
  background-color: var(--nile-blue);
  color: white;
  font-family: var(--font-body);
  padding: 6px 15px 3px;
  font-size: ${rem(13)};
  line-height: 1.1;
  text-align: center;
`;

const Anchor = styled.a`
  display: block;
`;

type Props = {
  text: string;
  url?: string;
  className?: string;
};

const Tag: React.FC<Props> = ({ text, url, className }) => {
  const content = <Container className={className}>{text}</Container>;

  if (url) {
    if (url.includes('http') || url.includes('https')) {
      return (
        <Anchor href={url} target="_blank" rel="noreferrer">
          {content}
        </Anchor>
      );
    } else {
      return <Link to={url}>{content}</Link>;
    }
  } else {
    return content;
  }
};

export default Tag;
