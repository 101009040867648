import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Container } from '../common';
import { rem, sectionMarginStyle } from '../../styles/utils';
import { ImgBedFrameIntro } from '../assets';

const Section = styled.section`
  width: 100%;
  ${sectionMarginStyle}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.md`
    flex-direction: column;
  `}
`;

const Info = styled.div`
  text-align: center;

  ${media.md`
    margin-bottom: 40px;
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  color: var(--font-primary-color);
  font-size: ${rem(24)};
  font-weight: normal;

  ${media.md`
    margin-top: 0;
  `}

  ${media.lg`
    font-size: ${rem(38)};
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const Body = styled.p`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-size: ${rem(12)};
  line-height: 1.3;
  font-weight: normal;

  ${media.md`
    max-width: 645px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const Img = styled(ImgBedFrameIntro)``;

const BedFrameIntroSection: React.FC = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Info>
            <Headline>Comfort you never knew&nbsp;existed.</Headline>
            <Body>
              The Woosa Adjustable Bed base is the perfect companion to the
              Woosa Mattress, allowing you to customize your position for an
              incredible night’s sleep, or lounge just the way you like for
              those&nbsp;Netflix&nbsp;nights.
            </Body>
          </Info>
          <Img />
        </Wrapper>
      </Container>
    </Section>
  );
};

export default BedFrameIntroSection;
