import React from 'react';

import ProductBenefitSectionV1, {
  Props as DefaultPropsV1,
} from './product-benefit-section';
import ProductBenefitSectionV2, {
  Props as DefaultPropsV2,
} from './product-benefit-section-v2';

type PropsV1 = {
  version: 'v1';
} & DefaultPropsV1;

type PropsV2 = {
  version: 'v2';
} & DefaultPropsV2;

type Props = PropsV1 | PropsV2;

const ProductBenefitSection: React.FC<Props> = props => {
  switch (props.version) {
    case 'v2':
      return <ProductBenefitSectionV2 {...props} />;
    default:
      return <ProductBenefitSectionV1 {...props} />;
  }
};

export default ProductBenefitSection;
