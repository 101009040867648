/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { pick } from 'lodash';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { STRIPE_KEY, GST_SG_RATE } from 'gatsby-env-variables';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { ProductOrderItem, Tax } from '../types/order';
import {
  ContactLinks,
  CountryCode,
  GlobalSiteUrls,
  SiteUrls,
} from './constants';
import { FirebaseTimestamp } from '../types/firebase';

export const currencyFormat = (num: number, currency = '$'): string => {
  return currency + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const getYoutubeThumb = (
  videoId: string,
  resolution: 'default' | 'maxresdefault' = 'default'
): string => `https://img.youtube.com/vi/${videoId}/${resolution}.jpg`;

export const getYoutubeId = (url: string): string => {
  const split = url.split('/');
  return split[split.length - 1];
};

export const sizeMap = (
  size: number,
  callback: (key: number) => JSX.Element
): JSX.Element[] => {
  const elements: JSX.Element[] = [];

  for (let i = 0; i < size; ++i) {
    elements.push(callback(i));
  }

  return elements;
};

export const stripTags = (str: string | null): string => {
  if (!str) return '';
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const loadStripePromise = (): Promise<Stripe | null> => {
  return loadStripe(STRIPE_KEY || '');
};

export const deepClone = (data: any): any => {
  return JSON.parse(JSON.stringify({ data })).data;
};

export const isNullOrUndefined = (data: any): boolean => {
  return data === null || data === undefined || data === '';
};

export const generateSlots = (start: number, end: number) => {
  const slots: string[] = [];
  let from = start;

  for (let i = start + 1; i <= end; i++) {
    const suffix = from === 11 ? 'am' : 'pm';
    let to = i;

    if (from > 12) {
      from -= 12;
    }
    if (to > 12) {
      to -= 12;
    }

    slots.push(`${from}:00${suffix} - ${to}:00pm`);
    from = i;
  }
  return slots;
};

export const unslugify = (value: string) => {
  if (!value) return value;
  return value.replace(/ /g, '+');
};

export const slugify = (value: string) => {
  if (!value) return value;
  return value
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const isSlug = (slugValue: string | string[], normalValue: string) => {
  const slug = slugify(normalValue);

  if (Array.isArray(slugValue)) {
    return slugValue.includes(slug);
  }

  return slugValue === slug;
};

export const isObjectEqual = (
  a: Record<string, unknown>,
  b: Record<string, unknown>,
  props: string[]
): boolean => {
  if (!a || !b) return false;
  const aData = pick(a, props);
  const bData = pick(b, props);
  const aKeys = Object.keys(aData);
  const bKeys = Object.keys(bData);
  if (aKeys.length !== bKeys.length) return false;
  return aKeys.reduce<boolean>((equal, key) => {
    if (!equal) return false;
    return aData[key] === bData[key];
  }, true);
};

export const isArrayObjectEqual = (
  a: Record<string, unknown>[] | undefined | null,
  b: Record<string, unknown>[] | undefined | null,
  props: string[]
): boolean => {
  if (!a || !b) return false;
  const results = a.reduce<boolean[]>((acc, aItem, i) => {
    acc.push(isObjectEqual(aItem, b[i], props));
    return acc;
  }, []);
  return results.every(r => r);
};

export const getDefaultMinDeliveryDate = (): Date => {
  const cutOffTime = moment().set('hour', 17).set('minute', 0).set('second', 0);
  const isAfterCutOffTime = moment().isSameOrAfter(cutOffTime);
  return moment()
    .add(isAfterCutOffTime ? 2 : 1, 'd')
    .startOf('day')
    .toDate();
};

export const generateProductRefKey = (product: ProductOrderItem): string => {
  const builder: string[] = [product.id, product.variant];
  if (product.subVariants) {
    builder.push(...product.subVariants.map(sv => sv.value));
  }
  return builder.join('-');
};

export const phoneContactLinksToFriendlyValue = () => {
  return ContactLinks.Phone.replace('tel:', '').replace(
    /\D*(\d{2})\D*(\d{4})\D*(\d{4})\D*/,
    '+$1 $2 $3'
  );
};

export const toMoment = (
  value: FirebaseTimestamp | Date | string | undefined
): Moment => {
  if (typeof value === 'string' || value instanceof Date) {
    return moment.utc(value).local();
  } else if (value === undefined) {
    return moment.utc().local();
  } else {
    return moment.unix(value._seconds);
  }
};

/**
 * Backward tax calculation from total amount
 */
export const calculateTax = (
  totalAmount: number,
  option?: { rate: number }
): Tax => {
  const rate = option?.rate || GST_SG_RATE;
  const amount = totalAmount - totalAmount / (1 + rate);
  return { amount, rate };
};

export const toSiteUrl = (
  url: SiteUrls,
  countryCode = CountryCode.UNDEFINED
) => {
  if (countryCode === CountryCode.UNDEFINED) return url;
  return `/${countryCode}${url}`;
};

export const renderStatus = (status: string, statusDate: FirebaseTimestamp) => {
  if (status === 'Pre-Order') {
    if (statusDate && statusDate._seconds) {
      return `PRE-ORDER: Earliest shipment ${moment
        .unix(statusDate._seconds)
        .format('Do MMM YYYY')}`;
    } else {
      return 'Pre-order now!';
    }
  } else {
    return status;
  }
};

export const isSimpleSubVariant = (
  subVariantKeys: string[] | null | undefined
) => subVariantKeys && subVariantKeys.length === 1;

export const navigateWithCountryCode = (props: {
  countryCode: CountryCode;
  to: string;
}): string => {
  const { to, countryCode } = props;
  const isGlobalSiteUrl = GlobalSiteUrls.some(url => url === to);
  return isGlobalSiteUrl ? to : toSiteUrl(to as SiteUrls, countryCode);
};
