import React from 'react';
import styled from 'styled-components';
import {
  ENABLE_MATTRESS_COMPARISON_PAGE,
  PRODUCT_ORIGINAL_MATTRESS_ID,
  PRODUCT_PLUS_COVER_ID,
} from 'gatsby-env-variables';

import { ProductSection } from '../common';
import Mattress1 from '../../static/videos/original-mattress-1.mp4';
import Mattress2 from '../../assets/original-mattress-2.png';
import Mattress2WebP from '../../assets/original-mattress-2.webp';
import Mattress3 from '../../assets/original-mattress-3.png';
import Mattress3WebP from '../../assets/original-mattress-3.webp';
import Mattress4 from '../../assets/original-mattress-4.png';
import Mattress4WebP from '../../assets/original-mattress-4.webp';
import Mattress5 from '../../assets/original-mattress-5.png';
import Mattress5WebP from '../../assets/original-mattress-5.webp';
import Mattress6 from '../../static/videos/original-plus-cover.mp4';
import Mattress7 from '../../assets/plus-cover-asset.png';
import Mattress7Webp from '../../assets/plus-cover-asset.webp';

import useGraphQLGetProduct from '../../hooks/use-graphql-get-product';
import { SiteUrls } from '../../utils/constants';
import { BreadcrumbLink } from '../common/breadcrumb';

const Section = styled(ProductSection)`
  background-color: var(--spring-wood);

  &.plus-cover-background {
    background-color: var(--blue-gray);
  }
`;
type Props = {
  onChangeIsPlusCover?: (isChecked: boolean) => void;
  isPlusCover?: boolean;
};

const OriginalMattressProductSection: React.FC<Props> = ({
  onChangeIsPlusCover,
  isPlusCover,
}) => {
  const product = useGraphQLGetProduct(PRODUCT_ORIGINAL_MATTRESS_ID);
  const plusCover = useGraphQLGetProduct(PRODUCT_PLUS_COVER_ID);
  const breadcrumbs: BreadcrumbLink[] = [
    {
      urlPath: SiteUrls.ComparisonMattress,
      label: 'Mattresses',
    },
    { label: 'The Original Mattress' },
  ];

  if (!ENABLE_MATTRESS_COMPARISON_PAGE) {
    breadcrumbs[0].urlPath = undefined;
  }

  return (
    <Section
      className={isPlusCover ? 'plus-cover-background' : ''}
      version="v2"
      {...product}
      breadcrumbs={breadcrumbs}
      plusCover={plusCover}
      setIsPlusCover={onChangeIsPlusCover}
      isPlusCover={isPlusCover}
      assets={[
        {
          src: Mattress1,
          type: 'video',
        },
        {
          src: Mattress2,
          webp: Mattress2WebP,
          type: 'image',
        },
        {
          src: Mattress3,
          webp: Mattress3WebP,
          type: 'image',
        },
        {
          src: Mattress4,
          webp: Mattress4WebP,
          type: 'image',
        },
        {
          src: Mattress5,
          webp: Mattress5WebP,
          type: 'image',
        },
        {
          src: Mattress6,
          type: 'video',
          isPlusCover: true,
        },
        {
          src: Mattress7,
          webp: Mattress7Webp,
          type: 'image',
          isPlusCover: true,
        },
      ]}
    >
      <p>
        For medium-firm comfort. Made with our signature 4-layer combination for
        a mattress that is both comfortable and supportive.
      </p>
      <ul>
        <li>
          Premium Sonocore® Latex and European-made Memory Foam provides
          pressure relief & support
        </li>
        <li>
          Perfectly balanced, adaptive and responsive for incredible sleep all
          night
        </li>
        <li>Recommended for Back or Side Sleepers</li>
        <li>Made in Belgium for superior comfort and durability</li>
      </ul>
    </Section>
  );
};

export default OriginalMattressProductSection;
