import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Carousel, InstagramCard, Container } from '../common';
import { rem, sectionMarginStyle } from '../../styles/utils';
import { InstagramCardGrid } from '../../types/component';

const Section = styled.section`
  width: 100%;
  ${sectionMarginStyle}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin: 0;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;

  ${media.lg`
    font-size: ${rem(38)};
    margin-bottom: 40px;
  `}

  ${media.xl`
    font-size: ${rem(42)};
  `}
`;

const CardCarousel = styled(Carousel)`
  display: none;

  ${media.xl`
    display: block;
  `}

  .carousel {
    padding: 0;
  }
`;

const TabletCardCarousel = styled(CardCarousel)`
  display: none;

  ${media.md`
    display: block;
  `}

  ${media.xl`
    display: none;
  `}
`;

const MobileCardCarousel = styled(CardCarousel)`
  display: block;

  ${media.md`
    display: none;
  `}
`;

const CardCarouselSlide = styled.div`
  padding: 0 15px;
`;

type Props = {
  cards: InstagramCardGrid[];
  className?: string;
};

const InstagramGridSection: React.FC<Props> = ({ cards, className }) => {
  const renderCarousel = (
    Component: React.FC<{ props: unknown }>,
    centerSlidePercentage: number
  ) => {
    return (
      <Component
        props={{
          showStatus: false,
          showThumbs: false,
          showIndicators: false,
          centerMode: true,
          selectedItem: 1,
          centerSlidePercentage,
          autoPlay: true,
          infiniteLoop: true,
          stopOnHover: true,
        }}
      >
        {cards.map((card, key) => (
          <CardCarouselSlide key={key}>
            <InstagramCard {...card} />
          </CardCarouselSlide>
        ))}
      </Component>
    );
  };

  return (
    <Section className={className}>
      <Container>
        <Headline>Meet our happy sleepers.</Headline>
      </Container>
      {renderCarousel(MobileCardCarousel, 80)}
      {renderCarousel(TabletCardCarousel, 50)}
      {renderCarousel(CardCarousel, 30)}
    </Section>
  );
};

export default InstagramGridSection;
