import React from 'react';

import { IconGridSection } from '../common';
import { IconGrid } from '../../types/component';
import SvgAbout1 from '../../static/images/about-icon-1.svg';
import SvgAbout2 from '../../static/images/about-icon-2.svg';
import SvgAbout3 from '../../static/images/about-icon-3.svg';

const data: IconGrid[] = [
  {
    icon: <SvgAbout1 />,
    title: 'Mattress donation program',
    body:
      "Through organizations like People's Association and Helping Hand, we have successfully donated all returned mattresses to families in need.",
  },
  {
    icon: <SvgAbout2 />,
    title: '5-Star Local Support ',
    body:
      "We pride ourselves in transparent business operations, and localized fuss free support 24/7 to assist in anything that's keeping you up.",
  },
  {
    icon: <SvgAbout3 />,
    title: 'The Woosa Sleep Trial and Warranty',
    body:
      'No regretful purchases, no fine print. We honor our return and warranty policies fully to ensure every purchase is stress-free, as it should be.',
  },
];

const AboutIconGridSection: React.FC = () => (
  <IconGridSection
    size={{ xs: 6, md: 4 }}
    headline="We're just here to help everyone sleep better"
    grid={data}
  />
);

export default AboutIconGridSection;
