import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import {
  ImgFeatureBedsheetEgyptian1,
  ImgFeatureBedsheetEgyptian2,
  ImgFeatureBedsheetEgyptian3,
} from '../assets';

const Section = styled(ProductBenefitSection)``;

const EgyptianCottonBeddingSetFeatureSection: React.FC = () => (
  <Section
    version="v2"
    headline="Features"
    benefits={[
      {
        img: <ImgFeatureBedsheetEgyptian1 />,
        eyebrow: 'LONG-LASTING COMFORT',
        headline: 'Gets Softer With Every Wash',
        body:
          "As you continue to care for and launder your bedsheets, the cotton fibers naturally break in, resulting in an increasingly luxurious and supple feel. With every wash, you'll discover a newfound level of comfort, allowing you to drift off into a world of blissful sleep for years to come.",
      },
      {
        img: <ImgFeatureBedsheetEgyptian2 />,
        eyebrow: 'CRISP AND CLEAN',
        headline: 'Easy Care and Maintenance',
        body:
          'Designed to be low maintenance, simply machine wash them in cold water and line-dry for easy and quick cleaning. The high-quality fabric remains wrinkle-resistant, ensuring your bedsheets always look fresh and inviting.',
      },
      {
        img: <ImgFeatureBedsheetEgyptian3 />,
        eyebrow: 'FOR EVERY ROOM',
        headline: 'Available in 5 Beautiful Shades',
        body:
          'Choose from Dusty Rose, Navy, Gray, Chalk or White to add a touch of elegance to your bedroom. Select the shade that best complements your decor and create a visually stunning bedroom oasis that reflects your unique taste.',
      },
    ]}
  />
);

export default EgyptianCottonBeddingSetFeatureSection;
