import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import WizardHeadline from './wizard-headline';
import { wizardButtonStyle } from './wizard-button';
import WizardContainer from './wizard-container';
import { rem } from '../../styles/utils';
import { DisplayPic } from '../common/';
import { useCheckoutDispatch } from '../../hooks/checkout-context';
import { verifyToken } from '../../utils/api';
import { useAuthState, useAuthDispatch } from '../../hooks/auth-context';
import { ForgetPasswordForm, LoginForm, GuestForm } from '../auth';
import { handleSocialRedirectSignIn } from '../../utils/auth';

const SignInForm = styled(LoginForm)`
  button[type='submit'] {
    ${wizardButtonStyle}
  }
`;

const SignUpForm = styled(GuestForm)`
  button[type='submit'] {
    ${wizardButtonStyle}
  }
`;

const ResetPasswordForm = styled(ForgetPasswordForm)`
  button[type='submit'] {
    ${wizardButtonStyle}
  }
`;

const SmallEyebrow = styled.span`
  color: var(--font-primary-color);
  font-size: ${rem(12)};
  display: block;
  margin-bottom: 5px;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const ButtonLink = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  text-decoration: underline;
`;

const Card = styled.div`
  border: 1px solid var(--border-color);
  padding: 20px 15px;
  border-radius: 10px;
  display: flex;
  margin-bottom: 10px;
`;

const CardBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Error = styled.span`
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: red;
  font-size: 80%;
`;

type Props = {
  className?: string;
  index?: number;
};

const AuthForm: React.FC<Props> = ({ className, index }) => {
  const { displayPic, email, accessToken } = useAuthState();
  const checkoutDispatch = useCheckoutDispatch();
  const authDispatch = useAuthDispatch();
  const [error, setError] = useState('');
  const [mode, setMode] = useState<
    'LOGIN' | 'REGISTER' | 'FORGET' | 'AUTHENTICATED'
  >(accessToken ? 'AUTHENTICATED' : 'REGISTER');

  const nextStep = () =>
    checkoutDispatch({
      type: 'SET_ACTIVE_INDEX',
      activeIndex: (index || 0) + 1,
    });

  const signOut = () => {
    authDispatch({ type: 'SIGN_OUT' });
    checkoutDispatch({ type: 'RESET' });
    setMode('REGISTER');
  };

  const setIsLoading = (isLoading: boolean) => {
    checkoutDispatch({ type: 'SET_IS_LOADING', isLoading });
  };

  useEffect(() => {
    if (!accessToken) {
      // Using redirect social login, handle on load after successful
      // sign in from provider
      (async () => {
        setIsLoading(true);
        const isRedirectSignIn = await handleSocialRedirectSignIn(authDispatch);
        setIsLoading(false);
        if (!isRedirectSignIn.result && isRedirectSignIn.message) {
          setError(isRedirectSignIn.message);
          return;
        }
        if (!isRedirectSignIn.result) return;
        nextStep();
        setMode('AUTHENTICATED');
      })();
    } else {
      // Check for accessToken validity if present
      (async () => {
        setIsLoading(true);
        const stillAuthenticated = await verifyToken(accessToken);
        if (!stillAuthenticated) {
          signOut();
        }
        setIsLoading(false);
      })();
    }
  }, []);

  // Keep track of accessToken changes
  useEffect(() => {
    if (accessToken && mode !== 'AUTHENTICATED') {
      setMode('AUTHENTICATED');
    } else if (!accessToken && mode === 'AUTHENTICATED') {
      setMode('LOGIN');
    }
  }, [accessToken]);

  return (
    <WizardContainer className={className}>
      {mode !== 'FORGET' && (
        <WizardHeadline data-num={(index || 0) + 1}>
          {mode === 'REGISTER' && 'Hi there! Checkout as Guest:'}
          {(mode === 'AUTHENTICATED' || mode === 'LOGIN') && 'Welcome Back!'}
        </WizardHeadline>
      )}
      {mode === 'LOGIN' && (
        <SignInForm
          submitText="Continue to Shipping"
          onClickRegister={() => setMode('REGISTER')}
          onClickForgetPassword={() => setMode('FORGET')}
          onBeforeRequest={() => setIsLoading(true)}
          onAfterRequest={result => {
            setIsLoading(false);
            if (!result) return;
            nextStep();
          }}
        />
      )}
      {mode === 'REGISTER' && (
        <SignUpForm
          submitText="Continue to Shipping"
          onClickLogin={() => setMode('LOGIN')}
          onBeforeRequest={() => setIsLoading(true)}
          onAfterRequest={result => {
            setIsLoading(false);
            if (!result) return;
            nextStep();
          }}
        />
      )}
      {mode === 'FORGET' && (
        <ResetPasswordForm
          onClickLogin={() => setMode('LOGIN')}
          onClickRegister={() => setMode('REGISTER')}
          onBeforeRequest={() => setIsLoading(true)}
          onAfterRequest={() => setIsLoading(false)}
        />
      )}
      {mode === 'AUTHENTICATED' && (
        <>
          <Card onClick={nextStep}>
            <DisplayPic src={displayPic} />
            <CardBody>
              <small>Continue as</small>
              <strong>{email}</strong>
            </CardBody>
          </Card>
          <SmallEyebrow>
            Or{' '}
            <ButtonLink type="button" onClick={signOut}>
              sign in with another account.
            </ButtonLink>
          </SmallEyebrow>
        </>
      )}
      {error && <Error>{error}</Error>}
    </WizardContainer>
  );
};

export default AuthForm;
