import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { lh, rem } from '../../../../styles/utils';
import ChevronLeftSVG from '../../../../static/images/mattress-layer-v2-chevron-left.svg';
import ChevronRightSVG from '../../../../static/images/mattress-layer-v2-chevron-right.svg';

const InfoWrapper = styled.div`
  margin-top: 20px;

  ${media.lg`
    margin-top: 48px;
  `}
`;

const InfoListWrapper = styled.div`
  margin-bottom: 16px;
  overflow-y: hidden;
  text-align: center;

  ${media.lg`
    text-align: left;
    margin-bottom: 24px;
  `}
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  transition: 0.5s ease;
`;

const InfoItem = styled.li``;

const InfoNavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lg`
    justify-content: flex-start;
  `}
`;

const InfoNavList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const InfoNavItem = styled.li`
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

const InfoNavButton = styled.button`
  background: none;
  border: 1px solid black;
  border-radius: 100%;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: black;
  font-size: ${rem(11)};
  line-height: ${lh(21, 11)};
  padding: 0;

  ${media.lg`
    font-size: ${rem(12)};
    line-height: ${lh(21, 12)};
  `}

  ${InfoNavItem}.active & {
    background: black;
    color: white;
  }
`;

const InfoContent = styled.div`
  transition: 1s ease;
  opacity: 0;

  ${InfoItem}.active & {
    opacity: 1;
  }
`;

const InfoTitle = styled.h4`
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: ${lh(18, 14)};
  color: var(--font-primary-color);
  margin: 0;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)}; 
  `}
`;

const InfoSubtitle = styled.h5`
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: ${lh(18, 14)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 4px;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)}; 
  `}
`;

const InfoDescription = styled.p`
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${lh(16.8, 14)};
  color: var(--xanadu);
  margin: 0;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)}; 
    max-width: 335px;
  `}
`;

const ChveronButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;

  &:first-child {
    margin-right: 20px;
  }

  &:last-child {
    margin-left: 20px;
  }
`;

const ChevronLeft = styled(ChevronLeftSVG)``;

const ChevronRight = styled(ChevronRightSVG)``;

export type Props = {
  className?: string;
  infos: {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    description: React.ReactNode;
  }[];
  currentIndex: number;
  onNavigate: (index: number) => number;
  plusCoverInfo?: {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    description: React.ReactNode;
  };
  currentIndexRef: React.MutableRefObject<number>;
};

const MattressLayerInfo: React.FC<Props> = ({
  className,
  infos,
  currentIndex,
  onNavigate,
  plusCoverInfo,
  currentIndexRef,
}) => {
  //const currenIndexRef = useRef(currentIndex);
  const infoInfoWrapperRef = useRef<HTMLDivElement>(null);

  const translateItems = () => {
    if (!infoInfoWrapperRef.current) return;

    const infoList = infoInfoWrapperRef.current.querySelector('.info-list');
    const items = infoInfoWrapperRef.current.querySelectorAll('.info-item');

    if (infoList && items && items.length) {
      let moveBy = 0;
      items.forEach((item, idx) => {
        if (idx >= currentIndexRef.current) return;
        moveBy += item.getBoundingClientRect().height;
      });
      infoList.setAttribute('style', `transform:translateY(-${moveBy}px)`);
    }
  };

  useEffect(() => {
    const resizeWrapper = () => {
      if (!infoInfoWrapperRef.current) return;

      const items = infoInfoWrapperRef.current.querySelectorAll('.info-item');
      let maxHeight = 0;

      items.forEach(item => {
        const { height } = item.getBoundingClientRect();
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      infoInfoWrapperRef.current.style.maxHeight = `${maxHeight}px`;
    };

    window.addEventListener('resize', resizeWrapper);
    window.addEventListener('resize', translateItems);
    resizeWrapper();

    return () => {
      window.removeEventListener('resize', resizeWrapper);
      window.removeEventListener('resize', translateItems);
    };
  }, [plusCoverInfo?.title]);

  useEffect(translateItems, [currentIndex]);

  const handleNavigate = (index: number) => {
    const newIndex = onNavigate(index);
    currentIndexRef.current = newIndex;
  };

  const isActiveClassName = (index: number) => {
    return currentIndex === index ? 'active' : '';
  };

  return (
    <InfoWrapper className={className}>
      <InfoListWrapper ref={infoInfoWrapperRef}>
        <InfoList className="info-list">
          {infos.map((info, idx) => (
            <InfoItem
              key={idx}
              className={`info-item ${isActiveClassName(idx)}`}
            >
              <InfoContent>
                <InfoTitle>
                  {idx === 0 ? 'C' : idx}.{' '}
                  {idx === 0 && plusCoverInfo != undefined
                    ? plusCoverInfo.title
                    : info.title}
                  :
                </InfoTitle>
                <InfoSubtitle>
                  {idx === 0 && plusCoverInfo != undefined
                    ? plusCoverInfo.subtitle
                    : info.subtitle}
                </InfoSubtitle>
                <InfoDescription>
                  {idx === 0 && plusCoverInfo != undefined
                    ? plusCoverInfo.description
                    : info.description}
                </InfoDescription>
              </InfoContent>
            </InfoItem>
          ))}
        </InfoList>
      </InfoListWrapper>
      <InfoNavWrapper>
        <ChveronButton onClick={() => handleNavigate(currentIndex - 1)}>
          <ChevronLeft />
        </ChveronButton>
        <InfoNavList>
          {infos.map((_, idx) => (
            <InfoNavItem key={idx} className={isActiveClassName(idx)}>
              <InfoNavButton onClick={() => handleNavigate(idx)}>
                {idx === 0 ? 'C' : idx}
              </InfoNavButton>
            </InfoNavItem>
          ))}
        </InfoNavList>
        <ChveronButton onClick={() => handleNavigate(currentIndex + 1)}>
          <ChevronRight />
        </ChveronButton>
      </InfoNavWrapper>
    </InfoWrapper>
  );
};

export default MattressLayerInfo;
