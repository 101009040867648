import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';

import VideoSrc from '../../static/videos/home-adjustable-base-2.mp4';
import {
  ImgAdjustableBase2Customisable,
  ImgAdjustableBase2Improved,
} from '../assets';
import {
  Video as VideoLink,
  Container,
  Link,
  Unorphan,
  Callout,
} from '../common';
import { SiteUrls } from '../../utils/constants';
import { flatButtonStyle, lh, rem } from '../../styles/utils';

const GridRow = styled(Row)`
  padding: 0 15px;
  margin-top: -4px;
`;

const Section = styled.section``;

const Header = styled.div`
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
  width: auto;
  align-items: center;

  ${media.lg`
    top: 80px;
    left: 40px;
  `}
`;

const Headline = styled.h2`
  font-weight: 400;
  font-family: var(--font-header);
  font-size: ${rem(24)};
  line-height: ${lh(32, 24)};
  color: white;
  margin: 0 0 5px 0;

  ${media.lg`
    font-size: ${rem(42)};
    line-height: ${lh(42, 42)};
    margin: 0 0 20px 0;
  `}
`;

const Eyebrow = css`
  font-weight: 700;
  font-family: var(--font-body);
  text-transform: uppercase;
  font-size: ${rem(12)};
  line-height: ${lh(21, 16)};
  margin-bottom: 7px;
  display: block;

  ${media.lg`
  margin-bottom: 12px;
  font-size: ${rem(16)};
`}
`;

const EyebrowHero = styled.b`
  ${Eyebrow}
  color: white;
`;

const EyebrowDetail = styled.b`
  ${Eyebrow}
  color: var(--santa-fe);
`;

const Paragraph = styled.p`
  font-weight: 400;
  font-family: var(--font-body);
  font-size: ${rem(14)};
  line-height: ${lh(21, 16)};
  color: var(--xanadu);
  margin: 0px;
  display: block;

  ${media.lg`
  font-size: ${rem(16)};
  `}
`;

const VideoContainer = styled(Container)`
  ${media.xs`
    padding: 0;
    width: 100%;
  `}
`;

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const Video = styled(VideoLink)`
  scale: 2.7;
  padding: 30% 50px 30% 0;

  ${media.md`
    scale: 1;
    padding: 0;
  `}
`;

const Button = styled(Link)`
  ${flatButtonStyle()}
  padding: 12px 32px;

  ${media.lg`
    padding: 15px 45px;
  `}
`;

const InfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-65%);
`;

const ContentWrapperImproved = styled.div`
  padding: 20px 30px 0 30px;
  height: 100%;
  background: var(--blue-gray);

  ${media.md`
    padding: 40px 50px 0 50px;
  `}
  ${media.lg`
    padding: 40px 50px 0 70px;
  `}
`;

const ContentWrapperCustomisable = styled.div`
  padding: 20px 0 0 30px;
  height: 100%;
  background: linear-gradient(180deg, #e2e3e8 0%, #d4d7de 100%);

  ${media.md`
  padding: 40px 0 0 50px;
  `}
  ${media.lg`
  padding: 40px 0 0 80px;
  `}
`;

const HomeUpsellAdjustableBase2Section: React.FC = () => (
  <Section>
    <VideoContainer>
      <VideoWrapper>
        <Header>
          <EyebrowHero>
            <Unorphan>The Brand New Adjustable Base II</Unorphan>
          </EyebrowHero>
          <Headline>
            <Unorphan>Elevate Your Sleep Game</Unorphan>
          </Headline>
          <Button to={SiteUrls.AdjustableBase2}>Shop now</Button>
        </Header>
        <Video src={VideoSrc} />
        <Callout text="Quad Rhythmic Massage" className="position-1" />
        <Callout text="Extra lumbar support" className="position-2" />
        <Callout text="USB-C Charging" className="position-3" />
        <Callout text="Enhanced Mood Lighting" className="position-4" />
      </VideoWrapper>

      <GridRow>
        <Col noGutter lg={6}>
          <ContentWrapperImproved>
            <Row>
              <Col xs={6} sm={7}>
                <InfoWrapper>
                  <EyebrowDetail>Improved</EyebrowDetail>
                  <Paragraph>
                    <Unorphan>
                      Increase the functionalities of your Adjustable Base with
                      the new Woosa Sleep App.
                    </Unorphan>
                  </Paragraph>
                </InfoWrapper>
              </Col>
              <Col xs={6} sm={5}>
                <ImgAdjustableBase2Improved />
              </Col>
            </Row>
          </ContentWrapperImproved>
        </Col>
        <Col noGutter lg={6}>
          <ContentWrapperCustomisable>
            <GridRow>
              <Col noGutter xs={5} sm={6}>
                <InfoWrapper>
                  <EyebrowDetail>Fully customisable</EyebrowDetail>
                  <Paragraph>
                    <Unorphan>
                      Browse online and shop over 100 different bed frame
                      designs and colour combinations.
                    </Unorphan>
                  </Paragraph>
                </InfoWrapper>
              </Col>
              <Col noGutter xs={7} sm={6}>
                <ImgAdjustableBase2Customisable />
              </Col>
            </GridRow>
          </ContentWrapperCustomisable>
        </Col>
      </GridRow>
    </VideoContainer>
  </Section>
);

export default HomeUpsellAdjustableBase2Section;
