import React from 'react';

import { WarrantyIntro } from '../common';

const BedFrameWarrantyIntroSection: React.FC = () => {
  return (
    <WarrantyIntro headline="5 Year Limited Warranty">
      The Woosa Adjustable Bed’s electronics and mechanisms are designed and
      tested to work flawlessly night after night.
      <br />
      <br />
      In the unlikely event that your adjustable bed is met with any defects, we
      will replace or repair it without any charge to you. Your new Woosa
      Adjustable Bed is covered by a 5 Year Limited Warranty, dating from the
      time you purchased your new Adjustable Bed from Woosa Sleep Pte Ltd. The
      details of this warranty, and some limitations, are provided in the
      complete Warranty Description below.
    </WarrantyIntro>
  );
};

export default BedFrameWarrantyIntroSection;
