import React from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { flatButtonStyle, lh, rem } from '../../styles/utils';

import { SiteUrls } from '../../utils/constants';
// import { ImgHeroHomeV2 } from '../assets';
import { Unorphan, Link, Video } from '../common';
import HomeHeroVideo from '../../static/videos/home-hero-v2.mp4';

const Section = styled.section`
  position: relative;
  overflow: hidden;
  padding: 0;
`;

// const ImgHeroHome = styled(ImgHeroHomeV2)`
//   transform: scale(1.5);

//   ${media.md`
//     transform: scale(1);
//   `}
// `;

const Wrapper = styled.div`
  position: absolute;
  left: 5%;
  bottom: 10%;
  width: 55%;
  ${media.lg`
    width: auto;
  `}
`;

const Container = styled.div`
  position: relative;
`;

const Content = styled.div`
  text-align: left;
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(32)};
  line-height: ${lh(32, 32)};
  color: white;
  margin: 0 0 12px 0;

  ${media.lg`
    font-size: ${rem(42)};
    line-height: ${lh(42, 42)};
  `}
`;

const Paragraph = styled.h1`
  font-family: var(--font-body);
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${lh(18, 14)};
  color: white;
  margin: 0 0 12px 0;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)}; 
  `}
`;

const Button = styled(Link)`
  ${flatButtonStyle()}
  padding: 12px 32px;

  ${media.lg`
    padding: 15px 45px;
  `}
`;

const VideoBackground = styled(Video)`
  object-fit: cover;
  width: 130%;

  ${media.md`
    width: 100%;
  `}
`;

const HomeHeroSection: React.FC = () => {
  return (
    <Section>
      {/* <ImgHeroHome /> */}
      <VideoBackground src={HomeHeroVideo} />
      <Wrapper>
        <Container>
          <Content>
            <Headline>
              Sweet Dreams
              <br />
              Start Here
            </Headline>
            <Paragraph>
              <Unorphan>
                Buy Mattresses, Designed in Singapore & Made in Belgium For
                Incredible Comfort
              </Unorphan>
            </Paragraph>
            <Button to={SiteUrls.ComparisonMattress}>See all mattresses</Button>
          </Content>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default HomeHeroSection;
