import moment from 'moment';

import { Address } from '../../types/order';
import { CheckoutEnum } from './checkout-context-config';

export const saveAddressToLocalStorage = (
  sameAddress?: boolean,
  shipping?: Address,
  billing?: Address
): void => {
  localStorage.setItem(
    CheckoutEnum.ADDRESS,
    JSON.stringify({
      sameAddress,
      shipping,
      billing,
      timestamp: moment().add(24, 'hours').unix(),
    })
  );
};

export const getAddressFromLocalStorage = (): {
  sameAddress: boolean;
  shipping: Address;
  billing: Address;
} | null => {
  const data = localStorage.getItem(CheckoutEnum.ADDRESS);
  if (!data) return null;
  const parsedData = JSON.parse(data);

  if (!parsedData.timestamp) return null;

  if (moment.unix(parseInt(parsedData.timestamp)).isBefore(moment().unix())) {
    localStorage.removeItem(CheckoutEnum.ADDRESS);
    return null;
  }

  return parsedData;
};
