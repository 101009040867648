import moment from 'moment';
import { API_HOST, STRIPE_KEY, ENV } from 'gatsby-env-variables';
import * as Sentry from '@sentry/gatsby';

import { ProductApi, UserProduct, UserProductApi } from '../types/product';
import { DeliverySlot, DeliverySlotApi } from '../types/deliverySlot';
import { ReviewApi } from '../types/review';
import {
  OrderApi,
  Order,
  ProductOrderItem,
  Payment,
  Address,
  Schedule,
  PaymentSubType,
  ListUserOrderData,
  ListUserOrderApi,
} from '../types/order';
import { Promo, ValidatePromoApi, ProcessPromoApi } from '../types/promo';
import { isNullOrUndefined } from '../utils';
import { Appointment } from '../types/appointment';
import { Post } from '../types/post';
import { User, UserAddress } from '../types/user';

const host = API_HOST;

const guestEndpoint = (accessToken: string) => {
  const isGuest = isNullOrUndefined(accessToken);
  return isGuest ? 'public/' : '';
};

const formatPaidDate = (order: Order) => {
  if (!order.payments) return;
  order.payments.forEach(p => {
    if (!p.paidOn) return;
    p.paidDate = moment(p.paidOn._seconds).toDate();
  });
};

export const getProductById = async (id: string): Promise<ProductApi> => {
  try {
    const response = await fetch(`${host}/api/public/products/${id}`);
    const data: ProductApi = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { id: '', variants: [], media: [] };
  }
};

export const getDeliverySlots = async (
  from: Date,
  to: Date,
  accessToken: string
): Promise<DeliverySlot[]> => {
  const format = 'yyyy-MM-DD';
  const start = moment(from).format(format);
  const end = moment(to).format(format);

  const response = await fetch(
    `${host}/api/${guestEndpoint(
      accessToken
    )}delivery/slots/available?from=${start}&to=${end}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) throw response.status.toString();

  const {
    deliverySlots,
  }: { deliverySlots: DeliverySlotApi[] } = await response.json();

  const results: DeliverySlot[] = deliverySlots.map(slot => {
    return { ...slot, date: moment.unix(slot.date._seconds).toDate() };
  });

  return results;
};

export const userSignUp = async (
  accessToken: string,
  firstName: string,
  lastName: string,
  displayPic = ''
): Promise<void> => {
  try {
    await fetch(`${host}/api/auth/users/signup`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ firstName, lastName, displayPic }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const userWelcomeEmail = async (accessToken: string): Promise<void> => {
  try {
    await fetch(`${host}/api/auth/users/welcome-email`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ env: ENV }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const verifyToken = async (accessToken: string): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/auth/verify`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const createPaymentIntent = async (
  accessToken: string,
  paymentToken: string,
  paymentSubType: PaymentSubType
): Promise<string> => {
  try {
    const env = STRIPE_KEY.split('_')[1];

    const response = await fetch(`${host}/api/payments/create-payment-intent`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ paymentToken, env, paymentSubType }),
    });
    if (response.status !== 200) throw response;
    const { clientSecret } = await response.json();

    return clientSecret;
  } catch (error) {
    Sentry.captureException(error);
    return '';
  }
};

export const createReview = async (review: ReviewApi): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/public/reviews`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ review }),
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const createOrder = async (
  accessToken: string,
  orderData: OrderApi
): Promise<Order | null> => {
  try {
    orderData.order.userId = isNullOrUndefined(accessToken)
      ? 'Guest'
      : undefined;

    const response = await fetch(
      `${host}/api/${guestEndpoint(accessToken)}orders`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(orderData),
      }
    );

    if (!response.ok) throw response;

    const data: { order: Order } = await response.json();
    if (!data) throw new Error(data);

    delete data.order.audit;
    return data.order;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const updateOrder = async (
  accessToken: string,
  id: string,
  order: OrderApi
): Promise<Order | null> => {
  try {
    const response = await fetch(
      `${host}/api/${guestEndpoint(accessToken)}orders/${id}`,
      {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(order),
      }
    );

    if (!response.ok) throw response;

    const data: { order: Order } = await response.json();
    if (!data) throw new Error('Invalid response data');

    delete data.order.audit;
    return data.order;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const patchOrder = async (
  accessToken: string,
  orderId: string,
  payload: unknown,
  status: 'payment error' | 'processing',
  payment: Payment
): Promise<Order | null> => {
  try {
    const response = await fetch(
      `${host}/api/${guestEndpoint(accessToken)}orders/payments/${orderId}`,
      {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ payload, status, payment }),
      }
    );

    if (!response.ok) throw response;

    const data: { order: Order } = await response.json();
    if (!data) throw new Error(data);

    const { order } = data;
    formatPaidDate(order);

    delete order.audit;
    return order;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const sendOrderReceipt = async (
  accessToken: string,
  orderId: string
): Promise<void> => {
  try {
    const response = await fetch(
      `${host}/api/${guestEndpoint(accessToken)}orders/invoice/${orderId}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          env: ENV,
        }),
      }
    );

    if (!response.ok) throw response;
  } catch (error) {
    console.error(error);
  }
};

export const getOrderById = async (
  accessToken: string,
  id: string
): Promise<Order | null> => {
  try {
    const response = await fetch(`${host}/api/orders/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) throw response;

    const { order } = await response.json();

    return order;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const applyPromo = async (
  code: string,
  products: ProductOrderItem[],
  appliedPromos: string[]
): Promise<Promo | null> => {
  try {
    const response = await fetch(`${host}/api/public/promos/apply/${code}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order: { products, userId: 'guest' },
        appliedPromos,
      }),
    });

    if (!response.ok) throw response;

    const { promotion }: { promotion: Promo } = await response.json();

    // delete unwanted fields
    delete promotion['audit'];
    delete promotion['usageLimits'];
    delete promotion['startOn'];
    delete promotion['endsOn'];
    delete promotion['eligibility'];

    return promotion;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const processPromo = async (
  products: ProductOrderItem[],
  promoCodes: string[]
): Promise<ProcessPromoApi | null> => {
  try {
    const response = await fetch(`${host}/api/public/promos/process`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order: { products, userId: 'guest' },
        promoCodes,
      }),
    });

    if (!response.ok) throw response;

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getOrderByPaymentToken = async (
  paymentToken: string
): Promise<Order | null> => {
  try {
    const response = await fetch(`${host}/api/restricted/orders`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paymentToken }),
    });

    if (!response.ok) throw response;

    const { order } = await response.json();

    return order;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createPaymentIntentByPaymentToken = async (
  paymentToken: string,
  paymentSubType: PaymentSubType
): Promise<string> => {
  try {
    const env = STRIPE_KEY.split('_')[1];

    const response = await fetch(`${host}/api/restricted/payments/intent`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paymentToken, env, paymentSubType }),
    });
    if (!response.ok) throw response;
    const { clientSecret } = await response.json();

    return clientSecret;
  } catch (error) {
    Sentry.captureException(error);
    return '';
  }
};

export const patchOrderByPaymentToken = async (
  paymentToken: string,
  payload: unknown,
  status: 'payment error' | 'processing',
  type: 'stripe' | 'hoolan',
  subtype: 'payment link' | 'checkout',
  remarks: string
): Promise<Order | null> => {
  try {
    const response = await fetch(`${host}/api/restricted/orders/payments`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentToken,
        payload,
        status,
        type,
        remarks,
        subtype,
      }),
    });

    if (!response.ok) throw response;

    const data: { order: Order } = await response.json();
    if (!data) throw new Error(data);

    const { order } = data;
    formatPaidDate(order);

    delete order.audit;
    return order;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const sendOrderReceiptByPaymentToken = async (
  paymentToken: string
): Promise<void> => {
  try {
    const response = await fetch(`${host}/api/restricted/orders/invoice`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paymentToken, env: ENV }),
    });

    if (!response.ok) throw response;
  } catch (error) {
    console.error(error);
  }
};

export const isReferralCode = async (code: string): Promise<boolean> => {
  try {
    const response = await fetch(
      `${host}/api/public/referrals/valid/${encodeURI(code)}`
    );
    return response.ok;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const validatePromo = async (
  products: ProductOrderItem[],
  promoCodes: string[]
): Promise<ValidatePromoApi> => {
  try {
    const response = await fetch(`${host}/api/public/promos/validate`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order: { products },
        promoCodes,
      }),
    });

    if (!response.ok) throw response;

    const data: ValidatePromoApi = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return {
      validPromoCodes: [],
      invalidPromoCodes: [],
      data: {
        shippingFee: 0,
        totalDiscount: 0,
        promos: [],
      },
    };
  }
};

export const getAddressInfo = async (
  accessToken: string
): Promise<{
  sameAddress: boolean;
  shipping: Address;
  billing: Address;
} | null> => {
  try {
    const response = await fetch(`${host}/api/users/address`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) throw response;

    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const sendBookSlotEmail = async (
  name: string,
  email: string,
  contact: string,
  date: Date,
  time: string
): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/public/appointments/book-slot`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        contact,
        date: moment(date).unix(),
        time,
        env: ENV,
      }),
    });

    if (!response.ok) throw response;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const isUserGuest = async (email: string): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/public/auth/users/is_guest`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });

    if (!response.ok) throw response;

    const { isGuest }: { isGuest: boolean } = await response.json();

    return isGuest;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const addDeliveryScheduleByPaymentToken = async (
  paymentToken: string,
  schedule: Schedule
): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/restricted/orders/delivery`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paymentToken, schedule }),
    });

    if (!response.ok) throw response;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const logEvent = async (
  level: 'info' | 'error',
  message: string,
  payload: unknown
): Promise<void> => {
  try {
    await fetch(`${host}/api/public/weblog/log`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message, level, payload }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const sendRedeemFreeGiftEmail = async (
  email: string,
  refNum: string,
  googleLink: string,
  facebookLink: string,
  uploaded: string[]
): Promise<void> => {
  try {
    await fetch(`${host}/api/public/utility/redeem-free-gift`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        refNum,
        googleLink,
        facebookLink,
        uploaded,
        env: ENV,
      }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const uploadFile = async (
  file: File,
  folder: string
): Promise<{ url?: string; name?: string; error?: string }> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(
      `${host}/api/public/utility/upload/${folder}`,
      {
        method: 'post',
        body: formData,
      }
    );

    const { url, originalName, error } = await response.json();

    if (!response.ok || !url) {
      return { error };
    }

    return { url, name: originalName };
  } catch (err) {
    console.error(err);
    return { error: err as string };
  }
};

export const findAppointmentSlots = async (
  month: Date
): Promise<Appointment[]> => {
  try {
    const mDate = moment(month);
    const start = mDate.startOf('month').startOf('day').unix();
    const end = mDate.endOf('month').endOf('day').unix();

    const response = await fetch(
      `${host}/api/public/appointments/slots?start=${start}&end=${end}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const { appointments } = await response.json();

    return appointments;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createAtomePaymentIntent = async ({
  accessToken,
  paymentToken,
  viaPaymentLink,
}: {
  accessToken?: string;
  paymentToken: string;
  viaPaymentLink?: boolean;
}): Promise<string> => {
  try {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };
    let url = `${host}/api/restricted/payments/intent`;
    let paymentSubType: PaymentSubType = 'checkout';

    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
      url = `${host}/api/payments/create-payment-intent`;
    }

    if (viaPaymentLink) {
      url = `${host}/api/restricted/payments/atome-link-intent`;
      paymentSubType = 'payment link';
    }

    const response = await fetch(url, {
      method: 'post',
      headers,
      body: JSON.stringify({
        paymentToken,
        env: ENV,
        paymentType: 'atome',
        paymentSubType,
      }),
    });
    if (response.status !== 200) throw response;
    const { clientSecret: redirectUrl } = await response.json();

    return redirectUrl;
  } catch (error) {
    Sentry.captureException(error);
    return '';
  }
};

export const getPreviewBlogPost = async (
  token: string
): Promise<Post | null> => {
  try {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };

    const response = await fetch(`${host}/api/restricted/post/preview`, {
      method: 'post',
      headers,
      body: JSON.stringify({ token }),
    });
    if (response.status !== 200) throw response;
    const postData: Post = await response.json();
    return postData;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const listUserProducts = async (
  accessToken: string,
  paging: {
    filter?: string;
    order?: 'desc' | 'asc';
    orderBy?: 'refNum' | 'orderedOn' | 'status';
    cursor?: string;
    size?: number;
    refNum?: string;
  } = {}
): Promise<UserProduct[]> => {
  try {
    const response = await fetch(`${host}/api/users/products`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ paging }),
    });
    if (response.status !== 200) throw response;
    const data: UserProductApi = await response.json();
    return data?.items || [];
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const listUserOrders = async (
  accessToken: string,
  paging: {
    filter?: string;
    order?: 'desc' | 'asc';
    orderBy?: 'refNum' | 'orderedOn' | 'status';
    cursor?: string;
    size?: number;
  } = {}
): Promise<ListUserOrderData[]> => {
  try {
    const response = await fetch(`${host}/api/users/orders`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ paging }),
    });
    if (response.status !== 200) throw response;
    const data: ListUserOrderApi = await response.json();
    return data?.items || [];
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const getCurrentUser = async (
  accessToken: string
): Promise<User | null> => {
  try {
    const response = await fetch(`${host}/api/users/current`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status !== 200) throw response;
    const data: User = await response.json();
    return data;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const updateCurrentUser = async (
  accessToken: string,
  user: { firstName: string; lastName: string }
): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/users/current`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(user),
    });
    if (response.status !== 200) throw response;
    return true;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};

export const getUserAddresses = async (
  accessToken: string
): Promise<UserAddress[]> => {
  try {
    const response = await fetch(`${host}/api/users/addresses/list`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) throw response;

    return await response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createUserAddress = async (
  accessToken: string,
  address: UserAddress
): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/users/addresses`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(address),
    });

    if (!response.ok) throw response;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const updateUserAddress = async (
  accessToken: string,
  address: UserAddress
): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/users/addresses/${address.id}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(address),
    });

    if (!response.ok) throw response;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteUserAddress = async (
  accessToken: string,
  address: UserAddress
): Promise<boolean> => {
  try {
    const response = await fetch(`${host}/api/users/addresses/${address.id}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) throw response;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const downloadOrderInvoice = async (
  accessToken: string,
  order: Order
): Promise<void> => {
  try {
    const response = await fetch(`${host}/api/orders/invoice/pdf/${order.id}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) throw response;

    const data = await response.text();
    const downloadLink = document.createElement('a');
    downloadLink.href = data;
    downloadLink.download = `${order.refNum}.pdf`;
    downloadLink.click();
    downloadLink.remove();
  } catch (error) {
    console.error(error);
  }
};
