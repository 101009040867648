import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';
import { Link } from '../common';

const Title = styled.h3`
  font-family: var(--font-header);
  font-size: ${rem(24)};
  line-height: 1.2;
  font-weight: 400;
  color: var(--font-primary-color);
  margin: 20px 0 10px 0;

  ${media.xl`
    font-size: ${rem(36)};
  `}
`;

const Body = styled.p`
  font-family: var(--font-body);
  color: var(--font-secondary-color);
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: 21px;

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const ProductLink = styled(Link)`
  text-decoration: none;
`;

type Props = {
  asset: React.ReactNode;
  title: string;
  body: string;
  url: string;
};

const ProductCard: React.FC<Props> = ({ asset, title, body, url }) => {
  return (
    <>
      <ProductLink to={url}>
        {asset}
        <Title>{title}</Title>
      </ProductLink>
      <Body>{body}</Body>
    </>
  );
};

export default ProductCard;
