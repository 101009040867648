import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { rem } from '../../styles/utils';
import { ProductOrderItem } from '../../types/order';

const Container = styled.div``;

const Name = styled.strong`
  font-family: var(--font-header);
  font-size: ${rem(14)};
  font-weight: normal;
  color: var(--font-primary-color);
  display: block;

  ${media.xl`
    font-size: ${rem(16)};
    margin-bottom: 5px;
  `}
`;

const Variant = styled.span`
  font-family: var(--font-body);
  font-size: ${rem(12)};
  font-weight: normal;
  color: var(--font-cart-color);
  display: block;

  ${media.xl`
    font-size: ${rem(14)};
  `}
`;

const PreOrderLabel = styled(Variant)`
  color: var(--leather);
  font-size: 70%;
`;

type Props = {
  className?: string;
  product: ProductOrderItem;
};

const ProductOrderItemInfo: React.FC<Props> = ({ className, product }) => {
  return (
    <Container className={className}>
      <Name>{product.name}</Name>
      <Variant>{product.variant}</Variant>
      {product.subVariants &&
        product.subVariants
          .filter(s => s.value && s.value.length > 0)
          .map(s => <Variant key={s.key}>+ {s.value}</Variant>)}
      {product.status === 'Pre-Order' && product.statusDate && (
        <>
          <br />
          <PreOrderLabel>PRE-ORDER</PreOrderLabel>
        </>
      )}
    </Container>
  );
};

export default ProductOrderItemInfo;
