import React from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { renderToStaticMarkup } from 'react-dom/server';

import { SvgWave } from '../assets';
import { sizeMap } from '../../utils';

const wave = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
`;

const Container = styled.div``;

const WaveLine = styled.div`
  background-repeat: repeat-x;
  width: 6400px;
  height: 150px;
  -webkit-animation: ${wave} 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  animation: ${wave} 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  margin-top: -130px;

  ${media.xl`
    margin-top: -120px;
  `}
`;

type Props = {
  className?: string;
  numOfWaves?: number;
  color?: string;
};

const WaveLineAnimation: React.FC<Props> = ({
  className,
  numOfWaves,
  color,
}) => {
  const svgString = encodeURIComponent(
    renderToStaticMarkup(<SvgWave stroke={color || 'white'} />)
  );
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  return (
    <Container className={className}>
      {sizeMap(numOfWaves || 1, key => (
        <WaveLine key={key} style={{ backgroundImage: dataUri }} />
      ))}
    </Container>
  );
};

export default WaveLineAnimation;
