import React from 'react';

import { WarrantyIntro } from '../common';

const StorageBaseWarrantyIntroSection: React.FC = () => {
  return (
    <WarrantyIntro headline="2 Year Limited Warranty">
      The Storage Bed and mechanisms are engineered for reliable and secure
      support night after night.
      <br />
      <br />
      In the unlikely event that your Storage Bed is met with any defects, we
      will replace or repair it without any charge to you. Your new Storage Bed
      is covered by a 2 Year Limited Warranty, dating from the time you
      purchased your new Storage Bed from Woosa Sleep Pte Ltd. The details of
      this warranty, and some limitations, are provided in the complete Warranty
      Description below.
      <br />
      <br />
      Your warranty is automatically registered to the purchaser, and will
      commence upon the delivery of your product.
    </WarrantyIntro>
  );
};

export default StorageBaseWarrantyIntroSection;
