import { useEffect } from 'react';

type Props<T> = {
  handler: () => void;
};

export default function useWindowResize<T>(props: Props<T>): void {
  const { handler } = props;

  useEffect(() => {
    // calls the handler once before re-sizing as initialisation
    handler();

    const handleResize = () => {
      handler();
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('scroll', handleResize);
  }, []);
}
