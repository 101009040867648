import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { BgBedsheetHero } from '../assets';
import { rem, lh } from '../../styles/utils';

const HeroContainer = styled.div`
  position: relative;
`;
const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
`;
const HeroTitle = styled.h1`
  font-family: var(--font-header);
  color: white;
  font-weight: normal;
  font-size: ${rem(36)};
  margin-bottom: 20px;

  ${media.lg`
    font-size: ${rem(42)};
  `}
`;
const HeroHeadline = styled.h2`
  font-family: var(--font-body);
  color: white;
  font-size: ${rem(12)};
  font-weight: normal;
  line-height: ${lh(21, 16)};

  ${media.lg`
    font-size: ${rem(16)};
  `}
`;

const BedsheetCategoryHeroSection: React.FC = () => {
  return (
    <HeroContainer>
      <BgBedsheetHero />
      <HeroContent>
        <HeroTitle>100% Egyptian Cotton</HeroTitle>
        <HeroHeadline>
          Luxuriously soft and only gets better with time.
        </HeroHeadline>
      </HeroContent>
    </HeroContainer>
  );
};

export default BedsheetCategoryHeroSection;
