import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';
import {
  ENABLE_GRABPAY,
  ENABLE_ATOME,
  ENABLE_PRODUCT_BEDSHEETS,
} from 'gatsby-env-variables';

import SvgMail from '../../static/images/mail-white.svg';
import SvgPhone from '../../static/images/phone-white.svg';
import SvgFacebook from '../../static/images/facebook-white.svg';
import SvgInstagram from '../../static/images/instagram-white.svg';
import SvgTiktok from '../../static/images/tiktok-white.svg';
import SvgYoutube from '../../static/images/youtube-white.svg';
import { rem } from '../../styles/utils';
import {
  Container,
  StoreInfo,
  FooterLogoList,
  Link,
  StoreContact,
  StoreEmail,
} from '../common';
import { FooterLogoItem } from '../common/footer-logo-list';
import { SiteUrls } from '../../utils/constants';
import {
  ImgBestInSg,
  ImgBestInSgWhite,
  ImgGrabPay,
  ImgVisa,
  ImgMastercard,
  ImgAmex,
  ImgAtomeFooter,
  ImgWoosa,
} from '../assets';

const FooterWrapper = styled.footer`
  font-family: var(--font-body);
  font-weight: normal;
  font-size: ${rem(16)};
  line-height: 1.3125;
  color: white;
  width: 100%;
  background-color: var(--firefly);
  position: relative;

  ${media.xl`
    font-size: ${rem(18)};
  `}

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterContainer = styled(Container)`
  padding: 60px 40px;
`;

const Logo = styled.div`
  width: 100%;
  max-width: 125px;
  margin-bottom: 20px;
`;

const Copyright = styled.div`
  line-height: 1.5;
  background-color: var(--nile-blue);
  width: 100%;
  text-align: center;
  color: white;
  padding: 15px 0;
`;

const NavList = styled.ul`
  padding: 0;
  margin-bottom: 0;
  list-style: none;

  ${media.md`
    margin-top: 0;
  `}
`;

const NavItem = styled.li`
  margin-bottom: 5px;
  font-size: ${rem(16)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const partnerLogoStyle = css`
  width: 60px;
  margin-top: 40px;

  ${media.md`
    margin-top: 0;
  `}

  ${media.xl`
    width: 80px;
  `}
`;

const BestInSgLogo = styled(ImgBestInSg)`
  ${partnerLogoStyle}
`;

const BestInSgWhiteLogo = styled(ImgBestInSgWhite)`
  ${partnerLogoStyle}
`;

const LogoItem = styled.div`
  display: block;
  margin-top: 30px;
  ${media.md`
    display: flex;
  `}
`;

const LogoList = styled(FooterLogoList)`
  margin-right: 0;

  ${media.md`
    margin-right: 50px;
  `}
`;

const PaymentLogoList = styled(FooterLogoList)``;

const SocialLogoList = styled(FooterLogoList)`
  li {
    margin-right: 15px;
  }
`;

const ContactItem = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SvgPhoneIcon = styled(SvgPhone)`
  margin-right: 10px;
`;
const SvgMailIcon = styled(SvgMail)`
  margin-right: 10px;
`;

const Headline = styled.h2`
  margin-top: 0;
  margin-bottom: 20px;
  font-size: ${rem(24)};
  font-weight: 400;
  font-family: var(--font-body);
`;

const SubHeadline = styled.h2`
  margin-top: 0;
  margin-bottom: 12px;
  font-size: ${rem(18)};
  font-weight: 400;
  font-family: var(--font-body);
`;

const FooterCol = styled(Col)`
  margin-bottom: 40px;

  ${media.lg`
    margin-bottom: 0;
  `}
`;

const HR = styled.div`
  min-height: 1px;
  background-color: #5a788d;
  margin: 40px 0;
`;

const WoosaIcon = styled(ImgWoosa)`
  width: 100%;
`;

const Footer: React.FC = () => {
  const paymentLogos: FooterLogoItem[] = [
    { image: <ImgAmex /> },
    { image: <ImgMastercard /> },
    { image: <ImgVisa /> },
  ];

  const socialLogos: FooterLogoItem[] = [
    { image: <SvgFacebook />, url: 'https://www.facebook.com/woosasg' },
    { image: <SvgInstagram />, url: 'https://www.instagram.com/woosasg' },
    { image: <SvgYoutube />, url: 'https://www.youtube.com/@woosasg' },
    { image: <SvgTiktok />, url: 'https://www.tiktok.com/@woosasg' },
  ];

  if (ENABLE_GRABPAY) {
    paymentLogos.push({ image: <ImgGrabPay /> });
  }

  if (ENABLE_ATOME) {
    paymentLogos.push({ image: <ImgAtomeFooter />, borderRadius: '0px' });
  }

  return (
    <FooterWrapper>
      <FooterContainer>
        <Row>
          <Col xs={12} md={12} lg={9}>
            <Headline>Shop all products</Headline>
            <Row>
              <FooterCol xs={12} md={12} lg={3}>
                <SubHeadline>Mattress</SubHeadline>
                <NavList>
                  {[
                    { to: SiteUrls.OriginalMattress, text: 'The Original' },
                    { to: SiteUrls.MysaMattress, text: 'The Mysa' },
                    { to: SiteUrls.TerraMattress, text: 'The Terra' },
                    { to: SiteUrls.ComparisonMattress, text: 'Compare all' },
                  ].map(({ to, text }) => (
                    <NavItem key={to}>
                      <Link to={to}>{text}</Link>
                    </NavItem>
                  ))}
                </NavList>
              </FooterCol>
              <FooterCol xs={12} md={12} lg={3}>
                <SubHeadline>
                  <Link to={SiteUrls.AdjustableBase2}>Adjustable</Link>
                </SubHeadline>
              </FooterCol>
              <FooterCol xs={12} md={12} lg={3}>
                <SubHeadline>
                  <Link to={SiteUrls.StorageBase}>Storage</Link>
                </SubHeadline>
              </FooterCol>
              <FooterCol xs={12} md={12} lg={3}>
                <SubHeadline>Accessories</SubHeadline>
                <NavList>
                  {[
                    { to: SiteUrls.OriginalPillow, text: 'The Woosa Pillow' },
                    {
                      to: SiteUrls.SeresSilkDuvet,
                      text: 'The Seres Silk Duvet',
                    },
                    {
                      to: SiteUrls.WaterproofMattressProtector,
                      text: 'The Mattress Protector',
                    },
                    {
                      to: SiteUrls.BedsheetsCategoryPage,
                      text: 'Egyptian Cotton Sheets',
                      hide: !ENABLE_PRODUCT_BEDSHEETS,
                    },
                  ]
                    .filter(({ hide }) => !hide)
                    .map(({ to, text }) => (
                      <NavItem key={to}>
                        <Link to={to}>{text}</Link>
                      </NavItem>
                    ))}
                </NavList>
              </FooterCol>
            </Row>
          </Col>
          <Col xs={12} md={12} lg={3}>
            <Headline>Support</Headline>
            <NavList>
              {[
                { to: SiteUrls.FreeTrial, text: 'The Woosa Sleep Trial' },
                { to: SiteUrls.OriginalMattressWarranty, text: 'Warranty' },
                { to: SiteUrls.Faq, text: 'FAQ' },
                { to: SiteUrls.OriginalMattressReview, text: 'Reviews' },
                { to: SiteUrls.Shipping, text: 'Shipping' },
              ].map(({ to, text }) => (
                <NavItem key={to}>
                  <Link to={to}>{text}</Link>
                </NavItem>
              ))}
            </NavList>
          </Col>
        </Row>
        <HR />
        <Row>
          <FooterCol xs={12} md={12} lg={6}>
            <Logo>
              <WoosaIcon />
            </Logo>

            <ContactItem>
              <SvgPhoneIcon />
              <StoreContact />
            </ContactItem>

            <ContactItem>
              <SvgMailIcon />
              <StoreEmail />
            </ContactItem>

            <LogoItem>
              <LogoList
                logos={[
                  {
                    image: <BestInSgLogo />,
                    url: 'https://linktr.ee/woosasg',
                  },
                  {
                    image: <BestInSgWhiteLogo />,
                    url:
                      'https://www.bestinsingapore.co/best-pillows-singapore/',
                  },
                ]}
              />
              <PaymentLogoList logos={paymentLogos} />
            </LogoItem>
          </FooterCol>
          <Col xs={12} md={12} lg={6}>
            <Row>
              <FooterCol xs={12} md={12} lg={6}>
                <Headline>Visit our showroom</Headline>
                <StoreInfo hideContact hideBookSlot />
              </FooterCol>
              <FooterCol xs={12} md={12} lg={6}>
                <Headline>Social</Headline>
                <SocialLogoList logos={socialLogos} />
              </FooterCol>
            </Row>
          </Col>
        </Row>
      </FooterContainer>
      <Copyright>
        © {new Date().getFullYear()}, Woosa Sleep Pte Ltd,
        <br />
        <small>GST&nbsp;No.&nbsp;201630124D</small>
      </Copyright>
    </FooterWrapper>
  );
};

export default Footer;
