import React from 'react';
import styled from 'styled-components';

import { rem, lh } from '../../styles/utils';
import { media } from 'styled-bootstrap-grid';

const Container = styled.div`
  position: absolute;
  display: none;
  ${media.xl`
    display: block;
  `}

  &.position-1 {
    bottom: 23%;
    right: 2%;

    ${media.xxl`
      bottom: 25%;
      right: 5%;
    `}
  }

  &.position-2 {
    bottom: 10%;
    right: 9%;

    ${media.xxl`
      bottom: 13%;
      right: 12%;
    `}
  }

  &.position-3 {
    bottom: 1%;
    right: 4%;

    ${media.xxl`
      bottom: 4%;
      right: 6%;
    `}
  }

  &.position-4 {
    bottom: -6%;
    right: 18%;

    ${media.xxl`
      bottom: -3%;
      right: 21%;
    `}
  }
`;

const Content = styled.div``;
const Dot = styled.div`
  position: absolute;
  top: -2.5px;
  z-index: 5;
  width: 6px;
  height: 6px;
  background: var(--blue-gray);
  border-radius: 50%;
  left: 0px;
`;
const Line = styled.div`
  position: absolute;
  top: 14px;
  z-index: 5;
  width: 70px;
  height: 1px;
  background: var(--blue-gray);
  left: -70px;
`;
const Headline = styled.h2`
  position: relative;
  z-index: 3;
  padding: 8px 15px;
  height: 30px;
  font-family: var(--font-body);
  font-size: ${rem(12)};
  color: var(--firefly);
  font-weight: 400;
  background: var(--blue-gray);
  margin: 0;
  border-radius: 15px;
  ${media.xxl`
  font-size: ${rem(14)};
  `}
`;
const OutLine1 = styled.div`
  position: relative;
  z-index: 2;
  top: -35px;
  left: -5px;
  height: 40px;
  width: calc(100% + 10px);
  border-radius: 20px;
  background: rgba(230, 238, 239, 0.4);
`;
const OutLine2 = styled.div`
  position: relative;
  z-index: 1;
  top: -80px;
  left: -10px;
  height: 50px;
  width: calc(100% + 20px);
  border-radius: 25px;
  background: rgba(230, 238, 239, 0.2);
`;

type Props = {
  className?: string;
  text: string;
};

const Callout: React.FC<Props> = ({ className, text }) => {
  return (
    <Container className={className}>
      <Line>
        <Dot />
      </Line>
      <Content>
        <Headline>{text}</Headline>
        <OutLine1 />
        <OutLine2 />
      </Content>
    </Container>
  );
};

export default Callout;
