import React from 'react';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import {
  ADJUSTABLE_BASE_PRODUCT_SECTION_VERSION,
  HEADBOARD_SIDEFRAME_PRODUCT_ID,
  PRODUCT_ADJUSTABLE_BASE_ID,
  PRODUCT_ORIGINAL_MATTRESS_ID,
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_MYSA_MATTRESS_ID,
  PRODUCT_PILLOW_ID,
} from 'gatsby-env-variables';

import { ProductSection } from '../common';
import { Product, ProductAddOnConfig } from '../../types/product';
import BedFrame1 from '../../static/videos/adjustable-bed-frame-1.mp4';
import BedFrame1Thumbnail from '../../assets/adjustable-bed-frame-1-video-thumbnail.png';
import BedFrame1Webp from '../../assets/adjustable-bed-frame-1-video-thumbnail.webp';
import BedFrame2 from '../../assets/bed-frame-2.png';
import BedFrame2Webp from '../../assets/bed-frame-2.webp';
import BedFrame3 from '../../assets/bed-frame-3.png';
import BedFrame3Webp from '../../assets/bed-frame-3.webp';
import BedFrame4 from '../../assets/bed-frame-4.png';
import BedFrame4Webp from '../../assets/bed-frame-4.webp';
import SvgMattress from '../../static/images/add-on-mattress.svg';
import SvgMysa from '../../static/images/add-on-mysa.svg';
import SvgPillow from '../../static/images/add-on-pillow.svg';
import SvgTerra from '../../static/images/add-on-terra.svg';

const Section = styled(ProductSection)`
  background-color: var(--panache);
`;

const BedFrameProductSection: React.FC = () => {
  const productId = PRODUCT_ADJUSTABLE_BASE_ID;
  const customAddOnId = HEADBOARD_SIDEFRAME_PRODUCT_ID;
  const addOnConfig: ProductAddOnConfig = {
    [PRODUCT_ORIGINAL_MATTRESS_ID]: {
      icon: <SvgMattress />,
      name: 'Original',
      sort: 2,
      qty: 1,
    },
    [PRODUCT_MYSA_MATTRESS_ID]: {
      icon: <SvgMysa />,
      name: 'Mysa',
      sort: 3,
      qty: 1,
    },
    [PRODUCT_PILLOW_ID]: {
      icon: <SvgPillow />,
      name: 'Pillow',
      sort: 1,
      qty: 2,
    },
    [PRODUCT_TERRA_MATTRESS_ID]: {
      icon: <SvgTerra />,
      name: 'Terra',
      sort: 4,
      qty: 1,
    },
  };

  // If v1, let's enable head board and side frame as normal addons
  if (ADJUSTABLE_BASE_PRODUCT_SECTION_VERSION !== 'v2') {
    addOnConfig[customAddOnId] = {
      icon: <div />,
      name: '',
      sort: 5,
      qty: 1,
    };
  }

  const {
    allProducts: { edges },
  } = useStaticQuery(graphql`
    query {
      allProducts(filter: { displayInStore: { eq: true } }) {
        edges {
          node {
            id
            name
            collection
            media {
              path
              default
              type
            }
            rating {
              avg
              count
              total
            }
            variants {
              type
              prices {
                amount
                currency
              }
              status
              statusDate {
                _seconds
              }
            }
            displayInStore
          }
        }
      }
    }
  `);

  const product = edges.find(
    ({ node }: { node: { id: string } }) => node.id === productId
  );
  const addOns = sortBy(
    edges
      .filter(({ node }: { node: { id: string } }) => node.id in addOnConfig)
      .map(({ node }: { node: Product }) => node),
    [p => addOnConfig[p.id].sort]
  );
  const customAddOn = edges.find(
    ({ node }: { node: { id: string } }) => node.id === customAddOnId
  );

  return (
    <Section
      {...product.node}
      addOns={addOns}
      addOnConfig={addOnConfig}
      addOnLabel={<>Accessories add&#8209;on</>}
      customAddOn={customAddOn.node}
      defaultAddOnText="Woosa Adjustable Base only"
      assets={[
        {
          src: BedFrame1,
          thumbnail: BedFrame1Thumbnail,
          webp: BedFrame1Webp,
          type: 'video',
        },
        {
          src: BedFrame2,
          webp: BedFrame2Webp,
          type: 'image',
        },
        {
          src: BedFrame3,
          webp: BedFrame3Webp,
          type: 'image',
        },
        {
          src: BedFrame4,
          webp: BedFrame4Webp,
          type: 'image',
        },
      ]}
      infoVersion={ADJUSTABLE_BASE_PRODUCT_SECTION_VERSION}
    >
      <p>
        Upgrade your bedroom experience by customizing your preferred positions
        in bed. The Woosa Adjustable Bed comes with an optional headboard and
        side frame for a more complete look.
      </p>
      <ul>
        <li>German Engineered Silent Motors</li>
        <li>Multiple One touch memory functions</li>
        <li>Dual USB Ports</li>
        <li>Double Vibration Motors</li>
      </ul>
    </Section>
  );
};

export default BedFrameProductSection;
