import React from 'react';
import styled from 'styled-components';
import { Col, Row, media } from 'styled-bootstrap-grid';

import { lh, rem } from '../../styles/utils';
import { SiteUrls } from '../../utils/constants';
import DefaultLink from './link';
import DefaultContainer from './container';
import useProductFromPrice from '../../hooks/use-product-from-price';

const Section = styled.section`
  font-family: var(--font-body);
  padding: 48px 0;

  ${media.lg`
    padding: 64px 0;
  `}
`;

const Link = styled(DefaultLink)`
  text-decoration: none;

  &.grid {
    margin-bottom: 30px;
    display: block;
  }

  ${media.lg`
    &.grid {
      width: 50%;
      
      &:nth-child(even) {
        margin-left: 10px;
      }

      &:nth-child(odd) {
        margin-right: 10px;
      }
    }
  `}
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  ${media.sm`
    max-width: 640px;
  `}
`;

const Eyebrow = styled.strong`
  display: inline-block;
  width: 100%;
  color: var(--santa-fe);
  font-weight: 700;
  font-size: ${rem(12)};
  line-height: ${lh(14.4, 12)};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 12px;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${lh(28.8, 24)};
  color: var(--font-primary-color);
  text-align: center;
  margin-top: 0;
  margin-bottom: 24px;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${lh(43.2, 36)};
    margin-bottom: 32px;
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;

  img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 40px;
`;

const Content = styled.div`
  width: 100%;
`;

const ProductContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const ProductName = styled.h3`
  font-family: var(--font-header);
  font-weight: 400;
  font-size: ${rem(20)};
  line-height: ${lh(24, 20)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 0;
  margin-right: auto;

  ${media.lg`
    font-size: ${rem(24)};
    line-height: ${lh(32, 24)}; 
  `}
`;

const Description = styled.p`
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${lh(16.8, 14)};
  color: var(--xanadu);
  margin-top: 0;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const ProductPrice = styled.strong`
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${lh(21, 14)};
  color: var(--font-primary-color);

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}
`;

const OverrideDefaultContainer = styled(DefaultContainer)`
  ${media.xs`
    padding: 0;
  `}
`;

const MultiContainer = styled.div`
  ${media.lg`
    display: flex;
    justify-content: center;
  `}
`;

type Product = {
  image: React.ReactNode;
  name: React.ReactNode;
  description: React.ReactNode;
  productId: string;
  to: SiteUrls;
};

type CardProps = {
  className?: string;
  product: Product;
};

const UpsellHeroCard: React.FC<CardProps> = ({
  className,
  product: { productId, to, image, name, description },
}) => {
  const price = useProductFromPrice(productId);

  return (
    <Link className={className} to={to}>
      <Container>
        <ImageContainer>{image}</ImageContainer>
        <Wrapper>
          <Content>
            <ProductContent>
              <ProductName>{name}</ProductName>
              <ProductPrice>{price}</ProductPrice>
            </ProductContent>
            <Description>{description}</Description>
          </Content>
        </Wrapper>
      </Container>
    </Link>
  );
};

type Props = {
  className?: string;
  eyebrow: React.ReactNode;
  headline: React.ReactNode;
  products: Product[];
};

const UpsellHeroSection: React.FC<Props> = ({
  className,
  eyebrow,
  headline,
  products,
}) => {
  return (
    <Section className={className}>
      <Container>
        <Wrapper>
          <Eyebrow>{eyebrow}</Eyebrow>
          <Headline>{headline}</Headline>
        </Wrapper>
      </Container>
      <OverrideDefaultContainer>
        <MultiContainer>
          {products.map(p => (
            <UpsellHeroCard
              className={products.length > 1 ? 'grid' : ''}
              key={p.productId}
              product={p}
            />
          ))}
        </MultiContainer>
      </OverrideDefaultContainer>
    </Section>
  );
};

export default UpsellHeroSection;
