/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import useSiteMetadata from '../../hooks/use-site-metadata';
import { CountryCode } from '../../utils/constants';

type Meta = {
  name?: string;
  content: string;
  property?: string;
};

interface Props {
  description?: string;
  lang?: string;
  meta?: Array<Meta>;
  title: string;
  titleTemplate?: string;
}

const mergeMeta = (defaultMeta: Meta[], newMeta?: Meta[]) => {
  const meta = [...(newMeta || [])];
  return defaultMeta
    .reduce<Meta[]>((acc, m) => {
      const index = meta.findIndex(nm => {
        if (m.name) {
          return m.name === nm.name;
        } else if (m.property) {
          return m.property === nm.property;
        } else {
          return false;
        }
      });
      if (index < 0) {
        acc.push(m);
      } else {
        acc.push(meta[index]);
        meta.splice(index, 1);
      }
      return acc;
    }, [])
    .concat(meta);
};

/**
 * Determine whether path is a country path or not and return the non-country path
 * And also return the country codes in an array format
 */
const normalizePath = (pathName: string) => {
  const countryCodes = Object.values(CountryCode).map(code =>
    code ? '/' + code : ''
  );
  const countryCodeInPathName = countryCodes
    .filter(code => code.length > 0)
    .find(code => pathName.includes(`${code}/`));
  const path = countryCodeInPathName
    ? pathName.replace(`${countryCodeInPathName}`, '')
    : pathName;
  return { path, countryCodes };
};

const renderAlternateTags = (siteUrl: string, pathName: string) => {
  const { path, countryCodes } = normalizePath(pathName);

  return countryCodes.map(code => {
    return (
      <link
        key={code}
        rel="alternate"
        href={`${siteUrl}${code}${path}`}
        hrefLang={code ? `en-${code.substring(1)}` : 'x-default'}
      />
    );
  });
};

const renderCanonicalTag = (siteUrl: string, pathName: string) => {
  let url = `${siteUrl}${pathName}`;
  // Base to SG for now
  const isSGPath = pathName.includes(`/${CountryCode.SG}`);

  if (!isSGPath) {
    url = `${siteUrl}/${CountryCode.SG}${pathName}`;
  }

  return <link rel="canonical" href={url} />;
};

const SEO: React.FC<Props> = ({
  description,
  lang,
  meta,
  title,
  titleTemplate,
}) => {
  const location = useLocation();
  const siteMetadata = useSiteMetadata();
  const url = `${siteMetadata.siteUrl}${location.pathname}`;

  const metaDescription = description || siteMetadata.description;
  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: 'google-site-verification',
      content: 'NyOu5PL2_n0uN7movCQ19WyqM0JI_h8_hz4epJEExPs',
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        titleTemplate !== undefined
          ? titleTemplate
          : `%s | ${siteMetadata.title}`
      }
      meta={mergeMeta(defaultMeta, meta)}
    >
      {renderAlternateTags(siteMetadata.siteUrl, location.pathname)}
      {renderCanonicalTag(siteMetadata.siteUrl, location.pathname)}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'LocalBusiness',
          name: 'Woosa Sleep Pte Ltd',
          image: '',
          '@id': '',
          url: 'https://woosa.sg/',
          telephone: '+65 9848 5822',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '1 Tampines North Dr 1,',
            addressLocality: '#01-01 T-Space',
            postalCode: '528559',
            addressCountry: 'SG',
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 1.3721233,
            longitude: 103.9314813,
          },
          openingHoursSpecification: {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: ['Saturday', 'Sunday'],
            opens: '11:00',
            closes: '20:00',
          },
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
