import React from 'react';
import styled from 'styled-components';
import { Row, Col as RawCol, media } from 'styled-bootstrap-grid';

import { rem, sectionMarginStyle } from '../../styles/utils';
import { CTA, Container } from '../common';
import { ProductGrid } from '../../types/component';

const Section = styled.section`
  width: 100%;
  text-align: center;
  ${sectionMarginStyle}
`;

const Headline = styled.h2`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(24)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 30px;

  ${media.lg`
    font-size: ${rem(38)};
    margin-bottom: 60px;
  `}

  ${media.lg`
    font-size: ${rem(42)};
    margin-bottom: 80px;
  `}
`;

const Subheadline = styled.h3`
  font-family: var(--font-header);
  font-weight: normal;
  font-size: ${rem(20)};
  color: var(--font-primary-color);
  margin-top: 0;
  margin-bottom: 20px;

  ${media.lg`
    font-size: ${rem(30)};
  `}
`;

const Body = styled.p`
  font-family: var(--font-body);
  font-weight: normal;
  font-size: ${rem(14)};
  line-height: 1.28;
  color: var(--font-primary-color);
  margin-bottom: 20px;

  ${media.lg`
    font-size: ${rem(18)};
    margin-bottom: 40px;
  `}
`;

const Col = styled(RawCol)`
  ${media.lg`
    padding: 0 30px;
  `}
`;

const Product = styled.div`
  margin-bottom: 40px;
`;

const Image = styled.div`
  margin-bottom: 30px;

  ${media.lg`
    margin-bottom: 40px;
  `}

  img,
  picture {
    width: 100%;
  }
`;

const Grid: React.FC<ProductGrid> = ({ headline, body, img, cta }) => (
  <Product>
    <Subheadline>{headline}</Subheadline>
    <Body>{body}</Body>
    <Image>{img}</Image>
    <CTA to={cta.to} type="flat">
      {cta.text}
    </CTA>
  </Product>
);

type Props = {
  className?: string;
  grids: ProductGrid[];
};

const ProductGridSection: React.FC<Props> = ({ grids, className }) => {
  return (
    <Section className={className}>
      <Container>
        <Headline>
          Complete your nights with the best mattress in Singapore.
        </Headline>
        <Row justifyContent="center">
          {grids.map((grid, key) => (
            <Col key={key} xs={12} md={4}>
              <Grid {...grid} />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default ProductGridSection;
