import React from 'react';
import { Breakpoints, Config } from './enums';
import { GridThemeProvider } from 'styled-bootstrap-grid';

export const gridTheme = {
  gridColumns: 12,
  breakpoints: {
    xxl: Breakpoints.XXL,
    xl: Breakpoints.XL,
    lg: Breakpoints.LG,
    md: Breakpoints.MD,
    sm: Breakpoints.SM,
    xs: Breakpoints.SM - 1,
  },
  row: {
    padding: 15,
  },
  col: {
    padding: 15,
  },
  container: {
    padding: Config.CONTAINER_PADDING,
    maxWidth: {
      xxl: 1400,
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

type Props = {
  children: React.ReactChild;
};

const GridProvider: React.FC<Props> = ({ children }) => {
  return (
    <GridThemeProvider gridTheme={gridTheme}>{children}</GridThemeProvider>
  );
};

export default GridProvider;
