import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';

import { Asset } from '../../types/component';
import SvgClose from '../../static/images/close.svg';
import SvgChevronLeft from '../../static/images/mattress-layer-v2-chevron-left.svg';
import SvgChevronRight from '../../static/images/mattress-layer-v2-chevron-right.svg';

const OverlayContainer = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  border: 1px solid aquamarine;
  background: rgba(39, 39, 39, 0.5);
  p {
    color: aquamarine;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0.5em 0;
  }
  svg {
    border: white 1px solid;
    fill: aquamarine;
    margin: 10px;
    padding: 5px;
    :hover {
      border: aquamarine;
      fill: aquamarine;
      cursor: pointer;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;

const CloseButton = styled.button`
  background: none;
  padding: 0;
  border: 0;
  width: 20px;
  cursor: pointer;

  svg {
    width: 100%;

    .close-1 {
      stroke: white;
    }
  }
`;

const ArrowButton = styled.button<{ position: 'left' | 'right' }>`
  position: absolute;
  z-index: 10;
  background: none;
  border-style: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  left: ${({ position }) => (position === 'left' ? '15px' : 'unset')};
  right: ${({ position }) => (position === 'right' ? '15px' : 'unset')};
  width: 15px;

  svg {
    width: 100%;
    height: auto;

    path {
      stroke: white !important;
    }
  }
`;

const StyledLightbox = styled(Lightbox)`
  background: #1f1f1f;
  * ::selection {
    background: ${({ theme }) => theme.pageContentSelectionColor};
  }
  * ::-moz-selection {
    background: aquamarine;
  }
`;

type Props = {
  className?: string;
  assets: Asset[];
  startIndex?: number;
  isOpen?: boolean;
  onClose?: () => void;
};

const LightboxGallery: React.FC<Props> = ({
  className,
  assets,
  startIndex,
  isOpen,
  onClose,
}) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex || 0);
  const images: ImagesListType = assets
    .filter(({ type }) => type === 'image')
    .map(({ src }) => ({
      src,
      loading: 'lazy',
      alt: '',
    }));

  useEffect(() => {
    if (!startIndex && startIndex !== 0) return;
    setCurrentIndex(startIndex);
  }, [startIndex]);

  const handlePrev = () => {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = images.length - 1;
    }
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    let newIndex = currentIndex + 1;
    if (newIndex > images.length - 1) {
      newIndex = 0;
    }
    setCurrentIndex(newIndex);
  };

  return (
    <StyledLightbox
      isOpen={isOpen || false}
      className={classNames('lightbox-gallery', className)}
      images={images}
      onPrev={handlePrev}
      onNext={handleNext}
      onClose={onClose}
      singleClickToZoom
      currentIndex={currentIndex}
      renderNextButton={() => (
        <ArrowButton position="right" onClick={handleNext}>
          <SvgChevronRight />
        </ArrowButton>
      )}
      renderPrevButton={() => (
        <ArrowButton position="left" onClick={handlePrev}>
          <SvgChevronLeft />
        </ArrowButton>
      )}
      renderImageOverlay={() => <OverlayContainer />}
      renderHeader={() => (
        <Header>
          <CloseButton onClick={onClose}>
            <SvgClose />
          </CloseButton>
        </Header>
      )}
    />
  );
};

export default LightboxGallery;
