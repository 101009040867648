import React from 'react';
import styled from 'styled-components';

import { SimpleSplitSection } from '../common';
import {
  Wrapper,
  LeftTitle,
  fontStyle,
  PlusIconList,
  PlusIconListItem,
} from '../common/simple-split-section';

const Container = styled(Wrapper)``;

const Row = styled(SimpleSplitSection)`
  margin-top: 40px;
`;

const Paragraph = styled.p`
  ${fontStyle}
  margin-top: 0;
`;

const StorageBaseWarrantyContentSection: React.FC = () => {
  const data = [
    {
      title: 'Warranty Description',
      content: (
        <Paragraph>
          All Woosa warranties, including any implied warranties, are valid only
          for the period of time the Storage Bed is owned by the original
          purchaser of the Woosa Storage Bed. The “original purchaser,” for the
          purposes of this Storage Bed Warranty, is the first purchaser of the
          Storage Bed from Woosa Sleep or a Woosa authorized retailer. Please
          retain a copy of your receipt as proof of purchase. All Woosa limited
          warranties are not transferable.
        </Paragraph>
      ),
    },
    {
      title: 'What does the Limited Warranty Cover?',
      content: (
        <Paragraph>
          Full coverage on the Frame structure, including the integrity of the
          wood slates, wooden internal structure and steel structure.
          <br />
          <br />
          Soft upholstery and structure of the Storage Bed, including the foam
          fillings, fabric, leather and other material covers, stitching on the
          headboard and the side boards. Coverage for the Storage Bed leather
          material includes flaking and peeling not caused by wear and tear.
          <br />
          <br />
          Hydraulic systems, including the hydraulic pistons, brackets and
          fastening hardware.
        </Paragraph>
      ),
    },
    {
      title: 'What does this Limited Warranty NOT Cover?',
      content: (
        <PlusIconList>
          <Paragraph>
            This limited warranty does not cover or apply:
            <br />
            <br />
            Damage resulting from negligence, abuse, normal wear and tear, or
            accidents: including (but not limited to) burns, cuts, scratches,
            tears, scuffs, watermarks, indentations, outdoor exposure or damage
            from pets.
          </Paragraph>
          <PlusIconListItem>
            Damage resulting from exceeding the recommended weight rating,
            damage resulting from uneven distribution of load on the Storage Bed
            or damage resulting from unauthorised repair.
          </PlusIconListItem>
          <PlusIconListItem>
            The natural degradation of hydrophobic/stain resistant coatings on
            fabric materials.
          </PlusIconListItem>
        </PlusIconList>
      ),
    },
  ];

  return (
    <Container>
      {data.map(({ title, content }, i) => (
        <Row key={i} left={<LeftTitle>{title}</LeftTitle>} right={content} />
      ))}
    </Container>
  );
};

export default StorageBaseWarrantyContentSection;
