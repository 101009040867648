import React from 'react';
import styled from 'styled-components';

import { Accordion } from '../../types/component';
import { rem } from '../../styles/utils';

export const Title = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: 700;
  margin-bottom: 5px;
  font-size: ${rem(16)};
`;

export const Headline = styled.h2`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  font-weight: 400;
  margin: 0;
  font-size: ${rem(16)};
  line-height: 21px;
`;

const Section = styled.div`
  padding: 20px 0;
`;

const Content = styled.div`
  margin-bottom: 25px;
`;

type Props = {
  data: Accordion[];
};

const FeatureContent: React.FC<Props> = ({ data }) => {
  return (
    <Section>
      {data.map((d, key) => (
        <Content key={key}>
          <Title>{d.title}</Title>
          <Headline>{d.body}</Headline>
        </Content>
      ))}
    </Section>
  );
};

export default FeatureContent;
