import React from 'react';
import styled from 'styled-components';

import { ProductBenefitSection } from '../common';
import { Benefit } from '../../types/component';
import {
  ImgWaterproofMattressProtectorBenefit1,
  ImgWaterproofMattressProtectorBenefit2,
} from '../assets';

const Section = styled(ProductBenefitSection)`
  background-color: var(--pearl-bush);
`;

const benefits: Benefit[] = [
  {
    img: <ImgWaterproofMattressProtectorBenefit1 />,
    eyebrow: 'MOVEMENT-FREE',
    headline: "Fits so snug, you won't notice it's there",
    body: (
      <>
        You&apos;ll be hard pressed to find a mattress protector as comfy as
        this. Made from silent, noise-free fabric, slip it onto your bed for a
        perfectly aligned fit that promises not to bunch up, no matter how much
        you toss and turn.
      </>
    ),
  },
  {
    img: <ImgWaterproofMattressProtectorBenefit2 />,
    eyebrow: 'TEMPERATURE REGULATING',
    headline: 'Sleep soundly even in humid weather',
    body:
      "Our design is made with a temperature-regulating material that keeps you cool when it's warm outside. With our breathable design, there's no need to choose between a cosy snooze and a well-protected mattress.",
  },
];

const WaterproofMattressProtectorBenefitSection: React.FC = () => (
  <Section
    benefits={benefits}
    blob={{ color1: '#dfcac3', color2: 'white' }}
    waveLineColor="#cac1b7"
  />
);

export default WaterproofMattressProtectorBenefitSection;
