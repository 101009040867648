import React, { useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import queryString from 'query-string';

const Container = styled.div``;

const Trigger = styled.div`
  cursor: pointer;
`;

const Content = styled.div`
  will-change: max-height, visibility;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.2s ease-out, visibility 0.2s ease-out;

  &.active {
    visibility: visible;
    transition: max-height 0.2s ease-out, visibility 0.2s ease-out;
  }
`;

type Props = {
  id?: string;
  className?: string;
  trigger: React.ReactNode;
  children: React.ReactNode;
  onChange?: (isExpanded: boolean) => void;
};

const Expander: React.FC<Props> = ({
  id,
  className,
  trigger,
  children,
  onChange,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (!ref.current) return;
    const content = ref.current.querySelector('.expander-content');
    if (!content) return;

    const handleTransitionEnd = () => {
      if (typeof onChange !== 'function') return;
      onChange(content.classList.contains('active'));
    };

    content.addEventListener('transitionend', handleTransitionEnd);

    if (location.hash.replace('#', '') === id) {
      toggle();
    }

    return () =>
      content.removeEventListener('transitionend', handleTransitionEnd);
  }, []);

  const toggle = () => {
    if (!ref.current) return;
    const content = ref.current.querySelector('.expander-content');

    if (content) {
      const isActive = !content.classList.contains('active');
      if (isActive) {
        content.classList.add('active');
        content.setAttribute(`style`, `max-height: ${content.scrollHeight}px`);
      } else {
        content.classList.remove('active');
        content.removeAttribute('style');
      }
    }
  };

  return (
    <Container id={id} className={className} ref={ref}>
      <Trigger onClick={toggle}>{trigger}</Trigger>
      <Content className="expander-content">{children}</Content>
    </Container>
  );
};

export default Expander;
