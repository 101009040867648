import React from 'react';

import { MattressLayer } from '../common';

import SvgCover from '../../static/images/terra-mattress-layer-cover.svg';
import SvgPlusCover from '../../static/images/plus-cover-layer.svg';
import Svg1 from '../../static/images/terra-mattress-layer-1.svg';
import Svg2 from '../../static/images/terra-mattress-layer-2.svg';
import Svg3 from '../../static/images/terra-mattress-layer-3.svg';
import Svg4 from '../../static/images/terra-mattress-layer-4.svg';
import Svg5 from '../../static/images/terra-mattress-layer-5.svg';
type Props = {
  isPlusCover?: boolean;
};
const TerraMattressLayerSection: React.FC<Props> = ({ isPlusCover }) => {
  return (
    <>
      <MattressLayer
        version="v2"
        headline="Let’s look under the covers."
        isPlusCover={isPlusCover}
        plusCoverInfo={{
          title: 'The PLUS+ Cover',
          subtitle: '',
          description:
            'Made in Italy from a blend of microtencel and cooling yarn. Quilted with a layer of organic cotton for plushier and firmer feel. Low-friction and allows for seamless movement without generating heat.',
          asset: <SvgPlusCover />,
        }}
        infos={[
          {
            title: 'The Cover',
            subtitle: 'Italian-made Microtencel',
            description:
              'Incredibly soft and plush microtencel with embedded silver threading for added antimicrobial properties and odour reduction.',
            transition: {
              initial: { x: '-5px', y: '200px' },
              active: { x: '0', y: '-30%' },
              neutral: { x: '0', y: '-55%' },
            },
            asset: <SvgCover />,
            cover: {
              initial: { x: '0', y: '705px', mdY: '755px' },
              active: { x: '0', y: '-600%', mdY: '-600%' },
              neutral: { x: '0', y: '-665%', mdY: '-665%' },
              size: { width: '440px', height: '100px' },
            },
          },
          {
            title: 'Ultra Density Latex Layer',
            subtitle: '100% Natural Latex - 3cm',
            description:
              'Ensures spinal alignment and maintains good sleeping posture throughout the night',
            transition: {
              initial: { x: '-20px', y: '108px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-33%' },
            },
            asset: <Svg1 />,
            cover: {
              initial: { x: '0', y: '264px', mdY: '314px' },
              active: { x: '0', y: '-300%', mdY: '-300%' },
              neutral: { x: '0', y: '-496%', mdY: '-496%' },
              size: { width: '380px', height: '19px' },
            },
          },
          {
            title: 'High Density Latex Layer',
            subtitle: '100% Natural Latex - 5cm',
            description:
              'Provides ergonomic support for the entire body without sinking and sagging, specially targeted at areas around the lower back.',
            transition: {
              initial: { x: '0', y: '128px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-38%' },
            },
            asset: <Svg2 />,
            cover: {
              initial: { x: '0', y: '283px', mdY: '333px' },
              active: { x: '0', y: '-172%', mdY: '-172%' },
              neutral: { x: '0', y: '-333%', mdY: '-333%' },
              size: { width: '380px', height: '28px' },
            },
          },
          {
            title: 'Ultra Density Memory Foam Layer',
            subtitle: 'Ultra Premium Memory Foam - 5cm',
            description:
              'Offers pressure relieving support, gently hugging you to sleep.',
            transition: {
              initial: { x: '0', y: '100px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-38%' },
            },
            asset: <Svg3 />,
            cover: {
              initial: { x: '0', y: '310px', mdY: '360px' },
              active: { x: '0', y: '-169%', mdY: '-169%' },
              neutral: { x: '0', y: '-318%', mdY: '-318%' },
              size: { width: '380px', height: '29px' },
            },
          },
          {
            title: 'The Transition Layer',
            subtitle: 'High Resiliency Transition Foam - 7cm',
            description:
              'Provides additional, deeper levels of comfort and support before easing into the base foam.',
            transition: {
              initial: { x: '0', y: '55px' },
              active: { x: '0', y: '-20%' },
              neutral: { x: '0', y: '-34%' },
            },
            asset: <Svg4 />,
            cover: {
              initial: { x: '0', y: '336px', mdY: '386px' },
              active: { x: '0', y: '-109%', mdY: '-109%' },
              neutral: { x: '0', y: '-186%', mdY: '-186%' },
              size: { width: '380px', height: '48px' },
            },
          },
          {
            title: 'The Foundation',
            subtitle: 'Ultra High Density BaseCore Foam - 10cm',
            description:
              'Foundational base for added lift and zero motion transfer when you toss and turn.',
            transition: {
              initial: { x: '0', y: '0' },
              active: { x: '0', y: '0' },
              neutral: { x: '0', y: '0' },
            },
            asset: <Svg5 />,
            cover: {
              initial: { x: '0', y: '381px', mdY: '431px' },
              active: { x: '0', y: '0', mdY: '0' },
              neutral: { x: '0', y: '0', mdY: '0' },
              size: { width: '380px', height: '59px' },
            },
          },
        ]}
      />
    </>
  );
};

export default TerraMattressLayerSection;
